<template>
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
      <a-card>
        <template slot="title">
          <strong>Form Input</strong>
          <a-button class="btn btn-outline-primary ml-3" @click="beforeSaved"
            >Save Changes</a-button
          >
          <a-button
            class="btn btn-outline-danger float-right"
            @click="resetForm"
            >Back</a-button
          >
        </template>
        <div class="row">
          <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
            <strong>Title</strong>
            <a-input
              class="ml-3"
              v-model="input.tittle"
              placeholder="Input Title"
              style="width: 250px;"
            ></a-input>
          </div>
          <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 mt-1">
            <strong class="mt-1">Img Url</strong>
            <a-input
              v-model="input.img_url"
              placeholder="Input Img Url"
              ref="isianNamaFoto"
              class="ml-3"
              style="width: 250px;"
            ></a-input>
            <a-button
              class="btn btn-outline-success ml-2"
              @click="selectFile('focusOnYourFcknJob')"
              >Or Upload</a-button
            >
            <a-button
              class="btn btn-outline-warning ml-2"
              v-if="fileinput !== null"
              @click="lihatgambar"
              >Preview</a-button
            >
            <input
              type="file"
              style="display: none;"
              ref="focusOnYourFcknJob"
              accept="image/*"
              @change="(e) => previewImage(e, 'isianNamaFoto')"
            />
          </div>
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-3">
            <center><strong>Content</strong></center>
            <a-textarea
              v-model="input.content"
              placeholder="Input Content"
              :auto-size="{ minRows: 7, maxRows: 8 }"
            />
          </div>
        </div>
      </a-card>
    </div>
    <zoomgambar ref="zoomgambar" />
  </div>
</template>

<script>
import * as lou from '@/services/data/lou'
import moment from 'moment'
import firebase from 'firebase/app'
import 'firebase/storage'
import zoomgambar from '@/components/lou/zoomgambar.vue'

export default {
  components: {
    zoomgambar,
  },
  data() {
    return {
      editdata: JSON.parse(localStorage.getItem('mobileadminnewsformdata')),
      fileinput: null,
      fileinputurl: null,
      input: {
        tittle: '',
        content: '',
        img_url: '',
        img_name: '',
      },
      action: 'create',
      title: '',
    }
  },
  created() {
    // console.log('this.editdata', this.editdata)
    if (this.editdata.id !== '') {
      this.action = 'update'
      this.title = 'Update Data'
      this.setData()
    } else {
      this.action = 'create'
      this.title = 'Tambah Data'
    }
  },
  methods: {
    moment,
    setData() {
      console.log('this.editdata', this.editdata)
      // this.input = this.$g.clone(this.editdata)
      this.input.tittle = this.editdata.tittle
      this.input.content = this.editdata.content
      this.input.img_url = this.editdata.img_url
      this.input.img_name = this.editdata.img_name ?? ''
      this.fileinputurl = this.editdata.img_url
    },
    previewImage(event, ref) {
      // this.$refs[ref].value = event.target.files[0].name
      this.input.img_url = event.target.files[0].name
      this.fileinput = event.target.files[0]
      this.fileinputurl = URL.createObjectURL(event.target.files[0])
      // console.log('this.fileinput', this.fileinput)
    },
    beforeSaved() {
      if (this.fileinput == null) {
        this.saveChanges()
      } else {
        var name = this.$uuid.v4()
        if (this.action === 'create') {
          name = this.$uuid.v4()
        } else {
          name = this.editdata.img_name
        }
        this.input.img_name = name
        this.onUpload(name, this.fileinput, this.editdata)
      }
    },
    async saveChanges() {
      var fd = this.input
      var res = null
      if (this.action === 'update') {
        fd.id = this.editdata.id
        res = await lou.customUrlPut2('news', fd, true, false, true)
      } else {
        res = await lou.customUrlPost2('news', fd, true, false, true)
      }
      if (res) {
        this.resetForm()
      }
    },
    async onUpload(name, imagedata, element) {
      this.uploadvalue = 0
      // var returnnya = ''
      const storageRef = firebase
        .storage()
        .ref('mobileadmin/news/' + name)
        .put(imagedata)
      storageRef.on(
        'state_changed',
        (snapshot) => {
          this.uploadvalue =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        },
        (error) => {
          // console.log('error.message', error.message)
        },
        () => {
          this.uploadvalue = 100
          // console.log('url', url)
          storageRef.snapshot.ref.getDownloadURL().then((url) => {
            this.input.img_url = url
            this.saveChanges()
            return url
          })
        },
      )
      // returnnya = await storageRef.snapshot.ref.getDownloadURL()
      // console.log('returnnya', returnnya)
      // return returnnya
    },
    resetForm() {
      localStorage.removeItem('mobileadminnewsformdata')
      this.$router.push('/mobileadmin/news')
    },
    selectFile(ref) {
      this.$refs[ref].click()
    },
    lihatgambar() {
      var obj = { img_url: this.fileinputurl }
      console.log('obj', obj)
      this.$refs.zoomgambar.lihatgambar(obj, 'img_url')
    },
  },
}
</script>

<style></style>
