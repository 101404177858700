<template>
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
      <a-card>
        <template slot="title">
          <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <strong>Arrange Permissions(Menu)</strong>
          <!-- <v-select
            ref="icon"
            placeholder="Select App"
            class="style-chooser"
            label="appName"
            style="width: 100px;"
            v-model="appId"
            :options="dataapplist"
            :reduce="(tes) => tes.id"
            @input="getData"
          >
        </v-select> -->
              <a-select
            show-search
            option-filter-prop="children"
            :filter-option="filterOption"
                class="ml-2"
                placeholder="Select App"
                v-model="appId"
                style="width: 200px;"
                @change="getData"
              >
                <a-select-option
                  v-for="(data, index) in dataapplist"
                  :key="index"
                  :value="data.id"
                  >{{ data.appName }}</a-select-option
                >
              </a-select>
              <a-button-group class="ml-2">
              <a-button
                class="btn btn-outline-primary"
                @click="showModal('create', 0)"
                >Create</a-button
              >
              <a-button
                class="btn btn-outline-success"
                @click="savechanges"
                >Save changes</a-button
              >
              <a-button
                class="btn btn-outline-success"
                @click="testarrangePermissions"
                >Clone To</a-button
              >
              </a-button-group>
              <a-switch v-model="showjsonincl" class="ml-2 mr-1">
                <a-icon slot="checkedChildren" type="check" />
                <a-icon slot="unCheckedChildren" type="close" />
              </a-switch>
              <strong>Show json in console when post</strong>
            </div>
          </div>
        </template>
        <div class="row">
          <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
            <a-tree
              class="draggable-tree"
              :replaceFields="{
                children: 'children',
                title: 'menu_name',
                key: 'id',
              }"
              :autoExpandParent="false"
              :default-expanded-keys="expandedKeys"
              draggable
              :tree-data="treeData"
              @dragenter="onDragEnter"
              @drop="onDrop"
            >
            <template #title="{ menu_name, id, key: treeKey }">
              <a-dropdown :trigger="['contextmenu']">
                <span>{{ menu_name }}</span>
                <template #overlay>
                  <a-menu @click="({ key: menuKey }) => onContextMenuClick(treeKey, menuKey)">
                    <a-menu-item key="1" class="text-warning" @click="showModal('update', id)">
                      <a-icon type="edit" />Edit</a-menu-item>
                    <a-menu-item key="2" class="text-primary" @click="showModal('addchild', id)">
                      <a-icon type="plus" />Add Child</a-menu-item>
                    <a-menu-item key="3" class="text-danger" @click="confirmationDelete(id)">
                      <a-icon type="delete" />Delete</a-menu-item>
                  </a-menu>
                </template>
              </a-dropdown>
            </template>
          </a-tree>
          </div>
          <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
            <a-tree
              class="draggable-tree"
              :replaceFields="{
                children: 'children',
                title: 'menu_name',
                key: 'id',
              }"
              :default-expanded-keys="expandedKeys"
              draggable
              :tree-data="modifiedTreeData"
              @dragenter="onDragEnter"
              @drop="onDrop"
            />
          </div>
        </div>
      </a-card>
    </div>
    <a-modal v-model="modalVisible">
      <template slot="footer">
        <a-button class="btn btn-outline-dark" @click="modalVisible = false"
          >Cancel</a-button
        >
        <a-button class="btn btn-outline-primary" @click="cloneAppData"
          >Process</a-button
        >
      </template>
      <strong>To App</strong>
      <a-select
        class="ml-5"
        v-model="tujuanappid"
        style="width: 200px;"
      >
        <a-select-option
          v-for="(data, index) in dataapplist"
          :key="index"
          :value="data.id"
          >{{ data.appName }}</a-select-option
        >
      </a-select>
    </a-modal>
    <a-modal v-model="formvisible" title="Form Permissions" :width="700" :dialogStyle="{ top: '10px' }">
      <template slot="footer">
        <a-button class="btn btn-outline-danger" @click="handleCancel">Cancel</a-button>
        <a-button class="btn btn-outline-primary" @click="handleSubmit">Submit</a-button>
      </template>
      <div class="row">
        <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-1">
          <label class="">Menu Name</label>
        </div>
        <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9">
          <a-input
            class=""
            placeholder="Menu Name"
            v-model="modalInput.menu_name"
            ref="menu_nameinput"
          ></a-input>
        </div>
        <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
          <label class="">Key</label>
        </div>
        <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
          <a-input
            class=""
            placeholder="Key"
            v-model="modalInput.key"
            ref="keyinput"
          ></a-input>
        </div>
        <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
          <label class="">Route</label>
        </div>
        <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
          <a-input
            class=""
            placeholder="Route"
            v-model="modalInput.route"
            ref="routeinput"
          ></a-input>
        </div>
        <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
          <label class="">URL</label>
        </div>
        <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
          <a-input
            class=""
            placeholder="URL"
            v-model="modalInput.url"
            ref="urlinput"
          ></a-input>
        </div>
        <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
          <label class="mr-2">Icon</label>
          <i :class="modalInput.icon ?? ''" style="font-size: 20px;"></i>
        </div>
        <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
          <!-- <v-select
            ref="icon"
            placeholder="Ketikan Icon"
            class="style-chooser"
            label="icon"
            style="width: 100%;"
            v-model="modalInput.icon"
            :options="iconData"
            :reduce="(tes) => tes.icon"
          >
          <template #selection="{ item }">
            <i :class="item.icon"></i> - {{ item.icon }}
          </template>
          <template #item="{ item, props }">
            <v-list-item v-bind="props">
              <template #title>
                <i :class="item.icon"></i> - {{ item.icon }}
              </template>
            </v-list-item>
          </template>
        </v-select> -->
          <a-input
            class=""
            placeholder="Icon"
            v-model="modalInput.icon"
            ref="iconinput"
            @click="selectIconModalVisible = true"
          ></a-input>
        </div>
        <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
          <label class="">Urutan</label>
        </div>
        <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
          <a-input
            class=""
            placeholder="Urutan"
            v-model="modalInput.urutan"
            ref="urutaninput"
          ></a-input>
        </div>
        <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
          <label class="">Level</label>
        </div>
        <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
          <a-input
            class=""
            placeholder="Level"
            v-model="modalInput.level"
            ref="levelinput"
          ></a-input>
        </div>
        <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
          <label class="">Parent</label>
        </div>
        <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
          <v-select
            ref="icon"
            placeholder="Ketikan Permissions"
            class="style-chooser"
            label="menu_name"
            style="width: 100%;"
            v-model="modalInput.parent"
            :options="pagesList"
            :reduce="(tes) => tes.id"
          >
        </v-select>
          <!-- <a-input
            class=""
            placeholder="Parent"
            v-model="modalInput.parent"
            ref="parentinput"
          ></a-input> -->
        </div>
        <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
          <label class="">Is Excluded</label>
        </div>
        <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
          <a-switch v-model="modalInput.isexclude">
            <a-icon slot="checkedChildren" type="check" />
            <a-icon slot="unCheckedChildren" type="close" />
          </a-switch>
          <!-- <a-input
            class=""
            placeholder="IsExclude"
            v-model="modalInput.isexclude"
            ref="isexcludeinput"
          ></a-input> -->
        </div>
        <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
          <label class="">Caption</label>
        </div>
        <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
          <a-input
            class=""
            placeholder="Caption"
            v-model="modalInput.caption"
            ref="captioninput"
          ></a-input>
        </div>
      </div>
    </a-modal>

    <a-modal v-model="selectIconModalVisible" :dialogStyle="{ top: '10px' }" :footer="null">
      <a-card>
        <template slot="title">
          <a-input class="" placeholder="Cari Icon" v-model="iconModalSearchInput" @input="filterIcon"></a-input>
        </template>
        <a-card-grid style="width:25%;text-align:center; cursor: pointer;white-space: nowrap;overflow: hidden;text-overflow: ellipsis; " v-for="(d, i) in iconData" :key="i" @click="selectIcon(d)">
          <i :class="d.icon" style="font-size: 25px;"></i>
          <br>
          <!-- <center> -->
            <strong style="font-size: 11px;">{{ d.icon }}</strong>
          <!-- </center> -->
        </a-card-grid>
      </a-card>
    </a-modal>
  </div>
</template>

<script>
import * as lou from '@/services/data/lou'
import moment from 'moment'
import { Modal } from 'ant-design-vue'

// import { mapState } from 'vuex'
export default {
  created() {
    this.getMaster()
    // this.getData()
  },
  data() {
    return {
      appId: null,
      tujuanappid: null,
      dataapplist: [],
      treeData: [],
      modifiedTreeData: [],
      modifiedTreeDataClone: [],
      changedData: [],
      expandedKeys: ['0-0', '0-0-0', '0-0-0-0'],
      showjsonincl: false,
      modalVisible: false,

      modalaction: 'create',
      pagesList: [],
      formvisible: false,
      modalInput: {
        app_Id: '',
        menu_name: '',
        key: '',
        route: '',
        url: '',
        icon: '',
        urutan: '',
        level: '',
        parent: '',
        isexclude: false,
        caption: '',
      },

      iconModalSearchInput: '',
      selectIconModalVisible: false,
      iconData: [
        { icon: 'fa fa-address-book' },
        { icon: 'fa fa-address-book-o' },
        { icon: 'fa fa-address-card' },
        { icon: 'fa fa-address-card-o' },
        { icon: 'fa fa-adjust' },
        { icon: 'fa fa-american-sign-language-interpreting' },
        { icon: 'fa fa-anchor' },
        { icon: 'fa fa-archive' },
        { icon: 'fa fa-area-chart' },
        { icon: 'fa fa-arrows' },
        { icon: 'fa fa-arrows-h' },
        { icon: 'fa fa-arrows-v' },
        { icon: 'fa fa-asl-interpreting' },
        { icon: 'fa fa-assistive-listening-systems' },
        { icon: 'fa fa-asterisk' },
        { icon: 'fa fa-at' },
        { icon: 'fa fa-automobile' },
        { icon: 'fa fa-audio-description' },
        { icon: 'fa fa-balance-scale' },
        { icon: 'fa fa-ban' },
        { icon: 'fa fa-bank' },
        { icon: 'fa fa-bar-chart' },
        { icon: 'fa fa-bar-chart-o' },
        { icon: 'fa fa-barcode' },
        { icon: 'fa fa-bars' },
        { icon: 'fa fa-bath' },
        { icon: 'fa fa-bathtub' },
        { icon: 'fa fa-battery-0' },
        { icon: 'fa fa-battery-1' },
        { icon: 'fa fa-battery-2' },
        { icon: 'fa fa-battery-3' },
        { icon: 'fa fa-battery-4' },
        { icon: 'fa fa-battery-empty' },
        { icon: 'fa fa-battery-full' },
        { icon: 'fa fa-battery-half' },
        { icon: 'fa fa-battery-quarter' },
        { icon: 'fa fa-battery-three-quarters' },
        { icon: 'fa fa-bed' },
        { icon: 'fa fa-beer' },
        { icon: 'fa fa-bell' },
        { icon: 'fa fa-bell-o' },
        { icon: 'fa fa-bell-slash' },
        { icon: 'fa fa-bell-slash-o' },
        { icon: 'fa fa-bicycle' },
        { icon: 'fa fa-binoculars' },
        { icon: 'fa fa-birthday-cake' },
        { icon: 'fa fa-blind' },
        { icon: 'fa fa-bolt' },
        { icon: 'fa fa-bomb' },
        { icon: 'fa fa-book' },
        { icon: 'fa fa-bookmark' },
        { icon: 'fa fa-bookmark-o' },
        { icon: 'fa fa-braille' },
        { icon: 'fa fa-briefcase' },
        { icon: 'fa fa-bug' },
        { icon: 'fa fa-building' },
        { icon: 'fa fa-building-o' },
        { icon: 'fa fa-bullhorn' },
        { icon: 'fa fa-bullseye' },
        { icon: 'fa fa-bus' },
        { icon: 'fa fa-cab' },
        { icon: 'fa fa-calculator' },
        { icon: 'fa fa-calendar' },
        { icon: 'fa fa-calendar-o' },
        { icon: 'fa fa-calendar-check-o' },
        { icon: 'fa fa-calendar-minus-o' },
        { icon: 'fa fa-calendar-plus-o' },
        { icon: 'fa fa-calendar-times-o' },
        { icon: 'fa fa-camera' },
        { icon: 'fa fa-camera-retro' },
        { icon: 'fa fa-car' },
        { icon: 'fa fa-caret-square-o-down' },
        { icon: 'fa fa-caret-square-o-left' },
        { icon: 'fa fa-caret-square-o-right' },
        { icon: 'fa fa-caret-square-o-up' },
        { icon: 'fa fa-cart-arrow-down' },
        { icon: 'fa fa-cart-plus' },
        { icon: 'fa fa-cc' },
        { icon: 'fa fa-certificate' },
        { icon: 'fa fa-check' },
        { icon: 'fa fa-check-circle' },
        { icon: 'fa fa-check-circle-o' },
        { icon: 'fa fa-check-square' },
        { icon: 'fa fa-check-square-o' },
        { icon: 'fa fa-child' },
        { icon: 'fa fa-circle' },
        { icon: 'fa fa-circle-o' },
        { icon: 'fa fa-circle-o-notch' },
        { icon: 'fa fa-circle-thin' },
        { icon: 'fa fa-clock-o' },
        { icon: 'fa fa-clone' },
        { icon: 'fa fa-close' },
        { icon: 'fa fa-cloud' },
        { icon: 'fa fa-cloud-download' },
        { icon: 'fa fa-cloud-upload' },
        { icon: 'fa fa-code' },
        { icon: 'fa fa-code-fork' },
        { icon: 'fa fa-coffee' },
        { icon: 'fa fa-cog' },
        { icon: 'fa fa-cogs' },
        { icon: 'fa fa-comment' },
        { icon: 'fa fa-comment-o' },
        { icon: 'fa fa-comments' },
        { icon: 'fa fa-comments-o' },
        { icon: 'fa fa-commenting' },
        { icon: 'fa fa-commenting-o' },
        { icon: 'fa fa-compass' },
        { icon: 'fa fa-copyright' },
        { icon: 'fa fa-credit-card' },
        { icon: 'fa fa-credit-card-alt' },
        { icon: 'fa fa-creative-commons' },
        { icon: 'fa fa-crop' },
        { icon: 'fa fa-crosshairs' },
        { icon: 'fa fa-cube' },
        { icon: 'fa fa-cubes' },
        { icon: 'fa fa-cutlery' },
        { icon: 'fa fa-dashboard' },
        { icon: 'fa fa-database' },
        { icon: 'fa fa-deaf' },
        { icon: 'fa fa-deafness' },
        { icon: 'fa fa-desktop' },
        { icon: 'fa fa-diamond' },
        { icon: 'fa fa-dot-circle-o' },
        { icon: 'fa fa-download' },
        { icon: 'fa fa-drivers-license' },
        { icon: 'fa fa-drivers-license-o' },
        { icon: 'fa fa-edit' },
        { icon: 'fa fa-ellipsis-h' },
        { icon: 'fa fa-ellipsis-v' },
        { icon: 'fa fa-envelope' },
        { icon: 'fa fa-envelope-o' },
        { icon: 'fa fa-envelope-open' },
        { icon: 'fa fa-envelope-open-o' },
        { icon: 'fa fa-envelope-square' },
        { icon: 'fa fa-eraser' },
        { icon: 'fa fa-exchange' },
        { icon: 'fa fa-exclamation' },
        { icon: 'fa fa-exclamation-circle' },
        { icon: 'fa fa-exclamation-triangle' },
        { icon: 'fa fa-external-link' },
        { icon: 'fa fa-external-link-square' },
        { icon: 'fa fa-eye' },
        { icon: 'fa fa-eye-slash' },
        { icon: 'fa fa-eyedropper' },
        { icon: 'fa fa-fax' },
        { icon: 'fa fa-female' },
        { icon: 'fa fa-fighter-jet' },
        { icon: 'fa fa-file-archive-o' },
        { icon: 'fa fa-file-audio-o' },
        { icon: 'fa fa-file-code-o' },
        { icon: 'fa fa-file-excel-o' },
        { icon: 'fa fa-file-image-o' },
        { icon: 'fa fa-file-movie-o' },
        { icon: 'fa fa-file-pdf-o' },
        { icon: 'fa fa-file-photo-o' },
        { icon: 'fa fa-file-picture-o' },
        { icon: 'fa fa-file-powerpoint-o' },
        { icon: 'fa fa-file-sound-o' },
        { icon: 'fa fa-file-video-o' },
        { icon: 'fa fa-file-word-o' },
        { icon: 'fa fa-file-zip-o' },
        { icon: 'fa fa-film' },
        { icon: 'fa fa-filter' },
        { icon: 'fa fa-fire' },
        { icon: 'fa fa-fire-extinguisher' },
        { icon: 'fa fa-flag' },
        { icon: 'fa fa-flag-checkered' },
        { icon: 'fa fa-flag-o' },
        { icon: 'fa fa-flash' },
        { icon: 'fa fa-flask' },
        { icon: 'fa fa-folder' },
        { icon: 'fa fa-folder-o' },
        { icon: 'fa fa-folder-open' },
        { icon: 'fa fa-folder-open-o' },
        { icon: 'fa fa-frown-o' },
        { icon: 'fa fa-futbol-o' },
        { icon: 'fa fa-gamepad' },
        { icon: 'fa fa-gavel' },
        { icon: 'fa fa-gear' },
        { icon: 'fa fa-gears' },
        { icon: 'fa fa-genderless' },
        { icon: 'fa fa-gift' },
        { icon: 'fa fa-glass' },
        { icon: 'fa fa-globe' },
        { icon: 'fa fa-graduation-cap' },
        { icon: 'fa fa-group' },
        { icon: 'fa fa-hard-of-hearing' },
        { icon: 'fa fa-hdd-o' },
        { icon: 'fa fa-handshake-o' },
        { icon: 'fa fa-hashtag' },
        { icon: 'fa fa-headphones' },
        { icon: 'fa fa-heart' },
        { icon: 'fa fa-heart-o' },
        { icon: 'fa fa-heartbeat' },
        { icon: 'fa fa-history' },
        { icon: 'fa fa-home' },
        { icon: 'fa fa-hotel' },
        { icon: 'fa fa-hourglass' },
        { icon: 'fa fa-hourglass-1' },
        { icon: 'fa fa-hourglass-2' },
        { icon: 'fa fa-hourglass-3' },
        { icon: 'fa fa-hourglass-end' },
        { icon: 'fa fa-hourglass-half' },
        { icon: 'fa fa-hourglass-o' },
        { icon: 'fa fa-hourglass-start' },
        { icon: 'fa fa-i-cursor' },
        { icon: 'fa fa-id-badge' },
        { icon: 'fa fa-id-card' },
        { icon: 'fa fa-id-card-o' },
        { icon: 'fa fa-image' },
        { icon: 'fa fa-inbox' },
        { icon: 'fa fa-industry' },
        { icon: 'fa fa-info' },
        { icon: 'fa fa-info-circle' },
        { icon: 'fa fa-institution' },
        { icon: 'fa fa-key' },
        { icon: 'fa fa-keyboard-o' },
        { icon: 'fa fa-language' },
        { icon: 'fa fa-laptop' },
        { icon: 'fa fa-leaf' },
        { icon: 'fa fa-legal' },
        { icon: 'fa fa-lemon-o' },
        { icon: 'fa fa-level-down' },
        { icon: 'fa fa-level-up' },
        { icon: 'fa fa-life-bouy' },
        { icon: 'fa fa-life-buoy' },
        { icon: 'fa fa-life-ring' },
        { icon: 'fa fa-life-saver' },
        { icon: 'fa fa-lightbulb-o' },
        { icon: 'fa fa-line-chart' },
        { icon: 'fa fa-location-arrow' },
        { icon: 'fa fa-lock' },
        { icon: 'fa fa-low-vision' },
        { icon: 'fa fa-magic' },
        { icon: 'fa fa-magnet' },
        { icon: 'fa fa-mail-forward' },
        { icon: 'fa fa-mail-reply' },
        { icon: 'fa fa-mail-reply-all' },
        { icon: 'fa fa-male' },
        { icon: 'fa fa-map' },
        { icon: 'fa fa-map-o' },
        { icon: 'fa fa-map-pin' },
        { icon: 'fa fa-map-signs' },
        { icon: 'fa fa-map-marker' },
        { icon: 'fa fa-meh-o' },
        { icon: 'fa fa-microchip' },
        { icon: 'fa fa-microphone' },
        { icon: 'fa fa-microphone-slash' },
        { icon: 'fa fa-minus' },
        { icon: 'fa fa-minus-circle' },
        { icon: 'fa fa-minus-square' },
        { icon: 'fa fa-minus-square-o' },
        { icon: 'fa fa-mobile' },
        { icon: 'fa fa-mobile-phone' },
        { icon: 'fa fa-money' },
        { icon: 'fa fa-moon-o' },
        { icon: 'fa fa-mortar-board' },
        { icon: 'fa fa-motorcycle' },
        { icon: 'fa fa-mouse-pointer' },
        { icon: 'fa fa-music' },
        { icon: 'fa fa-navicon' },
        { icon: 'fa fa-newspaper-o' },
        { icon: 'fa fa-object-group' },
        { icon: 'fa fa-object-ungroup' },
        { icon: 'fa fa-paint-brush' },
        { icon: 'fa fa-paper-plane' },
        { icon: 'fa fa-paper-plane-o' },
        { icon: 'fa fa-paw' },
        { icon: 'fa fa-pencil' },
        { icon: 'fa fa-pencil-square' },
        { icon: 'fa fa-pencil-square-o' },
        { icon: 'fa fa-percent' },
        { icon: 'fa fa-phone' },
        { icon: 'fa fa-phone-square' },
        { icon: 'fa fa-photo' },
        { icon: 'fa fa-picture-o' },
        { icon: 'fa fa-pie-chart' },
        { icon: 'fa fa-plane' },
        { icon: 'fa fa-plug' },
        { icon: 'fa fa-plus' },
        { icon: 'fa fa-plus-circle' },
        { icon: 'fa fa-plus-square' },
        { icon: 'fa fa-plus-square-o' },
        { icon: 'fa fa-podcast' },
        { icon: 'fa fa-power-off' },
        { icon: 'fa fa-print' },
        { icon: 'fa fa-puzzle-piece' },
        { icon: 'fa fa-qrcode' },
        { icon: 'fa fa-question' },
        { icon: 'fa fa-question-circle' },
        { icon: 'fa fa-question-circle-o' },
        { icon: 'fa fa-quote-left' },
        { icon: 'fa fa-quote-right' },
        { icon: 'fa fa-random' },
        { icon: 'fa fa-recycle' },
        { icon: 'fa fa-refresh' },
        { icon: 'fa fa-registered' },
        { icon: 'fa fa-remove' },
        { icon: 'fa fa-reorder' },
        { icon: 'fa fa-reply' },
        { icon: 'fa fa-reply-all' },
        { icon: 'fa fa-retweet' },
        { icon: 'fa fa-road' },
        { icon: 'fa fa-rocket' },
        { icon: 'fa fa-rss' },
        { icon: 'fa fa-rss-square' },
        { icon: 'fa fa-s15' },
        { icon: 'fa fa-search' },
        { icon: 'fa fa-search-minus' },
        { icon: 'fa fa-search-plus' },
        { icon: 'fa fa-send' },
        { icon: 'fa fa-send-o' },
        { icon: 'fa fa-server' },
        { icon: 'fa fa-share' },
        { icon: 'fa fa-share-alt' },
        { icon: 'fa fa-share-alt-square' },
        { icon: 'fa fa-share-square' },
        { icon: 'fa fa-share-square-o' },
        { icon: 'fa fa-shield' },
        { icon: 'fa fa-ship' },
        { icon: 'fa fa-shopping-bag' },
        { icon: 'fa fa-shopping-basket' },
        { icon: 'fa fa-shopping-cart' },
        { icon: 'fa fa-shower' },
        { icon: 'fa fa-sign-in' },
        { icon: 'fa fa-sign-out' },
        { icon: 'fa fa-sign-language' },
        { icon: 'fa fa-signal' },
        { icon: 'fa fa-signing' },
        { icon: 'fa fa-sitemap' },
        { icon: 'fa fa-sliders' },
        { icon: 'fa fa-smile-o' },
        { icon: 'fa fa-snowflake-o' },
        { icon: 'fa fa-soccer-ball-o' },
        { icon: 'fa fa-sort' },
        { icon: 'fa fa-sort-alpha-asc' },
        { icon: 'fa fa-sort-alpha-desc' },
        { icon: 'fa fa-sort-amount-asc' },
        { icon: 'fa fa-sort-amount-desc' },
        { icon: 'fa fa-sort-asc' },
        { icon: 'fa fa-sort-desc' },
        { icon: 'fa fa-sort-down' },
        { icon: 'fa fa-sort-numeric-asc' },
        { icon: 'fa fa-sort-numeric-desc' },
        { icon: 'fa fa-sort-up' },
        { icon: 'fa fa-space-shuttle' },
        { icon: 'fa fa-spinner' },
        { icon: 'fa fa-spoon' },
        { icon: 'fa fa-square' },
        { icon: 'fa fa-square-o' },
        { icon: 'fa fa-star' },
        { icon: 'fa fa-star-half' },
        { icon: 'fa fa-star-half-empty' },
        { icon: 'fa fa-star-half-full' },
        { icon: 'fa fa-star-half-o' },
        { icon: 'fa fa-star-o' },
        { icon: 'fa fa-sticky-note' },
        { icon: 'fa fa-sticky-note-o' },
        { icon: 'fa fa-street-view' },
        { icon: 'fa fa-suitcase' },
        { icon: 'fa fa-sun-o' },
        { icon: 'fa fa-support' },
        { icon: 'fa fa-tablet' },
        { icon: 'fa fa-tachometer' },
        { icon: 'fa fa-tag' },
        { icon: 'fa fa-tags' },
        { icon: 'fa fa-tasks' },
        { icon: 'fa fa-taxi' },
        { icon: 'fa fa-television' },
        { icon: 'fa fa-terminal' },
        { icon: 'fa fa-thermometer' },
        { icon: 'fa fa-thermometer-0' },
        { icon: 'fa fa-thermometer-1' },
        { icon: 'fa fa-thermometer-2' },
        { icon: 'fa fa-thermometer-3' },
        { icon: 'fa fa-thermometer-4' },
        { icon: 'fa fa-thermometer-empty' },
        { icon: 'fa fa-thermometer-full' },
        { icon: 'fa fa-thermometer-half' },
        { icon: 'fa fa-thermometer-quarter' },
        { icon: 'fa fa-thermometer-three-quarters' },
        { icon: 'fa fa-thumb-tack' },
        { icon: 'fa fa-thumbs-down' },
        { icon: 'fa fa-thumbs-o-up' },
        { icon: 'fa fa-thumbs-up' },
        { icon: 'fa fa-ticket' },
        { icon: 'fa fa-times' },
        { icon: 'fa fa-times-circle' },
        { icon: 'fa fa-times-circle-o' },
        { icon: 'fa fa-times-rectangle' },
        { icon: 'fa fa-times-rectangle-o' },
        { icon: 'fa fa-tint' },
        { icon: 'fa fa-toggle-down' },
        { icon: 'fa fa-toggle-left' },
        { icon: 'fa fa-toggle-right' },
        { icon: 'fa fa-toggle-up' },
        { icon: 'fa fa-toggle-off' },
        { icon: 'fa fa-toggle-on' },
        { icon: 'fa fa-trademark' },
        { icon: 'fa fa-trash' },
        { icon: 'fa fa-trash-o' },
        { icon: 'fa fa-tree' },
        { icon: 'fa fa-trophy' },
        { icon: 'fa fa-truck' },
        { icon: 'fa fa-tty' },
        { icon: 'fa fa-tv' },
        { icon: 'fa fa-umbrella' },
        { icon: 'fa fa-universal-access' },
        { icon: 'fa fa-university' },
        { icon: 'fa fa-unlock' },
        { icon: 'fa fa-unlock-alt' },
        { icon: 'fa fa-unsorted' },
        { icon: 'fa fa-upload' },
        { icon: 'fa fa-user' },
        { icon: 'fa fa-user-circle' },
        { icon: 'fa fa-user-circle-o' },
        { icon: 'fa fa-user-o' },
        { icon: 'fa fa-user-plus' },
        { icon: 'fa fa-user-secret' },
        { icon: 'fa fa-user-times' },
        { icon: 'fa fa-users' },
        { icon: 'fa fa-vcard' },
        { icon: 'fa fa-vcard-o' },
        { icon: 'fa fa-video-camera' },
        { icon: 'fa fa-volume-control-phone' },
        { icon: 'fa fa-volume-down' },
        { icon: 'fa fa-volume-off' },
        { icon: 'fa fa-volume-up' },
        { icon: 'fa fa-warning' },
        { icon: 'fa fa-wheelchair' },
        { icon: 'fa fa-wheelchair-alt' },
        { icon: 'fa fa-window-close' },
        { icon: 'fa fa-window-close-o' },
        { icon: 'fa fa-window-maximize' },
        { icon: 'fa fa-window-minimize' },
        { icon: 'fa fa-window-restore' },
        { icon: 'fa fa-wifi' },
        { icon: 'fa fa-wrenc' },
      ],
      backupIconData: [
        { icon: 'fa fa-address-book' },
        { icon: 'fa fa-address-book-o' },
        { icon: 'fa fa-address-card' },
        { icon: 'fa fa-address-card-o' },
        { icon: 'fa fa-adjust' },
        { icon: 'fa fa-american-sign-language-interpreting' },
        { icon: 'fa fa-anchor' },
        { icon: 'fa fa-archive' },
        { icon: 'fa fa-area-chart' },
        { icon: 'fa fa-arrows' },
        { icon: 'fa fa-arrows-h' },
        { icon: 'fa fa-arrows-v' },
        { icon: 'fa fa-asl-interpreting' },
        { icon: 'fa fa-assistive-listening-systems' },
        { icon: 'fa fa-asterisk' },
        { icon: 'fa fa-at' },
        { icon: 'fa fa-automobile' },
        { icon: 'fa fa-audio-description' },
        { icon: 'fa fa-balance-scale' },
        { icon: 'fa fa-ban' },
        { icon: 'fa fa-bank' },
        { icon: 'fa fa-bar-chart' },
        { icon: 'fa fa-bar-chart-o' },
        { icon: 'fa fa-barcode' },
        { icon: 'fa fa-bars' },
        { icon: 'fa fa-bath' },
        { icon: 'fa fa-bathtub' },
        { icon: 'fa fa-battery-0' },
        { icon: 'fa fa-battery-1' },
        { icon: 'fa fa-battery-2' },
        { icon: 'fa fa-battery-3' },
        { icon: 'fa fa-battery-4' },
        { icon: 'fa fa-battery-empty' },
        { icon: 'fa fa-battery-full' },
        { icon: 'fa fa-battery-half' },
        { icon: 'fa fa-battery-quarter' },
        { icon: 'fa fa-battery-three-quarters' },
        { icon: 'fa fa-bed' },
        { icon: 'fa fa-beer' },
        { icon: 'fa fa-bell' },
        { icon: 'fa fa-bell-o' },
        { icon: 'fa fa-bell-slash' },
        { icon: 'fa fa-bell-slash-o' },
        { icon: 'fa fa-bicycle' },
        { icon: 'fa fa-binoculars' },
        { icon: 'fa fa-birthday-cake' },
        { icon: 'fa fa-blind' },
        { icon: 'fa fa-bolt' },
        { icon: 'fa fa-bomb' },
        { icon: 'fa fa-book' },
        { icon: 'fa fa-bookmark' },
        { icon: 'fa fa-bookmark-o' },
        { icon: 'fa fa-braille' },
        { icon: 'fa fa-briefcase' },
        { icon: 'fa fa-bug' },
        { icon: 'fa fa-building' },
        { icon: 'fa fa-building-o' },
        { icon: 'fa fa-bullhorn' },
        { icon: 'fa fa-bullseye' },
        { icon: 'fa fa-bus' },
        { icon: 'fa fa-cab' },
        { icon: 'fa fa-calculator' },
        { icon: 'fa fa-calendar' },
        { icon: 'fa fa-calendar-o' },
        { icon: 'fa fa-calendar-check-o' },
        { icon: 'fa fa-calendar-minus-o' },
        { icon: 'fa fa-calendar-plus-o' },
        { icon: 'fa fa-calendar-times-o' },
        { icon: 'fa fa-camera' },
        { icon: 'fa fa-camera-retro' },
        { icon: 'fa fa-car' },
        { icon: 'fa fa-caret-square-o-down' },
        { icon: 'fa fa-caret-square-o-left' },
        { icon: 'fa fa-caret-square-o-right' },
        { icon: 'fa fa-caret-square-o-up' },
        { icon: 'fa fa-cart-arrow-down' },
        { icon: 'fa fa-cart-plus' },
        { icon: 'fa fa-cc' },
        { icon: 'fa fa-certificate' },
        { icon: 'fa fa-check' },
        { icon: 'fa fa-check-circle' },
        { icon: 'fa fa-check-circle-o' },
        { icon: 'fa fa-check-square' },
        { icon: 'fa fa-check-square-o' },
        { icon: 'fa fa-child' },
        { icon: 'fa fa-circle' },
        { icon: 'fa fa-circle-o' },
        { icon: 'fa fa-circle-o-notch' },
        { icon: 'fa fa-circle-thin' },
        { icon: 'fa fa-clock-o' },
        { icon: 'fa fa-clone' },
        { icon: 'fa fa-close' },
        { icon: 'fa fa-cloud' },
        { icon: 'fa fa-cloud-download' },
        { icon: 'fa fa-cloud-upload' },
        { icon: 'fa fa-code' },
        { icon: 'fa fa-code-fork' },
        { icon: 'fa fa-coffee' },
        { icon: 'fa fa-cog' },
        { icon: 'fa fa-cogs' },
        { icon: 'fa fa-comment' },
        { icon: 'fa fa-comment-o' },
        { icon: 'fa fa-comments' },
        { icon: 'fa fa-comments-o' },
        { icon: 'fa fa-commenting' },
        { icon: 'fa fa-commenting-o' },
        { icon: 'fa fa-compass' },
        { icon: 'fa fa-copyright' },
        { icon: 'fa fa-credit-card' },
        { icon: 'fa fa-credit-card-alt' },
        { icon: 'fa fa-creative-commons' },
        { icon: 'fa fa-crop' },
        { icon: 'fa fa-crosshairs' },
        { icon: 'fa fa-cube' },
        { icon: 'fa fa-cubes' },
        { icon: 'fa fa-cutlery' },
        { icon: 'fa fa-dashboard' },
        { icon: 'fa fa-database' },
        { icon: 'fa fa-deaf' },
        { icon: 'fa fa-deafness' },
        { icon: 'fa fa-desktop' },
        { icon: 'fa fa-diamond' },
        { icon: 'fa fa-dot-circle-o' },
        { icon: 'fa fa-download' },
        { icon: 'fa fa-drivers-license' },
        { icon: 'fa fa-drivers-license-o' },
        { icon: 'fa fa-edit' },
        { icon: 'fa fa-ellipsis-h' },
        { icon: 'fa fa-ellipsis-v' },
        { icon: 'fa fa-envelope' },
        { icon: 'fa fa-envelope-o' },
        { icon: 'fa fa-envelope-open' },
        { icon: 'fa fa-envelope-open-o' },
        { icon: 'fa fa-envelope-square' },
        { icon: 'fa fa-eraser' },
        { icon: 'fa fa-exchange' },
        { icon: 'fa fa-exclamation' },
        { icon: 'fa fa-exclamation-circle' },
        { icon: 'fa fa-exclamation-triangle' },
        { icon: 'fa fa-external-link' },
        { icon: 'fa fa-external-link-square' },
        { icon: 'fa fa-eye' },
        { icon: 'fa fa-eye-slash' },
        { icon: 'fa fa-eyedropper' },
        { icon: 'fa fa-fax' },
        { icon: 'fa fa-female' },
        { icon: 'fa fa-fighter-jet' },
        { icon: 'fa fa-file-archive-o' },
        { icon: 'fa fa-file-audio-o' },
        { icon: 'fa fa-file-code-o' },
        { icon: 'fa fa-file-excel-o' },
        { icon: 'fa fa-file-image-o' },
        { icon: 'fa fa-file-movie-o' },
        { icon: 'fa fa-file-pdf-o' },
        { icon: 'fa fa-file-photo-o' },
        { icon: 'fa fa-file-picture-o' },
        { icon: 'fa fa-file-powerpoint-o' },
        { icon: 'fa fa-file-sound-o' },
        { icon: 'fa fa-file-video-o' },
        { icon: 'fa fa-file-word-o' },
        { icon: 'fa fa-file-zip-o' },
        { icon: 'fa fa-film' },
        { icon: 'fa fa-filter' },
        { icon: 'fa fa-fire' },
        { icon: 'fa fa-fire-extinguisher' },
        { icon: 'fa fa-flag' },
        { icon: 'fa fa-flag-checkered' },
        { icon: 'fa fa-flag-o' },
        { icon: 'fa fa-flash' },
        { icon: 'fa fa-flask' },
        { icon: 'fa fa-folder' },
        { icon: 'fa fa-folder-o' },
        { icon: 'fa fa-folder-open' },
        { icon: 'fa fa-folder-open-o' },
        { icon: 'fa fa-frown-o' },
        { icon: 'fa fa-futbol-o' },
        { icon: 'fa fa-gamepad' },
        { icon: 'fa fa-gavel' },
        { icon: 'fa fa-gear' },
        { icon: 'fa fa-gears' },
        { icon: 'fa fa-genderless' },
        { icon: 'fa fa-gift' },
        { icon: 'fa fa-glass' },
        { icon: 'fa fa-globe' },
        { icon: 'fa fa-graduation-cap' },
        { icon: 'fa fa-group' },
        { icon: 'fa fa-hard-of-hearing' },
        { icon: 'fa fa-hdd-o' },
        { icon: 'fa fa-handshake-o' },
        { icon: 'fa fa-hashtag' },
        { icon: 'fa fa-headphones' },
        { icon: 'fa fa-heart' },
        { icon: 'fa fa-heart-o' },
        { icon: 'fa fa-heartbeat' },
        { icon: 'fa fa-history' },
        { icon: 'fa fa-home' },
        { icon: 'fa fa-hotel' },
        { icon: 'fa fa-hourglass' },
        { icon: 'fa fa-hourglass-1' },
        { icon: 'fa fa-hourglass-2' },
        { icon: 'fa fa-hourglass-3' },
        { icon: 'fa fa-hourglass-end' },
        { icon: 'fa fa-hourglass-half' },
        { icon: 'fa fa-hourglass-o' },
        { icon: 'fa fa-hourglass-start' },
        { icon: 'fa fa-i-cursor' },
        { icon: 'fa fa-id-badge' },
        { icon: 'fa fa-id-card' },
        { icon: 'fa fa-id-card-o' },
        { icon: 'fa fa-image' },
        { icon: 'fa fa-inbox' },
        { icon: 'fa fa-industry' },
        { icon: 'fa fa-info' },
        { icon: 'fa fa-info-circle' },
        { icon: 'fa fa-institution' },
        { icon: 'fa fa-key' },
        { icon: 'fa fa-keyboard-o' },
        { icon: 'fa fa-language' },
        { icon: 'fa fa-laptop' },
        { icon: 'fa fa-leaf' },
        { icon: 'fa fa-legal' },
        { icon: 'fa fa-lemon-o' },
        { icon: 'fa fa-level-down' },
        { icon: 'fa fa-level-up' },
        { icon: 'fa fa-life-bouy' },
        { icon: 'fa fa-life-buoy' },
        { icon: 'fa fa-life-ring' },
        { icon: 'fa fa-life-saver' },
        { icon: 'fa fa-lightbulb-o' },
        { icon: 'fa fa-line-chart' },
        { icon: 'fa fa-location-arrow' },
        { icon: 'fa fa-lock' },
        { icon: 'fa fa-low-vision' },
        { icon: 'fa fa-magic' },
        { icon: 'fa fa-magnet' },
        { icon: 'fa fa-mail-forward' },
        { icon: 'fa fa-mail-reply' },
        { icon: 'fa fa-mail-reply-all' },
        { icon: 'fa fa-male' },
        { icon: 'fa fa-map' },
        { icon: 'fa fa-map-o' },
        { icon: 'fa fa-map-pin' },
        { icon: 'fa fa-map-signs' },
        { icon: 'fa fa-map-marker' },
        { icon: 'fa fa-meh-o' },
        { icon: 'fa fa-microchip' },
        { icon: 'fa fa-microphone' },
        { icon: 'fa fa-microphone-slash' },
        { icon: 'fa fa-minus' },
        { icon: 'fa fa-minus-circle' },
        { icon: 'fa fa-minus-square' },
        { icon: 'fa fa-minus-square-o' },
        { icon: 'fa fa-mobile' },
        { icon: 'fa fa-mobile-phone' },
        { icon: 'fa fa-money' },
        { icon: 'fa fa-moon-o' },
        { icon: 'fa fa-mortar-board' },
        { icon: 'fa fa-motorcycle' },
        { icon: 'fa fa-mouse-pointer' },
        { icon: 'fa fa-music' },
        { icon: 'fa fa-navicon' },
        { icon: 'fa fa-newspaper-o' },
        { icon: 'fa fa-object-group' },
        { icon: 'fa fa-object-ungroup' },
        { icon: 'fa fa-paint-brush' },
        { icon: 'fa fa-paper-plane' },
        { icon: 'fa fa-paper-plane-o' },
        { icon: 'fa fa-paw' },
        { icon: 'fa fa-pencil' },
        { icon: 'fa fa-pencil-square' },
        { icon: 'fa fa-pencil-square-o' },
        { icon: 'fa fa-percent' },
        { icon: 'fa fa-phone' },
        { icon: 'fa fa-phone-square' },
        { icon: 'fa fa-photo' },
        { icon: 'fa fa-picture-o' },
        { icon: 'fa fa-pie-chart' },
        { icon: 'fa fa-plane' },
        { icon: 'fa fa-plug' },
        { icon: 'fa fa-plus' },
        { icon: 'fa fa-plus-circle' },
        { icon: 'fa fa-plus-square' },
        { icon: 'fa fa-plus-square-o' },
        { icon: 'fa fa-podcast' },
        { icon: 'fa fa-power-off' },
        { icon: 'fa fa-print' },
        { icon: 'fa fa-puzzle-piece' },
        { icon: 'fa fa-qrcode' },
        { icon: 'fa fa-question' },
        { icon: 'fa fa-question-circle' },
        { icon: 'fa fa-question-circle-o' },
        { icon: 'fa fa-quote-left' },
        { icon: 'fa fa-quote-right' },
        { icon: 'fa fa-random' },
        { icon: 'fa fa-recycle' },
        { icon: 'fa fa-refresh' },
        { icon: 'fa fa-registered' },
        { icon: 'fa fa-remove' },
        { icon: 'fa fa-reorder' },
        { icon: 'fa fa-reply' },
        { icon: 'fa fa-reply-all' },
        { icon: 'fa fa-retweet' },
        { icon: 'fa fa-road' },
        { icon: 'fa fa-rocket' },
        { icon: 'fa fa-rss' },
        { icon: 'fa fa-rss-square' },
        { icon: 'fa fa-s15' },
        { icon: 'fa fa-search' },
        { icon: 'fa fa-search-minus' },
        { icon: 'fa fa-search-plus' },
        { icon: 'fa fa-send' },
        { icon: 'fa fa-send-o' },
        { icon: 'fa fa-server' },
        { icon: 'fa fa-share' },
        { icon: 'fa fa-share-alt' },
        { icon: 'fa fa-share-alt-square' },
        { icon: 'fa fa-share-square' },
        { icon: 'fa fa-share-square-o' },
        { icon: 'fa fa-shield' },
        { icon: 'fa fa-ship' },
        { icon: 'fa fa-shopping-bag' },
        { icon: 'fa fa-shopping-basket' },
        { icon: 'fa fa-shopping-cart' },
        { icon: 'fa fa-shower' },
        { icon: 'fa fa-sign-in' },
        { icon: 'fa fa-sign-out' },
        { icon: 'fa fa-sign-language' },
        { icon: 'fa fa-signal' },
        { icon: 'fa fa-signing' },
        { icon: 'fa fa-sitemap' },
        { icon: 'fa fa-sliders' },
        { icon: 'fa fa-smile-o' },
        { icon: 'fa fa-snowflake-o' },
        { icon: 'fa fa-soccer-ball-o' },
        { icon: 'fa fa-sort' },
        { icon: 'fa fa-sort-alpha-asc' },
        { icon: 'fa fa-sort-alpha-desc' },
        { icon: 'fa fa-sort-amount-asc' },
        { icon: 'fa fa-sort-amount-desc' },
        { icon: 'fa fa-sort-asc' },
        { icon: 'fa fa-sort-desc' },
        { icon: 'fa fa-sort-down' },
        { icon: 'fa fa-sort-numeric-asc' },
        { icon: 'fa fa-sort-numeric-desc' },
        { icon: 'fa fa-sort-up' },
        { icon: 'fa fa-space-shuttle' },
        { icon: 'fa fa-spinner' },
        { icon: 'fa fa-spoon' },
        { icon: 'fa fa-square' },
        { icon: 'fa fa-square-o' },
        { icon: 'fa fa-star' },
        { icon: 'fa fa-star-half' },
        { icon: 'fa fa-star-half-empty' },
        { icon: 'fa fa-star-half-full' },
        { icon: 'fa fa-star-half-o' },
        { icon: 'fa fa-star-o' },
        { icon: 'fa fa-sticky-note' },
        { icon: 'fa fa-sticky-note-o' },
        { icon: 'fa fa-street-view' },
        { icon: 'fa fa-suitcase' },
        { icon: 'fa fa-sun-o' },
        { icon: 'fa fa-support' },
        { icon: 'fa fa-tablet' },
        { icon: 'fa fa-tachometer' },
        { icon: 'fa fa-tag' },
        { icon: 'fa fa-tags' },
        { icon: 'fa fa-tasks' },
        { icon: 'fa fa-taxi' },
        { icon: 'fa fa-television' },
        { icon: 'fa fa-terminal' },
        { icon: 'fa fa-thermometer' },
        { icon: 'fa fa-thermometer-0' },
        { icon: 'fa fa-thermometer-1' },
        { icon: 'fa fa-thermometer-2' },
        { icon: 'fa fa-thermometer-3' },
        { icon: 'fa fa-thermometer-4' },
        { icon: 'fa fa-thermometer-empty' },
        { icon: 'fa fa-thermometer-full' },
        { icon: 'fa fa-thermometer-half' },
        { icon: 'fa fa-thermometer-quarter' },
        { icon: 'fa fa-thermometer-three-quarters' },
        { icon: 'fa fa-thumb-tack' },
        { icon: 'fa fa-thumbs-down' },
        { icon: 'fa fa-thumbs-o-up' },
        { icon: 'fa fa-thumbs-up' },
        { icon: 'fa fa-ticket' },
        { icon: 'fa fa-times' },
        { icon: 'fa fa-times-circle' },
        { icon: 'fa fa-times-circle-o' },
        { icon: 'fa fa-times-rectangle' },
        { icon: 'fa fa-times-rectangle-o' },
        { icon: 'fa fa-tint' },
        { icon: 'fa fa-toggle-down' },
        { icon: 'fa fa-toggle-left' },
        { icon: 'fa fa-toggle-right' },
        { icon: 'fa fa-toggle-up' },
        { icon: 'fa fa-toggle-off' },
        { icon: 'fa fa-toggle-on' },
        { icon: 'fa fa-trademark' },
        { icon: 'fa fa-trash' },
        { icon: 'fa fa-trash-o' },
        { icon: 'fa fa-tree' },
        { icon: 'fa fa-trophy' },
        { icon: 'fa fa-truck' },
        { icon: 'fa fa-tty' },
        { icon: 'fa fa-tv' },
        { icon: 'fa fa-umbrella' },
        { icon: 'fa fa-universal-access' },
        { icon: 'fa fa-university' },
        { icon: 'fa fa-unlock' },
        { icon: 'fa fa-unlock-alt' },
        { icon: 'fa fa-unsorted' },
        { icon: 'fa fa-upload' },
        { icon: 'fa fa-user' },
        { icon: 'fa fa-user-circle' },
        { icon: 'fa fa-user-circle-o' },
        { icon: 'fa fa-user-o' },
        { icon: 'fa fa-user-plus' },
        { icon: 'fa fa-user-secret' },
        { icon: 'fa fa-user-times' },
        { icon: 'fa fa-users' },
        { icon: 'fa fa-vcard' },
        { icon: 'fa fa-vcard-o' },
        { icon: 'fa fa-video-camera' },
        { icon: 'fa fa-volume-control-phone' },
        { icon: 'fa fa-volume-down' },
        { icon: 'fa fa-volume-off' },
        { icon: 'fa fa-volume-up' },
        { icon: 'fa fa-warning' },
        { icon: 'fa fa-wheelchair' },
        { icon: 'fa fa-wheelchair-alt' },
        { icon: 'fa fa-window-close' },
        { icon: 'fa fa-window-close-o' },
        { icon: 'fa fa-window-maximize' },
        { icon: 'fa fa-window-minimize' },
        { icon: 'fa fa-window-restore' },
        { icon: 'fa fa-wifi' },
        { icon: 'fa fa-wrenc' },
      ],
    }
  },
  methods: {
    moment,
    selectIcon(record) {
      this.modalInput.icon = record.icon
      this.selectIconModalVisible = false
      this.iconModalSearchInput = ''
      this.iconData = this.$g.clone(this.backupIconData)
    },
    filterIcon() {
      var dic = this.$g.clone(this.backupIconData)
      this.iconData = dic.filter(x => x.icon.includes(this.iconModalSearchInput))
    },
    showModal(mode = 'create', id) {
      // console.log('this.pagesList', this.pagesList)
      // console.log('id', id)
      this.formvisible = true
      if (mode === 'create') {
        this.modalInput = {
          app_Id: this.appId,
          menu_name: '',
          key: '',
          route: '',
          url: '',
          icon: '',
          urutan: '',
          level: '',
          parent: '',
          isexclude: false,
          caption: '',
        }
        this.modalaction = 'create'
      } else if (mode === 'update') {
        var tindex = this.pagesList.findIndex(x => x.id === id)
        // console.log('tindex', tindex)
        var tr = this.pagesList[tindex]
        // console.log('tr', tr)
        this.modalInput = {
          ...tr,
          app_Id: this.appId,
          isexclude: tr.isexclude === 1,
        }
        // console.log('tr.isexclude', tr.isexclude)
        // console.log('this.modalInput.isexclude', this.modalInput.isexclude)
        this.modalaction = 'update'
      } else {
        var tindex1 = this.pagesList.findIndex(x => x.id === id)
        // console.log('tindex1', tindex1)
        var tr1 = this.pagesList[tindex1]
        this.modalInput = {
          app_Id: this.appId,
          menu_name: '',
          key: tr1.key,
          route: '',
          url: '',
          icon: '',
          urutan: tr1.children !== undefined && tr1.children !== null ? tr1.children.length + 1 : '1',
          level: tr1.level !== null && tr1.level !== '' ? tr1.level + 1 : '',
          parent: tr1.id,
          isexclude: false,
          caption: '',
        }
        this.modalaction = 'create'
      }
    },
    handleCancel() {
      this.formvisible = false
      this.modalInput = {
        app_Id: this.appId,
        menu_name: '',
        key: '',
        route: '',
        url: '',
        icon: '',
        urutan: '',
        level: '',
        parent: '',
        isexclude: false,
        caption: '',
      }
    },
    onContextMenuClick(treeKey, menuKey) {
      console.log(`treeKey: ${treeKey}, menuKey: ${menuKey}`)
    },
    async handleSubmit() {
      var fd = this.$g.clone(this.modalInput)
      fd.isexclude = fd.isexclude ? 1 : 0
      fd.parent = fd.parent !== '' ? fd.parent : 0
      // console.log('fd', fd)
      var res = {}
      if (this.modalaction === 'create') {
        // console.log('fd', fd)
        res = await lou.customUrlPost2('manage/permission', fd, true, true)
      } else {
        res = await lou.customUrlPut2('manage/permission', fd, true, true)
        // console.log('fd', fd)
      }
      if (res) {
        this.handleCancel()
        this.getData()
      }
    },
    async confirmationDelete(id) {
      Modal.confirm({
        title: 'Are you sure delete this data?',
        content: "You can't prevent this!!! ",
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'No',
        onOk: async () => {
          await lou.customUrlDelete2('manage/permission/' + id, true, true)
          this.getData()
        },
        onCancel: () => {
          // console.log('Cancel')
        },
      })
    },

    async getMaster() {
      var res = await lou.customUrlGet('manage/app', false, true)
      if (res) {
        this.dataapplist = this.$g.clone(res.data)
        if (this.dataapplist.length !== 0) {
          this.appId = this.dataapplist[0].id
          this.getData()
        }
      }
    },
    async getData() {
      var res = await lou.customUrlGet(
        'manage/arrange/' + this.appId,
        false,
        true,
      )
      if (res) {
        var ndata = []
        res.data.forEach((element) => {
          if (element.children === undefined || element.children === null) {
            element.children = []
          }
          ndata.push(element)
        })
        // console.log('ndata', ndata)
        this.treeData = this.$g.clone(ndata)

        const pagesList = () => {
        // console.log('this.menuData', this.menuData)
          const menuData = this.treeData
          const _menuData = JSON.parse(JSON.stringify(menuData))
          const flattenItems = (items, key) =>
            items.reduce((flattenedItems, item) => {
              // console.log('key', key)
              if (item.category) {
                return flattenedItems
              }
              if (item.key === 'nestedItem1' || item.disabled) {
              // skip unwanted items
                return flattenedItems
              }
              // console.log('item[key]', item[key])
              if (item[key] !== null) {
                // console.log('1items', item)
                // console.log('items', items)
                if (item[key].length !== 0) {
                  flattenedItems.push(item)
                  const items = item[key].map((child) => {
                    child.icon = item.icon
                    return child
                  })
                  // console.log('flattenedItems', flattenedItems)
                  // console.log('key', key)
                  // flattenItems.push(item)
                  return flattenedItems.concat(flattenItems(items, key))
                }
              }
              // console.log('item', item)
              flattenedItems.push(item)
              return flattenedItems
            }, [])
          // console.log('_menuData', _menuData)
          return flattenItems(_menuData, 'children')
        }
        this.pagesList = pagesList()
        // console.log('this.treeData', this.treeData)
      }
    },

    onDrop(info) {
      // console.log('info', info)
      const dragKeyFromIndex = info.dragNode.eventKey
      const dropKeyToIndex = info.node.eventKey
      const dropPos = info.node.pos.split('-')
      const dropPosition =
        info.dropPosition - Number(dropPos[dropPos.length - 1])
      // console.log(dropKeyToIndex, dragKeyFromIndex)

      // Helper get target data
      const loop = (data, key, callback) => {
        data.forEach((item, index, arr) => {
          if (item.id === key) {
            return callback(item, index, arr)
          }
          if (item.children) {
            return loop(item.children, key, callback)
          }
        })
      }
      const data = [...this.treeData]

      // Find dragObject
      let dragObj
      loop(data, dragKeyFromIndex, (item, index, arr) => {
        arr.splice(index, 1)
        dragObj = item
      })
      if (!info.dropToGap) {
        // Drop to data
        loop(data, dropKeyToIndex, (item) => {
          item.children = item.children || []
          // Tujuan insert
          // console.log('dropPosition', dropPosition)
          // console.log('item.id', item)
          // console.log('dragObj', dragObj)
          item.children.push(dragObj)
        })
      } else if (
        (info.node.children || []).length > 0 &&
        info.node.expanded &&
        dropPosition === 1 // Dibagian bawah
      ) {
        loop(data, dropKeyToIndex, (item) => {
          item.children = item.children || []
          // Tujuan insert
          item.children.unshift(dragObj)
        })
      } else {
        let ar
        let i
        loop(data, dropKeyToIndex, (item, index, arr) => {
          ar = arr
          i = index
        })
        // console.log('ar', ar)
        if (dropPosition === -1) {
          ar.splice(i, 0, dragObj)
        } else {
          ar.splice(i + 1, 0, dragObj)
        }
      }
      // console.log('dropPos', dropPos)
      // console.log('dropPosition', dropPosition)
      this.treeData = this.$g.clone(data)
    },
    onDragEnter(info) {
      // console.log('info', info)
    },
    testarrangePermissions() {
      this.modalVisible = true
      // this.arrangePermissions()
      // console.log('this.modifiedTreeData', this.modifiedTreeData)
      // this.modifiedTreeData.forEach((element, index) => {
      //   console.log(index, element.urutan)
      // })
    },
    arrangePermissions() {
      var npermissions = this.$g.clone(this.treeData)
      var sortchildren = (data, parentid) => {
        data.forEach((element, index) => {
          var nurutan = index + 1
          element.parent = parentid
          element.urutan = nurutan
          if (
            element.children !== undefined &&
            element.children !== null &&
            element.children.length !== 0
          ) {
            var nchildren = sortchildren(element.children, element.id)
            element.children = nchildren
          }
        })
        return data
      }
      npermissions.forEach((element, index) => {
        var nurutan = index + 1
        element.parent = 0
        element.urutan = nurutan
        if (element.children !== null && element.children !== undefined) {
          if (element.children.length !== 0) {
            var nchildren = sortchildren(element.children, element.id)
            element.children = nchildren
          }
        }
      })
      this.modifiedTreeData = this.$g.clone(npermissions)
    },
    arrangePermissionsClone() {
      var npermissions = this.$g.clone(this.treeData)
      var apptarget = this.dataapplist.findIndex(x => x.id === this.tujuanappid)
      var sortchildren = (data, parentid) => {
        data.forEach((element, index) => {
          var nurutan = index + 1
          element.parent = parentid
          element.urutan = nurutan
          var appname = this.dataapplist[apptarget].appName
          appname = appname.replaceAll(' ', '')
          var newkey = appname.toLowerCase() + '-' + element.key
          element.key = newkey
          // console.log('element.key', element.key)
          if (
            element.children !== undefined &&
            element.children !== null &&
            element.children.length !== 0
          ) {
            var nchildren = sortchildren(element.children, element.id)
            element.children = nchildren
          }
        })
        return data
      }
      npermissions.forEach((element, index) => {
        var nurutan = index + 1
        element.parent = 0
        element.urutan = nurutan
        var appname = this.dataapplist[apptarget].appName
        appname = appname.replaceAll(' ', '')
        var newkey = appname.toLowerCase() + '-' + element.key
        element.key = newkey
        // console.log('element.key', element.key)
        if (element.children !== null && element.children !== undefined) {
          if (element.children.length !== 0) {
            var nchildren = sortchildren(element.children, element.id)
            element.children = nchildren
          }
        }
      })
      this.modifiedTreeDataClone = this.$g.clone(npermissions)
    },
    async savechanges() {
      this.arrangePermissions()
      if (this.showjsonincl) {
        console.log('JSON', JSON.stringify(this.modifiedTreeData))
      }
      var res = await lou.customUrlPost('manage/arrange', this.modifiedTreeData, true, true)
      if (res) {
        this.getData()
      }
    },
    async cloneAppData() {
      this.arrangePermissionsClone()
      if (this.showjsonincl) {
        console.log('this.modifiedTreeData', JSON.stringify(this.modifiedTreeDataClone))
      }
      var res = await lou.customUrlPost('manage/arrange/clone/' + this.tujuanappid, this.modifiedTreeDataClone, true, true)
      if (res) {
        this.modalVisible = false
        this.appId = this.tujuanappid
        this.getData()
        this.tujuanappid = null
      }
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      )
    },
  },
}
</script>

<style></style>
