<template>
  <div>
    <div class="row">
      <!-- <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
        <div class="row">

      <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4">
        <a-input placeholder="Gunakan 0 bukan 62 Ex: 085xxxxxx115" v-model="nomor" @keydown.native.enter="checkNomer"></a-input>
      </div>
      <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2">
        <a-button class="btn btn-outline-primary" @click="checkNomer">Cari</a-button>
      </div>
        </div>
      </div> -->
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-2">
        <strong>Contact List</strong>
        <a-table
          class="mt-2"
          size="small"
          :columns="columnDefs"
          :dataSource="dataDefs"
          :scroll="{ y: 300 }"
          :pagination="{
            defaultPageSize: 200,
            showQuickJumper: true,
            showSizeChanger: true,
            showTotal: (total) => `Total ${total} items`,
            pageSizeOptions: ['10', '20', '30'],
            hideOnSinglePage: true,
          }"
        >
          <span slot="action" slot-scope="text, record">
            <!-- <a
                href="javascript:void(0)"
                @click="sendToForm('Update', record)"
                class="text-warning"
                >Edit</a
              >
              <a-divider type="vertical" v-if="index !== 0" /> -->
            <a href="javascript:void(0)" @click="showProfilePicture(record)" class="text-primary">
              Show Profile Picture
              <!-- <a-icon type="stop" /> -->
            </a>
          </span>
        </a-table>
      </div>
    </div>
    <zoomgambar ref="zoomgambar" />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import * as lou from '@/services/data/lou'
import moment from 'moment'
import zoomgambar from '@/components/lou/zoomgambar.vue'

export default {
  components: {
    zoomgambar,
  },
  computed: {
    ...mapState(['settings', 'user']),
  },
  data() {
    return {
      sessionKey: '',
      nomor: '',
      columnDefs: [
        {
          title: '#',
          scopedSlots: {
            customRender: 'action',
          },
          // width: 50,
        },
        // {
        //   title: 'pkId',
        //   dataIndex: 'pkId',
        //   // class: 'bg-transparent text-gray-6',
        // },
        // {
        //   title: 'sessionId',
        //   dataIndex: 'sessionId',
        //   // class: 'bg-transparent text-gray-6',
        // },
        {
          title: 'name',
          dataIndex: 'name',
          // class: 'bg-transparent text-gray-6',
        },
        {
          title: 'Key',
          dataIndex: 'id',
          // class: 'bg-transparent text-gray-6',
        },
        // {
        //   title: 'notify',
        //   dataIndex: 'notify',
        //   // class: 'bg-transparent text-gray-6',
        // },
        // {
        //   title: 'verifiedName',
        //   dataIndex: 'verifiedName',
        //   // class: 'bg-transparent text-gray-6',
        // },
        // {
        //   title: 'imgUrl',
        //   dataIndex: 'imgUrl',
        //   // class: 'bg-transparent text-gray-6',
        // },
        // {
        //   title: 'status',
        //   dataIndex: 'status',
        //   // class: 'bg-transparent text-gray-6',
        // },
      ],
      dataDefs: [],
    }
  },
  created () {
    this.checkActiveSessionsFromApi()
    this.sessionKey = this.user.activeSessions
    this.checkStatusSessions()
  },
  methods: {
    moment,
    async checkActiveSessionsFromApi() {
      var res = await lou.customUrlGet2('sessions/active')
      if (res) {
        this.user.activeSessions = res.sessionId
      }
    },
    async checkStatusSessions() {
      var res = await lou.customUrlGet2(
        'sessions/' + this.sessionKey + '/status',
      )
      if (res) {
        if (res.status === 'CONNECTED') {
          var delet = await lou.customUrlDelete2(
            'sessions/' + this.sessionKey,
          )
          // console.log('delet', delet)
          lou.shownotif('Error', 'Sesi belum connect dengan whatsapp', 'error')
        } else if (res.status === 'AUTHENTICATED') {
          this.getList()
        }
      } else {
        lou.shownotif('Error', 'Sesi tidak ada atau belum connect dengan whatsapp', 'error')
      }
    },
    async showProfilePicture(record) {
      var res = await lou.customUrlGet2(this.sessionKey + '/groups/' + record.id + '/photo')
      if (res) {
        var obj = { img_url: res.url }
        // console.log('obj', obj)
        this.$refs.zoomgambar.lihatgambar(obj, 'img_url')
      }
    },
    async checkNomer() {
      var res = await lou.customUrlGet2(this.sessionKey + '/contacts/' + this.nomor)
      // var res = await lou.customUrlGet2(this.sessionKey + '/chats')
      if (res) {
        if (res.exists) {
          this.$message.success('Nomor hp ada.')
        } else {
          this.$message.error('Nomor hp tidak ada.')
        }
        // this.dataDefs = res.data
      }
    },
    async getList() {
      var res = await lou.customUrlGet2(this.sessionKey + '/groups')
      // var res = await lou.customUrlGet2(this.sessionKey + '/chats')
      if (res) {
        console.log('res', res)
        this.dataDefs = res.data
      }
    },
    async blockedOrUnblocked(item, action = 'block') {
      var res = await lou.customUrlPost2(item.sessionId + '/contacts/blocklist/update', {
        jid: item.id,
        action: action,
      })
      // var res = await lou.customUrlGet2(this.sessionKey + '/chats')
      if (res) {
        if (action === 'unblock') {
          this.$message.success('The Contact Has Been Unblocked.')
        } else {
          this.$message.error(res.message)
        }
        this.getList()
        // this.dataDefs = res.data
      }
    },
  },
}
</script>

<style lang="scss" scoped></style>
