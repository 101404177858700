<template>
  <div>
    <a-modal
      v-model="visible"
      :title="action + ' Bank App'"
      :dialogStyle="{ top: '10px' }"
      :destroyOnClose="true"
    >
      <template slot="footer">
        <a-button class="btn btn-outline-dark" @click="handleCancel">
          Cancel
        </a-button>
        <a-button
          class="btn btn-outline-primary"
          :loading="loading"
          @click="submit('ruleForm')"
        >
          Submit
        </a-button>
      </template>
      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12">
          <a-form-model
            ref="ruleForm"
            :model="form"
            :rules="rules"
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
          >
            <a-form-model-item
              style="margin: unset;"
              ref="sandibpr"
              label="sandibpr"
              prop="sandibpr"
            >
              <a-input
                v-model="form.sandibpr"
                @blur="
                  () => {
                    $refs.sandibpr.onFieldBlur();
                  }
                "
              />
            </a-form-model-item>
            <a-form-model-item
              style="margin: unset;"
              ref="namabpr"
              label="namabpr"
              prop="namabpr"
            >
              <a-input
                v-model="form.namabpr"
                @blur="
                  () => {
                    $refs.namabpr.onFieldBlur();
                  }
                "
              />
            </a-form-model-item>
            <a-form-model-item
              style="margin: unset;"
              ref="periode"
              label="periode"
              prop="periode"
            >
              <a-date-picker format='YYYY-MM-DD' v-model="form.periode" @change="(e, a) => selectDate(e, a, 'periode')"/>
            </a-form-model-item>
            <a-form-model-item
              style="margin: unset;"
              ref="selectAppLists"
              label="Bank App"
              prop="selectAppLists"
            >
              <a-select v-model="form.selectedAppLists" mode="multiple" @change="userAppSelect">
                <a-select-option
                  v-for="data in dataapplist"
                  :key="data.id"
                  :value="data.id"
                  >{{ data.appName }}</a-select-option
                >
              </a-select>
            </a-form-model-item>
          </a-form-model>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import * as lou from '@/services/data/lou'
import moment from 'moment'

export default {
  name: 'editprofile',
  created() {
    // this.getMaster()
  },
  data() {
    // const validatePass = (rule, value, callback) => {
    //   // if (this.action === 'Create') {
    //   //   if (this.form.password_confirmation !== '') {
    //   //     this.$refs.ruleForm.validateField('checkPass')
    //   //   }
    //   //   callback()
    //   // } else {
    //   if (value === '') {
    //     callback(new Error('Please input the password'))
    //   } else {
    //     if (this.form.password_confirmation !== '') {
    //       this.$refs.ruleForm.validateField('checkPass')
    //     }
    //     callback()
    //   }
    //   // }
    // }
    // const validatePass2 = (rule, value, callback) => {
    //   // if (this.action === 'Create') {
    //   //   if (value !== this.form.password) {
    //   //     callback(new Error("Password doesn't match!"))
    //   //   } else {
    //   //     callback()
    //   //   }
    //   // } else {
    //   if (value === '') {
    //     callback(new Error('Please input the password again'))
    //   } else {
    //     if (value !== this.form.password) {
    //       callback(new Error("Password doesn't match!"))
    //     } else {
    //       callback()
    //     }
    //   }
    //   // }
    // }
    return {
      labelCol: { span: 6 },
      wrapperCol: { span: 18 },
      user: [],
      datarole: [],
      dataapplist: [],
      roletags: false,
      editdata: {},
      action: 'Create',
      form: {
        sandibpr: '',
        namabpr: '',
        periode: null,
        appLists: [],
        selectedAppLists: [],
      },
      rules: {
        sandibpr: [{ required: true, message: 'Please input sandibpr!' }],
        namabpr: [{ required: true, message: 'Please input namabpr!' }],
        // periode: [{ required: true, message: 'Please input periode!' }],
      },
      loading: false,
      visible: false,
      datenow: null,
    }
  },
  methods: {
    moment,
    // async getMaster() {
    //   var resp = await lou.readMaster('id_role')
    //   this.datarole = resp.data
    // },
    selectDate(selectedDates, dateString, input) {
      console.log('selectedDates', selectedDates)
      console.log('dateString', dateString)
      console.log('input', input)
      if (selectedDates !== null) {
        this.form[input] = dateString
      } else {
        this.form[input] = null
      }
    },
    userAppSelect(e) {
      // console.log('e', e)
      var temp = []
      for (let m = 0; m < e.length; m++) {
        const element = e[m]
        temp.push({ id_app: element })
      }
      // console.log('temp', temp)
      this.form.appLists = temp
    },
    async showModal(action, data = {}) {
      // console.log('data', data)
      this.editdata = data
      var resapplist = await lou.readMaster('manage/app', false, true)
      this.dataapplist = resapplist.data
      if (action === 'Update') {
        this.form.id = data.id
        this.form.sandibpr = data.sandibpr
        this.form.namabpr = data.namabpr
        this.form.periode = data.periode
        // console.log('data', data)
        var temp = []
        for (let h = 0; h < data.appLists.length; h++) {
          const el = data.appLists[h]
          temp.push(el.id_app)
        }
        this.form.selectedAppLists = temp
        console.log('data.appLists', data.appLists)
        // console.log('this.form.selectedAppLists', this.form.selectedAppLists)
        this.form.appLists = data.appLists
      } else {
        this.form.sandibpr = ''
        this.form.namabpr = ''
        this.form.periode = null
        this.form.selectedAppLists = []
        this.form.appLists = []
      }
      this.action = action
      this.visible = true
    },
    handleCancel() {
      this.visible = false
    },
    submit(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          // console.log('this.form.appLists', this.form.appLists)
          var fd = {
            sandibpr: this.form.sandibpr,
            namabpr: this.form.namabpr,
            periode: this.form.periode,
            appLists: this.form.appLists,
          }
          if (this.action === 'Create') {
            await lou.createMaster('manage/bank', fd, true, true)
          } else {
            fd.id = this.form.id
            await lou.updateMaster('manage/bank', fd, true, true)
          }
          this.$parent.getData()
          this.visible = false
          this.resetForm(formName)
        } else {
          // console.log('error submit!!')
          return false
        }
      })
    },
    resetForm(formName) {
      this.$refs[formName].resetFields()
    },
  },
}
</script>

<style></style>
