<template>
  <div>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
        <a-card>
          <template slot="title">
            <strong>Batal Pencairan Deposito</strong>
          </template>
          <div class="row pl-3 pr-3 pt-3 pb-3">
            <!-- <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3">
              <label></label>
            </div> -->
            <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
              <div class="row">
                <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3">
                  <label class="mt-1">Rekening</label>
                </div>
                <div class="col-lg-9 col-md-9 col-sm-9">
                  <a-input
                    ref="rekcabang"
                    placeholder=""
                    v-model="maskInput.rekcabang"
                    style="width: 60px;"
                    :readOnly="false"
                    @input="rekeninglimit('rekcabang', 2, 'rerekening')"
                  ></a-input>
                  <a-input
                    class="ml-2"
                    placeholder=""
                    v-model="maskInput.rerekening"
                    style="width: 60px;"
                    :readOnly="false"
                    ref="rerekening"
                    @input="rekeninglimit('rerekening', 2, 'rerekening1')"
                  ></a-input>
                  <a-input
                    ref="rerekening1"
                    class="ml-2"
                    placeholder=""
                    v-model="maskInput.rerekening1"
                    style="width: 150px;"
                    :readOnly="false"
                    @keydown.enter="rekeningcheck"
                  ></a-input>
                  <a-button class="ml-2" @click="rekeningcheck">
                    <a-icon type="search"></a-icon>
                  </a-button>
                </div>
                <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-1">
                  <label class="mt-1">Tgl Transaksi</label>
                </div>
                <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                  <a-input
                    style="width: 150px;"
                    v-model="maskInput.tgl"
                    :readOnly="true"
                  ></a-input>
                </div>
                <div class="col-sm-3 col-md-3 col-lg-3 mt-1">
                  <label class="mt-1">Nama</label>
                </div>
                <div class="col-lg-9 col-md-9 col-sm-9 mt-1">
                  <a-input
                    placeholder="Nama"
                    v-model="maskInput.nama"
                    :readOnly="true"
                  ></a-input>
                </div>
                <div class="col-sm-3 col-md-3 col-lg-3 mt-1">
                  <label class="mt-1">Alamat</label>
                </div>
                <div class="col-lg-9 col-md-9 col-sm-9 mt-1">
                  <a-input
                    placeholder="Alamat"
                    v-model="maskInput.alamat"
                    :readOnly="true"
                  ></a-input>
                </div>

                <!-- <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
                  <label class="mt-1">No. Pencairan</label>
                </div>
                <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                  <a-input
                    placeholder="Rekening Tabungan"
                    v-model="maskInput.no_pencairan"
                    :readOnly="true"
                  ></a-input>
                </div> -->
                <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
                  <label class="mt-1">Nominal</label>
                </div>
                <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                  <a-input
                    placeholder="Nominal"
                    v-model="maskInput.nominal"
                    :readOnly="true"
                  ></a-input>
                </div>
                <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
                  <label class="mt-1">Suku Bunga</label>
                </div>
                <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                  <a-input
                    class="mr-2"
                    placeholder="Suku Bunga"
                    v-model="maskInput.sukubunga"
                    style="width: 80px;"
                    :readOnly="true"
                  ></a-input>
                  %
                </div>
                <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
                  <label class="mt-1">Rek. Tabungan</label>
                </div>
                <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                  <a-input
                    placeholder="Rekening Tabungan"
                    v-model="maskInput.rekening_tabungan"
                    style="width: 150px;"
                    :readOnly="true"
                  ></a-input>
                </div>
              </div>
            </div>
            <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
              <div class="row">
                <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
                  <label class="mt-1">Atas Nama</label>
                </div>
                <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                  <a-input
                    placeholder="Atas Nama"
                    v-model="maskInput.atas_nama"
                    :readOnly="true"
                  ></a-input>
                </div>
                <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
                  <label class="mt-1">Saldo Tabungan</label>
                </div>
                <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                  <a-input
                    style="width: 120px;"
                    placeholder="Saldo Tabungan"
                    v-model="maskInput.saldo_tabungan"
                    :readOnly="true"
                  ></a-input>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-2">
                  <a-input
                    placeholder='Ketikan "PEMBATALAN PENCAIRAN DEPOSITO"'
                    style="text-align: center;"
                    v-model="input.confirmation"
                    :readOnly="false"
                  ></a-input>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-2">
                  <a-button class="btn btn-outline-danger ml-3 float-right" @click="handleSubmit">Pembatalan Pencairan</a-button>
                </div>
              </div>
            </div>
          </div>
        </a-card>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import * as lou from '@/services/data/lou'
import { mapState } from 'vuex'

export default {
  computed: mapState(['settings', 'user']),
  watch: {
    // 'settings.selectedRekening'() {
    //   this.checkRekening()
    // },
  },
  data() {
    return {
      searchText: '',
      searchInput: null,
      searchedColumn: '',
      input: {
        rekening: '',
        confirmation: '',
      },
      maskInput: {
        rekcabang: '',
        rerekening: '',
        rerekening1: '',
        nama_nasabah: '',
        alamat_nasabah: '',
        no_pencairan: '',
        nominal: '',
        sukubunga: '',
        rekening_tabungan: '',
        atas_nama: '',
        saldo_tabungan: '',
      },
      allMaster: {
        carapencairan: [],
      },

      // Modal Property
      formvisible: false,
      carinasabahtitle: 'Cari',
      inputModal: {
        nama: '',
        alamat: '',
        cabang: '',
      },
    }
  },
  created() {
    // this.getAllData()
  },
  methods: {
    moment,
    rekeninglimit(from, limit, toref) {
      if (this.maskInput[from].length >= limit) {
        this.$refs[toref].$el.focus()
      }
    },
    async showModal() {
      this.carinasabahvisible = true
      var res = await lou.customUrlGet2('deposito_batalcair')
      if (res) {
        console.log('res', res)
        this.table.tablebawah.datatable = res.data
      }
    },
    async handleSubmit() {
      if (this.input.confirmation === 'PEMBATALAN PENCAIRAN DEPOSITO') {
        var res = await lou.customUrlPost2(
          'deposito_batalcair/' + this.maskInput.id,
          {},
        )
        if (res) {
          // this.getAllData()
          this.resetForm()
        }
      } else {
        lou.shownotif('Tolong ketik "PEMBATALAN PENCAIRAN DEPOSITO" di input konfirmasi!')
      }
    },
    async checkRekening() {
      this.input.rekening = this.settings.selectedRekening
      var text = this.settings.selectedRekening
      this.maskInput.rekcabang = text.slice(0, text.indexOf('.'))
      this.maskInput.rerekening = text.slice(
        text.indexOf('.') + 1,
        text.indexOf('.') + 3,
      )
      var ntext = text.replace('.', '')
      // console.log('ntext', ntext.slice(ntext.indexOf('.') + 1, ntext.length))
      this.maskInput.rerekening1 = ntext.slice(
        ntext.indexOf('.') + 1,
        ntext.length,
      )
      // this.rekeningcheck()
    },
    async rekeningcheck() {
      var res = await lou.customUrlGet2(
        'deposito_batalcair?rekening=' +
          this.maskInput.rekcabang +
          '.' +
          this.maskInput.rerekening +
          '.' +
          this.maskInput.rerekening1,
      )
      if (res) {
        console.log('res', res)
        if (res.data !== null) {
          lou.shownotif('Ditemukan!', 'Rekening ditemukan.')
          // this.maskInput = this.$g.clone(res.data)
          this.maskInput.tgl = res.data[0].tgl
          this.maskInput.alamat = res.data[0].alamat
          this.maskInput.id = res.data[0].id
          this.maskInput.nama = res.data[0].nama
          this.maskInput.atas_nama = res.data[0].nama_tabungan
          this.maskInput.nominal = res.data[0].nominal
          this.maskInput.sukubunga = res.data[0].sukubunga
          this.maskInput.rekening_tabungan = res.data[0].rekening_tabungan
          this.maskInput.saldo_tabungan = res.data[0].saldo_tabungan
          // console.log('this.maskInput', this.maskInput)
        } else {
          lou.shownotif(
            'Tidak ditemukan!',
            'Rekening tidak ditemukan.',
            'error',
          )
        }
      }
    },
    async getAllData() {
      var indexeddb = await lou.getDataFromDb()
      // console.log('indexeddb', indexeddb)
      // console.log('this.settings.whatapp', this.settings.whatapp)
      var allmaster = {}
      var masterIndex = indexeddb.findIndex(
        (x) =>
          x.mykey ===
          'allmaster' +
            this.settings.whatapp.appName.toLowerCase() +
            this.settings.whatapp.id,
      )
      // console.log('masterIndex', indexeddb[masterIndex])
      if (masterIndex < 0) {
        var res1 = await lou.customUrlGet2('master', false, true)
        var master = {
          mykey:
            'allmaster' +
            this.settings.whatapp.appName.toLowerCase() +
            this.settings.whatapp.id,
          data: JSON.stringify(res1.data),
        }
        await lou.addDataToDb(master)
        var nindexeddb = await lou.getDataFromDb()
        var targetIndex = nindexeddb.findIndex(
          (x) =>
            x.mykey ===
            'allmaster' +
              this.settings.whatapp.appName.toLowerCase() +
              this.settings.whatapp.id,
        )
        // console.log('nindexeddb', nindexeddb[targetIndex])
        allmaster = JSON.parse(nindexeddb[targetIndex].data)
      } else {
        allmaster = JSON.parse(indexeddb[masterIndex].data)
      }
      var cabanglist = []
      for (let u = 0; u < this.user.cabangList.length; u++) {
        const element = this.user.cabangList[u]
        // console.log('element', element)
        cabanglist.push({ kode: element.kode, keterangan: element.kode })
      }
      // console.log('indexeddb', indexeddb)
      this.allMaster = allmaster
      console.log('this.allMaster', this.allMaster)
      var res = await lou.customUrlGet2('deposito_batalcair')
      if (res) {
        this.table.tablebawah.datatable = res.data
      }
    },
    resetForm() {
      this.input = {
        rekening: '',
        confirmation: '',
      }
      this.maskInput = {
        rekcabang: '',
        rerekening: '',
        rerekening1: '',
        nama_nasabah: '',
        alamat_nasabah: '',
        no_pencairan: '',
        nominal: '',
        sukubunga: '',
        rekening_tabungan: '',
        atas_nama: '',
        saldo_tabungan: '',
      }
      this.$refs.rekcabang.$el.focus()
    },

    // Modal function
    clearRekening() {
      this.settings.selectedRekening = ''
    },
    async findData() {
      var imbuhan = ''
      imbuhan +=
        this.inputModal.nama !== '' ? '?Nama=' + this.inputModal.nama : ''
      imbuhan +=
        this.inputModal.alamat !== ''
          ? this.inputModal.nama !== ''
            ? '&Alamat=' + this.inputModal.alamat
            : '?Alamat=' + this.inputModal.alamat
          : ''
      imbuhan +=
        this.inputModal.cabang !== ''
          ? '&cabang=' + this.inputModal.cabang
          : ''
      var res = await lou.customUrlGet2('carinasabah' + imbuhan)
      if (res) {
        this.table.tableatas.datatable = res.data
        // for (let i = 0; i < 20; i++) {
        //   const element = res.data[0]
        //   this.table.tableatas.datatable.push(element)
        // }
      }
    },
    async nasabahDetail(record) {
      this.loading = true
      var res = await lou.customUrlGet2('carinasabah/detail/' + record.kode)
      if (res) {
        this.maskInput.cabang = record.kode.slice(0, record.kode.indexOf('.'))
        this.maskInput.reregister = record.kode.slice(
          record.kode.indexOf('.') + 1,
          record.kode.length,
        )
        // this.registeridchanged()
        this.table.tablebawah.datatable = res.data
        this.loading = false
      }
    },
    customRow(record, index) {
      return {
        on: {
          click: (event) => {
            // console.log('record', record)
            // console.log('index', index)
            // console.log('event', event)
            this.settings.selectedKodeRegister = record.kode
            this.nasabahDetail(record)
          },
          contextmenu: (event) => {
            event.preventDefault()
          },
        },
      }
    },
    customRowB(record, index) {
      return {
        on: {
          click: (event) => {
            // console.log('record', record)

            this.input.rekening = record.rekening
            var text = record.rekening
            this.maskInput.rekcabang = text.slice(0, text.indexOf('.'))
            this.maskInput.rerekening = text.slice(
              text.indexOf('.') + 1,
              text.indexOf('.') + 3,
            )
            var ntext = text.replace('.', '')
            // console.log('ntext', ntext.slice(ntext.indexOf('.') + 1, ntext.length))
            this.maskInput.rerekening1 = ntext.slice(
              ntext.indexOf('.') + 1,
              ntext.length,
            )
            this.maskInput = this.$g.clone(record)
            this.maskInput.plafond = this.formatCurrency(record.plafond)
            this.maskInput.biaya_administrasi = this.formatCurrency(
              record.biaya_administrasi,
            )
            this.maskInput.biaya_asuransi = this.formatCurrency(
              record.biaya_asuransi,
            )
            this.maskInput.biaya_materai = this.formatCurrency(
              record.biaya_materai,
            )
            this.maskInput.biaya_notaris = this.formatCurrency(
              record.biaya_notaris,
            )
            // console.log('this.maskInput', this.maskInput)
            this.formvisible = true
            // this.settings.selectedRekening = record.rekening
            // console.log('index', index)
            // console.log('event', event)
            // console.log('this.settings.selectedRekening', this.settings.selectedRekening)
          },
          contextmenu: (event) => {
            event.preventDefault()
          },
        },
      }
    },
    formatCurrency(v) {
      return lou.curency(v)
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      )
    },
    dateFormatter(params) {
      var ret =
        params.value === null
          ? ''
          : moment(params.value, 'YYYY-MM-DD').format('DD-MM-YYYY')
      // console.log('dateFormatter', ret)
      return ret !== 'Invalid date' ? ret : params.value
    },
    currencyFormatter(params) {
      var ret =
        params.value === null
          ? 'NaN'
          : lou.curency(parseFloat(params.value).toFixed(0))
      return ret === 'NaN' ? '' : ret
    },
  },
}
</script>

<style lang="scss" scoped></style>
