<template>
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
      <a-card>
        <template slot="title">
          <div class="row">
            <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
              <!-- <a-button class="btn btn-outline-secondary" v-on:click="refreshTable">
              Refresh
            </a-button> -->
              <strong
                >{{ title }}
                <!-- + ' - Length:' + rowData.length -->
              </strong>
              <!-- <a-button
                v-if="mode !== 'netral'"
                ref="undoBtn"
                class="btn btn-outline-secondary ml-2"
                :disabled="input.undoInputdisable"
                v-on:click="undo()"
              >
                {{ input.undoInput }} Undo
              </a-button>
              <a-button
                v-if="mode !== 'netral'"
                ref="redoBtn"
                class="ml-2 btn btn-outline-secondary"
                :disabled="input.redoInputdisable"
                v-on:click="redo()"
              >
                {{ input.redoInput }} Redo
              </a-button> -->
              <a-button
                v-if="mode === 'netral' || mode === 'oncreate'"
                ref="addBtn"
                class="ml-2 btn btn-outline-primary"
                :disabled="mode !== 'netral' && mode !== 'oncreate'"
                @click="addNullData"
              >
                Tambah
              </a-button>
              <a-button
                v-if="mode === 'netral'"
                class="btn btn-outline-danger ml-2 mr-2"
                @click="removeOrDelete"
                :disabled="mode !== 'netral'"
              >
                Delete
              </a-button>
              <a-button
                class="btn btn-outline-success ml-2"
                @click="saveChanges"
                v-if="mode === 'onupdate' || mode === 'oncreate'"
              >
                Save Changes
              </a-button>
              <a-button
                class="btn btn-outline-danger ml-2 mr-2"
                @click="cancelaction"
                v-if="mode === 'onupdate' || mode === 'oncreate'"
              >
                Cancel
              </a-button>
            </div>
            <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
              <!-- <strong class="ml-2">Error Data: </strong>
              <a-switch
                class="mr-2"
                @change="onlySeeError"
                checked-children="E"
                un-checked-children="N"
              /> -->
              <a-dropdown>
                <a-menu slot="overlay">
                  <a-menu-item key="4" class="text-success" @click="exportExcel">
                    <a-icon type="file-excel" /> Export Excel
                  </a-menu-item>
                  <a-menu-item key="1" @click="txtExport" class="text-secondary"> <a-icon type="file" />Export txt</a-menu-item>
                  <a-menu-item key="90" @click="exportExcel" class="text-success"> <a-icon type="file-excel" />Export Excel</a-menu-item>
                  <!-- <a-menu-item key="2" @click="validasidata" class="text-warning"> <a-icon type="check" />Validasi</a-menu-item> -->
                  <a-menu-item key="3" @click="clearData" class="text-danger"> <a-icon type="delete" />Clear</a-menu-item>
                  <a-menu-item class="text-success" @click="getAllData(true)"> <a-icon type="sync" />Refresh </a-menu-item>
                </a-menu>
                <a-button
                  class="btn btn-outline-secondary ml-2 float-right"
                  >Menu <a-icon type="down" /></a-button
                >
              </a-dropdown>
              <a-dropdown>
                <a-menu slot="overlay">
                  <a-menu-item key="1" @click="selectImportFile('uploadtext')"> <a-icon type="file" />File txt</a-menu-item>
                  <a-menu-item key="2" @click="selectImportFile('uploadexcel')"> <a-icon type="file-excel" />File Excel</a-menu-item>
                  <a-menu-item class="text-success" @click="importExcel"> <a-icon type="file-excel" />Import Excel2 </a-menu-item>
                  <a-menu-item class="text-success" @click="downloadTemplate"> <a-icon type="file-excel" />Template Excel2 </a-menu-item>
                </a-menu>
                <a-button
                  class="btn btn-outline-success ml-2 float-right"
                  :loading="importBtnLoading"
                  >Import <a-icon type="down" /></a-button
                >
              </a-dropdown>
              <a-input
                v-model="tablequickfilter"
                class="ml-2 float-right"
                placeholder="Find data..."
                style="width: 160px;"
              />
              <input
                type="file"
                ref="excelFileUpload"
                style="display: none;"
                accept="application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                @input="getSheetExcel"
              />
              <input
                type="file"
                ref="uploadtext"
                style="display: none;"
                accept="text/plain"
                @input="readTextFile"
              />
              <input
                type="file"
                ref="uploadexcel"
                style="display: none;"
                accept=".xlsx, .xls"
                @input="readExcelFile"
              />
            </div>
          </div>
        </template>
        <div class="row">
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
          <!-- :key="componentKey" -->
          <strong class="text-success mt-2" v-if="mode === 'oncreate'"
            >New data: <span>{{ changedData.length }}</span></strong
          >
          <strong class="text-warning mt-2" v-if="mode === 'onupdate'"
            >Update data: <span>{{ changedData.length }}</span></strong
          >
          <strong
            :class="mode === 'oncreate' || mode === 'onupdate' ? 'ml-4 mt-2' : ' mt-2'"
            >Total data: {{ rowData != null ? rowData.length : '0' }}</strong
          >
          <strong class="ml-4 text-danger mt-2" v-if="showErrorLength"
            >Error data: <span>{{ errorData.length }}</span></strong
          >
          <button @click="detailCIFControlPanel" :class="detailVisibility ? 'btn btn-sm btn-outline-secondary float-right' : 'btn btn-sm btn-outline-primary float-right'">{{ detailVisibility ? 'Sembunyikan Detail CIF' : 'Tampilkan Detail CIF' }}</button>
          </div>
        </div>
        <div class="row">
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
          <ag-grid-vue
            style="height: 58vh;"
            :pagination="false"
            :paginationPageSize="15"
            :gridOptions="gridOptions"
            :rowClassRules="rowClassRules"
            @first-data-rendered="onFirstDataRendered('')"
            @grid-ready="(e) => onGridReady(e, '')"
            :cacheQuickFilter="true"
            :quickFilterText="tablequickfilter"
            @cell-value-changed="onCellValueChanged"
            :undoRedoCellEditing="true"
            :undoRedoCellEditingLimit="5"
            :frameworkComponents="frameworkComponents"
            rowSelection="single"
            @selection-changed="onSelectionChanged"
            :class="
              settings.theme === 'dark'
                ? 'ag-theme-balham-dark mt-2'
                : 'ag-theme-balham mt-2'
            "
            :columnDefs="columnDefs"
            :rowData="onlyseeerror ? errorData : rowData"
          ></ag-grid-vue>
          </div>
        </div>
        <div class="row mt-4" v-if="detailVisibility">
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            <strong class="">{{ rowDataa01.length === 0 ? 'Tidak ada data form A01' : 'Data A01' }} </strong><label for="">{{ selectedData !== null ? '('+ selectedData[0].cif +')' : '(Belum memilih nomor CIF)' }}</label>
            <ag-grid-vue
              v-if="rowDataa01.length !== 0"
              style="height: 20vh;"
              :pagination="false"
              :paginationPageSize="15"
              :gridOptions="gridOptionsa01"
              :rowClassRules="rowClassRules"
              @first-data-rendered="onFirstDataRendered('a01')"
              @grid-ready="(e) => onGridReady(e, 'a01')"
              :cacheQuickFilter="true"
              :undoRedoCellEditing="true"
              :undoRedoCellEditingLimit="5"
              :frameworkComponents="frameworkComponents"
              rowSelection="single"
              :class="
                settings.theme === 'dark'
                  ? 'ag-theme-balham-dark mt-2'
                  : 'ag-theme-balham mt-2'
              "
              :columnDefs="columnDefsa01"
              :rowData="rowDataa01"
            ></ag-grid-vue>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-2">
            <strong class="">{{ rowDatad01.length === 0 ? 'Tidak ada data form D01 ' : 'Data D01 ' }}</strong><label for="">{{ selectedData !== null ? '('+ selectedData[0].cif +')' : '(Belum memilih nomor CIF)' }}</label>
            <ag-grid-vue
              v-if="rowDatad01.length !== 0"
              style="height: 20vh;"
              :pagination="false"
              :paginationPageSize="15"
              :gridOptions="gridOptionsd01"
              :rowClassRules="rowClassRules"
              @first-data-rendered="onFirstDataRendered('d01')"
              @grid-ready="(e) => onGridReady(e, 'd01')"
              :cacheQuickFilter="true"
              :undoRedoCellEditing="true"
              :undoRedoCellEditingLimit="5"
              :frameworkComponents="frameworkComponents"
              rowSelection="single"
              :class="
                settings.theme === 'dark'
                  ? 'ag-theme-balham-dark mt-2'
                  : 'ag-theme-balham mt-2'
              "
              :columnDefs="columnDefsd01"
              :rowData="rowDatad01"
            ></ag-grid-vue>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-2">
            <strong class="">{{ rowDatad02.length === 0 ? 'Tidak ada data form D02 ' : 'Data D02 ' }}</strong><label for="">{{ selectedData !== null ? '('+ selectedData[0].cif +')' : '(Belum memilih nomor CIF)' }}</label>
            <ag-grid-vue
              v-if="rowDatad02.length !== 0"
              style="height: 20vh;"
              :pagination="false"
              :paginationPageSize="15"
              :gridOptions="gridOptionsd02"
              :rowClassRules="rowClassRules"
              @first-data-rendered="onFirstDataRendered('d02')"
              @grid-ready="(e) => onGridReady(e, 'd02')"
              :cacheQuickFilter="true"
              :undoRedoCellEditing="true"
              :undoRedoCellEditingLimit="5"
              :frameworkComponents="frameworkComponents"
              rowSelection="single"
              :class="
                settings.theme === 'dark'
                  ? 'ag-theme-balham-dark mt-2'
                  : 'ag-theme-balham mt-2'
              "
              :columnDefs="columnDefsd02"
              :rowData="rowDatad02"
            ></ag-grid-vue>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-2">
            <strong class="">{{ rowDatam01.length === 0 ? 'Tidak ada data form M01 ' : 'Data M01 ' }}</strong><label for="">{{ selectedData !== null ? '('+ selectedData[0].cif +')' : '(Belum memilih nomor CIF)' }}</label>
            <ag-grid-vue
              v-if="rowDatam01.length !== 0"
              style="height: 20vh;"
              :pagination="false"
              :paginationPageSize="15"
              :gridOptions="gridOptionsm01"
              :rowClassRules="rowClassRules"
              @first-data-rendered="onFirstDataRendered('m01')"
              @grid-ready="(e) => onGridReady(e, 'm01')"
              :cacheQuickFilter="true"
              :undoRedoCellEditing="true"
              :undoRedoCellEditingLimit="5"
              :frameworkComponents="frameworkComponents"
              rowSelection="single"
              :class="
                settings.theme === 'dark'
                  ? 'ag-theme-balham-dark mt-2'
                  : 'ag-theme-balham mt-2'
              "
              :columnDefs="columnDefsm01"
              :rowData="rowDatam01"
            ></ag-grid-vue>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-2">
            <strong class="">{{ rowDatak01.length === 0 ? 'Tidak ada data form K01 ' : 'Data K01 ' }}</strong><label for="">{{ selectedData !== null ? '('+ selectedData[0].cif +')' : '(Belum memilih nomor CIF)' }}</label>
            <ag-grid-vue
              v-if="rowDatak01.length !== 0"
              style="height: 20vh;"
              :pagination="false"
              :paginationPageSize="15"
              :gridOptions="gridOptionsk01"
              :rowClassRules="rowClassRules"
              @first-data-rendered="onFirstDataRendered('k01')"
              @grid-ready="(e) => onGridReady(e, 'k01')"
              :cacheQuickFilter="true"
              :undoRedoCellEditing="true"
              :undoRedoCellEditingLimit="5"
              :frameworkComponents="frameworkComponents"
              rowSelection="single"
              :class="
                settings.theme === 'dark'
                  ? 'ag-theme-balham-dark mt-2'
                  : 'ag-theme-balham mt-2'
              "
              :columnDefs="columnDefsk01"
              :rowData="rowDatak01"
            ></ag-grid-vue>
          </div>
        </div>
        <!-- <a-collapse v-model="activeKey">
          <a-collapse-panel key="1" header="This is panel header 1">
            <div class="row">
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              </div>
            </div>
          </a-collapse-panel>
        </a-collapse> -->
        <div class="row mt-4" v-if="specialformf01.visible">
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            <div class="row">
              <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4">
                <strong class="mt-1">Rekap data {{ whatform.toUpperCase() }}</strong>
                <a-button class="btn btn-outline-primary ml-2" @click="ambilRekap">Check data rekap</a-button>
              </div>
              <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4">
                <strong class="mt-1">Kolekbilitas</strong>
                <a-select v-model="specialformf01.kodecabangforrekap" placeholder="Kode cabang" class="ml-2" style="width: 120px;" @change="ambilRekap">
                  <a-select-option value="000">Kode: 000</a-select-option>
                  <a-select-option value="001">Kode: 001</a-select-option>
                  <a-select-option value="002">Kode: 002</a-select-option>
                </a-select>
              </div>
              <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4">
                <strong class="mt-1">Non Performing Loan (NPL)</strong>
              </div>
            </div>
          </div>
          <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4">
            <ag-grid-vue
            :key="specialformf01.compkey"
              :class="
                settings.theme === 'dark'
                  ? 'ag-theme-balham-dark mt-2'
                  : 'ag-theme-balham mt-2'
              "
              :columnDefs="specialformf01.columnDefs"
              :rowData="specialformf01.rowData"
              style="height: 35vh;"
            ></ag-grid-vue>
          </div>
          <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4">
            <ag-grid-vue
            :key="specialformf01.compkey1"
              :class="
                settings.theme === 'dark'
                  ? 'ag-theme-balham-dark mt-2'
                  : 'ag-theme-balham mt-2'
              "
              :columnDefs="specialformf01.columnDefs1"
              :rowData="specialformf01.rowData1"
              style="height: 35vh;"
            ></ag-grid-vue>
          </div>
          <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4">
            <ag-grid-vue
            :key="specialformf01.compkey2"
              :class="
                settings.theme === 'dark'
                  ? 'ag-theme-balham-dark mt-2'
                  : 'ag-theme-balham mt-2'
              "
              :columnDefs="specialformf01.columnDefs2"
              :rowData="specialformf01.rowData2"
              style="height: 35vh;"
            ></ag-grid-vue>
          </div>
        </div>
      </a-card>
    </div>
  </div>
</template>

<script>
import * as lou from '@/services/data/lou'
import moment from 'moment'
import numericInput from './component/numeric'
import selectInput from './component/select'
import dateInput from './component/date'
import tooltip from './component/tooltip'
import htmlrender from './component/htmlrender'
import { mapState } from 'vuex'
import { Modal } from 'ant-design-vue'
export default {
  computed: {
    ...mapState(['settings']),
  },
  watch: {
    excelModalVisible(newValue, oldValue) {
      if (newValue === false) {
        // console.log('oldValue', oldValue)
        this.excelModalCancel()
        // console.log('newValue', newValue)
      }
    },
  },
  created() {
    this.rowData = null
    this.getAllMaster()
    // if (this.settings.whatapp !== 'slik') {
    //   this.requestChangedToSlik()
    // }
    // console.log('this.allMaster', this.allMaster)
    this.gridOptionsModalExcel = {}
    this.gridOptions = {}
    this.gridApi = this.gridOptions.api
    this.gridColumnApi = this.gridOptions.columnApi

    this.gridOptionsa01 = {}
    this.gridApi = this.gridOptionsa01.api
    this.gridColumnApi = this.gridOptionsa01.columnApi

    this.gridOptionsd01 = {}
    this.gridApi = this.gridOptionsd01.api
    this.gridColumnApi = this.gridOptionsd01.columnApi

    this.gridOptionsd02 = {}
    this.gridApi = this.gridOptionsd02.api
    this.gridColumnApi = this.gridOptionsd02.columnApi

    this.gridOptionsm01 = {}
    this.gridApi = this.gridOptionsm01.api
    this.gridColumnApi = this.gridOptionsm01.columnApi

    this.gridOptionsk01 = {}
    this.gridApi = this.gridOptionsk01.api
    this.gridColumnApi = this.gridOptionsk01.columnApi

    // this.getColumnDefs()
    // this.getAllData()
  },
  // watch: {
  //   settings: {
  //     handler: function(newValue) {
  //       if (newValue.whatapp !== 'SLIK') {
  //         this.requestChangedToSlik()
  //       }
  //     },
  //     deep: true,
  //   },
  // },
  data() {
    return {
      title: 'Kredit',
      mode: 'netral',
      allMaster: [],
      importBtnLoading: false,
      tablequickfilter: '',
      gridOptions: null,
      evaluateBtnLoading: false,
      showErrorLength: false,
      allowPagination: localStorage.getItem('gridpagination') || false,
      frameworkComponents: {
        numericEditor: numericInput,
        dateEditor: dateInput,
        selectEditor: selectInput,
        customTooltip: tooltip,
        htmlRender: htmlrender,
      },
      input: {
        undoInput: '',
        redoInput: '',
        undoInputdisable: true,
        redoInputdisable: true,
      },

      selectedData: null,
      changedData: [],
      rowAfterFilter: [],
      columnCurrency: [],
      theme: this.$store.state.settings.theme,
      gridOptionsModalExcel: null,
      sheetColection: [],
      excelModalVisible: false,
      excelMode: 'normal',
      excelModal: {
        column: [
          {
            headerName: 'Flag Detail',
            field: 'flag_detail',
            filter: true,
            resizable: true,
            editable: true,
          },
          {
            headerName: 'No Rekening',
            field: 'no_rekening',
            filter: true,
            resizable: true,
            editable: true,
          },
          {
            headerName: 'CIF',
            field: 'cif',
            filter: true,
            resizable: true,
            editable: true,
          },
          {
            headerName: 'Kode Sifat Kredit',
            field: 'kode_sifat_kredit',
            filter: true,
            resizable: true,
            editable: true,
          },
          {
            headerName: 'Kode Jenis Kredit',
            field: 'kode_jenis_kredit',
            filter: true,
            resizable: true,
            editable: true,
          },
          {
            headerName: 'Kode Skim Akad Pembiayaan',
            field: 'kode_skim_akad_pembiayaan',
            filter: true,
            resizable: true,
            editable: true,
          },
          {
            headerName: 'No Akad Awal',
            field: 'no_akad_awal',
            filter: true,
            resizable: true,
            editable: true,
          },
          {
            headerName: 'Tanggal Akad Awal',
            field: 'tanggal_akad_awal',
            filter: true,
            resizable: true,
            editable: true,
          },
          {
            headerName: 'No Akad Akhir',
            field: 'no_akad_akhir',
            filter: true,
            resizable: true,
            editable: true,
          },
          {
            headerName: 'Tanggal Akad Akhir',
            field: 'tanggal_akad_akhir',
            filter: true,
            resizable: true,
            editable: true,
          },
          {
            headerName: 'Baru Perpanjangan',
            field: 'baru_perpanjangan',
            filter: true,
            resizable: true,
            editable: true,
          },
          {
            headerName: 'Tanggal Awal Kredit',
            field: 'tanggal_awal_kredit',
            filter: true,
            resizable: true,
            editable: true,
          },
          {
            headerName: 'Tanggal Mulai',
            field: 'tanggal_mulai',
            filter: true,
            resizable: true,
            editable: true,
          },
          {
            headerName: 'Tanggal Jatuh Tempo',
            field: 'tanggal_jatuh_tempo',
            filter: true,
            resizable: true,
            editable: true,
          },
          {
            headerName: 'Kode Kategori Debitur',
            field: 'kode_kategori_debitur',
            filter: true,
            resizable: true,
            editable: true,
          },
          {
            headerName: 'Kode Jenis Penggunaan',
            field: 'kode_jenis_penggunaan',
            filter: true,
            resizable: true,
            editable: true,
          },
          {
            headerName: 'Kode Orientasi Penggunaan',
            field: 'kode_orientasi_penggunaan',
            filter: true,
            resizable: true,
            editable: true,
          },
          {
            headerName: 'Kode Sektor Ekonomi',
            field: 'kode_sektor_ekonomi',
            filter: true,
            resizable: true,
            editable: true,
          },
          {
            headerName: 'Kode Kab Kota DATI 2 Lokasi Proyek Penggunaan Kredit',
            field: 'kode_kab_kota_dati_2_lokasi_proyek_penggunaan_kredit',
            filter: true,
            resizable: true,
            editable: true,
          },
          {
            headerName: 'Nilai Proyek',
            field: 'nilai_proyek',
            filter: true,
            resizable: true,
            editable: true,
          },
          {
            headerName: 'Kode Valuta',
            field: 'kode_valuta',
            filter: true,
            resizable: true,
            editable: true,
          },
          {
            headerName: 'Prosentase Suku Bunga Imbalan',
            field: 'prosentase_suku_bunga_imbalan',
            filter: true,
            resizable: true,
            editable: true,
          },
          {
            headerName: 'Jenis Suku Bunga Imbalan',
            field: 'jenis_suku_bunga_imbalan',
            filter: true,
            resizable: true,
            editable: true,
          },
          {
            headerName: 'Kredit Program Pemerintah',
            field: 'kredit_program_pemerintah',
            filter: true,
            resizable: true,
            editable: true,
          },
          {
            headerName: 'Takeover Dari',
            field: 'takeover_dari',
            filter: true,
            resizable: true,
            editable: true,
          },
          {
            headerName: 'Sumber Dana',
            field: 'sumber_dana',
            filter: true,
            resizable: true,
            editable: true,
          },
          {
            headerName: 'Plafon Awal',
            field: 'plafon_awal',
            filter: true,
            resizable: true,
            editable: true,
          },
          {
            headerName: 'Plafon',
            field: 'plafon',
            filter: true,
            resizable: true,
            editable: true,
          },
          {
            headerName: 'Realisasi Pencairan Bulan Berjalan',
            field: 'realisasi_pencairan_bulan_berjalan',
            filter: true,
            resizable: true,
            editable: true,
          },
          {
            headerName: 'Denda',
            field: 'denda',
            filter: true,
            resizable: true,
            editable: true,
          },
          {
            headerName: 'Baki Debet',
            field: 'baki_debet',
            filter: true,
            resizable: true,
            editable: true,
          },
          {
            headerName: 'Nilai Dalam Mata Uang Asal',
            field: 'nilai_dalam_mata_uang_asal',
            filter: true,
            resizable: true,
            editable: true,
          },
          {
            headerName: 'Kode Kolektibilitas',
            field: 'kode_kolektibilitas',
            filter: true,
            resizable: true,
            editable: true,
          },
          {
            headerName: 'Tanggal Macet',
            field: 'tanggal_macet',
            filter: true,
            resizable: true,
            editable: true,
          },
          {
            headerName: 'Kode Sebab Macet',
            field: 'kode_sebab_macet',
            filter: true,
            resizable: true,
            editable: true,
          },
          {
            headerName: 'Tunggakan Pokok',
            field: 'tunggakan_pokok',
            filter: true,
            resizable: true,
            editable: true,
          },
          {
            headerName: 'Tunggakan Bunga',
            field: 'tunggakan_bunga',
            filter: true,
            resizable: true,
            editable: true,
          },
          {
            headerName: 'Jumlah Hari Tunggakan',
            field: 'jumlah_hari_tunggakan',
            filter: true,
            resizable: true,
            editable: true,
          },
          {
            headerName: 'Frekuensi Tunggakan',
            field: 'frekuensi_tunggakan',
            filter: true,
            resizable: true,
            editable: true,
          },
          {
            headerName: 'Frekuensi Restrukturisasi',
            field: 'frekuensi_restrukturisasi',
            filter: true,
            resizable: true,
            editable: true,
          },
          {
            headerName: 'Tanggal Restrukturisasi Awal',
            field: 'tanggal_restrukturisasi_awal',
            filter: true,
            resizable: true,
            editable: true,
          },
          {
            headerName: 'Tanggal Restrukturisasi Akhir',
            field: 'tanggal_restrukturisasi_akhir',
            filter: true,
            resizable: true,
            editable: true,
          },
          {
            headerName: 'Kode Cara Restrukturisasi',
            field: 'kode_cara_restrukturisasi',
            filter: true,
            resizable: true,
            editable: true,
          },
          {
            headerName: 'Kode Kondisi',
            field: 'kode_kondisi',
            filter: true,
            resizable: true,
            editable: true,
          },
          {
            headerName: 'Tanggal Kondisi',
            field: 'tanggal_kondisi',
            filter: true,
            resizable: true,
            editable: true,
          },
          {
            headerName: 'Keterangan',
            field: 'keterangan',
            filter: true,
            resizable: true,
            editable: true,
          },
          {
            headerName: 'Kode Kantor Cabang',
            field: 'kode_kantor_cabang',
            filter: true,
            resizable: true,
            editable: true,
          },
          {
            headerName: 'Operasi Data',
            field: 'operasi_data',
            filter: true,
            resizable: true,
            editable: true,
          },

        ],
        datatable: [],
      },
      excelErrorExist: false,
      columnDefs: [],
      gridApi: null,
      backupRowData: [],
      rowData: [],
      rowClassRules: {
        'text-success': (params) => {
          return params.data.newdata !== undefined
        },
        'text-warning': (params) => {
          return params.data.edited !== undefined
        },
        'bg-kukalauerror': (params) => {
          return params.data.isError !== undefined
        },
        'text-primary': (params) => {
          return params.data.isPrimary !== undefined && params.data.isPrimary === 1
        },
        'font-weight-bold': (params) => {
          return params.data.isBold !== undefined
        },
      },
      onlyseeerror: false,
      backupErrorData: [],
      errorData: [],
      importTextHeader: '',
      filetextarr: [],
      componentKey: 0,
      whatform: 'f01',
      specialformf01: {
        kodecabangforrekap: '000',
        visible: true,
        compkey: 0,
        compkey1: 0,
        compkey2: 0,
        columnDefs: [
          {
            headerName: 'Kode Kondisi',
            field: 'kode_kondisi',
            width: 100,
          },
          {
            headerName: 'Baki Debet',
            field: 'baki_debet',
            valueFormatter: this.currencyFormatter,
            width: 120,
          },
          {
            headerName: 'Jumlah Rek',
            field: 'jumlah_rek',
            valueFormatter: this.currencyFormatter,
            width: 120,
          },
          {
            headerName: 'Keterangan',
            field: 'keterangan',
            width: 140,
          },
        ],
        columnDefs1: [
          {
            headerName: 'Kolektibilitas',
            field: 'kolektibilitas',
            width: 100,
          },
          {
            headerName: 'Baki Debet',
            field: 'baki_debet',
            valueFormatter: this.currencyFormatter,
            editable: true,
            width: 120,
          },
          {
            headerName: 'Jumlah Rek',
            field: 'jumlah_rek',
            valueFormatter: this.currencyFormatter,
            width: 120,
          },
          {
            headerName: 'Keterangan',
            field: 'keterangan',
            width: 140,
          },
        ],
        columnDefs2: [
          {
            headerName: 'Cabang',
            field: 'cabang',
            width: 100,
            resizable: true,
          },
          {
            headerName: 'Baki Debet',
            field: 'baki_debet',
            valueFormatter: this.currencyFormatter,
            width: 120,
            resizable: true,
          },
          {
            headerName: 'NPL',
            field: 'npl',
            width: 100,
            resizable: true,
          },
          {
            headerName: 'Jumlah Rek',
            field: 'jumlah_rek',
            valueFormatter: this.currencyFormatter,
            width: 120,
            resizable: true,
          },
        ],
        rowData: [],
        rowData1: [
          {
            kolektibilitas: '1',
            baki_debet: 0,
            jumlah_rek: 0,
            keterangan: 'Lancar',
          },
          {
            kolektibilitas: '2',
            baki_debet: 0,
            jumlah_rek: 0,
            keterangan: 'DPK',
          },
          {
            kolektibilitas: '3',
            baki_debet: 0,
            jumlah_rek: 0,
            keterangan: 'Kurang Lancar',
          },
          {
            kolektibilitas: '4',
            baki_debet: 0,
            jumlah_rek: 0,
            keterangan: 'Diragukan',
          },
          {
            kolektibilitas: '5',
            baki_debet: 0,
            jumlah_rek: 0,
            keterangan: 'Macet',
          },
          {
            kolektibilitas: 'Total',
            baki_debet: 0,
            jumlah_rek: 0,
            keterangan: '',
          },
        ],
        rowData2: [],
        gridOptions: null,
        gridOptions1: null,
        gridApi: null,
        gridApi1: null,
      },

      activeKey: 1,
      detailVisibility: false,

      gridOptionsa01: null,
      gridApia01: null,
      gridColumnApia01: null,
      columnDefsa01: [],
      rowDataa01: [],

      gridOptionsd01: null,
      gridApid01: null,
      gridColumnApid01: null,
      columnDefsd01: [],
      rowDatad01: [],

      gridOptionsd02: null,
      gridApid02: null,
      gridColumnApid02: null,
      columnDefsd02: [],
      rowDatad02: [],

      gridOptionsm01: null,
      gridApim01: null,
      gridColumnApim01: null,
      columnDefsm01: [],
      rowDatam01: [],

      gridOptionsk01: null,
      gridApik01: null,
      gridColumnApik01: null,
      columnDefsk01: [],
      rowDatak01: [],
    }
  },
  methods: {
    moment,
    detailCIFControlPanel() {
      this.detailVisibility = !this.detailVisibility
      if (this.detailVisibility) {
        if (this.columnDefsa01.length === 0) { this.getColumnDefs('a01') }
        if (this.columnDefsd01.length === 0) { this.getColumnDefs('d01') }
        if (this.columnDefsd02.length === 0) { this.getColumnDefs('d02') }
        if (this.columnDefsk01.length === 0) { this.getColumnDefs('k01') }
        if (this.columnDefsm01.length === 0) { this.getColumnDefs('m01') }
        if (this.selectedData !== null) { this.getSpesificsCIF() }
        // lou.shownotif('Detail', 'Table detail ditampilkan!')
      } else {
        this.columnDefsa01 = []
        this.columnDefsd01 = []
        this.columnDefsd02 = []
        this.columnDefsk01 = []
        this.columnDefsm01 = []
        // lou.shownotif('Detail', 'Table detail disembunyikan!')
      }
    },
    async getSpesificsCIF() {
      this.rowDataa01 = null
      this.rowDatad01 = null
      this.rowDatad02 = null
      this.rowDatam01 = null
      this.rowDatak01 = null
      // console.log('this.selectedData', this.selectedData)
      var link = 'api/' + this.whatform + '/related?cif=' + this.selectedData[0].cif
      var res = await lou.customUrlGet2(link, false, false, false, true)
      if (res) {
        this.rowDataa01 = res.data.a01
        this.rowDatad01 = res.data.d01
        this.rowDatad02 = res.data.d02
        this.rowDatam01 = res.data.m01
        this.rowDatak01 = res.data.k01
      }
    },
    async downloadTemplate() {
      const ExcelJS = require('exceljs')
      const workbook = new ExcelJS.Workbook()
      workbook.creator = 'Heasoft System'
      workbook.lastModifiedBy = 'Me'
      workbook.created = new Date()
      workbook.modified = new Date()
      workbook.lastPrinted = new Date()
      var worksheet = workbook.addWorksheet('import data')
      var alphabeth = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z']
      var column = this.$g.clone(this.columnDefs)
      var cfirst = 0
      var cloop = 0
      var columnLocation = []
      for (let i = 0; i < column.length; i++) {
        const element = column[i]
        var cloopni = cloop - 1
        if (cloop === 0) {
          columnLocation.push({
            location: alphabeth[cfirst],
            field: element.field,
          })
          worksheet.getCell(alphabeth[cfirst] + '1').value = element.field
        } else {
          columnLocation.push({
            location: alphabeth[cloopni] + alphabeth[cfirst],
            field: element.field,
          })
          worksheet.getCell(alphabeth[cloopni] + alphabeth[cfirst] + '1').value = element.field
        }
        if (cfirst === 25) {
          cfirst = 0
          cloop += 1
        } else {
          cfirst += 1
        }
      }
      const buffer = await workbook.xlsx.writeBuffer()
      var blob = new Blob([buffer], {
        type:
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      })
      var link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = 'Form ' + this.whatform.toUpperCase() + ' TEMPLATE.xlsx'
      link.click()
    },
    mselectSheet(params) {
      this.selectSheet(params)
    },
    selectSheet(params) {
      // console.log('params', params)
      var that = this
      if (this.fileupload) {
        var XLSX = require('xlsx')
        const reader = new FileReader()

        reader.onload = (e) => {
          /* Parse data */
          var data = e.target.result
          const wb = XLSX.read(data, {
            type: 'binary',
            cellDates: true,
          })
          /* Get first worksheet */
          // console.log('wb.SheetNames', wb.SheetNames)
          const ws = wb.Sheets[params]
          // var excellist = []
          /* Convert array of arrays */
          const ret = XLSX.utils.sheet_to_json(ws)
          // console.log('ret', ret)
          // var literalydata = ret.map((x) => {
          //   console.log('x', x[this.excelModal.column[0].field])
          //   if (x[this.excelModal.column[0].field] !== 'undefined') {
          //     return x
          //   }
          // })
          if (ret.length !== 0) {
            that.excelModal.datatable = []
            // console.log('that.excelModal.datatable', that.excelModal.datatable)
            var length = this.excelMode === 'normal' ? ret.length : ret.length - 1
            for (let i = 0; i < length; i++) {
              var element = ret[i]
              if (element[that.excelModal.column[0].field] !== undefined) {
                // console.log('element', element.TglHapusBuku)
                element.tanggal_akad_awal = element.tanggal_akad_awal !== null && element.tanggal_akad_awal !== undefined ? moment(element.tanggal_akad_awal, 'YYYY-MM-DD').format('YYYY-MM-DD') : null
                element.tanggal_akad_akhir = element.tanggal_akad_akhir !== null && element.tanggal_akad_akhir !== undefined ? moment(element.tanggal_akad_akhir, 'YYYY-MM-DD').format('YYYY-MM-DD') : null
                element.tanggal_awal_kredit = element.tanggal_awal_kredit !== null && element.tanggal_awal_kredit !== undefined ? moment(element.tanggal_awal_kredit, 'YYYY-MM-DD').format('YYYY-MM-DD') : null
                element.tanggal_mulai = element.tanggal_mulai !== null && element.tanggal_mulai !== undefined ? moment(element.tanggal_mulai, 'YYYY-MM-DD').format('YYYY-MM-DD') : null
                element.tanggal_jatuh_tempo = element.tanggal_jatuh_tempo !== null && element.tanggal_jatuh_tempo !== undefined ? moment(element.tanggal_jatuh_tempo, 'YYYY-MM-DD').format('YYYY-MM-DD') : null
                element.tanggal_macet = element.tanggal_macet !== null && element.tanggal_macet !== undefined ? moment(element.tanggal_macet, 'YYYY-MM-DD').format('YYYY-MM-DD') : null
                element.tanggal_restrukturisasi_awal = element.tanggal_restrukturisasi_awal !== null && element.tanggal_restrukturisasi_awal !== undefined ? moment(element.tanggal_restrukturisasi_awal, 'YYYY-MM-DD').format('YYYY-MM-DD') : null
                element.tanggal_restrukturisasi_akhir = element.tanggal_restrukturisasi_akhir !== null && element.tanggal_restrukturisasi_akhir !== undefined ? moment(element.tanggal_restrukturisasi_akhir, 'YYYY-MM-DD').format('YYYY-MM-DD') : null
                element.tanggal_kondisi = element.tanggal_kondisi !== null && element.tanggal_kondisi !== undefined ? moment(element.tanggal_kondisi, 'YYYY-MM-DD').format('YYYY-MM-DD') : null
                element.baru_perpanjangan = element.baru_perpanjangan !== null && element.baru_perpanjangan !== undefined ? parseInt(element.baru_perpanjangan) : null
                element.prosentase_suku_bunga_imbalan = element.prosentase_suku_bunga_imbalan !== null && element.prosentase_suku_bunga_imbalan !== undefined ? parseInt(element.prosentase_suku_bunga_imbalan) : null
                element.plafon_awal = element.plafon_awal !== null && element.plafon_awal !== undefined ? parseInt(element.plafon_awal) : null
                element.plafon = element.plafon !== null && element.plafon !== undefined ? parseInt(element.plafon) : null
                element.realisasi_pencairan_bulan_berjalan = element.realisasi_pencairan_bulan_berjalan !== null && element.realisasi_pencairan_bulan_berjalan !== undefined ? parseInt(element.realisasi_pencairan_bulan_berjalan) : null
                element.denda = element.denda !== null && element.denda !== undefined ? parseInt(element.denda) : null
                element.baki_debet = element.baki_debet !== null && element.baki_debet !== undefined ? parseInt(element.baki_debet) : null
                element.tunggakan_pokok = element.tunggakan_pokok !== null && element.tunggakan_pokok !== undefined ? parseInt(element.tunggakan_pokok) : null
                element.tunggakan_bunga = element.tunggakan_bunga !== null && element.tunggakan_bunga !== undefined ? parseInt(element.tunggakan_bunga) : null
                element.jumlah_hari_tunggakan = element.jumlah_hari_tunggakan !== null && element.jumlah_hari_tunggakan !== undefined ? parseInt(element.jumlah_hari_tunggakan) : null
                element.frekuensi_tunggakan = element.frekuensi_tunggakan !== null && element.frekuensi_tunggakan !== undefined ? parseInt(element.frekuensi_tunggakan) : null
                element.frekuensi_restrukturisasi = element.frekuensi_restrukturisasi !== null && element.frekuensi_restrukturisasi !== undefined ? parseInt(element.frekuensi_restrukturisasi) : null
                // console.log('element', element.TglHapusBuku)
                that.excelModal.datatable.push(element)
              }
            }
          }
        }
        reader.readAsBinaryString(this.fileupload)
      }
    },
    getSheetExcel() {
      this.importBtnLoading = true
      this.fileupload = this.$refs.excelFileUpload.files[0]
      // console.log('this.fileupload', this.fileupload)
      if (this.fileupload) {
        var XLSX = require('xlsx')
        const reader = new FileReader()

        reader.onload = (e) => {
          /* Parse data */
          var data = e.target.result
          const wb = XLSX.read(data, {
            type: 'binary',
            cellDates: true,
          })
          /* Get first worksheet */
          // console.log('wb.SheetNames', wb.SheetNames)
          const wsname = wb.SheetNames
          this.sheetColection = wsname
          this.importBtnLoading = false
          this.excelModalVisible = true
          // console.log('literalydata', literalydata)
          // for (var i = 0; i < ret.length; i++) {
          //   excellist.push(ret[i])
          // }
          // console.log('excellist', ret)
          // const a = wb.Sheets[wb.SheetNames[13]]
          // const headers = this.getHeader(a)
          // console.log('headers', headers)
          // dataexcel = literalydata
        }
        reader.readAsBinaryString(this.fileupload)
      }
    },
    importExcel(mode) {
      this.excelMode = mode
      this.excelModalFrom = ''
      this.$refs.excelFileUpload.click()
    },
    excelModalCancel() {
      this.excelModalVisible = false
      this.fileupload = null
      this.$refs.excelFileUpload.value = null
    },
    async proccessDataExcel() {
      var res = null
      if (this.excelErrorExist) {
        Modal.confirm({
          title: 'Error data',
          content: 'Skip error data!?',
          okText: 'Agree',
          okType: 'primary',
          cancelText: 'No',
          onOk: async () => {
            var fd = this.excelModal.datatable
            fd = fd.filter((x) => x.TglHapusBuku !== 'Error')
            res = await lou.createMaster(this.whatform + '/many', fd)
            this.afterProccessDataExcel(res)
          },
          onCancel: () => {
            // console.log('Cancel')
            return false
          },
        })
      } else {
        var fd = this.excelModal.datatable
        res = await lou.createMaster(this.whatform + '/many', fd)
        this.afterProccessDataExcel(res)
      }
    },
    afterProccessDataExcel(res) {
      if (res) {
        this.excelModalVisible = false
        this.fileupload = null
        this.$refs.excelFileUpload.value = null
        this.getAllData()
      }
    },
    async exportExcel() {
      const ExcelJS = require('exceljs')
      const workbook = new ExcelJS.Workbook()
      workbook.creator = 'LB-BPRS Heasoft System'
      workbook.lastModifiedBy = 'Me'
      workbook.created = new Date()
      workbook.modified = new Date()
      workbook.lastPrinted = new Date()
      var alphabeth = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z']
      const worksheet = workbook.addWorksheet(moment().format('DD-MM-YYYY'))

      // Table Data
      var column = this.$g.clone(this.columnDefs)
      var data = this.$g.clone(this.rowAfterFilter)
      var cfirst = 0
      var cloop = 0
      var dincrement = 2
      var columnLocation = []
      for (let i = 0; i < column.length; i++) {
        const element = column[i]
        var cloopni = cloop - 1
        if (cloop === 0) {
          columnLocation.push({
            location: alphabeth[cfirst],
            field: element.field,
          })
          worksheet.getCell(alphabeth[cfirst] + '1').value = element.field
        } else {
          columnLocation.push({
            location: alphabeth[cloopni] + alphabeth[cfirst],
            field: element.field,
          })
          worksheet.getCell(alphabeth[cloopni] + alphabeth[cfirst] + '1').value = element.field
        }
        for (let u = 0; u < data.length; u++) {
          const el = data[u]
          if (cloop === 0) {
            worksheet.getCell(alphabeth[cfirst] + dincrement).value = el[element.field]
          } else {
            worksheet.getCell(alphabeth[cloopni] + alphabeth[cfirst] + dincrement).value = el[element.field]
          }
          var checklast = u + 1
          if (checklast === data.length) {
            dincrement = 2
          } else {
            dincrement += 1
          }
        }
        if (cfirst === 25) {
          cfirst = 0
          cloop += 1
        } else {
          cfirst += 1
        }
      }
      // var last = data.length + 3
      // console.log('last', last)
      // worksheet.mergeCells('A' + last + ':B' + last)
      // worksheet.getCell('A' + last).value = 'Total'
      // for (let ti = 0; ti < this.columnDefsTotal.length; ti++) {
      //   const element = this.columnDefsTotal[ti]
      //   var targetLoc = columnLocation.findIndex(x => x.field === element.field)
      //   if (targetLoc >= 0) {
      //     worksheet.getCell(columnLocation[targetLoc].location + last).value = this.rowDataTotal[0][element.field]
      //   }
      //   // console.log(columnLocation[targetLoc].location + last, this.rowDataTotal[0][element.field])
      // }
      // write to a new buffer
      const buffer = await workbook.xlsx.writeBuffer()
      var blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
      var link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = 'Export Excel Form F01.xlsx'
      link.click()
    },
    clearData() {
      Modal.confirm({
        title: 'Apakah anda yakin?',
        content: 'Data tidak dapat dikembalikan!',
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'No',
        onOk: async () => {
          var res = await lou.customUrlDelete(this.whatform + '/clear')
          if (res) {
            this.getAllData()
            // this.rowData = []
            // this.backupRowData = []
            // this.rowDataTotal = []
          }
        },
        onCancel: () => {
          // console.log('Cancel')
          return false
        },
      })
    },
    ambilRekap() {
      var sp = this.specialformf01
      // console.log('sp.rowData[1].kode_kondis', sp.rowData)
      var groupingofkodekondisi = this.rowData.reduce(function (r, a) {
        if (a.kode_kondisi !== undefined && a.kode_kondisi !== null) {
          r[a.kode_kondisi] = r[a.kode_kondisi] || []
          r[a.kode_kondisi].push(a)
        }
        return r
      }, Object.create(null))
      var arrkodekondisi = Object.keys(groupingofkodekondisi)
      var groupingofkodekantorcabang = this.rowData.reduce(function (r, a) {
        if (a.kode_kantor_cabang !== undefined && a.kode_kantor_cabang !== null) {
          r[a.kode_kantor_cabang] = r[a.kode_kantor_cabang] || []
          r[a.kode_kantor_cabang].push(a)
        }
        return r
      }, Object.create(null))
      var arrkodekantorcabang = Object.keys(groupingofkodekantorcabang)
      sp.rowData = []
      // console.log('groupingofkodekondisi', groupingofkodekondisi)
      // console.log('arrkodekondisi', arrkodekondisi)
      var totalbaki = 0
      var totaljumlahrek = 0
      var datatotal = {
        kode_kondisi: 'Total',
        baki_debet: totalbaki,
        jumlah_rek: totaljumlahrek,
        keterangan: '',
      }
      sp.rowData.push(datatotal)
      for (let i = 0; i < arrkodekondisi.length; i++) {
        const el = arrkodekondisi[i]
        var targetIndex = this.allMaster.kode_kondisi.findIndex(x => x.kode === el)
        // console.log(el, this.allMaster.kode_kondisi[targetIndex].keterangan)
        var datasum = this.newgetsum('baki_debet', 'kode_kondisi', el)
        var data = {
          kode_kondisi: el,
          baki_debet: datasum.total,
          jumlah_rek: datasum.jumlah,
          keterangan: this.allMaster.kode_kondisi[targetIndex].keterangan,
        }
        sp.rowData.push(data)
        totalbaki += datasum.total
        totaljumlahrek += datasum.jumlah
      }
      sp.rowData[0].baki_debet = totalbaki
      sp.rowData[0].jumlah_rek = totaljumlahrek

      var t2kolek1 = this.newgetsum('baki_debet', 'kode_kolektibilitas', sp.rowData1[0].kolektibilitas)
      // console.log('t2kolek1', t2kolek1)
      var t2kolek2 = this.newgetsum('baki_debet', 'kode_kolektibilitas', sp.rowData1[1].kolektibilitas)
      // console.log('t2kolek2', t2kolek2)
      var t2kolek3 = this.newgetsum('baki_debet', 'kode_kolektibilitas', sp.rowData1[2].kolektibilitas)
      // console.log('t2kolek3', t2kolek3)
      var t2kolek4 = this.newgetsum('baki_debet', 'kode_kolektibilitas', sp.rowData1[3].kolektibilitas)
      // console.log('t2kolek4', t2kolek4)
      var t2kolek5 = this.newgetsum('baki_debet', 'kode_kolektibilitas', sp.rowData1[4].kolektibilitas)
      // console.log('t2kolek5', t2kolek5)
      sp.rowData1[0].baki_debet = t2kolek1.total
      sp.rowData1[0].jumlah_rek = t2kolek1.jumlah
      sp.rowData1[1].baki_debet = t2kolek2.total
      sp.rowData1[1].jumlah_rek = t2kolek2.jumlah
      sp.rowData1[2].baki_debet = t2kolek3.total
      sp.rowData1[2].jumlah_rek = t2kolek3.jumlah
      sp.rowData1[3].baki_debet = t2kolek4.total
      sp.rowData1[3].jumlah_rek = t2kolek4.jumlah
      sp.rowData1[4].baki_debet = t2kolek5.total
      sp.rowData1[4].jumlah_rek = t2kolek5.jumlah
      sp.rowData1[5].baki_debet = parseInt(t2kolek1.total) + parseInt(t2kolek2.total) + parseInt(t2kolek3.total) + parseInt(t2kolek4.total) + parseInt(t2kolek5.total)
      sp.rowData1[5].jumlah_rek = parseInt(t2kolek1.jumlah) + parseInt(t2kolek2.jumlah) + parseInt(t2kolek3.jumlah) + parseInt(t2kolek4.jumlah) + parseInt(t2kolek5.jumlah)

      sp.rowData2 = []
      var allkolek1 = this.getsum('baki_debet', 'kode_kolektibilitas', sp.rowData1[0].kolektibilitas)
      var allkolek2 = this.getsum('baki_debet', 'kode_kolektibilitas', sp.rowData1[1].kolektibilitas)
      var allkolek3 = this.getsum('baki_debet', 'kode_kolektibilitas', sp.rowData1[2].kolektibilitas)
      var allkolek4 = this.getsum('baki_debet', 'kode_kolektibilitas', sp.rowData1[3].kolektibilitas)
      var allkolek5 = this.getsum('baki_debet', 'kode_kolektibilitas', sp.rowData1[4].kolektibilitas)
      var alltotal345 = allkolek3.total + allkolek4.total + allkolek5.total
      var alltotalallbaki = parseInt(allkolek1.total) + parseInt(allkolek2.total) + parseInt(allkolek3.total) + parseInt(allkolek4.total) + parseInt(allkolek5.total)
      var alltotalalljumlahrek = parseInt(allkolek1.jumlah) + parseInt(allkolek2.jumlah) + parseInt(allkolek3.jumlah) + parseInt(allkolek4.jumlah) + parseInt(allkolek5.jumlah)

      var allnpl = alltotal345 / alltotalallbaki
      var alld = {
        cabang: '000',
        baki_debet: alltotalallbaki,
        npl: Math.floor(allnpl * 100) + '%',
        jumlah_rek: alltotalalljumlahrek,
      }
      sp.rowData2.push(alld)
      for (let i = 0; i < arrkodekantorcabang.length; i++) {
        const element = arrkodekantorcabang[i]
        var kolek1 = this.customgetsum('baki_debet', 'kode_kolektibilitas', sp.rowData1[0].kolektibilitas, element)
        var kolek2 = this.customgetsum('baki_debet', 'kode_kolektibilitas', sp.rowData1[1].kolektibilitas, element)
        var kolek3 = this.customgetsum('baki_debet', 'kode_kolektibilitas', sp.rowData1[2].kolektibilitas, element)
        var kolek4 = this.customgetsum('baki_debet', 'kode_kolektibilitas', sp.rowData1[3].kolektibilitas, element)
        var kolek5 = this.customgetsum('baki_debet', 'kode_kolektibilitas', sp.rowData1[4].kolektibilitas, element)
        var total345 = kolek3.total + kolek4.total + kolek5.total
        var totalallbaki = parseInt(kolek1.total) + parseInt(kolek2.total) + parseInt(kolek3.total) + parseInt(kolek4.total) + parseInt(kolek5.total)
        var totalalljumlahrek = parseInt(kolek1.jumlah) + parseInt(kolek2.jumlah) + parseInt(kolek3.jumlah) + parseInt(kolek4.jumlah) + parseInt(kolek5.jumlah)
        var npl = total345 / totalallbaki
        var d = {
          cabang: element,
          baki_debet: totalallbaki,
          npl: Math.floor(npl * 100) + '%',
          jumlah_rek: totalalljumlahrek,
        }
        sp.rowData2.push(d)
      }
      sp.compkey += 1
      sp.compkey1 += 1
      sp.compkey2 += 1
    },
    getsum(key, groupkey, value) {
      var retvalue = {
        jumlah: 0,
        total: 0,
      }
      this.rowData.forEach((element) => {
        // console.log('element[groupkey].toString()', element[groupkey].toString())
        // console.log('value.toString()', value.toString())
        if (element[groupkey].toString() === value.toString()) {
          // console.log(key, element[key])
          retvalue.total += parseInt(element[key])
          retvalue.jumlah += 1
        }
      })
      return retvalue
    },
    customgetsum(key, groupkey, value, customKodeCabang) {
      var retvalue = {
        jumlah: 0,
        total: 0,
      }
      this.rowData.forEach((element) => {
        // console.log('element[groupkey].toString()', element[groupkey].toString())
        // console.log('value.toString()', value.toString())
        if (element.kode_kantor_cabang === customKodeCabang) {
          if (element[groupkey].toString() === value.toString()) {
          // console.log(key, element[key])
            retvalue.total += parseInt(element[key])
            retvalue.jumlah += 1
          }
        }
      })
      return retvalue
    },
    newgetsum(key, groupkey, value) {
      var retvalue = {
        jumlah: 0,
        total: 0,
      }
      if (this.specialformf01.kodecabangforrekap === '000') {
        this.rowData.forEach((element) => {
        // console.log('element[groupkey].toString()', element[groupkey].toString())
        // console.log('value.toString()', value.toString())
          if (element[groupkey].toString() === value.toString()) {
          // console.log(key, element[key])
            retvalue.total += parseInt(element[key])
            retvalue.jumlah += 1
          }
        })
      } else {
        this.rowData.forEach((element) => {
        // console.log('element[groupkey].toString()', element[groupkey].toString())
        // console.log('value.toString()', value.toString())
          if (element.kode_kantor_cabang === this.specialformf01.kodecabangforrekap) {
            if (element[groupkey].toString() === value.toString()) {
              // console.log(key, element[key])
              retvalue.total += parseInt(element[key])
              retvalue.jumlah += 1
            }
          }
        })
      }
      return retvalue
    },
    validasidata() {
      this.showErrorLength = true
      this.evaluateBtnLoading = true
      this.errorData = []
      // console.log('this.columnDefs', this.columnDefs)
      for (let u = 0; u < this.rowData.length; u++) {
        const element = this.rowData[u]
        element.columnError = []
        // console.log('element', element)
        for (let i = 0; i < this.columnDefs.length; i++) {
          const el = this.columnDefs[i]
          // console.log('el.rules', el.rules)
          this.theRealValidate(el, element)
        }
      }
      this.evaluateBtnLoading = false
      this.gridApi.refreshCells()
      // console.log('this.errorData', this.errorData)
    },
    theRealValidate(pcolumn, pdata, from = 'exportText') {
      if (pcolumn.rules !== undefined || pcolumn.rules !== '(NULL)') {
        var rules = pcolumn.rules || ''
        if (rules.includes('findkey')) {
          // console.log('findkey')
          var refkey = rules.slice(
            rules.indexOf('findkey') + 9,
            rules.indexOf(','),
          )
          var refname = rules.slice(rules.indexOf('|') + 1, rules.indexOf('~'))
          var data = {
            data: pdata,
            value: pdata[pcolumn.field],
          }
          var check = this.checkContain(refkey, refname, data)
          if (check) {
            if (from === 'onchange') {
              var indextarget = pdata.columnError.indexOf(pcolumn.field)
              if (indextarget !== -1) {
                pdata.columnError.splice(indextarget, 1)
              }
              pdata.isError = pdata.columnError.length === 0
            }
            // delete this.rowData[u].isError
          } else {
            // console.log(check, data)
            pdata.isError = true
            pdata.columnError.push(pcolumn.field)
            // this.errorData.push(pdata)
            // console.log('pdata', pdata)
            this.saveErrorData(refkey, pdata)
          }
        } else if (rules.includes('regex')) {
          // console.log('regex')
          var regex = rules.slice(
            rules.indexOf('regex') + 7,
            rules.indexOf('endregex'),
          )
          // var length = column.rules.includes('length') ? column.rules.slice(column.rules.indexOf('length') + 8, column.rules.length) : null
          // eslint-disable-next-line no-redeclare
          var data = {
            data: pdata,
            value: pdata[pcolumn.field],
          }
          // eslint-disable-next-line no-redeclare
          var check = this.regexKan(regex, data)
          if (check) {
            if (from === 'onchange') {
              // eslint-disable-next-line no-redeclare
              var indextarget = pdata.columnError.indexOf(pcolumn.field)
              if (indextarget !== -1) {
                pdata.columnError.splice(indextarget, 1)
              }
              pdata.isError = pdata.columnError.length === 0
            }
            // delete this.rowData[u].isError
          } else {
            // console.log(check, data)
            pdata.isError = true
            pdata.columnError.push(pcolumn.field)
            // this.errorData.push(pdata)
            // console.log('pdata', pdata)
            this.saveErrorData('id', pdata)
          }
        } else {
          // console.log('rules', rules)
        }
      }
    },
    handleRowDataChanged(event) {
      const index = this.rowData.length - 1
      this.gridOptions.api.ensureIndexVisible(index, 'bottom')
    },
    onlySeeError(checked) {
      this.onlyseeerror = checked
      // if (checked) {
      //   this.backupErrorData = this.$g.clone(this.rowData)
      //   this.rowData = this.rowData.filter(x => {
      //     if (x.isError !== undefined) {
      //       return x
      //     }
      //   })
      // } else {
      //   this.rowData = this.$g.clone(this.backupErrorData)
      // }
      // this.gridApi.refreshCells()
    },
    selectImportFile(to) {
      this.$refs[to].click()
    },
    txtExport() {
      var dataperiode = this.$g.clone(this.rowData)
      var user = this.$store.state.user
      var kodejenispelapor = user.KodeJenisPelapor || '0103'
      var form = this.whatform.toUpperCase()
      var periode = moment(user.periode, 'YYYY-MM-DD').endOf('months')
      var year = periode.format('YYYY')
      var month = periode.format('MM')
      var content =
        this.importTextHeader.toString() !== ''
          ? this.importTextHeader.toString().replaceAll(',', '|')
          : 'H|' + kodejenispelapor + '|' +
            user.sandibpr +
            '|' +
            year.replaceAll('-', '') +
            '|' +
            month.replaceAll('-', '') +
            '|' +
            form +
            '|' +
            this.rowData.length +
            '|' +
            this.rowData.length +
            '\r\n'
      for (let u = 0; u < dataperiode.length; u++) {
        const el = dataperiode[u]
        for (let i = 0; i < this.columnDefs.length; i++) {
          const element = this.columnDefs[i]
          var lastkah = i + 1
          var imp = lastkah === this.columnDefs.length ? '' : '|'
          var initc = i === 0 ? 'D|' : ''
          var val = el[element.field] || ''
          if (element.cellEditor === 'dateEditor') {
            if (el[element.field] !== null) {
              val = el[element.field].replaceAll('-', '')
            }
          }
          content += initc + val === null ? '' : val + imp
          if (lastkah === this.columnDefs.length) {
            content += '\r\n'
          }
        }
      }
      // any kind of extension (.txt,.cpp,.cs,.bat)

      var blob = new Blob([content], {
        type: 'text/plain;charset=utf-8',
      })
      var link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      var posisiperiode = moment(user.periode, 'YYYY-MM-DD').endOf('months')
      link.download =
        kodejenispelapor + '.' +
        user.sandibpr +
        '.' +
        posisiperiode.format('YYYY') +
        '.' +
        posisiperiode.format('MM') +
        '.' +
        form +
        '.1.txt'
      link.click()
    },
    readExcelFile() {
      this.importBtnLoading = true
      var fileupload = this.$refs.uploadexcel.files[0]
      var that = this
      const ExcelJS = require('exceljs')
      const reader = new FileReader()
      reader.onload = (function (reader) {
        return async function () {
          var content = reader.result
          var alphabeth = [
            'A',
            'B',
            'C',
            'D',
            'E',
            'F',
            'G',
            'H',
            'I',
            'J',
            'K',
            'L',
            'M',
            'N',
            'O',
            'P',
            'Q',
            'R',
            'S',
            'T',
            'U',
            'V',
            'W',
            'X',
            'Y',
            'Z',
          ]
          const workbook = new ExcelJS.Workbook()
          await workbook.xlsx.load(content)
          var worksheet = workbook.worksheets[0]
          // console.log('worksheet', )
          // console.log('lines', lines)

          var column = that.$g.clone(that.columnDefs)
          // var data = that.$g.clone(that.rowAfterFilter)
          var data = []
          var cfirst = 1
          var cloop = 0
          var dincrement = 2
          var length = worksheet._rows.length - 1
          // console.log('length', length)
          // var columnLocation = []
          for (let u = 0; u < length; u++) {
            var fd = {
              id: that.$uuid.v4(),
              newdata: true,
            }
            for (let i = 0; i < column.length; i++) {
              const element = column[i]

              var cloopni = cloop - 1
              var value = ''
              if (cloop === 0) {
                value = worksheet.getCell(alphabeth[cfirst] + dincrement).value
              } else {
                value = worksheet.getCell(
                  alphabeth[cloopni] + alphabeth[cfirst] + dincrement,
                ).value
              }
              fd[element.field] = value
              if (cfirst === 25) {
                cfirst = 0
                cloop += 1
              } else {
                cfirst += 1
              }
            }
            // var islast = u + 1
            // console.log('fd', )
            var val = fd[column[0].field]
            if (val !== null && val !== undefined && val !== '') {
              // console.log('val', val)
              data.push(fd)
            }
            dincrement += 1
            cfirst = 1
            cloop = 0
          }
          that.rowData = [...that.backupRowData, ...data]
          that.changedData = that.$g.clone(data)
          that.mode = 'oncreate'
          that.$refs.uploadexcel.value = null
          // console.log('last', last)
          // write to a new buffer
          // const buffer = await workbook.xlsx.writeBuffer()
          // var blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
          // var link = document.createElement('a')
          // link.href = window.URL.createObjectURL(blob)
          // var form = that.whatform.slice(7, that.whatform.length)
          // link.download = 'Export Excel SLIK' + form + '.xlsx'
          // link.click()
          // that.filetextarr = lines
          // that.scrapExcelFile()
        }
      })(reader)

      reader.readAsArrayBuffer(fileupload)
      this.importBtnLoading = false
    },
    readTextFile() {
      this.cancelaction()
      lou.shownotif('Loading...', 'Data onprocessing...', 'info', 1)
      this.importBtnLoading = true
      var fileupload = this.$refs.uploadtext.files[0]
      // console.log('this.rowData.length', this.rowData.length)
      if (this.rowData.length === 0) {
        this.rowData = null
      }
      var that = this
      setTimeout(() => {
        const reader = new FileReader()
        reader.onload = (function (reader) {
          return function () {
            var content = reader.result
            var lines = content.split('\r\n')
            // console.log('content', content)
            // console.log('lines', lines)
            that.filetextarr = lines
            // console.log('this.rowData', that.rowData)
            that.scrapTextFile()
          }
        })(reader)
        reader.readAsText(fileupload)
      }, 300)
      this.importBtnLoading = false
    },
    scrapTextFile() {
      var header = []
      var data = []
      for (let u = 0; u < this.filetextarr.length; u++) {
        const element = this.filetextarr[u]
        var aftersplit = []
        if (u === 0) {
          aftersplit = element.split('|')
          header = aftersplit
        } else {
          aftersplit = element.split('|')
          // console.log('aftersplit', aftersplit)
          var md = {
            id: this.$uuid.v4(),
            periode: header[3][4],
            newdata: true,
          }
          for (let i = 0; i < this.columnDefs.length; i++) {
            const element = this.columnDefs[i]
            // console.log('element', element)
            if (
              element.field.includes('tanggal') ||
              element.field.includes('Tanggal')
            ) {
              md[element.field] =
                aftersplit[i] !== ''
                  ? moment(aftersplit[i], 'YYYYMMDD').format('YYYY-MM-DD')
                  : aftersplit[i]
            } else {
              md[element.field] = aftersplit[i]
            }
          }
          var last = u + 1
          if (last !== this.filetextarr.length) {
            data.push(md)
          }
        }
      }
      // console.log('header', header)
      // console.log('data', data)
      this.importTextHeader = header
      this.rowData = [...data, ...this.backupRowData]
      this.changedData = this.$g.clone(data)
      this.mode = 'oncreate'
      this.$refs.uploadtext.value = null
      lou.shownotif('Done', 'Text data selesai di baca.', 'success')
      // this.autoSizeAll(false, { autosizecolumn: true })
    },
    evaluate() {
      this.showErrorLength = true
      this.evaluateBtnLoading = true
      var length = this.rowData.length / 15
      // var length = this.rowData.length <= 1000 ? this.rowData.length / 15 : this.rowData.length / 10
      for (let ind = 0; ind < Math.round(length); ind++) {
        var timer = 100
        setTimeout(() => {
          // var nasdf = length <= 30 ? ind * 15 : ind * 10
          var nasdf = ind * 15
          const index = this.rowData.length - nasdf
          this.gridOptions.api.ensureIndexVisible(index, 'bottom')
        }, timer)
      }
      setTimeout(() => {
        this.evaluateBtnLoading = false
      }, 100)
    },
    async getColumnDefs(form = 'f01') {
      var nindexeddb = await lou.getDataFromDb()
      // console.log('nindexxeddb', nindexeddb)
      var gcIndexTarget = nindexeddb.findIndex(x => x.mykey === 'allGridConfig' + this.settings.whatapp.appName + this.settings.whatapp.id)
      // console.log('this.settings.whatapp', this.settings.whatapp)
      var column = []
      var res = null
      // console.log('res.data', res.data)
      if (gcIndexTarget < 0) {
        res = await lou.customUrlGet('master/tables', false, true)
        // console.log('res.data', res.data)
        column = res.data[form]
        var master = {
          mykey: 'allGridConfig' + this.settings.whatapp.appName + this.settings.whatapp.id,
          data: JSON.stringify(res.data),
        }
        await lou.addDataToDb(master)
        // console.log('column', column)
      } else {
        var dataFDB = JSON.parse(nindexeddb[gcIndexTarget].data)
        // console.log('dataFDB', dataFDB)
        if (dataFDB[form] !== undefined) {
          column = dataFDB[form]
        } else {
          await lou.deleteDataFromDb(nindexeddb[gcIndexTarget].id)
          res = await lou.customUrlGet('master/tables', false, true)
          column = res.data[form]
          // console.log('column', column)
          var masters = {
            mykey: 'allGridConfig' + this.settings.whatapp.appName + this.settings.whatapp.id,
            data: JSON.stringify(res.data),
          }
          await lou.addDataToDb(masters)
        }
        // console.log('column', column)
      }
      this['columnDefs' + form] = []
      this.contohdata = {}
      for (let i = 0; i < column.length; i++) {
        const element = column[i]
        var data = element
        if (data.valueFormatter === '') {
          delete data.valueFormatter
        } else {
          if (data.valueFormatter === 'currency') {
            this.columnCurrency.push({
              field: data.field,
              headerName: data.headerName,
              valueFormatter: this.currencyFormatter,
            })
            data.valueFormatter = this.currencyFormatter
          }
        }
        if (data.reftable !== undefined || data.reftable !== '') {
          var object = this.allMaster[data.reftable]
          if (object !== undefined) {
            data.cellEditor = 'selectEditor'
            data.cellEditorParams = {
              values: object,
              reffield: data.retreffield,
            }
          }
        }
        if (data.cellEditor === '') delete data.cellEditor
        if (data.pinned === '') delete data.pinned
        data.editable = data.editable === 'true'
        data.resizable = data.resizable === 'true'
        data.sortable = data.sortable === 'true'
        // console.log('data.rules', data.rules)
        if (data.filter === 'true') {
          data.filter = true
        } else if (data.filter === 'false') {
          data.filter = false
        }
        // if (data.rules !== null) {
        //   if (data.rules.includes('x ') || data.rules.includes('x.length ')) {
        //     data.cellClassRules = { 'text-danger': data.rules }
        //   }
        //   data.cellClass = (params) => {
        //     // console.log('params', params)
        //     // var targetError = this.errorData.findIndex(x => x. === params.value)
        //     var check =
        //       params.data.columnError !== undefined
        //         ? params.data.columnError.includes(params.colDef.field)
        //         : false
        //     return check ? 'text-danger' : ''
        //   }
        // } else {
        //   delete data.rules
        // }
        data.field = data.field.toLowerCase()
        // data.rules = data.rules
        data.tooltipField = data.field
        data.tooltipComponent = 'customTooltip'
        this.contohdata[data.field] = ''
        if (form === 'f01') {
          this.columnDefs.push(data)
        } else {
          this['columnDefs' + form].push(data)
        }
      }
      // console.log('this.columnDefs', this.columnDefs)
    },
    getStyle(error, params) {
      // console.log('params', params)
      // var that = this
      var target = -1
      var targetRow = params.rowIndex
      if (error) {
        target = this.errorData.findIndex((x) => x.id === params.data.id)
        if (target >= 0) {
          delete this.rowData[targetRow].isError
        }
        // setTimeout(function() { that.gridApi.redrawRows(params.node) }, 100)
        return { color: 'red' }
      } else {
        target = this.errorData.findIndex((x) => x.id === params.data.id)
        if (target >= 0) {
          this.rowData[targetRow].isError = true
        }
        // setTimeout(function() { that.gridApi.redrawRows(params.node) }, 100)
        return { color: '' }
      }
    },
    saveErrorData(key, data) {
      // console.log('data', data)
      if (data !== undefined) {
        var target = this.errorData.findIndex((x) => x[key] === data[key])
        if (target < 0) {
          this.errorData.push(data)
        } else {
          this.errorData[target] = this.$g.clone(data)
        }
      }
      // console.log('this.errorData', this.errorData)
    },
    checkContain(key, ref, value) {
      // console.log('key', key)
      // console.log('ref', ref)
      // console.log('value', value)
      // console.log('this.allMaster[ref]', this.allMaster[ref])
      var indexTarget = this.allMaster[ref].findIndex(
        (x) => x[key] === value.value,
      )
      // console.log('indexTarget', indexTarget)
      if (indexTarget < 0) {
        // console.log('false')
        // console.log('value.data', value.data)
        // this.saveErrorData(key, value.data)
        // var target = this.errorData.findIndex(x => x.id === value.data.id)
        // if (target >= 0) {
        //   delete this.rowData[value.rowIndex].isWarning
        // }
        // console.log('this.errorData', this.errorData)
        return false
      } else {
        // console.log('true')
        // eslint-disable-next-line no-redeclare
        // var target = this.errorData.findIndex(x => x.id === value.data.id)
        // if (target >= 0) {
        //   this.rowData[value.rowIndex].isWarning = true
        // }
        return true
      }
    },
    regexKan(regex, params) {
      // console.log('regex', regex)
      var re = new RegExp(regex, 'gm')
      // console.log('re', re)
      var value = 'false'
      if (params.value !== undefined) {
        value = params.value.match(re) || 'false'
      }
      // console.log('value', value)
      // console.log('value !== "false"', value !== 'false')
      if (value === 'false') {
        // console.log('value.data', value.data)
        // this.saveErrorData('id', params.data)
        // console.log('this.errorData', this.errorData)
      }
      return value !== 'false'
    },
    async getAllData(withNotif = false) {
      if (withNotif) {
        lou.shownotif('Loading!', 'Data sedang direfresh')
      }
      this.rowData = null
      var res = await lou.readMaster(this.whatform)
      // console.log('res', res.data)
      if (res) {
        this.rowData = res.data
        this.backupRowData = this.$g.clone(this.rowData)
        this.rowAfterFilter = this.$g.clone(this.rowData)
        if (withNotif) {
          lou.shownotif('Loading!', 'Data berhasil direfresh', 'success')
        }
      } else {
        this.rowData = []
        if (withNotif) {
          lou.shownotif('Loading!', 'Data berhasil direfresh. Tapi data kosong!', 'success')
        }
      }
    },
    async getAllMaster() {
      var indexeddb = await lou.getDataFromDb()
      // console.log('indexeddb', indexeddb)
      var allmaster = {}
      var masterIndex = indexeddb.findIndex(x => x.mykey === 'allmasterslik')
      // console.log('masterIndex', indexeddb[masterIndex])
      if (masterIndex < 0) {
        var res = await lou.customUrlGet('master', false, true)
        var master = {
          mykey: 'allmasterslik',
          data: JSON.stringify(res.data),
        }
        await lou.addDataToDb(master)
        var nindexeddb = await lou.getDataFromDb()
        var targetIndex = nindexeddb.findIndex(x => x.mykey === 'allmasterslik')
        // console.log('nindexeddb', nindexeddb[targetIndex])
        allmaster = JSON.parse(nindexeddb[targetIndex].data)
      } else {
        allmaster = JSON.parse(indexeddb[masterIndex].data)
      }
      // console.log('indexeddb', indexeddb)
      this.allMaster = allmaster
      // console.log('this.allMaster', this.allMaster)
      this.getColumnDefs()
      this.getAllData()
    },
    onCellValueChanged(params) {
      var undoSize = params.api.getCurrentUndoSize()
      this.input.undoInput = undoSize
      this.input.undoInputdisable = undoSize < 1
      var redoSize = params.api.getCurrentRedoSize()
      this.input.redoInput = redoSize
      this.input.redoInputdisable = redoSize < 1
      if (this.mode === 'oncreate') {
        if (params.data.newdata) {
          var target = this.changedData.findIndex(
            (x) => x.id === params.data.id,
          )
          if (target < 0) {
            this.changedData.push(params.data)
          } else {
            this.changedData[target] = params.data
          }
          // console.log('this.changedData', this.changedData)
        } else {
          // this.theRealValidate(params.colDef, params.data, 'onchange')
          // eslint-disable-next-line no-redeclare
          var target = this.rowData.findIndex((x) => x.id === params.data.id)
          var backupTarget = this.backupRowData.findIndex(
            (x) => x.id === params.data.id,
          )
          // console.log('this.rowData[target] before:', this.rowData[target])
          this.rowData[target] = this.$g.clone(
            this.backupRowData[backupTarget],
          )
          // console.log('this.rowData[target] after:', this.rowData[target])
          // console.log('this.backupRowData[backupTarget]', this.backupRowData[backupTarget])
          // // this.componentKey += 1
          this.changedData = []
          this.input.undoInput = 0
          this.input.undoInputdisable = true

          this.input.redoInput = 0
          this.input.redoInputdisable = true
          lou.shownotif('Anda sedang dalam mode create')
        }
      } else {
        this.mode = 'onupdate'
        // eslint-disable-next-line no-redeclare
        var targetChanged = this.changedData.findIndex(
          (x) => x.id === params.data.id,
        )
        if (targetChanged < 0) {
          // var cd = {}
          // cd.id = params.data.id
          // cd[params.column.colId] = value.value
          // console.log('cd[params.column.colId]', cd)
          // console.log('value.value', value)
          this.changedData.push(params.data)
        } else {
          // this.theRealValidate(params.colDef, params.data, 'onchange')
          this.changedData[targetChanged][params.column.colId] =
            params.newValue
        }
        // console.log('this.changedData', this.changedData)
        // this.componentKey += 1
      }
    },
    async saveChanges() {
      var fd = this.changedData.length !== 0 ? this.$g.clone(this.changedData) : []
      // var user = this.$store.state.user
      if (fd.length === 0) lou.shownotif('Belum ada perubahan')
      fd.forEach((element) => {
        for (let i = 0; i < this.columnDefs.length; i++) {
          const column = this.columnDefs[i]
          if (column.field === 'Operasi_Data' || column.field === 'operasi_Data' || column.field === 'operasi_data') {
            if (element[column.field] !== undefined) {
              element[column.field] = element[column.field].replaceAll('\r', '')
            }
          } else {
            if (column.field === 'prosentase_suku_bunga_imbalan') {
              element[column.field] =
              element[column.field] === '' ? null : parseFloat(element[column.field].toString())
            } else {
              element[column.field] =
              element[column.field] === '' ? null : element[column.field]
            }
          }
        }
        if (element.isError !== undefined) {
          delete element.isError
        }
      })
      if (this.mode === 'oncreate') {
        fd.forEach((element) => {
          delete element.id
          if (element.newdata) {
            delete element.newdata
          }
        })
      } else if (this.mode === 'onupdate') {
        fd.forEach((element) => {
          element.id = parseInt(element.id)
        })
      }
      // fd[0].tglmulai = fd[0].tglmulai.slice(0, 10)
      // fd[0].tgljatuhtempo = fd[0].tgljatuhtempo.slice(0, 10)
      // fd[0].tglpenilaianterakhir = fd[0].tglpenilaianterakhir.slice(0, 10)
      var res = {}
      if (this.mode === 'oncreate') {
        // console.log('fd', JSON.stringify(fd))
        Modal.confirm({
          title: 'Replace All data?',
          content: "You can't prevent this!!! ",
          okText: 'Replace',
          okType: 'danger',
          cancelText: 'No Replace',
          onOk: async () => {
            var url = 'api/' + this.whatform + '/many?ReplaceAll=true'
            res = await lou.customUrlPost2(url, fd, true, false)
            this.afterSubmit(res)
          },
          onCancel: async () => {
            res = await lou.createMasterMany(this.whatform, fd)
            this.afterSubmit(res)
          },
        })
      } else {
        res = await lou.updateMasterMany(this.whatform, fd)
        this.afterSubmit(res)
        // console.log('fd', fd)
      }
    },
    afterSubmit(res) {
      if (res) {
        this.mode = 'netral'
        this.changedData = []
        this.rowData.forEach((element) => {
          if (element.newdata) {
            delete element.newdata
          }
          if (element.edited) {
            delete element.edited
          }
        })
        this.getAllData()
      }
    },
    addNullData() {
      this.mode = 'oncreate'
      var fd = {}
      this.columnDefs.forEach((element, index) => {
        var booltgl = element.cellEditor === 'dateEditor'
        if (element.field === 'flagdetail' || element.field === 'FlagDetail' || element.field === 'Flagdetail') {
          fd[element.field] = 'D01'
        } else if (booltgl) {
          fd[element.field] = moment().format('YYYY-MM-DD')
        } else if (element.cellEditor === 'numericEditor') {
          fd[element.field] = 0
        } else {
          fd[element.field] = ''
        }
      })
      fd.id = this.$uuid.v4()
      fd.newdata = true
      this.rowData.splice(0, 0, fd)
    },
    cancelaction() {
      // console.log('this.mode', this.mode)
      if (this.mode === 'oncreate') {
        var maped = this.rowData.filter((x) => x.newdata === undefined)
        // console.log('maped', maped)
        this.rowData = maped
        this.backupErrorData = this.$g.clone(this.rowData)
        this.errorData = []
      } else {
        // var changedRowNode = []
        this.rowData =
          this.backupRowData.length === 0
            ? []
            : this.$g.clone(this.backupRowData)
        this.backupErrorData = this.$g.clone(this.rowData)
        this.errorData = []
      }
      this.mode = 'netral'
      this.changedData = []
      this.input.undoInput = 0
      this.input.undoInputdisable = true

      this.input.redoInput = 0
      this.input.redoInputdisable = true
    },
    removeOrDelete() {
      // console.log('this.selectedData', this.selectedData)
      if (this.selectedData === null) {
        lou.shownotif(
          'Anda belum memilih data atau data yang anda pilih harus tidak boleh lebih dari 1',
        )
      } else {
        if (this.selectedData[0].newdata) {
          var indexTarget = this.rowData.findIndex(
            (x) => x.id === this.selectedData[0].id,
          )
          this.rowData.splice(indexTarget, 1)
        } else {
          this.confirmationDelete(this.selectedData[0].id)
          // send to api
        }
      }
    },
    async confirmationDelete(id) {
      Modal.confirm({
        title: 'Are you sure delete this data?',
        content: "You can't prevent this!!! ",
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'No',
        onOk: async () => {
          await lou.deleteMaster(this.whatform, id)
          this.getAllData()
        },
        onCancel: () => {
          // console.log('Cancel')
        },
      })
    },
    undo() {
      this.gridApi.undoCellEditing()
    },
    redo() {
      this.gridApi.redoCellEditing()
    },
    currencyFormatter(params) {
      var ret = params.value === null ? 'NaN' : lou.curency(params.value)
      return ret === 'NaN' ? '' : ret
    },
    autoSizeAll(skipHeader, { autosizecolumn = true }, form = '') {
      if (autosizecolumn) {
        var allColumnIds = []
        // console.log('this.gridColumnApi', this.gridColumnApi)
        this['gridColumnApi' + form].getAllColumns().forEach(function (column) {
          allColumnIds.push(column.colId)
        })
        this['gridColumnApi' + form].autoSizeColumns(allColumnIds, skipHeader)
      } else {
        this['gridApi' + form].sizeColumnsToFit()
      }
    },
    onSelectionChanged() {
      var selectedRows = this.gridApi.getSelectedRows()
      // console.log('selectedRows', selectedRows)
      this.selectedData = selectedRows
      if (this.detailVisibility) {
        this.getSpesificsCIF()
      }
    },
    onFirstDataRendered(form = '') {
      // this['gridColumnApi' + form].autoSizeColumns()
      this.autoSizeAll(false, { autosizecolumn: true }, form)
    },
    onGridReady(params, form = 'f01') {
      // params.api.sizeColumnsToFit()
      this['gridApi' + form] = params.api
      this['gridColumnApi' + form] = params.columnApi
    },
    requestChangedToSlik() {
      Modal.confirm({
        title: 'Ubah ke slik mode?',
        content: 'Anda dapat merubah ini nanti!',
        okText: 'Yes',
        closable: false,
        okType: 'primary',
        cancelButtonProps: { style: { display: 'none' } },
        onOk: () => {
          this.$store.commit('CHANGE_SETTING', {
            setting: 'whatapp',
            value: 'SLIK',
          })
          this.getAllData()
        },
        onCancel: () => {
          // console.log('Cancel')
        },
      })
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      )
    },
  },
  // beforeDestroy () {
  //   // console.log('this.$route', this.$router)
  //   if (!this.$router.currentRoute.fullPath.includes('slik')) {
  //     this.$store.commit('CHANGE_SETTING', { setting: 'whatapp', value: 'LBBPRS' })
  //     // console.log('this.settings.whatapp', this.settings.whatapp)
  //   }
  // },
}
</script>

<style lang="scss">
.bg-kukalauerror {
  background-color: #ffe7e6 !important;
}
[data-kit-theme="dark"] {
  .bg-kukalauerror {
    background-color: rgba(207, 102, 121, 0.3) !important;
  }
}

.ant-card-body {
  padding-top: 10px !important;
}

ant-collapse-content-box {
  padding: 0 !important;
}
</style>
