<template>
  <div>
    <a-card>
      <template slot="title">
        <strong class="mr-3">APU PPT</strong><a-date-picker
          class="ml-4"
          style="width: 150px"
          v-model="tgl1"
          :allowClear="true"
          format="DD-MM-YYYY"
          @change="filterDate"
        />
        <!-- <a-range-picker class="ml-3" :format="'DD-MM-YYYY'" @change="rangedofunction"/> -->
        <!-- <a-button
          type="button"
          class="btn btn-outline-primary ml-2"
          @click="connector('nonanggota')"
        >
          Tambah Non Nasabah
        </a-button> -->
        <!-- <a-button-group>
          <a-button
            type="button"
            class="btn btn-outline-primary"
            @click="showModal('create', {})"
          >
            Tambah
          </a-button>
          <a-button class="btn btn-outline-success" @click="getAllData(true)"
            >Refresh Data</a-button
          >
        </a-button-group> -->
        <a-button-group class="ml-2">
          <a-button class="btn btn-outline-primary" @click="getAllData(true)"
          >Refresh Data</a-button
          >
          <a-button
          class="btn btn-outline-success float-"
          @click="exportExcel"
          >Export Data</a-button
          >
          <!-- <a-button
          class="btn btn-outline-warning float-warning"
          @click="exportPdf"
          >Export PDF</a-button
          > -->
        </a-button-group>
        </template>
      <a-table
      :scroll="{ x: 3000 }"
        :columns="columns"
        :dataSource="data"
        style="margin: 0px"
        :pagination="{
          hideOnSinglePage: true,
          defaultPageSize: 10,
          showQuickJumper: true,
          showSizeChanger: true,
          showTotal: (total) => `Total ${total} items`,
          pageSizeOptions: ['10', '20', '30'],
        }"
        size="small"
        :loading="loading"
      >
        <div
          slot="filterDropdown"
          slot-scope="{
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
            column,
          }"
          style="padding: 8px"
        >
          <a-input
            v-ant-ref="(c) => (searchInput = c)"
            :placeholder="`Search ${column.dataIndex}`"
            :value="selectedKeys[0]"
            style="width: 188px; margin-bottom: 8px; display: block"
            @change="
              (e) => setSelectedKeys(e.target.value ? [e.target.value] : [])
            "
            @pressEnter="
              () => handleSearch(selectedKeys, confirm, column.dataIndex)
            "
          />
          <a-button
            type="primary"
            icon="search"
            size="small"
            style="width: 90px; margin-right: 8px"
            @click="() => handleSearch(selectedKeys, confirm, column.dataIndex)"
          >
            Search
          </a-button>
          <a-button
            size="small"
            style="width: 90px"
            @click="() => handleReset(clearFilters)"
          >
            Reset
          </a-button>
        </div>
        <a-icon
          slot="filterIcon"
          slot-scope="filtered"
          type="search"
          :style="{ color: filtered ? '#108ee9' : undefined }"
        ></a-icon>
        <template slot="customRender" slot-scope="text, record, index, column">
          <span v-if="searchText && searchedColumn === column.dataIndex">
            <template
              v-for="(fragment, i) in text
                .toString()
                .split(new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i'))"
            >
              <mark
                v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                :key="i"
                class="highlight"
                >{{ fragment }}</mark
              >
              <template v-else>{{ fragment }}</template>
            </template>
          </span>
          <template v-else>
            {{ text }}
          </template>
        </template>
        <a slot="name" slot-scope="text" href="javascript:;">{{ text }}</a>
        <span slot="customTitle"><a-icon type="smile-o" /> Kode</span>
        <span slot="tags" slot-scope="tags">
          <a-tag v-for="tag in tags" color="blue" :key="tag">{{ tag }}</a-tag>
        </span>
        <span slot="action" slot-scope="text, record">
          <a href="javascript:void(0)" class="text-warning" @click="showModal('update', record)">
            <a-icon type="edit"></a-icon>
          </a>
          <a-divider type="vertical" />
          <a href="javascript:void(0)" class="text-danger" @click="showDeleteConfirm(record)">
            <a-icon type="delete"></a-icon>
          </a>
          <!-- <a href="javascript:;" class="ant-dropdown-link">
            More actions <a-icon type="down" />
          </a> -->
        </span>
        <span slot="numberFormat" slot-scope="text">
          {{ numberFormat(text) }}
        </span>
        <span slot="dateFormat" slot-scope="text">
          {{ formatDate(text) }}
        </span>
      </a-table>
    </a-card>
    <a-modal
      v-model="formvisible"
      title="Form Account Officer"
      :width="600"
      :dialogStyle="{ top: '10px' }"
    >
      <template slot="footer">
        <a-button class="" type="danger" @click="handleCancel">Cancel</a-button>
        <a-button class="" type="primary" @click="handleSubmit"
          >Submit</a-button
        >
      </template>
      <div class="row">
        <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2">
          <label class="mt-1">Kode</label>
        </div>
        <div class="col-xs-10 col-sm-10 col-md-10 col-lg-10">
          <a-input
            class=""
            v-model="modalInput.kode"
            placeholder="Masukan Kode"
          ></a-input>
        </div>
        <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2 mt-2">
          <label class="mt-1">Nama</label>
        </div>
        <div class="col-xs-10 col-sm-10 col-md-10 col-lg-10 mt-1">
          <a-input
            class=""
            v-model="modalInput.nama"
            placeholder="Masukan Nama"
          ></a-input>
        </div>
        <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2 mt-2">
          <label class="mt-1">Alamat</label>
        </div>
        <div class="col-xs-10 col-sm-10 col-md-10 col-lg-10 mt-1">
          <a-input
            class=""
            v-model="modalInput.alamat"
            placeholder="Masukan Alamat"
          ></a-input>
        </div>
        <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2 mt-2">
          <label class="mt-1">Telepon</label>
        </div>
        <div class="col-xs-10 col-sm-10 col-md-10 col-lg-10 mt-1">
          <a-input
            class=""
            v-model="modalInput.telepon"
            placeholder="Masukan Telepon"
          ></a-input>
        </div>
        <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2 mt-2">
          <label class="mt-1">Kota</label>
        </div>
        <div class="col-xs-10 col-sm-10 col-md-10 col-lg-10 mt-1">
          <a-input
            class=""
            v-model="modalInput.kota"
            placeholder="Masukan Kota"
          ></a-input>
        </div>
        <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2 mt-2">
          <label class="mt-1">Provinsi</label>
        </div>
        <div class="col-xs-10 col-sm-10 col-md-10 col-lg-10 mt-1">
          <a-input
            class=""
            v-model="modalInput.provinsi"
            placeholder="Masukan Provinsi"
          ></a-input>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
/* Import Script */
import { Modal } from 'ant-design-vue'
import * as lou from '@/services/data/lou'
import moment from 'moment'
import NProgress from 'nprogress'
// import Cleave from 'vue-cleave-component'
// import { mapState } from 'vuex'
/* End Script Import Script */

/* Kolom atau header dari table */
const columns = [
  // {
  //   title: 'Action',
  //   scopedSlots: { customRender: 'action' },
  //   fixed: 'left',
  //   width: 70,
  // },
  // {
  //   title: 'Periode',
  //   width: 100,
  //   dataIndex: 'periode',
  //   scopedSlots: { customRender: 'dateFormat' },
  //   // width: 100,
  // },
  {
    title: 'CIF',
    dataIndex: 'cif',
    width: 100,
    // fixed: 'left',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
    onFilter: (value, record) =>
      record.cif.toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => {
          this.searchInput.focus()
        }, 0)
      }
    },
  },
  {
    title: 'Rekening',
    dataIndex: 'rekening',
    width: 200,
    // fixed: 'left',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
    onFilter: (value, record) =>
      record.rekening.toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => {
          this.searchInput.focus()
        }, 0)
      }
    },
  },
  {
    title: 'Jenis Produk',
    width: 150,
    dataIndex: 'jenis_produk',
    scopedSlots: { customRender: 'jenis_produk' },
    // width: 100,
  },
  {
    title: 'Nominal',
    width: 150,
    dataIndex: 'nominal',
    scopedSlots: { customRender: 'nominal' },
    // width: 100,
  },
  {
    title: 'Nama',
    width: 150,
    dataIndex: 'nama',
    scopedSlots: { customRender: 'nama' },
    // width: 100,
  },
  {
    title: 'Kelamin',
    width: 100,
    dataIndex: 'kelamin',
    scopedSlots: { customRender: 'kelamin' },
    // width: 100,
  },
  {
    title: 'Tempat Lahir',
    width: 100,
    dataIndex: 'tempatlahir',
    scopedSlots: { customRender: 'tempatlahir' },
    // width: 100,
  },
  {
    title: 'Tgl Lahir',
    width: 100,
    dataIndex: 'tgllahir',
    scopedSlots: { customRender: 'dateFormat' },
    // width: 100,
  },
  {
    title: 'Jenis Identitas',
    width: 100,
    dataIndex: 'jenis_identitas',
    scopedSlots: { customRender: 'jenis_identitas' },
    // width: 100,
  },
  {
    title: 'KTP',
    width: 150,
    dataIndex: 'ktp',
    scopedSlots: { customRender: 'ktp' },
    // width: 100,
  },
  {
    title: 'NPWP',
    width: 100,
    dataIndex: 'npwp',
    scopedSlots: { customRender: 'npwp' },
    // width: 100,
  },
  {
    title: 'Status Perkawinan',
    width: 100,
    dataIndex: 'statusperkawinan',
    scopedSlots: { customRender: 'statusperkawinan' },
    // width: 100,
  },
  {
    title: 'Kewarganegaraan',
    width: 100,
    dataIndex: 'kewarganegaraan',
    scopedSlots: { customRender: 'kewarganegaraan' },
    // width: 100,
  },
  {
    title: 'Alamat',
    width: 250,
    dataIndex: 'alamat',
    scopedSlots: { customRender: 'alamat' },
    // width: 100,
  },
  {
    title: 'Provinsi',
    width: 100,
    dataIndex: 'provinsi',
    scopedSlots: { customRender: 'provinsi' },
    // width: 100,
  },
  {
    title: 'Kota',
    width: 100,
    dataIndex: 'kota',
    scopedSlots: { customRender: 'kota' },
    // width: 100,
  },
  {
    title: 'Pekerjaan',
    width: 150,
    dataIndex: 'pekerjaan',
    scopedSlots: { customRender: 'pekerjaan' },
    // width: 100,
  },
  {
    title: 'Nama Perusahaan',
    width: 100,
    dataIndex: 'namaperusahaan',
    scopedSlots: { customRender: 'namaperusahaan' },
    // width: 100,
  },
  {
    title: 'Alamat Kantor',
    width: 100,
    dataIndex: 'alamatkantor',
    scopedSlots: { customRender: 'alamatkantor' },
    // width: 100,
  },
  {
    title: 'Telepon Kantor',
    width: 100,
    dataIndex: 'teleponkantor',
    scopedSlots: { customRender: 'teleponkantor' },
    // width: 100,
  },
  {
    title: 'Sumber Dana',
    width: 100,
    dataIndex: 'sumberdana',
    scopedSlots: { customRender: 'sumberdana' },
    // width: 100,
  },
  {
    title: 'Pendapatan Kotor Bulan',
    width: 100,
    dataIndex: 'pendapatankotor_bln',
    scopedSlots: { customRender: 'pendapatankotor_bln' },
    // width: 100,
  },
  {
    title: 'Tujuan Pembukaan',
    width: 100,
    dataIndex: 'tujuan_pembukaan',
    scopedSlots: { customRender: 'tujuan_pembukaan' },
    // width: 100,
  },
  {
    title: 'Pep',
    width: 100,
    dataIndex: 'pep',
    scopedSlots: { customRender: 'pep' },
    // width: 100,
  },
  {
    title: 'Kantor',
    width: 100,
    dataIndex: 'kantor',
    scopedSlots: { customRender: 'kantor' },
    // width: 100,
  },
]
/* End script Kolom atau header dari table */

/* Data dari table */
const data = []
/* End script Data dari table */

export default {
  components: {
    // cleave: Cleave,
  },
  computed: {
    // ...mapState(['settings', 'user']),
  },
  created() {
    this.getAllData()
  },
  /* Data variable vue */
  data() {
    return {
      searchText: '',
      tgl1: moment(),
      tgl2: moment(),
      searchInput: null,
      searchedColumn: '',
      name: 'Tabletabungan',
      visible: false,
      allMaster: [],
      masterstatuskawin: [],
      masterkelamin: [],
      data,
      columns,
      datenow: '',
      componentKey: 0,

      exportColumn: [
        {
          title: 'CIF',
          dataIndex: 'cif',
          // fixed: 'left',
        },
        {
          title: 'No. Rekening',
          dataIndex: 'rekening',
          // width: 100,
        },
        {
          title: 'Jenis Produk Sesuai Nomor Rekening',
          dataIndex: 'jenis_produk',
          // fixed: 'left',
        },
        {
          title: 'Nominal Per Nomor Rekening',
          dataIndex: 'nominal',
          // width: 100,
        },
        {
          title: 'Nama Korporasi',
          dataIndex: 'nama',
          // width: 100,
        },
        {
          title: 'Jenis Kelamin',
          dataIndex: 'kelamin',
          // width: 100,
        },
        {
          title: 'Tempat Lahir',
          dataIndex: 'tempatlahir',
          // width: 100,
        },
        {
          title: 'Tanggal Lahir',
          dataIndex: 'tgllahir',
          // width: 100,
        },
        {
          title: 'Jenis Identitas',
          dataIndex: 'jenis_identitas',
          // width: 100,
        },
        {
          title: 'No Identitas',
          width: 150,
          dataIndex: 'ktp',
          scopedSlots: { customRender: 'ktp' },
          // width: 100,
        },
        {
          title: 'No NPWP',
          width: 100,
          dataIndex: 'npwp',
          scopedSlots: { customRender: 'npwp' },
          // width: 100,
        },
        {
          title: 'Status Perkawinan',
          width: 100,
          dataIndex: 'statusperkawinan',
          scopedSlots: { customRender: 'statusperkawinan' },
          // width: 100,
        },
        {
          title: 'Kewarganegaraan',
          width: 100,
          dataIndex: 'kewarganegaraan',
          scopedSlots: { customRender: 'kewarganegaraan' },
          // width: 100,
        },
        {
          title: 'Alamat',
          width: 250,
          dataIndex: 'alamat',
          scopedSlots: { customRender: 'alamat' },
          // width: 100,
        },
        {
          title: 'Provinsi',
          width: 100,
          dataIndex: 'provinsi',
          scopedSlots: { customRender: 'provinsi' },
          // width: 100,
        },
        {
          title: 'Kota/Kabupaten',
          width: 100,
          dataIndex: 'kota',
          scopedSlots: { customRender: 'kota' },
          // width: 100,
        },
        {
          title: 'Pekerjaan',
          width: 150,
          dataIndex: 'pekerjaan',
          scopedSlots: { customRender: 'pekerjaan' },
          // width: 100,
        },
        {
          title: 'Nama Perusahaan',
          width: 100,
          dataIndex: 'namaperusahaan',
          scopedSlots: { customRender: 'namaperusahaan' },
          // width: 100,
        },
        {
          title: 'Alamat Tempat Kerja',
          width: 100,
          dataIndex: 'alamatkantor',
          scopedSlots: { customRender: 'alamatkantor' },
          // width: 100,
        },
        {
          title: 'No Telepon Korporasi',
          width: 100,
          dataIndex: 'teleponkantor',
          scopedSlots: { customRender: 'teleponkantor' },
          // width: 100,
        },
        {
          title: 'Sumber Dana',
          width: 100,
          dataIndex: 'sumberdana',
          scopedSlots: { customRender: 'sumberdana' },
          // width: 100,
        },
        {
          title: 'Pendapatan Per Tahun',
          width: 100,
          dataIndex: 'pendapatankotor_bln',
          scopedSlots: { customRender: 'pendapatankotor_bln' },
          // width: 100,
        },
        {
          title: 'Maksud dan Tujuan Hub Usaha',
          width: 100,
          dataIndex: 'tujuan_pembukaan',
          scopedSlots: { customRender: 'tujuan_pembukaan' },
          // width: 100,
        },
        {
          title: 'PEP',
          width: 100,
          dataIndex: 'pep',
          scopedSlots: { customRender: 'pep' },
          // width: 100,
        },
        {
          title: 'Kantor',
          width: 100,
          dataIndex: 'kantor',
          scopedSlots: { customRender: 'kantor' },
          // width: 100,
        },
      ],

      // Modal property
      masterRekening: [],
      action: null,
      formvisible: false,
      editdata: {},
      modalInput: {
        kode: '',
        nama: '',
        alamat: '',
        telepon: '',
        kota: '',
        provinsi: '',
      },

      loading: false,
    }
  },
  /* End Data variable vue */
  mounted: function () {
    const date = moment().format('YYYY-MM-DD') // Tanggal hari ini format 2020-12-31
    // localStorage.removeItem('minibankedittabungandata')
    // this.tgl1 = date
    // this.tgl2 = date
    this.datenow = date.toString()
    // this.getAgamaF()
  },
  methods: {
    moment,
    // exportExcelTemplate() {
    //   const Excel = require('exceljs')
    //   const generateXLSX = async(res, data) => {
    //     const baseFile = './Template_NasabahKorporasi.xlsx'

    //     const wb = new Excel.Workbook()

    //     await wb.xlsx.readFile(baseFile)

    //     const ws = wb.getWorksheet(1)

    //     // loop and write data
    //     for (const [rowNum, inputData] of data.entries()) {
    //       console.log('row: ', rowNum, ', data', inputData)

    //       // increment rowNum to change the row start position if needed
    //       // for example, start at 5th row:
    //       // const row = ws.getRow(rowNum+6);
    //       const row = ws.getRow(rowNum + 1)

    //       // insert values
    //       row.getCell(1).value = inputData.pdu
    //       row.getCell(2).value = inputData.name
    //       row.getCell(3).value = inputData.appevel

    //       row.commit()
    //     }

    //     const fileName = 'excel.xlsx'

    //     res.header('Content-Type', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')
    //     res.header('Content-Disposition', 'attachment; filename=' + fileName)

    //     await wb.xlsx.write(res)

    //     res.end()
    //   }
    //   // data format
    //   const data = [{
    //     id: 1,
    //     urlImagen: 'http://placeimg.com/640/480',
    //     name: 'test national',
    //     pdu: '53014',
    //     creationDate: 2020,
    //     appevel: 'ascending',
    //     ddlevel: 'descending',
    //     mapa: 1,
    //     Module: 'Lead',
    //     sector: 'Something',
    //   }]
    //   var res = []
    //   generateXLSX(res, data)
    // },
    async exportPdf() {
      // this.amodal.selectedIndex = i
      console.log('this.tgl1', this.tgl1)
      var imbuhan =
        this.tgl1 !== null && this.tgl1 !== ''
          ? '?tgl=' + this.tgl1.format('YYYY-MM-DD') + '&preview=pdf'
          : '?preview=pdf'
      var res = await lou.ngetDocumentPdf('apuppt/api/templateperorangan' + imbuhan, false, false)
      if (res) {
        // console.log("Success", response);
        const blob = new Blob([res], {
          type: 'application/pdf',
        })
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.target = '_blank'
        // link.download = filename
        link.click()
        // const objectUrl = URL.createObjectURL(blob)
        // console.log('objectUrl', objectUrl)
        // this.amodal.ArrayBuffer = objectUrl
        URL.revokeObjectURL(blob)
        setTimeout(() => {
          this.componentKey += 1
          // this.amodalvisible = true
        }, 500)
      }
      // console.log('record.url2_invoice', record.url2_invoice)
      // window.open(record.url2_invoice, '_blank').focus()
    },
    filterDate(date, dateString) {
      this.tgl1 = date
      this.getAllData()
    },
    async showModal(action, data) {
      this.masterRekening = []
      // var res = await lou.customUrlGet2('general/master/rekening')
      this.formvisible = true
      this.action = action
      if (action === 'update') {
        this.modalInput = this.$g.clone(data)
        this.editdata = data
      }
      // if (res) {
      //   res.data.forEach((x) => {
      //     var np = {
      //       ...x,
      //       label: x.kode + ' - ' + x.keterangan,
      //     }
      //     this.masterRekening.push(np)
      //   })
      //   this.formvisible = true
      //   this.action = action
      //   // console.log('this.masterRekening', this.masterRekening)
      //   if (action === 'update') {
      //     this.modalInput = this.$g.clone(data)
      //     this.editdata = data
      //   }
      // } else {
      //   // lou.shownotif('Api!', 'Pastikan i')
      // }
    },
    async getAllData(onclick = false) {
      // console.log('excelTemplate', excelTemplate)
      this.loading = true
      var imbuhan =
        this.tgl1 !== null && this.tgl1 !== ''
          ? '?tgl=' + this.tgl1.format('YYYY-MM-DD')
          : ''
      var response = await lou.customUrlGet2('apuppt/api/templateperorangan' + imbuhan)
      if (response) {
        this.data = response.data
        this.loading = false
        if (onclick) {
          lou.shownotif('Refresh', 'Data telah di refresh.')
        }
      } else {
        this.loading = false
      }
      // console.log('this.data', this.data)
    },
    formatDate(value) {
      return moment(value).format('DD-MM-YYYY')
    },
    showDeleteConfirm(deldata) {
      Modal.confirm({
        title: 'Are you sure delete this data?',
        content: "You can't prevent this!!! ",
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'No',
        onOk: async () => {
          var response = await lou.customUrlDelete2(
            'apuppt/api/templateperorangan/' + deldata.id,
          )
          if (response) {
            this.getAllData()
          }
        },
        onCancel: () => {
          // console.log('Cancel')
        },
      })
    },
    handleSearch(selectedKeys, confirm, dataIndex) {
      confirm()
      this.searchText = selectedKeys[0]
      this.searchedColumn = dataIndex
    },

    handleReset(clearFilters) {
      clearFilters()
      this.searchText = ''
    },

    // Modal function
    async handleSubmit() {
      var fd = this.$g.clone(this.modalInput)
      var res = false
      if (this.action === 'update') {
        res = await lou.customUrlPut2('apuppt/api/templateperorangan', fd)
      } else {
        res = await lou.customUrlPost2('apuppt/api/templateperorangan', fd)
      }
      if (res) {
        this.getAllData()
        this.handleCancel()
      }
    },
    handleCancel() {
      this.formvisible = false
      this.action = 'create'
      this.masterRekening = []
      this.modalInput = {
        kode: '',
        nama: '',
        alamat: '',
        telepon: '',
        kota: '',
        provinsi: '',
      }
    },
    async exportExcel() {
      NProgress.start()
      const ExcelJS = require('exceljs')
      const workbook = new ExcelJS.Workbook()
      workbook.creator = 'LB-BPRS Heasoft System'
      workbook.lastModifiedBy = 'Me'
      workbook.created = new Date()
      workbook.modified = new Date()
      workbook.lastPrinted = new Date()
      var alphabeth = [
        'A',
        'B',
        'C',
        'D',
        'E',
        'F',
        'G',
        'H',
        'I',
        'J',
        'K',
        'L',
        'M',
        'N',
        'O',
        'P',
        'Q',
        'R',
        'S',
        'T',
        'U',
        'V',
        'W',
        'X',
        'Y',
        'Z',
      ]
      const worksheet = workbook.addWorksheet(moment().format('DD-MM-YYYY'))

      // Table Data
      var column = this.$g.clone(this.exportColumn)
      var tdata = this.$g.clone(this.data)
      var data = []
      tdata.forEach((element) => {
        var retv = {
          ...element,
          tgl:
            element.tgl === null
              ? ''
              : moment(element.tgl, 'YYYY-MM-DD').format('DD-MM-YYYY'),
        }
        data.push(retv)
      })

      // worksheet.getCell('A2').value = 'Sudah Jelas'
      // worksheet.getCell('B2').value = 'Jika no rekening lebih dari satu, pisahkan dengan koma. Misalkan: 100001, 200001,  300001'
      // worksheet.getCell('C2').value = 'Tuliskan Jenis Produk (Tabungan, Kredit, Deposito) sesuai dengan urutan nomor rekening. Misalkan: Tabungan, Kredit, Deposito'
      // worksheet.getCell('D2').value = 'Isikan jumlah total rekening (Isikan dalam nominal rupiah penuh, tanpa pemisah ribuan dan currency) sesuai dengan urutan penulisn nomor rekening di kolom B. Jika lebih dari satu pisahkan dengan tanda koma. Misalkan: 100000000, 150000000, 500000000'
      // worksheet.getCell('E2').value = 'Isikan nama perusahaan'
      // worksheet.getCell('F2').value = 'Isikan NIB/SKU/SKDU, bisa disertai tanda baca, atau spasi'
      // worksheet.getCell('G2').value = 'Isikan masa berlaku NIB/SKU/SKDU, format tanggal YYYY-MM-DD'
      // worksheet.getCell('H2').value = 'Isikan NPWP, hanya angka, tanpa tanda baca'
      // worksheet.getCell('I2').value = 'Tuliskan bidang usaha (Periksa sheet Referensi untuk menentukan Bidang Usaha)'
      // worksheet.getCell('J2').value = 'Isikan kota/kabupaten'
      // worksheet.getCell('K2').value = 'format tanggal YYYY-MM-DD'
      // worksheet.getCell('L2').value = 'Tuliskan bentuk korporasi (Periksa sheet Referensi untuk menentukan Bentuk Referensi)'
      // worksheet.getCell('M2').value = 'Tulis alamat lengkap, sesuai identitas berlaku disertai RT, RW, Kel/Desa, Kecamatan'
      // worksheet.getCell('N2').value = 'Tuliskan nama provinsi sesuai identitas/tanda pengenal yang berlaku (Periksa sheet Referensi untuk menentukan nama Provinsi)'
      // worksheet.getCell('O2').value = 'Tuliskan nama kota/kabupaten, disertai KOTA/KABUPATEN, sesuai dengan identitas/tanda pengenal yang berlaku (Periksa sheet Referensi untuk menentukan nama Kota/Kabupaten)'
      // worksheet.getCell('P2').value = 'Jika lebih dari satu, pisahkan dengan koma'
      // worksheet.getCell('Q2').value = 'Tuliskan sumber dana: Hasil Usaha, Hibah, Lainnya'
      // worksheet.getCell('R2').value = 'Isikan dalam angka sebenarnya, tanpa pemisah ribuan'
      // worksheet.getCell('S2').value = 'Tuliskan: Investasi, Transaksi Usaha, Lainnya'

      // Struktur <format TEXT>
      // worksheet.getCell('A3').value = '<format TEXT>'
      // worksheet.getCell('B3').value = '<format TEXT>'
      // worksheet.getCell('C3').value = '<format TEXT>'
      // worksheet.getCell('D3').value = '<format TEXT>'
      // worksheet.getCell('E3').value = '<format TEXT>'
      // worksheet.getCell('F3').value = '<format TEXT>'
      // worksheet.getCell('G3').value = '<format TEXT>'
      // worksheet.getCell('H3').value = '<format TEXT>'
      // worksheet.getCell('I3').value = '<format TEXT>'
      // worksheet.getCell('J3').value = '<format TEXT>'
      // worksheet.getCell('K3').value = '<format TEXT>'
      // worksheet.getCell('L3').value = '<format TEXT>'
      // worksheet.getCell('M3').value = '<format TEXT>'
      // worksheet.getCell('N3').value = '<format TEXT>'
      // worksheet.getCell('O3').value = '<format TEXT>'
      // worksheet.getCell('P3').value = '<format TEXT>'
      // worksheet.getCell('Q3').value = '<format TEXT>'
      // worksheet.getCell('R3').value = '<format TEXT>'
      // worksheet.getCell('S3').value = '<format TEXT>'

      // worksheet.getCell('A4').value = 'Baris kuning di atas adalah petunjuk pengisian. Pengisian data nasabah dimulai dari baris 6 (baris hijau) di bawah ini. Diharap tidak melakukan perubahan terhadap template ini agar tidak menjadi kendala saat pengisian data.'
      var cfirst = 0
      var cloop = 0
      var dincrement = 2
      var columnLocation = []
      for (let i = 0; i < column.length; i++) {
        const element = column[i]
        var cloopni = cloop - 1
        var hname = element.title.toString()
        if (hname !== 'Action') {
          if (cloop === 0) {
            columnLocation.push({
              location: alphabeth[cfirst],
              field: element.dataIndex,
            })
            worksheet.getCell(alphabeth[cfirst] + '1').value = hname
            // .replaceAll(
            //   '',
            //   '',
            // )
          } else {
            columnLocation.push({
              location: alphabeth[cloopni] + alphabeth[cfirst],
              field: element.dataIndex,
            })
            worksheet.getCell(
              alphabeth[cloopni] + alphabeth[cfirst] + '1',
            ).value = hname.replaceAll(' ', '')
          }
          for (let u = 0; u < data.length; u++) {
            const el = data[u]
            if (cloop === 0) {
              worksheet.getCell(alphabeth[cfirst] + dincrement).value = element.dataIndex === 'tgllahir' ? moment(el[element.dataIndex], 'YYYY-MM-DD').format('DD-MM-YYYY') : el[element.dataIndex]
            } else {
              worksheet.getCell(
                alphabeth[cloopni] + alphabeth[cfirst] + dincrement,
              ).value = element.dataIndex === 'tgllahir' ? moment(el[element.dataIndex], 'YYYY-MM-DD').format('DD-MM-YYYY') : el[element.dataIndex]
            }
            var checklast = u + 1
            if (checklast === data.length) {
              dincrement = 2
            } else {
              dincrement += 1
            }
          }
          if (cfirst === 25) {
            cfirst = 0
            cloop += 1
          } else {
            cfirst += 1
          }
        }
      }

      // // Define header style
      // const headerStyle = {
      //   font: { bold: true, size: 12 },
      //   fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'C6E0B4' } },
      //   alignment: { vertical: 'middle', horizontal: 'center' },
      //   border: {
      //     top: { style: 'thin' },
      //     left: { style: 'thin' },
      //     bottom: { style: 'thin' },
      //     right: { style: 'thin' },
      //   },
      // }
      // // Define cell style for data rows
      // const cellStyle = {
      //   font: { size: 12 },
      //   alignment: { vertical: 'middle', horizontal: 'left' },
      //   border: {
      //     top: { style: 'thin' },
      //     left: { style: 'thin' },
      //     bottom: { style: 'thin' },
      //     right: { style: 'thin' },
      //   },
      // }

      // // Apply header style to the first row
      // worksheet.getRow(1).eachCell((cell) => {
      //   cell.style = headerStyle
      // })

      // // Apply cell style to the rest of the rows
      // worksheet.eachRow((row, rowNumber) => {
      //   if (rowNumber !== 1) {
      //     row.eachCell((cell) => {
      //       cell.style = cellStyle
      //     })
      //   }
      // })

      // Customize column specific styles (if needed)
      // worksheet.getColumn(4).numFmt = '#,##0' // For nominal column (example)

      /* For sum data */
      // var last = data.length + 3
      // worksheet.mergeCells('A' + last + ':B' + last)
      // worksheet.getCell('A' + last).value = 'Total'
      // for (let ti = 0; ti < this.columnsTotal.length; ti++) {
      //   const element = this.columnsTotal[ti]
      //   var targetLoc = columnLocation.findIndex(x => x.field === element.dataIndex)
      //   if (targetLoc >= 0) {
      //     worksheet.getCell(columnLocation[targetLoc].location + last).value = this.rowDataTotal[0][element.dataIndex]
      //   }
      //   // console.log(columnLocation[targetLoc].location + last, this.rowDataTotal[0][element.dataIndex])
      // }

      // write to a new buffer
      const buffer = await workbook.xlsx.writeBuffer()
      var blob = new Blob([buffer], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      })
      var link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = 'Data Master apuppt/api/templateperorangan.xlsx'
      NProgress.done()
      link.click()
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      )
    },
    numberFormat(val) {
      return lou.curency(val)
    },
  },
}
</script>

<style scoped>
.ant-card-body {
  padding: 24px !important;
}
.highlight {
  background-color: rgb(255, 192, 105);
  padding: 0px;
}
</style>
