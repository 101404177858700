<template>
  <div>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
        <a-card>
          <template slot="title">
            <strong>{{ title }}</strong>
            <a-button
              type="reset"
              class="btn btn-sm btn-outline-danger mb-0 ml-3"
              @click="resetForm()"
            >
              Back
            </a-button>
            <a-button
              class="btn btn-sm btn-outline-primary mb-0 ml-3"
              @click="handleSubmit"
            >
              Save Changes
            </a-button>
          </template>
          <a-tabs default-active-key="1" size="small">
            <a-tab-pane key="1" tab="Data Diri">
              <div class="row">
                <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                  <div class="row">
                    <div
                      class="col-xs-3 col-sm-3 col-md-3 col-lg-3"
                      v-if="action === 'create'"
                    >
                      <label>Kode Cabang</label>
                    </div>
                    <div
                      class="col-xs-9 col-sm-9 col-md-9 col-lg-9"
                      v-if="action === 'create'"
                    >
                      <input
                        maxlength="10"
                        class="ant-input"
                        v-model="formData.cabangentry"
                        placeholder="Kode Cabang"
                      />
                    </div>
                    <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
                      <label>Tanggal Register</label>
                    </div>
                    <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                      <a-date-picker
                        v-if="action === 'create'"
                        style="width: 120px"
                        format="DD-MM-YYYY"
                        v-model="formData.tglregister"
                        placeholder="Tanggal Register"
                        :allowClear="false"
                      />
                      <input
                        v-else
                        :readOnly="true"
                        class="ant-input"
                        v-model="formData.tglregister"
                        placeholder="Tanggal Register"
                      />
                    </div>
                    <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
                      <label>Nama Lengkap</label>
                    </div>
                    <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                      <input
                        maxlength="50"
                        class="ant-input"
                        v-model="formData.nama"
                        placeholder="Nama"
                      />
                    </div>
                    <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
                      <label>Nama Ibu</label>
                    </div>
                    <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                      <input
                        maxlength="50"
                        class="ant-input"
                        v-model="formData.namaibu"
                        placeholder="Nama Ibu"
                      />
                    </div>
                    <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
                      <label>Jenis Kelamin</label>
                    </div>
                    <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                      <v-select
                        :clearable="false"
                        class="style-chooser"
                        v-model="formData.kelamin"
                        placeholder="Kelamin"
                        label="keterangan"
                        :options="dataKelamin"
                        :reduce="(tes) => tes.kode"
                      />
                    </div>
                    <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
                      <label>Gol Darah</label>
                    </div>
                    <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                      <v-select
                        :clearable="false"
                        class="style-chooser"
                        v-model="formData.golongandarah"
                        placeholder="Golongan Darah"
                        label="kode"
                        :options="dataGolDarah"
                        :reduce="(tes) => tes.kode"
                      />
                    </div>
                    <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
                      <label>Tgl/Tempat Lahir</label>
                    </div>
                    <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                      <a-date-picker
                        style="width: 120px"
                        format="DD-MM-YYYY"
                        v-model="formData.tgllahir"
                        placeholder="Tanggal Lahir"
                        :allowClear="false"
                      />
                      <input
                        style="width: 200px"
                        maxlength="100"
                        class="ant-input"
                        v-model="formData.tempatlahir"
                        placeholder="Tempat Lahir"
                      />
                      <!-- <input maxlength="10" class="ant-input" v-model="formData.tgllahir" placeholder="Tanggal Lahir"> -->
                    </div>
                    <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
                      <label>Status Kawin</label>
                    </div>
                    <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                      <v-select
                        :clearable="false"
                        class="style-chooser"
                        v-model="formData.statusperkawinan"
                        placeholder="Status Perkawinan"
                        label="keterangan"
                        :options="dataStatusPerkawinan"
                        :reduce="(tes) => tes.kode"
                      />
                    </div>
                    <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
                      <label>KTP</label>
                    </div>
                    <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                      <input
                        maxlength="10"
                        class="ant-input"
                        v-model="formData.ktp"
                        placeholder="KTP"
                      />
                    </div>
                    <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
                      <label>Tgl Berlaku Identitas</label>
                    </div>
                    <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                      <a-date-picker
                        style="width: 120px"
                        format="DD-MM-YYYY"
                        v-model="formData.tglktp"
                        placeholder="Tanggal KTP"
                        :allowClear="false"
                      />
                      <!-- <input maxlength="10" class="ant-input" v-model="formData.tglktp" placeholder="Tanggal KTP"> -->
                    </div>
                    <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
                      <label>Agama</label>
                    </div>
                    <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                      <v-select
                        :clearable="false"
                        class="style-chooser-pos-relative"
                        v-model="formData.agama"
                        placeholder="Agama"
                        label="keterangan"
                        :options="dataAgama"
                        :reduce="(tes) => tes.kode"
                      />
                    </div>
                    <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
                      <label>Pekerjaan</label>
                    </div>
                    <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                      <v-select
                        :clearable="false"
                        class="style-chooser-pos-relative"
                        label="keterangan"
                        v-model="formData.pekerjaan"
                        placeholder="Pekerjaan"
                        :options="dataPekerjaan"
                        :reduce="(tes) => tes.kode"
                      />
                      <!-- <input maxlength="10" class="ant-input" v-model="formData.pekerjaan" placeholder="Pekerjaan"> -->
                    </div>
                    <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
                      <label>Instansi</label>
                    </div>
                    <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                      <v-select
                        :clearable="false"
                        class="style-chooser-pos-relative"
                        label="keterangan"
                        v-model="formData.instansi"
                        placeholder="Instansi"
                        :options="dataInstansi"
                        :reduce="(tes) => tes.kode"
                      />
                      <!-- <input maxlength="10" class="ant-input" v-model="formData.instansi" placeholder="Instansi"> -->
                    </div>
                    <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
                      <label>NIP</label>
                    </div>
                    <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                      <input
                        maxlength="10"
                        class="ant-input"
                        v-model="formData.nip"
                        placeholder="NIP"
                      />
                    </div>
                    <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
                      <label>NPWP</label>
                    </div>
                    <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                      <input
                        maxlength="10"
                        class="ant-input"
                        v-model="formData.nonpwp"
                        placeholder="No NPWP"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                  <div class="row">
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                      <strong>Foto & Specimen Nasabah</strong>
                    </div>
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                      <center>
                        <a-card style="max-width: 450px; max-height: 450px">
                          <img
                            src="https://dummyimage.com/450x300/000/fff.png&text=Foto+Sementara"
                            alt="Testing"
                          />
                        </a-card>
                      </center>
                    </div>
                    <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
                      <label>Path</label>
                    </div>
                    <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                      <input
                        maxlength="10"
                        class="ant-input"
                        v-model="formData.path"
                        placeholder="Path"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </a-tab-pane>
            <a-tab-pane key="5" tab="Alamat Sesuai KTP">
              <div class="row">
                <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                  <div class="row">
                    <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
                      <label>Nama Identitas</label>
                    </div>
                    <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                      <input
                        maxlength="50"
                        class="ant-input"
                        v-model="formData.namaidentitas"
                        placeholder="Nama Identitas"
                      />
                    </div>
                    <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
                      <label>Alamat</label>
                    </div>
                    <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                      <!-- maxlength="10" -->
                      <input
                        class="ant-input"
                        v-model="formData.alamat"
                        placeholder="Alamat"
                      />
                    </div>
                    <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
                      <label>Telepon</label>
                    </div>
                    <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                      <input
                        maxlength="10"
                        class="ant-input"
                        v-model="formData.telepon"
                        placeholder="Telepon"
                      />
                    </div>
                    <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
                      <label>HP</label>
                    </div>
                    <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                      <input
                        maxlength="10"
                        class="ant-input"
                        v-model="formData.hp"
                        placeholder="HP"
                      />
                    </div>
                    <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
                      <label>FAX</label>
                    </div>
                    <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                      <input
                        maxlength="10"
                        class="ant-input"
                        v-model="formData.fax"
                        placeholder="FAX"
                      />
                    </div>
                    <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
                      <label>Kode Pos</label>
                    </div>
                    <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                      <input
                        maxlength="10"
                        class="ant-input"
                        v-model="formData.kodepos"
                        placeholder="Kode Pos"
                      />
                    </div>
                    <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
                      <label>Kodya</label>
                    </div>
                    <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                      <v-select
                        :clearable="false"
                        class="style-chooser-pos-relative"
                        placeholder="Kodya"
                        label="keterangan"
                        v-model="formData.kodya"
                        :options="dataKodya"
                        :reduce="(tes) => tes.kode"
                        @input="(e) => getKecamatan(e, '')"
                      />
                      <!-- <input maxlength="10" class="ant-input" v-model="formData.kodya" placeholder="Kodya"> -->
                    </div>
                    <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
                      <label>Kecamatan</label>
                    </div>
                    <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                      <v-select
                        :clearable="false"
                        class="style-chooser-pos-relative"
                        label="keterangan"
                        v-model="formData.kecamatan"
                        placeholder="Kecamatan"
                        :options="dataKecamatan"
                        :reduce="(tes) => tes.kode"
                        @input="(e) => getKelurahan('kodya', e, '')"
                      />
                      <!-- <input maxlength="10" class="ant-input" v-model="formData.kecamatan" placeholder="Kecamatan"> -->
                    </div>
                    <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
                      <label>Kelurahan</label>
                    </div>
                    <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                      <v-select
                        :clearable="false"
                        class="style-chooser-pos-relative"
                        label="keterangan"
                        v-model="formData.kelurahan"
                        placeholder="Kelurahan"
                        :options="dataKelurahan"
                        :reduce="(tes) => tes.kode"
                      />
                      <!-- <input maxlength="10" class="ant-input" v-model="formData.kelurahan" placeholder="Kelurahan"> -->
                    </div>
                    <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
                      <label>RT/RW</label>
                    </div>
                    <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                      <input
                        maxlength="10"
                        class="ant-input"
                        v-model="formData.rtrw"
                        placeholder="RT/RW"
                      />
                    </div>
                    <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
                      <label>Email</label>
                    </div>
                    <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                      <input
                        maxlength="10"
                        class="ant-input"
                        v-model="formData.email"
                        placeholder="Email"
                      />
                    </div>
                    <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
                      <label>Jenis Identitas</label>
                    </div>
                    <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                      <v-select
                        :clearable="false"
                        class="style-chooser-pos-relative"
                        v-model="formData.jenisidentitas"
                        placeholder="jenis Identitas"
                        label="keterangan"
                        :options="dataJenisIdentitas"
                        :reduce="(tes) => tes.kode"
                      />
                    </div>
                    <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
                      <label>Gelar</label>
                    </div>
                    <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                      <v-select
                        :clearable="false"
                        class="style-chooser-pos-relative"
                        label="keterangan"
                        v-model="formData.gelar"
                        placeholder="Gelar"
                        :options="dataGelar"
                        :reduce="(tes) => tes.kode"
                      />
                      <!-- <input maxlength="10" class="ant-input" v-model="formData.gelar" placeholder="Gelar"> -->
                    </div>
                  </div>
                </div>
                <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                  <div class="row">
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                      <strong>Foto Rumah</strong>
                    </div>
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                      <center>
                        <a-card style="max-width: 450px; max-height: 450px">
                          <img
                            src="https://dummyimage.com/450x300/000/fff.png&text=Foto+Sementara"
                            alt="Testing"
                          />
                        </a-card>
                      </center>
                    </div>
                    <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
                      <label>Path1</label>
                    </div>
                    <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                      <input
                        maxlength="10"
                        class="ant-input"
                        v-model="formData.path1"
                        placeholder="Path1"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </a-tab-pane>
            <a-tab-pane key="6" tab="Alamat Tempat Tinggal">
              <div class="row">
                <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                  <div class="row">
                    <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
                      <label>Alamat</label>
                    </div>
                    <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                      <!-- maxlength="10" -->
                      <input
                        class="ant-input"
                        v-model="formData.alamattinggal"
                        placeholder="Alamat"
                      />
                    </div>
                    <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
                      <label>Telepon</label>
                    </div>
                    <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                      <input
                        maxlength="10"
                        class="ant-input"
                        v-model="formData.telepontinggal"
                        placeholder="Telepon"
                      />
                    </div>
                    <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
                      <label>FAX</label>
                    </div>
                    <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                      <input
                        maxlength="10"
                        class="ant-input"
                        v-model="formData.faxtinggal"
                        placeholder="FAX"
                      />
                    </div>
                    <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
                      <label>Kode Pos</label>
                    </div>
                    <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                      <input
                        maxlength="10"
                        class="ant-input"
                        v-model="formData.kodepostinggal"
                        placeholder="Kode Pos"
                      />
                    </div>
                    <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
                      <label>Kodya</label>
                    </div>
                    <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                      <v-select
                        :clearable="false"
                        class="style-chooser-pos-relative"
                        label="keterangan"
                        v-model="formData.kodyatinggal"
                        placeholder="Kodya"
                        :options="dataKodya"
                        :reduce="(tes) => tes.kode"
                        @input="(e) => getKecamatan(e, 'Tinggal')"
                      />
                      <!-- <input maxlength="10" class="ant-input" v-model="formData.kodyatinggal" placeholder="Kodya"> -->
                    </div>
                    <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
                      <label>Kecamatan</label>
                    </div>
                    <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                      <v-select
                        :clearable="false"
                        class="style-chooser-pos-relative"
                        label="keterangan"
                        v-model="formData.kecamatantinggal"
                        placeholder="Kecamatan"
                        :options="dataKecamatanTinggal"
                        :reduce="(tes) => tes.kode"
                        @input="
                          (e) => getKelurahan('kodyatinggal', e, 'Tinggal')
                        "
                      />
                      <!-- <input maxlength="10" class="ant-input" v-model="formData.kecamatantinggal" placeholder="Kecamatan"> -->
                    </div>
                    <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
                      <label>Kelurahan</label>
                    </div>
                    <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                      <v-select
                        :clearable="false"
                        class="style-chooser-pos-relative"
                        label="keterangan"
                        v-model="formData.kelurahantinggal"
                        placeholder="Kelurahan"
                        :options="dataKelurahanTinggal"
                        :reduce="(tes) => tes.kode"
                      />
                      <!-- <input maxlength="10" class="ant-input" v-model="formData.kelurahantinggal" placeholder="Kelurahan"> -->
                    </div>
                    <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
                      <label>RT/RW</label>
                    </div>
                    <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                      <input
                        maxlength="10"
                        class="ant-input"
                        v-model="formData.rtrwtinggal"
                        placeholder="RT/RW"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                  <div class="row">
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                      <strong>Foto Rumah</strong>
                    </div>
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                      <center>
                        <a-card style="max-width: 450px; max-height: 450px">
                          <img
                            src="https://dummyimage.com/450x300/000/fff.png&text=Foto+Sementara"
                            alt="Testing"
                          />
                        </a-card>
                      </center>
                    </div>
                    <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
                      <label>Path1</label>
                    </div>
                    <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                      <input
                        maxlength="10"
                        class="ant-input"
                        v-model="formData.path1"
                        placeholder="Path1"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </a-tab-pane>
            <a-tab-pane key="2" tab="Data Kantor">
              <div class="row">
                <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                  <div class="row">
                    <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
                      <label>Alamat Kantor</label>
                    </div>
                    <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                      <!-- maxlength="10" -->
                      <input
                        class="ant-input"
                        v-model="formData.alamatkantor"
                        placeholder="Alamat Kantor"
                      />
                    </div>
                    <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
                      <label>Telepon Kantor</label>
                    </div>
                    <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                      <input
                        maxlength="10"
                        class="ant-input"
                        v-model="formData.teleponkantor"
                        placeholder="Telepon Kantor"
                      />
                    </div>
                    <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
                      <label>FAX Kantor</label>
                    </div>
                    <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                      <input
                        maxlength="10"
                        class="ant-input"
                        v-model="formData.faxkantor"
                        placeholder="FAX Kantor"
                      />
                    </div>
                    <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
                      <label>Kodya Kantor</label>
                    </div>
                    <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                      <v-select
                        :clearable="false"
                        class="style-chooser-pos-relative"
                        label="keterangan"
                        v-model="formData.kodyakantor"
                        placeholder="Kodya Kantor"
                        :options="dataKodya"
                        :reduce="(tes) => tes.kode"
                        @input="(e) => getKecamatan(e, 'Kantor')"
                      />
                      <!-- <input maxlength="10" class="ant-input" v-model="formData.kodyakantor" placeholder="Kodya Kantor"> -->
                    </div>
                    <!-- <a-divider type="horizontal" style="margin: unset !important; margin-bottom: 8px !important;"><strong class="mb-1">Alamat Sesuai KTP</strong></a-divider> -->
                    <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
                      <label>Kecamatan Kantor</label>
                    </div>
                    <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                      <v-select
                        :clearable="false"
                        class="style-chooser-pos-relative"
                        label="keterangan"
                        v-model="formData.kecamatankantor"
                        placeholder="Kecamatan Kantor"
                        :options="dataKecamatanKantor"
                        :reduce="(tes) => tes.kode"
                        @input="(e) => getKelurahan('kodyakantor', e, 'Kantor')"
                      />
                      <!-- <input maxlength="10" class="ant-input" v-model="formData.kecamatankantor" placeholder="Kecamatan Kantor"> -->
                    </div>
                    <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
                      <label>Kelurahan Kantor</label>
                    </div>
                    <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                      <v-select
                        :clearable="false"
                        class="style-chooser-pos-relative"
                        label="keterangan"
                        v-model="formData.kelurahankantor"
                        placeholder="Kelurahan Kantor"
                        :options="dataKelurahanKantor"
                        :reduce="(tes) => tes.kode"
                      />
                      <!-- <input maxlength="10" class="ant-input" v-model="formData.kelurahankantor" placeholder="Kelurahan Kantor"> -->
                    </div>
                    <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
                      <label>RT/RW Kantor</label>
                    </div>
                    <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                      <input
                        maxlength="10"
                        class="ant-input"
                        v-model="formData.rtrwkantor"
                        placeholder="RT/RW Kantor"
                      />
                    </div>
                    <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
                      <label>Kode Pos Kantor</label>
                    </div>
                    <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                      <input
                        maxlength="10"
                        class="ant-input"
                        v-model="formData.kodeposkantor"
                        placeholder="Kode Pos Kantor"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                  <div class="row">
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                      <strong>Foto Kantor</strong>
                    </div>
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                      <center>
                        <a-card style="max-width: 450px; max-height: 450px">
                          <img
                            src="https://dummyimage.com/450x300/000/fff.png&text=Foto+Sementara"
                            alt="Testing"
                          />
                        </a-card>
                      </center>
                    </div>
                  </div>
                </div>
              </div>
            </a-tab-pane>
            <a-tab-pane key="3" tab="K Y C">
              <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                  <div class="row">
                    <a-divider
                      class="mt-2"
                      type="horizontal"
                      orientation="left"
                      style="
                        z-index: 999;
                        margin: unset !important;
                        margin-bottom: 8px !important;
                      "
                      ><strong class="mb-1">Sumber Dana</strong></a-divider
                    >
                    <div
                      class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mb-3"
                      style="margin-top: -21px"
                    >
                      <a-card>
                        <div class="row">
                          <div class="col-xs-4 col-sm-3 col-md-2 col-lg-2 mt-2">
                            <label>Gaji</label>
                          </div>
                          <div
                            class="col-xs-8 col-sm-9 col-md-10 col-lg-10 mt-1"
                          >
                            <strong>±</strong>
                            <input
                              maxlength="10"
                              class="ant-input ml-2 mr-2"
                              v-model="formData.gaji"
                              placeholder="Gaji"
                              style="width: 50%"
                            />
                            <strong> / Bulan</strong>
                          </div>
                          <div class="col-xs-4 col-sm-3 col-md-2 col-lg-2 mt-2">
                            <label>Pendapatan Lainnya</label>
                          </div>
                          <div
                            class="col-xs-8 col-sm-9 col-md-10 col-lg-10 mt-1 mb-3"
                          >
                            <strong>±</strong>
                            <input
                              class="ant-input ml-2 mr-2"
                              v-model="formData.pendapatanlainnya"
                              placeholder="Pendapatan Lainnya"
                              style="width: 50%"
                            />
                            <strong> / Bulan</strong>
                          </div>
                        </div>
                      </a-card>
                    </div>
                    <a-divider
                      class=""
                      type="horizontal"
                      orientation="left"
                      style="
                        z-index: 999;
                        margin: unset !important;
                        margin-bottom: 8px !important;
                      "
                      ><strong class="mb-1">Tujuan Pembukaan</strong></a-divider
                    >
                    <!-- <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-1">
                      <label>Tujuan Pembukaan</label>
                    </div> -->
                    <div
                      class="col-xs-12 col-sm-12 col-md-12 col-lg-12"
                      style="margin-top: -21px"
                    >
                      <a-card>
                        <div class="row">
                          <div
                            class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mb-4"
                          >
                            <div class="row">
                              <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                <div class="row">
                                  <div
                                    class="col-xs-6 col-sm-6 col-md-6 col-lg-6"
                                  >
                                    <a-checkbox
                                      v-model="tujuanpembukaan.simpanan"
                                      @change="checkBox('simpanan')"
                                      >1. Simpanan</a-checkbox
                                    >
                                  </div>
                                  <div
                                    class="col-xs-6 col-sm-6 col-md-6 col-lg-6"
                                  >
                                    <a-checkbox
                                      v-model="tujuanpembukaan.transaksiusaha"
                                      @change="checkBox('transaksiusaha')"
                                      >2. Transaksi Usaha</a-checkbox
                                    >
                                  </div>
                                  <div
                                    class="col-xs-6 col-sm-6 col-md-6 col-lg-6"
                                  >
                                    <a-checkbox
                                      v-model="tujuanpembukaan.konsumtif"
                                      @change="checkBox('konsumtif')"
                                      >3. Konsumtif</a-checkbox
                                    >
                                  </div>
                                  <div
                                    class="col-xs-6 col-sm-6 col-md-6 col-lg-6"
                                  >
                                    <a-checkbox
                                      v-model="tujuanpembukaan.lainnya"
                                      @change="checkBox('lainnya')"
                                      >4. Lainnya</a-checkbox
                                    >
                                  </div>
                                </div>
                                <!-- <input
                          maxlength="10"
                          class="ant-input"
                          v-model="formData.tujuanpembukaan"
                          placeholder="Tujuan Pembukaan"
                        /> -->
                              </div>
                            </div>
                          </div>
                          <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
                            <label>Ket Tujuan Pembukaan</label>
                          </div>
                          <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                            <!-- maxlength="10" -->
                            <input
                              class="ant-input"
                              v-model="formData.keterangantujuanpembukaan"
                              placeholder="Keterangan Tujuan Pembukaan"
                            />
                          </div>
                          <div
                            class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-3"
                          >
                            <a-card>
                              <div class="row">
                                <div
                                  class="col-xs-12 col-sm-12 col-md-12 col-lg-12"
                                >
                                  <a-checkbox
                                    v-model="formData.usahanontunai"
                                    @change="
                                      clearOrNotClear(
                                        'usahanontunai',
                                        'jumlahnontunai'
                                      )
                                    "
                                    >A. Non Tunai
                                    (Transfer/Kliring/Overbooking)</a-checkbox
                                  >
                                </div>
                                <div
                                  class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2"
                                >
                                  <label class="ml-5"
                                    >Jumlah Rata-Rata/bulan</label
                                  >
                                </div>
                                <div
                                  class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1"
                                >
                                  <cleave
                                    :readOnly="
                                      inputOptions.jumlahnontunai.readOnly
                                    "
                                    :options="{ numeral: true }"
                                    class="ant-input"
                                    :raw="true"
                                    style="width: 40%"
                                    v-model="formData.jumlahnontunai"
                                    placeholder="Usaha non Tunai"
                                  />
                                </div>
                                <div
                                  class="col-xs-12 col-sm-12 col-md-12 col-lg-12"
                                >
                                  <a-checkbox
                                    v-model="formData.usahatunai"
                                    @change="
                                      clearOrNotClear(
                                        'usahatunai',
                                        'jumlahtunai'
                                      )
                                    "
                                    >B. Tunai</a-checkbox
                                  >
                                </div>
                                <div
                                  class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2"
                                >
                                  <label class="ml-5"
                                    >Jumlah Rata-Rata/bulan</label
                                  >
                                </div>
                                <div
                                  class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1"
                                >
                                  <cleave
                                    :readOnly="
                                      inputOptions.jumlahtunai.readOnly
                                    "
                                    :options="{ numeral: true }"
                                    class="ant-input"
                                    :raw="true"
                                    style="width: 40%"
                                    v-model="formData.jumlahtunai"
                                    placeholder="Jumlah non Tunai"
                                  />
                                </div>
                              </div>
                            </a-card>
                          </div>
                        </div>
                      </a-card>
                    </div>
                  </div>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-3">
                  <div class="row">
                    <a-divider
                      class="mt-2"
                      type="horizontal"
                      orientation="left"
                      style="
                        margin: unset !important;
                        z-index: 999;
                        margin-bottom: 8px !important;
                      "
                      ><strong class="mb-1"
                        >Rekening Yang Dimiliki Saat Ini</strong
                      ></a-divider
                    >
                    <div
                      class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mb-3"
                      style="margin-top: -21px"
                    >
                      <a-card>
                        <div class="row">
                          <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
                            <label>Nama/Rekening Bank</label>
                          </div>
                          <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                            <input
                              maxlength="50"
                              class="ant-input"
                              v-model="formData.namabank1"
                              placeholder="Nama Bank"
                              style="width: 40%"
                            />
                            /
                            <input
                              maxlength="10"
                              class="ant-input"
                              v-model="formData.rekeningbank1"
                              placeholder="Rekening Bank"
                              style="width: 50%"
                            />
                          </div>
                          <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
                            <label>Nama/Rekening Bank 2</label>
                          </div>
                          <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                            <input
                              maxlength="50"
                              class="ant-input"
                              v-model="formData.namabank2"
                              placeholder="Nama Bank 2"
                              style="width: 40%"
                            />
                            /
                            <input
                              maxlength="10"
                              class="ant-input"
                              v-model="formData.rekeningbank2"
                              placeholder="Rekening Bank 2"
                              style="width: 50%"
                            />
                          </div>
                        </div>
                      </a-card>
                    </div>
                    <a-divider
                      class=""
                      type="horizontal"
                      style="
                        margin: unset !important;
                        margin-bottom: 8px !important;
                      "
                      ><strong class="mb-1"></strong
                    ></a-divider>
                    <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
                      <label>Tingkat Resiko</label>
                    </div>
                    <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                      <v-select
                        :clearable="false"
                        class="style-chooser-pos-relative"
                        v-model="formData.tingkat_resiko"
                        placeholder="Tingkat Resiko"
                        label="keterangan"
                        :options="dataTingkatResiko"
                        :reduce="(tes) => tes.kode"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </a-tab-pane>
            <a-tab-pane key="7" tab="Pasangan">
              <div class="row">
                <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8">
                  <div class="row">
                    <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
                      <label>Nama Pasangan</label>
                    </div>
                    <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                      <input
                        maxlength="50"
                        class="ant-input"
                        v-model="formData.namapasangan"
                        placeholder="Nama Pasangan"
                      />
                    </div>
                    <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
                      <label>Jumlah Anak</label>
                    </div>
                    <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                      <input
                        maxlength="10"
                        class="ant-input"
                        v-model="formData.jumlahanak"
                        placeholder="Jumlah Anak"
                      />
                    </div>
                    <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
                      <label>KTP Pasangan</label>
                    </div>
                    <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                      <input
                        maxlength="18"
                        class="ant-input"
                        v-model="formData.ktppasangan"
                        placeholder="KTP Pasangan"
                      />
                    </div>
                    <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
                      <label>Tgl Lahir Pasangan</label>
                    </div>
                    <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                      <a-date-picker
                        style="width: 120px"
                        format="DD-MM-YYYY"
                        v-model="formData.tgllahirpasangan"
                        placeholder="Tanggal Lahir Pasangan"
                        :allowClear="false"
                      />
                      <!-- <input maxlength="10" class="ant-input" v-model="formData.tgllahirpasangan" placeholder="Tanggal Lahir Pasangan"> -->
                    </div>
                    <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
                      <label>Pisah Harta</label>
                    </div>
                    <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                      <v-select
                        :clearable="false"
                        class="style-chooser-pos-relative"
                        v-model="formData.pisahharta"
                        placeholder="Pisah Harta"
                        label="keterangan"
                        :options="dataYaAtauTidak"
                        :reduce="(tes) => tes.kode"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </a-tab-pane>
          </a-tabs>
        </a-card>
      </div>
    </div>
  </div>
</template>

<script>
import { Modal } from 'ant-design-vue'
import moment from 'moment'
import * as lou from '@/services/data/lou'
import { mapState } from 'vuex'
import Cleave from 'vue-cleave-component'

export default {
  components: {
    cleave: Cleave,
  },
  computed: {
    ...mapState(['settings', 'user']),
  },
  watch: {
    'formData.alamat'(newValue, oldValue) {
      if (this.alamatktpdantempattinggalsama) {
        this.formData.alamattinggal = newValue
      }
    },
    'formData.telepon'(newValue, oldValue) {
      if (this.alamatktpdantempattinggalsama) {
        this.formData.telepontinggal = newValue
      }
    },
    'formData.fax'(newValue, oldValue) {
      if (this.alamatktpdantempattinggalsama) {
        this.formData.faxtinggal = newValue
      }
    },
    'formData.kodepos'(newValue, oldValue) {
      if (this.alamatktpdantempattinggalsama) {
        this.formData.kodepostinggal = newValue
      }
    },
    'formData.rtrw'(newValue, oldValue) {
      if (this.alamatktpdantempattinggalsama) {
        this.formData.rtrwtinggal = newValue
      }
    },
    'formData.alamattinggal'(newValue, oldValue) {
      if (this.alamatktpdantempattinggalsama) {
        this.formData.alamat = newValue
      }
    },
    'formData.telepontinggal'(newValue, oldValue) {
      if (this.alamatktpdantempattinggalsama) {
        this.formData.telepon = newValue
      }
    },
    'formData.faxtinggal'(newValue, oldValue) {
      if (this.alamatktpdantempattinggalsama) {
        this.formData.fax = newValue
      }
    },
    'formData.kodepostinggal'(newValue, oldValue) {
      if (this.alamatktpdantempattinggalsama) {
        this.formData.kodepos = newValue
      }
    },
    'formData.rtrwtinggal'(newValue, oldValue) {
      if (this.alamatktpdantempattinggalsama) {
        this.formData.rtrw = newValue
      }
    },
  },
  data: function () {
    return {
      inputOptions: {
        jumlahnontunai: {
          readOnly: false,
        },
        jumlahtunai: {
          readOnly: false,
        },
      },

      dateFormat: ['DD/MM/YYYY', 'DD/MM/YY'],
      form: this.$form.createForm(this),
      editdata: JSON.parse(
        localStorage.getItem('cbsjombangeditregisternasabahdata'),
      ),
      alamatktpdantempattinggalsama: true,
      config: {
        rules: [
          { type: 'object', required: false, message: 'Tolong pilih tanggal!' },
        ],
      },
      errorMsg: null,
      successMsg: null,
      action: null,
      title: 'Register Nasabah',
      showHistory: true,
      showInformation: false,
      kembarnamacolumn: [
        {
          title: 'Kode',
          rowKey: 'kode',
          dataIndex: 'kode',
          scopedSlots: { customRender: 'kode' },
          // width: 100,
        },
        {
          title: 'Nama',
          dataIndex: 'nama',
          scopedSlots: { customRender: 'nama' },
          // width: 100,
        },
        {
          title: 'KTP',
          dataIndex: 'ktp',
          scopedSlots: { customRender: 'ktp' },
          // width: 100,
        },
        {
          title: 'Alamat',
          dataIndex: 'alamat',
          scopedSlots: { customRender: 'alamat' },
          // width: 100,
        },
      ],
      kembarnamadatatable: [],
      dataKodya: [],
      dataInstansi: [],
      dataKecamatan: [],
      dataKelurahan: [],
      dataKodyaKTP: [],
      dataKecamatanKTP: [],
      dataKelurahanKTP: [],
      dataKodyaTinggal: [],
      dataKecamatanTinggal: [],
      dataKelurahanTinggal: [],
      dataKodyaLembaga: [],
      dataKecamatanLembaga: [],
      dataKelurahanLembaga: [],
      dataKecamatanKantor: [],
      dataKelurahanKantor: [],
      dataAgama: [],
      dataPekerjaan: [],
      dataGelar: [],
      dataJenisIdentitas: [],
      dataGolDarah: [
        { kode: 'A' },
        { kode: 'B' },
        { kode: 'AB' },
        { kode: 'O' },
      ],
      dataYaAtauTidak: [
        {
          kode: 'Y',
          keterangan: 'Ya',
        },
        {
          kode: 'T',
          keterangan: 'Tidak',
        },
      ],
      dataStatusPerkawinan: [
        {
          kode: 'S',
          keterangan: 'Sudah Kawin',
        },
        {
          kode: 'B',
          keterangan: 'Belum Kawin',
        },
      ],
      dataKelamin: [
        {
          kode: 'P',
          keterangan: 'Perempuan',
        },
        {
          kode: 'L',
          keterangan: 'Laki-Laki',
        },
      ],
      dataTingkatResiko: [
        {
          kode: '1',
          keterangan: 'Rendah',
        },
        {
          kode: '2',
          keterangan: 'Sedang',
        },
        {
          kode: '3',
          keterangan: 'Tinggi',
        },
      ],
      tujuanpembukaan: {
        simpanan: false,
        transaksiusaha: false,
        konsumtif: false,
        lainnya: false,
      },
      formData: {
        cabangentry: '',
        nama: '',
        namaibu: '',
        kelamin: '',
        golongandarah: '',
        tgllahir: moment().format('YYYY-MM-DD'),
        tempatlahir: '',
        statusperkawinan: '',
        ktp: '',
        tglktp: moment().format('YYYY-MM-DD'),
        agama: '',
        pekerjaan: '',
        alamat: '',
        alamat2: '',
        kodepos: '',
        telepon: '',
        fax: '',
        kodya: '',
        kecamatan: '',
        kelurahan: '',
        rtrw: '',
        alamatkantor: '',
        teleponkantor: '',
        faxkantor: '',
        kodyakantor: '',
        kecamatankantor: '',
        kelurahankantor: '',
        rtrwkantor: '',
        kodeposkantor: '',
        path: '',
        ao: '',
        path1: '',
        tglregister: moment().format('YYYY-MM-DD'),
        namalembaga: '',
        alamatlembaga: '',
        noaktependirian: '',
        nonpwp: '',
        kodyalembaga: '',
        kecamatanlembaga: '',
        kelurahanlembaga: '',
        alamattinggal: '',
        telepontinggal: '',
        faxtinggal: '',
        kodyatinggal: '',
        kecamatantinggal: '',
        kelurahantinggal: '',
        rtrwtinggal: '',
        kodepostinggal: '',
        merchant: '',
        tglupdate: moment().format('YYYY-MM-DD'),
        detailpekerjaan: '',
        blacklist: '',
        desa: '',
        hubunganbank: '',
        tempatbekerja: '',
        namapasangan: '',
        jumlahanak: 0,
        kelurahan1: '',
        kecamatan1: '',
        kodya1: '',
        provinsi1: '',
        hp: '',
        namaperusahaan: '',
        username: '',
        email: '',
        npwp: '',
        gaji: 0,
        pendapatanlainnya: 0,
        tujuanpembukaan: '',
        keterangantujuanpembukaan: '',
        usahanontunai: false,
        jumlahnontunai: 0,
        usahatunai: '',
        jumlahtunai: 0,
        namabank1: '',
        rekeningbank1: '',
        namabank2: '',
        rekeningbank2: '',
        instansi: '',
        nip: '',
        jenisdebitur: '',
        jenisidentitas: '',
        gelar: '',
        namaidentitas: '',
        ktppasangan: '',
        tgllahirpasangan: moment().format('YYYY-MM-DD'),
        pisahharta: '',
        tingkat_resiko: '',
        sandibank: '',
        jenisbadanusaha: '',
      },
    }
  },
  mounted: function () {
    // const date = moment().format('YYYY-MM-DD') // Tanggal hari ini format 2020-12-31
    if (this.editdata.id !== '') {
      this.action = 'update'
      this.title = 'Update Nasabah'
      this.getEditData()
    } else {
      this.action = 'create'
      this.title = 'Create Nasabah'
    }
    // this.getData()
    this.getAgama()
  },
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: 'register nasabah' })
  },
  methods: {
    moment,
    clearOrNotClear(from, to, rules = ['true', '1']) {
      // console.log('from', from)
      // console.log('to', to)
      var val = this.formData[from]
      this.formData[to] = '0'
      // console.log('val', val)
      if (val || rules.includes(val)) {
        this.inputOptions[to].readOnly = false
      } else {
        this.inputOptions[to].readOnly = true
      }
      // console.log('this.inputOptions[to].readOnly', this.inputOptions[to].readOnly)
    },
    checkBox(p) {
      console.log('this.tujuantujuanpembukaan.' + p, this.tujuanpembukaan[p])
    },
    samakandatachange(e) {
      // console.log('e', e)
      if (this.alamatktpdantempattinggalsama) {
        if (this.formData.alamat !== '') {
          this.formData.alamattinggal = this.formData.alamat
        }
        if (this.formData.telepon !== '') {
          this.formData.telepontinggal = this.formData.telepon
        }
        if (this.formData.fax !== '') {
          this.formData.faxtinggal = this.formData.fax
        }
        if (this.formData.kodepos !== '') {
          this.formData.kodepostinggal = this.formData.kodepos
        }
        if (this.formData.rtrw !== '') {
          this.formData.rtrwtinggal = this.formData.rtrw
        }
        if (this.formData.alamattinggal !== '') {
          this.formData.alamat = this.formData.alamattinggal
        }
        if (this.formData.telepontinggal !== '') {
          this.formData.telepon = this.formData.telepontinggal
        }
        if (this.formData.faxtinggal !== '') {
          this.formData.fax = this.formData.faxtinggal
        }
        if (this.formData.kodepostinggal !== '') {
          this.formData.kodepos = this.formData.kodepostinggal
        }
        if (this.formData.rtrwtinggal !== '') {
          this.formData.rtrw = this.formData.rtrwtinggal
        }
      }
    },
    changeDateInput(dateMoment, dateString, arrdata) {
      this[arrdata[0]][arrdata[1]] = dateMoment
      // console.log('dateMoment', dateMoment)
      // console.log('dateString', dateString)
      // console.log('arrdata', arrdata)
    },
    async getAgama() {
      var dataagama = await lou.customUrlGet(
        'RegisterNasabah/agama',
        false,
        false,
        false,
        false,
      )
      var datakodya = await lou.customUrlGet(
        'RegisterNasabah/Kodya',
        false,
        false,
        false,
        false,
      )
      // var datakecamatan = await lou.customUrlGet('RegisterNasabah/Kecamatan', false, false, false, false)
      // var datakelurahan = await lou.customUrlGet('RegisterNasabah/Kelurahan', false, false, false, false)
      var datapekerjaan = await lou.customUrlGet(
        'RegisterNasabah/Pekerjaan',
        false,
        false,
        false,
        false,
      )
      var datagelar = await lou.customUrlGet(
        'RegisterNasabah/Gelar',
        false,
        false,
        false,
        false,
      )
      var dataInstansi = await lou.customUrlGet(
        'RegisterNasabah/Instansi',
        false,
        false,
        false,
        false,
      )
      var dataJenisIdentitas = await lou.customUrlGet(
        'RegisterNasabah/Jenis_Identitas',
        false,
        false,
        false,
        false,
      )

      this.allMaster = {}

      if (datakodya) {
        this.dataKodya = datakodya.data
        this.dataKodyaKTP = datakodya.data
        this.dataKodyaTinggal = datakodya.data
        this.dataKodyaLembaga = datakodya.data
        this.allMaster.kodya = datakodya.data
      }

      if (dataagama) {
        this.dataAgama = dataagama.data
        this.allMaster.agama = dataagama.data
      }

      if (datapekerjaan) {
        this.dataPekerjaan = datapekerjaan.data
        this.allMaster.pekerjaan = datapekerjaan.data
      }

      if (datagelar) {
        this.dataGelar = datagelar.data
        this.allMaster.gelar = datagelar.data
      }

      if (dataInstansi) {
        this.dataInstansi = dataInstansi.data
        this.allMaster.instansi = dataInstansi.data
      }

      if (dataJenisIdentitas) {
        this.dataJenisIdentitas = dataJenisIdentitas.data
        this.allMaster.instansi = dataJenisIdentitas.data
      }

      // this.allMaster.kecamatan = datakecamatan.data
      // this.allMaster.kelurahan = datakelurahan.data
    },
    async getKecamatan(kodya, from) {
      var datakecamatan = await lou.customUrlGet(
        'RegisterNasabah/Kecamatan?kodya=' + kodya,
      )
      // this.dataKecamatan = datakecamatan.data
      this['dataKecamatan' + from] = datakecamatan.data
      // console.log("this['dataKecamatan' + from]", this['dataKecamatan' + from])
    },
    async getKelurahan(kody, kecamatan, from) {
      var kodya = this.formData[kody]
      var datakelurahan = await lou.customUrlGet(
        'RegisterNasabah/Kelurahan?kodya=' + kodya + '&kecamatan=' + kecamatan,
      )
      // this.dataKelurahan = datakelurahan.data
      this['dataKelurahan' + from] = datakelurahan.data
    },
    async getData() {
      var indexeddb = await lou.getDataFromDb()
      // console.log('indexeddb', indexeddb)
      // console.log('this.settings.whatapp', this.settings.whatapp)
      var allmaster = {}
      var masterIndex = indexeddb.findIndex(
        (x) =>
          x.mykey ===
          'allmaster' +
            this.settings.whatapp.appName.toLowerCase() +
            this.settings.whatapp.id,
      )
      // console.log('masterIndex', indexeddb[masterIndex])
      if (masterIndex < 0) {
        var res = await lou.customUrlGet('master', false, true)
        var master = {
          mykey:
            'allmaster' +
            this.settings.whatapp.appName.toLowerCase() +
            this.settings.whatapp.id,
          data: JSON.stringify(res.data),
        }
        await lou.addDataToDb(master)
        var nindexeddb = await lou.getDataFromDb()
        var targetIndex = nindexeddb.findIndex(
          (x) =>
            x.mykey ===
            'allmaster' +
              this.settings.whatapp.appName.toLowerCase() +
              this.settings.whatapp.id,
        )
        // console.log('nindexeddb', nindexeddb[targetIndex])
        allmaster = JSON.parse(nindexeddb[targetIndex].data)
      } else {
        allmaster = JSON.parse(indexeddb[masterIndex].data)
      }
      var cabanglist = []
      for (let u = 0; u < this.user.cabangList.length; u++) {
        const element = this.user.cabangList[u]
        // console.log('element', element)
        cabanglist.push({ kode: element.kode, keterangan: element.kode })
      }
      // console.log('indexeddb', indexeddb)
      this.allMaster = allmaster
      // console.log('this.allMaster', this.allMaster)
      this.dataKodya = this.allMaster.kodya
      this.dataKodyaKTP = this.allMaster.kodya
      this.dataKodyaTinggal = this.allMaster.kodya
      this.dataKodyaLembaga = this.allMaster.kodya
      this.dataAgama = this.allMaster.agama
      this.dataPekerjaan = this.allMaster.pekerjaan
      this.dataGelar = this.allMaster.statusgelar
    },
    changeKodya(e, from) {
      // console.log('e', e)
      // console.log('from', from)
      var ndata = this.allMaster.kecamatan.filter((x) => x.kodya === e)
      // console.log('ndata', ndata)
      this['dataKecamatan' + from] = ndata
    },
    changeKecamatan(e, from, kodya) {
      // console.log('e', e)
      // console.log('from', from)
      // console.log('kodya', kodya)
      // console.log(kodya, this.formData[kodya])
      // var ndata = this.allMaster.kelurahan.filter(x => x.kecamatan === e && x.kodya === this.formData[kodya])
      // console.log('ndata', ndata)
      // this['dataKelurahan' + from] = ndata
    },
    getEditData() {
      // var res = await lou.customUrlGet('RegisterNasabah', { where: { id: this.editdata.id } })
      this.formData = this.$g.clone(this.editdata)
      console.log('this.editdata', this.editdata)
      this.tujuanpembukaan.simpanan =
        this.editdata.tujuanpembukaan.substring(0, 1) === '1'
      this.tujuanpembukaan.transaksiusaha =
        this.editdata.tujuanpembukaan.substring(3, 4) === '1'
      this.tujuanpembukaan.konsumtif =
        this.editdata.tujuanpembukaan.substring(6, 7) === '1'
      this.tujuanpembukaan.lainnya =
        this.editdata.tujuanpembukaan.substring(9, 10) === '1'
      this.formData.usahanontunai = this.editdata.usahanontunai === '1'
      this.formData.usahatunai = this.editdata.usahatunai === '1'
      this.editdata.kodya !== ''
        ? this.getKecamatan(this.editdata.kodya, '')
        : this.nullFunction()
      this.editdata.kecamatan !== ''
        ? this.getKelurahan('kodya', this.editdata.kecamatan, '')
        : this.nullFunction()
      this.editdata.kodyalembaga !== ''
        ? this.getKecamatan(this.editdata.kodyalembaga, 'Lembaga')
        : this.nullFunction()
      this.editdata.kecamatanlembaga !== ''
        ? this.getKelurahan(
          'kodyalembaga',
          this.editdata.kecamatanlembaga,
          'Lembaga',
        )
        : this.nullFunction()
      this.editdata.kodyakantor !== ''
        ? this.getKecamatan(this.editdata.kodyakantor, 'Kantor')
        : this.nullFunction()
      this.editdata.kecamatankantor !== ''
        ? this.getKelurahan(
          'kodyakantor',
          this.editdata.kecamatankantor,
          'Kantor',
        )
        : this.nullFunction()
      this.editdata.kodyatinggal !== ''
        ? this.getKecamatan(this.editdata.kodyatinggal, 'Tinggal')
        : this.nullFunction()
      this.editdata.kecamatantinggal !== ''
        ? this.getKelurahan(
          'kodyatinggal',
          this.editdata.kecamatantinggal,
          'Tinggal',
        )
        : this.nullFunction()
      this.editdata.kodyaktp !== ''
        ? this.getKecamatan(this.editdata.kodyaktp, 'KTP')
        : this.nullFunction()
      this.editdata.kecamatan1 !== ''
        ? this.getKelurahan('kodya1', this.editdata.kecamatan1, 'KTP')
        : this.nullFunction()
    },
    async findName() {
      var res = await lou.customUrlGet(
        'carinasabah?Nama=' + this.formData.nama,
      )
      if (res) {
        this.kembarnamadatatable = res.data
      }
    },
    handleSubmit(e) {
      Modal.confirm({
        title: 'Are you sure the data is correct?',
        content: 'You can edit the data later! ',
        okText: 'Yes',
        okType: 'primary',
        cancelText: 'No',
        onOk: async () => {
          // Should format date value before submit.
          var res = false
          var fd = this.formData
          // console.log('this.formData', this.formData)
          var inSimpanan = this.tujuanpembukaan.simpanan ? '1' : '0'
          var inTransaksiUsaha = this.tujuanpembukaan.transaksiusaha
            ? '1'
            : '0'
          var inKonsumtif = this.tujuanpembukaan.konsumtif ? '1' : '0'
          var inLainnya = this.tujuanpembukaan.lainnya ? '1' : '0'
          fd.tujuanpembukaan =
            inSimpanan +
            ', ' +
            inTransaksiUsaha +
            ', ' +
            inKonsumtif +
            ', ' +
            inLainnya

          fd.usahanontunai = this.formData.usahanontunai ? '1' : '0'
          fd.usahatunai = this.formData.usahatunai ? '1' : '0'
          try {
            fd.tgllahir =
              this.formData.tgllahir !== null
                ? this.formData.tgllahir.format('YYYY-MM-DD')
                : null
          } catch (error) {
            fd.tgllahir =
              this.formData.tgllahir !== null ? this.formData.tgllahir : null
          }
          try {
            fd.tglktp =
              this.formData.tglktp !== null
                ? this.formData.tglktp.format('YYYY-MM-DD')
                : null
          } catch (error) {
            fd.tglktp =
              this.formData.tglktp !== null ? this.formData.tglktp : null
          }
          try {
            fd.tglregister =
              this.formData.tglregister !== null
                ? this.formData.tglregister.format('YYYY-MM-DD')
                : null
          } catch (error) {
            fd.tglregister =
              this.formData.tglregister !== null
                ? this.formData.tglregister
                : null
          }
          try {
            fd.tglupdate =
              this.formData.tglupdate !== null
                ? this.formData.tglupdate.format('YYYY-MM-DD')
                : null
          } catch (error) {
            fd.tglupdate =
              this.formData.tglupdate !== null ? this.formData.tglupdate : null
          }
          try {
            fd.tgllahirpasangan =
              this.formData.tgllahirpasangan !== null
                ? this.formData.tgllahirpasangan.format('YYYY-MM-DD')
                : null
          } catch (error) {
            fd.tgllahirpasangan =
              this.formData.tgllahirpasangan !== null
                ? this.formData.tgllahirpasangan
                : null
          }
          if (this.action === 'update') {
            res = await lou.customUrlPut('RegisterNasabah', fd)
          } else {
            delete fd.id
            res = await lou.customUrlPost('RegisterNasabah', fd)
          }
          if (res) {
            Modal.confirm({
              title: 'Kembali?',
              content: 'Kembali ke halaman data?! ',
              okText: 'Yes',
              okType: 'primary',
              cancelText: 'No',
              onOk: () => {
                // Should format date value before submit.
                this.$router.push('/cbsjombang/registernasabah/data')
              },
              onCancel: () => {
                Modal.confirm({
                  title: 'Reset Form?',
                  content: 'Data Input Akan direset?! ',
                  okText: 'Yes',
                  okType: 'primary',
                  cancelText: 'No',
                  onOk: () => {
                    this.resetForm()
                  },
                  onCancel: () => {
                    fd.tgllahir =
                      this.formData.tgllahir !== null
                        ? moment(this.formData.tgllahir, 'YYYY-MM-DD')
                        : moment()
                    fd.tglregister =
                      this.formData.tglregister !== null
                        ? moment(this.formData.tglregister, 'YYYY-MM-DD')
                        : moment()
                    fd.tglktp =
                      this.formData.tglktp !== null
                        ? moment(this.formData.tglktp, 'YYYY-MM-DD')
                        : moment()
                    fd.tgllahirpasangan =
                      this.formData.tgllahirpasangan !== null
                        ? moment(this.formData.tgllahirpasangan, 'YYYY-MM-DD')
                        : moment()
                    fd.tgllahir_pasangan =
                      this.formData.tgllahir_pasangan !== null
                        ? moment(this.formData.tgllahir_pasangan, 'YYYY-MM-DD')
                        : moment()
                    fd.tglperubahanbu =
                      this.formData.tglperubahanbu !== null
                        ? moment(this.formData.tglperubahanbu, 'YYYY-MM-DD')
                        : moment()
                    fd.tglbu =
                      this.formData.tglbu !== null
                        ? moment(this.formData.tglbu, 'YYYY-MM-DD')
                        : moment()
                    // console.log('Cancel')
                  },
                })
              },
            })
          }
        },
        onCancel: () => {
          // console.log('Cancel')
        },
      })
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      )
    },
    resetForm() {
      localStorage.removeItem('cbsjombangeditregisternasabahdata')
      this.formData = {
        id: null,
        cabangentry: '',
        nama: '',
        namaibu: '',
        kelamin: '',
        golongandarah: '',
        tgllahir: moment().format('YYYY-MM-DD'),
        tempatlahir: '',
        statusperkawinan: '',
        ktp: '',
        tglktp: moment().format('YYYY-MM-DD'),
        agama: '',
        pekerjaan: '',
        alamat: '',
        alamat2: '',
        kodepos: '',
        telepon: '',
        fax: '',
        kodya: '',
        kecamatan: '',
        kelurahan: '',
        rtrw: '',
        alamatkantor: '',
        teleponkantor: '',
        faxkantor: '',
        kodyakantor: '',
        kecamatankantor: '',
        kelurahankantor: '',
        rtrwkantor: '',
        kodeposkantor: '',
        path: '',
        ao: '',
        path1: '',
        tglregister: moment().format('YYYY-MM-DD'),
        namalembaga: '',
        alamatlembaga: '',
        noaktependirian: '',
        nonpwp: '',
        kodyalembaga: '',
        kecamatanlembaga: '',
        kelurahanlembaga: '',
        alamattinggal: '',
        telepontinggal: '',
        faxtinggal: '',
        kodyatinggal: '',
        kecamatantinggal: '',
        kelurahantinggal: '',
        rtrwtinggal: '',
        kodepostinggal: '',
        merchant: '',
        tglupdate: moment().format('YYYY-MM-DD'),
        detailpekerjaan: '',
        blacklist: '',
        desa: '',
        hubunganbank: '',
        tempatbekerja: '',
        namapasangan: '',
        jumlahanak: 0,
        kelurahan1: '',
        kecamatan1: '',
        kodya1: '',
        provinsi1: '',
        hp: '',
        namaperusahaan: '',
        username: '',
        email: '',
        npwp: '',
        gaji: 0,
        pendapatanlainnya: 0,
        tujuanpembukaan: '',
        keterangantujuanpembukaan: '',
        usahanontunai: false,
        jumlahnontunai: 0,
        usahatunai: '',
        jumlahtunai: 0,
        namabank1: '',
        rekeningbank1: '',
        namabank2: '',
        rekeningbank2: '',
        instansi: '',
        nip: '',
        jenisdebitur: '',
        jenisidentitas: '',
        gelar: '',
        namaidentitas: '',
        ktppasangan: '',
        tgllahirpasangan: moment().format('YYYY-MM-DD'),
        pisahharta: '',
        tingkat_resiko: '',
        sandibank: '',
        jenisbadanusaha: '',
      }
      this.$router.push('/cbsjombang/registernasabah/data')
    },
    nullFunction() {
      // console.log('%c! Nothing to see here! close debuging windows this instant or the app will automatically delete itself.%c', 'color: red; font-size:30px;', 'color: green; font-size:12px;')
      console.log(
        '%c! Hayooo... nyari apa disini... kalau error jangan di otak-atik sendiri, mending tanyakan team IT.%c',
        'color: red; font-size:30px;',
        'color: green; font-size:12px;',
      )
    },
  },
}
</script>

<style></style>
