<template>
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
      <div class="card">
        <div class="card-header">
          <div class="row">
            <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
              <a-button
                id="buttontambahdiindex"
                class="btn btn-outline-primary btn-sm"
                @click="sendToForm('create')"
                >Tambah</a-button
              >
              <a
                class="text-success ml-2"
                @click="getExDocument"
                >Download contoh template</a
              >
              <a-divider type="vertical"/>
              <a
                class="text-primary"
                @click="showUpload()"
                >Upload template baru</a
              >
              <input type="file" accept=".docx" id="file" ref="file" class="d-none" v-on:change="handleFileUpload()"/>
              <!-- <strong>Taksasi BPKB</strong> -->
            </div>
            <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
            </div>
          </div>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <a-table
                :bordered="true"
                :columns="columns"
                :data-source="datatable"
                size="small"
                style="
                  margin-left: -26px;
                  margin-right: -26px;
                  margin-top: -21px;
                "
                :customRow="customRow"
                :loading="loading"
                :pagination="{
                  hideOnSinglePage: true,
                  defaultPageSize: 10,
                  showQuickJumper: true,
                  showSizeChanger: true,
                  showTotal: (total) => `Total ${total} items`,
                  pageSizeOptions: ['10', '20', '30'],
                }"
              >
                <div
                  slot="filterDropdown"
                  slot-scope="{
                    setSelectedKeys,
                    selectedKeys,
                    confirm,
                    clearFilters,
                    column,
                  }"
                  style="padding: 8px;"
                >
                  <a-input
                    v-ant-ref="(c) => (searchInput = c)"
                    :placeholder="`Search ${column.dataIndex}`"
                    :value="selectedKeys[0]"
                    style="width: 188px; margin-bottom: 8px; display: block;"
                    @change="
                      (e) =>
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                    "
                    @pressEnter="
                      () =>
                        handleSearch(selectedKeys, confirm, column.dataIndex)
                    "
                  />
                  <a-button
                    type="primary"
                    icon="search"
                    size="small"
                    style="width: 90px; margin-right: 8px;"
                    @click="
                      () =>
                        handleSearch(selectedKeys, confirm, column.dataIndex)
                    "
                  >
                    Search
                  </a-button>
                  <a-button
                    size="small"
                    style="width: 90px;"
                    @click="() => handleReset(clearFilters)"
                  >
                    Reset
                  </a-button>
                </div>
                <a-icon
                  slot="filterIcon"
                  slot-scope="filtered"
                  type="search"
                  :style="{ color: filtered ? '#108ee9' : undefined }"
                />
                <template
                  slot="customRender"
                  slot-scope="text, record, index, column"
                >
                  <span
                    v-if="searchText && searchedColumn === column.dataIndex"
                  >
                    <template
                      v-for="(fragment, i) in text
                        .toString()
                        .split(
                          new RegExp(
                            `(?<=${searchText})|(?=${searchText})`,
                            'i'
                          )
                        )"
                    >
                      <mark
                        v-if="
                          fragment.toLowerCase() === searchText.toLowerCase()
                        "
                        :key="i"
                        class="highlight"
                        >{{ fragment }}</mark
                      >
                      <template v-else>{{ fragment }}</template>
                    </template>
                  </span>
                  <template v-else>
                    <a-dropdown :trigger="['contextmenu']">
                      <span v-if="column.dataIndex === 'id_pemohon'">
                        {{ record.pemohon === null ? 'Tidak ada pemohon' : record.pemohon.nama }}
                      </span>
                      <span v-else> {{ text }}</span>
                      <a-menu slot="overlay">
                        <a-menu-item>
                          <a-icon
                            type="edit"
                            class="text-warning"
                            @click="sendToForm('update', record)"
                          />
                          <span class="ml-1 text-warning">Edit</span>
                        </a-menu-item>
                        <a-menu-item>
                          <a-icon
                            type="delete"
                            class="text-danger"
                            @click="showDeleteConfirm(record)"
                          />
                          <span class="ml-1 text-danger">Hapus</span>
                        </a-menu-item>
                        <a-menu-item>
                          <a-icon
                            type="printer"
                            class="text-success"
                            @click="getReciept(record)"
                          />
                          <span class="ml-1 text-success">Print</span>
                        </a-menu-item>
                      </a-menu>
                    </a-dropdown>
                  </template>
                </template>
                <template slot="operations" slot-scope="text, record">
                  <a-icon
                    type="edit"
                    class="text-warning"
                    @click="sendToForm('update', record)"
                  />
                  <a-divider type="vertical" />
                  <a-icon
                    type="delete"
                    class="text-danger"
                    @click="showDeleteConfirm(record)"
                  />
                  <a-divider type="vertical" />
                  <a-icon
                    type="printer"
                    class="text-success"
                    @click="getReciept(record)"
                  />
                </template>
                <template slot="dropdown" slot-scope="text, record">
                  <a-dropdown :trigger="['contextmenu']">
                    <!-- <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12"> -->
                    <span> {{ text }}</span>
                    <!-- </div> -->
                    <a-menu slot="overlay">
                      <a-menu-item>
                        <a-icon
                          type="edit"
                          class="text-warning"
                          @click="sendToForm('update', record)"
                        />
                        <span class="ml-1 text-warning">Edit</span>
                      </a-menu-item>
                      <a-menu-item>
                        <a-icon
                          type="delete"
                          class="text-danger"
                          @click="showDeleteConfirm(record)"
                        />
                        <span class="ml-1 text-danger">Hapus</span>
                      </a-menu-item>
                      <a-menu-item>
                        <a-icon
                          type="printer"
                          class="text-success"
                          @click="getReciept(record)"
                        />
                        <span class="ml-1 text-success">Print</span>
                      </a-menu-item>
                    </a-menu>
                  </a-dropdown>
                </template>
                <span slot="tanggal_register" slot-scope="text, record">
                  {{ moment(record.pemohon.tglregister, 'YYYY-MM-DD').format('DD-MM-YYYY') }}
                </span>
                <span slot="number" slot-scope="text">
                  {{ numberFormat(text) }}
                </span>
              </a-table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <detail ref="datadetail"> </detail>
  </div>
</template>

<script>
import NProgress from 'nprogress'
import * as lou from '@/services/data/lou'
import moment from 'moment'
import detail from './detail'
import { Modal } from 'ant-design-vue'

import firebase from 'firebase/app'
import 'firebase/storage'

import Docxtemplater from 'docxtemplater'
import ImageModule from 'docxtemplater-image-module-free'
import PizZip from 'pizzip'
import { saveAs } from 'file-saver'
import PizZipUtils from 'pizzip/utils/index.js'
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState(['settings', 'user']),
  },
  components: {
    detail,
  },
  data() {
    return {
      columns: [
        {
          title: 'Actions',
          dataIndex: '#',
          width: 100,
          scopedSlots: {
            customRender: 'operations',
          },
        },
        {
          title: 'Nomor Taksasi',
          dataIndex: 'nomortaksasi',
          scopedSlots: {
            customRender: 'nomortaksasi',
          },
        },
        {
          title: 'Tanggal Register',
          dataIndex: 'tanggal_register',
          scopedSlots: {
            customRender: 'tanggal_register',
          },
        },
        {
          title: 'Pemohon',
          dataIndex: 'kode_pemohon',
          scopedSlots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
            customRender: 'customRender',
          },
          onFilter: (value, record) =>
            record.kode_pemohon
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus()
              }, 0)
            }
          },
        },
        {
          title: 'Nominal',
          dataIndex: 'nominal',
          ellipsis: true,
          scopedSlots: {
            customRender: 'number',
          },
        },
        // {
        //   title: 'BPKB atas nama',
        //   dataIndex: 'atasnamabpkb',
        //   scopedSlots: {
        //     customRender: 'dropdown',
        //   },
        // },
      ],
      datatable: [],
      searchText: '',
      searchInput: null,
      searchedColumn: '',
      dropdownVisibility: false,
      loading: false,
    }
  },
  created() {
    this.getAllData()
  },
  methods: {
    moment,
    async downloadTemplate() {
      // eslint-disable-next-line no-unused-vars
      const storageRef = await firebase
        .storage()
        .ref('taksasi/template/' + this.user.sandibpr + 'taksasidepositotemplate.docx').getDownloadURL().then((url) => {
          // `url` is the download URL
          // console.log(url)
          // This can be downloaded directly:
          const xhr = new XMLHttpRequest()
          xhr.responseType = 'blob'
          xhr.onload = function () {
            const blob = xhr.response
            const link = document.createElement('a')
            link.href = URL.createObjectURL(blob)
            link.download = 'Taksasi Deposito Active Template'
            link.click()
            URL.revokeObjectURL(link.href)
          }
          xhr.open('GET', url)
          xhr.send()
        }).catch((error) => {
          // Handle any errors
          switch (error.code) {
            case 'storage/object-not-found':
              // File doesn't exist
              lou.shownotif("File doesn't exist", 'File for archive ' + this.user.sandibpr + " doesn't exist", 'error')
              this.downloadTemplateDefault()
              break

            case 'storage/unauthorized':
              // User doesn't have permission to access the object
              lou.shownotif("User doesn't have permission to access the object")
              break

            case 'storage/canceled':
              // User canceled the upload
              lou.shownotif('User canceled the upload')
              break

            case 'storage/unknown':
              // Unknown error occurred, inspect the server response
              lou.shownotif('Unknown error occurred, inspect the server response')
              break
            default:
              break
          }
        })
    },
    async downloadTemplateDefault() {
      lou.shownotif('Default Template', 'Redirecting to the default template instead.')
      // eslint-disable-next-line no-unused-vars
      const storageRef = await firebase
        .storage()
        .ref('taksasi/template/taksasidepositotemplate.docx').getDownloadURL().then((url) => {
          // `url` is the download URL
          // console.log(url)
          // This can be downloaded directly:
          const xhr = new XMLHttpRequest()
          xhr.responseType = 'blob'
          xhr.onload = function () {
            const blob = xhr.response
            const link = document.createElement('a')
            link.href = URL.createObjectURL(blob)
            link.download = 'Taksasi Deposito Active Default Template'
            link.click()
            URL.revokeObjectURL(link.href)
          }
          xhr.open('GET', url)
          xhr.send()
        }).catch((error) => {
          // Handle any errors
          switch (error.code) {
            case 'storage/object-not-found':
              // File doesn't exist
              lou.shownotif("File doesn't exist")
              break

            case 'storage/unauthorized':
              // User doesn't have permission to access the object
              lou.shownotif("User doesn't have permission to access the object")
              break

            case 'storage/canceled':
              // User canceled the upload
              lou.shownotif('User canceled the upload')
              break

            case 'storage/unknown':
              // Unknown error occurred, inspect the server response
              lou.shownotif('Unknown error occurred, inspect the server response')
              break
            default:
              break
          }
        })
    },
    loadFile(url, callback) {
      PizZipUtils.getBinaryContent(url, callback)
    },
    async getImageUrl(name) {
      const storageRef = await firebase
        .storage()
        .ref('taksasi/bpkb/' + name).getDownloadURL()
      return storageRef
    },
    async generateDocx(record) {
      // lou.shownotif('Word Generator!', 'Generating Word File!', 'info')
      NProgress.start()
      var storageRef = ''
      await firebase
        .storage()
        .ref('taksasi/template/' + this.user.sandibpr + 'taksasidepositotemplate.docx').getDownloadURL().then((url) => {
          // `url` is the download URL
          // console.log(url)
          storageRef = url
        // eslint-disable-next-line handle-callback-err
        }).catch(async (error) => {
          // Handle any errors
          var defaultTemplateLink = await firebase
            .storage()
            .ref('taksasi/template/taksasidepositotemplate.docx').getDownloadURL()
          storageRef = defaultTemplateLink
          // switch (error.code) {
          //   case 'storage/object-not-found':
          //     // File doesn't exist
          //     lou.shownotif("File doesn't exist")
          //     break

          //   case 'storage/unauthorized':
          //     // User doesn't have permission to access the object
          //     lou.shownotif("User doesn't have permission to access the object")
          //     break

          //   case 'storage/canceled':
          //     // User canceled the upload
          //     lou.shownotif('User canceled the upload')
          //     break

          //   case 'storage/unknown':
          //     // Unknown error occurred, inspect the server response
          //     lou.shownotif('Unknown error occurred, inspect the server response')
          //     break
          //   default:
          //     break
          // }
        })
      // console.log('storageRef', storageRef)
      var that = this
      this.loadFile(
        storageRef,
        function (error, content) {
          var dataset = {
            ...record,
          }
          // console.log('record', record)
          dataset.tglSekarang = moment().locale('id').format('DD MMMM YYYY')
          // const data = record.pemohon
          dataset.namaUserLogin = that.user.name
          dataset.nominal = record.nominal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
          // console.log('dataset', dataset)
          var opts = {}
          opts.centered = false
          opts.getImage = function (tagValue, tagName) {
            // console.log('tagValue', tagValue)
            return new Promise(function (resolve, reject) {
              PizZipUtils.getBinaryContent(tagValue, function (error, content) {
                // console.log('content', content)
                if (error) {
                  // console.log('error', error)
                  return reject(error)
                }
                return resolve(content)
              })
            })
          }
          opts.getSize = function (img, tagValue, tagName) {
            // FOR FIXED SIZE IMAGE :
            return [405, 303]
          }
          // eslint-disable-next-line no-unused-vars
          var imageModule = new ImageModule(opts)
          if (error) {
            throw error
          }
          const zip = new PizZip(content)
          const doc = new Docxtemplater()
            .loadZip(zip)
            .attachModule(imageModule)
            .compile()

          // render the document (replace all occurences of {first_name} by John, {last_name} by Doe, ...)
          doc.renderAsync(dataset).then(() => {
            // console.log('ready')
            // doc.render()
            const out = doc.getZip().generate({
              type: 'blob',
              mimeType:
                'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            })
            lou.shownotif('Generator success!', 'Generate word success without problem!', 'success')
            NProgress.done()
            // Output the document using Data-URI
            saveAs(out, 'Taksasi Deposito_ ' + record.pemohon.nama + '_' + record.nomortaksasi + '.docx')
          })
        },
      )
    },
    async getAllData() {
      var response = await lou.customUrlGet2('taksasideposito')
      this.datatable = response.data
    },
    async getReciept(record) {
      setTimeout(() => {
        this.$refs.datadetail.close()
      }, 50)
      this.generateDocx(record)
      // await lou.getDocument('taksasideposito', record.id)
    },
    async getExDocument() {
      // await lou.getDocument('taksasideposito', '')
      this.downloadTemplate()
    },
    showUpload() {
      this.$refs.file.click()
    },
    async handleFileUpload() {
      this.file = this.$refs.file.files[0]
      // console.log('this.$refs.file.files[0]', this.$refs.file.files[0])
      // const formData = new FormData()
      // formData.append('template', this.file)
      // await lou.uploadDocument('taksasibpkb', formData)
      this.uploadNewTemplate()
    },
    async uploadNewTemplate() {
      if (this.file !== null) {
        NProgress.start()
        const storageRef = firebase
          .storage()
          .ref('taksasi/template/' + this.user.sandibpr + 'taksasidepositotemplate.docx')
          .put(this.file) // File
        storageRef.on(
          'state_changed',
          (snapshot) => {
            this.uploadvalue =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          },
          (error) => {
            // console.log('error.message', error.message)
            lou.shownotif('Error!', 'Template gagal diperbarui!', 'error')
          },
          () => {
          // this.uploadvalue = 100
          // console.log('url', url)
            storageRef.snapshot.ref.getDownloadURL().then((url) => {
              if (url) {
                this.file = null
                NProgress.done()
                lou.shownotif('Success', 'Template telah berhasil diperbarui!', 'success')
              }
              // this.urlGambar.push({ key: element.from, link: url })
              // if (this.urlGambar.length === this.input.uploadbpkb.length) {
              //   this.simpan()
              // }
              return url
            })
          },
        )
      }
    },
    sendToForm(action, edata = {}) {
      setTimeout(() => {
        this.$refs.datadetail.close()
      }, 50)
      localStorage.removeItem('datanasabah')
      if (action === 'create') {
        localStorage.setItem('editformdeposito', JSON.stringify({ id: '' }))
      } else {
        localStorage.setItem('editformdeposito', JSON.stringify(edata))
      }
      this.$router.push('/taksasijaminan/deposito/form')
    },
    showDeleteConfirm(deldata) {
      setTimeout(() => {
        this.$refs.datadetail.close()
      }, 50)
      Modal.confirm({
        title: 'Are you sure delete this data?',
        content: "You can't prevent this!!! ",
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'No',
        onOk: async () => {
          await lou.customUrlDelete2('taksasideposito/' + deldata.id)
          this.getAllData()
        },
        onCancel: () => {
          // console.log('Cancel')
        },
      })
    },
    customRow(record, index) {
      return {
        on: {
          click: (event) => {
            // console.log('record', record)
            // this.$refs.datadetail.open(record)
          },
          contextmenu: (event) => {
            event.preventDefault()
            this.dropdownVisibility = true
            // console.log('this.dropdownVisibility', this.dropdownVisibility)
          },
        },
      }
    },
    handleSearch(selectedKeys, confirm, dataIndex) {
      confirm()
      this.searchText = selectedKeys[0]
      this.searchedColumn = dataIndex
    },

    handleReset(clearFilters) {
      clearFilters()
      this.searchText = ''
    },
    numberFormat(value) {
      return lou.curency(value)
    },
  },
}
</script>

<style></style>
