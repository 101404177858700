<template>
  <div>
    <a-card>
      <template slot="title">
        <strong class="mr-3">Data Pembayaran</strong>
        <!-- <a-range-picker class="ml-3" :format="'DD-MM-YYYY'" @change="rangedofunction"/> -->
        <!-- <a-button
          type="button"
          class="btn btn-outline-primary ml-2"
          @click="connector('nonanggota')"
        >
          Tambah Non Anggota
        </a-button> -->
        <a-button-group>
          <a-button @click="connector('create', {})">
            <a-icon class="text-primary" type="plus-circle"></a-icon
          ></a-button>
          <a-tooltip placement="top">
            <template slot="title">
              <span>Refresh Data</span>
            </template>
            <a-button @click="getAllData(true)">
              <a-icon type="sync" :spin="loading" class="text-success" />
            </a-button>
          </a-tooltip>
          <a-button class="text-success" @click="exportExcel">
            <a-icon type="file-excel" />
          </a-button>
        </a-button-group>
      </template>
      <!-- :scroll="{ x: 0 }" -->
      <a-table
        :columns="columns"
        :dataSource="data"
        style="margin: -25px"
        :pagination="{
          hideOnSinglePage: true,
          defaultPageSize: 10,
          showQuickJumper: true,
          showSizeChanger: true,
          showTotal: (total) => `Total ${total} items`,
          pageSizeOptions: ['10', '20', '30'],
        }"
        size="small"
      >
        <div
          slot="filterDropdown"
          slot-scope="{
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
            column,
          }"
          style="padding: 8px"
        >
          <a-input
            v-ant-ref="(c) => (searchInput = c)"
            :placeholder="`Search ${column.dataIndex}`"
            :value="selectedKeys[0]"
            style="width: 188px; margin-bottom: 8px; display: block"
            @change="
              (e) => setSelectedKeys(e.target.value ? [e.target.value] : [])
            "
            @pressEnter="
              () => handleSearch(selectedKeys, confirm, column.dataIndex)
            "
          />
          <a-button
            type="primary"
            icon="search"
            size="small"
            style="width: 90px; margin-right: 8px"
            @click="() => handleSearch(selectedKeys, confirm, column.dataIndex)"
          >
            Search
          </a-button>
          <a-button
            size="small"
            style="width: 90px"
            @click="() => handleReset(clearFilters)"
          >
            Reset
          </a-button>
        </div>
        <a-icon
          slot="filterIcon"
          slot-scope="filtered"
          type="search"
          :style="{ color: filtered ? '#108ee9' : undefined }"
        ></a-icon>
        <template slot="customRender" slot-scope="text, record, index, column">
          <span v-if="searchText && searchedColumn === column.dataIndex">
            <template
              v-for="(fragment, i) in text
                .toString()
                .split(new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i'))"
            >
              <mark
                v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                :key="i"
                class="highlight"
                >{{ fragment }}</mark
              >
              <template v-else>{{ fragment }}</template>
            </template>
          </span>
          <template v-else>
            {{ text }}
          </template>
        </template>
        <a slot="name" slot-scope="text" href="javascript:;">{{ text }}</a>
        <span slot="customTitle"><a-icon type="smile-o" /> Kode</span>
        <span slot="tags" slot-scope="tags">
          <a-tag v-for="tag in tags" color="blue" :key="tag">{{ tag }}</a-tag>
        </span>
        <span slot="action" slot-scope="text, record">
          <a href="javascript:void(0)" class="text-warning" @click="sendToEdit(record)">
            <a-icon type="edit"></a-icon>
          </a>
          <a-divider type="vertical" />
          <a href="javascript:void(0)" class="text-danger" @click="showDeleteConfirm(record)">
            <a-icon type="delete"></a-icon>
          </a>
          <!-- <a href="javascript:;" class="ant-dropdown-link">
            More actions <a-icon type="down" />
          </a> -->
        </span>
        <span slot="numberFormat" slot-scope="text">
          {{ numberFormat(text) }}
        </span>
        <span slot="dateFormat" slot-scope="text">
          {{ formatDate(text) }}
        </span>
        <span slot="student_id" slot-scope="text">
          {{ findMaster("masterStudent", "id", text, "name") }}
        </span>
        <span slot="payment_type_id" slot-scope="text">
          {{ findMaster("masterPaymentType", "id", text, "name") }}
        </span>
        <span slot="schedule_id" slot-scope="text">
          {{ formatDate(findMaster("masterSchedule", "id", text, "due_date")) }}
        </span>
        <template
          :expandIconColumnIndex="3"
          slot="expandedRowRender"
          slot-scope="record"
          style="margin: 1"
        >
          <a-table
            :columns="columnChildren"
            :dataSource="record.details"
            size="small"
            :pagination="{
              hideOnSinglePage: true,
              defaultPageSize: 10,
              showQuickJumper: true,
              showSizeChanger: true,
              showTotal: (total) => `Total ${total} items`,
              pageSizeOptions: ['10', '20', '30'],
            }"
          >
            <span slot="formatCurrency" slot-scope="text">
              {{ numberFormat(text) }}
            </span>
          </a-table>
        </template>
      </a-table>
    </a-card>
    <a-modal
      v-model="formvisible"
      title="Pembayaran"
      :width="550"
      :dialogStyle="{ top: '10px' }"
    >
      <template slot="footer">
        <a-button class="" type="danger" @click="handleCancel">Cancel</a-button>
        <a-button class="" type="primary" @click="handleSubmit"
          >Submit</a-button
        >
      </template>
      <div class="row">
        <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
          <label class="mt-1">Jadwal</label>
        </div>
        <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
          <a-select
            class=""
            show-search
            placeholder="Pilih Jadwal"
            v-model="modalInput.schedule_id"
            option-filter-prop="children"
            :filter-option="filterOption"
            style="width: 100%"
            @change="autoAmount"
          >
            <!-- <a-spin v-if="fetching" slot="notFoundContent" size="small" /> -->
            <a-select-option
              style="width: 100%"
              v-for="d in masterSchedule"
              :key="d.id"
            >
              <!-- {{ formatDate(d.start_date) + " - " + formatDate(d.end_date) }} -->
              {{ formatDate(d.due_date) }}
            </a-select-option>
          </a-select>
        </div>
        <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
          <label class="mt-1">Jumlah</label>
        </div>
        <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
          <cleave
            :options="{ numeral: true }"
            class="ant-input"
            :raw="true"
            style="width: 100%"
            v-model="modalInput.amount"
            placeholder="Jumlah"
          ></cleave>
        </div>
        <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
          <label class="mt-1">Payment Date</label>
        </div>
        <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
          <a-date-picker
            class=""
            style="width: 120px"
            v-model="modalInput.payment_date"
            :allowClear="false"
            format="DD-MM-YYYY"
          />
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
/* Import Script */
import { Modal } from 'ant-design-vue'
import * as lou from '@/services/data/lou'
import moment from 'moment'
import NProgress from 'nprogress'
import Cleave from 'vue-cleave-component'
// import { mapState } from 'vuex'
/* End Script Import Script */

/* Kolom atau header dari table */
const columns = [
  {
    title: 'Action',
    scopedSlots: { customRender: 'action' },
    // fixed: 'left',
    width: 70,
  },
  {
    title: 'Payment Date',
    dataIndex: 'payment_date',
    align: 'center',
    scopedSlots: { customRender: 'dateFormat' },
    // width: 100,
  },
  {
    title: 'Nama',
    dataIndex: 'name',
    align: 'center',
    // width: 160,
    // fixed: 'left',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
    onFilter: (value, record) =>
      record.name.toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => {
          this.searchInput.focus()
        }, 0)
      }
    },
  },
  // {
  //   title: 'Jadwal',
  //   dataIndex: 'schedule_id',
  //   // width: 160,
  //   // fixed: 'left',
  //   scopedSlots: {
  //     customRender: 'schedule_id',
  //   },
  // },
  {
    title: 'Amount',
    dataIndex: 'amount',
    align: 'center',
    // width: 160,
    // fixed: 'left',
    scopedSlots: {
      customRender: 'numberFormat',
    },
  },
  {
    title: 'Notes',
    dataIndex: 'notes',
    align: 'center',
    scopedSlots: { customRender: '' },
    // width: 100,
  },
]
/* End script Kolom atau header dari table */

/* Data dari table */
const data = []
/* End script Data dari table */

export default {
  components: {
    cleave: Cleave,
  },
  computed: {
    // ...mapState(['settings', 'user']),
  },
  created() {
    this.getAllData()
  },
  /* Data variable vue */
  data() {
    return {
      loading: false,

      searchText: '',
      tgl1: '',
      tgl2: '',
      searchInput: null,
      searchedColumn: '',
      name: 'Tabletabungan',
      visible: false,
      allMaster: [],
      masterstatuskawin: [],
      masterkelamin: [],
      data,
      columns,
      datenow: '',
      componentKey: 0,

      // Modal property
      masterStudent: [],
      masterPaymentType: [],
      masterSchedule: [],
      action: null,
      formvisible: false,
      editdata: {},
      modalInput: {
        // schedule_id: '',
        payment_date: moment(),
        amount: 0,
      },

      // Expanded Table
      columnChildren: [
        {
          title: 'Payment Type',
          dataIndex: 'payment_type_name',
          align: 'center',
          // width: 160,
          // fixed: 'left',
          scopedSlots: {
            // customRender: 'formatCurrency',
          },
        },
        {
          title: 'Academic Year',
          dataIndex: 'academicYear',
          align: 'center',
          // width: 160,
          // fixed: 'left',
          scopedSlots: {
            // customRender: 'formatCurrency',
          },
        },
        {
          title: 'Pembayaran',
          dataIndex: 'pembayaran',
          align: 'center',
          // width: 160,
          // fixed: 'left',
          scopedSlots: {
            customRender: 'formatCurrency',
          },
        },
      ],
    }
  },
  /* End Data variable vue */
  mounted: function () {
    const date = moment().format('YYYY-MM-DD') // Tanggal hari ini format 2020-12-31
    // localStorage.removeItem('newkoperasiedittabungandata')
    this.tgl1 = date
    this.tgl2 = date
    this.datenow = date.toString()
    this.getMaster()
  },
  methods: {
    moment,
    connector() {
      // console.log('keanggotaan', keanggotaan)
      localStorage.setItem(
        'schoolmanagementformpembayaran',
        JSON.stringify({ id: '' }),
      )
      this.$router.push('/schoolmanagement/pembayaran/form')
    },
    sendToEdit(edata) {
      // var ndata = {}
      // for (let i = 0; i < this.columns.length; i++) {
      //   const element = this.columns[i].dataIndex
      //   ndata[element.toString().toLowerCase()] = edata[element]
      // }
      // console.log('edata', edata)
      localStorage.setItem(
        'schoolmanagementformpembayaran',
        JSON.stringify(edata),
      )
      this.$router.push('/schoolmanagement/pembayaran/form')
    },
    autoAmount() {
      var target = this.masterSchedule.findIndex(
        (x) => x.id === this.modalInput.schedule_id,
      )
      if (target >= 0) {
        this.modalInput.amount = parseInt(this.masterSchedule[target].amount)
      }
    },
    async getMaster() {
      this.masterSchedule = []
      // this.masterStudent = []
      // this.masterPaymentType = []
      var res = await lou.customUrlGet2('sekolah/paymentschedule')
      // var res1 = await lou.customUrlGet2('sekolah/student')
      // var res2 = await lou.customUrlGet2('sekolah/payment_type')
      if (res) {
        this.masterSchedule = res.data
        // this.masterStudent = res1.data
        // this.masterPaymentType = res2.data
      }
    },
    findMaster(mastername, key, val, tokey) {
      var ret = ''
      var trgt = this[mastername].findIndex((x) => x[key] === val)
      if (trgt >= 0) {
        // if (mastername === 'masterSchedule') {
        // console.log('this[mastername][trgt][tokey]', this[mastername][trgt][tokey])
        // }
        ret = this[mastername][trgt][tokey]
      } else {
        ret = 'Data tidak ditemukan!'
      }
      return ret
    },
    async showModal(action, data) {
      // this.masterSchedule = []
      // var res = await lou.customUrlGet2('sekolah/payment_type')
      // console.log('res', data)
      // if (res) {
      //   res.data.forEach((x) => {
      //     var np = {
      //       ...x,
      //       label: x.name + ' - ' + x.description,
      //     }
      //     this.masterSchedule.push(np)
      //   })
      this.formvisible = true
      this.action = action
      // console.log('this.masterSchedule', this.masterSchedule)
      if (action === 'update') {
        this.modalInput = this.$g.clone(data)
        this.modalInput.payment_date = moment(data.payment_date, 'YYYY-MM-DD')
        this.editdata = data
      }
      // } else {
      //   // lou.shownotif('Api!', 'Pastikan i')
      // }
    },
    async getAllData(onclick = false) {
      var response = await lou.customUrlGet2('sekolah/payment')
      if (response) {
        this.data = response.data
        if (onclick) {
          lou.shownotif('Refresh', 'Data telah di refresh.')
        }
      }
      // console.log('this.data', this.data)
    },
    formatDate(value) {
      return moment(value).format('DD-MM-YYYY')
    },
    showDeleteConfirm(deldata) {
      Modal.confirm({
        title: 'Are you sure delete this data?',
        content: "You can't prevent this!!! ",
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'No',
        onOk: async () => {
          var response = await lou.customUrlDelete2(
            'sekolah/payment/' + deldata.id,
          )
          if (response) {
            this.getAllData()
          }
        },
        onCancel: () => {
          // console.log('Cancel')
        },
      })
    },
    handleSearch(selectedKeys, confirm, dataIndex) {
      confirm()
      this.searchText = selectedKeys[0]
      this.searchedColumn = dataIndex
    },

    handleReset(clearFilters) {
      clearFilters()
      this.searchText = ''
    },

    // Modal function
    async handleSubmit() {
      var fd = this.$g.clone(this.modalInput)
      fd.payment_date = this.modalInput.payment_date.format('YYYY-MM-DD')
      var res = false
      if (this.action === 'update') {
        res = await lou.customUrlPut2('sekolah/payment', fd)
      } else {
        res = await lou.customUrlPost2('sekolah/payment', fd)
      }
      if (res) {
        this.getAllData()
        this.handleCancel()
      }
    },
    handleCancel() {
      this.formvisible = false
      this.action = 'create'
      // this.masterSchedule = []
      this.modalInput = {
        schedule_id: '',
        payment_date: moment(),
        amount: 0,
      }
    },
    async exportExcel() {
      NProgress.start()
      const ExcelJS = require('exceljs')
      const workbook = new ExcelJS.Workbook()
      workbook.creator = 'LB-BPRS Heasoft System'
      workbook.lastModifiedBy = 'Me'
      workbook.created = new Date()
      workbook.modified = new Date()
      workbook.lastPrinted = new Date()
      var alphabeth = [
        'A',
        'B',
        'C',
        'D',
        'E',
        'F',
        'G',
        'H',
        'I',
        'J',
        'K',
        'L',
        'M',
        'N',
        'O',
        'P',
        'Q',
        'R',
        'S',
        'T',
        'U',
        'V',
        'W',
        'X',
        'Y',
        'Z',
      ]
      const worksheet = workbook.addWorksheet(moment().format('DD-MM-YYYY'))

      // Table Data
      var column = this.$g.clone(this.columns)
      var tdata = this.$g.clone(this.data)
      var data = []
      tdata.forEach((element) => {
        var retv = {
          ...element,
          tgl:
            element.tgl === null
              ? ''
              : moment(element.tgl, 'YYYY-MM-DD').format('DD-MM-YYYY'),
        }
        data.push(retv)
      })
      var cfirst = 0
      var cloop = 0
      var dincrement = 2
      var columnLocation = []
      for (let i = 0; i < column.length; i++) {
        const element = column[i]
        var cloopni = cloop - 1
        var hname = element.title.toString()
        if (hname !== 'Action') {
          if (cloop === 0) {
            columnLocation.push({
              location: alphabeth[cfirst],
              field: element.dataIndex,
            })
            worksheet.getCell(alphabeth[cfirst] + '1').value = hname.replaceAll(
              ' ',
              '',
            )
          } else {
            columnLocation.push({
              location: alphabeth[cloopni] + alphabeth[cfirst],
              field: element.dataIndex,
            })
            worksheet.getCell(
              alphabeth[cloopni] + alphabeth[cfirst] + '1',
            ).value = hname.replaceAll(' ', '')
          }
          for (let u = 0; u < data.length; u++) {
            const el = data[u]
            if (cloop === 0) {
              worksheet.getCell(alphabeth[cfirst] + dincrement).value =
                el[element.dataIndex]
            } else {
              worksheet.getCell(
                alphabeth[cloopni] + alphabeth[cfirst] + dincrement,
              ).value = el[element.dataIndex]
            }
            var checklast = u + 1
            if (checklast === data.length) {
              dincrement = 2
            } else {
              dincrement += 1
            }
          }
          if (cfirst === 25) {
            cfirst = 0
            cloop += 1
          } else {
            cfirst += 1
          }
        }
      }

      /* For sum data */
      // var last = data.length + 3
      // worksheet.mergeCells('A' + last + ':B' + last)
      // worksheet.getCell('A' + last).value = 'Total'
      // for (let ti = 0; ti < this.columnsTotal.length; ti++) {
      //   const element = this.columnsTotal[ti]
      //   var targetLoc = columnLocation.findIndex(x => x.field === element.dataIndex)
      //   if (targetLoc >= 0) {
      //     worksheet.getCell(columnLocation[targetLoc].location + last).value = this.rowDataTotal[0][element.dataIndex]
      //   }
      //   // console.log(columnLocation[targetLoc].location + last, this.rowDataTotal[0][element.dataIndex])
      // }

      // write to a new buffer
      const buffer = await workbook.xlsx.writeBuffer()
      var blob = new Blob([buffer], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      })
      var link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = 'Data Master sekolah/payment.xlsx'
      NProgress.done()
      link.click()
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      )
    },
    numberFormat(val) {
      return lou.curency(val)
    },
  },
}
</script>

<style scoped>
.highlight {
  background-color: rgb(255, 192, 105);
  padding: 0px;
}
</style>
