<template>
  <div class="row">
    <div class="col-xs-5 col-sm-5 col-md-5 col-lg-5">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mb-3">
              <strong>Data Pemohon</strong>
            </div>
            <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 mt-1">
              <span>Nomor Registrasi</span>
            </div>
            <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8">
              <!-- <a-input
                :readOnly="true"
                v-if="action === 'update'"
                placeholder="Data pemohon tidak dapat diubah"
              ></a-input> -->
              <a-select
                v-if="pinginubah"
                show-search
                label-in-value
                v-model="input.datapemohon.nomor_registrasi"
                placeholder="Nomor registrasi pemohon"
                id="inputdatapemohonnomor_registrasi"
                style="width: 100%;"
                :filter-option="false"
                :not-found-content="fetching ? undefined : null"
                @search="fetchUser"
                @change="handleChange"
              >
                <a-spin v-if="fetching" slot="notFoundContent" size="small" />
                <a-select-option v-for="d in data" :key="d.kode">
                  {{ d.kode + "-" + d.nama }}
                </a-select-option>
              </a-select>
              <a-input
                :readOnly="true"
                :value="input.datapemohon.nomor_registrasi.key"
                @click="
                  datanasabah !== null
                    ? (pinginubah = false)
                    : (pinginubah = true)
                "
                v-else
              ></a-input>
            </div>
            <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 mt-2">
              <span>Tanggal Permohonan</span>
            </div>
            <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8 mt-1">
              <a-date-picker
                id="inputdatapemohontanggal_permohonan"
                style="width: 120px;"
                format="DD-MM-YYYY"
                disabled
                v-model="input.datapemohon.tanggal_permohonan"
              />
            </div>
            <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 mt-2">
              <span>Jumlah Kredit</span>
            </div>
            <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8 mt-1">
              <cleave
                :raw="true"
                id="inputdatapemohonjumlah_kredit"
                :options="options.number"
                v-model="input.datapemohon.jumlah_kredit"
                placeholder="Jumlah kredit pemohon"
                class="ant-input"
                :readOnly="true"
                style="cursor: not-allowed;"
              />
            </div>
            <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 mt-2">
              <span>Jangka Waktu</span>
            </div>
            <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8 mt-1">
              <a-input
                id="inputdatapemohonjangka_waktu"
                class=""
                v-model="input.datapemohon.jangka_waktu"
                placeholder="Jangka waktu"
                :readOnly="true"
                style="cursor: not-allowed;"
              ></a-input>
            </div>
            <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 mt-2">
              <span>Nama</span>
            </div>
            <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8 mt-1">
              <a-input
                id="inputdatapemohonnama"
                class=""
                v-model="input.datapemohon.nama"
                placeholder="Nama pemohon"
                :readOnly="true"
                style="cursor: not-allowed;"
              ></a-input>
            </div>
            <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 mt-2">
              <span>Pekerjaan</span>
            </div>
            <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8 mt-1">
              <a-input
                id="inputdatapemohonjenis_pekerjaan"
                class=""
                v-model="input.datapemohon.pekerjaan"
                placeholder="Pekerjaan pemohon"
                :readOnly="true"
                style="cursor: not-allowed;"
              ></a-input>
            </div>
            <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 mt-2">
              <span>Nomor Telepon</span>
            </div>
            <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8 mt-1">
              <a-input
                id="inputdatapemohonnomor_telepon"
                class=""
                v-model="input.datapemohon.nomor_telepon"
                placeholder="Nomor telepon"
                :readOnly="true"
                style="cursor: not-allowed;"
              ></a-input>
            </div>
            <a-divider />
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mb-3">
              <strong>Metode Penilaian</strong>
            </div>
            <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4">
              <span
                @click="
                  showModal(
                    'Informasi pasar/tanah',
                    'datatableinformasiPasarTanah'
                  )
                "
                style="cursor: pointer;"
                >Informasi</span
              >
              <a-icon
                type="table"
                class="ml-2 text-primary"
                @click="
                  showModal(
                    'Informasi pasar/tanah',
                    'datatableinformasiPasarTanah'
                  )
                "
              />
            </div>
            <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8">
              <div class="row">
                <a-input
                  id="inputfortablejenis"
                  class=""
                  v-model="input.fortable.jenis"
                  placeholder="Keterangan"
                ></a-input>
              </div>
            </div>
            <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4">
              <span>Pasar/Tanah</span>
            </div>
            <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8 mt-1">
              <div class="row">
                <cleave
                  :raw="true"
                  :options="options.number"
                  id="inputfortablevalue"
                  class="col-xs-9 col-sm-9 col-md-9 col-lg-9 ant-input"
                  v-model="input.fortable.value"
                  v-on:keydown.native.enter="tambahinformasiPasarTanah"
                  placeholder="Harga"
                />
                <a-button
                  class="col-xs-3 col-sm-3 col-md-3 col-lg-3 btn btn-outline-primary btn-sm"
                  @click="tambahinformasiPasarTanah"
                  >{{
                    input.fortable.jenis !== "" && input.fortable.value !== ""
                      ? "Tambah"
                      : datatableinformasiPasarTanah.length + " Data"
                  }}</a-button
                >
              </div>
            </div>
            <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 mt-1">
              <span>Rata-Rata Penilaian Tanah</span>
            </div>
            <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8 mt-1">
              <cleave
                :raw="true"
                :options="options.perkubik"
                v-model="input.metode_penilaian.rata2_penilaian_tanah"
                @input="countRataRataPenilaianTanah"
                id="inputmetode_penilaianrata2_penilaian_tanah"
                placeholder="Rata-Rata Penilaian Tanah"
                class="ant-input"
              />
            </div>
            <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 mt-1">
              <span>Taksiran Nilai Bangunan</span>
            </div>
            <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8 mt-1">
              <cleave
                :raw="true"
                :options="options.perkubik"
                v-model="input.metode_penilaian.taksiran_nilai_bangunan"
                id="inputmetode_penilaiantaksiran_nilai_bangunan"
                placeholder="Taksiran nilai bangunan"
                @input="countPenyusutanJaminan"
                class="ant-input"
              />
            </div>
            <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 mt-2">
              <strong>Usia Bangunan</strong>
            </div>
            <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8 mt-1">
              <cleave
                :raw="true"
                :options="options.tahun"
                v-model="input.metode_penilaian.usia_bangunan"
                id="inputmetode_penilaianusia_bangunan"
                placeholder="Usia bangunan"
                @input="countPenyusutanJaminan"
                class="ant-input"
                style="width: 40%;"
              />
              <!-- style="cursor: not-allowed;" -->
            </div>
            <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 mt-1">
              <strong>Penyusutan Jaminan/Bangunan</strong>
            </div>
            <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8 mt-1">
              <cleave
                :raw="true"
                :options="options.perkubik"
                v-model="input.metode_penilaian.penyusutan_jaminan"
                id="inputmetode_penilaianpenyusutan_jaminan"
                placeholder="Penyusutan Jaminan/Bangunan"
                class="ant-input"
              />
              <!-- style="cursor: not-allowed;" -->
            </div>
            <a-divider />
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <strong class="mb-3">Hasil Penilaian Agunan</strong>
              <a-table
                :bordered="true"
                :columns="columnsratapenilaiantanah"
                :data-source="datatableratapenilaiantanah"
                tableLayout="auto"
                size="small"
                :scroll="{ x: 500 }"
                :pagination="{
                  hideOnSinglePage: true,
                  defaultPageSize: 10,
                }"
              >
                <template slot="footer">
                  <div class="row">
                    <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4">
                      <strong>Total Nilai Taksasi</strong>
                    </div>
                    <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8">
                      <strong class="float-right">{{
                        numberFormat(total_nilai_taksasi)
                      }}</strong>
                    </div>
                  </div>
                </template>
                <span slot="nilai_taksasi" slot-scope="text, record">
                  {{ countNilaiTaksasi(record) }}
                </span>
                <span slot="harga" slot-scope="text">
                  {{ numberFormat(text) }}
                </span>
                <span slot="penyusutan" slot-scope="text">
                  {{ numberFormat(text) }}
                </span>
              </a-table>
            </div>
            <a-divider />
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mb-3">
              <strong>Kesimpulan</strong>
            </div>
            <div class="col-xs-5 col-sm-5 col-md-5 col-lg-5 mt-1">
              <span>Nilai Taksasi</span>
            </div>
            <div class="col-xs-7 col-sm-7 col-md-7 col-lg-7">
              <cleave
                :raw="true"
                :options="options.number"
                v-model="input.kesimpulan.nilai_taksasi"
                id="inputkesimpulannilai_taksasi"
                placeholder="Nilai taksasi"
                class="ant-input"
              />
            </div>
            <div class="col-xs-5 col-sm-5 col-md-5 col-lg-5 mt-2">
              <span>Presentase Fasilitas Kredit</span>
            </div>
            <div class="col-xs-7 col-sm-7 col-md-7 col-lg-7 mt-1">
              <cleave
                :raw="true"
                :options="options.persentase"
                class="ant-input"
                v-model="input.kesimpulan.persentase_fasilitas_kredit"
                id="inputkesimpulanpersentase_fasilitas_kredit"
                placeholder="Presentase fasilitas kredit"
                style="width: 50%;"
                @input="countMaksimalFasilitasKredit"
              />
            </div>
            <div class="col-xs-5 col-sm-5 col-md-5 col-lg-5 mt-2">
              <strong>Maksimal Fasilitas Kredit</strong>
            </div>
            <div class="col-xs-7 col-sm-7 col-md-7 col-lg-7 mt-1">
              <cleave
                :raw="true"
                :options="options.number"
                v-model="input.kesimpulan.maksimal_fasilitas_kredit"
                id="inputkesimpulanmaksimal_fasilitas_kredit"
                placeholder="Maks fasilitas kredit"
                class="ant-input"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xs-7 col-sm-7 col-md-7 col-lg-7">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-xs-7 col-sm-7 col-md-7 col-lg-7">
              <div class="row">
                <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 mt-1">
                  <strong>Kode Jaminan</strong>
                </div>
                <div class="col-xs-5 col-sm-5 col-md-5 col-lg-5">
                  <div class="row">
                    <a-select
                      class="col-xs-4 col-sm-4 col-md-4 col-lg-4"
                      placeholder="01"
                      style="width: 100%;"
                      v-model="input.kode_jaminan.kode"
                      id="inputkode_jaminankode"
                    >
                      <!-- <a-icon slot="suffixIcon" type="down" /> -->
                    </a-select>
                    <a-input
                      class="col-xs-7 col-sm-7 col-md-7 col-lg-7 ml-2"
                      v-model="input.kode_jaminan.keterangan"
                      id="inputkode_jaminanketerangan"
                      placeholder=""
                      :readOnly="false"
                      style="cursor: not-allowed;"
                    ></a-input>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xs-5 col-sm-5 col-md-5 col-lg-5">
              <a-button class="btn btn-outline-danger float-right" @click="back"
                >Return</a-button
              >
            </div>
          </div>
          <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <div class="row mt-4">
                <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-1">
                  <span>Nomor Taksasi</span>
                </div>
                <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4">
                  <a-input
                    class=""
                    v-model="input.kode_jaminan.nomor_taksasi"
                    id="inputkode_jaminannomor_taksasi"
                    placeholder="nomor taksasi"
                    :readOnly="false"
                  ></a-input>
                  <!-- style="cursor: not-allowed;" -->
                </div>
              </div>
            </div>
          </div>
          <a-divider />
          <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mb-2">
              <strong>Data Tanah dan Bangunan</strong>
            </div>
            <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-1">
              <span style="margin-top: 10px;">Jenis surat Tanah</span>
            </div>
            <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9">
              <a-input
                class=""
                placeholder="Jenis surat tanah"
                v-model="input.data_tanah_bangunan.jenis_surat_tanah"
                id="inputdata_tanah_bangunanjenis_surat_tanah"
              ></a-input>
            </div>
            <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
              <span>Terdaftar Atas Nama</span>
            </div>
            <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
              <a-input
                class=""
                v-model="input.data_tanah_bangunan.terdaftar_atas_nama"
                id="inputdata_tanah_bangunanterdaftar_atas_nama"
                placeholder="Terdaftar atas nama"
                :readOnly="false"
              ></a-input>
              <!-- style="cursor: not-allowed;" -->
            </div>
            <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
              <span>Nama Pemilik</span>
            </div>
            <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
              <a-input
                class=""
                v-model="input.data_tanah_bangunan.nama_pemilik"
                id="inputdata_tanah_bangunannama_pemilik"
                placeholder="Nama pemilik"
                :readOnly="false"
              ></a-input>
              <!-- style="cursor: not-allowed;" -->
            </div>
            <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
              <span>Lokasi</span>
            </div>
            <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
              <a-input
                class=""
                v-model="input.data_tanah_bangunan.lokasi"
                id="inputdata_tanah_bangunanlokasi"
                placeholder="Lokasi"
                :readOnly="false"
              ></a-input>
              <!-- style="cursor: not-allowed;" -->
            </div>
            <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
              <span>Ditempati Oleh</span>
            </div>
            <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
              <a-input
                class=""
                v-model="input.data_tanah_bangunan.ditempati_oleh"
                id="inputdata_tanah_bangunanditempati_oleh"
                placeholder="Ditempati oleh"
                :readOnly="false"
              ></a-input>
              <!-- style="cursor: not-allowed;" -->
            </div>
            <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
              <span>Dasar menempati</span>
            </div>
            <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
              <a-input
                class=""
                v-model="input.data_tanah_bangunan.dasar_menempati"
                id="inputdata_tanah_bangunandasar_menempati"
                placeholder="Dasar menempati"
                :readOnly="false"
              ></a-input>
              <!-- style="cursor: not-allowed;" -->
            </div>
            <a-divider />
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mb-3">
              <strong>Kondisi Tanah dan Bangunan</strong>
            </div>
            <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
              <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                  <strong>Keadaan Tanah</strong>
                </div>
                <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
                  <span>Topografi</span>
                </div>
                <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                  <a-input
                    class=""
                    v-model="input.kondisi_tanah_bangunan.topografi"
                    id="inputdata_tanah_bangunantopografi"
                    placeholder="Topografi"
                    :readOnly="false"
                  ></a-input>
                  <!-- style="cursor: not-allowed;" -->
                </div>
                <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-1">
                  <span>Bentuk Tanah</span>
                </div>
                <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                  <a-input
                    class=""
                    v-model="input.kondisi_tanah_bangunan.bentuk_tanah"
                    id="inputdata_tanah_bangunanbentuk_tanah"
                    placeholder="Bentuk tanah"
                    :readOnly="false"
                  ></a-input>
                  <!-- style="cursor: not-allowed;" -->
                </div>
                <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-1">
                  <span>Batas Utara</span>
                </div>
                <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                  <a-input
                    class=""
                    v-model="input.kondisi_tanah_bangunan.batas_utara"
                    id="inputdata_tanah_bangunanbatas_utara"
                    placeholder="Batas utara"
                    :readOnly="false"
                  ></a-input>
                  <!-- style="cursor: not-allowed;" -->
                </div>
                <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-1">
                  <span>Batas Timur</span>
                </div>
                <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                  <a-input
                    class=""
                    v-model="input.kondisi_tanah_bangunan.batas_timur"
                    id="inputdata_tanah_bangunanbatas_timur"
                    placeholder="Batas timur"
                    :readOnly="false"
                  ></a-input>
                  <!-- style="cursor: not-allowed;" -->
                </div>
                <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-1">
                  <span>Batas Selatan</span>
                </div>
                <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                  <a-input
                    class=""
                    v-model="input.kondisi_tanah_bangunan.batas_selatan"
                    id="inputdata_tanah_bangunanbatas_selatan"
                    placeholder="Batas selatan"
                    :readOnly="false"
                  ></a-input>
                  <!-- style="cursor: not-allowed;" -->
                </div>
                <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-1">
                  <span>Batas Barat</span>
                </div>
                <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                  <a-input
                    class=""
                    v-model="input.kondisi_tanah_bangunan.batas_barat"
                    id="inputdata_tanah_bangunanbatas_barat"
                    placeholder="Batas barat"
                    :readOnly="false"
                  ></a-input>
                  <!-- style="cursor: not-allowed;" -->
                </div>
                <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-1">
                  <span>Luas tanah</span>
                </div>
                <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                  <cleave
                    :options="options.kubik"
                    class="ant-input"
                    :raw="true"
                    v-model="input.kondisi_tanah_bangunan.luas_tanah"
                    id="inputdata_tanah_bangunanluas_tanah"
                    @input="setTable('luas_tanah')"
                    placeholder="Luas tanah"
                  />
                  <!-- style="cursor: not-allowed;" -->
                </div>
                <div
                  class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-1"
                  style="padding-right: unset;"
                >
                  <span>Luas bangunan</span>
                </div>
                <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                  <cleave
                    :options="options.kubik"
                    class="ant-input"
                    :raw="true"
                    v-model="input.kondisi_tanah_bangunan.luas_bangunan"
                    id="inputdata_tanah_bangunanluas_bangunan"
                    @input="setTable('luas_bangunan')"
                    placeholder="Luas bangunan"
                  />
                  <!-- style="cursor: not-allowed;" -->
                </div>
                <div
                  class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-3"
                  style="padding-right: unset;"
                >
                  <span>Peruntukan</span>
                </div>
                <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                  <a-input
                    class=""
                    v-model="input.kondisi_tanah_bangunan.peruntukan"
                    id="inputdata_tanah_bangunanperuntukan"
                    placeholder="Peruntukan"
                    :readOnly="false"
                  ></a-input>
                  <!-- style="cursor: not-allowed;" -->
                </div>
                <div
                  class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-1"
                  style="padding-right: unset;"
                >
                  <span>Kemungkinan banjir</span>
                </div>
                <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                  <a-input
                    class=""
                    v-model="input.kondisi_tanah_bangunan.kemungkinan_banjir"
                    id="inputdata_tanah_bangunankemungkinan_banjir"
                    placeholder="Kemungkinan banjir"
                    :readOnly="false"
                  ></a-input>
                  <!-- style="cursor: not-allowed;" -->
                </div>
              </div>
            </div>
            <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
              <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                  <strong>Keadaan Bangunan</strong>
                </div>
                <div
                  class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-3"
                  style="padding-right: unset;"
                >
                  <span>Konstruksi</span>
                </div>
                <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                  <a-input
                    class=""
                    v-model="input.kondisi_tanah_bangunan.konstruksi"
                    id="inputkondisi_tanah_bangunankonstruksi"
                    placeholder="Konstruksi"
                    :readOnly="false"
                  ></a-input>
                  <!-- style="cursor: not-allowed;" -->
                </div>
                <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
                  <span>Dinding</span>
                </div>
                <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                  <a-input
                    class=""
                    v-model="input.kondisi_tanah_bangunan.dinding"
                    id="inputdata_tanah_bangunandinding"
                    placeholder="Dinding"
                    :readOnly="false"
                  ></a-input>
                  <!-- style="cursor: not-allowed;" -->
                </div>
                <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
                  <span>Lantai</span>
                </div>
                <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                  <a-input
                    class=""
                    v-model="input.kondisi_tanah_bangunan.lantai"
                    id="inputkondisi_tanah_bangunanlantai"
                    placeholder="Lantai"
                    :readOnly="false"
                  ></a-input>
                  <!-- style="cursor: not-allowed;" -->
                </div>
                <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
                  <span>Atap</span>
                </div>
                <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                  <a-input
                    class=""
                    v-model="input.kondisi_tanah_bangunan.atap"
                    id="inputkondisi_tanah_bangunanatap"
                    placeholder="Atap"
                    :readOnly="false"
                  ></a-input>
                  <!-- style="cursor: not-allowed;" -->
                </div>
                <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-1">
                  <span>Langit-Langit</span>
                </div>
                <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                  <a-input
                    class=""
                    v-model="input.kondisi_tanah_bangunan.langit_langit"
                    id="inputkondisi_tanah_bangunanlangit_langit"
                    placeholder="Langit-Langit"
                    :readOnly="false"
                  ></a-input>
                  <!-- style="cursor: not-allowed;" -->
                </div>
                <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
                  <span>Kusen</span>
                </div>
                <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                  <a-input
                    class=""
                    v-model="input.kondisi_tanah_bangunan.kusen"
                    id="inputkondisi_tanah_bangunankusen"
                    placeholder="Kusen"
                    :readOnly="false"
                  ></a-input>
                  <!-- style="cursor: not-allowed;" -->
                </div>
                <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
                  <span>Pintu</span>
                </div>
                <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                  <a-input
                    class=""
                    v-model="input.kondisi_tanah_bangunan.pintu"
                    id="inputkondisi_tanah_bangunanpintu"
                    placeholder="Pintu"
                    :readOnly="false"
                  ></a-input>
                  <!-- style="cursor: not-allowed;" -->
                </div>
                <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
                  <span>Jendela</span>
                </div>
                <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                  <a-input
                    class=""
                    v-model="input.kondisi_tanah_bangunan.jendela"
                    id="inputkondisi_tanah_bangunanjendela"
                    placeholder="Jendela"
                    :readOnly="false"
                  ></a-input>
                  <!-- style="cursor: not-allowed;" -->
                </div>
                <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
                  <span>Pagar</span>
                </div>
                <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                  <a-input
                    class=""
                    v-model="input.kondisi_tanah_bangunan.pagar"
                    id="inputkondisi_tanah_bangunanpagar"
                    placeholder="Pagar"
                    :readOnly="false"
                  ></a-input>
                  <!-- style="cursor: not-allowed;" -->
                </div>
                <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
                  <span>Listrik</span>
                </div>
                <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                  <a-input
                    class=""
                    v-model="input.kondisi_tanah_bangunan.listrik"
                    id="inputkondisi_tanah_bangunanlistrik"
                    placeholder="Listrik"
                    suffix="VA"
                    :readOnly="false"
                  ></a-input>
                  <!-- style="cursor: not-allowed;" -->
                </div>
                <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
                  <span>Air</span>
                </div>
                <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                  <a-input
                    class=""
                    v-model="input.kondisi_tanah_bangunan.air"
                    id="inputkondisi_tanah_bangunanair"
                    placeholder="Air"
                    :readOnly="false"
                  ></a-input>
                  <!-- style="cursor: not-allowed;" -->
                </div>
                <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
                  <span>Telepon</span>
                </div>
                <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                  <a-input
                    class=""
                    v-model="input.kondisi_tanah_bangunan.telepon"
                    id="inputkondisi_tanah_bangunantelepon"
                    placeholder="Telepon"
                    :readOnly="false"
                  ></a-input>
                  <!-- style="cursor: not-allowed;" -->
                </div>
              </div>
            </div>
            <a-divider />
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <strong>Data Pendukung Lainnya</strong>
            </div>
            <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
              <span>Sarana Jalan</span>
            </div>
            <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
              <a-input
                class=""
                v-model="input.data_pendukung_lainnya.sarana_jalan"
                id="inputdata_pendukung_lainnyasarana_jalan"
                style="width: 50%;"
                placeholder="Sarana jalan"
                :readOnly="false"
              ></a-input>
              <!-- style="cursor: not-allowed;" -->
            </div>
            <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
              <span>Lebar Jalan</span>
            </div>
            <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
              <cleave
                :options="options.meter"
                v-model="input.data_pendukung_lainnya.lebar_jalan"
                id="inputdata_pendukung_lainnyalebar_jalan"
                placeholder="Lebar jalan"
                style="width: 30%;"
                class="ant-input"
              />
              <!-- style="cursor: not-allowed;" -->
            </div>
            <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
              <span
                @click="showModal('Fasilitas umum', 'datatablefasilitasUmum')"
                style="cursor: pointer;"
                >Fasilitas umum</span
              >
              <a-icon
                type="table"
                class="ml-2 text-primary"
                @click="showModal('Fasilitas umum', 'datatablefasilitasUmum')"
              />
            </div>
            <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
              <div class="row">
                <a-input
                  class="col-xs-5 col-sm-5 col-md-5 col-lg-5 mr-1 ml-3"
                  v-model="input.fortable1.jenis"
                  id="inputdata_pendukung_lainnyafasilitas_umumjenis"
                  placeholder="Keterangan"
                  :readOnly="false"
                ></a-input>
                <a-input
                  class="col-xs-4 col-sm-4 col-md-4 col-lg-4 mr-1"
                  v-model="input.fortable1.value"
                  id="inputdata_pendukung_lainnyafasilitas_umumvalue"
                  placeholder="jarak"
                  v-on:keydown.native.enter="tambahfasilitasUmum"
                  :readOnly="false"
                ></a-input>
                <a-button
                  class="btn btn-outline-primary"
                  @click="tambahfasilitasUmum"
                  >{{
                    input.fortable1.jenis !== "" && input.fortable1.value !== ""
                      ? "Tambah"
                      : datatablefasilitasUmum.length + " Data"
                  }}</a-button
                >
              </div>
              <!-- style="cursor: not-allowed;" -->
            </div>
            <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
              <span>Penentuan Value</span>
            </div>
            <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
              <a-input
                class=""
                v-model="input.data_pendukung_lainnya.penentuan_value"
                id="inputdata_pendukung_lainnyapenentuan_value"
                placeholder="Penentuan value"
                :readOnly="false"
              ></a-input>
              <!-- style="cursor: not-allowed;" -->
            </div>
            <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
              <span>Keterangan</span>
            </div>
            <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
              <a-input
                class=""
                v-model="input.data_pendukung_lainnya.keterangan"
                id="inputdata_pendukung_lainnyaketerangan"
                placeholder="Keterangan"
                :readOnly="false"
              ></a-input>
              <!-- style="cursor: not-allowed;" -->
            </div>
            <a-divider />
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mb-3">
              <strong>Analisa Yuridis/Legalitas</strong>
            </div>
            <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2 mt-1">
              <span>Analisa</span>
            </div>
            <div class="col-xs-10 col-sm-10 col-md-10 col-lg-10">
              <a-input
                class=""
                v-model="input.analisa_yuridis.analisa"
                id="inputanalisa_yuridisanalisa"
                placeholder="Ex: Surat-surat kendaraan berupa STNK dan pajak masih berlaku, BPKB lengkap. Kendaraan atas nama debitur"
                :readOnly="false"
              ></a-input>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-2">
              <a-button
                id="therealsimpanbutton"
                :loading="loading"
                class="btn btn-outline-primary col-xs-2 col-sm-2 col-md-2 col-lg-2 float-right"
                @click="validasi"
                >Simpan</a-button
              >
              <!-- <a-button class="btn btn-outline-success mr-2 float-right"
                >Cetak Perjanjian</a-button
              > -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
      <div class="card">
        <div class="card-header">
          <strong>Upload</strong>
          <strong v-if="action === 'update'">
            (*Untuk foto jika tidak ingin merubah biarkan kosong)</strong
          >
          <a-button
            :loading="loading"
            id="therealsimpanbutton"
            class="btn btn-outline-primary col-xs-2 col-sm-2 col-md-2 col-lg-2 float-right"
            @click="validasi"
            >Simpan</a-button
          >
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
              <center>
                <a-input
                  style="text-align: center;"
                  class="col-xs-8 col-sm-8 col-md-8 col-lg-8"
                  placeholder="Akses Jalan"
                  ref="aksesjalan"
                  @click="selectFile('halamanAksesJalan')"
                ></a-input>
              </center>
              <input
                type="file"
                style="display: none;"
                ref="halamanAksesJalan"
                accept="image/*"
                @change="(e) => previewImage(e, 'aksesjalan')"
              />
            </div>
            <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
              <center>
                <a-input
                  style="text-align: center;"
                  class="col-xs-8 col-sm-8 col-md-8 col-lg-8"
                  placeholder="Foto Tampak Depan"
                  ref="tampakdepan"
                  @click="selectFile('halamanTampakDepan')"
                >
                </a-input>
              </center>
              <!-- <a-icon type="close" class="text-danger" @click=""/> -->
              <input
                type="file"
                style="display: none;"
                ref="halamanTampakDepan"
                accept="image/*"
                @change="(e) => previewImage(e, 'tampakdepan')"
              />
            </div>
            <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 mt-1">
              <center>
                <a-card style="width: 50%;">
                  <div>
                    <!-- {{ image.aksesjalan === null }} -->
                    <img
                      :src="image.aksesjalan"
                      style="width: 100%; cursor: pointer;"
                      alt="Akses Jalan"
                      v-if="image.aksesjalan !== null"
                      @click="lihatgambar('Akses Jalan', image.aksesjalan)"
                    />
                    <a-icon
                      type="file-image"
                      style="font-size: 18px; cursor: pointer;"
                      @click="selectFile('halamanAksesJalan')"
                      v-else
                    /></div
                ></a-card>
              </center>
            </div>
            <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 mt-1">
              <center>
                <a-card style="width: 50%;">
                  <div>
                    <img
                      :src="image.tampakdepan"
                      alt="Tampak Depan"
                      style="width: 100%; cursor: pointer;"
                      v-if="image.tampakdepan !== null"
                      @click="lihatgambar('Tampak Depan', image.tampakdepan)"
                    /><a-icon
                      @click="selectFile('halamanTampakDepan')"
                      type="file-image"
                      style="font-size: 18px; cursor: pointer;"
                      v-else
                    /></div
                ></a-card>
              </center>
            </div>
            <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4">
              <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-2">
                  <center>
                    <a-input
                      style="text-align: center;"
                      class="col-xs-10 col-sm-10 col-md-10 col-lg-10"
                      placeholder="Foto Batas Kiri"
                      ref="bataskiri"
                      @click="selectFile('batasKiri')"
                    ></a-input>
                  </center>
                  <input
                    type="file"
                    style="display: none;"
                    ref="batasKiri"
                    accept="image/*"
                    @change="(e) => previewImage(e, 'bataskiri')"
                  />
                </div>
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-1">
                  <center>
                    <a-card style="width: 82%;">
                      <div>
                        <img
                          :src="image.bataskiri"
                          alt="Foto Batas Kiri"
                          style="width: 100%; cursor: pointer;"
                          v-if="image.bataskiri !== null"
                          @click="
                            lihatgambar(
                              'Foto Batas Kiri',
                              image.bataskiri
                            )
                          "
                        />
                        <a-icon
                          type="file-image"
                          style="font-size: 18px; cursor: pointer;"
                      @click="selectFile('batasKiri')"
                          v-else
                        /></div
                    ></a-card>
                  </center>
                </div>
              </div>
            </div>
            <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4">
              <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-2">
                  <center>
                    <a-input
                      style="text-align: center;"
                      class="col-xs-10 col-sm-10 col-md-10 col-lg-10"
                      placeholder="Foto Tampak Dalam"
                      ref="tampakdalam"
                      @click="selectFile('tampakDalam')"
                    ></a-input>
                  </center>
                  <input
                    type="file"
                    style="display: none;"
                    ref="tampakDalam"
                    accept="image/*"
                    @change="(e) => previewImage(e, 'tampakdalam')"
                  />
                </div>
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-1">
                  <center>
                    <a-card style="width: 82%;">
                      <div>
                        <img
                          :src="image.tampakdalam"
                          alt="Foto Tampak Dalam"
                          style="width: 100%; cursor: pointer;"
                          v-if="image.tampakdalam !== null"
                          @click="
                            lihatgambar(
                              'Foto Tampak Dalam',
                              image.tampakdalam
                            )
                          "
                        />
                        <a-icon
                          type="file-image"
                          style="font-size: 18px; cursor: pointer;"
                      @click="selectFile('tampakDalam')"
                          v-else
                        /></div
                    ></a-card>
                  </center>
                </div>
              </div>
            </div>
            <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4">
              <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-2">
                  <center>
                    <a-input
                      style="text-align: center;"
                      class="col-xs-10 col-sm-10 col-md-10 col-lg-10"
                      placeholder="Foto Batas Kanan"
                      ref="bataskanan"
                      @click="selectFile('batasKanan')"
                    ></a-input>
                  </center>
                  <input
                    type="file"
                    style="display: none;"
                    ref="batasKanan"
                    accept="image/*"
                    @change="(e) => previewImage(e, 'bataskanan')"
                  />
                </div>
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-1">
                  <center>
                    <a-card style="width: 82%;">
                      <div>
                        <img
                          :src="image.bataskanan"
                          alt="Foto Batas Kanan"
                          style="width: 100%; cursor: pointer;"
                          v-if="image.bataskanan !== null"
                          @click="
                            lihatgambar(
                              'Foto Batas Kanan',
                              image.bataskanan
                            )
                          "
                        />
                        <a-icon
                          type="file-image"
                          style="font-size: 18px; cursor: pointer;"
                      @click="selectFile('batasKanan')"
                          v-else
                        /></div
                    ></a-card>
                  </center>
                </div>
              </div>
            </div>
            <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 mt-2">
              <center>
                <a-input
                  style="text-align: center;"
                  class="col-xs-8 col-sm-8 col-md-8 col-lg-8"
                  placeholder="Foto Pembanding 1"
                  ref="pembanding1"
                  @click="selectFile('pembanding1ref')"
                ></a-input>
              </center>
              <input
                type="file"
                style="display: none;"
                ref="pembanding1ref"
                accept="image/*"
                @change="(e) => previewImage(e, 'pembanding1')"
              />
            </div>
            <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 mt-2">
              <center>
                <a-input
                  style="text-align: center;"
                  class="col-xs-8 col-sm-8 col-md-8 col-lg-8"
                  placeholder="Foto Pembanding 2"
                  ref="pembanding2"
                  @click="selectFile('pembanding2ref')"
                ></a-input>
              </center>
              <input
                type="file"
                style="display: none;"
                ref="pembanding2ref"
                accept="image/*"
                @change="(e) => previewImage(e, 'pembanding2')"
              />
            </div>
            <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 mt-1">
              <center>
                <a-card style="width: 50%;">
                  <div>
                    <img
                      :src="image.pembanding1"
                      alt="Foto Pembanding 1"
                      style="width: 100%; cursor: pointer;"
                      v-if="image.pembanding1 !== null"
                      @click="lihatgambar('Foto Pembanding 1', image.pembanding1)"
                    /><a-icon
                      @click="selectFile('pembanding1ref')"
                      type="file-image"
                      style="font-size: 18px; cursor: pointer;"
                      v-else
                    /></div
                ></a-card>
              </center>
            </div>
            <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 mt-1">
              <center>
                <a-card style="width: 50%;">
                  <div>
                    <img
                      :src="image.pembanding2"
                      alt="Foto Pembanding 2"
                      style="width: 100%; cursor: pointer;"
                      v-if="image.pembanding2 !== null"
                      @click="lihatgambar('Foto Pembanding 2', image.pembanding2)"
                    /><a-icon
                      @click="selectFile('pembanding2ref')"
                      type="file-image"
                      style="font-size: 18px; cursor: pointer;"
                      v-else
                    /></div
                ></a-card>
              </center>
            </div>
            <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 mt-1">
              <center>
                <!-- <a-card> -->
                  <div class="card" style="width: 70%;">
                    <div class="card-body" style="padding: 10px;">
                  <a-textarea
                  style="text-align: center;"
                    v-model="input.keteranganpembanding1"
                    placeholder="Kolom Keterangan Pembanding 1"
                    :auto-size="{ minRows: 3, maxRows: 5 }"
                  />
                    </div>
                  </div>
                <!-- </a-card> -->
              </center>
            </div>
            <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 mt-1">
              <center>
                <!-- <a-card> -->
                  <div class="card" style="width: 70%;">
                    <div class="card-body" style="padding: 10px;">
                  <a-textarea
                  style="text-align: center;"
                    v-model="input.keteranganpembanding2"
                    placeholder="Kolom Keterangan Pembanding 2"
                    :auto-size="{ minRows: 3, maxRows: 5 }"
                  />
                    </div>
                  </div>
                <!-- </a-card> -->
              </center>
            </div>
          </div>
        </div>
      </div>
    </div>
    <modaldetail ref="detailmodal" />
    <zoomgambar ref="zoomgambar" />
  </div>
</template>

<script>
import NProgress from 'nprogress'
import * as lou from '@/services/data/lou'
import modaldetail from '@/components/lou/temptable'
import { Modal } from 'ant-design-vue'
import debounce from 'lodash/debounce'
import moment from 'moment'
import Cleave from 'vue-cleave-component'

import firebase from 'firebase/app'
import 'firebase/storage'

import zoomgambar from '@/components/lou/zoomgambar.vue'

import Docxtemplater from 'docxtemplater'
import ImageModule from 'docxtemplater-image-module-free'
import PizZip from 'pizzip'
import { saveAs } from 'file-saver'
import PizZipUtils from 'pizzip/utils/index.js'
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState(['settings', 'user']),
  },
  components: {
    modaldetail,
    cleave: Cleave,
    zoomgambar,
  },
  data() {
    this.lastFetchId = 0
    this.fetchUser = debounce(this.fetchUser, 800)
    return {
      image: {
        aksesjalan: null,
        tampakdepan: null,
        bataskanan: null,
        bataskiri: null,
        tampakdalam: null,
        pembanding1: null,
        pembanding2: null,
      },
      datanasabah: JSON.parse(localStorage.getItem('datanasabah')),
      editdata: JSON.parse(localStorage.getItem('editformshm')),
      pinginubah: false,
      options: {
        number: {
          numeral: true,
        },
        perkubik: {
          numeral: true,
          prefix: '/m²',
          tailPrefix: true,
        },
        kubik: {
          numeral: true,
          prefix: 'm²',
          tailPrefix: true,
        },
        meter: {
          numeral: true,
          prefix: 'm',
          tailPrefix: true,
        },
        tahun: {
          numeral: true,
          prefix: ' Tahun',
          tailPrefix: true,
        },
        persentase: {
          numeral: true,
          prefix: ' %',
          tailPrefix: true,
        },
      },
      input: {
        keteranganpembanding1: '',
        keteranganpembanding2: '',
        dokumen: [],
        datapemohon: {
          nomor_registrasi: {},
          tanggal_permohonan: moment(),
          jumlah_kredit: '',
          jangka_waktu: '',
          nama: '',
          pekerjaan: '',
          nomor_telepon: '',
        },
        metode_penilaian: {
          informasi_pasar: '',
          rata2_penilaian_tanah: '',
          taksiran_nilai_bangunan: '',
          usia_bangunan: '1',
          penyusutan_jaminan: '',
        },
        kesimpulan: {
          nilai_taksasi: '',
          persentase_fasilitas_kredit: '',
          maksimal_fasilitas_kredit: '',
        },
        kode_jaminan: {
          kode: '01',
          keterangan: 'SHM',
          nomor_taksasi: '',
        },
        data_tanah_bangunan: {
          jenis_surat_tanah: '',
          terdaftar_atas_nama: '',
          nama_pemilik: '',
          lokasi: '',
          ditempati_oleh: '',
          dasar_menempati: '',
        },
        kondisi_tanah_bangunan: {
          topografi: '',
          bentuk_tanah: '',
          batas_utara: '',
          batas_timur: '',
          batas_selatan: '',
          batas_barat: '',
          luas_tanah: '',
          luas_bangunan: '',
          peruntukan: '',
          kemungkinan_banjir: '',
          konstruksi: '',
          dinding: '',
          lantai: '',
          atap: '',
          langit_langit: '',
          kusen: '',
          pintu: '',
          jendela: '',
          pagar: '',
          listrik: '',
          air: '',
          telepon: '',
        },
        data_pendukung_lainnya: {
          sarana_jalan: '',
          lebar_jalan: '',
          fasilitas_umum: '',
          penentuan_value: '',
          keterangan: '',
        },
        analisa_yuridis: {
          analisa: '',
        },
        fortable: {
          jenis: '',
          value: '',
          keterangan: '',
        },
        fortable1: {
          jenis: '',
          value: '',
          keterangan: '',
        },
      },
      action: '',
      title: '',
      data: [],
      fetching: false,
      datatablefasilitasUmum: [],
      datatableinformasiPasarTanah: [],
      datenow: '',
      columnsratapenilaiantanah: [
        {
          title: 'Perincian',
          dataIndex: 'perincian',
          fixed: 'left',
          scopedSlots: {
            customRender: 'perincian',
          },
        },
        {
          title: 'Luas',
          dataIndex: 'luas',
          elipsis: true,
          scopedSlots: {
            customRender: 'luas',
          },
        },
        {
          title: 'Harga',
          dataIndex: 'harga',
          scopedSlots: {
            customRender: 'harga',
          },
        },
        {
          title: 'Penyusutan',
          dataIndex: 'penyusutan',
          scopedSlots: {
            customRender: 'penyusutan',
          },
        },
        {
          title: 'Nilai Taksasi',
          dataIndex: 'nilai_taksasi',
          scopedSlots: {
            customRender: 'nilai_taksasi',
          },
        },
        {
          title: 'Ket',
          dataIndex: 'ket',
          scopedSlots: {
            customRender: 'ket',
          },
        },
      ],
      datatableratapenilaiantanah: [
        {
          perincian: 'Tanah',
          luas: '',
          harga: '',
          penyusutan: '0',
          nilai_taksasi: '',
          ket: '',
        },
        {
          perincian: 'Bangunan',
          luas: '',
          harga: '',
          penyusutan: '',
          nilai_taksasi: '',
          ket: '',
        },
      ],
      total_nilai_taksasi: '',
      urlGambar: [],
      loading: false,
    }
  },
  async created() {
    this.datenow = lou.datenow()
    if (this.datanasabah !== null) {
      if (this.datanasabah.action === 'create') {
        this.action = 'create'
        this.title = 'Tambah Data'
        this.input.datapemohon.nomor_registrasi.key = this.datanasabah.kode
        this.input.datapemohon.tanggal_permohonan = this.datanasabah.tglregister
        this.input.datapemohon.jumlah_kredit = this.datanasabah.plafond
        this.input.datapemohon.jangka_waktu = this.datanasabah.lama
        this.input.datapemohon.nama = this.datanasabah.nama
        this.input.datapemohon.pekerjaan = this.datanasabah.pekerjaan
        this.input.datapemohon.nomor_telepon = this.datanasabah.TELEPON
        this.fetchUser(this.input.datapemohon.nomor_registrasi)
        this.handleChange(this.input.datapemohon.nomor_registrasi)
      } else {
        var target = this.datanasabah.target_jaminan
        this.action = 'update'
        this.title = 'Update Data'
        var response = await lou.customUrlGet2(
          'taksasishm/' + target.id_jaminan,
        )
        this.editdata = response.data
        this.setData()
        this.fetchUser(this.input.datapemohon.nomor_registrasi.key)
        this.handleChange(this.input.datapemohon.nomor_registrasi)
        setTimeout(() => {
          this.countMaksimalFasilitasKredit()
        }, 500)
      }
    } else {
      if (this.editdata.id !== '') {
        this.action = 'update'
        this.title = 'Update Data'
        this.setData()
        // console.log('this.input.datapemohon.nomor_registrasi', this.input.datapemohon.nomor_registrasi)
        this.fetchUser(this.input.datapemohon.nomor_registrasi.key)
        this.handleChange(this.input.datapemohon.nomor_registrasi)
        setTimeout(() => {
          this.countMaksimalFasilitasKredit()
        }, 500)
      } else {
        this.pinginubah = true
        this.action = 'create'
        this.title = 'Tambah Data'
      }
    }
  },
  watch: {
    datatableinformasiPasarTanah: {
      handler: function (val, oldVal) {
        this.countRataRata()
      },
      deep: true,
    },
  },
  methods: {
    moment,
    previewImage(event, ref) {
      var indexada = this.input.dokumen.find((x) => x.from === ref)
      // console.log('indexada', indexada)
      if (indexada === undefined) {
        this.input.dokumen.push({
          from: ref,
          image: event.target.files[0],
        })
      } else {
        indexada.image = event.target.files[0]
      }
      this.$refs[ref].value = event.target.files[0].name
      this.image[ref] = URL.createObjectURL(event.target.files[0])
      // console.log('this.input.dokumen', this.input.dokumen)
      // console.log('this.image', this.image)
    },
    lihatgambar(caption, link) {
      var data = {
        imageUrl: link,
        caption: caption,
      }
      this.$refs.zoomgambar.lihatgambar(data)
    },
    validasi() {
      if (
        this.input.datapemohon.nomor_registrasi.key === undefined ||
        this.input.datapemohon.nomor_registrasi.key === ''
      ) {
        lou.shownotif('Data nomor registrasi harus diisi!')
      } else {
        this.loading = true
        this.tryUpload()
      }
    },
    tryUpload() {
      this.urlGambar = []
      // console.log('this.input.uploadbpkb', this.input.uploadbpkb)
      if (this.input.dokumen.length === 0) {
        this.simpan()
      } else {
        var uuid = this.$uuid.v4().replace(/[^a-z\d\s]+/gi, '')
        if (this.action === 'update') {
          if (this.editdata.photo_aksesjalan !== null) {
            if (this.editdata.photo_aksesjalan.includes('firebasestorage')) {
              uuid = this.editdata.photo_aksesjalan.substring(this.editdata.photo_aksesjalan.indexOf('-') + 1, this.editdata.photo_aksesjalan.indexOf('.png') < 0 ? this.editdata.photo_aksesjalan.indexOf('.jpg') : this.editdata.photo_aksesjalan.indexOf('.png'))
            }
          }
        }
        console.log('this.input.dokumen', this.input.dokumen)
        this.input.dokumen.forEach(async (element) => {
          var type = element.type === 'image/jpeg' ? '.jpg' : '.png'
          await this.onUpload(
            '_' + this.input.datapemohon.nomor_registrasi.key + element.from + '-' + uuid + type,
            element.image,
            element,
          )
        })
      }
    },
    async simpan() {
      var fasilitasUmum = this.datatablefasilitasUmum.map((x) => {
        return {
          jenis: x.jenis,
          jarak: x.value,
          keterangan: x.keterangan,
        }
      })
      var informasiPasarTanah = this.datatableinformasiPasarTanah.map(
        ({ key, ...item }) => item,
      )
      // console.log('this.input.metode_penilaian.taksiran_nilai_bangunan', this.input.metode_penilaian.taksiran_nilai_bangunan.substring(
      //   0,
      //   this.input.metode_penilaian.taksiran_nilai_bangunan.indexOf('/'),
      // ))
      var fd = {
        kode_pemohon: this.input.datapemohon.nomor_registrasi.key,
        jenissurattanah: this.input.data_tanah_bangunan.jenis_surat_tanah,
        terdaftaratasnama: this.input.data_tanah_bangunan.terdaftar_atas_nama,
        namapemilik: this.input.data_tanah_bangunan.nama_pemilik,
        lokasi: this.input.data_tanah_bangunan.lokasi,
        ditempatioleh: this.input.data_tanah_bangunan.ditempati_oleh,
        dasarmenempati: this.input.data_tanah_bangunan.dasar_menempati,
        topografi: this.input.kondisi_tanah_bangunan.topografi,
        bentuktanah: this.input.kondisi_tanah_bangunan.bentuk_tanah,
        batasutara: this.input.kondisi_tanah_bangunan.batas_utara,
        batastimur: this.input.kondisi_tanah_bangunan.batas_timur,
        batasselatan: this.input.kondisi_tanah_bangunan.batas_selatan,
        batasbarat: this.input.kondisi_tanah_bangunan.batas_barat,
        luastanah: this.input.kondisi_tanah_bangunan.luas_tanah.substring(
          0,
          this.input.kondisi_tanah_bangunan.luas_tanah.indexOf('m'),
        ),
        luasbangunan: this.input.kondisi_tanah_bangunan.luas_bangunan.substring(
          0,
          this.input.kondisi_tanah_bangunan.luas_bangunan.indexOf('m'),
        ),
        peruntukan: this.input.kondisi_tanah_bangunan.peruntukan,
        kemungkinanbanjir: this.input.kondisi_tanah_bangunan.kemungkinan_banjir,
        konstruksi: this.input.kondisi_tanah_bangunan.konstruksi,
        nomortaksasi: this.input.kode_jaminan.nomor_taksasi,
        dinding: this.input.kondisi_tanah_bangunan.dinding,
        lantai: this.input.kondisi_tanah_bangunan.lantai,
        atap: this.input.kondisi_tanah_bangunan.atap,
        langitlangit: this.input.kondisi_tanah_bangunan.langit_langit,
        kusen: this.input.kondisi_tanah_bangunan.kusen,
        pintu: this.input.kondisi_tanah_bangunan.pintu,
        jendela: this.input.kondisi_tanah_bangunan.jendela,
        pagar: this.input.kondisi_tanah_bangunan.pagar,
        listrik: this.input.kondisi_tanah_bangunan.listrik,
        air: this.input.kondisi_tanah_bangunan.air,
        telepon: this.input.kondisi_tanah_bangunan.telepon,
        saranajalan: this.input.data_pendukung_lainnya.sarana_jalan,
        lebarjalan: this.input.data_pendukung_lainnya.lebar_jalan.substring(
          0,
          this.input.data_pendukung_lainnya.lebar_jalan.indexOf('m'),
        ),
        detail_taksasi_shm_fasilitas_umums: fasilitasUmum,
        penentuanvalue: this.input.data_pendukung_lainnya.penentuan_value,
        keterangan: this.input.data_pendukung_lainnya.keterangan,
        analisa: this.input.analisa_yuridis.analisa,
        detail_taksasi_shm_informasi_pasartanahs: informasiPasarTanah,
        taksirannilaibangunan: this.input.metode_penilaian.taksiran_nilai_bangunan.substring(
          0,
          this.input.metode_penilaian.taksiran_nilai_bangunan.indexOf('/'),
        ),
        penyusutannilaijaminan: this.input.metode_penilaian.penyusutan_jaminan.substring(
          0,
          this.input.metode_penilaian.penyusutan_jaminan.indexOf('/'),
        ),
        usiabangunan: this.input.metode_penilaian.usia_bangunan.substring(
          0,
          this.input.metode_penilaian.usia_bangunan.indexOf(' '),
        ),
        tablepenilaianagunan: this.datatableratapenilaiantanah,
        totalnilaitaksasi: this.total_nilai_taksasi,
        nilaitaksasikesimpulan: this.input.kesimpulan.nilai_taksasi,
        presentasefasilitaskredit: this.input.kesimpulan.persentase_fasilitas_kredit.substring(
          0,
          this.input.kesimpulan.persentase_fasilitas_kredit.indexOf(' '),
        ),
        maksimalfasilitaskredit: this.input.kesimpulan
          .maksimal_fasilitas_kredit,
        penyusutanbangunan: this.datatableratapenilaiantanah[1].penyusutan,
        nilaitaksasibangunan: this.datatableratapenilaiantanah[1].nilai_taksasi,
        photo_aksesjalan: this.image.aksesjalan,
        photo_tampakdepan: this.image.tampakdepan,
        photo_bataskanan: this.image.bataskanan,
        photo_bataskiri: this.image.bataskiri,
        photo_tampakdalam: this.image.tampakdalam,
        photo_pembanding1: this.image.pembanding1,
        photo_pembanding2: this.image.pembanding2,
        keterangan_pembanding1: this.input.keteranganpembanding1,
        keterangan_pembanding2: this.input.keteranganpembanding2,
      }
      // console.log('urlGambar', this.urlGambar)
      // console.log('fd.detail_taksasi_shm_informasi_pasartanahs', fd.detail_taksasi_shm_informasi_pasartanahs)
      var errordatapenilaian = []
      fd.detail_taksasi_shm_informasi_pasartanahs.forEach((element, i) => {
        // console.log('element', element)
        // console.log('i', i)
        if (typeof element.value === 'string') {
          if (element.value.includes('.') || element.value.includes(',')) {
            errordatapenilaian.push(
              {
                baris: i + 1,
                value: element.value,
              },
            )
          }
        }
      })
      if (errordatapenilaian.length === 0) {
        if (this.urlGambar.length !== 0) {
          this.urlGambar.forEach((element) => {
            fd['photo_' + element.key] = element.link
          })
        }
        var res
        if (this.action === 'create') {
          res = await lou.customUrlPost2('taksasishm', fd)
        } else {
          fd.id = this.editdata.id
          res = await lou.customUrlPut2('taksasishm', fd)
        }
        if (res) {
          this.loading = false
          Modal.confirm({
            title: 'Print?',
            content: 'Apakah anda ingin cetak taksasi?',
            okText: 'Yes',
            okType: 'success',
            cancelText: 'No',
            onOk: async () => {
            // await lou.getDocument('taksasishm', res.data.id)
            // this.$router.push('/taksasijaminan/shm')
              this.generateDocx(res.data)
              this.$router.go(-1)
            },
            onCancel: () => {
            // this.$router.push('/taksasijaminan/shm')
              this.$router.go(-1)
            // console.log('Cancel')
            },
          })
        } else {
          this.loading = false
          lou.shownotif('error', 'Something wrong!')
        }
      } else {
        this.loading = false
        errordatapenilaian.forEach(element => {
          lou.shownotif('error', 'Data pada baris ' + element.baris + ' dengan nilai ' + element.value + ', Harap diperbaiki!', 'error')
        })
      }
    },
    async onUpload(name, imagedata, element) {
      this.uploadvalue = 0
      // var returnnya = ''
      const storageRef = firebase
        .storage()
        .ref('taksasi/shm/' + name)
        .put(imagedata)
      storageRef.on(
        'state_changed',
        (snapshot) => {
          this.uploadvalue =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        },
        (error) => {
          // console.log('error.message', error.message)
        },
        () => {
          this.uploadvalue = 100
          // console.log('url', url)
          storageRef.snapshot.ref.getDownloadURL().then((url) => {
            this.urlGambar.push({ key: element.from, link: url })
            if (this.urlGambar.length === this.input.dokumen.length) {
              this.simpan()
            }
            return url
          })
        },
      )
      // returnnya = await storageRef.snapshot.ref.getDownloadURL()
      // console.log('returnnya', returnnya)
      // return returnnya
    },
    setData() {
      console.log('this.editdata', this.editdata)
      this.input.datapemohon.nomor_registrasi.key = this.editdata.kode_pemohon
      this.input.datapemohon.tanggal_permohonan = this.editdata.pemohon.tglregister
      this.input.datapemohon.jumlah_kredit = this.editdata.pemohon.plafond
      this.input.datapemohon.jangka_waktu = this.editdata.pemohon.lama
      this.input.datapemohon.nama = this.editdata.pemohon.nama
      this.input.datapemohon.pekerjaan = this.editdata.pemohon.pekerjaan
      this.input.datapemohon.nomor_telepon = this.editdata.pemohon.TELEPON
      this.input.kode_jaminan.nomor_taksasi = this.editdata.nomortaksasi
      this.input.data_tanah_bangunan.jenis_surat_tanah = this.editdata.jenissurattanah
      this.input.data_tanah_bangunan.terdaftar_atas_nama = this.editdata.terdaftaratasnama
      this.input.data_tanah_bangunan.nama_pemilik = this.editdata.namapemilik
      this.input.data_tanah_bangunan.lokasi = this.editdata.lokasi
      this.input.data_tanah_bangunan.ditempati_oleh = this.editdata.ditempatioleh
      this.input.data_tanah_bangunan.dasar_menempati = this.editdata.dasarmenempati
      this.input.kondisi_tanah_bangunan.topografi = this.editdata.topografi
      this.input.kondisi_tanah_bangunan.bentuk_tanah = this.editdata.bentuktanah
      this.input.kondisi_tanah_bangunan.batas_utara = this.editdata.batasutara
      this.input.kondisi_tanah_bangunan.batas_timur = this.editdata.batastimur
      this.input.kondisi_tanah_bangunan.batas_selatan = this.editdata.batasselatan
      this.input.kondisi_tanah_bangunan.batas_barat = this.editdata.batasbarat
      this.input.kondisi_tanah_bangunan.luas_tanah = this.editdata.luastanah
      this.input.kondisi_tanah_bangunan.luas_bangunan = this.editdata.luasbangunan
      this.input.kondisi_tanah_bangunan.peruntukan = this.editdata.peruntukan
      this.input.kondisi_tanah_bangunan.kemungkinan_banjir = this.editdata.kemungkinanbanjir
      this.input.kondisi_tanah_bangunan.konstruksi = this.editdata.konstruksi
      this.input.kondisi_tanah_bangunan.dinding = this.editdata.dinding
      this.input.kondisi_tanah_bangunan.lantai = this.editdata.lantai
      this.input.kondisi_tanah_bangunan.atap = this.editdata.atap
      this.input.kondisi_tanah_bangunan.langit_langit = this.editdata.langitlangit
      this.input.kondisi_tanah_bangunan.kusen = this.editdata.kusen
      this.input.kondisi_tanah_bangunan.pintu = this.editdata.pintu
      this.input.kondisi_tanah_bangunan.jendela = this.editdata.jendela
      this.input.kondisi_tanah_bangunan.pagar = this.editdata.pagar
      this.input.kondisi_tanah_bangunan.listrik = this.editdata.listrik
      this.input.kondisi_tanah_bangunan.air = this.editdata.air
      this.input.kondisi_tanah_bangunan.telepon = this.editdata.telepon
      this.input.data_pendukung_lainnya.sarana_jalan = this.editdata.saranajalan
      this.input.data_pendukung_lainnya.lebar_jalan = this.editdata.lebarjalan
      this.input.data_pendukung_lainnya.penentuan_value = this.editdata.penentuanvalue
      this.input.data_pendukung_lainnya.keterangan = this.editdata.keterangan
      this.input.analisa_yuridis.analisa = this.editdata.analisa
      this.input.metode_penilaian.taksiran_nilai_bangunan = this.editdata.taksirannilaibangunan
      this.input.metode_penilaian.penyusutan_jaminan = this.editdata.penyusutannilaijaminan
      this.input.metode_penilaian.usia_bangunan = this.editdata.usiabangunan
      this.input.kesimpulan.nilai_taksasi = this.editdata.nilaitaksasikesimpulan
      this.input.kesimpulan.persentase_fasilitas_kredit = this.editdata.presentasefasilitaskredit
      this.input.kesimpulan.maksimal_fasilitas_kredit = this.editdata.maksimalfasilitaskredit
      this.datatableinformasiPasarTanah = this.editdata.detail_taksasi_shm_informasi_pasartanahs
      this.image.aksesjalan = this.editdata.photo_aksesjalan === '' ? null : this.editdata.photo_aksesjalan
      this.image.tampakdepan = this.editdata.photo_tampakdepan === '' ? null : this.editdata.photo_tampakdepan
      this.image.bataskanan = this.editdata.photo_bataskanan === '' ? null : this.editdata.photo_bataskanan
      this.image.bataskiri = this.editdata.photo_bataskiri === '' ? null : this.editdata.photo_bataskiri
      this.image.tampakdalam = this.editdata.photo_tampakdalam === '' ? null : this.editdata.photo_tampakdalam
      this.image.pembanding1 = this.editdata.photo_pembanding1 === '' ? null : this.editdata.photo_pembanding1
      this.image.pembanding2 = this.editdata.photo_pembanding2 === '' ? null : this.editdata.photo_pembanding2
      this.input.keteranganpembanding1 = this.editdata.keterangan_pembanding1
      this.input.keteranganpembanding2 = this.editdata.keterangan_pembanding2
      // console.log('this.datatableinformasiPasarTanah', this.datatableinformasiPasarTanah)
      // console.log('this.editdata.detail_taksasi_shm_informasi_pasartanahs', this.editdata.detail_taksasi_shm_informasi_pasartanahs)
      this.datatableinformasiPasarTanah = this.editdata.detail_taksasi_shm_informasi_pasartanahs.map(
        (x) => {
          return {
            key: this.$uuid.v4(),
            jenis: x.jenis,
            value: x.value,
            // value: parseInt(x.value.replaceAll(',', '')),
            keterangan: x.keterangan,
          }
        },
      )
      this.datatablefasilitasUmum = this.editdata.detail_taksasi_shm_fasilitas_umums.map(
        (x) => {
          return {
            key: this.$uuid.v4(),
            jenis: x.jenis,
            value: x.jarak,
            keterangan: x.keterangan,
          }
        },
      )
      // console.log('this.datatablefasilitasUmum', this.datatablefasilitasUmum)
      // console.log('this.editdata.detail_taksasi_shm_fasilitas_umums', this.editdata.detail_taksasi_shm_fasilitas_umums)
      // console.log('this.input', this.input)
    },
    tambahfasilitasUmum() {
      if (
        this.input.fortable1.jenis !== '' &&
        this.input.fortable1.value !== ''
      ) {
        var uniq = 'id' + new Date().getTime()
        this.datatablefasilitasUmum.push({
          key: uniq,
          jenis: 'Fasilitas Umum',
          value: this.input.fortable1.value,
          keterangan: this.input.fortable1.jenis,
        })
        this.input.fortable1.jenis = ''
        this.input.fortable1.value = ''
        this.input.fortable1.keterangan = '-'
        this.showModal('Fasilitas umum', 'datatablefasilitasUmum')
      } else {
        this.showModal('Fasilitas umum', 'datatablefasilitasUmum')
      }
    },
    tambahinformasiPasarTanah() {
      if (
        this.input.fortable.jenis !== '' &&
        this.input.fortable.value !== ''
      ) {
        var uniq = 'id' + new Date().getTime()
        this.datatableinformasiPasarTanah.push({
          key: uniq,
          jenis: 'Informasi Pasar/Tanah',
          value: this.input.fortable.value,
          keterangan: this.input.fortable.jenis,
        })
        this.input.fortable.jenis = ''
        this.input.fortable.value = ''
        this.input.fortable.keterangan = '-'
        this.showModal('Infromasi pasar/tanah', 'datatableinformasiPasarTanah')
      } else {
        this.showModal('Infromasi pasar/tanah', 'datatableinformasiPasarTanah')
      }
    },
    showModal(title, datatable) {
      var column = [
        {
          title: 'Jenis',
          className: 'Jenis',
          dataIndex: 'jenis',
          scopedSlots: {
            customRender: 'jenis',
          },
        },
        {
          title: 'Keterangan',
          className: 'Keterangan',
          dataIndex: 'keterangan',
          scopedSlots: {
            customRender: 'keterangan',
          },
        },
        {
          title: 'Harga',
          className: 'Harga',
          dataIndex: 'value',
          scopedSlots: {
            customRender: 'value',
          },
        },
      ]
      if (title === 'Fasilitas umum') {
        column[2].title = 'Jarak'
        column[2].className = 'Jarak'
        // console.log('column', column)
      }
      this.$refs.detailmodal.showModal(
        title,
        column,
        this[datatable],
        datatable,
      )
    },
    setTable(from) {
      if (from === 'luas_tanah') {
        this.datatableratapenilaiantanah[0].luas = this.input.kondisi_tanah_bangunan.luas_tanah.substring(
          0,
          this.input.kondisi_tanah_bangunan.luas_tanah.indexOf('m'),
        )
      } else {
        this.datatableratapenilaiantanah[1].luas = this.input.kondisi_tanah_bangunan.luas_bangunan.substring(
          0,
          this.input.kondisi_tanah_bangunan.luas_bangunan.indexOf('m'),
        )
      }
    },
    async fetchUser(value) {
      // console.log('fetchinguser', value)
      if (value !== '') {
        this.lastFetchId += 1
        this.data = []
        this.fetching = true
        var response = await lou.customUrlGet2(
          'pengajuankredit?limit=10&kode=' + value,
        )
        if (response) {
          if (response.data.length !== 0) {
            this.data = response.data
          } else {
            this.data = []
            lou.shownotif('Not Found!', 'Data not found!')
            this.fetching = false
          }
        }
      } else {
        console.log('Empty: -')
      }
    },
    countRataRata() {
      if (this.datatableinformasiPasarTanah.length !== 0) {
        var grandTotal = 0
        var totalSumValue = 0
        this.datatableinformasiPasarTanah.forEach((element) => {
          totalSumValue += parseInt(element.value)
        })
        grandTotal = totalSumValue / this.datatableinformasiPasarTanah.length
        this.input.metode_penilaian.rata2_penilaian_tanah = grandTotal
      } else {
        this.input.metode_penilaian.rata2_penilaian_tanah = 0
      }
    },
    countRataRataPenilaianTanah() {
      this.datatableratapenilaiantanah[0].harga = this.input.metode_penilaian.rata2_penilaian_tanah.substring(
        0,
        this.input.metode_penilaian.rata2_penilaian_tanah.indexOf('/'),
      )
      this.countNilaiTaksasi(this.datatableratapenilaiantanah[0])
    },
    countPenyusutanJaminan() {
      this.datatableratapenilaiantanah[1].harga = this.input.metode_penilaian.taksiran_nilai_bangunan.substring(
        0,
        this.input.metode_penilaian.taksiran_nilai_bangunan.indexOf('/'),
      )
      var total =
        parseInt(this.input.metode_penilaian.taksiran_nilai_bangunan) /
        parseInt(this.input.metode_penilaian.usia_bangunan)
      var jangka =
        (parseInt(total) * parseInt(this.input.datapemohon.jangka_waktu)) / 12
      var theRealTotal =
        parseInt(this.input.metode_penilaian.taksiran_nilai_bangunan) - jangka
      this.input.metode_penilaian.penyusutan_jaminan = theRealTotal
      this.datatableratapenilaiantanah[1].penyusutan = isNaN(
        parseInt(this.input.metode_penilaian.taksiran_nilai_bangunan) -
          theRealTotal,
      )
        ? '0'
        : parseInt(this.input.metode_penilaian.taksiran_nilai_bangunan) -
          theRealTotal
      this.countNilaiTaksasi(this.datatableratapenilaiantanah[1])
    },
    countNilaiTaksasi(record) {
      var totalharga = parseInt(record.harga) * parseInt(record.luas)
      var totalpenyusutan = parseInt(record.penyusutan) * parseInt(record.luas)
      var total = parseInt(totalharga) - parseInt(totalpenyusutan)
      record.nilai_taksasi = total
      this.countTotalNilaiTaksasi()
      return isNaN(record.nilai_taksasi)
        ? '0'
        : this.numberFormat(record.nilai_taksasi)
    },
    countTotalNilaiTaksasi() {
      var ntotal =
        parseInt(this.datatableratapenilaiantanah[0].nilai_taksasi) +
        parseInt(this.datatableratapenilaiantanah[1].nilai_taksasi)
      this.total_nilai_taksasi = isNaN(ntotal) ? '0' : ntotal
      this.input.kesimpulan.nilai_taksasi = this.total_nilai_taksasi
      // this.countMaksimalFasilitasKredit()
    },
    countMaksimalFasilitasKredit() {
      var presentase =
        parseInt(this.input.kesimpulan.persentase_fasilitas_kredit) / 100
      // console.log('this.input.kesimpulan.persentase_fasilitas_kredit', this.input.kesimpulan.persentase_fasilitas_kredit)
      var total = parseInt(this.input.kesimpulan.nilai_taksasi) * presentase
      // console.log('this.input.kesimpulan.nilai_taksasi', this.input.kesimpulan.nilai_taksasi)
      // console.log('totalf', total)
      var parsedIntGrandTotal = parseFloat(total).toFixed(0)
      this.input.kesimpulan.maksimal_fasilitas_kredit = parsedIntGrandTotal
    },
    handleChange(value) {
      var backup = this.$g.clone(this.data)
      var target = backup.find((x) => x.kode === value.key)
      if (target) {
        this.input.datapemohon.nama = target.nama
        this.input.datapemohon.tanggal_permohonan = moment(
          target.tglregister,
          'YYYY-MM-DD',
        )
        this.input.datapemohon.pekerjaan = target.pekerjaan
        this.input.datapemohon.nomor_telepon = target.TELEPON
        this.input.datapemohon.jumlah_kredit = target.plafond
        this.input.datapemohon.jangka_waktu = target.lama
      }
      // console.log('target', target)
      Object.assign(this, {
        value,
        data: [],
        fetching: false,
      })
    },
    loadFile(url, callback) {
      PizZipUtils.getBinaryContent(url, callback)
    },
    async generateDocx(record) {
      // lou.shownotif('Word Generator!', 'Generating Word File!', 'info')
      NProgress.start()
      // var storageRef = ''
      // await firebase
      //   .storage()
      //   .ref(
      //     'taksasi/template/' + this.user.sandibpr + 'taksasishmtemplate.docx',
      //   )
      //   .getDownloadURL()
      //   .then((url) => {
      //     // `url` is the download URL
      //     console.log(url)
      //     storageRef = url
      //     // eslint-disable-next-line handle-callback-err
      //   }).catch(async (error) => {
      //     // Handle any errors
      //     var defaultTemplateLink = await firebase
      //       .storage()
      //       .ref('taksasi/template/taksasishmtemplate.docx')
      //       .getDownloadURL()
      //     storageRef = defaultTemplateLink
      //     // switch (error.code) {
      //     //   case 'storage/object-not-found':
      //     //     // File doesn't exist
      //     //     lou.shownotif("File doesn't exist")
      //     //     break

      //     //   case 'storage/unauthorized':
      //     //     // User doesn't have permission to access the object
      //     //     lou.shownotif("User doesn't have permission to access the object")
      //     //     break

      //     //   case 'storage/canceled':
      //     //     // User canceled the upload
      //     //     lou.shownotif('User canceled the upload')
      //     //     break

      //     //   case 'storage/unknown':
      //     //     // Unknown error occurred, inspect the server response
      //     //     lou.shownotif('Unknown error occurred, inspect the server response')
      //     //     break
      //     //   default:
      //     //     break
      //     // }
      //   })
      // console.log('storageRef', storageRef)
      var dataSettings = []
      var res = await lou.customUrlGet('jaminan/settings', true, true)
      if (res) {
        dataSettings = res.data
      }
      var that = this
      this.loadFile('https://apilbbprs.heasoft.com/word/600767TaksasiSHM_Template.docx', function (error, content) {
        var dataset = {
          ...record,
          datasettings: dataSettings,
          imgAksesJalan:
                record.photo_aksesjalan === '' || record.photo_aksesjalan === null ? 'https://firebasestorage.googleapis.com/v0/b/jaminan.appspot.com/o/taksasi%2Ftemplate%2Fblank1123124123.png?alt=media&token=20d7e917-0fa0-4ecc-980b-0bed4a306b1c' : record.photo_aksesjalan,
          imgTampakDepan:
                record.photo_tampakdepan === '' || record.photo_tampakdepan === null ? 'https://firebasestorage.googleapis.com/v0/b/jaminan.appspot.com/o/taksasi%2Ftemplate%2Fblank1123124123.png?alt=media&token=20d7e917-0fa0-4ecc-980b-0bed4a306b1c' : record.photo_tampakdepan,
          imgBatasKanan:
                record.photo_bataskanan === '' || record.photo_bataskanan === null ? 'https://firebasestorage.googleapis.com/v0/b/jaminan.appspot.com/o/taksasi%2Ftemplate%2Fblank1123124123.png?alt=media&token=20d7e917-0fa0-4ecc-980b-0bed4a306b1c' : record.photo_bataskanan,
          imgBatasKiri:
                record.photo_bataskiri === '' || record.photo_bataskiri === null ? 'https://firebasestorage.googleapis.com/v0/b/jaminan.appspot.com/o/taksasi%2Ftemplate%2Fblank1123124123.png?alt=media&token=20d7e917-0fa0-4ecc-980b-0bed4a306b1c' : record.photo_bataskiri,
          imgTampakDalam:
                record.photo_tampakdalam === '' || record.photo_tampakdalam === null ? 'https://firebasestorage.googleapis.com/v0/b/jaminan.appspot.com/o/taksasi%2Ftemplate%2Fblank1123124123.png?alt=media&token=20d7e917-0fa0-4ecc-980b-0bed4a306b1c' : record.photo_tampakdalam,
          imgPembanding1:
                record.photo_pembanding1 === '' || record.photo_pembanding1 === null ? 'https://firebasestorage.googleapis.com/v0/b/jaminan.appspot.com/o/taksasi%2Ftemplate%2Fblank1123124123.png?alt=media&token=20d7e917-0fa0-4ecc-980b-0bed4a306b1c' : record.photo_pembanding1,
          imgPembanding2:
                record.photo_pembanding2 === '' || record.photo_pembanding2 === null ? 'https://firebasestorage.googleapis.com/v0/b/jaminan.appspot.com/o/taksasi%2Ftemplate%2Fblank1123124123.png?alt=media&token=20d7e917-0fa0-4ecc-980b-0bed4a306b1c' : record.photo_pembanding2,
        }
        dataset.tglSekarang = moment().locale('id').format('DD MMMM YYYY')
        const data = record.pemohon
        dataset.namaUserLogin = that.user.name
        dataset.noregistrasi = data.kode
        dataset.namapemohon = data.nama
        dataset.alamatpemohon = data.alamat
        dataset.pekerjaanpemohon = data.pekerjaan
        dataset.namaperusahaan = data.alamatkantor
        dataset.plafond = data.plafond
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        dataset.nomortelepon = data.telepon
        dataset.jangkawaktu = data.lama
        dataset.jumlahnilai = 0
        dataset.keteranganpembanding1 = record.keterangan_pembanding1
        dataset.keteranganpembanding2 = record.keterangan_pembanding2
        const dataDetailFasilitas = record.detail_taksasi_shm_fasilitas_umums

        const dataDetailPasarTanah =
          record.detail_taksasi_shm_informasi_pasartanahs

        // let isijmlnilai = 0
        dataset.fasilitasumum = []
        dataset.informasipasartanah = []
        dataDetailFasilitas.forEach((isiNilai) => {
          if (isiNilai.jenis === 'Fasilitas Umum') { dataset.fasilitasumum.push(isiNilai) }
        })
        var jumlahNilai = 0
        dataDetailPasarTanah.forEach((isiNilai) => {
          // if (isiNilai.jenis === 'Informasi Pasar/Tanah') {
          jumlahNilai += isiNilai.value
          // isijmlnilai++
          isiNilai.value = isiNilai.value
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
          dataset.informasipasartanah.push(isiNilai)
          // }
        })

        dataset.jumlahnilai = jumlahNilai / dataDetailPasarTanah.length

        var Hasilpenyusutannilai =
          parseInt(record.taksirannilaibangunan) /
          parseInt(record.usiabangunan)

        const val1 =
          parseInt(Hasilpenyusutannilai) * parseInt(dataset.jangkawaktu)
        const val2 = val1 / 12
        var Hasilpenyusutanjaminan =
          parseInt(record.taksirannilaibangunan) - val2

        var Nilaitaksasitanah =
          parseInt(record.luastanah) * parseInt(dataset.jumlahnilai)

        dataset.jumlahnilai = lou.curency(dataset.jumlahnilai) // curency
        dataset.penyusutanNilaiJaminan = lou.curency(
          record.taksirannilaibangunan,
        ) // curency
        dataset.hasilpenyusutannilai = lou.curency(Hasilpenyusutannilai) // curency
        dataset.taksirannilaibangunan = lou.curency(
          record.taksirannilaibangunan,
        ) // curency
        dataset.hasilpenyusutanjaminan = lou.curency(Hasilpenyusutanjaminan) // curency
        dataset.penyusutanbangunan = lou.curency(record.penyusutanbangunan) // curency
        dataset.penyusutannilaijaminan = lou.curency(
          record.penyusutannilaijaminan,
        ) // curency
        dataset.nilaitaksasitanah = lou.curency(Nilaitaksasitanah) // curency
        dataset.nilaitaksasibangunan = lou.curency(record.nilaitaksasibangunan) // curency
        dataset.totalnilaitaksasi = lou.curency(record.totalnilaitaksasi) // curency
        dataset.maksimalfasilitaskredit = lou.curency(
          record.maksimalfasilitaskredit,
        ) // curency

        // Settings Mengubah Disini
        var itarget = dataset.datasettings.findIndex(x => x.kode === 'namaKepalaBagian')
        dataset.NamaKepalaBagian = dataset.datasettings[itarget].keterangan

        // console.log('dataset', dataset)
        var opts = {}
        opts.centered = false
        opts.getImage = function (tagValue, tagName) {
          // console.log('tagValue', tagValue)
          return new Promise(function (resolve, reject) {
            PizZipUtils.getBinaryContent(tagValue, function (error, content) {
              // console.log('content', content)
              if (error) {
                // console.log('error', error)
                return reject(error)
              }
              return resolve(content)
            })
          })
        }
        opts.getSize = function (img, tagValue, tagName) {
          // return new Promise(function (
          //   resolve,
          //   reject,
          // ) {
          //   const image = new Image()
          //   image.src = tagValue
          //   image.onload = function () {
          //     // console.log('image.width', image.width)
          //     // console.log('image.height', image.height)
          //     resolve([
          //       image.width * (85 / 100),
          //       image.height,
          //     ])
          //   }
          //   image.onerror = function (e) {
          //     console.log(
          //       'img, tagValue, tagName : ',
          //       img,
          //       tagValue,
          //       tagName,
          //     )
          //     alert(
          //       'An error occured while loading ' +
          //                               tagValue,
          //     )
          //     reject(e)
          //   }
          // })
          // FOR FIXED SIZE IMAGE :
          return [345.6, 460.8]
        }
        // eslint-disable-next-line no-unused-vars
        var imageModule = new ImageModule(opts)
        if (error) {
          throw error
        }
        const zip = new PizZip(content)
        const doc = new Docxtemplater()
          .loadZip(zip)
          .attachModule(imageModule)
          .compile()

        // render the document (replace all occurences of {first_name} by John, {last_name} by Doe, ...)
        doc.renderAsync(dataset).then(() => {
          // console.log('ready')
          // doc.render()
          const out = doc.getZip().generate({
            type: 'blob',
            mimeType:
              'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
          })
          lou.shownotif(
            'Generator success!',
            'Generate word success without problem!',
            'success',
          )
          NProgress.done()
          // Output the document using Data-URI
          saveAs(
            out,
            'Taksasi SHM_ ' +
              record.pemohon.nama +
              '_' +
              record.nomortaksasi +
              '.docx',
          ).then(() => {
            // this.$router.go(-1)
          })
        })
      })
    },
    numberFormat(value) {
      return lou.curency(value)
    },
    selectFile(ref) {
      this.$refs[ref].click()
    },
    back() {
      this.$router.go(-1)
    },
  },
}
</script>

<style>
.ant-table-row {
  line-height: 1 !important;
}
</style>
