<template>
  <div>
    <a-card>
      <template slot="title">
        <strong>Penerimaan Bahan Baku</strong>
        <a-button-group class="ml-2">
          <!-- <a-button class="btn btn-outline-primary" @click="handleSubmit" :disabled="rowData.length === 0"
        >Simpan</a-button
      >
      <a-button v-if="action === 'update'" class="btn btn-outline-danger" @click="resetForm(true)"
        >Cancel Update</a-button
      > -->
          <a-button class="btn btn-outline-danger" @click="back">Back</a-button>
          <a-button class="btn btn-outline-success" @click="handleSubmit('create')">
            <a-icon :type="selectedData.length === 0 ? 'save' : 'check'"></a-icon> {{ selectedData.length === 0 ? 'Save' : 'Save Changes' }}
          </a-button>
          <a-button class="btn btn-outline-danger" v-if="selectedData.length !== 0" @click="resetForm">
            <a-icon type="cross"></a-icon> Cancel Update
          </a-button>
        </a-button-group>
      </template>
      <div class="row pl-1 pr-1 pt-1 pb-1">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
          <div class="row">
            <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2 mt-1">
              <label class="">Tgl Terima</label>
            </div>
            <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3">
              <a-date-picker
              class="pull-right"
                style="width: 125px;"
                v-model="input.tgl_terima"
                :allowClear="false"
                format="DD-MM-YYYY"
              />
            </div>
            <div class="col-xs-7 col-sm-7 col-md-7 col-lg-7">
              <div class="row">
                <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2 mt-1">
                  <label class="mr-2">Tgl.Order</label>
                </div>
                <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4">
                  <a-input
                    style="width: 100%"
                    placeholder="Tanggal Order"
                    :readOnly="true"
                    v-model="maskInput.tgl_order"
                  ></a-input>
                </div>
                <!-- <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2 mt-1">
                  <label class="mr-2">Tgl.Terima</label>
                </div>
                <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4">
                  <a-input
                    style="width: 100%"
                    placeholder="Tanggal Terima"
                    :readOnly="true"
                    v-model="maskInput.tgl_terima"
                  ></a-input>
                </div> -->
              </div>
            </div>
            <div class="col-xs-1 col-sm-1 col-md-1 col-lg-1 mt-2">
              <label class="">Catatan</label>
            </div>
            <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 mt-1">
                <a-input
                style="width: 100%"
                v-model="input.catatan"
                placeholder="Catatan"
                :readOnly="false"
                @keydown.native.enter="handleSubmit('create')"
                ></a-input>
            </div>
            <div class="col-xs-7 col-sm-7 col-md-7 col-lg-7 mt-1">
              <div class="row">
                <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2 mt-1">
                  <label class="mr-2">No Order</label>
                </div>
                <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4">
                  <a-input
                    style="width: 100%"
                    placeholder="No Order"
                    :readOnly="true"
                    v-model="maskInput.order_no"
                  ></a-input>
                </div>
                <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2 mt-1">
                  <label class="mr-2">Supplier</label>
                </div>
                <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4">
                  <a-input
                    style="width: 100%"
                    placeholder="Nama Supplier"
                    :readOnly="true"
                    v-model="maskInput.nama_supplier"
                  ></a-input>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2">
        <table class="mt-3">
          <tr>
            <th>Action</th>
          </tr>
          <tr v-for="(d, i) in rowData" :key="i">
            <td>
        <a-button-group>
              <a-button href="javascript:void(0)" @click="selectProduk" class="btn btn-outline-warning">
                <a-icon class="" type="minus"></a-icon>1
              </a-button>
              <a-button href="javascript:void(0)" @click="remove()" class="btn btn-outline-danger">
                <a-icon class="" type="delete"></a-icon> {{ d.kode }}
              </a-button>
              <a-button href="javascript:void(0)" @click="selectProduk" class="btn btn-outline-success">
                <a-icon class="" type="plus"></a-icon>1
              </a-button>
            </a-button-group>
            </td>
            <td>
            </td>
          </tr>
        </table>
      </div> -->
        <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8 mt-2">
          <ag-grid-vue
            style="height: 31vh; width: 100%"
            :class="
              settings.theme === 'dark'
                ? 'ag-theme-balham-dark mt-2'
                : 'ag-theme-balham mt-2'
            "
            :rowClassRules="rowClassRules"
            :key="componentKey"
            :gridOptions="gridOptions"
            :columnDefs="columnDefs1"
            :pagination="false"
            :accentedSort="false"
            :sortingOrder="['asc', 'desc']"
            :rowData="rowData"
            @grid-ready="onGridReady"
            :singleClickEdit="false"
            :undoRedoCellEditing="true"
            :undoRedoCellEditingLimit="5"
            rowSelection="single"
            :frameworkComponents="frameworkComponents"
            @first-data-rendered="onFirstDataRendered"
            ></ag-grid-vue>
            <!-- @selection-changed="onSelectionChanged"
            @cell-value-changed="onCellValueChanged" -->
          <!-- <strong class="mt-2 mr-1 pull-right">Sub Total:  {{ formatCurrency(therealsubtotal ?? 0) }}</strong> -->
        </div>
        <!-- <div class="col-xs-7 col-sm-7 col-md-7 col-lg-7">
          <ag-grid-vue
            style="height: 31vh; width: 100%"
            :class="
              settings.theme === 'dark'
                ? 'ag-theme-balham-dark mt-2'
                : 'ag-theme-balham mt-2'
            "
            :rowClassRules="rowClassRules"
            :key="componentKey"
            :gridOptions="gridOptions1"
            :columnDefs="columnDefs1"
            :pagination="false"
            :accentedSort="false"
            :sortingOrder="['asc', 'desc']"
            :rowData="rowData1"
            @grid-ready="onGridReady1"
            :singleClickEdit="false"
            :undoRedoCellEditing="true"
            :undoRedoCellEditingLimit="5"
            rowSelection="single"
            :frameworkComponents="frameworkComponents"
            @first-data-rendered="onFirstDataRendered"
          ></ag-grid-vue>
          @selection-changed="onSelectionChanged"
          @cell-value-changed="onCellValueChanged"
        </div> -->
      </div>
    </a-card>
    <!-- <a-button
              ref="addBtn"
              class="ml-2 btn btn-outline-primary"
              @click="addNullData"
            >
              Tambah
            </a-button> -->
  </div>
</template>

<script>
import moment from 'moment'
import * as lou from '@/services/data/lou'
import numericInput from './component/numeric'
import selectInput from './component/select'
import dateInput from './component/date'
import tooltip from './component/tooltip'
import htmlrender from './component/htmlrender'
import removeData from './component/removeData'
// import Cleave from 'vue-cleave-component'
import { Modal } from 'ant-design-vue'
// import vueCustomScrollbar from 'vue-custom-scrollbar'

import { mapState } from 'vuex'

export default {
  computed: mapState(['settings', 'user']),
  components: {
    // vueCustomScrollbar,
    // cleave: Cleave,
  },
  created() {
    // console.log('this.action', this.action)
    this.gridOptions = {}
    this.gridApi = this.gridOptions.api
    this.gridColumnApi = this.gridOptions.columnApi
    this.gridOptions1 = {}
    this.gridApi1 = this.gridOptions1.api
    this.gridColumnApi1 = this.gridOptions1.columnApi
    // console.log('this.editdata', this.editdata)
    if (
      this.editdata !== null &&
        this.editdata.order_no !== null &&
        this.editdata.order_no !== ''
    ) {
      this.action = 'update'
      this.title = 'Update Penerimaan Bahan Baku'
      this.getEditData()
    } else {
      this.action = 'create'
      this.title = 'Create Penerimaan Bahan Baku'
      // this.tempInput.f2digitreg = this.user.user_branch
    }
    // this.getMaster()
  },
  // watch: {
  //   'rowData'(o, n) {
  //     console.log('o', o.length - 1)
  //     console.log('n', n)
  //   },
  // },
  data() {
    return {
      action: 'create',
      title: 'Create Penerimaan Bahan Baku',
      tgl1: moment(),
      editdata: JSON.parse(
        localStorage.getItem('projectatransaksipurchasereceive'),
      ),
      masterCustomer: [],
      masterEmployee: [],
      masterBarang: [],
      therealsubtotal: '0',
      maskInput: {
        id: 0,
        tgl_order: '',
        tgl_terima: '',
        order_no: '',
        nama_supplier: '',
      },
      input: {
        order_id: '',
        tgl_terima: '',
        catatan: '',
      },

      // Property a-table
      componentKeyDatatable: 0,
      // End of property

      // ag-grid-property
      rowClassRules: {
        'text-success': (params) => {
          return params.data.newdata !== undefined
        },
        'text-warning': (params) => {
          return params.data.edited !== undefined
        },
        'text-danger': (params) => {
          return params.data.isError !== undefined
        },
        'text-primary': (params) => {
          return (
            params.data.isPrimary !== undefined && params.data.isPrimary === 1
          )
        },
        'font-weight-bold': (params) => {
          return params.data.isBold !== undefined
        },
      },
      changedData: [],
      frameworkComponents: {
        numericEditor: numericInput,
        dateEditor: dateInput,
        selectEditor: selectInput,
        customTooltip: tooltip,
        htmlRender: htmlrender,
        removeData: removeData,
      },
      selectedData: [],
      columnDefs: [
        // {
        //   headerName: '#',
        //   width: 50,
        //   editable: false,
        //   resizable: false,
        //   sortable: false,
        //   cellRenderer: 'removeData',
        // },
        {
          headerName: 'Order',
          field: 'order_id',
          width: 80,
          editable: false,
          resizable: true,
          sortable: true,
        },
        {
          headerName: 'Tanggal',
          field: 'tgl',
          width: 100,
          editable: false,
          resizable: true,
          sortable: true,
        },
        {
          headerName: 'Nominal',
          field: 'nominal',
          editable: false,
          resizable: true,
          sortable: true,
          width: 150,
          type: 'rightAligned',
          valueFormatter: (params) => {
            // console.log('params', params)
            var ret = params.value === null ? 'NaN' : lou.curency(params.value)
            return ret === 'NaN' ? '' : ret
          },
        },
        // {
        //   headerName: 'Total Harga',
        //   isBold: 'true',
        //   valueGetter: params => {
        //     // console.log('params.data.jumlah', params.data.jumlah)
        //     var total = parseInt(params.data.harga) * parseInt(params.data.jumlah)
        //     return total
        //   },
        //   editable: false,
        //   resizable: true,
        //   sortable: true,
        //   width: 150,
        //   type: 'rightAligned',
        //   valueFormatter: (params) => {
        //     // console.log('params', params)
        //     var ret = params.value === null ? 'NaN' : lou.curency(params.value)
        //     return ret === 'NaN' ? '' : ret
        //   },
        // },
      ],
      columnDefs1: [
        {
          headerName: 'Kode',
          field: 'kode',
          width: 100,
          editable: false,
          resizable: true,
          sortable: true,
        },
        {
          headerName: 'Nama Produk',
          field: 'product_name',
          width: 150,
          editable: false,
          resizable: true,
          sortable: true,
        },
        // {
        //   headerName: 'Harga',
        //   field: 'harga',
        //   width: 100,
        //   editable: false,
        //   resizable: true,
        //   sortable: true,
        //   valueFormatter: (params) => {
        //     // console.log('params', params)
        //     var ret = params.value === null ? 'NaN' : lou.curency(params.value)
        //     return ret === 'NaN' ? '' : ret
        //   },
        // },
        {
          headerName: 'Jumlah',
          field: 'jumlah',
          width: 70,
          editable: false,
          resizable: true,
          sortable: true,
          valueFormatter: (params) => {
            // console.log('params', params)
            var ret = params.value === null ? 'NaN' : lou.curency(params.value)
            return ret === 'NaN' ? '' : ret
          },
        },
        // {
        //   headerName: 'Total',
        //   field: 'total',
        //   width: 100,
        //   editable: false,
        //   resizable: true,
        //   sortable: true,
        //   valueFormatter: (params) => {
        //     // console.log('params', params)
        //     var ret = params.value === null ? 'NaN' : lou.curency(params.value)
        //     return ret === 'NaN' ? '' : ret
        //   },
        // },
        // {
        //   headerName: 'Diskon',
        //   field: 'diskon',
        //   width: 70,
        //   editable: false,
        //   resizable: true,
        //   sortable: true,
        //   valueFormatter: (params) => {
        //     // console.log('params', params)
        //     var ret = params.value === null ? 'NaN' : lou.curency(params.value)
        //     return ret === 'NaN' ? '' : ret
        //   },
        // },
      ],
      rowData: [],
      rowData1: [],
      gridOptions: null,
      gridApi: null,
      gridOptions1: null,
      gridApi1: null,
      componentKey: 0,
    }
  },
  methods: {
    moment,
    selectProduk() {
      var targetKerd = this.masterBarang.findIndex(
        (x) => x.id === this.maskInput.selectedproduct,
      )
      var dataTargetd = this.masterBarang[targetKerd]
      this.maskInput.satuan = dataTargetd.satuan
      this.maskInput.jumlah = 1
      this.maskInput.harga = dataTargetd.harga
      // var exist = this.rowData.findIndex(x => x.id === this.maskInput.selectedproduct)
      // console.log('exist', exist)
      // if (exist < 0) {
      //   var targetKer = this.masterBarang.findIndex((x) => x.id === this.maskInput.selectedproduct)
      //   var dataTarget = this.masterBarang[targetKer]
      //   var datafd = {
      //     ...dataTarget,
      //     jumlah: 1,
      //     totalharga: 0,
      //   }
      //   this.rowData.splice(this.rowData.length - 1, 0, datafd)
      // } else {
      //   var drowData = this.rowData[exist]
      //   var targetKerd = this.masterBarang.findIndex((x) => x.id === this.maskInput.selectedproduct)
      //   var dataTargetd = this.masterBarang[targetKerd]
      //   var datafdd = {
      //     ...dataTargetd,
      //     jumlah: parseInt(drowData.jumlah) + 1,
      //     totalharga: 0,
      //   }
      //   var cofrwdt = this.$g.clone(this.rowData)
      //   var targetKerd1 = cofrwdt.findIndex((x) => x.id === this.maskInput.selectedproduct)
      //   cofrwdt.splice(targetKerd1, 1)
      //   cofrwdt.splice(targetKerd1, 0, datafdd)
      //   this.rowData = this.$g.clone(cofrwdt)
      // }
      // this.maskInput.selectedproduct = ''
    },
    filterDate(date, dateString) {
      this.tgl1 = date
      this.getAllData()
    },
    async getAllData() {
      var imbuhan =
        this.tgl1 !== null && this.tgl1 !== ''
          ? '?tgl=' + this.tgl1.format('YYYY-MM-DD')
          : ''
      var res = await lou.customUrlGet2('transaksi/jurnal' + imbuhan)
      if (res) {
        this.data = res.data
      }
    },
    nextFocus(to) {
      // const input = this.$refs.rekening.$el.querySelector('input')
      // input.click()
      // this.$refs.rekening.$el.blur()
      // console.log('to', to)
      setTimeout(() => {
        this.$refs[to].$el.focus()
      }, 200)
    },
    removeFromGrid() {
      var indexTarget = this.rowData.findIndex(
        (x) => x.uuid === this.selectedData[0].uuid,
      )
      this.rowData.splice(indexTarget, 1)
    },
    bataledit() {
      this.selectedData = []
      this.maskInput = {
        id: 0,
        tgl_order: '',
        tgl_terima: '',
        order_no: '',
        nama_supplier: '',
      }
    },
    addDataToGrid() {
      var exist = this.rowData.findIndex(
        (x) => x.id === this.maskInput.selectedproduct,
      )
      // console.log('exist', exist)
      if (exist < 0) {
        var targetKer = this.masterBarang.findIndex(
          (x) => x.id === this.maskInput.selectedproduct,
        )
        var dataTarget = this.masterBarang[targetKer]
        var datafd = {
          ...dataTarget,
          satuan:
            this.maskInput.satuan === ''
              ? dataTarget.satuan
              : this.maskInput.satuan,
          jumlah: this.maskInput.jumlah === '' ? 1 : this.maskInput.jumlah,
          harga:
            this.maskInput.harga === ''
              ? dataTarget.harga
              : this.maskInput.harga,
          totalharga: 0,
        }
        this.rowData.splice(this.rowData.length - 1, 0, datafd)
      } else {
        // var drowData = this.rowData[exist]
        var targetKerd = this.masterBarang.findIndex(
          (x) => x.id === this.maskInput.selectedproduct,
        )
        var dataTargetd = this.masterBarang[targetKerd]
        var datafdd = {
          ...dataTargetd,
          satuan:
            this.maskInput.satuan === ''
              ? dataTarget.satuan
              : this.maskInput.satuan,
          jumlah: this.maskInput.jumlah === '' ? 1 : this.maskInput.jumlah,
          harga:
            this.maskInput.harga === ''
              ? dataTarget.harga
              : this.maskInput.harga,
          totalharga: 0,
        }
        var cofrwdt = this.$g.clone(this.rowData)
        var targetKerd1 = cofrwdt.findIndex(
          (x) => x.id === this.maskInput.selectedproduct,
        )
        cofrwdt.splice(targetKerd1, 1)
        cofrwdt.splice(targetKerd1, 0, datafdd)
        this.rowData = this.$g.clone(cofrwdt)
      }
      // if (this.selectedData.length === 0) {
      //   var targetKer = this.masterRekening.findIndex((x) => x.kode === this.maskInput.rekening)
      //   var dataTarget = this.masterRekening[targetKer]
      //   var datafd = {
      //     uuid: this.$uuid.v4(),
      //     no: this.maskInput.no,
      //     id: dataTarget.id,
      //     rekening: dataTarget.kode,
      //     nama_rekening: dataTarget.keterangan,
      //     keterangan: this.maskInput.keterangan,
      //     debet: this.maskInput.debet !== '' ? this.maskInput.debet : '0',
      //     kredit: this.maskInput.kredit !== '' ? this.maskInput.kredit : '0',
      //   }
      //   this.rowData.splice(this.rowData.length - 1, 0, datafd)
      this.hitHitungTotal()
      // } else {
      //   var target = this.rowData.findIndex((x) => x.uuid === this.selectedData[0].uuid)
      //   if (target !== -1) {
      //     var targetrek = this.masterRekening.findIndex(
      //       (x) => x.kode === this.maskInput.rekening,
      //     )
      //     var dataTargetRek = this.masterRekening[targetrek]
      //     var data = this.rowData[target]
      //     data.id = dataTargetRek.id
      //     data.rekening = dataTargetRek.kode
      //     data.nama_rekening = dataTargetRek.keterangan
      //     data.keterangan = this.maskInput.keterangan
      //     data.debet = this.maskInput.debet !== '' ? this.maskInput.debet : '0'
      //     data.kredit = this.maskInput.kredit !== '' ? this.maskInput.kredit : '0'
      //     this.selectedData = []
      //     this.componentKey += 1
      //     this.hitHitungTotal()
      //   }
      // }
      this.selectedData = []
      this.maskInput = {
        id: 0,
        tgl_order: '',
        tgl_terima: '',
        order_no: '',
        nama_supplier: '',
      }
      this.hitungSubTotal()
      // const input = this.$refs.rekening.$el.querySelector('input')
      // input.focus()
    },
    hitungSubTotal() {
      var vt = 0
      this.rowData.forEach((element) => {
        vt += parseInt(element.jumlah) * parseInt(element.harga)
      })
      this.therealsubtotal = vt
      console.log('this.therealsubtotal', this.therealsubtotal)
    },
    hitHitungTotal() {
      var target = this.rowData.findIndex(
        (x) => x.uuid === 'totaldarisemuanya',
      )
      // console.log('this.rowData.length', this.rowData.length)
      // console.log('this.rowData[this.rowData.length - 1]', this.rowData[this.rowData.length - 1])
      // this.rowData.splice(this.rowData.length - 1, 1)
      var totaldebet = 0
      this.rowData.forEach((element) => {
        if (element.uuid !== 'totaldarisemuanya') {
          totaldebet += parseFloat(element.nominal)
        }
        // console.log('element.debet', element.debet)
        // console.log('element.kredit', element.kredit)
      })
      this.rowData[target].nominal = totaldebet
      // this.rowData.push(
      //   {
      //     uuid: 'totaldarisemuanya',
      //     rekening: '',
      //     namarek: '',
      //     keterangan: 'Total',
      //     debet: totaldebet,
      //     kredit: totalkredit,
      //   },
      // )
    },
    async getMaster() {
      this.componentKey += 1
      var resmasterBarang = await lou.customUrlGet2(
        'transaksi/purchase_receive/barang',
      )
      var resmasterCustomer = await lou.customUrlGet2(
        'transaksi/purchase_receive/customer',
      )
      var resmasterEmployee = await lou.customUrlGet2(
        'transaksi/purchase_receive/employee',
      )
      if (resmasterCustomer) {
        this.masterCustomer = []
        resmasterCustomer.data.forEach((element) => {
          var nobj = {
            ...element,
            label: element.nama_perusahaan + ' - ' + element.nama_kontak,
          }
          this.masterCustomer.push(nobj)
        })
        // console.log('this.masterRekening', this.masterRekening)
      }
      if (resmasterEmployee) {
        this.masterEmployee = []
        resmasterEmployee.data.forEach((element) => {
          var nobj = {
            ...element,
            label: element.nama + ' - ' + element.hp,
          }
          this.masterEmployee.push(nobj)
        })
        // console.log('this.masterRekening', this.masterRekening)
      }
      if (resmasterBarang) {
        this.masterBarang = []
        resmasterBarang.data.forEach((element) => {
          var nobj = {
            ...element,
            label: element.kode + ' - ' + element.nama + ' - ' + element.harga,
          }
          this.masterBarang.push(nobj)
        })
        // console.log('this.masterRekening', this.masterRekening)
      }
      // this.rowData.push({
      //   uuid: 'totaldarisemuanya',
      //   rekening: '',
      //   namarek: '',
      //   keterangan: 'Total',
      //   debet: 0,
      //   kredit: 0,
      // })
    },
    async handleSubmit(action = 'create') {
      // console.log('this.rowData', this.rowData)
      if (this.input.tgl_terima !== null && this.input.tgl_terima !== '') {
        var fd = this.input
        // console.log('fd', fd)
        // console.log('this.editdata', this.editdata)
        fd.order_id = fd.order_id === '' || fd.order_id === null ? this.editdata.id : fd.order_id
        // fd.tgl_order = fd.tgl_order.format('YYYY-MM-DD')
        if (typeof fd.tgl_terima === 'string') {
          fd.tgl_terima = moment(this.input.tgl_terima, 'DD-MM-YYYY').format('YYYY-MM-DD')
        } else {
          fd.tgl_terima = this.input.tgl_terima.format('YYYY-MM-DD')
        }
        // fd.nominal = parseInt(fd.nominal)
        var res = null
        if (action === 'create') {
          delete fd.id
          res = await lou.customUrlPost2('transaksi/purchase_receive', fd)
        } else {
          res = await lou.customUrlPut2('transaksi/purchase_receive', fd)
        }
        if (res) {
          this.editdata = res.data
          this.resetForm(true)
          this.getEditData()
          // this.getAllData()
        }
        // if (this.input.order_id !== null && this.input.order_id !== '') {
        // } else {
        //   lou.shownotif('Peringatan!', 'Tidak ada data Order ID.', 'error')
        // }
        //   },
        //   onCancel: () => {
        //     // console.log('Cancel')
        //     return false
        //   },
        // })
      } else {
        lou.shownotif('Peringatan!', 'Tidak ada data tanggal yang diinput.', 'error')
      }
    },

    resetForm(back = false) {
      // this.editdata = {}
      // this.action = 'create'
      this.selectedData = []
      this.maskInput = {
        id: 0,
        tgl_order: '',
        tgl_terima: '',
        order_no: '',
        nama_supplier: '',
      }
      this.input = {
        order_id: '',
        tgl_terima: moment(),
        catatan: '',
      }
      this.rowData = []
      if (back) {
        localStorage.removeItem('projectatransaksipurchasereceive')
        this.$parent.active = 0
        // this.$router.push({ path: '/projecta/transaksi/orderpenjualan' })
      }
    },
    // ag-grid function
    onGridReady(params) {
      // params.api.sizeColumnsToFit()
      this.gridApi = params.api
      this.gridColumnApi = params.columnApi
    },
    onGridReady1(params) {
      // params.api.sizeColumnsToFit()
      this.gridApi1 = params.api
      this.gridColumnApi1 = params.columnApi
    },
    onCellValueChanged(params) {
      // var undoSize = params.api.getCurrentUndoSize()
      // this.input.undoInput = undoSize
      // this.input.undoInputdisable = undoSize < 1
      // var redoSize = params.api.getCurrentRedoSize()
      // this.input.redoInput = redoSize
      // this.input.redoInputdisable = redoSize < 1
      if (this.mode === 'oncreate') {
        if (params.data.newdata) {
          var target = this.changedData.findIndex(
            (x) => x.id === params.data.id,
          )
          if (target < 0) {
            this.changedData.push(params.data)
          } else {
            this.changedData[target] = params.data
          }
          // console.log('this.changedData', this.changedData)
        } else {
          // eslint-disable-next-line no-redeclare
          var target = this.rowData.findIndex((x) => x.id === params.data.id)
          var backupTarget = this.backupRowData.findIndex(
            (x) => x.id === params.data.id,
          )
          // console.log('this.rowData[target] before:', this.rowData[target])
          this.rowData[target] = this.$g.clone(
            this.backupRowData[backupTarget],
          )
          // console.log('this.rowData[target] after:', this.rowData[target])
          // console.log('this.backupRowData[backupTarget]', this.backupRowData[backupTarget])
          // this.componentKey += 1
          this.changedData = []
          this.input.undoInput = 0
          this.input.undoInputdisable = true

          this.input.redoInput = 0
          this.input.redoInputdisable = true
          lou.shownotif('Anda sedang dalam mode create')
        }
      } else {
        this.mode = 'onupdate'
        // eslint-disable-next-line no-redeclare
        var targetChanged = this.changedData.findIndex(
          (x) => x.id === params.data.id,
        )
        if (targetChanged < 0) {
          // var cd = {}
          // cd.id = params.data.id
          // cd[params.column.colId] = value.value
          // console.log('cd[params.column.colId]', cd)
          // console.log('value.value', value)
          this.changedData.push(params.data)
        } else {
          this.changedData[targetChanged][params.column.colId] =
            params.newValue
        }
        // console.log('this.changedData', this.changedData)
        // this.componentKey += 1
      }
      this.hitungSubTotal()
      // console.log('this.mode', this.mode)
    },
    onSelectionChanged() {
      var selectedRows = this.gridApi.getSelectedRows()
      if (selectedRows[0].uuid !== 'totaldarisemuanya') {
        this.selectedData = selectedRows
        this.input.id = selectedRows[0].id
        this.input.order_id = selectedRows[0].order_id
        this.input.tgl = moment(selectedRows[0].tgl, 'YYYY-MM-DD')
        this.input.nominal = selectedRows[0].nominal
      } else {
        lou.shownotif('Total', 'Total akan terupdate secara otomatis.')
      }
      // console.log('selectedRows', selectedRows)
    },
    onFirstDataRendered() {
      // this.autoSizeAll(false, { autosizecolumn: true })
    },
    autoSizeAll(skipHeader, { autosizecolumn = true }) {
      if (autosizecolumn) {
        var allColumnIds = []
        // console.log('this.gridColumnApi', this.gridColumnApi)
        this.gridColumnApi.getAllColumns().forEach(function (column) {
          allColumnIds.push(column.colId)
        })
        this.gridColumnApi.autoSizeColumns(allColumnIds, skipHeader)
      } else {
        this.gridApi.sizeColumnsToFit()
      }
    },

    getEditData() {
      // this.action = 'update'
      this.selectedData = []
      // console.log('this.editdata', this.editdata)
      this.maskInput = {
        id: 0,
        tgl_order: this.editdata.tgl_order !== null ? moment(this.editdata.tgl_order, 'YYYY-MM-DD').format('DD-MM-YYYY') : '',
        tgl_terima: this.editdata.tgl_terima !== null ? moment(this.editdata.tgl_terima, 'YYYY-MM-DD').format('DD-MM-YYYY') : '',
        order_no: this.editdata.order_no,
        nama_supplier: this.editdata.nama_supplier,
      }
      this.input = {
        id: this.editdata.id,
        order_id: this.editdata.order_id,
        tgl_terima: this.editdata.tgl_terima !== null ? moment(this.editdata.tgl_terima, 'YYYY-MM-DD') : moment(),
        catatan: this.editdata.catatan,
      }
      // this.rowData = []
      // var tempdata = this.$g.clone(this.editdata.payment)
      // tempdata.forEach((element) => {
      //   var dfasdf = {
      //     ...element,
      //   }
      //   this.rowData.push(dfasdf)
      // })
      this.rowData = []
      var tempdata1 = this.$g.clone(this.editdata.details)
      tempdata1.forEach((element) => {
        var dfasdf = {
          ...element,
        }
        this.rowData.push(dfasdf)
      })
      this.rowData.push({
        uuid: 'totaldarisemuanya',
        tgl: 'Total',
        nominal: '0',
      })
      this.hitHitungTotal()
    },
    showDeleteConfirm(deldata) {
      Modal.confirm({
        title: 'Are you sure delete this data?',
        content: "You can't prevent this!!! ",
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'No',
        onOk: async () => {
          var response = await lou.customUrlDelete2(
            'transaksi/jurnal?faktur=' + deldata,
          )
          if (response) {
            this.getAllData()
          }
        },
        onCancel: () => {
          // console.log('Cancel')
        },
      })
    },
    dateFormatter(params) {
      var ret =
        params.value === null
          ? ''
          : moment(params.value, 'YYYY-MM-DD').format('DD-MM-YYYY')
      // console.log('dateFormatter', ret)
      return ret !== 'Invalid date' ? ret : params.value
    },
    currencyFormatter(params) {
      var ret =
        params.value === null
          ? 'NaN'
          : lou.curency(parseFloat(params.value).toFixed(0))
      return ret === 'NaN' ? '' : ret
    },
    formatTanggal(v) {
      // console.log('v', v)
      // console.log('ntgl', ntgl)
      return moment(v.tgl, 'YYYY-MM-DD').format('DD-MM-YYYY')
    },
    formatCurrency(v) {
      return lou.curency(v)
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      )
    },
    getTotalDetails(what, data) {
      var total = 0

      data.forEach((element) => {
        total += element[what]
      })

      return total
    },
    back() {
      this.$parent.active = 0
      // this.$router.go(-1)
    },
  },
}
</script>

<style lang="scss" scoped></style>
