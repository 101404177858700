<template>
  <div class="row">
    <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8 mb-3">
              <strong>Data Deposito</strong>
            </div>
            <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 mb-3">
              <a-button class="btn btn-outline-danger float-right" @click="back"
                >Return</a-button
              >
            </div>
            <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 mt-1">
              <span>Nominal</span>
            </div>
            <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8">
                <cleave
                  id="inputfortablepenilaianvalue"
                  v-model="input.datapemohon.nominal"
                  :options="options.number"
                  placeholder="Masukan Nominal"
                  class="ant-input"
                  v-on:keydown.native.enter="tambahnilaipasar"
                />
              <!-- style="cursor: not-allowed;" -->
            </div>
            <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 mt-3">
              <span>Rekening</span>
            </div>
            <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8 mt-2">
              <a-input
                class=""
                v-model="input.datapemohon.rekening"
                placeholder="Rekening pemohon"
                :readOnly="false"
              ></a-input>
              <!-- style="cursor: not-allowed;" -->
            </div>
            <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 mt-3">
              <span>Kode Pemohon</span>
            </div>
            <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8 mt-2">
              <a-select
                v-if="pinginubah"
                show-search
                label-in-value
                id="inputdatapemohonnomor_registrasi"
                v-model="input.datapemohon.nomor_registrasi"
                placeholder="Nomor registrasi pemohon"
                style="width: 100%;"
                :filter-option="false"
                :not-found-content="fetching ? undefined : null"
                @search="fetchUser"
                @change="handleChange"
              >
                <a-spin v-if="fetching" slot="notFoundContent" size="small" />
                <a-select-option v-for="d in data" :key="d.kode">
                  {{ d.kode + "-" + d.nama }}
                </a-select-option>
              </a-select>
              <a-input
                :readOnly="true"
                :value="input.datapemohon.nomor_registrasi.key"
                @click="
                  datanasabah !== null
                    ? (pinginubah = false)
                    : (pinginubah = true)
                "
                v-else
              ></a-input>
              <!-- <a-select
                show-search
                label-in-values
                v-model="input.datapemohon.nomor_registrasi"
                placeholder="Nomor registrasi"
                style="width: 100%"
                :filter-option="false"
                :not-found-content="fetching ? undefined : null"
                @search="handleSearch"
                @change="pilihPemohon"
              >
                <a-spin v-if="fetching" slot="notFoundContent" size="small" />
                <a-select-option v-for="d in datapemohon" :key="d.id">
                  {{ d.kode + ' - ' + d.nama }}
                </a-select-option>
              </a-select> -->
            </div>
            <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 mt-3">
              <span>Nama</span>
            </div>
            <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8 mt-2">
              <a-input
                class=""
                v-model="input.datapemohon.nama"
                placeholder="Nama pemohon"
                :readOnly="true"
              ></a-input>
              <!-- style="cursor: not-allowed;" -->
            </div>
            <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 mt-3">
              <span>Alamat</span>
            </div>
            <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8 mt-2">
              <a-input
                class=""
                v-model="input.datapemohon.alamat"
                placeholder="Alamat pemohon"
                :readOnly="true"
              ></a-input>
              <!-- style="cursor: not-allowed;" -->
            </div>
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-3">
              <a-button
                id="therealsimpanbutton"
                class="btn btn-outline-primary float-right"
                @click="validasi"
                >Simpan</a-button
              >
              <!-- <a-button class="btn btn-outline-success mr-2 float-right">Cetak Perjanjian</a-button> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <modaldetail ref="detailmodal" /> -->
  </div>
</template>

<script>
import NProgress from 'nprogress'
import * as lou from '@/services/data/lou'
// import modaldetail from '@/components/lou/temptable'
import { Modal } from 'ant-design-vue'
import debounce from 'lodash/debounce'
import moment from 'moment'

import Cleave from 'vue-cleave-component'

import firebase from 'firebase/app'
import 'firebase/storage'

import Docxtemplater from 'docxtemplater'
import ImageModule from 'docxtemplater-image-module-free'
import PizZip from 'pizzip'
import { saveAs } from 'file-saver'
import PizZipUtils from 'pizzip/utils/index.js'
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState(['settings', 'user']),
  },
  components: {
    // modaldetail,
    cleave: Cleave,
  },
  data() {
    this.lastFetchId = 0
    this.fetchUser = debounce(this.fetchUser, 800)
    return {
      pinginubah: false,
      datanasabah: JSON.parse(localStorage.getItem('datanasabah')),
      editdata: JSON.parse(localStorage.getItem('editformdeposito')),
      options: {
        number: {
          numeral: true,
        },
      },
      input: {
        datapemohon: {
          nomor_registrasi: {},
          rekening: '',
          nama: '',
          kode: '',
          alamat: '',
          nominal: '',
        },
      },
      action: '',
      title: '',
      data: [],
      datatablenilaipasar: [],
      fetching: false,
      datapemohon: [],
      datenow: '',
    }
  },
  async created() {
    this.datenow = lou.datenow()
    console.log('this.datanasabah', this.datanasabah)
    if (this.datanasabah !== null) {
      if (this.datanasabah.action === 'create') {
        this.action = 'create'
        this.title = 'Tambah Data'
        this.input.datapemohon.nomor_registrasi.key = this.datanasabah.kode
        this.input.datapemohon.nama = this.datanasabah.nama
        this.input.datapemohon.alamat = this.datanasabah.alamat
        this.fetchUser(this.input.datapemohon.nomor_registrasi)
        this.handleChange(this.input.datapemohon.nomor_registrasi)
      } else {
        var target = this.datanasabah.target_jaminan
        this.action = 'update'
        this.title = 'Update Data'
        var response = await lou.customUrlGet2('taksasideposito/' + target.id_jaminan)
        this.editdata = response.data
        this.setData()
        this.fetchUser(this.input.datapemohon.nomor_registrasi)
        this.handleChange(this.input.datapemohon.nomor_registrasi)
      }
    } else {
      if (this.editdata.id !== '') {
        this.action = 'update'
        this.title = 'Update Data'
        this.setData()
        this.fetchUser(this.input.datapemohon.nomor_registrasi)
        this.handleChange(this.input.datapemohon.nomor_registrasi)
      } else {
        this.pinginubah = true
        this.action = 'create'
        this.title = 'Tambah Data'
      }
    }
  },
  watch: {
    datatablenilaipasar: {
      handler: function (val, oldVal) {
        this.countRataRata()
      },
      deep: true,
    },
  },
  methods: {
    moment,
    validasi() {
      if (this.input.datapemohon.nomor_registrasi === '') {
        lou.shownotif('Data nomor registrasi harus diisi!')
      } else {
        this.simpan()
      }
    },
    find(value) {
      // console.log('value', value)
    },
    pilihPemohon(value) {
      this.value = value
      fetch(value, (data) => (this.data = data))
      // console.log('value', value)
    },
    async simpan() {
      // var listUniqId = this.datatablenilaipasar.map(({ key, ...item }) => item)
      var fd = {
        kode_pemohon: this.input.datapemohon.nomor_registrasi.key,
        rekening: this.input.datapemohon.rekening,
        nama: this.input.datapemohon.nama,
        alamat: this.input.datapemohon.alamat,
        nominal: this.input.datapemohon.nominal,
      }
      var res
      if (this.action === 'create') {
        res = await lou.customUrlPost2('taksasideposito', fd)
      } else {
        fd.id = this.editdata.id
        console.log('fd', fd)
        res = await lou.customUrlPut2('taksasideposito', fd)
      }
      if (res) {
        Modal.confirm({
          title: 'Print?',
          content: 'Apakah anda ingin cetak taksasi?',
          okText: 'Yes',
          okType: 'success',
          cancelText: 'No',
          onOk: async () => {
            this.generateDocx(res.data)
            this.$router.go(-1)
            // await lou.getDocument('taksasideposito', res.data.id)
            // this.$router.push('/taksasijaminan/deposito')
          },
          onCancel: () => {
            this.$router.go(-1)
            // this.$router.push('/taksasijaminan/deposito')
            // console.log('Cancel')
          },
        })
      } else {
        lou.shownotif('error', 'Something wrong!')
      }
    },
    setData() {
      this.input.datapemohon.nomor_registrasi.key = this.editdata.kode_pemohon
      this.input.datapemohon.nama = this.editdata.pemohon.nama
      this.input.datapemohon.nominal = this.editdata.nominal
      this.input.datapemohon.rekening = this.editdata.rekening
      this.input.datapemohon.alamat = this.editdata.pemohon.alamat
    },
    async fetchUser(value) {
      // console.log('fetchinguser', value)
      if (value !== '') {
        this.lastFetchId += 1
        this.data = []
        this.fetching = true
        var response = await lou.customUrlGet2('pengajuankredit?limit=10&kode=' + value)
        if (response) {
          if (response.data.length !== 0) {
            this.data = response.data
          } else {
            this.data = []
            lou.shownotif('Not Found!', 'Data not found!')
            this.fetching = false
          }
        }
      } else {
        console.log('Empty: -')
      }
    },
    handleChange(value) {
      var backup = this.$g.clone(this.data)
      var target = backup.find((x) => x.kode === value.key)
      if (target) {
        this.input.datapemohon.nama = target.nama
        this.input.datapemohon.alamat = target.alamat
        this.input.datapemohon.jenis_pekerjaan = target.pekerjaan
        this.input.datapemohon.nama_perusahaan = ''
      }
      Object.assign(this, {
        value,
        data: [],
        fetching: false,
      })
    },
    countRataRata() {
      if (this.datatablenilaipasar.length !== 0) {
        var grandTotal = 0
        var totalSumValue = 0
        this.datatablenilaipasar.forEach((element) => {
          totalSumValue += parseInt(element.value)
        })
        grandTotal = totalSumValue / this.datatablenilaipasar.length
        this.input.penilaian.nilai_rata_pasar = grandTotal
      } else {
        this.input.penilaian.nilai_rata_pasar = 0
      }
      this.penyusutan()
    },
    penyusutan() {
      // console.log('this.input.penilaian.penyusutan', this.input.penilaian.penyusutan)
      var penyusutan =
        (parseInt(this.input.penilaian.nilai_rata_pasar) *
          parseInt(this.input.penilaian.penyusutan)) /
        100
      var grandTotal =
        parseInt(this.input.penilaian.nilai_rata_pasar) - parseInt(penyusutan)
      this.input.penilaian.nilai_taksasi = grandTotal
      this.input.kesimpulan.nilai_taksasi = grandTotal
      // console.log('grandTotal', grandTotal)
    },
    countKesimpulan() {
      // console.log('this.input.penilaian.penyusutan', this.input.penilaian.penyusutan)
      var grandTotal =
        (parseInt(this.input.kesimpulan.nilai_taksasi) *
          parseInt(this.input.kesimpulan.presentase_plafond_kredit)) /
        100
      this.input.kesimpulan.maksimal_plafond_kredit = grandTotal
      // console.log('grandTotal', grandTotal)
    },
    async generateDocx(record) {
      // lou.shownotif('Word Generator!', 'Generating Word File!', 'info')
      NProgress.start()
      var storageRef = ''
      await firebase
        .storage()
        .ref('taksasi/template/' + this.user.sandibpr + 'taksasidepositotemplate.docx').getDownloadURL().then((url) => {
          // `url` is the download URL
          // console.log(url)
          storageRef = url
        // eslint-disable-next-line handle-callback-err
        }).catch(async (error) => {
          // Handle any errors
          var defaultTemplateLink = await firebase
            .storage()
            .ref('taksasi/template/taksasidepositotemplate.docx').getDownloadURL()
          storageRef = defaultTemplateLink
          // switch (error.code) {
          //   case 'storage/object-not-found':
          //     // File doesn't exist
          //     lou.shownotif("File doesn't exist")
          //     break

          //   case 'storage/unauthorized':
          //     // User doesn't have permission to access the object
          //     lou.shownotif("User doesn't have permission to access the object")
          //     break

          //   case 'storage/canceled':
          //     // User canceled the upload
          //     lou.shownotif('User canceled the upload')
          //     break

          //   case 'storage/unknown':
          //     // Unknown error occurred, inspect the server response
          //     lou.shownotif('Unknown error occurred, inspect the server response')
          //     break
          //   default:
          //     break
          // }
        })
      // console.log('storageRef', storageRef)
      var that = this
      this.loadFile(
        storageRef,
        function (error, content) {
          var dataset = {
            ...record,
          }
          dataset.tglSekarang = moment().locale('id').format('DD MMMM YYYY')
          // const data = record.pemohon
          dataset.namaUserLogin = that.user.name
          dataset.nominal = record.nominal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
          // console.log('dataset', dataset)
          var opts = {}
          opts.centered = false
          opts.getImage = function (tagValue, tagName) {
            // console.log('tagValue', tagValue)
            return new Promise(function (resolve, reject) {
              PizZipUtils.getBinaryContent(tagValue, function (error, content) {
                // console.log('content', content)
                if (error) {
                  // console.log('error', error)
                  return reject(error)
                }
                return resolve(content)
              })
            })
          }
          opts.getSize = function (img, tagValue, tagName) {
            // FOR FIXED SIZE IMAGE :
            return [405, 303]
          }
          // eslint-disable-next-line no-unused-vars
          var imageModule = new ImageModule(opts)
          if (error) {
            throw error
          }
          const zip = new PizZip(content)
          const doc = new Docxtemplater()
            .loadZip(zip)
            .attachModule(imageModule)
            .compile()

          // render the document (replace all occurences of {first_name} by John, {last_name} by Doe, ...)
          doc.renderAsync(dataset).then(() => {
            // console.log('ready')
            // doc.render()
            const out = doc.getZip().generate({
              type: 'blob',
              mimeType:
                'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            })
            lou.shownotif('Generator success!', 'Generate word success without problem!', 'success')
            NProgress.done()
            // Output the document using Data-URI
            saveAs(out, 'Taksasi Deposito_ ' + record.pemohon.nama + '_' + record.nomortaksasi + '.docx').then(() => {
              // this.$router.go(-1)
            })
          })
        },
      )
    },
    // showModal() {
    //   var column = [
    //     {
    //       title: 'Jenis',
    //       dataIndex: 'jenis',
    //       scopedSlots: {
    //         customRender: 'jenis',
    //       },
    //     },
    //     {
    //       title: 'Value',
    //       dataIndex: 'value',
    //       scopedSlots: {
    //         customRender: 'value',
    //       },
    //     },
    //     {
    //       title: 'Keterangan',
    //       dataIndex: 'keterangan',
    //       scopedSlots: {
    //         customRender: 'keterangan',
    //       },
    //     },
    //   ]
    //   this.$refs.detailmodal.showModal(
    //     'nilai pasar',
    //     column,
    //     this.datatablenilaipasar,
    //     'datatablenilaipasar',
    //   )
    // },
    handleSearch(value) {
      fetch(value, (data) => (this.data = data))
    },
    back() {
      this.$router.go(-1)
    },
  },
}
</script>

<style></style>
