<template>
  <div>
    <a-card>
      <template slot="title">
    <strong>Pembelian By Supplier</strong>
    <a-range-picker
          class="ml-3 mr-2"
          style="width: 250px;"
          :allowClear="false"
          :default-value="[moment().startOf('month'), moment()]"
          format="DD-MM-YYYY"
          @change="filterDate"/>
        <a-button-group>
          <!-- <a-button icon="plus-circle" class="text-primary" type="" @click="sendToCreate('create', {})">
            Tambah
          </a-button> -->
          <a-tooltip placement="top">
            <template slot="title">
              <span>Refresh Data</span>
            </template>
            <a-button @click="getAllData(true)">
              <a-icon type="sync" :spin="loading" class="text-success"/>
            </a-button>
          </a-tooltip>
          <a-tooltip placement="top">
            <template slot="title">
              <span>Print Preview</span>
            </template>
            <a-button @click="exportPdf">
              <a-icon type="printer" class="text-success"/>
            </a-button>
          </a-tooltip>
          <!-- <a-tooltip placement="top">
            <template slot="title">
              <span>Export Data</span>
            </template>
            <a-button @click="exportExcel">
              <a-icon type="file-excel" class="text-success"/>
            </a-button>
          </a-tooltip> -->
        </a-button-group>
      </template>
          <v-select
              ref="supplier_id"
              placeholder="Ketikan Nama Supplier"
              class="style-chooser"
              label="label"
              style="width: 300px;margin-top: -15px;margin-bottom: 10px"
              v-model="idsupplier"
              :options="masterSupplier"
              :reduce="(tes) => tes.id"
              @input="selectDataSupplier"
            />
    <a-table
      class=""
      style="margin: 0px -25px;margin-bottom: -25px !important;"
      :columns="columns"
      :dataSource="data"
      :pagination="{
        hideOnSinglePage: true,
        defaultPageSize: 10000000,
        showQuickJumper: true,
        showSizeChanger: true,
        showTotal: (total) => `Total ${total} items`,
        pageSizeOptions: ['10', '20', '30'],
      }"
      size="small"
      :loading="loading"
    >
      <div
        slot="filterDropdown"
        slot-scope="{
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
          column,
        }"
        style="padding: 8px"
      >
        <a-input
          v-ant-ref="(c) => (searchInput = c)"
          :placeholder="`Search ${column.title}`"
          :value="selectedKeys[0]"
          style="width: 188px; margin-bottom: 8px; display: block"
          @change="
            (e) => setSelectedKeys(e.target.value ? [e.target.value] : [])
          "
          @pressEnter="
            () => handleSearch(selectedKeys, confirm, column.dataIndex)
          "
        />
        <a-button
          type="primary"
          icon="search"
          size="small"
          style="width: 90px; margin-right: 8px"
          @click="() => handleSearch(selectedKeys, confirm, column.dataIndex)"
        >
          Search
        </a-button>
        <a-button
          size="small"
          style="width: 90px"
          @click="() => handleReset(clearFilters)"
        >
          Reset
        </a-button>
      </div>
      <a-icon
        slot="filterIcon"
        slot-scope="filtered"
        type="search"
        :style="{ color: filtered ? '#108ee9' : undefined }"
      ></a-icon>
      <template slot="customRender" slot-scope="text, record, index, column">
        <span v-if="searchText && searchedColumn === column.dataIndex">
          <template
            v-for="(fragment, i) in text
              .toString()
              .split(new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i'))"
          >
            <mark
              v-if="fragment.toLowerCase() === searchText.toLowerCase()"
              :key="i"
              class="highlight"
              >{{ fragment }}</mark
            >
            <template v-else>{{ fragment }}</template>
          </template>
        </span>
        <template v-else>
          {{ text }}
        </template>
      </template>
      <span slot="action" slot-scope="text, record">
        <a class="text-warning" @click="sendToEdit(record)">
          <a-icon type="edit"></a-icon>
        </a>
        <a-divider type="vertical" />
        <a href="javascript:void(0)" class="text-danger" @click="showDeleteConfirm(record)">
          <a-icon type="delete"></a-icon>
        </a>
        <!-- <a href="javascript:;" class="ant-dropdown-link">
            More actions <a-icon type="down" />
          </a> -->
      </span>
      <span slot="tgl" slot-scope="text, record">
        {{ formatDate(record.tgl) }}
      </span>
        <span slot="formatCurrency" slot-scope="text">
          {{ formatCurrency(text) }}
        </span>
      <template
      :expandIconColumnIndex="3" slot="expandedRowRender" slot-scope="record" style="margin: 1">
        <a-table
          :columns="columnChildren"
          :dataSource="record.details"
          size="small"
          :pagination="{
            hideOnSinglePage: true,
            defaultPageSize: 10000000,
            showQuickJumper: true,
            showSizeChanger: true,
            showTotal: (total) => `Total ${total} items`,
            pageSizeOptions: ['10', '20', '30'],
          }"
        >
        <span slot="formatCurrency" slot-scope="text">
          {{ formatCurrency(text) }}
        </span>
        </a-table>
      </template>
    </a-table>
    </a-card>
  </div>
</template>

<script>
import { Modal } from 'ant-design-vue'
import * as lou from '@/services/data/lou'
import moment from 'moment'
import { mapState } from 'vuex'

// Firebase component
import firebase from 'firebase/app'
import 'firebase/storage'

// Word replacer
import Docxtemplater from 'docxtemplater'
// import ImageModule from 'docxtemplater-image-module-free'
import PizZip from 'pizzip'
import { saveAs } from 'file-saver'
import PizZipUtils from 'pizzip/utils/index.js'
import NProgress from 'nprogress'

export default {
  computed: {
    ...mapState(['settings', 'user']),
  },
  created() {
    this.getMaster()
    // this.getAllData()
  },
  data() {
    return {
      idsupplier: '',
      action: 'create',
      loading: false,
      theme: this.$store.state.settings.theme,
      tgl1: moment().startOf('month'),
      tgl2: moment(),
      masterSupplier: [],
      searchText: '',
      searchInput: null,
      searchedColumn: '',
      data: [],
      columns: [
        // {
        //   title: 'Action',
        //   scopedSlots: { customRender: 'action' },
        //   width: 100,
        //   // fixed: 'left',
        // },
        {
          title: 'No Order',
          dataIndex: 'order_no',
          width: 150,
          // fixed: 'left',
          scopedSlots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
            customRender: 'customRender',
          },
          onFilter: (value, record) =>
            record.order_no
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus()
              }, 0)
            }
          },
        },
        {
          title: 'Tanggal',
          dataIndex: 'tgl',
          width: 100,
          // fixed: 'left',
          scopedSlots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
            customRender: 'customRender',
          },
          onFilter: (value, record) =>
            record.tgl.toString().toLowerCase().includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus()
              }, 0)
            }
          },
        },
        {
          title: 'N. Supplier',
          dataIndex: 'nama_supplier',
          width: 150,
          // fixed: 'left',
          scopedSlots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
            customRender: 'customRender',
          },
          onFilter: (value, record) =>
            record.nama_supplier
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus()
              }, 0)
            }
          },
        },
        {
          title: 'Catatan',
          dataIndex: 'catatan',
          width: 150,
          // fixed: 'left',
          scopedSlots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
            customRender: 'customRender',
          },
          onFilter: (value, record) =>
            record.catatan
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus()
              }, 0)
            }
          },
        },
        {
          title: 'Sub Total',
          dataIndex: 'sub_total',
          ellipsis: true,
          align: 'right',
          scopedSlots: {
            customRender: 'formatCurrency',
          },
          sorter: (a, b) => a.sub_total - b.sub_total,
        },
        {
          title: 'Diskon',
          dataIndex: 'diskon',
          ellipsis: true,
          align: 'right',
          scopedSlots: {
            customRender: 'formatCurrency',
          },
          sorter: (a, b) => a.diskon - b.diskon,
        },
        {
          title: 'PPN',
          dataIndex: 'ppn',
          ellipsis: true,
          align: 'right',
          scopedSlots: {
            customRender: 'formatCurrency',
          },
          sorter: (a, b) => a.ppn - b.ppn,
        },
        {
          title: 'Total',
          dataIndex: 'total',
          ellipsis: true,
          align: 'right',
          scopedSlots: {
            customRender: 'formatCurrency',
          },
          sorter: (a, b) => a.total - b.total,
        },
      ],
      columnChildren: [
        {
          title: 'Nama',
          dataIndex: 'product_name',
          ellipsis: true,
          // scopedSlots: {
          //   customRender: 'formatCurrency',
          // },
        },
        {
          title: 'Harga',
          dataIndex: 'harga',
          ellipsis: true,
          align: 'right',
          scopedSlots: {
            customRender: 'formatCurrency',
          },
          sorter: (a, b) => a.harga - b.harga,
        },
        {
          title: 'Jumlah',
          dataIndex: 'jumlah',
          ellipsis: true,
          align: 'right',
          scopedSlots: {
            customRender: 'formatCurrency',
          },
          sorter: (a, b) => a.jumlah - b.jumlah,
        },
        {
          title: 'Diskon',
          dataIndex: 'diskon',
          ellipsis: true,
          align: 'right',
          scopedSlots: {
            customRender: 'formatCurrency',
          },
          sorter: (a, b) => a.diskon - b.diskon,
        },
        {
          title: 'Total',
          dataIndex: 'total',
          ellipsis: true,
          align: 'right',
          scopedSlots: {
            customRender: 'formatCurrency',
          },
          sorter: (a, b) => a.total - b.total,
        },
      ],
      datenow: '',

      // A Modal Property
      amodalvisible: false,
      masterData: {
        barang: [],
        customer: [],
        supplier: [],
      },

      // ag-grid-property
      changedData: [],
      selectedData: [],
      rowData: [],
      gridOptions: null,
      gridApi: null,
      componentKey: 0,
    }
  },
  methods: {
    moment,
    async exportPdf() {
      // this.amodal.selectedIndex = i
      if (this.idsupplier !== '' && this.idsupplier !== null) {
        var imbuhan =
      this.tgl1 !== null && this.tgl1 !== '' && this.tgl2 !== null && this.tgl2 !== ''
        ? '?tglawal=' + this.tgl1.format('YYYY-MM-DD') + '&tglakhir=' + this.tgl2.format('YYYY-MM-DD')
        : ''
        var imbuhan2 = this.tgl1 !== null && this.tgl1 !== '' && this.tgl2 !== null && this.tgl2 !== '' ? this.idsupplier !== null && this.idsupplier !== '' ? '&supplier_id=' + this.idsupplier : '' : this.idsupplier !== null && this.idsupplier !== '' ? '?supplier_id=' + this.idsupplier : ''
        var res = await lou.ngetDocumentPdf('laporan/pembelian/supplier' + imbuhan + imbuhan2 + '&preview=pdf', false, false)
        if (res) {
        // console.log("Success", response);
          const blob = new Blob([res], {
            type: 'application/pdf',
          })
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.target = '_blank'
          // link.download = filename
          link.click()
          // const objectUrl = URL.createObjectURL(blob)
          // console.log('objectUrl', objectUrl)
          // this.amodal.ArrayBuffer = objectUrl
          URL.revokeObjectURL(link.href)
          setTimeout(() => {
            this.componentKey += 1
            // this.amodalvisible = true
          }, 500)
        }
        // console.log('record.url2_invoice', record.url2_invoice)
        // window.open(record.url2_invoice, '_blank').focus()
      } else {
        lou.shownotif('Peringatan', 'Data supplier belum dipilih!', 'error')
      }
    },
    sendToCreate() {
      localStorage.setItem('projectatransaksisalesorder', JSON.stringify({ id: '' }))
      this.$parent.active = 1
      // this.$router.push('/projecta/transaksi/orderpembelian/form')
    },
    sendToEdit(record) {
      localStorage.setItem('projectatransaksisalesorder', JSON.stringify(record))
      this.$parent.active = 1
      // this.$router.push({ path: '/projecta/transaksi/orderpembelian/form' })
    },
    selectDataSupplier() {
      this.getAllData()
    },
    filterDate(date, dateString) {
      this.tgl1 = date[0]
      this.tgl2 = date[1]
      if (this.idsupplier !== '') {
        this.getAllData()
      } else {
        lou.shownotif('Notif', 'Data supplier belum dipilih.')
      }
    },
    async getMaster() {
      var res = await lou.customUrlGet2('master/supplier')
      if (res) {
        this.masterSupplier = []
        res.data.forEach(element => {
          var rd = {
            ...element,
            label: element.nama_perusahaan + ' - ' + element.nama_kontak,
          }
          this.masterSupplier.push(rd)
        })
      }
    },
    async getAllData() {
      this.loading = true
      var imbuhan =
      this.tgl1 !== null && this.tgl1 !== '' && this.tgl2 !== null && this.tgl2 !== ''
        ? '?tglawal=' + this.tgl1.format('YYYY-MM-DD') + '&tglakhir=' + this.tgl2.format('YYYY-MM-DD')
        : ''
      var imbuhan2 = this.tgl1 !== null && this.tgl1 !== '' && this.tgl2 !== null && this.tgl2 !== '' ? this.idsupplier !== null && this.idsupplier !== '' ? '&supplier_id=' + this.idsupplier : '' : this.idsupplier !== null && this.idsupplier !== '' ? '?supplier_id=' + this.idsupplier : ''
      var res = await lou.customUrlGet2('laporan/pembelian/supplier' + imbuhan + imbuhan2)
      if (res) {
        this.data = res.data
        // console.log('this.data', this.data)
        setTimeout(() => {
          this.loading = false
        }, 100)
      } else {
        setTimeout(() => {
          this.loading = false
        }, 100)
      }
    },

    // Modal Func
    showModal(action, data) {
      this.getDataMaster()
      // this.amodalvisible = true
      this.action = action
      if (action === 'update') {
        // this.modalInput = this.$g.clone(data)
        this.editdata = data
      }
    },
    async getDataMaster() {
      var resbarang = await lou.customUrlGet2('transaksi/sales_order/barang')
      var rescustomer = await lou.customUrlGet2('transaksi/sales_order/customer')
      var ressupplier = await lou.customUrlGet2('transaksi/sales_order/supplier')
      if (resbarang !== null) {
        this.masterData.barang = []
        resbarang.data.forEach(element => {
          var ret = {
            ...element,
            label: element.kode + ' - ' + element.nama + ' - ' + element.harga,
          }
          this.masterData.barang.push(ret)
        })
      } else {
        lou.shownotif('Error!', 'Master Data Barang failed to fetch from API.', 'error')
      }
      if (rescustomer !== null) {
        this.masterData.Customer = []
        rescustomer.data.forEach(element => {
          var ret = {
            ...element,
            label: element.nama_perusahaan + ' - ' + element.nama_kontak,
          }
          this.masterData.Customer.push(ret)
        })
      } else {
        lou.shownotif('Error!', 'Master Data Customer failed to fetch from API.', 'error')
      }
      if (ressupplier !== null) {
        this.masterData.supplier = ressupplier.data
      } else {
        lou.shownotif('Error!', 'Master Data Supplier failed to fetch from API.', 'error')
      }
    },

    // ag-grid function
    onGridReady(params) {
      // params.api.sizeColumnsToFit()
      this.gridApi = params.api
      this.gridColumnApi = params.columnApi
    },
    onCellValueChanged(params) {
      var undoSize = params.api.getCurrentUndoSize()
      this.input.undoInput = undoSize
      this.input.undoInputdisable = undoSize < 1
      var redoSize = params.api.getCurrentRedoSize()
      this.input.redoInput = redoSize
      this.input.redoInputdisable = redoSize < 1
      if (this.mode === 'oncreate') {
        if (params.data.newdata) {
          var target = this.changedData.findIndex(
            (x) => x.id === params.data.id,
          )
          if (target < 0) {
            this.changedData.push(params.data)
          } else {
            this.changedData[target] = params.data
          }
          // console.log('this.changedData', this.changedData)
        } else {
          // eslint-disable-next-line no-redeclare
          var target = this.rowData.findIndex((x) => x.id === params.data.id)
          var backupTarget = this.backupRowData.findIndex(
            (x) => x.id === params.data.id,
          )
          // console.log('this.rowData[target] before:', this.rowData[target])
          this.rowData[target] = this.$g.clone(
            this.backupRowData[backupTarget],
          )
          // console.log('this.rowData[target] after:', this.rowData[target])
          // console.log('this.backupRowData[backupTarget]', this.backupRowData[backupTarget])
          // this.componentKey += 1
          this.changedData = []
          this.input.undoInput = 0
          this.input.undoInputdisable = true

          this.input.redoInput = 0
          this.input.redoInputdisable = true
          lou.shownotif('Anda sedang dalam mode create')
        }
      } else {
        this.mode = 'onupdate'
        // eslint-disable-next-line no-redeclare
        var targetChanged = this.changedData.findIndex(
          (x) => x.id === params.data.id,
        )
        if (targetChanged < 0) {
          // var cd = {}
          // cd.id = params.data.id
          // cd[params.column.colId] = value.value
          // console.log('cd[params.column.colId]', cd)
          // console.log('value.value', value)
          this.changedData.push(params.data)
        } else {
          this.changedData[targetChanged][params.column.colId] =
            params.newValue
        }
        // console.log('this.changedData', this.changedData)
        // this.componentKey += 1
      }
      // console.log('this.mode', this.mode)
    },
    showDeleteConfirm(deldata) {
      Modal.confirm({
        title: 'Are you sure delete this data?',
        content: "You can't prevent this!!! ",
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'No',
        onOk: async () => {
          var response = await lou.customUrlDelete2('transaksi/sales_order/' + deldata.id)
          if (response) {
            this.getAllData()
          }
        },
        onCancel: () => {
          // console.log('Cancel')
        },
      })
    },
    onSelectionChanged() {
      var selectedRows = this.gridApi.getSelectedRows()
      if (selectedRows[0].uuid !== 'totaldarisemuanya') {
        this.selectedData = selectedRows
        this.maskInput = selectedRows[0]
        this.maskInput.id = selectedRows[0].id
        this.maskInput.rekening = selectedRows[0].rekening
        this.maskInput.namarek = selectedRows[0].namarek
        this.maskInput.keterangan = selectedRows[0].keterangan
        this.maskInput.debet = selectedRows[0].debet
        this.maskInput.kredit = selectedRows[0].kredit
      } else {
        lou.shownotif('Total', 'Total akan terupdate secara otomatis.')
      }
      // console.log('selectedRows', selectedRows)
    },
    onFirstDataRendered() {
      // this.autoSizeAll(false, { autosizecolumn: true })
    },
    autoSizeAll(skipHeader, { autosizecolumn = true }) {
      if (autosizecolumn) {
        var allColumnIds = []
        // console.log('this.gridColumnApi', this.gridColumnApi)
        this.gridColumnApi.getAllColumns().forEach(function (column) {
          allColumnIds.push(column.colId)
        })
        this.gridColumnApi.autoSizeColumns(allColumnIds, skipHeader)
      } else {
        this.gridApi.sizeColumnsToFit()
      }
    },

    formatCurrency(v) {
      // console.log('v', v)
      return lou.curency(v)
    },
    handleSearch(selectedKeys, confirm, dataIndex) {
      confirm()
      this.searchText = selectedKeys[0]
      this.searchedColumn = dataIndex
    },

    handleReset(clearFilters) {
      clearFilters()
      this.searchText = ''
    },
    // For template word
    async downloadTemplate() {
      // eslint-disable-next-line no-unused-vars
      const xhr = new XMLHttpRequest()
      xhr.responseType = 'blob'
      xhr.onload = function () {
        const blob = xhr.response
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.download = 'Invoice Active Template'
        link.click()
        URL.revokeObjectURL(link.href)
      }
      xhr.open(
        'GET',
        'https://firebasestorage.googleapis.com/v0/b/jaminan.appspot.com/o/invoice.docx?alt=media&token=a999143b-effd-4f04-9ee9-3d32fdb85ccb&_gl=1*11pd68u*_ga*MTA5MTQzMjQ2Ny4xNjk2ODUyOTg1*_ga_CW55HF8NVT*MTY5OTI1NjMyMS4yLjEuMTY5OTI1Njc2Ny41NS4wLjA.',
      )
      xhr.send()
      // const storageRef = await firebase
      //   .storage()
      //   .ref('taksasi/template/' + this.user.sandibpr + 'TaksasiBPKB_Template.docx').getDownloadURL().then((url) => {
      //     // `url` is the download URL
      //     console.log(url)
      //     // This can be downloaded directly:
      //   }).catch((error) => {
      //     // Handle any errors
      //     switch (error.code) {
      //       case 'storage/object-not-found':
      //         // File doesn't exist
      //         lou.shownotif("File doesn't exist", 'File for archive ' + this.user.sandibpr + " doesn't exist", 'error')
      //         this.downloadTemplateDefault()
      //         break

      //       case 'storage/unauthorized':
      //         // User doesn't have permission to access the object
      //         lou.shownotif("User doesn't have permission to access the object")
      //         break

      //       case 'storage/canceled':
      //         // User canceled the upload
      //         lou.shownotif('User canceled the upload')
      //         break

      //       case 'storage/unknown':
      //         // Unknown error occurred, inspect the server response
      //         lou.shownotif('Unknown error occurred, inspect the server response')
      //         break
      //       default:
      //         break
      //     }
      //   })
    },
    async downloadTemplateDefault() {
      lou.shownotif(
        'Default Template',
        'Redirecting to the default template instead.',
      )
      // eslint-disable-next-line no-unused-vars
      const storageRef = await firebase
        .storage()
        .ref('invoice.docx')
        .getDownloadURL()
        .then((url) => {
          // `url` is the download URL
          // console.log(url)
          // This can be downloaded directly:
          const xhr = new XMLHttpRequest()
          xhr.responseType = 'blob'
          xhr.onload = function () {
            const blob = xhr.response
            const link = document.createElement('a')
            link.href = URL.createObjectURL(blob)
            link.download = 'Invoice Active Default Template'
            link.click()
            URL.revokeObjectURL(link.href)
          }
          xhr.open('GET', url)
          xhr.send()
        })
        .catch((error) => {
          // Handle any errors
          switch (error.code) {
            case 'storage/object-not-found':
              // File doesn't exist
              lou.shownotif("File doesn't exist")
              break

            case 'storage/unauthorized':
              // User doesn't have permission to access the object
              lou.shownotif(
                "User doesn't have permission to access the object",
              )
              break

            case 'storage/canceled':
              // User canceled the upload
              lou.shownotif('User canceled the upload')
              break

            case 'storage/unknown':
              // Unknown error occurred, inspect the server response
              lou.shownotif(
                'Unknown error occurred, inspect the server response',
              )
              break
            default:
              break
          }
        })
    },
    loadFile(url, callback) {
      PizZipUtils.getBinaryContent(url, callback)
    },
    async getImageUrl(name) {
      const storageRef = await firebase
        .storage()
        .ref('taksasi/bpkb/' + name)
        .getDownloadURL()
      return storageRef
    },
    async generateDocx(record) {
      var res = await lou.customUrlGet2(
        'setting/template/single?template_name=invoice',
      )
      NProgress.start()
      // lou.shownotif('Word Generator!', 'Generating Word File!', 'info')
      // var storageRef = ''
      // await firebase
      //   .storage()
      //   .ref('taksasi/template/' + this.user.sandibpr + 'TaksasiBPKB_Template.docx').getDownloadURL().then((url) => {
      //     // `url` is the download URL
      //     console.log(url)
      //     storageRef = url
      //   // eslint-disable-next-line handle-callback-err
      //   }).catch(async (error) => {
      //     // Handle any errors
      //     var defaultTemplateLink = await firebase
      //       .storage()
      //       .ref('invoice.docx').getDownloadURL()
      //     storageRef = defaultTemplateLink
      //     // switch (error.code) {
      //     //   case 'storage/object-not-found':
      //     //     // File doesn't exist
      //     //     lou.shownotif("File doesn't exist")
      //     //     break

      //     //   case 'storage/unauthorized':
      //     //     // User doesn't have permission to access the object
      //     //     lou.shownotif("User doesn't have permission to access the object")
      //     //     break

      //     //   case 'storage/canceled':
      //     //     // User canceled the upload
      //     //     lou.shownotif('User canceled the upload')
      //     //     break

      //     //   case 'storage/unknown':
      //     //     // Unknown error occurred, inspect the server response
      //     //     lou.shownotif('Unknown error occurred, inspect the server response')
      //     //     break
      //     //   default:
      //     //     break
      //     // }
      //   })
      // console.log('storageRef', storageRef)
      var dataSettings = []
      // var res = await lou.customUrlGet('jaminan/settings', true, true)
      // if (res) {
      //   dataSettings = res.data
      // }
      // console.log('dataSettings', dataSettings)
      if (res) {
        var that = this
        this.loadFile(res.data.url, function (error, content) {
          var dataset = {
            ...record,
            datasettings: dataSettings,
            subTotal: '',
            realDiskon: '',
            realTotal: '',
            nomorOrder: '',
            namaSupplier: '',
            tglSekarang: '',
            namaUserLogin: '',
          }
          // console.log('dataset', dataset)
          var arrpenilaian = record.details
          // var jumlahNilai = 0
          dataset.details = []
          arrpenilaian.forEach((elemental) => {
            // jumlahNilai += elemental.value
            var dset = {
              ...elemental,
              harga: lou.curency(elemental.harga.toString()),
              diskon: lou.curency(elemental.diskon.toString()),
              total: lou.curency(elemental.total.toString()),
            }
            // .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            dataset.details.push(dset)
          })

          dataset.subTotal = lou.curency(record.sub_total)
          dataset.realDiskon = lou.curency(record.diskon)
          dataset.realTotal = lou.curency(record.total)
          dataset.nomororder = record.order_no
          dataset.namaSupplier = record.nama_supplier
          dataset.namaCustomer = record.nama_customer
          dataset.alamatCustomer = record.alamat_customer
          dataset.telpCustomer = record.telp_customer
          dataset.tglSekarang = moment().locale('id').format('DD MMMM YYYY')
          dataset.namaUserLogin = that.user.name

          // Format curency
          // dataset.jumlahnilai = lou.curency(jumlahNilai)

          // Settings Mengubah Disini
          // var itarget = dataset.datasettings.findIndex(x => x.kode === 'namaKepalaBagian')
          // dataset.namaKepalaBagian = dataset.datasettings[itarget].keterangan

          // console.log('dataset', dataset.namaKepalaBagian)
          // console.log('dataset', dataset)
          var opts = {}
          opts.centered = false
          // opts.getImage = function (tagValue, tagName) {
          //   // console.log('tagValue', tagValue)
          //   return new Promise(function (resolve, reject) {
          //     PizZipUtils.getBinaryContent(tagValue, function (error, content) {
          //       // console.log('content', content)
          //       if (error) {
          //         // console.log('error', error)
          //         return reject(error)
          //       }
          //       return resolve(content)
          //     })
          //   })
          // }
          // opts.getSize = function (img, tagValue, tagName) {
          //   // return new Promise(function (
          //   //   resolve,
          //   //   reject,
          //   // ) {
          //   //   const image = new Image()
          //   //   image.src = tagValue
          //   //   image.onload = function () {
          //   //     console.log('image.width', image.width)
          //   //     console.log('image.height', image.height)
          //   //     resolve([
          //   //       image.width * (85 / 100),
          //   //       image.height,
          //   //     ])
          //   //   }
          //   //   image.onerror = function (e) {
          //   //     console.log(
          //   //       'img, tagValue, tagName : ',
          //   //       img,
          //   //       tagValue,
          //   //       tagName,
          //   //     )
          //   //     alert(
          //   //       'An error occured while loading ' +
          //   //                             tagValue,
          //   //     )
          //   //     reject(e)
          //   //   }
          //   // })
          //   // FOR FIXED SIZE IMAGE :
          //   return [345.6, 460.8]
          // }
          // eslint-disable-next-line no-unused-vars
          // var imageModule = new ImageModule(opts)
          if (error) {
            throw error
          }
          const zip = new PizZip(content)
          const doc = new Docxtemplater()
            .loadZip(zip)
            // .attachModule(imageModule)
            .compile()

          // render the document (replace all occurences of {first_name} by John, {last_name} by Doe, ...)
          doc.renderAsync(dataset).then(() => {
            // console.log('ready')
            // doc.render()
            const out = doc.getZip().generate({
              type: 'blob',
              mimeType:
                'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            })
            lou.shownotif(
              'Generator success!',
              'Generate word success without problem!',
              'success',
            )
            NProgress.done()
            // Output the document using Data-URI
            saveAs(out, 'Invoice ' + record.order_no + '.docx')
          })
        })
        this.getAllData()
      } else {
        lou.shownotif(
          'Template error!',
          'Data template Invoice tidak ditemukan.',
          'error',
        )
      }
    },
    async generateDocxSuratJalan(record) {
      NProgress.start()
      var res = await lou.customUrlGet2(
        'setting/template/single?template_name=surat_jalan',
      )
      // lou.shownotif('Word Generator!', 'Generating Word File!', 'info')
      // var storageRef = ''
      // await firebase
      //   .storage()
      //   .ref('taksasi/template/' + this.user.sandibpr + 'TaksasiBPKB_Template.docx').getDownloadURL().then((url) => {
      //     // `url` is the download URL
      //     console.log(url)
      //     storageRef = url
      //   // eslint-disable-next-line handle-callback-err
      //   }).catch(async (error) => {
      //     // Handle any errors
      //     var defaultTemplateLink = await firebase
      //       .storage()
      //       .ref('invoice.docx').getDownloadURL()
      //     storageRef = defaultTemplateLink
      //     // switch (error.code) {
      //     //   case 'storage/object-not-found':
      //     //     // File doesn't exist
      //     //     lou.shownotif("File doesn't exist")
      //     //     break

      //     //   case 'storage/unauthorized':
      //     //     // User doesn't have permission to access the object
      //     //     lou.shownotif("User doesn't have permission to access the object")
      //     //     break

      //     //   case 'storage/canceled':
      //     //     // User canceled the upload
      //     //     lou.shownotif('User canceled the upload')
      //     //     break

      //     //   case 'storage/unknown':
      //     //     // Unknown error occurred, inspect the server response
      //     //     lou.shownotif('Unknown error occurred, inspect the server response')
      //     //     break
      //     //   default:
      //     //     break
      //     // }
      //   })
      // console.log('storageRef', storageRef)
      var dataSettings = []
      // var res = await lou.customUrlGet('jaminan/settings', true, true)
      // if (res) {
      //   dataSettings = res.data
      // }
      // console.log('dataSettings', dataSettings)
      if (res) {
        var that = this
        this.loadFile(res.data.url, function (error, content) {
          var dataset = {
            ...record,
            datasettings: dataSettings,
            subTotal: '',
            realDiskon: '',
            realTotal: '',
            nomorOrder: '',
            namaSupplier: '',
            tglSekarang: '',
            namaUserLogin: '',
          }
          // console.log('dataset', dataset)
          var arrpenilaian = record.details
          // var jumlahNilai = 0
          dataset.details = []
          arrpenilaian.forEach((elemental, i) => {
            // jumlahNilai += elemental.value
            var dset = {
              ...elemental,
              no: i + 1,
              harga: lou.curency(elemental.harga.toString()),
              diskon: lou.curency(elemental.diskon.toString()),
              total: lou.curency(elemental.total.toString()),
            }
            // .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            dataset.details.push(dset)
          })

          dataset.subTotal = lou.curency(record.sub_total)
          dataset.realDiskon = lou.curency(record.diskon)
          dataset.realTotal = lou.curency(record.total)
          dataset.nomororder = record.order_no
          dataset.namaSupplier = record.nama_supplier
          dataset.namaCustomer = record.nama_customer
          dataset.alamatCustomer = record.alamat_customer
          dataset.telpCustomer = record.telp_customer
          dataset.tglSekarang = moment().locale('id').format('DD MMMM YYYY')
          dataset.namaUserLogin = that.user.name

          // Format curency
          // dataset.jumlahnilai = lou.curency(jumlahNilai)

          // Settings Mengubah Disini
          // var itarget = dataset.datasettings.findIndex(x => x.kode === 'namaKepalaBagian')
          // dataset.namaKepalaBagian = dataset.datasettings[itarget].keterangan

          // console.log('dataset', dataset.namaKepalaBagian)
          // console.log('dataset', dataset)
          var opts = {}
          opts.centered = false
          // opts.getImage = function (tagValue, tagName) {
          //   // console.log('tagValue', tagValue)
          //   return new Promise(function (resolve, reject) {
          //     PizZipUtils.getBinaryContent(tagValue, function (error, content) {
          //       // console.log('content', content)
          //       if (error) {
          //         // console.log('error', error)
          //         return reject(error)
          //       }
          //       return resolve(content)
          //     })
          //   })
          // }
          // opts.getSize = function (img, tagValue, tagName) {
          //   // return new Promise(function (
          //   //   resolve,
          //   //   reject,
          //   // ) {
          //   //   const image = new Image()
          //   //   image.src = tagValue
          //   //   image.onload = function () {
          //   //     console.log('image.width', image.width)
          //   //     console.log('image.height', image.height)
          //   //     resolve([
          //   //       image.width * (85 / 100),
          //   //       image.height,
          //   //     ])
          //   //   }
          //   //   image.onerror = function (e) {
          //   //     console.log(
          //   //       'img, tagValue, tagName : ',
          //   //       img,
          //   //       tagValue,
          //   //       tagName,
          //   //     )
          //   //     alert(
          //   //       'An error occured while loading ' +
          //   //                             tagValue,
          //   //     )
          //   //     reject(e)
          //   //   }
          //   // })
          //   // FOR FIXED SIZE IMAGE :
          //   return [345.6, 460.8]
          // }
          // eslint-disable-next-line no-unused-vars
          // var imageModule = new ImageModule(opts)
          if (error) {
            throw error
          }
          const zip = new PizZip(content)
          const doc = new Docxtemplater()
            .loadZip(zip)
            // .attachModule(imageModule)
            .compile()

          // render the document (replace all occurences of {first_name} by John, {last_name} by Doe, ...)
          doc.renderAsync(dataset).then(() => {
            // console.log('ready')
            // doc.render()
            const out = doc.getZip().generate({
              type: 'blob',
              mimeType:
                'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            })
            lou.shownotif(
              'Generator success!',
              'Generate word success without problem!',
              'success',
            )
            NProgress.done()
            // Output the document using Data-URI
            saveAs(out, 'Surat Jalan ' + record.order_no + '.docx')
          })
        })
        this.getAllData()
      } else {
        lou.shownotif(
          'Template error!',
          'Data template Surat Jalan tidak ditemukan.',
          'error',
        )
      }
    },
    async getExDocument() {
      // await lou.getDocument('taksasibpkb', '')
      this.downloadTemplate()
    },
    showUpload() {
      this.$refs.file.click()
    },
    async handleFileUpload() {
      this.file = this.$refs.file.files[0]
      // console.log('this.$refs.file.files[0]', this.$refs.file.files[0])
      // const formData = new FormData()
      // formData.append('template', this.file)
      // await lou.uploadDocument('taksasibpkb', formData)
      this.uploadNewTemplate()
    },
    async uploadNewTemplate() {
      if (this.file !== null) {
        NProgress.start()
        const storageRef = firebase
          .storage()
          .ref(
            'taksasi/template/' +
              this.user.sandibpr +
              'TaksasiBPKB_Template.docx',
          )
          .put(this.file) // File
        storageRef.on(
          'state_changed',
          (snapshot) => {
            this.uploadvalue =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          },
          (error) => {
            // console.log('error.message', error.message)
            lou.shownotif('Error!', 'Template gagal diperbarui!', 'error')
          },
          () => {
            // this.uploadvalue = 100
            // console.log('url', url)
            storageRef.snapshot.ref.getDownloadURL().then((url) => {
              if (url) {
                this.file = null
                NProgress.done()
                lou.shownotif(
                  'Success',
                  'Template telah berhasil diperbarui!',
                  'success',
                )
              }
              // this.urlGambar.push({ key: element.from, link: url })
              // if (this.urlGambar.length === this.input.uploadbpkb.length) {
              //   this.simpan()
              // }
              return url
            })
          },
        )
      }
    },
  },
}
</script>

<style lang="scss" scoped></style>
