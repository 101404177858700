<template>
  <div>
    <a-card title="Cetak Buku Tabungan">
      <div class="row" style="padding: 10px;">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
          <div class="row">
            <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
              <div class="row">
                <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3">
                  <label for="betweenTgl">No. Rekening: </label>
                </div>
                <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9">
                  <cleave
                    :readOnly="false"
                    :options="{ delimiter: '.', blocks: [2, 2, 6] }"
                    class="ant-input"
                    :key="componentKey"
                    :raw="false"
                    style="width: 150px;"
                    v-model.lazy="norekening"
                    placeholder="Ketik No Rekening"
                    v-on:keydown.enter.native="checkRekening"
                  />
                  <!-- <a-select
                    label-in-value
                    show-search
                    :value="selectedRekening"
                    placeholder="Nomor Rekening"
                    style="width: 150px;"
                    :filter-option="false"
                    :not-found-content="fetching ? undefined : null"
                    @search="fetchUser"
                    @change="(e) => handleChange(e, 'selectedRekening')"
                  >
                    <a-spin v-if="fetching" slot="notFoundContent" size="small" />
                    <a-select-option v-for="(d, index) in dataRekening" :key="index">
                      {{ d.rekening + ' - ' + d.nama }}
                      {{ d.rekening }}
                    </a-select-option>
                  </a-select> -->
                <a-button
                  @click="carinasabahvisible = !carinasabahvisible"
                  class="ml-2 btn btn-outline-primary"
                >
                  <a-icon type="search"></a-icon> Cari Nasabah</a-button
                >
                </div>
              </div>
            </div>
            <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
              <label for="saldoAwal">Saldo Awal: </label>
              <a-input class="ml-2" style="width: 200px; text-align: right;" v-model="modalCetakBuku.saldoAwal" :readOnly="true"></a-input>
            </div>
          </div>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-1">
          <div class="row">
            <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
              <div class="row">
                <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3">
                  <label for="betweenTgl">Antara Tanggal: </label>
                </div>
                <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9">
                  <a-date-picker
                    class="mr-2"
                    style="width: 100px;"
                    v-model="modalCetakBuku.tgl1"
                    :allowClear="false"
                    format="DD-MM-YYYY"
                    @change="(dateMoment, dateString) => changeDateInput(dateMoment, dateString, ['modalCetakBuku', 'tgl1'])"
                  />
                  -
                  <a-date-picker
                    class="ml-2"
                    style="width: 100px;"
                    v-model="modalCetakBuku.tgl2"
                    :allowClear="false"
                    format="DD-MM-YYYY"
                    @change="(dateMoment, dateString) => changeDateInput(dateMoment, dateString, ['modalCetakBuku', 'tgl2'])"
                  />
                </div>
              </div>
            </div>
            <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
              <label for="saldoAwal">Saldo Akhir: </label>
              <a-input class="ml-2" style="width: 200px; text-align: right;" v-model="modalCetakBuku.saldoAkhir" :readOnly="true"></a-input>
            </div>
          </div>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-1">
          <div class="row">
            <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
              <div class="row">
                <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3">
                  <label for="betweenTgl">Nama: </label>
                </div>
                <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9">
                  <a-input class="" style="width: 300px;" v-model="namaprekening" :readOnly="true"></a-input>
                </div>
              </div>
            </div>
            <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
              <label for="saldoAwal">Baris Cetak: </label>
              <a-input class="ml-2" style="width: 40px;" v-model="modalCetakBuku.barisPencetakan"></a-input>
            </div>
          </div>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-1">
          <div class="row">
            <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
              <div class="row">
                <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3">
                  <label for="betweenTgl">Alamat: </label>
                </div>
                <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9">
                  <a-input class="" style="width: 300px;" v-model="alamatprekening" :readOnly="true"></a-input>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-3">
          <a-button :class="modalCetakBuku.selectallmode ? 'btn btn-outline-primary btn-sm mb-2' : 'btn btn-outline-danger btn-sm mb-2'" @click="selectAllModalCetak" :disabled="modalCetakBuku.datatable.length === 0">{{modalCetakBuku.selectallmode ? 'Select All' : 'Diselect All'}}</a-button>
          <a-button class="btn btn-outline-success btn-sm mb-2 ml-2" :disabled="modalCetakBuku.datatable.length === 0" @click="exportPdf">
            Cetak Buku
          </a-button>
          <a-button class="btn btn-outline-warning btn-sm mb-2 ml-2" :disabled="modalCetakBuku.datatable.length === 0" @click="exportPdfHeader">
            Cetak Header
          </a-button>
          <a-table
            :columns="modalCetakBuku.columns"
            :dataSource="modalCetakBuku.datatable"
            size="small"
            :key="componentKeyDatatable"
            :scroll="{ y: 200 }"
            :pagination="{
              hideOnSinglePage: true,
              defaultPageSize: 200,
              showQuickJumper: true,
              showSizeChanger: true,
              showTotal: (total) => `Total ${total} items`,
              pageSizeOptions: ['100', '200', '300'],
            }"
          >
            <div
              slot="filterDropdown"
              slot-scope="{
                setSelectedKeys,
                selectedKeys,
                confirm,
                clearFilters,
                column,
              }"
              style="padding: 8px;"
            >
              <a-input
                v-ant-ref="(c) => (searchInput = c)"
                :placeholder="`Search ${column.dataIndex}`"
                :value="selectedKeys[0]"
                style="width: 188px; margin-bottom: 8px; display: block;"
                @change="
                  (e) => setSelectedKeys(e.target.value ? [e.target.value] : [])
                "
                @pressEnter="
                  () => handleSearch(selectedKeys, confirm, column.dataIndex)
                "
              />
              <a-button
                type="primary"
                icon="search"
                size="small"
                style="width: 90px; margin-right: 8px;"
                @click="
                  () => handleSearch(selectedKeys, confirm, column.dataIndex)
                "
              >
                Search
              </a-button>
              <a-button
                size="small"
                style="width: 90px;"
                @click="() => handleReset(clearFilters)"
              >
                Reset
              </a-button>
            </div>
            <a-icon
              slot="filterIcon"
              slot-scope="filtered"
              type="search"
              :style="{ color: filtered ? '#108ee9' : undefined }"
            />
            <template
              slot="customRender"
              slot-scope="text, record, index, column"
            >
              <span v-if="searchText && searchedColumn === column.dataIndex">
                <template
                  v-for="(fragment, i) in text
                    .toString()
                    .split(
                      new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i')
                    )"
                >
                  <mark
                    v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                    :key="i"
                    class="highlight"
                    >{{ fragment }}</mark
                  >
                  <template v-else>{{ fragment }}</template>
                </template>
              </span>
              <template v-else>
                {{ text }}
              </template>
            </template>
            <!-- <template slot="mutasi" slot-scope="text, record">
              {{ record.debit === 0 ? formatCurrency(record.kredit) : formatCurrency(record.debit) }}
            </template> -->
            <template slot="currencyFormatter" slot-scope="text">
              {{ formatCurrency(text)}}
            </template>
            <template slot="saldo" slot-scope="text, record">
              {{ formatCurrency(record.saldo) }}
            </template>
            <template slot="selected" slot-scope="text, record">
              <a-checkbox :key="componentKey" :checked="record.selected" @change="(e) => changedSelectedModal(e, record)" />
            </template>
          </a-table>
        </div>
      </div>
    </a-card>
    <a-modal
      v-model="carinasabahvisible"
      :dialogStyle="{ top: '10px' }"
      :footer="null"
      :width="800"
    >
      <template slot="title">
        <strong>{{ carinasabahtitle }} </strong>
        <label style="margin: unset" v-if="modalselectedRekening !== ''">
          | Active Rekening: {{ modalselectedRekening }}</label
        >
      </template>
      <!-- <template slot="footer">
          <a-button class="btn btn-outline-dark">Cancel</a-button>
          <a-button class="btn btn-outline-primary">Process</a-button>
        </template> -->
      <div class="row">
        <a-input
          class="ml-3"
          placeholder="Ketik Nama"
          v-model="input.nama"
          style="width: 200px"
          @keydown.enter="findData"
        ></a-input>
        <!-- <a-input
          class="ml-2"
          placeholder="Ketik Alamat"
          v-model="input.alamat"
          style="width: 200px"
          @keydown.enter="findData"
        ></a-input>
        <a-select
          style="width: 70px"
          placeholder="Kode"
          class="ml-2"
          v-model="input.cabang"
        >
          <a-select-option value="01">01</a-select-option>
        </a-select> -->
        <a-button
          v-if="modalselectedRekening !== ''"
          class="btn btn-outline-danger btn-sm ml-2"
          @click="clearRekening"
          >Clear Selected Rekening</a-button
        >
        <a-divider type="horizontal" style="margin: 8px 8px" />
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
          <label
            v-if="
              table.tableatas.datatable.length !== null &&
              table.tableatas.datatable.length !== 0
            "
            class="text-primary"
            style="
              margin-left: 0.5rem;
              margin-top: 0.5rem;
              margin-bottom: unset !important;
            "
            >Jumlah Data: {{ table.tableatas.datatable.length }}</label
          >
          <a-table
            :scroll="{ y: 300 }"
            :columns="table.tableatas.column"
            :dataSource="table.tableatas.datatable"
            :pagination="false"
            size="small"
            :customRow="customRow"
          >
          <template slot="actionButton" slot-scope="index, record">
            <a-button class="btn btn-outline-primary" @click="selectModalTable1(record)">Pilih</a-button>
          </template>
          </a-table>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-2">
          <label
            v-if="
              table.tablebawah.datatable.length !== null &&
              table.tablebawah.datatable.length !== 0
            "
            class="text-primary"
            style="
              margin-left: 0.5rem;
              margin-top: 0.5rem;
              margin-bottom: unset !important;
            "
            >Jumlah Data: {{ table.tablebawah.datatable.length }}</label
          >
          <a-table
            :scroll="{ y: 200 }"
            :columns="table.tablebawah.column"
            :dataSource="table.tablebawah.datatable"
            :pagination="false"
            size="small"
            :customRow="customRowB"
          >
            <span slot="saldo" slot-scope="text">
              {{ formatCurrency(text) }}
            </span>
          <template slot="actionButton" slot-scope="index, record">
            <a-button class="btn btn-outline-primary" @click="selectModalTable2(record)">Pilih</a-button>
          </template>
          </a-table>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import * as lou from '@/services/data/lou'
import debounce from 'lodash/debounce'
import moment from 'moment'
import { mapState } from 'vuex'
import JSPDF from 'jspdf'
import Cleave from 'vue-cleave-component'

export default {
  components: {
    cleave: Cleave,
  },
  computed: {
    ...mapState(['settings', 'user']),
  },
  watch: {
    'settings.selectedRekening'() {
      this.norekening = this.settings.selectedRekening
      this.checkRekening()
    },
  },
  created () {
    if (this.settings.selectedRekening !== '') {
      this.norekening = this.settings.selectedRekening
      this.checkRekening()
    }
  },
  data() {
    this.lastFetchId = 0
    this.fetchUser = debounce(this.fetchUser, 800)
    return {
      dataRekening: [],
      modalCetakBuku: {
        responseAPI: null,
        changedData: [],
        visible: false,
        tgl1: moment(),
        tgl2: moment(),
        noRekening: '',
        nama: '',
        alamat: '',
        saldoAwal: '',
        saldoAkhir: '',
        barisPencetakan: 0,
        selectallmode: true,
        columns: [
          {
            title: '#',
            width: 50,
            dataIndex: 'selected',
            scopedSlots: {
              customRender: 'selected',
            },
          },
          {
            title: 'Tanggal',
            dataIndex: 'tgl',
            scopedSlots: {
              filterDropdown: 'filterDropdown',
              filterIcon: 'filterIcon',
              customRender: 'customRender',
            },
            onFilter: (value, record) =>
              record.tgl.toString().toLowerCase().includes(value.toLowerCase()),
            onFilterDropdownVisibleChange: (visible) => {
              if (visible) {
                setTimeout(() => {
                  this.searchInput.focus()
                }, 0)
              }
            },
          },
          {
            title: 'Sandi',
            dataIndex: 'sandi',
            scopedSlots: {
              filterDropdown: 'filterDropdown',
              filterIcon: 'filterIcon',
              customRender: 'customRender',
            },
            onFilter: (value, record) =>
              record.sandi.toString().toLowerCase().includes(value.toLowerCase()),
            onFilterDropdownVisibleChange: (visible) => {
              if (visible) {
                setTimeout(() => {
                  this.searchInput.focus()
                }, 0)
              }
            },
          },
          // {
          //   title: 'Mutasi',
          //   dataIndex: 'mutasi',
          //   scopedSlots: {
          //     customRender: 'mutasi',
          //   },
          //   align: 'right',
          // },
          {
            title: 'Debit',
            dataIndex: 'debit',
            scopedSlots: {
              customRender: 'debit',
            },
            align: 'right',
          },
          {
            title: 'Kredit',
            dataIndex: 'kredit',
            scopedSlots: {
              customRender: 'formatCurrency',
            },
            align: 'right',
          },
          {
            title: 'Saldo',
            dataIndex: 'saldo',
            scopedSlots: {
              customRender: 'formatCurrency',
            },
            align: 'right',
          },
          // {
          //   title: 'Username',
          //   dataIndex: 'username',
          //   scopedSlots: {
          //     filterDropdown: 'filterDropdown',
          //     filterIcon: 'filterIcon',
          //     customRender: 'customRender',
          //   },
          //   onFilter: (value, record) =>
          //     record.username.toString().toLowerCase().includes(value.toLowerCase()),
          //   onFilterDropdownVisibleChange: (visible) => {
          //     if (visible) {
          //       setTimeout(() => {
          //         this.searchInput.focus()
          //       }, 0)
          //     }
          //   },
          // },
        ],
        datatable: [],
      },
      selectedRekening: [],
      selectedName: [],
      tanggalTransaksi: moment(),
      norekening: '',
      namaprekening: '',
      alamatprekening: '',
      searchText: '',
      searchInput: null,
      searchedColumn: '',
      fetching: false,
      componentKey: 0,
      componentKeyDatatable: 0,

      // Modal cari nasabah property
      carinasabahvisible: false,
      carinasabahtitle: 'Cari Nasabah',
      modalselectedRekening: '',
      input: {
        nama: '',
        alamat: '',
        cabang: '',
      },
      table: {
        tableatas: {
          column: [
            {
              title: 'Kode',
              rowKey: 'kode',
              dataIndex: 'kode',
              scopedSlots: { customRender: 'kode' },
              width: 100,
            },
            {
              title: 'Nama',
              dataIndex: 'nama',
              scopedSlots: { customRender: 'nama' },
              width: 150,
            },
            // {
            //   title: 'KTP',
            //   dataIndex: 'ktp',
            //   scopedSlots: { customRender: 'ktp' },
            //   width: 100,
            // },
            {
              title: 'Alamat',
              dataIndex: 'alamat',
              scopedSlots: { customRender: 'alamat' },
              // width: 100,
            },
            // {
            //   title: '#',
            //   scopedSlots: { customRender: 'actionButton' },
            //   width: 70,
            // },
          ],
          datatable: [],
        },
        tablebawah: {
          column: [
            {
              title: 'Jenis',
              dataIndex: 'jenis',
              scopedSlots: { customRender: 'jenis' },
              // width: 100,
            },
            {
              title: 'Rekening',
              dataIndex: 'rekening',
              scopedSlots: { customRender: 'rekening' },
              // width: 100,
            },
            {
              title: 'Saldo',
              dataIndex: 'saldo',
              scopedSlots: { customRender: 'saldo' },
              // width: 100,
            },
            {
              title: '#',
              scopedSlots: { customRender: 'actionButton' },
              // width: 70,
            },
          ],
          datatable: [],
        },
      },
      loading: false,
    }
  },
  methods: {
    // Modal Cari Nasabah Function
    clearRekening() {
      this.modalselectedRekening = ''
    },
    async findData() {
      // console.log('this.formData.rekening', this.formData.rekening)
      var imbuhan = ''
      imbuhan += this.input.nama !== '' ? '?q=' + this.input.nama : ''
      imbuhan +=
        this.input.alamat !== ''
          ? this.input.nama !== ''
            ? '&Alamat=' + this.input.alamat
            : '?Alamat=' + this.input.alamat
          : ''
      imbuhan += this.input.cabang !== '' ? '&cabang=' + this.input.cabang : ''
      var res = await lou.customUrlGet2('transaksi/simpanan/cetakbuku/cari_nama' + imbuhan, false)
      if (res) {
        if (res.data.length === 0) {
          lou.shownotif('Info', 'Data tidak ditemukan!', 'warning')
          this.table.tableatas.datatable = []
        } else {
          lou.shownotif('Info', 'Ditemukan ' + res.data.length + ' data yang cocok!', 'success')
          this.table.tableatas.datatable = res.data
        }
        // for (let i = 0; i < 20; i++) {
        //   const element = res.data[0]
        //   this.table.tableatas.datatable.push(element)
        // }
      }
    },
    async nasabahDetail(record) {
      if (!this.loading) {
        this.loading = true
        var res = await lou.customUrlGet2('transaksi/simpanan/cetakbuku/cari_rekening?kode=' + record.kode, false)
        if (res) {
          if (res.data.length === 0) {
            lou.shownotif('Info', 'Data tidak ditemukan!', 'warning')
            this.table.tablebawah.datatable = []
          } else {
            lou.shownotif('Info', 'Ditemukan ' + res.data.length + ' data yang cocok!', 'success')
            this.table.tablebawah.datatable = res.data
          }
          this.listKode = this.$g.clone(res.data)
          this.backuplistKode = this.$g.clone(res.data)
          this.loading = false
        }
        this.loading = false
      }
    },
    selectModalTable1(record) {
      this.selectedKodeRegister = record.kode
      // this.formData.kode = record.kode
      this.maskInput.nama = record.nama
      this.nasabahDetail(record)
      this.carinasabahvisible = false
    },
    selectModalTable2(record) {
      // console.log('record', record)
      // this.selectedKodeRegister = record.kode
      // this.formData.kode = record.kode
      this.modalselectedRekening = record.rekening
      // this.formData.rekening = record.rekening
      this.carinasabahvisible = false
    },
    customRow(record, index) {
      return {
        on: {
          click: (event) => {
            // console.log('record', record)
            // console.log('index', index)
            // console.log('event', event)
            // this.selectedKodeRegister = record.kode
            // this.formData.kode = record.kode
            // this.maskInput.nama = record.nama
            this.nasabahDetail(record)
          },
          contextmenu: (event) => {
            event.preventDefault()
          },
        },
      }
    },
    customRowB(record, index) {
      return {
        on: {
          click: (event) => {
            // this.selectedKodeRegister = record.kode
            // this.formData.kode = record.kode
            // console.log('record', record)
            this.modalselectedRekening = record.rekening
            this.norekening = record.rekening
            this.checkRekening()
            // console.log('index', index)
            // console.log('event', event)
            // console.log('this.modalselectedRekening', this.modalselectedRekening)
            // this.formData.rekening = record.rekening
          },
          contextmenu: (event) => {
            event.preventDefault()
          },
        },
      }
    },
    // End of Modal Cari Nasabah Function

    async checkRekening() {
      if (this.norekening.length === 12) {
      // console.log('this.norekening', this.norekening)
        var imbuhan = '?rekening=' + this.norekening.toString()
        if (this.modalCetakBuku.tgl1 !== null) {
          // console.log('this.modalCetakBuku.tgl1', this.modalCetakBuku.tgl1)
          imbuhan += '&tglawal=' + this.modalCetakBuku.tgl1.format('YYYY-MM-DD')
        }
        if (this.modalCetakBuku.tgl2 !== null) {
          // console.log('this.modalCetakBuku.tgl2', this.modalCetakBuku.tgl2)
          imbuhan += '&tglakhir=' + this.modalCetakBuku.tgl2.format('YYYY-MM-DD')
        }
        var res = await lou.customUrlGet2('transaksi/simpanan/cetakbuku' + imbuhan)
        if (res) {
          this.modalCetakBuku.selectallmode = true
          this.modalCetakBuku.changedData = []
          this.modalCetakBuku.nama = res.data.nama
          this.modalCetakBuku.alamat = res.data.alamat
          this.modalCetakBuku.responseAPI = res.data
          this.modalCetakBuku.datatable = res.data.detail
          this.modalCetakBuku.saldoAwal = lou.curency(this.modalCetakBuku.responseAPI.saldoAwal)
          this.modalCetakBuku.saldoAkhir = lou.curency(this.modalCetakBuku.responseAPI.saldoAkhir)
          this.modalCetakBuku.barisPencetakan = this.modalCetakBuku.responseAPI.barisPencetakan
          this.modalCetakBuku.datatable.forEach((element) => {
            element.selected = element.printed === '0'
          })
          // lou.shownotif('Ditemukan!', 'Data telah ditemukan!', 'info')
          this.dataRekening = res.data
          // console.log('this.dataRekening', this.dataRekening)
          // var value = this.dataRekening.findIndex(x => x.rekening === this.norekening)
          var dataTarget = this.dataRekening
          this.selectedName = { key: dataTarget.rekening, label: dataTarget.nama }
          this.selectedRekening = { key: dataTarget.rekening, label: dataTarget.rekening }
          this.rekeningjurnal = dataTarget.rekening
          this.norekening = dataTarget.rekening
          this.namaprekening = dataTarget.nama
          this.alamatprekening = dataTarget.alamat
          this.saldoprekening = dataTarget.saldo
          // console.log('this', this)
          this.fetching = false
          // console.log('dataTarget', dataTarget)
          // Object.assign(this, data)
          // this.getDatatableModalBuku()
          // this.checkRekening()
          // if (res.data.length !== 0) {
          // } else {
          //   lou.shownotif('Tidak di temukan', 'Tidak ada data dengan rekening tersebut!', 'error')
          // }
        } else {
        // lou.shownotif('Tidak di temukan', 'Tidak ada data dengan rekening tersebut!', 'error')
        }
      }
    },
    changedSelectedModal(e, record) {
    // console.log('record', record.selected)
    // console.log('e', e)
      var checked = e.target.checked
      // console.log('record', record.selected)
      record.selected = checked
      record.checked = checked
      var target = this.modalCetakBuku.changedData.findIndex((x) => x.id === record.id)
      if (target < 0) {
        this.modalCetakBuku.changedData.push(record)
      } else {
        if (checked) {
          this.modalCetakBuku.changedData[target].selected = checked
        } else {
          this.modalCetakBuku.changedData.splice(target, 1)
        }
      }
      this.componentKey++
    // console.log('this.modalCetakBuku.changedData', this.modalCetakBuku.changedData)
    // console.log('checked', checked)
    },
    changeDateInput(dateMoment, dateString, from) {
      if (dateMoment !== null && from[0] === 'modalCetakBuku') {
        // this.getDatatableModalBuku()
        this.checkRekening()
      }
    },
    async getDatatableModalBuku() {
      var params = {
        rekening: this.norekening,
        tgl_awal: this.modalCetakBuku.tgl1.format('YYYY-MM-DD'),
        tgl_akhir: this.modalCetakBuku.tgl2.format('YYYY-MM-DD'),
      }
      var res = await lou.customUrlPost2('transaksi/simpanan/cetakbuku', params, false)
      if (res) {
      }
    },
    handleChange(value, from) {
    // console.log('value', value)
      if (value.key !== null && value.key !== undefined) {
        var dataTarget = this.dataRekening[value.key]
        // var data = {
        //   selectedName: value,
        //   selectedRekening: value,
        //   rekeningjurnal: dataTarget.rekening,
        //   norekening: dataTarget.rekening,
        //   namaprekening: dataTarget.nama,
        //   fetching: false,
        //   // saldoprekening: dataTarget.saldo,
        //   // dataRekening: [],
        // }
        // Object.assign(this, data)
        this.selectedName = value
        this.selectedRekening = value
        this.rekeningjurnal = dataTarget.kode
        this.norekening = dataTarget.kode
        this.namaprekening = dataTarget.nama
        this.fetching = false
        this.componentKey += 1
        // console.log('this.norekening', this.norekening)
        this.alamatprekening = dataTarget.alamat
        this.modalCetakBuku.nama = this.namaprekening
        this.modalCetakBuku.alamat = this.alamatprekening
        // this.getDatatableModalBuku()
        this.checkRekening()
      }
    },
    selectAllModalCetak() {
      this.modalCetakBuku.datatable.forEach((element) => {
        element.selected = this.modalCetakBuku.selectallmode
      })
      if (this.modalCetakBuku.selectallmode) {
        this.modalCetakBuku.changedData = this.$g.clone(this.modalCetakBuku.datatable)
      } else {
        this.modalCetakBuku.changedData = []
      }
      this.modalCetakBuku.selectallmode = !this.modalCetakBuku.selectallmode
    },
    async generateBuku() {
      this.modalCetakBuku.changedData = []
      this.modalCetakBuku.datatable.forEach(element => {
        if (element.selected) {
          this.modalCetakBuku.changedData.push(element)
        }
      })
      if (this.modalCetakBuku.changedData.lenght === 0) {
        lou.shownotif('Data', 'Tidak memilih data untuk di cetak!')
      } else {
        const doc = new JSPDF()
        doc.setFontSize(10)
        var leftMargin = 2
        var topMargin = 27
        // console.log('this.modalCetakBuku', this.modalCetakBuku.changedData)
        var nourut = this.modalCetakBuku.barisPencetakan !== 0 ? this.modalCetakBuku.barisPencetakan.toString() : 1
        var tambahanjarak = 5 * this.modalCetakBuku.barisPencetakan
        var jarak = 0 + tambahanjarak
        for (let i = 0; i < this.modalCetakBuku.changedData.length; i++) {
          const element = this.modalCetakBuku.changedData[i]
          // console.log('element', element)
          // console.log('nourut', nourut)
          doc.text(nourut.toString(), leftMargin, topMargin + jarak)
          doc.text(moment(element.tgl, 'YYYY-MM-DD').format('DD-MM-YYYY'), leftMargin + 8, topMargin + jarak)
          doc.text(element.sandi.toString(), leftMargin + 40, topMargin + jarak)
          // var mutasi = element.debit === 0 ? lou.curency(element.kredit) : lou.curency(element.debit)
          var debit = element.debit !== 0 ? lou.curency(element.debit) : 0
          var kredit = element.kredit !== 0 ? lou.curency(element.kredit) : 0
          doc.text(debit.toString() + '', leftMargin + 60, topMargin + jarak)
          doc.text(kredit.toString() + '', leftMargin + 80, topMargin + jarak)
          doc.text(lou.curency(element.saldo).toString() + '', leftMargin + 100, topMargin + jarak)
          // doc.text(element.username.toString(), leftMargin + 140, topMargin + jarak)
          nourut++
          jarak += 5
        }
        // var filename = 'Buku'
        doc.output('dataurlnewwindow')
        var datapost = []
        this.modalCetakBuku.changedData.forEach(element => {
          datapost.push({ id: element.id, printed: '1' })
        })
        // var res = await lou.customUrlPost2('mutasisimpanan/statuscetak', datapost)
        // if (res) {
        //   this.modalCetakBuku.datatable.forEach(element => {
        //     var targetIndex = this.modalCetakBuku.changedData.findIndex(x => x.id === element.id)
        //     if (targetIndex) {
        //       element.printed = '1'
        //     }
        //   })
        //   this.componentKeyDatatable++
        //   this.componentKey++
        //   this.modalCetakBuku.barisPencetakan += this.modalCetakBuku.changedData.length
        // }
      }
    },

    async exportPdf() {
      if (this.modalCetakBuku.changedData.length === 0) {
        lou.shownotif('Error!', 'Tidak ada data yang dipilih!', 'error')
      } else {
      // this.amodal.selectedIndex = i
      // var imbuhan =
      //   this.tgl1 !== null && this.tgl1 !== ''
      //     ? '?tgl=' + this.tgl1.format('YYYY-MM-DD')
      //     : ''
      // this.tgl1 !== null && this.tgl1 !== '' && this.tgl2 !== null && this.tgl2 !== ''
      //   ? '?tglawal=' + this.tgl1.format('YYYY-MM-DD') + '&tglakhir=' + this.tgl2.format('YYYY-MM-DD')
      //   : ''
      // var imbuhan2 = this.tgl1 !== null && this.tgl1 !== '' && this.tgl2 !== null && this.tgl2 !== '' ? this.idsupplier !== null && this.idsupplier !== '' ? '&id=' + this.idsupplier : '' : this.idsupplier !== null && this.idsupplier !== '' ? '?id=' + this.idsupplier : ''
        var fd = {
          rekening: this.norekening,
          kode: '',
          barispencetakan: this.modalCetakBuku.barisPencetakan,
          detail: [],
        }
        this.modalCetakBuku.changedData.forEach(element => {
          // console.log('element', element)
          element.checked = true
          delete element.selected
          fd.detail.push(element)
        })
        // console.log('fd', fd)
        var res = await lou.npostDocumentPdf('transaksi/simpanan/cetakbuku', fd, false, false)
        if (res) {
        // console.log("Success", response);
          const blob = new Blob([res], {
            type: 'application/pdf',
          })
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.target = '_blank'
          // link.download = filename
          link.click()
          // const objectUrl = URL.createObjectURL(blob)
          // console.log('objectUrl', objectUrl)
          // this.amodal.ArrayBuffer = objectUrl
          URL.revokeObjectURL(blob)
          setTimeout(() => {
            this.componentKey += 1
            this.checkRekening()
          // this.amodalvisible = true
          }, 500)
        }
      // console.log('record.url2_invoice', record.url2_invoice)
      // window.open(record.url2_invoice, '_blank').focus()
      }
    },
    async exportPdfHeader() {
      if (this.modalCetakBuku.changedData.length === 0) {
        lou.shownotif('Error!', 'Tidak ada data yang dipilih!', 'error')
      } else {
      // this.amodal.selectedIndex = i
        // var imbuhan =
        //   this.tgl1 !== null && this.tgl1 !== ''
        //     ? '?tgl=' + this.tgl1.format('YYYY-MM-DD')
        //     : ''
        // this.tgl1 !== null && this.tgl1 !== '' && this.tgl2 !== null && this.tgl2 !== ''
        //   ? '?tglawal=' + this.tgl1.format('YYYY-MM-DD') + '&tglakhir=' + this.tgl2.format('YYYY-MM-DD')
        //   : ''
        // var imbuhan2 = this.tgl1 !== null && this.tgl1 !== '' && this.tgl2 !== null && this.tgl2 !== '' ? this.idsupplier !== null && this.idsupplier !== '' ? '&id=' + this.idsupplier : '' : this.idsupplier !== null && this.idsupplier !== '' ? '?id=' + this.idsupplier : ''
        var fd = {
          rekening: this.norekening,
          kode: '',
          barispencetakan: this.modalCetakBuku.barisPencetakan,
          detail: [],
        }
        this.modalCetakBuku.changedData.forEach(element => {
          // console.log('element', element)
          element.checked = true
          delete element.selected
          fd.detail.push(element)
        })
        // console.log('fd', fd)
        var res = await lou.npostDocumentPdf('transaksi/simpanan/cetakbuku/header', fd, false, false)
        if (res) {
        // console.log("Success", response);
          const blob = new Blob([res], {
            type: 'application/pdf',
          })
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.target = '_blank'
          // link.download = filename
          link.click()
          // const objectUrl = URL.createObjectURL(blob)
          // console.log('objectUrl', objectUrl)
          // this.amodal.ArrayBuffer = objectUrl
          URL.revokeObjectURL(blob)
          setTimeout(() => {
            this.componentKey += 1
            this.checkRekening()
          // this.amodalvisible = true
          }, 500)
        }
      // console.log('record.url2_invoice', record.url2_invoice)
      // window.open(record.url2_invoice, '_blank').focus()
      }
    },
    async fetchUser(value) {
    // console.log('fetching user', value)
      if (value !== '') {
        this.lastFetchId += 1
        const fetchId = this.lastFetchId
        this.dataRekening = []
        this.fetching = true
        var res = false
        if (!this.hasNumber(value)) {
          var nvalue = value.replace(/[^\w\s]/gi, '')
          res = await lou.customUrlGet2('transaksi/simpanan/cetakbuku/cari_nama?q=' + nvalue)
        } else {
          var nvalue1 = value.replace(/[0-9]/g, '')
          res = await lou.customUrlGet2('transaksi/simpanan/cetakbuku/cari_rekening?kode=' + nvalue1)
        }
        if (res) {
          if (fetchId !== this.lastFetchId) {
          // for fetch callback order
            return false
          }
          this.dataRekening = res.data
          this.fetching = false
        } else {
          this.fetching = null
        }
      }
    },
    formatTanggal(v) {
      var ntgl = v.tgl.substring(0, 10)
      // console.log('ntgl', ntgl)
      return moment(ntgl, 'YYYY-MM-DD').format('DD-MM-YYYY')
    },
    hasNumber(myString) {
      return /\d/.test(myString)
    },
    formatCurrency(v) {
      var ret = lou.curency(v)
      return ret
    },
  },
}
</script>
