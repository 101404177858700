<template>
  <div>
    <a-card>

      <template slot="title">
    <strong>Order Pembelian</strong>
    <!-- v-model="tgl1" -->
    <a-range-picker
          class="ml-3 mr-2"
          style="width: 250px;"
          :allowClear="false"
          :default-value="[moment(), moment()]"
          format="DD-MM-YYYY"
          @change="filterDate"/>
        <a-button-group>
          <a-button icon="plus-circle" class="text-primary" type="" @click="sendToCreate('create', {})">
            Tambah
          </a-button>
          <a-tooltip placement="top">
            <template slot="title">
              <span>Refresh Data</span>
            </template>
            <a-button @click="getAllData(true)">
              <a-icon type="sync" :spin="loading" class="text-success"/>
            </a-button>
          </a-tooltip>
          <!-- <a-tooltip placement="top">
            <template slot="title">
              <span>Export Data</span>
            </template>
            <a-button @click="exportExcel">
              <a-icon type="file-excel" class="text-success"/>
            </a-button>
          </a-tooltip> -->
        </a-button-group>
      </template>
    <a-table
      class=""
      style="margin: -25px -25px;"
      :columns="columns"
      :dataSource="data"
      :pagination="{
        hideOnSinglePage: true,
        defaultPageSize: 10,
        showQuickJumper: true,
        showSizeChanger: true,
        showTotal: (total) => `Total ${total} items`,
        pageSizeOptions: ['10', '20', '30'],
      }"
      size="small"
      :loading="loading"
    >
      <div
        slot="filterDropdown"
        slot-scope="{
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
          column,
        }"
        style="padding: 8px"
      >
        <a-input
          v-ant-ref="(c) => (searchInput = c)"
          :placeholder="`Search ${column.title}`"
          :value="selectedKeys[0]"
          style="width: 188px; margin-bottom: 8px; display: block"
          @change="
            (e) => setSelectedKeys(e.target.value ? [e.target.value] : [])
          "
          @pressEnter="
            () => handleSearch(selectedKeys, confirm, column.dataIndex)
          "
        />
        <a-button
          type="primary"
          icon="search"
          size="small"
          style="width: 90px; margin-right: 8px"
          @click="() => handleSearch(selectedKeys, confirm, column.dataIndex)"
        >
          Search
        </a-button>
        <a-button
          size="small"
          style="width: 90px"
          @click="() => handleReset(clearFilters)"
        >
          Reset
        </a-button>
      </div>
      <a-icon
        slot="filterIcon"
        slot-scope="filtered"
        type="search"
        :style="{ color: filtered ? '#108ee9' : undefined }"
      ></a-icon>
      <template slot="customRender" slot-scope="text, record, index, column">
        <span v-if="searchText && searchedColumn === column.dataIndex">
          <template
            v-for="(fragment, i) in text
              .toString()
              .split(new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i'))"
          >
            <mark
              v-if="fragment.toLowerCase() === searchText.toLowerCase()"
              :key="i"
              class="highlight"
              >{{ fragment }}</mark
            >
            <template v-else>{{ fragment }}</template>
          </template>
        </span>
        <template v-else>
          {{ text }}
        </template>
      </template>
      <span slot="action" slot-scope="text, record">
        <a class="text-warning" @click="sendToEdit(record)">
          <a-icon type="edit"></a-icon>
        </a>
        <a-divider type="vertical" />
        <a href="javascript:void(0)" class="text-danger" @click="showDeleteConfirm(record)">
          <a-icon type="delete"></a-icon>
        </a>
        <!-- <a href="javascript:;" class="ant-dropdown-link">
            More actions <a-icon type="down" />
          </a> -->
      </span>
      <span slot="tgl" slot-scope="text, record">
        {{ formatDate(record.tgl) }}
      </span>
      <template
      :expandIconColumnIndex="3" slot="expandedRowRender" slot-scope="record" style="margin: 1">
        <a-table
          :columns="columnChildren"
          :dataSource="record.details"
          size="small"
          :pagination="{
            hideOnSinglePage: true,
            defaultPageSize: 10,
            showQuickJumper: true,
            showSizeChanger: true,
            showTotal: (total) => `Total ${total} items`,
            pageSizeOptions: ['10', '20', '30'],
          }"
        >
        <span slot="formatCurrency" slot-scope="text">
          {{ formatCurrency(text) }}
        </span>
        </a-table>
      </template>
    </a-table>
    </a-card>
    <a-modal :visible="amodalvisible" :title="'Form ' + action" :dialogStyle="{ top: '10px' }">
        <ag-grid-vue
          style="height: 41vh;"
          :class="
            settings.theme === 'dark'
              ? 'ag-theme-balham-dark mt-2'
              : 'ag-theme-balham mt-2'
          "
          :key="componentKey"
          :gridOptions="gridOptions"
          :columnDefs="columnDefs"
          :pagination="false"
          :accentedSort="false"
          :sortingOrder="['asc', 'desc']"
          :rowData="rowData"
          @grid-ready="onGridReady"
          :singleClickEdit="false"
          :undoRedoCellEditing="true"
          :undoRedoCellEditingLimit="5"
          rowSelection="single"
          :frameworkComponents="frameworkComponents"
          @first-data-rendered="onFirstDataRendered"
          @selection-changed="onSelectionChanged"
          @cell-value-changed="onCellValueChanged"
        ></ag-grid-vue>
    </a-modal>
  </div>
</template>

<script>
import numericInput from './component/numeric'
import selectInput from './component/select'
import dateInput from './component/date'
import tooltip from './component/tooltip'
import htmlrender from './component/htmlrender'
import { Modal } from 'ant-design-vue'
import * as lou from '@/services/data/lou'
import moment from 'moment'
import { mapState } from 'vuex'
export default {
  computed: {
    ...mapState(['settings', 'user']),
  },
  created() {
    // this.getMaster()
    this.getAllData()
  },
  data() {
    return {
      action: 'create',
      loading: false,
      theme: this.$store.state.settings.theme,
      searchText: '',
      tgl1: moment(),
      tgl2: moment(),
      searchInput: null,
      searchedColumn: '',
      data: [],
      columns: [
        {
          title: 'Action',
          scopedSlots: { customRender: 'action' },
          width: 100,
          // fixed: 'left',
        },
        {
          title: 'No Order',
          dataIndex: 'order_no',
          width: 150,
          // fixed: 'left',
          scopedSlots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
            customRender: 'customRender',
          },
          onFilter: (value, record) =>
            record.order_no
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus()
              }, 0)
            }
          },
        },
        {
          title: 'Tanggal',
          dataIndex: 'tgl',
          width: 100,
          // fixed: 'left',
          scopedSlots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
            customRender: 'customRender',
          },
          onFilter: (value, record) =>
            record.tgl.toString().toLowerCase().includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus()
              }, 0)
            }
          },
        },
        {
          title: 'N. Supplier',
          dataIndex: 'nama_supplier',
          width: 150,
          // fixed: 'left',
          scopedSlots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
            customRender: 'customRender',
          },
          onFilter: (value, record) =>
            record.nama_supplier
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus()
              }, 0)
            }
          },
        },
        {
          title: 'Catatan',
          dataIndex: 'catatan',
          width: 150,
          // fixed: 'left',
          scopedSlots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
            customRender: 'customRender',
          },
          onFilter: (value, record) =>
            record.catatan
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus()
              }, 0)
            }
          },
        },
      ],
      columnChildren: [
        {
          title: 'Nama',
          dataIndex: 'product_name',
          ellipsis: true,
          // scopedSlots: {
          //   customRender: 'formatCurrency',
          // },
        },
        {
          title: 'Harga',
          dataIndex: 'harga',
          ellipsis: true,
          align: 'right',
          scopedSlots: {
            customRender: 'formatCurrency',
          },
          sorter: (a, b) => a.harga - b.harga,
        },
        {
          title: 'Jumlah',
          dataIndex: 'jumlah',
          ellipsis: true,
          align: 'right',
          scopedSlots: {
            customRender: 'formatCurrency',
          },
          sorter: (a, b) => a.jumlah - b.jumlah,
        },
        {
          title: 'Diskon',
          dataIndex: 'diskon',
          ellipsis: true,
          align: 'right',
          scopedSlots: {
            customRender: 'formatCurrency',
          },
          sorter: (a, b) => a.diskon - b.diskon,
        },
      ],
      datenow: '',

      // A Modal Property
      amodalvisible: false,
      masterData: {
        barang: [],
        supplier: [],
      },

      // ag-grid-property
      changedData: [],
      frameworkComponents: {
        numericEditor: numericInput,
        dateEditor: dateInput,
        selectEditor: selectInput,
        customTooltip: tooltip,
        htmlRender: htmlrender,
      },
      selectedData: [],
      columnDefs: [
        {
          headerName: 'Nama',
          field: 'nama',
          editable: false,
          resizable: true,
          sortable: true,
        },
        {
          headerName: 'Kode',
          field: 'kode',
          editable: false,
          resizable: true,
          sortable: true,
        },
        {
          headerName: 'Satuan',
          field: 'satuan',
          editable: false,
          resizable: true,
          sortable: true,
        },
        {
          headerName: 'Harga',
          field: 'harga',
          editable: false,
          resizable: true,
          sortable: true,
        },
      ],
      rowData: [],
      gridOptions: null,
      gridApi: null,
      componentKey: 0,
    }
  },
  methods: {
    moment,
    sendToCreate() {
      localStorage.setItem('projectatransaksipurchaseorder', JSON.stringify({ id: '' }))
      console.log('this.$parent.active', this.$parent.active)
      this.$parent.active = 1
      // this.$router.push('/projecta/transaksi/orderpembelian/form')
    },
    sendToEdit(record) {
      localStorage.setItem('projectatransaksipurchaseorder', JSON.stringify(record))
      this.$parent.active = 1
      // this.$router.push({ path: '/projecta/transaksi/orderpembelian/form' })
    },
    filterDate(date, dateString) {
      this.tgl1 = date[0]
      this.tgl2 = date[1]
      this.getAllData()
    },
    async getAllData() {
      this.loading = true
      var imbuhan =
      this.tgl1 !== null && this.tgl1 !== '' && this.tgl2 !== null && this.tgl2 !== ''
        ? '?tglawal=' + this.tgl1.format('YYYY-MM-DD') + '&tglakhir=' + this.tgl2.format('YYYY-MM-DD')
        : ''
      var res = await lou.customUrlGet2('transaksi/purchase_order' + imbuhan)
      if (res) {
        this.data = res.data
        // console.log('this.data', this.data)
        setTimeout(() => {
          this.loading = false
        }, 100)
      } else {
        setTimeout(() => {
          this.loading = false
        }, 100)
      }
    },

    // Modal Func
    showModal(action, data) {
      this.getDataMaster()
      // this.amodalvisible = true
      this.action = action
      if (action === 'update') {
        // this.modalInput = this.$g.clone(data)
        this.editdata = data
      }
    },
    async getDataMaster() {
      var resbarang = await lou.customUrlGet2('transaksi/purchase_order/barang')
      var ressupplier = await lou.customUrlGet2('transaksi/purchase_order/supplier')
      if (resbarang !== null) {
        this.masterData.barang = []
        resbarang.data.forEach(element => {
          var ret = {
            ...element,
            label: element.kode + ' - ' + element.nama + ' - ' + element.harga,
          }
          this.masterData.barang.push(ret)
        })
      } else {
        lou.shownotif('Error!', 'Master Data Barang failed to fetch from API.', 'error')
      }
      if (ressupplier !== null) {
        this.masterData.Customer = []
        ressupplier.data.forEach(element => {
          var ret = {
            ...element,
            label: element.nama_perusahaan + ' - ' + element.nama_kontak,
          }
          this.masterData.Customer.push(ret)
        })
      } else {
        lou.shownotif('Error!', 'Master Data Customer failed to fetch from API.', 'error')
      }
    },

    // ag-grid function
    onGridReady(params) {
      // params.api.sizeColumnsToFit()
      this.gridApi = params.api
      this.gridColumnApi = params.columnApi
    },
    onCellValueChanged(params) {
      var undoSize = params.api.getCurrentUndoSize()
      this.input.undoInput = undoSize
      this.input.undoInputdisable = undoSize < 1
      var redoSize = params.api.getCurrentRedoSize()
      this.input.redoInput = redoSize
      this.input.redoInputdisable = redoSize < 1
      if (this.mode === 'oncreate') {
        if (params.data.newdata) {
          var target = this.changedData.findIndex(
            (x) => x.id === params.data.id,
          )
          if (target < 0) {
            this.changedData.push(params.data)
          } else {
            this.changedData[target] = params.data
          }
          // console.log('this.changedData', this.changedData)
        } else {
          // eslint-disable-next-line no-redeclare
          var target = this.rowData.findIndex((x) => x.id === params.data.id)
          var backupTarget = this.backupRowData.findIndex(
            (x) => x.id === params.data.id,
          )
          // console.log('this.rowData[target] before:', this.rowData[target])
          this.rowData[target] = this.$g.clone(
            this.backupRowData[backupTarget],
          )
          // console.log('this.rowData[target] after:', this.rowData[target])
          // console.log('this.backupRowData[backupTarget]', this.backupRowData[backupTarget])
          // this.componentKey += 1
          this.changedData = []
          this.input.undoInput = 0
          this.input.undoInputdisable = true

          this.input.redoInput = 0
          this.input.redoInputdisable = true
          lou.shownotif('Anda sedang dalam mode create')
        }
      } else {
        this.mode = 'onupdate'
        // eslint-disable-next-line no-redeclare
        var targetChanged = this.changedData.findIndex(
          (x) => x.id === params.data.id,
        )
        if (targetChanged < 0) {
          // var cd = {}
          // cd.id = params.data.id
          // cd[params.column.colId] = value.value
          // console.log('cd[params.column.colId]', cd)
          // console.log('value.value', value)
          this.changedData.push(params.data)
        } else {
          this.changedData[targetChanged][params.column.colId] =
            params.newValue
        }
        // console.log('this.changedData', this.changedData)
        // this.componentKey += 1
      }
      // console.log('this.mode', this.mode)
    },
    showDeleteConfirm(deldata) {
      Modal.confirm({
        title: 'Are you sure delete this data?',
        content: "You can't prevent this!!! ",
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'No',
        onOk: async () => {
          var response = await lou.customUrlDelete2('transaksi/purchase_order/' + deldata.id)
          if (response) {
            this.getAllData()
          }
        },
        onCancel: () => {
          // console.log('Cancel')
        },
      })
    },
    onSelectionChanged() {
      var selectedRows = this.gridApi.getSelectedRows()
      if (selectedRows[0].uuid !== 'totaldarisemuanya') {
        this.selectedData = selectedRows
        this.maskInput = selectedRows[0]
        this.maskInput.id = selectedRows[0].id
        this.maskInput.rekening = selectedRows[0].rekening
        this.maskInput.namarek = selectedRows[0].namarek
        this.maskInput.keterangan = selectedRows[0].keterangan
        this.maskInput.debet = selectedRows[0].debet
        this.maskInput.kredit = selectedRows[0].kredit
      } else {
        lou.shownotif('Total', 'Total akan terupdate secara otomatis.')
      }
      // console.log('selectedRows', selectedRows)
    },
    onFirstDataRendered() {
      // this.autoSizeAll(false, { autosizecolumn: true })
    },
    autoSizeAll(skipHeader, { autosizecolumn = true }) {
      if (autosizecolumn) {
        var allColumnIds = []
        // console.log('this.gridColumnApi', this.gridColumnApi)
        this.gridColumnApi.getAllColumns().forEach(function (column) {
          allColumnIds.push(column.colId)
        })
        this.gridColumnApi.autoSizeColumns(allColumnIds, skipHeader)
      } else {
        this.gridApi.sizeColumnsToFit()
      }
    },

    formatCurrency(v) {
      return lou.curency(v)
    },
    handleSearch(selectedKeys, confirm, dataIndex) {
      confirm()
      this.searchText = selectedKeys[0]
      this.searchedColumn = dataIndex
    },

    handleReset(clearFilters) {
      clearFilters()
      this.searchText = ''
    },
  },
}
</script>

<style lang="scss" scoped></style>
