<template>
  <div>
    <a-card>
      <template slot="title">
        <strong>Tabungan</strong>
        <!-- <a-range-picker class="ml-3" :format="'DD-MM-YYYY'" @change="rangedofunction"/> -->
        <!-- <a-button
          type="button"
          class="btn btn-outline-primary ml-2"
          @click="connector('nonanggota')"
        >
          Tambah Non Anggota
        </a-button> -->
        <a-button
          type="button"
          class="btn btn-outline-primary ml-4"
          @click="connector"
        >
          Tambah
        </a-button>
            <a-button
              class="btn btn-outline-success ml-2"
              @click="getAllData(true)"
              >Refresh Data</a-button
            >
            <a-dropdown>
              <a-button
                class="btn btn-outline-success float-right ml-2"
                style="width: 140px;"
                :loading="importBtnLoading"
                >Menu Import/Template</a-button
              >
              <a-menu slot="overlay">
                <a-menu-item key="1" class="text-success" @click="exportExcel">
                  <a-icon type="file-excel" />Export Excel
                </a-menu-item>
                <a-menu-item class="text-success" @click="importExcel('normal')">
                  <a-icon type="file-excel" />Import
                </a-menu-item>
                <a-menu-item class="text-success" @click="downloadTemplate">
                  <a-icon type="file-excel" />Template
                </a-menu-item>
              </a-menu>
            </a-dropdown>
            <input
              type="file"
              ref="excelFileUpload"
              style="display: none;"
              accept="application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              @input="getSheetExcel"
            />
      </template>
      <a-table
        :scroll="{ x: 1000}"
        :columns="columns"
        :dataSource="data"
        :pagination="{
          hideOnSinglePage:true,
          defaultPageSize: 10,
          showQuickJumper: true,
          showSizeChanger: true,
          showTotal: (total) => `Total ${total} items`,
          pageSizeOptions: ['10', '20', '30'],
        }"
        size="small"
      >
        <div
          slot="filterDropdown"
          slot-scope="{
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
            column,
          }"
          style="padding: 8px;"
        >
          <a-input
            v-ant-ref="(c) => (searchInput = c)"
            :placeholder="`Search ${column.title}`"
            :value="selectedKeys[0]"
            style="width: 188px; margin-bottom: 8px; display: block;"
            @change="
              (e) => setSelectedKeys(e.target.value ? [e.target.value] : [])
            "
            @pressEnter="
              () => handleSearch(selectedKeys, confirm, column.dataIndex)
            "
          />
          <a-button
            type="primary"
            icon="search"
            size="small"
            style="width: 90px; margin-right: 8px;"
            @click="
              () => handleSearch(selectedKeys, confirm, column.dataIndex)
            "
          >
            Search
          </a-button>
          <a-button
            size="small"
            style="width: 90px;"
            @click="() => handleReset(clearFilters)"
          >
            Reset
          </a-button>
        </div>
        <a-icon
          slot="filterIcon"
          slot-scope="filtered"
          type="search"
          :style="{ color: filtered ? '#108ee9' : undefined }"
        ></a-icon>
        <template
          slot="customRender"
          slot-scope="text, record, index, column"
        >
          <span v-if="searchText && searchedColumn === column.dataIndex">
            <template
              v-for="(fragment, i) in text
                .toString()
                .split(
                  new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i')
                )"
            >
              <mark
                v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                :key="i"
                class="highlight"
                >{{ fragment }}</mark
              >
              <template v-else>{{ fragment }}</template>
            </template>
          </span>
          <template v-else>
            {{ text }}
          </template>
        </template>
        <a slot="name" slot-scope="text" href="javascript:;">{{ text }}</a>
        <span slot="customTitle"><a-icon type="smile-o" /> Kode</span>
        <span slot="tags" slot-scope="tags">
          <a-tag v-for="tag in tags" color="blue" :key="tag">{{ tag }}</a-tag>
        </span>
        <span slot="action" slot-scope="text, record">
          <a class="text-warning" @click="sendToEdit(record)">
            <a-icon type="edit"></a-icon>
          </a>
          <a-divider type="vertical" />
          <a href="javascript:void(0)" class="text-danger" @click="showDeleteConfirm(record)">
            <a-icon type="delete"></a-icon>
          </a>
          <a-divider type="vertical" />
          <a href="javascript:void(0)" class="text-success" @click="printHeader(record)">
            <a-icon type="printer"></a-icon>
          </a>
          <!-- <a href="javascript:;" class="ant-dropdown-link">
            More actions <a-icon type="down" />
          </a> -->
        </span>
        <span slot="jenis_identitas" slot-scope="text, record">
          {{ findRelationData(record, 'masterJenisIndentitas', 'id', 'jenis_identitas', 'keterangan') }}
        </span>
        <span slot="tgl" slot-scope="text">
          {{ formatDate(text) }}
        </span>
      </a-table>
    </a-card>
      <a-modal
        title="Preview Import Excel"
        v-model="excelModalVisible"
        :width="800"
        :dialogStyle="{ top: '0px' }"
      >
        <template slot="footer">
          <a-button class="btn btn-outline-dark" @click="excelModalCancel"
            >Cancel</a-button
          >
          <a-button class="btn btn-outline-primary" @click="proccessDataExcel"
            >Process</a-button
          >
        </template>
        <div class="row">
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            <a-select
              show-search
              option-filter-prop="children"
              :filter-option="filterOption"
              style="width: 50%;"
              placeholder="Pilih sheet"
              @change="mselectSheet"
            >
              <a-select-option
                v-for="(data, index) in sheetColection"
                :key="index"
                :value="data"
                >{{ data }}</a-select-option
              >
            </a-select>
          </div>
          <ag-grid-vue
            :key="componentKey"
            style="height: 50vh; width: 100%;"
            :gridOptions="gridOptionsModalExcel"
            :class="
              theme === 'dark'
                ? 'ag-theme-balham-dark mt-2'
                : 'ag-theme-balham mt-2'
            "
            :columnDefs="excelModal.column"
            :rowData="excelModal.datatable"
          ></ag-grid-vue>
        </div>
      </a-modal>
    <mymodal ref="myownmodal" :key="componentKey" />
  </div>
</template>

<script>
/* Import Script */
import { Modal } from 'ant-design-vue'
import * as lou from '@/services/data/lou'
import moment from 'moment'
import { mapState } from 'vuex'
import mymodal from './form'
import JSPDF from 'jspdf'
/* End Script Import Script */

/* Kolom atau header dari table */
const columns = [
  {
    title: 'Action',
    scopedSlots: { customRender: 'action' },
    fixed: 'left',
    width: 100,
  },
  {
    title: 'Rekening',
    dataIndex: 'rekening',
    width: 100,
    // fixed: 'left',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
    onFilter: (value, record) =>
      record.rekening.toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => {
          this.searchInput.focus()
        }, 0)
      }
    },
  },
  {
    title: 'Nama',
    dataIndex: 'nama',
    width: 150,
    // fixed: 'left',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
    onFilter: (value, record) =>
      record.nama.toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => {
          this.searchInput.focus()
        }, 0)
      }
    },
  },
  {
    title: 'No Seri',
    dataIndex: 'noseri',
    width: 80,
    // fixed: 'left',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
    onFilter: (value, record) =>
      record.noseri.toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => {
          this.searchInput.focus()
        }, 0)
      }
    },
  },
  {
    title: 'Tanggal',
    width: 100,
    dataIndex: 'tgl',
    scopedSlots: { customRender: 'tgl' },
    // width: 100,
  },
  {
    title: 'Golongan Tabungan',
    width: 100,
    dataIndex: 'golongantabungan',
    scopedSlots: { customRender: 'golongantabungan' },
    // width: 100,
  },
  {
    title: 'ZIS',
    width: 70,
    dataIndex: 'zis',
    scopedSlots: { customRender: 'zis' },
    // width: 100,
  },
  {
    title: 'AO',
    width: 100,
    dataIndex: 'nama_ao',
    scopedSlots: { customRender: 'ao' },
    // width: 100,
  },
  {
    title: 'Ahli Waris',
    width: 150,
    dataIndex: 'ahliwaris',
    scopedSlots: { customRender: 'ahliwaris' },
    // width: 100,
  },
  {
    title: 'Baris Akhir',
    width: 70,
    dataIndex: 'baristerakhir',
    scopedSlots: { customRender: 'baristerakhir' },
    // width: 100,
  },
]
/* End script Kolom atau header dari table */

/* Data dari table */
const data = []
/* End script Data dari table */

export default {
  components: {
    mymodal,
  },
  computed: {
    ...mapState(['settings', 'user']),
  },
  /* Data variable vue */
  data() {
    return {
      theme: this.$store.state.settings.theme,
      searchText: '',
      tgl1: '',
      tgl2: '',
      searchInput: null,
      searchedColumn: '',
      name: 'Tabletabungan',
      visible: false,
      statusperkawinan: {
        K: 'Kawin',
        B: 'Belum Kawin',
        D: 'Duda',
        J: 'Janda',
      },
      jenisidentitas: {
        1: 'KTP',
        2: 'SIM',
        3: 'Pasport',
      },
      yatidak: {
        0: 'Tidak',
        1: 'Ya',
      },
      perorangan: {
        1: 'Perorangan',
        2: 'Non-Perorangan',
      },
      allMaster: [],
      masterstatuskawin: [],
      masterkelamin: [],
      data,
      columns,
      datenow: '',

      // Import Excel Property
      gridOptionsModalExcel: null,
      sheetColection: [],
      importBtnLoading: false,
      excelModalVisible: false,
      excelMode: 'normal',
      excelModal: {
        column: [
          {
            headerName: 'register',
            field: 'register',
            filter: true,
            resizable: true,
            editable: true,
          },
          {
            headerName: 'noseri',
            field: 'noseri',
            filter: true,
            resizable: true,
            editable: true,
          },
          {
            headerName: 'tgl',
            field: 'tgl',
            filter: true,
            resizable: true,
            editable: true,
          },
          {
            headerName: 'golongantabungan',
            field: 'golongantabungan',
            filter: true,
            resizable: true,
            editable: true,
          },
          {
            headerName: 'zis',
            field: 'zis',
            filter: true,
            resizable: true,
            editable: true,
          },
          {
            headerName: 'ao',
            field: 'ao',
            filter: true,
            resizable: true,
            editable: true,
          },
          {
            headerName: 'ahliwaris',
            field: 'ahliwaris',
            filter: true,
            resizable: true,
            editable: true,
          },
          {
            headerName: 'baristerakhir',
            field: 'baristerakhir',
            filter: true,
            resizable: true,
            editable: true,
          },
        ],
        datatable: [],
      },
      failInsertManyData: [],
      excelErrorExist: false,
      input: {
        undoInput: '',
        redoInput: '',
        undoInputdisable: true,
        redoInputdisable: true,
      },
      rowAfterFilter: [],
      loadingImportExcel: false,
      componentKey: 0,
    }
  },
  /* End Data variable vue */
  mounted: function () {
    this.gridOptionsModalExcel = {}
    const date = moment().format('YYYY-MM-DD') // Tanggal hari ini format 2020-12-31
    // localStorage.removeItem('newkoperasiedittabungandata')
    this.tgl1 = date
    this.tgl2 = date
    this.datenow = date.toString()
    this.getAllData()
    // this.getAgamaF()
  },
  methods: {
    moment,
    printHeader(record) {
      // Create a new Document an save it in a variable
      const doc = new JSPDF()
      // doc.addImage('resources/images/logo-bpr-1-01.png',
      //   'png', 15, 25, 12, 15)
      // doc.setFont(undefined, 'bold')
      doc.setFontSize(10)
      // doc.setTextColor('#062649')
      doc.text('Rekening', 15, 25)
      doc.text('Nama', 15, 30)
      doc.text('Alamat', 15, 35)
      doc.text(record.rekening, 35, 25)
      doc.text(record.nama, 35, 30)
      doc.text(record.alamat, 35, 35)
      // doc.text('BILYET DEPOSITO', 80, 25)
      // var filename = 'Validasi'
      doc.output('dataurlnewwindow')
    },
    connector() {
      // this.$refs.myownmodal.showModal('Create', {})
      localStorage.setItem('newkoperasiedittabungandata', JSON.stringify({ id: '' }))
      this.$router.push('/koperasi/tabungan/form')
    },
    rangedofunction(date, dateString) {
      this.tgl1 = moment(dateString[0], 'DD-MM-YYYY').format('YYYY-MM-DD')
      this.tgl2 = moment(dateString[1], 'DD-MM-YYYY').format('YYYY-MM-DD')
      this.getAllData()
    },
    async getAllData(onclick = false) {
      var indexeddb = await lou.getDataFromDb()
      // console.log('indexeddb', indexeddb)
      // console.log('this.settings.whatapp', this.settings.whatapp)
      var allmaster = {}
      var masterIndex = indexeddb.findIndex(
        (x) =>
          x.mykey ===
          'allmaster' +
            this.settings.whatapp.appName.toLowerCase() +
            this.settings.whatapp.id,
      )
      // console.log('masterIndex', indexeddb[masterIndex])
      if (masterIndex < 0) {
        var res = await lou.customUrlGet2('master', false, true)
        var master = {
          mykey:
            'allmaster' +
            this.settings.whatapp.appName.toLowerCase() +
            this.settings.whatapp.id,
          data: JSON.stringify(res.data),
        }
        await lou.addDataToDb(master)
        var nindexeddb = await lou.getDataFromDb()
        var targetIndex = nindexeddb.findIndex(
          (x) =>
            x.mykey ===
            'allmaster' +
              this.settings.whatapp.appName.toLowerCase() +
              this.settings.whatapp.id,
        )
        // console.log('nindexeddb', nindexeddb[targetIndex])
        allmaster = JSON.parse(nindexeddb[targetIndex].data)
      } else {
        allmaster = JSON.parse(indexeddb[masterIndex].data)
      }
      var cabanglist = []
      for (let u = 0; u < this.user.cabangList.length; u++) {
        const element = this.user.cabangList[u]
        // console.log('element', element)
        cabanglist.push({ kode: element.kode, keterangan: element.kode })
      }
      // console.log('indexeddb', indexeddb)
      this.allMaster = allmaster
      // console.log('this.allMaster', this.allMaster)
      var response = await lou.customUrlGet2('tabungan')
      if (response) {
        this.data = response.data
        if (onclick) {
          lou.shownotif('Refresh', 'Data telah di refresh.')
        }
      }
      // console.log('this.data', this.data)
    },
    sendToEdit(edata) {
      // this.$refs.myownmodal.showModal('Update', edata)
      // var ndata = {}
      // for (let i = 0; i < this.columns.length; i++) {
      //   const element = this.columns[i].dataIndex
      //   ndata[element.toString().toLowerCase()] = edata[element]
      // }
      // console.log('edata', edata)
      localStorage.setItem('newkoperasiedittabungandata', JSON.stringify(edata))
      this.$router.push('/koperasi/tabungan/form')
    },
    formatDate(value) {
      return moment(value).format('DD-MM-YYYY')
    },
    showDeleteConfirm(deldata) {
      Modal.confirm({
        title: 'Are you sure delete this data?',
        content: "You can't prevent this!!! ",
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'No',
        onOk: async () => {
          var response = await lou.customUrlDelete2('tabungan/' + deldata.id)
          if (response) {
            this.getAllData()
          }
        },
        onCancel: () => {
          // console.log('Cancel')
        },
      })
    },
    findRelationData(data, mastername, key, datakey, label) {
      var target = this[mastername].findIndex(x => x[key] === data[datakey])
      // console.log('data', data)
      // console.log('mastername', mastername)
      // console.log('key', key)
      // console.log('target', target)
      // console.log('this.allMaster[mastername][target]', this.allMaster[mastername][target])
      // console.log('this[mastername][target]', this[mastername][target])
      return this[mastername][target][label]
    },
    handleSearch(selectedKeys, confirm, dataIndex) {
      confirm()
      this.searchText = selectedKeys[0]
      this.searchedColumn = dataIndex
    },

    handleReset(clearFilters) {
      clearFilters()
      this.searchText = ''
    },

    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      )
    },
    // Import Excel Function
    selectImportFile(to) {
      this.$refs[to].click()
    },
    readTextFile() {
      this.cancelaction()
      lou.shownotif('Loading...', 'Data onprocessing...', 'info', 1)
      this.importBtnLoading = true
      var fileupload = this.$refs.uploadtext.files[0]
      // console.log('this.rowData.length', this.rowData.length)
      if (this.rowData.length === 0) {
        this.rowData = null
      }
      var that = this
      setTimeout(() => {
        const reader = new FileReader()
        reader.onload = (function (reader) {
          return function () {
            var content = reader.result
            var lines = content.split('\r\n')
            // console.log('content', content)
            // console.log('lines', lines)
            that.filetextarr = lines
            // console.log('this.rowData', that.rowData)
            that.scrapTextFile()
          }
        })(reader)
        reader.readAsText(fileupload)
      }, 300)
      this.importBtnLoading = false
    },
    scrapTextFile() {
      var header = []
      var data = []
      var column = this.$g.clone(this.columns)
      // console.log('column', column)
      for (let u = 0; u < this.filetextarr.length; u++) {
        const element = this.filetextarr[u]
        var aftersplit = []
        if (u === 0) {
          aftersplit = element.split('|')
          header = aftersplit
        } else {
          aftersplit = element.split('|')
          // console.log('aftersplit', aftersplit)
          var md = {
            id: this.$uuid.v4(),
            periode: header[3][4],
            newdata: true,
          }
          for (let i = 0; i < column.length; i++) {
            const element = column[i]
            // console.log('element', element)
            // console.log('element.cellEditor', element.cellEditor)
            if (element.cellEditor === 'dateEditor') {
              md[element.field] =
                aftersplit[i] !== ''
                  ? moment(aftersplit[i], 'YYYYMMDD').format('YYYY-MM-DD')
                  : null
              md[element.field] =
                md[element.field] !== 'Invalid date'
                  ? md[element.field]
                  : null
            // } else if (element.valueFormatter === 'currency') {
            //   // console.log('element', element.field)
            //   md[element.field] = aftersplit[i] === '' ? 0 : parseInt(aftersplit[i])
            } else {
              var isCurency = this.columnCurrency.findIndex(x => x.field === element.field)
              if (isCurency >= 0) {
                md[element.field] = aftersplit[i] === '' ? 0 : parseInt(aftersplit[i])
              } else {
                md[element.field] = aftersplit[i] === '' ? null : aftersplit[i]
              }
            }
          }
          var last = u + 1
          if (last !== this.filetextarr.length) {
            data.push(md)
          }
        }
      }
      // console.log('header', header)
      // console.log('data', data)
      this.importTextHeader = header
      this.rowData = [...data, ...this.backupRowData]
      this.changedData = this.$g.clone(data)
      this.mode = 'oncreate'
      this.$refs.uploadtext.value = null
      lou.shownotif('Done', 'Text data selesai di baca.', 'success')
      // this.autoSizeAll(false, { autosizecolumn: true })
    },
    async downloadTemplate() {
      const ExcelJS = require('exceljs')
      const workbook = new ExcelJS.Workbook()
      workbook.creator = 'LB-BPR Heasoft System'
      workbook.lastModifiedBy = 'Me'
      workbook.created = new Date()
      workbook.modified = new Date()
      workbook.lastPrinted = new Date()
      var worksheet = workbook.addWorksheet('import data')
      var alphabeth = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z']
      var column = this.$g.clone(this.excelModal.column)
      var cfirst = 0
      var cloop = 0
      var columnLocation = []
      for (let i = 0; i < column.length; i++) {
        const element = column[i]
        var cloopni = cloop - 1
        var hname = element.headerName.toString()
        if (cloop === 0) {
          columnLocation.push({
            location: alphabeth[cfirst],
            field: element.field,
          })
          worksheet.getCell(alphabeth[cfirst] + '1').value = hname.replaceAll(' ', '')
        } else {
          columnLocation.push({
            location: alphabeth[cloopni] + alphabeth[cfirst],
            field: element.field,
          })
          worksheet.getCell(alphabeth[cloopni] + alphabeth[cfirst] + '1').value = hname.replaceAll(' ', '')
        }
        if (cfirst === 25) {
          cfirst = 0
          cloop += 1
        } else {
          cfirst += 1
        }
      }
      const buffer = await workbook.xlsx.writeBuffer()
      var blob = new Blob([buffer], {
        type:
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      })
      var link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = 'Pembukaan Tabungan IMPORT TEMPLATE.xlsx'
      link.click()
    },
    mselectSheet(params) {
      this.selectSheet(params)
    },
    selectSheet(params) {
      // console.log('params', params)
      var that = this
      if (this.fileupload) {
        var XLSX = require('xlsx')
        const reader = new FileReader()

        reader.onload = (e) => {
          /* Parse data */
          var data = e.target.result
          const wb = XLSX.read(data, {
            type: 'binary',
            cellDates: true,
          })
          /* Get first worksheet */
          // console.log('wb.SheetNames', wb.SheetNames)
          const ws = wb.Sheets[params]
          // var excellist = []
          /* Convert array of arrays */
          const ret = XLSX.utils.sheet_to_json(ws)
          // console.log('ret', ret)
          // var literalydata = ret.map((x) => {
          //   console.log('x', x[this.excelModal.column[0].field])
          //   if (x[this.excelModal.column[0].field] !== 'undefined') {
          //     return x
          //   }
          // })
          if (ret.length !== 0) {
            that.excelModal.datatable = []
            // console.log('that.excelModal.datatable', that.excelModal.datatable)
            var length = this.excelMode === 'normal' ? ret.length : ret.length - 1
            for (let i = 0; i < length; i++) {
              var element = ret[i]
              // if (element[that.excelModal.column[0].field] !== undefined) {
              // console.log('element', element.TglHapusBuku)
              element.tgl = element.tgl !== null && element.tgl !== undefined ? moment(element.tgl, 'DD-MM-YYYY').format('YYYY-MM-DD') : null
              element.golongantabungan = element.golongantabungan !== null && element.golongantabungan !== undefined ? element.golongantabungan.toString() : null
              element.register = element.register !== null && element.register !== undefined ? element.register.toString() : null
              element.zis = element.zis !== null && element.zis !== undefined ? element.zis.toString() : null
              element.ao = element.ao !== null && element.ao !== undefined ? element.ao.toString() : null
              element.ahliwaris = element.ahliwaris !== null && element.ahliwaris !== undefined ? element.ahliwaris.toString() : null
              element.noseri = element.noseri !== null && element.noseri !== undefined ? element.noseri.toString() : null
              element.baristerakhir = element.baristerakhir !== null && element.baristerakhir !== undefined ? element.baristerakhir.toString() : null
              // console.log('element', element.TglHapusBuku)
              that.excelModal.datatable.push(element)
              // }
            }
            this.componentKey += 1
            // console.log('that.excelModal.datatable', that.excelModal.datatable)
          }
        }
        reader.readAsBinaryString(this.fileupload)
      }
    },
    getSheetExcel() {
      this.importBtnLoading = true
      this.fileupload = this.$refs.excelFileUpload.files[0]
      // console.log('this.fileupload', this.fileupload)
      if (this.fileupload) {
        var XLSX = require('xlsx')
        const reader = new FileReader()

        reader.onload = (e) => {
          /* Parse data */
          var data = e.target.result
          const wb = XLSX.read(data, {
            type: 'binary',
            cellDates: true,
          })
          /* Get first worksheet */
          // console.log('wb.SheetNames', wb.SheetNames)
          const wsname = wb.SheetNames
          this.sheetColection = wsname
          this.importBtnLoading = false
          this.excelModalVisible = true
          // console.log('literalydata', literalydata)
          // for (var i = 0; i < ret.length; i++) {
          //   excellist.push(ret[i])
          // }
          // console.log('excellist', ret)
          // const a = wb.Sheets[wb.SheetNames[13]]
          // const headers = this.getHeader(a)
          // console.log('headers', headers)
          // dataexcel = literalydata
        }
        reader.readAsBinaryString(this.fileupload)
      }
    },
    importExcel(mode) {
      this.excelMode = mode
      this.excelModalFrom = ''
      this.$refs.excelFileUpload.click()
    },
    excelModalCancel() {
      this.excelModalVisible = false
      this.fileupload = null
      this.$refs.excelFileUpload.value = null
    },
    async proccessDataExcel() {
      if (!this.loadingImportExcel) {
        this.loadingImportExcel = true
        var res = null
        if (this.excelErrorExist) {
          Modal.confirm({
            title: 'Error data',
            content: 'Skip error data!?',
            okText: 'Agree',
            okType: 'primary',
            cancelText: 'No',
            onOk: async () => {
              var fd = this.excelModal.datatable
              fd = fd.filter((x) => x.TglHapusBuku !== 'Error')
              res = await lou.customUrlPost2('tabungan/many', fd)
              this.afterProccessDataExcel(res)
            },
            onCancel: () => {
            // console.log('Cancel')
              return false
            },
          })
        } else {
          var fd = this.excelModal.datatable
          // res = await lou.customUrlPost2('tabungan', fd)
          res = await lou.customUrlPost2('tabungan/many', fd)
          this.afterProccessDataExcel(res)
        }
      }
    },
    async afterProccessDataExcel(res) {
      if (res) {
        this.loadingImportExcel = false
        this.excelModalVisible = false
        this.fileupload = null
        this.$refs.uploadtext.value = null
        this.$refs.excelFileUpload.value = null
        var response = await lou.customUrlGet2('tabungan')
        if (response) {
          this.data = response.data
        }
      }
    },
    async exportExcel() {
      const ExcelJS = require('exceljs')
      const workbook = new ExcelJS.Workbook()
      workbook.creator = 'LB-BPRS Heasoft System'
      workbook.lastModifiedBy = 'Me'
      workbook.created = new Date()
      workbook.modified = new Date()
      workbook.lastPrinted = new Date()
      var alphabeth = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z']
      const worksheet = workbook.addWorksheet(moment().format('DD-MM-YYYY'))

      // Table Data
      var column = this.$g.clone(this.columns)
      var tdata = this.$g.clone(this.data)
      var data = []
      tdata.forEach(element => {
        var retv = {
          ...element,
          tgl: element.tgl === null ? '' : moment(element.tgl, 'YYYY-MM-DD').format('DD-MM-YYYY'),
        }
        data.push(retv)
      })
      var cfirst = 0
      var cloop = 0
      var dincrement = 2
      var columnLocation = []
      for (let i = 0; i < column.length; i++) {
        const element = column[i]
        var cloopni = cloop - 1
        var hname = element.title.toString()
        if (hname !== 'Action') {
          if (cloop === 0) {
            columnLocation.push({
              location: alphabeth[cfirst],
              field: element.dataIndex,
            })
            worksheet.getCell(alphabeth[cfirst] + '1').value = hname.replaceAll(' ', '')
          } else {
            columnLocation.push({
              location: alphabeth[cloopni] + alphabeth[cfirst],
              field: element.dataIndex,
            })
            worksheet.getCell(alphabeth[cloopni] + alphabeth[cfirst] + '1').value = hname.replaceAll(' ', '')
          }
          for (let u = 0; u < data.length; u++) {
            const el = data[u]
            if (cloop === 0) {
              worksheet.getCell(alphabeth[cfirst] + dincrement).value = el[element.dataIndex]
            } else {
              worksheet.getCell(alphabeth[cloopni] + alphabeth[cfirst] + dincrement).value = el[element.dataIndex]
            }
            var checklast = u + 1
            if (checklast === data.length) {
              dincrement = 2
            } else {
              dincrement += 1
            }
          }
          if (cfirst === 25) {
            cfirst = 0
            cloop += 1
          } else {
            cfirst += 1
          }
        }
      }

      /* For sum data */
      // var last = data.length + 3
      // worksheet.mergeCells('A' + last + ':B' + last)
      // worksheet.getCell('A' + last).value = 'Total'
      // for (let ti = 0; ti < this.columnsTotal.length; ti++) {
      //   const element = this.columnsTotal[ti]
      //   var targetLoc = columnLocation.findIndex(x => x.field === element.dataIndex)
      //   if (targetLoc >= 0) {
      //     worksheet.getCell(columnLocation[targetLoc].location + last).value = this.rowDataTotal[0][element.dataIndex]
      //   }
      //   // console.log(columnLocation[targetLoc].location + last, this.rowDataTotal[0][element.dataIndex])
      // }

      // write to a new buffer
      const buffer = await workbook.xlsx.writeBuffer()
      var blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
      var link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = 'Data tabungan.xlsx'
      link.click()
    },
  },
}
</script>

<style scoped>
.highlight {
  background-color: rgb(255, 192, 105);
  padding: 0px;
}
</style>
