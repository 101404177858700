<template>
  <div>
    <a-card>
      <template slot="title">
        <strong>Penerimaan Kas</strong>
        <!-- <a-range-picker class="ml-3" :format="'DD-MM-YYYY'" @change="rangedofunction"/> -->
        <a-button
          type="button"
          class="btn btn-outline-primary ml-4"
          @click="connector()"
        >
          Tambah
        </a-button>
      </template>
      <a-table
        :scroll="{ x: 2000 }"
        :columns="columns"
        :dataSource="data"
        :pagination="{
          hideOnSinglePage:true,
          defaultPageSize: 10,
          showQuickJumper: true,
          showSizeChanger: true,
          showTotal: (total) => `Total ${total} items`,
          pageSizeOptions: ['10', '20', '30'],
        }"
        size="small"
      >
        <div
          slot="filterDropdown"
          slot-scope="{
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
            column,
          }"
          style="padding: 8px;"
        >
          <a-input
            v-ant-ref="(c) => (searchInput = c)"
            :placeholder="`Search ${column.dataIndex}`"
            :value="selectedKeys[0]"
            style="width: 188px; margin-bottom: 8px; display: block;"
            @change="
              (e) => setSelectedKeys(e.target.value ? [e.target.value] : [])
            "
            @pressEnter="
              () => handleSearch(selectedKeys, confirm, column.dataIndex)
            "
          />
          <a-button
            type="primary"
            icon="search"
            size="small"
            style="width: 90px; margin-right: 8px;"
            @click="
              () => handleSearch(selectedKeys, confirm, column.dataIndex)
            "
          >
            Search
          </a-button>
          <a-button
            size="small"
            style="width: 90px;"
            @click="() => handleReset(clearFilters)"
          >
            Reset
          </a-button>
        </div>
        <a-icon
          slot="filterIcon"
          slot-scope="filtered"
          type="search"
          :style="{ color: filtered ? '#108ee9' : undefined }"
        ></a-icon>
        <template
          slot="customRender"
          slot-scope="text, record, index, column"
        >
          <span v-if="searchText && searchedColumn === column.dataIndex">
            <template
              v-for="(fragment, i) in text
                .toString()
                .split(
                  new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i')
                )"
            >
              <mark
                v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                :key="i"
                class="highlight"
                >{{ fragment }}</mark
              >
              <template v-else>{{ fragment }}</template>
            </template>
          </span>
          <template v-else>
            {{ text }}
          </template>
        </template>
        <a slot="name" slot-scope="text" href="javascript:;">{{ text }}</a>
        <span slot="customTitle"><a-icon type="smile-o" /> Kode</span>
        <span slot="tags" slot-scope="tags">
          <a-tag v-for="tag in tags" color="blue" :key="tag">{{ tag }}</a-tag>
        </span>
        <span slot="action" slot-scope="text, record">
          <a class="text-warning" @click="sendToEdit(record)">
            <a-icon type="edit"></a-icon>
          </a>
          <a-divider type="vertical" />
          <a href="javascript:void(0)" class="text-danger" @click="showDeleteConfirm(record)">
            <a-icon type="delete"></a-icon>
          </a>
          <!-- <a href="javascript:;" class="ant-dropdown-link">
            More actions <a-icon type="down" />
          </a> -->
        </span>
        <span slot="tgl" slot-scope="text, record">
          {{ formatDate(record.tgl) }}
        </span>
        <span slot="boolean" slot-scope="text">
          {{ formatBoolean(text) }}
        </span>
        <span slot="sudah_cair" slot-scope="text">
          {{ text === '0' ? 'Belum Cair' : text === '1' ? 'Sudah Cair' : text ? 'Sudah Cair' : !text ? 'Belum Cair' : text }}
        </span>
        <span slot="formatCurrency" slot-scope="text">
          {{ formatCurrency(text) }}
        </span>
      </a-table>
    </a-card>
  </div>
</template>

<script>
/* Import Script */
import { Modal } from 'ant-design-vue'
import * as lou from '@/services/data/lou'
import moment from 'moment'
import { mapState } from 'vuex'
/* End Script Import Script */

/* Kolom atau header dari table */
const columns = [
  {
    title: 'Action',
    scopedSlots: { customRender: 'action' },
    width: 70,
    fixed: 'left',
  },
  {
    title: 'Tanggal',
    width: 100,
    dataIndex: 'tgl',
    scopedSlots: { customRender: 'tgl' },
    // width: 100,
  },
  {
    title: 'Faktur',
    dataIndex: 'faktur',
    width: 100,
    // fixed: 'left',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
    onFilter: (value, record) =>
      record.faktur.toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => {
          this.searchInput.focus()
        }, 0)
      }
    },
  },
  {
    title: 'Rekening',
    dataIndex: 'rekening',
    width: 100,
    // fixed: 'left',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
    onFilter: (value, record) =>
      record.rekening.toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => {
          this.searchInput.focus()
        }, 0)
      }
    },
  },
  {
    title: 'Nama',
    dataIndex: 'nama',
    width: 150,
    // fixed: 'left',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
    onFilter: (value, record) =>
      record.nama.toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => {
          this.searchInput.focus()
        }, 0)
      }
    },
  },
  {
    title: 'Rekening Tabungan',
    dataIndex: 'rekeningtabungan',
    width: 100,
    // fixed: 'left',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
    onFilter: (value, record) =>
      record.rekeningtabungan.toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => {
          this.searchInput.focus()
        }, 0)
      }
    },
  },
  {
    title: 'Biaya Administrasi',
    width: 100,
    dataIndex: 'biaya_administrasi',
    scopedSlots: { customRender: 'formatCurrency' },
    // width: 100,
  },
  {
    title: 'Biaya Asuransi',
    width: 100,
    dataIndex: 'biaya_asuransi',
    scopedSlots: { customRender: 'formatCurrency' },
    // width: 100,
  },
  {
    title: 'Biaya Materai',
    width: 100,
    dataIndex: 'biaya_materai',
    scopedSlots: { customRender: 'formatCurrency' },
    // width: 100,
  },
  {
    title: 'Biaya Notaris',
    width: 100,
    dataIndex: 'biaya_notaris',
    scopedSlots: { customRender: 'formatCurrency' },
    // width: 100,
  },
  {
    title: 'Cara Perhitungan',
    width: 100,
    dataIndex: 'caraperhitungan',
    scopedSlots: { customRender: 'caraperhitungan' },
    // width: 100,
  },
  {
    title: 'JW',
    width: 100,
    dataIndex: 'jw',
    scopedSlots: { customRender: 'jw' },
    // width: 100,
  },
  {
    title: 'Nama AO',
    width: 100,
    dataIndex: 'nama_ao',
    scopedSlots: { customRender: 'nama_ao' },
    // width: 100,
  },
  {
    title: 'Nama Golongan',
    width: 100,
    dataIndex: 'nama_golongan',
    scopedSlots: { customRender: 'nama_golongan' },
    // width: 100,
  },
  {
    title: 'Nama Pasangan',
    width: 100,
    dataIndex: 'nama_pasangan',
    scopedSlots: { customRender: 'nama_pasangan' },
    // width: 100,
  },
  {
    title: 'Nama Tabungan',
    width: 100,
    dataIndex: 'nama_tabungan',
    scopedSlots: { customRender: 'nama_tabungan' },
    // width: 100,
  },
  {
    title: 'Nil. Pasar',
    width: 100,
    dataIndex: 'nilai_pasar',
    scopedSlots: { customRender: 'formatCurrency' },
    // width: 100,
  },
  {
    title: 'Nil. Pengurang PPAP',
    width: 100,
    dataIndex: 'nilai_pengurangppap',
    scopedSlots: { customRender: 'formatCurrency' },
    // width: 100,
  },
  {
    title: 'Nil. Taksasi',
    width: 100,
    dataIndex: 'nilai_taksasi',
    scopedSlots: { customRender: 'formatCurrency' },
    // width: 100,
  },
  {
    title: 'No SPK',
    width: 100,
    dataIndex: 'no_spk',
    scopedSlots: { customRender: 'no_spk' },
    // width: 100,
  },
  {
    title: 'Plafond',
    width: 100,
    dataIndex: 'plafond',
    scopedSlots: { customRender: 'formatCurrency' },
    // width: 100,
  },
  {
    title: 'Sudah Cair',
    width: 100,
    dataIndex: 'sudah_cair',
    scopedSlots: { customRender: 'sudah_cair' },
    // width: 100,
  },
  {
    title: 'Suku Bunga',
    width: 100,
    dataIndex: 'sukubunga',
    scopedSlots: { customRender: 'sukubunga' },
    // width: 100,
  },
  {
    title: 'Total Bunga',
    width: 100,
    dataIndex: 'totalbunga',
    scopedSlots: { customRender: 'formatCurrency' },
    // width: 100,
  },
  {
    title: 'Jaminan',
    width: 200,
    dataIndex: 'jaminan',
    scopedSlots: { customRender: 'jaminan' },
    // width: 100,
  },
  {
    title: 'Username',
    width: 100,
    dataIndex: 'username',
    scopedSlots: { customRender: 'username' },
    // width: 100,
  },
  {
    title: 'Lunas',
    width: 100,
    dataIndex: 'lunas',
    scopedSlots: { customRender: 'lunas' },
    // width: 100,
  },
  {
    title: 'Tgl Lunas',
    width: 100,
    dataIndex: 'tgllunas',
    scopedSlots: { customRender: 'tgllunas' },
    // width: 100,
  },
]
/* End script Kolom atau header dari table */

/* Data dari table */
const data = []
/* End script Data dari table */

export default {
  computed: {
    ...mapState(['settings', 'user']),
  },
  /* Data variable vue */
  data() {
    return {
      searchText: '',
      searchInput: null,
      searchedColumn: '',
      tgl1: '',
      tgl2: '',
      name: 'TableRegisterNasabah',
      visible: false,
      statusperkawinan: {
        K: 'Kawin',
        B: 'Belum Kawin',
        D: 'Duda',
        J: 'Janda',
      },
      jenisidentitas: {
        1: 'KTP',
        2: 'SIM',
        3: 'Pasport',
      },
      yatidak: {
        0: 'Tidak',
        1: 'Ya',
      },
      perorangan: {
        1: 'Perorangan',
        2: 'Non-Perorangan',
      },
      allMaster: [],
      masterstatuskawin: [],
      masterkelamin: [],
      data,
      columns,
      datenow: '',
    }
  },
  /* End Data variable vue */
  mounted: function () {
    const date = moment().format('YYYY-MM-DD') // Tanggal hari ini format 2020-12-31
    localStorage.removeItem('newkoperasieditrealisasikreditdata')
    this.tgl1 = date
    this.tgl2 = date
    this.datenow = date.toString()
    this.getAllData()
    // this.getAgamaF()
  },
  methods: {
    moment,
    connector() {
      // console.log('keanggotaan', keanggotaan)
      localStorage.setItem('newkoperasieditrealisasikreditdata', JSON.stringify({ id: '' }))
      this.$router.push('/koperasi/realisasikredit/form')
    },
    rangedofunction(date, dateString) {
      // this.tgl1 = moment(dateString[0], 'DD-MM-YYYY').format('YYYY-MM-DD')
      // this.tgl2 = moment(dateString[1], 'DD-MM-YYYY').format('YYYY-MM-DD')
      // this.getAllData()
    },
    async getAllData() {
      var indexeddb = await lou.getDataFromDb()
      // console.log('indexeddb', indexeddb)
      // console.log('this.settings.whatapp', this.settings.whatapp)
      var allmaster = {}
      var masterIndex = indexeddb.findIndex(
        (x) =>
          x.mykey ===
          'allmaster' +
            this.settings.whatapp.appName.toLowerCase() +
            this.settings.whatapp.id,
      )
      // console.log('masterIndex', indexeddb[masterIndex])
      if (masterIndex < 0) {
        var res = await lou.customUrlGet2('master', false, true)
        var master = {
          mykey:
            'allmaster' +
            this.settings.whatapp.appName.toLowerCase() +
            this.settings.whatapp.id,
          data: JSON.stringify(res.data),
        }
        await lou.addDataToDb(master)
        var nindexeddb = await lou.getDataFromDb()
        var targetIndex = nindexeddb.findIndex(
          (x) =>
            x.mykey ===
            'allmaster' +
              this.settings.whatapp.appName.toLowerCase() +
              this.settings.whatapp.id,
        )
        // console.log('nindexeddb', nindexeddb[targetIndex])
        allmaster = JSON.parse(nindexeddb[targetIndex].data)
      } else {
        allmaster = JSON.parse(indexeddb[masterIndex].data)
      }
      var cabanglist = []
      for (let u = 0; u < this.user.cabangList.length; u++) {
        const element = this.user.cabangList[u]
        // console.log('element', element)
        cabanglist.push({ kode: element.kode, keterangan: element.kode })
      }
      // console.log('indexeddb', indexeddb)
      this.allMaster = allmaster
      // console.log('this.allMaster', this.allMaster)
      var response = await lou.customUrlGet2('data_kredit')
      if (response) {
        this.data = response.data
      }
      // console.log('this.data', this.data)
    },
    sendToEdit(edata) {
      // var ndata = {}
      // for (let i = 0; i < this.columns.length; i++) {
      //   const element = this.columns[i].dataIndex
      //   ndata[element.toString().toLowerCase()] = edata[element]
      // }
      // console.log('edata', edata)
      localStorage.setItem('newkoperasieditrealisasikreditdata', JSON.stringify(edata))
      this.$router.push('/koperasi/realisasikredit/form')
    },
    showDeleteConfirm(deldata) {
      Modal.confirm({
        title: 'Are you sure delete this data?',
        content: "You can't prevent this!!! ",
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'No',
        onOk: async () => {
          var response = await lou.customUrlDelete2('data_kredit/' + deldata.id)
          this.data = response.data
          this.getAllData()
        },
        onCancel: () => {
          // console.log('Cancel')
        },
      })
    },
    findRelationData(data, mastername, key, datakey, label) {
      var target = this[mastername].findIndex(x => x[key] === data[datakey])
      // console.log('data', data)
      // console.log('mastername', mastername)
      // console.log('key', key)
      // console.log('target', target)
      // console.log('this.allMaster[mastername][target]', this.allMaster[mastername][target])
      // console.log('this[mastername][target]', this[mastername][target])
      return target === -1 ? 'Data tidak ditemukan' : this[mastername][target][label]
    },
    formatCurrency(v) {
      return v === '' || v === null ? 0 : lou.curency(v)
    },
    formatDate(value) {
      return moment(value).format('DD-MM-YYYY')
    },
    formatBoolean(v) {
      if (v) {
        return 'Ya'
      } else {
        return 'Tidak'
      }
    },
    handleSearch(selectedKeys, confirm, dataIndex) {
      confirm()
      this.searchText = selectedKeys[0]
      this.searchedColumn = dataIndex
    },
    handleReset(clearFilters) {
      clearFilters()
      this.searchText = ''
    },
  },
}
</script>

<style scoped>
.highlight {
  background-color: rgb(255, 192, 105);
  padding: 0px;
}
</style>
