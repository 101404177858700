<template>
  <div>
    <a-card>
      <template slot="title">
        <strong class="mr-4">Data Golongan Kredit</strong>
        <!-- <a-range-picker class="ml-4" :format="'DD-MM-YYYY'" @change="rangedofunction"/> -->
        <!-- <a-button
          type="button"
          class="btn btn-outline-primary ml-2"
          @click="connector('nonanggota')"
        >
          Tambah Non Anggota
        </a-button> -->
        <a-button
          type="button"
          class="btn btn-outline-primary "
          @click="showModal('create', {})"
        >
          Tambah
        </a-button>
            <a-button
              class="btn btn-outline-success ml-2"
              @click="getAllData(true)"
              >Refresh Data</a-button
            >
      </template>
      <a-table
        :scroll="{ x:700 }"
        :columns="columns"
        :dataSource="data"
        :pagination="{
          hideOnSinglePage:true,
          defaultPageSize: 10,
          showQuickJumper: true,
          showSizeChanger: true,
          showTotal: (total) => `Total ${total} items`,
          pageSizeOptions: ['10', '20', '30'],
        }"
        size="small"
      >
        <div
          slot="filterDropdown"
          slot-scope="{
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
            column,
          }"
          style="padding: 8px;"
        >
          <a-input
            v-ant-ref="(c) => (searchInput = c)"
            :placeholder="`Search ${column.dataIndex}`"
            :value="selectedKeys[0]"
            style="width: 188px; margin-bottom: 8px; display: block;"
            @change="
              (e) => setSelectedKeys(e.target.value ? [e.target.value] : [])
            "
            @pressEnter="
              () => handleSearch(selectedKeys, confirm, column.dataIndex)
            "
          />
          <a-button
            type="primary"
            icon="search"
            size="small"
            style="width: 90px; margin-right: 8px;"
            @click="
              () => handleSearch(selectedKeys, confirm, column.dataIndex)
            "
          >
            Search
          </a-button>
          <a-button
            size="small"
            style="width: 90px;"
            @click="() => handleReset(clearFilters)"
          >
            Reset
          </a-button>
        </div>
        <a-icon
          slot="filterIcon"
          slot-scope="filtered"
          type="search"
          :style="{ color: filtered ? '#108ee9' : undefined }"
        ></a-icon>
        <template
          slot="customRender"
          slot-scope="text, record, index, column"
        >
          <span v-if="searchText && searchedColumn === column.dataIndex">
            <template
              v-for="(fragment, i) in text
                .toString()
                .split(
                  new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i')
                )"
            >
              <mark
                v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                :key="i"
                class="highlight"
                >{{ fragment }}</mark
              >
              <template v-else>{{ fragment }}</template>
            </template>
          </span>
          <template v-else>
            {{ text }}
          </template>
        </template>
        <a slot="name" slot-scope="text" href="javascript:;">{{ text }}</a>
        <span slot="customTitle"><a-icon type="smile-o" /> Kode</span>
        <span slot="tags" slot-scope="tags">
          <a-tag v-for="tag in tags" color="blue" :key="tag">{{ tag }}</a-tag>
        </span>
        <span slot="action" slot-scope="text, record">
          <a href="javascript:void(0)" class="text-warning" @click="showModal('update', record)">
            <a-icon type="edit"></a-icon>
          </a>
          <a-divider type="vertical" />
          <a href="javascript:void(0)" class="text-danger" @click="showDeleteConfirm(record)">
            <a-icon type="delete"></a-icon>
          </a>
          <!-- <a href="javascript:;" class="ant-dropdown-link">
            More actions <a-icon type="down" />
          </a> -->
        </span>
        <span slot="jenis_identitas" slot-scope="text, record">
          {{ findRelationData(record, 'masterJenisIndentitas', 'id', 'jenis_identitas', 'keterangan') }}
        </span>
        <span slot="tgl" slot-scope="text, record">
          {{ formatDate(record.tgl) }}
        </span>
      </a-table>
    </a-card>
    <a-modal :maskClosable="false" v-model="formvisible" title="Form Golongan Kredit" :width="850" :dialogStyle="{ top: '10px' }">
      <template slot="footer">
        <a-button class="" type="danger" @click="handleCancel">Cancel</a-button>
        <a-button class="" type="primary" @click="handleSubmit">Submit</a-button>
      </template>
      <div class="row">
        <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3">
          <label class="mt-1">Kode</label>
        </div>
        <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9">
          <a-input class="" v-model="modalInput.kode" placeholder="kode"></a-input>
        </div>
        <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
          <label class="mt-1">Keterangan</label>
        </div>
        <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
          <a-input class="" v-model="modalInput.keterangan" placeholder="keterangan"></a-input>
        </div>
        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
          <div class="row">
        <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 mt-2">
          <label class="mt-1">Rekening</label>
        </div>
        <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8 mt-2">
          <v-select
            placeholder="Ketikan Rekening"
            label="label"
            style="width: 100%;"
            v-model="modalInput.rekening_id"
            :options="masterRekening"
            :reduce="(tes) => tes.id"
            @input="connectValue('rekening_id', 'rekening')"
          />
        </div>
        <!-- <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-1">
          <v-select
            placeholder="Keterangan"
            label="keterangan"
            v-model="modalInput.rekening"
            :options="masterRekening"
            :reduce="(tes) => tes.kode"
            @input="connectValue('rekening', 'rekening_id')"
          />
        </div> -->
        <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 mt-2">
          <label class="mt-1">R.Bunga</label>
        </div>
        <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8 mt-2">
          <v-select
            placeholder="Ketikan Rekening"
            label="label"
            style="width: 100%;"
            v-model="modalInput.rekeningbunga_id"
            :options="masterRekening"
            :reduce="(tes) => tes.id"
            @input="connectValue('rekeningbunga_id', 'rekeningbunga')"
          />
        </div>
        <!-- <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-1">
          <v-select
            placeholder="Keterangan"
            label="keterangan"
            v-model="modalInput.rekeningbunga"
            :options="masterRekening"
            :reduce="(tes) => tes.kode"
            @input="connectValue('rekeningbunga', 'rekeningbunga_id')"
          />
        </div> -->
        <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 mt-2">
          <label class="mt-1">R.Denda</label>
        </div>
        <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8 mt-2">
          <v-select
            placeholder="Ketikan Rekening"
            label="label"
            style="width: 100%;"
            v-model="modalInput.rekeningdenda_id"
            :options="masterRekening"
            :reduce="(tes) => tes.id"
            @input="connectValue('rekeningdenda_id', 'rekeningdenda')"
          />
        </div>
        <!-- <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-1">
          <v-select
            placeholder="Keterangan"
            label="keterangan"
            v-model="modalInput.rekeningdenda"
            :options="masterRekening"
            :reduce="(tes) => tes.kode"
            @input="connectValue('rekeningdenda', 'rekeningdenda_id')"
          />
        </div> -->
        <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 mt-2">
          <label class="mt-1">R.Provisi</label>
        </div>
        <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8 mt-2">
          <v-select
            placeholder="Ketikan Rekening"
            label="label"
            style="width: 100%;"
            v-model="modalInput.rekeningprovisi_id"
            :options="masterRekening"
            :reduce="(tes) => tes.id"
            @input="connectValue('rekeningprovisi_id', 'rekeningprovisi')"
          />
        </div>
        <!-- <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-1">
          <v-select
            placeholder="Keterangan"
            label="keterangan"
            v-model="modalInput.rekeningprovisi"
            :options="masterRekening"
            :reduce="(tes) => tes.kode"
            @input="connectValue('rekeningprovisi', 'rekeningprovisi_id')"
          />
        </div> -->
        <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 mt-2">
          <label class="mt-1">R.Administrasi</label>
        </div>
        <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8 mt-2">
          <v-select
            placeholder="Ketikan Rekening"
            label="label"
            style="width: 100%;"
            v-model="modalInput.rekeningadministrasi_id"
            :options="masterRekening"
            :reduce="(tes) => tes.id"
            @input="connectValue('rekeningadministrasi_id', 'rekeningadministrasi')"
          />
        </div>
        <!-- <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-1">
          <v-select
            placeholder="Keterangan"
            label="keterangan"
            v-model="modalInput.rekeningadministrasi"
            :options="masterRekening"
            :reduce="(tes) => tes.kode"
            @input="connectValue('rekeningadministrasi', 'rekeningadministrasi_id')"
          />
        </div> -->
        <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 mt-2">
          <label class="mt-1">R.Materai</label>
        </div>
        <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8 mt-2">
          <v-select
            placeholder="Ketikan Rekening"
            label="label"
            style="width: 100%;"
            v-model="modalInput.rekeningmaterai_id"
            :options="masterRekening"
            :reduce="(tes) => tes.id"
            @input="connectValue('rekeningmaterai_id', 'rekeningmaterai')"
          />
        </div>
        <!-- <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-1">
          <v-select
            placeholder="Keterangan"
            label="keterangan"
            v-model="modalInput.rekeningmaterai"
            :options="masterRekening"
            :reduce="(tes) => tes.kode"
            @input="connectValue('rekeningmaterai', 'rekeningmaterai_id')"
          />
        </div> -->
          </div>
        </div>
        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
          <div class="row">
        <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 mt-2">
          <label class="mt-1">R.Notaris</label>
        </div>
        <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8 mt-2">
          <v-select
            placeholder="Ketikan Rekening"
            label="label"
            style="width: 100%;"
            v-model="modalInput.rekeningnotaris_id"
            :options="masterRekening"
            :reduce="(tes) => tes.id"
            @input="connectValue('rekeningnotaris_id', 'rekeningnotaris')"
          />
        </div>
        <!-- <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-1">
          <v-select
            placeholder="Keterangan"
            label="keterangan"
            v-model="modalInput.rekeningnotaris"
            :options="masterRekening"
            :reduce="(tes) => tes.kode"
            @input="connectValue('rekeningnotaris', 'rekeningnotaris_id')"
          />
        </div> -->

        <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 mt-2">
          <label class="mt-1">R.Titipan</label>
        </div>
        <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8 mt-2">
          <v-select
            placeholder="Ketikan Rekening"
            label="label"
            style="width: 100%;"
            v-model="modalInput.rekeningtitipan_id"
            :options="masterRekening"
            :reduce="(tes) => tes.id"
            @input="connectValue('rekeningtitipan_id', 'rekeningtitipan')"
          />
        </div>
        <!-- <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-1">
          <v-select
            placeholder="Keterangan"
            label="keterangan"
            v-model="modalInput.rekeningtitipan"
            :options="masterRekening"
            :reduce="(tes) => tes.kode"
            @input="connectValue('rekeningtitipan', 'rekeningtitipan_id')"
          />
        </div> -->
        <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 mt-2">
          <label class="mt-1">R.Asuransi</label>
        </div>
        <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8 mt-2">
          <v-select
            placeholder="Ketikan Rekening"
            label="label"
            style="width: 100%;"
            v-model="modalInput.rekeningasuransi_id"
            :options="masterRekening"
            :reduce="(tes) => tes.id"
            @input="connectValue('rekeningasuransi_id', 'rekeningasuransi')"
          />
        </div>
        <!-- <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-1">
          <v-select
            placeholder="Keterangan"
            label="keterangan"
            v-model="modalInput.rekeningasuransi"
            :options="masterRekening"
            :reduce="(tes) => tes.kode"
            @input="connectValue('rekeningasuransi', 'rekeningasuransi_id')"
          />
        </div> -->
        <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 mt-2">
          <label class="mt-1">R.Angsuran Pokok</label>
        </div>
        <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8 mt-2">
          <v-select
            placeholder="Ketikan Rekening"
            label="label"
            style="width: 100%;"
            v-model="modalInput.rekeningangsuranpokok_id"
            :options="masterRekening"
            :reduce="(tes) => tes.id"
            @input="connectValue('rekeningangsuranpokok_id', 'rekeningangsuranpokok')"
          />
        </div>
        <!-- <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-1">
          <v-select
            placeholder="Keterangan"
            label="keterangan"
            v-model="modalInput.rekeningangsuranpokok"
            :options="masterRekening"
            :reduce="(tes) => tes.kode"
            @input="connectValue('rekeningangsuranpokok', 'rekeningangsuranpokok_id')"
          />
        </div> -->
        <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 mt-2">
          <label class="mt-1">R.Angsuran Bunga</label>
        </div>
        <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8 mt-2">
          <v-select
            placeholder="Ketikan Rekening"
            label="label"
            style="width: 100%;"
            v-model="modalInput.rekeningangsuranbunga_id"
            :options="masterRekening"
            :reduce="(tes) => tes.id"
            @input="connectValue('rekeningangsuranbunga_id', 'rekeningangsuranbunga')"
          />
        </div>
        <!-- <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-1">
          <v-select
            placeholder="Keterangan"
            label="keterangan"
            v-model="modalInput.rekeningangsuranbunga"
            :options="masterRekening"
            :reduce="(tes) => tes.kode"
            @input="connectValue('rekeningangsuranbunga', 'rekeningangsuranbunga_id')"
          />
        </div> -->
        <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 mt-2">
          <label class="mt-1">R.Selisih</label>
        </div>
        <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8 mt-2">
          <v-select
            placeholder="Ketikan Rekening"
            label="label"
            style="width: 100%;"
            v-model="modalInput.rekeningselisih_id"
            :options="masterRekening"
            :reduce="(tes) => tes.id"
            @input="connectValue('rekeningselisih_id', 'rekeningselisih')"
          />
        </div>
        <!-- <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-1">
          <v-select
            placeholder="Keterangan"
            label="keterangan"
            v-model="modalInput.rekeningselisih"
            :options="masterRekening"
            :reduce="(tes) => tes.kode"
            @input="connectValue('rekeningselisih', 'rekeningselisih_id')"
          />
        </div> -->
          </div>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
/* Import Script */
import { Modal } from 'ant-design-vue'
import * as lou from '@/services/data/lou'
import moment from 'moment'
// import Cleave from 'vue-cleave-component'
// import { mapState } from 'vuex'
/* End Script Import Script */

/* Kolom atau header dari table */
const columns = [
  {
    title: 'Action',
    scopedSlots: { customRender: 'action' },
    fixed: 'left',
    width: 70,
  },
  {
    title: 'Kode',
    dataIndex: 'kode',
    // fixed: 'left',
    width: 100,
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
    onFilter: (value, record) =>
      record.kode.toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => {
          this.searchInput.focus()
        }, 0)
      }
    },
  },
  {
    title: 'Keterangan',
    dataIndex: 'keterangan',
    // fixed: 'left',
    width: 100,
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
    onFilter: (value, record) =>
      record.keterangan.toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => {
          this.searchInput.focus()
        }, 0)
      }
    },
  },
  {
    title: 'Rekening',
    dataIndex: 'rekening',
    // fixed: 'left',
    width: 100,
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
    onFilter: (value, record) =>
      record.rekening.toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => {
          this.searchInput.focus()
        }, 0)
      }
    },
  },
  {
    title: 'Rekening Bunga',
    dataIndex: 'rekeningbunga',
    // fixed: 'left',
    width: 100,
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
    onFilter: (value, record) =>
      record.rekeningbunga.toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => {
          this.searchInput.focus()
        }, 0)
      }
    },
  },
  {
    title: 'Rekening Denda',
    dataIndex: 'rekeningdenda',
    // fixed: 'left',
    width: 100,
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
    onFilter: (value, record) =>
      record.rekeningdenda.toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => {
          this.searchInput.focus()
        }, 0)
      }
    },
  },
  {
    title: 'Rekening Provisi',
    dataIndex: 'rekeningprovisi',
    // fixed: 'left',
    width: 100,
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
    onFilter: (value, record) =>
      record.rekeningprovisi.toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => {
          this.searchInput.focus()
        }, 0)
      }
    },
  },
  {
    title: 'Rekening Administrasi',
    dataIndex: 'rekeningadministrasi',
    // fixed: 'left',
    width: 100,
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
    onFilter: (value, record) =>
      record.rekeningadministrasi.toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => {
          this.searchInput.focus()
        }, 0)
      }
    },
  },
  {
    title: 'Rekening Materai',
    dataIndex: 'rekeningmaterai',
    // fixed: 'left',
    width: 100,
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
    onFilter: (value, record) =>
      record.rekeningmaterai.toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => {
          this.searchInput.focus()
        }, 0)
      }
    },
  },
  {
    title: 'Rekening Notaris',
    dataIndex: 'rekeningnotaris',
    // fixed: 'left',
    width: 100,
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
    onFilter: (value, record) =>
      record.rekeningnotaris.toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => {
          this.searchInput.focus()
        }, 0)
      }
    },
  },
  {
    title: 'Rekening Titipan',
    dataIndex: 'rekeningtitipan',
    // fixed: 'left',
    width: 100,
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
    onFilter: (value, record) =>
      record.rekeningtitipan.toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => {
          this.searchInput.focus()
        }, 0)
      }
    },
  },
  {
    title: 'Rekening Asuransi',
    dataIndex: 'rekeningasuransi',
    // fixed: 'left',
    width: 100,
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
    onFilter: (value, record) =>
      record.rekeningasuransi.toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => {
          this.searchInput.focus()
        }, 0)
      }
    },
  },
  {
    title: 'Rekening Angsuran Pokok',
    dataIndex: 'rekeningangsuranpokok',
    // fixed: 'left',
    width: 100,
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
    onFilter: (value, record) =>
      record.rekeningangsuranpokok.toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => {
          this.searchInput.focus()
        }, 0)
      }
    },
  },
  {
    title: 'Rekening Angsuran Bunga',
    dataIndex: 'rekeningangsuranbunga',
    // fixed: 'left',
    width: 100,
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
    onFilter: (value, record) =>
      record.rekeningangsuranbunga.toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => {
          this.searchInput.focus()
        }, 0)
      }
    },
  },
  {
    title: 'Rekening Selisih',
    dataIndex: 'rekeningselisih',
    // fixed: 'left',
    width: 100,
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
    onFilter: (value, record) =>
      record.rekeningselisih.toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => {
          this.searchInput.focus()
        }, 0)
      }
    },
  },
]
/* End script Kolom atau header dari table */

/* Data dari table */
const data = []
/* End script Data dari table */

export default {
  components: {
    // cleave: Cleave,
  },
  computed: {
    // ...mapState(['settings', 'user']),
  },
  created () {
    this.getAllData()
  },
  /* Data variable vue */
  data() {
    return {
      searchText: '',
      tgl1: '',
      tgl2: '',
      searchInput: null,
      searchedColumn: '',
      name: 'Tabletabungan',
      visible: false,
      allMaster: [],
      masterstatuskawin: [],
      masterkelamin: [],
      data,
      columns,
      datenow: '',
      componentKey: 0,

      // Modal property
      action: null,
      formvisible: false,
      editdata: {},
      masterRekening: [],
      modalInput: {
        kode: '',
        keterangan: '',
        rekening: '',
        rekeningbunga: '',
        rekeningdenda: '',
        rekeningprovisi: '',
        rekeningadministrasi: '',
        rekeningmaterai: '',
        rekeningnotaris: '',
        rekeningtitipan: '',
        rekeningasuransi: '',
        rekeningangsuranpokok: '',
        rekeningangsuranbunga: '',
        rekeningselisih: '',
        rekening_id: '',
        rekeningbunga_id: '',
        rekeningdenda_id: '',
        rekeningprovisi_id: '',
        rekeningadministrasi_id: '',
        rekeningmaterai_id: '',
        rekeningnotaris_id: '',
        rekeningtitipan_id: '',
        rekeningasuransi_id: '',
        rekeningangsuranpokok_id: '',
        rekeningangsuranbunga_id: '',
        rekeningselisih_id: '',
      },
    }
  },
  /* End Data variable vue */
  mounted: function () {
    const date = moment().format('YYYY-MM-DD') // Tanggal hari ini format 2020-12-41
    // localStorage.removeItem('tabungankuedittabungandata')
    this.tgl1 = date
    this.tgl2 = date
    this.datenow = date.toString()
    // this.getAgamaF()
  },
  methods: {
    moment,
    connectValue(firstInput, secondInput) {
      var target = this.masterRekening.findIndex(
        (x) => x.id === this.modalInput[firstInput],
      )
      // console.log('target', target)
      if (target !== -1) {
        // console.log('this.masterRekening[target]', this.masterRekening[target])
        this.modalInput[secondInput] = this.masterRekening[target].kode
      }
    },
    async showModal(action, data) {
      var masterRekening = await lou.customUrlGet2('master_akuntansi')
      if (masterRekening) {
        this.masterRekening = []
        // this.masterRekening = this.$g.clone(masterRekening.data)
        masterRekening.data.forEach(element => {
          var nobj = {
            label: element.kode + ' - ' + element.keterangan,
            ...element,
          }
          this.masterRekening.push(nobj)
        })
        this.formvisible = true
        this.action = action
        if (action === 'update') {
          this.modalInput = this.$g.clone(data)
          this.editdata = data
        }
      } else {
        lou.shownotif('error', 'Master akuntansi bermasalah silahkan hubungi admin!', 'error')
      }
    },
    async getAllData(onclick = false) {
      var response = await lou.customUrlGet2('golongankredit')
      if (response) {
        this.data = response.data
        if (onclick) {
          lou.shownotif('Refresh', 'Data telah di refresh.')
        }
      }
      // console.log('this.data', this.data)
    },
    formatDate(value) {
      return moment(value).format('DD-MM-YYYY')
    },
    showDeleteConfirm(deldata) {
      Modal.confirm({
        title: 'Are you sure delete this data?',
        content: "You can't prevent this!!! ",
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'No',
        onOk: async () => {
          var response = await lou.customUrlDelete2('golongankredit/' + deldata.id)
          if (response) {
            this.getAllData()
          }
        },
        onCancel: () => {
          // console.log('Cancel')
        },
      })
    },
    handleSearch(selectedKeys, confirm, dataIndex) {
      confirm()
      this.searchText = selectedKeys[0]
      this.searchedColumn = dataIndex
    },

    handleReset(clearFilters) {
      clearFilters()
      this.searchText = ''
    },

    // Modal function
    async handleSubmit() {
      var fd = this.$g.clone(this.modalInput)
      var res = false
      if (this.action === 'update') {
        res = await lou.customUrlPut2('golongankredit', fd)
      } else {
        res = await lou.customUrlPost2('golongankredit', fd)
      }
      if (res) {
        this.getAllData()
        this.handleCancel()
      }
    },
    handleCancel() {
      this.formvisible = false
      this.action = 'create'
      this.modalInput = {
        kode: '',
        keterangan: '',
        rekening: '',
        rekeningbunga: '',
        rekeningdenda: '',
        rekeningprovisi: '',
        rekeningadministrasi: '',
        rekeningmaterai: '',
        rekeningnotaris: '',
        rekeningtitipan: '',
        rekeningasuransi: '',
        rekeningangsuranpokok: '',
        rekeningangsuranbunga: '',
        rekeningselisih: '',
        rekening_id: 0,
        rekeningbunga_id: 0,
        rekeningdenda_id: 0,
        rekeningprovisi_id: 0,
        rekeningadministrasi_id: 0,
        rekeningmaterai_id: 0,
        rekeningnotaris_id: 0,
        rekeningtitipan_id: 0,
        rekeningasuransi_id: 0,
        rekeningangsuranpokok_id: 0,
        rekeningangsuranbunga_id: 0,
        rekeningselisih_id: 0,
      }
    },
    filterOption(input, option) {
      var text = option.componentOptions.children[0].data.domProps.innerHTML + option.componentOptions.children[1].text
      return (
        text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      )
    },
  },
}
</script>

<style scoped>
.highlight {
  background-color: rgb(255, 192, 105);
  padding: 0px;
}
</style>
