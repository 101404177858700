<template>
  <div>
    <a-card>
      <template slot="title">
        <strong class="mr-4">Data Complain</strong>
        <!-- <a-range-picker class="ml-2" :format="'DD-MM-YYYY'" @change="rangedofunction"/> -->
        <!-- <a-button
          type="button"
          class="btn btn-outline-primar10 ml-2"
10        @click="connecto10('nonanggota'10"
        >
          Tambah Non Anggota
        </a-button> -->
        <a-button-group>
          <a-button
            icon="plus-circle"
            class="text-primary"
            type=""
            @click="showModal('create', {})"
          >
            Tambah
          </a-button>
          <a-tooltip placement="top">
            <template slot="title">
              <span>Refresh Data</span>
            </template>
            <a-button @click="getAllData(true)">
              <a-icon type="sync" :spin="loadingRefresh" class="text-success" />
            </a-button>
          </a-tooltip>
          <a-tooltip placement="top">
            <template slot="title">
              <span>Export Data</span>
            </template>
            <a-button @click="exportExcel">
              <a-icon type="file-excel" class="text-success" />
            </a-button>
          </a-tooltip>
          <a-tooltip placement="top">
            <template slot="title">
              <span>Print Preview</span>
            </template>
            <a-button @click="exportPdf">
              <a-icon type="printer" class="text-success"/>
            </a-button>
          </a-tooltip>
        </a-button-group>
      </template>
      <a-table
        style="margin: -25px -25px"
        :columns="columns"
        :dataSource="data"
        :pagination="{
          hideOnSinglePage: true,
          defaultPageSize: 1000000,
          showQuickJumper: true,
          showSizeChanger: true,
          showTotal: (total) => `Total ${total} items`,
          pageSizeOptions: ['10', '20', '30'],
        }"
        size="small"
      >
        <div
          slot="filterDropdown"
          slot-scope="{
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
            column,
          }"
          style="padding: 8px"
        >
          <a-input
            v-ant-ref="(c) => (searchInput = c)"
            :placeholder="`Search ${column.title}`"
            :value="selectedKeys[0]"
            style="width: 188px; margin-bottom: 8px; display: block"
            @change="
              (e) => setSelectedKeys(e.target.value ? [e.target.value] : [])
            "
            @pressEnter="
              () => handleSearch(selectedKeys, confirm, column.dataIndex)
            "
          />
          <a-button
            type="primary"
            icon="search"
            size="small"
            style="width: 90px; margin-right: 8px"
            @click="() => handleSearch(selectedKeys, confirm, column.dataIndex)"
          >
            Search
          </a-button>
          <a-button
            size="small"
            style="width: 90px"
            @click="() => handleReset(clearFilters)"
          >
            Reset
          </a-button>
        </div>
        <a-icon
          slot="filterIcon"
          slot-scope="filtered"
          type="search"
          :style="{ color: filtered ? '#108ee9' : undefined }"
        ></a-icon>
        <template slot="customRender" slot-scope="text, record, index, column">
          <span v-if="searchText && searchedColumn === column.dataIndex">
            <template
              v-for="(fragment, i) in text
                .toString()
                .split(new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i'))"
            >
              <mark
                v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                :key="i"
                class="highlight"
                >{{ fragment }}</mark
              >
              <template v-else>{{ fragment }}</template>
            </template>
          </span>
          <template v-else>
            {{ text }}
          </template>
        </template>
        <a slot="name" slot-scope="text" href="javascript:;">{{ text }}</a>
        <span slot="customTitle"><a-icon type="smile-o" /> Kode</span>
        <!-- <span slot="reorder" slot-scope="text">
          <a-tag :color="text === 1 ? 'green' : 'red'">{{ text === 1 ? 'Reorder' : 'Tidak' }}</a-tag>
        </span> -->
        <span slot="action" slot-scope="text, record">
          <a
            href="javascript:void(0)"
            class="text-warning"
            @click="showModal('update', record)"
          >
            <a-icon type="edit"></a-icon>
          </a>
          <a-divider type="vertical" />
          <a
            href="javascript:void(0)"
            class="text-danger"
            @click="showDeleteConfirm(record)"
          >
            <a-icon type="delete"></a-icon>
          </a>
          <!-- <a href="javascript:;" class="ant-dropdown-link">
            More actions <a-icon type="down" />
          </a> -->
        </span>
        <span slot="formatCurrency" slot-scope="text">
          {{ formatCurrency(text) }}
        </span>
        <span slot="tgl" slot-scope="text">
          {{ formatDate(text) }}
        </span>
      </a-table>
    </a-card>
    <a-modal
      v-model="formvisible"
      title="Form Complain"
      :dialogStyle="{ top: '10px' }"
    >
      <template slot="footer">
        <a-button class="btn btn-outline-danger" @click="handleCancel"
          >Cancel</a-button
        >
        <a-button class="btn btn-outline-primary" @click="handleSubmit"
          >Submit</a-button
        >
      </template>
      <div class="row">
        <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-1">
          <label class="">Tanggal</label>
        </div>
        <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9">
          <a-date-picker
          v-model="modalInput.tgl"
            style="width: 125px"
            :allowClear="false"
            format="DD-MM-YYYY"
            @change="filterDate"
          />
        </div>
        <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
          <label class="">Nama Customer</label>
        </div>
        <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
          <a-input
            class=""
            placeholder="Masukan Nama Customer"
            v-model="modalInput.nama_customer"
            ref="nama_customer"
          ></a-input>
          <!-- <cleave
                ref="angka_meter"
                :options="{ numeral: true }"
                class="ant-input mr-1"
                :raw="true"
                style="text-align: right;width: 100%;"
                v-model="modalInput.angka_meter"
                placeholder="Nominal"
                /> -->
        </div>
        <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
          <label class="">Nama Produk</label>
        </div>
        <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
          <a-input
            class=""
            placeholder="Masukan Nama Produk"
            v-model="modalInput.nama_produk"
            ref="nama_produk"
          ></a-input>
          <!-- <cleave
                ref="angka_meter"
                :options="{ numeral: true }"
                class="ant-input mr-1"
                :raw="true"
                style="text-align: right;width: 100%;"
                v-model="modalInput.angka_meter"
                placeholder="Nominal"
                /> -->
        </div>
        <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
          <label class="">Keluhan</label>
        </div>
        <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
          <a-input
            class=""
            placeholder="Masukan Keluhan"
            v-model="modalInput.keluhan"
            ref="keluhan"
          ></a-input>
          <!-- <cleave
                ref="angka_meter"
                :options="{ numeral: true }"
                class="ant-input mr-1"
                :raw="true"
                style="text-align: right;width: 100%;"
                v-model="modalInput.angka_meter"
                placeholder="Nominal"
                /> -->
        </div>
        <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
          <label class="">Bagian Terkait</label>
        </div>
        <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
          <a-input
            class=""
            placeholder="Masukan Bagian Terkait"
            v-model="modalInput.bagian_terkait"
            ref="bagian_terkait"
          ></a-input>
          <!-- <cleave
                ref="angka_meter"
                :options="{ numeral: true }"
                class="ant-input mr-1"
                :raw="true"
                style="text-align: right;width: 100%;"
                v-model="modalInput.angka_meter"
                placeholder="Nominal"
                /> -->
        </div>
        <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
          <label class="">Tindakan</label>
        </div>
        <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
          <a-input
            class=""
            placeholder="Masukan Tindakan"
            v-model="modalInput.tindakan"
            ref="tindakan"
          ></a-input>
          <!-- <cleave
                ref="angka_meter"
                :options="{ numeral: true }"
                class="ant-input mr-1"
                :raw="true"
                style="text-align: right;width: 100%;"
                v-model="modalInput.angka_meter"
                placeholder="Nominal"
                /> -->
        </div>
        <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
          <label class="">Status</label>
        </div>
        <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
          <!-- <a-input
            class=""
            placeholder="Masukan Status"
            v-model="modalInput.status"
            ref="status"
          ></a-input> -->
          <v-select
              ref="status"
              placeholder="Pilih Status"
              class="style-chooser"
              label="label"
              v-model="modalInput.status"
              :options="masterStatus"
              :reduce="(tes) => tes.kode"
            />
          <!-- <cleave
                ref="angka_meter"
                :options="{ numeral: true }"
                class="ant-input mr-1"
                :raw="true"
                style="text-align: right;width: 100%;"
                v-model="modalInput.angka_meter"
                placeholder="Nominal"
                /> -->
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
/* Import Script */
import { Modal } from 'ant-design-vue'
import * as lou from '@/services/data/lou'
import moment from 'moment'
import NProgress from 'nprogress'
// import cleave from 'vue-cleave-component'
// import Cleave from 'vue-cleave-component'
// import { mapState } from 'vuex'
/* End Script Import Script */

/* Kolom atau header dari table */
/* End script Kolom atau header dari table */

/* Data dari table */
const data = []
/* End script Data dari table */

export default {
  components: {
    // cleave: cleave,
  },
  computed: {
    // ...mapState(['settings', 'user']),
  },
  created() {
    this.getAllData()
  },
  /* Data variable vue */
  data() {
    return {
      loadingRefresh: false,
      searchText: '',
      tgl1: '',
      tgl2: '',
      searchInput: null,
      searchedColumn: '',
      name: '',
      visible: false,
      allMaster: [],
      masterDataSupplier: [],
      masterDataKategori: [],
      masterStatus: [
        {
          kode: 'Diterima',
          label: 'Diterima',
        },
        {
          kode: 'Diproses',
          label: 'Diproses',
        },
        {
          kode: 'Selesai',
          label: 'Selesai',
        },
        {
          kode: 'Dibatalkan',
          label: 'Dibatalkan',
        },
      ],
      data,
      columns: [
        {
          title: 'Action',
          scopedSlots: { customRender: 'action' },
          width: 70,
          // fixed: 'left',
        },
        // {
        //   title: 'No Order',
        //   dataIndex: 'order_no',
        //   width: 150,
        //   // fixed: 'left',
        //   scopedSlots: {
        //     filterDropdown: 'filterDropdown',
        //     filterIcon: 'filterIcon',
        //     customRender: 'customRender',
        //   },
        //   onFilter: (value, record) =>
        //     record.order_no
        //       .toString()
        //       .toLowerCase()
        //       .includes(value.toLowerCase()),
        //   onFilterDropdownVisibleChange: (visible) => {
        //     if (visible) {
        //       setTimeout(() => {
        //         this.searchInput.focus()
        //       }, 0)
        //     }
        //   },
        // },
        {
          title: 'Tanggal',
          dataIndex: 'tgl',
          width: 100,
          // fixed: 'left',
          scopedSlots: {
            customRender: 'tgl',
          },
        },
        {
          title: 'N. Customer',
          dataIndex: 'nama_customer',
          width: 150,
          // fixed: 'left',
          scopedSlots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
            customRender: 'customRender',
          },
          onFilter: (value, record) =>
            record.nama_customer
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus()
              }, 0)
            }
          },
        },
        {
          title: 'N. Produk',
          dataIndex: 'nama_produk',
          width: 150,
          // fixed: 'left',
          scopedSlots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
            customRender: 'customRender',
          },
          onFilter: (value, record) =>
            record.nama_produk
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus()
              }, 0)
            }
          },
        },
        {
          title: 'Keluhan',
          dataIndex: 'keluhan',
          width: 150,
          // fixed: 'left',
          scopedSlots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
            customRender: 'customRender',
          },
          onFilter: (value, record) =>
            record.keluhan
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus()
              }, 0)
            }
          },
        },
        {
          title: 'Bagian Terkait',
          dataIndex: 'bagian_terkait',
          width: 150,
          // fixed: 'left',
          scopedSlots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
            customRender: 'customRender',
          },
          onFilter: (value, record) =>
            record.bagian_terkait
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus()
              }, 0)
            }
          },
        },
        {
          title: 'Tindakan',
          dataIndex: 'tindakan',
          width: 150,
          // fixed: 'left',
          scopedSlots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
            customRender: 'customRender',
          },
          onFilter: (value, record) =>
            record.tindakan
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus()
              }, 0)
            }
          },
        },
        {
          title: 'Status',
          dataIndex: 'status',
          width: 150,
          // fixed: 'left',
          scopedSlots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
            customRender: 'customRender',
          },
          onFilter: (value, record) =>
            record.status
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus()
              }, 0)
            }
          },
        },
      ],
      datenow: '',
      componentKey: 0,

      // Modal property
      action: null,
      formvisible: false,
      editdata: {},
      modalInput: {
        tgl: moment(),
        nama_customer: '',
        nama_produk: '',
        keluhan: '',
        bagian_terkait: '',
        tindakan: '',
        status: 'Diterima', // Diterima, Diproses, Selesai, Dibatalkan
      },
    }
  },
  /* End Data variable vue */
  mounted: function () {
    const date = moment().format('YYYY-MM-DD') // Tanggal hari ini format 2020-12-21
    // localStorage.removeItem('newkoperasiedittabungandata')
    this.tgl1 = date
    this.tgl2 = date
    this.datenow = date.toString()
    // this.getDataMaster()
  },
  methods: {
    moment,
    async exportPdf() {
      // this.amodal.selectedIndex = i
      var res = await lou.ngetDocumentPdf('customer_care/complain?preview=pdf', false, false)
      if (res) {
        // console.log("Success", response);
        const blob = new Blob([res], {
          type: 'application/pdf',
        })
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.target = '_blank'
        // link.download = filename
        link.click()
        // const objectUrl = URL.createObjectURL(blob)
        // console.log('objectUrl', objectUrl)
        // this.amodal.ArrayBuffer = objectUrl
        URL.revokeObjectURL(blob)
        setTimeout(() => {
          this.componentKey += 1
          // this.amodalvisible = true
        }, 500)
      }
      // console.log('record.url2_invoice', record.url2_invoice)
      // window.open(record.url2_invoice, '_blank').focus()
    },
    filterDate(date, dateString) {
      // console.log('date', date)
      // setTimeout(() => {
      this.modalInput.tgl = date
      // }, 200)
    },
    showModal(action, data) {
      // this.getDataMaster()
      this.formvisible = true
      this.action = action
      if (action === 'update') {
        this.modalInput = this.$g.clone(data)
        this.editdata = data
        this.modalInput.tgl = moment(this.editdata.tgl, 'YYYY-MM-DD')
        console.log('this.modalInput.tgl', this.modalInput.tgl)
      }
    },
    async getDataMaster() {
      var resmasterDataSupplier = await lou.customUrlGet2('master/supplier')
      var resmasterDataKategori = await lou.customUrlGet2(
        'master/kategori_bahan',
      )
      if (resmasterDataSupplier !== null) {
        this.masterDataSupplier = []
        resmasterDataSupplier.data.forEach((element) => {
          var ret = {
            ...element,
            label: element.nama_perusahaan + ' - ' + element.nama_kontak,
          }
          this.masterDataSupplier.push(ret)
        })
      } else {
        lou.shownotif(
          'Error!',
          'Master Data Supplier failed to fetch from API.',
          'error',
        )
      }
      if (resmasterDataKategori !== null) {
        this.masterDataKategori = resmasterDataKategori.data
      } else {
        lou.shownotif(
          'Error!',
          'Master Data Kategori failed to fetch from API.',
          'error',
        )
      }
    },
    async getAllData(onclick = false) {
      this.loadingRefresh = true
      var response = await lou.customUrlGet2('customer_care/complain')
      if (response) {
        this.data = response.data
        if (onclick) {
          lou.shownotif('Refresh', 'Data telah di refresh.')
        }
        setTimeout(() => {
          this.loadingRefresh = false
        }, 450)
      } else {
        setTimeout(() => {
          this.loadingRefresh = false
        }, 450)
      }
      // console.log('this.data', this.data)
    },
    formatDate(value) {
      return moment(value, 'YYYY-MM-DD').format('DD-MM-YYYY')
    },
    showDeleteConfirm(deldata) {
      Modal.confirm({
        title: 'Are you sure delete this data?',
        content: "You can't prevent this!!! ",
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'No',
        onOk: async () => {
          await lou.customUrlDelete2('customer_care/complain/' + deldata.id)
          // if (response) {
          this.getAllData()
          // }
        },
        onCancel: () => {
          // console.log('Cancel')
        },
      })
    },
    handleSearch(selectedKeys, confirm, dataIndex) {
      confirm()
      this.searchText = selectedKeys[0]
      this.searchedColumn = dataIndex
    },

    handleReset(clearFilters) {
      clearFilters()
      this.searchText = ''
    },

    // Modal function
    async handleSubmit() {
      // console.log('this.modalInput', this.modalInput)
      var fd = this.$g.clone(this.modalInput)
      fd.tgl =
        this.modalInput.tgl !== null && this.modalInput.tgl !== ''
          ? this.modalInput.tgl.format('YYYY-MM-DD')
          : moment()
      // console.log('fd', fd)
      var res = false
      if (this.action === 'create') {
        res = await lou.customUrlPost2('customer_care/complain', fd)
      } else if (this.action === 'update') {
        res = await lou.customUrlPut2('customer_care/complain', fd)
      }
      if (res) {
        this.handleCancel()
        this.getAllData()
      }
    },
    handleCancel() {
      this.formvisible = false
      this.action = 'create'
      this.modalInput = {
        tgl: moment(),
        nama_customer: '',
        nama_produk: '',
        keluhan: '',
        bagian_terkait: '',
        tindakan: '',
        status: 'Diterima', // Diterima, Diproses, Selesai, Dibatalkan
      }
    },
    async exportExcel() {
      NProgress.start()
      const ExcelJS = require('exceljs')
      const workbook = new ExcelJS.Workbook()
      workbook.creator = 'LB-BPRS Heasoft System'
      workbook.lastModifiedBy = 'Me'
      workbook.created = new Date()
      workbook.modified = new Date()
      workbook.lastPrinted = new Date()
      var alphabeth = [
        'A',
        'B',
        'C',
        'D',
        'E',
        'F',
        'G',
        'H',
        'I',
        'J',
        'K',
        'L',
        'M',
        'N',
        'O',
        'P',
        'Q',
        'R',
        'S',
        'T',
        'U',
        'V',
        'W',
        'X',
        'Y',
        'Z',
      ]
      const worksheet = workbook.addWorksheet(moment().format('DD-MM-YYYY'))

      // Table Data
      var column = this.$g.clone(this.columns)
      var tdata = this.$g.clone(this.data)
      var data = []
      tdata.forEach((element) => {
        var retv = {
          ...element,
          tgl:
            element.tgl === null
              ? ''
              : moment(element.tgl, 'YYYY-MM-DD').format('DD-MM-YYYY'),
        }
        data.push(retv)
      })
      var cfirst = 0
      var cloop = 0
      var dincrement = 2
      var columnLocation = []
      for (let i = 0; i < column.length; i++) {
        const element = column[i]
        var cloopni = cloop - 1
        var hname = element.title.toString()
        if (hname !== 'Action') {
          if (cloop === 0) {
            columnLocation.push({
              location: alphabeth[cfirst],
              field: element.dataIndex,
            })
            worksheet.getCell(alphabeth[cfirst] + '1').value = hname.replaceAll(
              ' ',
              '',
            )
          } else {
            columnLocation.push({
              location: alphabeth[cloopni] + alphabeth[cfirst],
              field: element.dataIndex,
            })
            worksheet.getCell(
              alphabeth[cloopni] + alphabeth[cfirst] + '1',
            ).value = hname.replaceAll(' ', '')
          }
          for (let u = 0; u < data.length; u++) {
            const el = data[u]
            if (cloop === 0) {
              worksheet.getCell(alphabeth[cfirst] + dincrement).value =
                el[element.dataIndex]
            } else {
              worksheet.getCell(
                alphabeth[cloopni] + alphabeth[cfirst] + dincrement,
              ).value = el[element.dataIndex]
            }
            var checklast = u + 1
            if (checklast === data.length) {
              dincrement = 2
            } else {
              dincrement += 1
            }
          }
          if (cfirst === 25) {
            cfirst = 0
            cloop += 1
          } else {
            cfirst += 1
          }
        }
      }

      /* For sum data */
      // var last = data.length + 3
      // worksheet.mergeCells('A' + last + ':B' + last)
      // worksheet.getCell('A' + last).value = 'Total'
      // for (let ti = 0; ti < this.columnsTotal.length; ti++) {
      //   const element = this.columnsTotal[ti]
      //   var targetLoc = columnLocation.findIndex(x => x.field === element.dataIndex)
      //   if (targetLoc >= 0) {
      //     worksheet.getCell(columnLocation[targetLoc].location + last).value = this.rowDataTotal[0][element.dataIndex]
      //   }
      //   // console.log(columnLocation[targetLoc].location + last, this.rowDataTotal[0][element.dataIndex])
      // }

      // write to a new buffer
      const buffer = await workbook.xlsx.writeBuffer()
      var blob = new Blob([buffer], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      })
      var link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = 'Data Master Complain.xlsx'
      NProgress.done()
      link.click()
    },
    formatCurrency(v) {
      return lou.curency(v)
    },
  },
}
</script>

<style scoped>
.highlight {
  background-color: rgb(255, 192, 105);
  padding: 0px;
}
</style>
