<template>
  <div>
    <a-card class="">
      <template slot="title">
        <strong>Konfigurasi</strong>
        <a-button class="btn btn-outline-success ml-3" @click="handleSubmit"
          >Save Changes</a-button
        >
      </template>
      <div class="row">
        <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8">
          <div class="row pl-3 pr-3 pt-3 pb-3">
            <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3">
              <label class="mt-1">Akun Simpanan Pokok</label>
            </div>
            <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9">
              <v-select
                :key="componentKey"
                placeholder="Akun Simpanan Pokok"
                label="label"
                v-model="input.akun_simpanan_pokok_id"
                :options="masterRekening"
                :reduce="(tes) => tes.id"
              />
            </div>
            <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-1">
              <label class="mt-1">Akun Simpanan Wajib</label>
            </div>
            <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
              <v-select
                :key="componentKey"
                placeholder="Akun Simpanan Wajib"
                label="label"
                v-model="input.akun_simpanan_wajib_id"
                :options="masterRekening"
                :reduce="(tes) => tes.id"
              />
            </div>
            <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-1">
              <label class="mt-1">Akun PB Simpanan</label>
            </div>
            <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
              <v-select
                :key="componentKey"
                placeholder="Akun PB Simpanan"
                label="label"
                v-model="input.akun_pb_simpanan_id"
                :options="masterRekening"
                :reduce="(tes) => tes.id"
              />
            </div>
            <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-1">
              <label class="mt-1">Akun Laba Berjalan</label>
            </div>
            <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
              <v-select
                :key="componentKey"
                placeholder="Akun Laba Berjalan"
                label="label"
                v-model="input.akun_laba_berjalan_id"
                :options="masterRekening"
                :reduce="(tes) => tes.id"
              />
            </div>
            <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-1">
              <label class="mt-1">Akun Rugi Berjalan</label>
            </div>
            <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
              <v-select
                :key="componentKey"
                placeholder="Akun Rugi Berjalan"
                label="label"
                v-model="input.akun_rugi_berjalan_id"
                :options="masterRekening"
                :reduce="(tes) => tes.id"
              />
            </div>
            <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-1">
              <label class="mt-1">Akun Laba Tahunlalu</label>
            </div>
            <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
              <v-select
                :key="componentKey"
                placeholder="Akun Laba Tahunlalu"
                label="label"
                v-model="input.akun_laba_tahunlalu_id"
                :options="masterRekening"
                :reduce="(tes) => tes.id"
              />
            </div>
            <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-1">
              <label class="mt-1">Akun Rugi Tahunlalu</label>
            </div>
            <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
              <v-select
                :key="componentKey"
                placeholder="Akun Rugi Tahunlalu"
                label="label"
                v-model="input.akun_rugi_tahunlalu_id"
                :options="masterRekening"
                :reduce="(tes) => tes.id"
              />
            </div>
            <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-1">
              <label class="mt-1">Akun Pindah Buku</label>
            </div>
            <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
              <v-select
                :key="componentKey"
                placeholder="Akun Pindah Buku"
                label="label"
                v-model="input.akun_pindahbuku_id"
                :options="masterRekening"
                :reduce="(tes) => tes.id"
              />
            </div>
          </div>
        </div>
      </div>
    </a-card>
  </div>
</template>

<script>
import { Modal } from 'ant-design-vue'
import * as lou from '@/services/data/lou'
import moment from 'moment'

export default {
  created() {
    this.getMaster()
  },
  data() {
    return {
      searchText: '',
      tgl1: '',
      tgl2: '',
      searchInput: null,
      searchedColumn: '',
      name: 'Tabletabungan',
      visible: false,
      allMaster: [],
      masterstatuskawin: [],
      masterkelamin: [],
      datenow: '',
      componentKey: 0,

      // Input Property
      input: {
        id: 0,
        bpr_id: 0,
        akun_simpanan_pokok_id: null,
        akun_simpanan_wajib_id: null,
        akun_pb_simpanan_id: null,
        akun_laba_berjalan_id: null,
        akun_rugi_berjalan_id: null,
        akun_laba_tahunlalu_id: null,
        akun_rugi_tahunlalu_id: null,
        akun_pindahbuku_id: null,
      },
      masterRekening: [],
      backupmasterRekening: [],
    }
  },
  methods: {
    moment,
    async getMaster() {
      var masterRekening = await lou.customUrlGet2('master_akuntansi')
      this.masterRekening = []
      masterRekening.data.forEach((element) => {
        // if (element.id === 211) {
        // console.log('element', element)
        // }
        var nobj = {
          label: element.kode + ' - ' + element.keterangan,
          ...element,
        }
        this.masterRekening.push(nobj)
      })
      this.backupmasterRekening = this.$g.clone(this.masterRekening)
      this.getAllData()
    },
    async getAllData(onclick = false) {
      // console.log('this.masterUsers', this.masterUsers)
      var response = await lou.customUrlGet2('konfigurasi')
      if (response) {
        this.input = response.data
        if (onclick) {
          lou.shownotif('Refresh', 'Data telah di refresh.')
        }
      }
      this.componentKey += 1
      // console.log('this.data', this.data)
    },
    handleSubmit() {
      if (
        this.input.akun_simpanan_pokok_id !== null &&
        this.input.akun_simpanan_pokok_id !== 0 &&
        this.input.akun_simpanan_wajib_id !== null &&
        this.input.akun_simpanan_wajib_id !== 0 &&
        this.input.akun_pb_simpanan_id !== null &&
        this.input.akun_pb_simpanan_id !== 0 &&
        this.input.akun_laba_berjalan_id !== null &&
        this.input.akun_laba_berjalan_id !== 0 &&
        this.input.akun_rugi_berjalan_id !== null &&
        this.input.akun_rugi_berjalan_id !== 0 &&
        this.input.akun_laba_tahunlalu_id !== null &&
        this.input.akun_laba_tahunlalu_id !== 0 &&
        this.input.akun_rugi_tahunlalu_id !== null &&
        this.input.akun_rugi_tahunlalu_id !== 0 &&
        this.input.akun_pindahbuku_id !== null &&
        this.input.akun_pindahbuku_id !== 0
      ) {
        Modal.confirm({
          title: 'Yakin?',
          content: 'Apakah data sudah benar?',
          okText: 'Yes',
          okType: 'danger',
          cancelText: 'No',
          onOk: async () => {
            var fd = this.$g.clone(this.input)
            var response = await lou.customUrlPut2('konfigurasi', fd)
            if (response) {
              // this.getAllData()
            }
          },
          onCancel: () => {
            // console.log('Cancel')
          },
        })
      } else {
        lou.shownotif('Warning', 'Input masih kosong!', 'warning')
      }
    },
  },
}
</script>

<style lang="scss" scoped></style>
