<template>
  <div>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
        <div class="row">
          <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9">
            <strong>{{ title }}</strong>
            <!-- <a-button class="btn btn-outline-secondary" v-on:click="refreshTable">
              Refresh
            </a-button> -->
            <!-- <a-button
              v-if="mode !== 'netral'"
              ref="undoBtn"
              class="btn btn-outline-secondary ml-2"
              :disabled="input.undoInputdisable"
              v-on:click="undo()"
            >
              {{ input.undoInput }} Undo
            </a-button>
            <a-button
              v-if="mode !== 'netral'"
              ref="redoBtn"
              class="ml-2 btn btn-outline-secondary"
              :disabled="input.redoInputdisable"
              v-on:click="redo()"
            >
              {{ input.redoInput }} Redo
            </a-button> -->
            <!-- <a-input v-model="dbname" v-if="mode === 'netral'" style="width: 150px;" class="ml-2" placeholder="DB name" @keydown.enter="getTableList"></a-input> -->
            <a-select v-model="appname" v-if="mode === 'netral'" style="width: 150px;" placeholder="DB name" class="ml-2" show-search option-filter-prop="children" :filter-option="filterOption" @change="getTableList">
              <a-select-option v-for="(data, index) in applist" :key="index" :value="data.appName">{{ data.appName }}</a-select-option>
            </a-select>
            <a-select v-model="dbname" v-if="mode === 'netral'" style="width: 150px;" placeholder="DB name" class="ml-2" show-search option-filter-prop="children" :filter-option="filterOption" @change="getTableList">
              <a-select-option v-for="(data, index) in dblist" :key="index" :value="data.dbname">{{ data.dbname + ' - ' + data.appname }}</a-select-option>
            </a-select>
            <a-select v-model="tablename" v-if="mode === 'netral'" style="width: 150px;" class="ml-2" show-search option-filter-prop="children" :filter-option="filterOption" @change="getAllData">
              <a-select-option v-for="(data, index) in tablelist" :key="index" :value="data.table_name">{{ data.table_name }}</a-select-option>
            </a-select>
            <a-button
              v-if="dbname !== '' && tablename !== '' && mode === 'netral' || mode === 'oncreate'"
              class="ml-2 btn btn-outline-primary"
              @click="showFormModal"
            >
              Open Form
            </a-button>
            <a-button
              v-if="dbname !== '' && tablename !== '' && mode === 'netral'"
              class="btn btn-outline-danger ml-2 mr-2"
              @click="removeOrDelete"
              :disabled="mode !== 'netral'"
            >
              Delete
            </a-button>
            <a-button
              class="btn btn-outline-success ml-2"
              @click="saveChanges"
              v-if="dbname !== '' && tablename !== '' && mode === 'onupdate' || mode === 'oncreate'"
            >
              Save Changes
            </a-button>
            <a-button
              class="btn btn-outline-danger ml-2 mr-2"
              @click="cancelaction"
              v-if="dbname !== '' && tablename !== '' && mode === 'onupdate' || mode === 'oncreate'"
            >
              Cancel
            </a-button>
          </div>
          <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3">
            <a-dropdown>
              <a-menu slot="overlay">
                <a-menu-item key="1" class="text-dark" @click="paginationruleschange">
                  <a-icon :type="allowPagination ? 'menu-fold' : 'menu-unfold'" /> {{ allowPagination ? 'Disable pagination' : 'Enable pagination' }}
                </a-menu-item>
              </a-menu>
              <a-button
                class="btn btn-outline-dark float-right ml-2"
                style="width: 70px;"
              >
                Menu <a-icon type="down" />
              </a-button>
            </a-dropdown>
            <a-input
              v-model="tablequickfilter"
              class="float-right ml-2"
              placeholder="Find data..."
              style="width: 150px;"
            />
          </div>
        </div>
        <ag-grid-vue
          style="height: 50vh;"
          :class="
            settings.theme === 'dark'
              ? 'ag-theme-balham-dark mt-2'
              : 'ag-theme-balham mt-2'
          "
          :key="componentKey"
          :gridOptions="gridOptions"
          :columnDefs="columnDefs"
          :pagination="allowPagination"
          :paginationPageSize="20"
          :cacheQuickFilter="true"
          :quickFilterText="tablequickfilter"
          :accentedSort="false"
          :sortingOrder="['asc', 'desc']"
          :rowData="rowData"
          @grid-ready="onGridReady"
          :singleClickEdit="false"
          @cell-value-changed="onCellValueChanged"
          :undoRedoCellEditing="true"
          :undoRedoCellEditingLimit="5"
          :frameworkComponents="frameworkComponents"
          @first-data-rendered="onFirstDataRendered"
          rowSelection="single"
          @selection-changed="onSelectionChanged"
          :rowClassRules="rowClassRules"
        >
        </ag-grid-vue>
      </div>
    </div>
    <myform ref="myform" />
  </div>
</template>

<script>
import * as lou from '@/services/data/lou'
import moment from 'moment'
import myform from './form'
import numericInput from '@/components/lou/gridinputcomponent/numeric'
import selectInput from '@/components/lou/gridinputcomponent/select'
import dateInput from '@/components/lou/gridinputcomponent/date'
import tooltip from '@/components/lou/gridinputcomponent/tooltip'
import htmlrender from '@/components/lou/gridinputcomponent/htmlrender'
import { mapState } from 'vuex'
import { Modal } from 'ant-design-vue'
export default {
  components: {
    myform,
  },
  computed: {
    ...mapState(['settings', 'user']),
  },
  data() {
    return {
      appname: '',
      dbname: '',
      tablename: '',
      applist: [],
      dblist: [],
      tablelist: [],
      title: 'Grid Config',
      mode: 'netral',
      theme: this.$store.state.settings.theme,
      dataCabangList: [],
      modal: {
        visible: false,
        periode: this.$store.state.user.periode,
        nomor_surat: '',
      },
      tablequickfilter: '',
      gridOptions: null,
      kodeCabang: 'all',
      allowPagination: localStorage.getItem('gridpagination') || false,
      frameworkComponents: {
        numericEditor: numericInput,
        dateEditor: dateInput,
        selectEditor: selectInput,
        customTooltip: tooltip,
        htmlRender: htmlrender,
      },
      rowClassRules: {
        'text-success': (params) => {
          return params.data.newdata !== undefined
        },
        'text-warning': (params) => {
          return params.data.edited !== undefined
        },
        'text-danger': (params) => {
          return params.data.isError !== undefined
        },
        'text-primary': (params) => {
          return params.data.isPrimary !== undefined && params.data.isPrimary === 1
        },
        'font-weight-bold': (params) => {
          return params.data.isBold !== undefined
        },
      },
      columnDefs: [
        {
          headerName: 'Urutan',
          field: 'urutan',
          editable: true,
          // cellEditor: '',
          valueFormatter: '',
          filter: true,
          resizable: true,
          sortable: true,
          // pinned: '',
          minWidth: 75,
          // maxWidth: '',
          // width: '',
          // cellRenderer: '',
          // type: '', // rightAligned
        },
        {
          headerName: 'Table Name',
          field: 'table_name',
          editable: false,
          // cellEditor: '',
          valueFormatter: '',
          filter: true,
          resizable: true,
          sortable: true,
          // pinned: '',
          minWidth: 75,
          // maxWidth: '',
          // width: '',
          // cellRenderer: '',
          // type: '', // rightAligned
        },
        {
          headerName: 'Field',
          field: 'field',
          editable: true,
          // cellEditor: '',
          valueFormatter: '',
          filter: true,
          resizable: true,
          sortable: true,
          // pinned: '',
          minWidth: 75,
          // maxWidth: '',
          // width: '',
          // cellRenderer: '',
          // type: '', // rightAligned
        },
        {
          headerName: 'Header Name',
          field: 'headerName',
          editable: true,
          // cellEditor: '',
          valueFormatter: '',
          filter: true,
          resizable: true,
          sortable: true,
          // pinned: '',
          minWidth: 75,
          // maxWidth: '',
          // width: '',
          // cellRenderer: '',
          // type: '', // rightAligned
        },
        {
          headerName: 'Editable',
          field: 'editable',
          editable: true,
          cellEditor: 'selectEditor',
          cellEditorParams: {
            values: [
              {
                keterangan: 'True',
                value: 'true',
              },
              {
                keterangan: 'False',
                value: 'false',
              },
            ],
            reffield: 'value',
          },
          valueFormatter: '',
          filter: true,
          resizable: true,
          sortable: true,
          // pinned: '',
          minWidth: 75,
          // maxWidth: '',
          // width: '',
          // cellRenderer: '',
          // type: '', // rightAligned
        },
        {
          headerName: 'Cell Editor',
          field: 'cellEditor',
          editable: true,
          // cellEditor: '',
          valueFormatter: '',
          filter: true,
          resizable: true,
          sortable: true,
          // pinned: '',
          minWidth: 75,
          // maxWidth: '',
          // width: '',
          // cellRenderer: '',
          // type: '', // rightAligned
        },
        {
          headerName: 'Value Formatter',
          field: 'valueFormatter',
          editable: true,
          // cellEditor: '',
          valueFormatter: '',
          filter: true,
          resizable: true,
          sortable: true,
          // pinned: '',
          minWidth: 75,
          // maxWidth: '',
          // width: '',
          // cellRenderer: '',
          // type: '', // rightAligned
        },
        {
          headerName: 'data_type',
          field: 'data_type',
          editable: true,
          // cellEditor: '',
          valueFormatter: '',
          filter: true,
          resizable: true,
          sortable: true,
          // pinned: '',
          minWidth: 75,
          // maxWidth: '',
          // width: '',
          // cellRenderer: '',
          // type: '', // rightAligned
        },
        {
          headerName: 'reftable',
          field: 'reftable',
          editable: true,
          // cellEditor: '',
          valueFormatter: '',
          filter: true,
          resizable: true,
          sortable: true,
          // pinned: '',
          minWidth: 75,
          // maxWidth: '',
          // width: '',
          // cellRenderer: '',
          // type: '', // rightAligned
        },
        {
          headerName: 'retreffield',
          field: 'retreffield',
          editable: true,
          // cellEditor: '',
          valueFormatter: '',
          filter: true,
          resizable: true,
          sortable: true,
          // pinned: '',
          minWidth: 75,
          // maxWidth: '',
          // width: '',
          // cellRenderer: '',
          // type: '', // rightAligned
        },
        {
          headerName: 'filter',
          field: 'filter',
          editable: true,
          // cellEditor: '',
          valueFormatter: '',
          filter: true,
          resizable: true,
          sortable: true,
          // pinned: '',
          minWidth: 75,
          // maxWidth: '',
          // width: '',
          // cellRenderer: '',
          // type: '', // rightAligned
        },
        {
          headerName: 'resizable',
          field: 'resizable',
          editable: true,
          // cellEditor: '',
          valueFormatter: '',
          filter: true,
          resizable: true,
          sortable: true,
          // pinned: '',
          minWidth: 75,
          // maxWidth: '',
          // width: '',
          // cellRenderer: '',
          // type: '', // rightAligned
        },
        {
          headerName: 'sortable',
          field: 'sortable',
          editable: true,
          // cellEditor: '',
          valueFormatter: '',
          filter: true,
          resizable: true,
          sortable: true,
          // pinned: '',
          minWidth: 75,
          // maxWidth: '',
          // width: '',
          // cellRenderer: '',
          // type: '', // rightAligned
        },
        {
          headerName: 'pinned',
          field: 'pinned',
          editable: true,
          // cellEditor: '',
          valueFormatter: '',
          filter: true,
          resizable: true,
          sortable: true,
          // pinned: '',
          minWidth: 75,
          // maxWidth: '',
          // width: '',
          // cellRenderer: '',
          // type: '', // rightAligned
        },
        {
          headerName: 'minWidth',
          field: 'minWidth',
          editable: true,
          // cellEditor: '',
          valueFormatter: '',
          filter: true,
          resizable: true,
          sortable: true,
          // pinned: '',
          minWidth: 75,
          // maxWidth: '',
          // width: '',
          // cellRenderer: '',
          // type: '', // rightAligned
        },
        {
          // headerName: 'maxWidth',
          field: 'maxWidth',
          // editable: true,
          // cellEditor: '',
          valueFormatter: '',
          filter: true,
          resizable: true,
          sortable: true,
          // pinned: '',
          minWidth: 75,
          // maxWidth: '',
          // width: '',
          // cellRenderer: '',
          // type: '', // rightAligned
        },
        {
          headerName: 'width',
          field: 'width',
          editable: true,
          // cellEditor: '',
          valueFormatter: '',
          filter: true,
          resizable: true,
          sortable: true,
          // pinned: '',
          minWidth: 75,
          // maxWidth: '',
          // width: '',
          // cellRenderer: '',
          // type: '', // rightAligned
        },
        {
          headerName: 'isHide',
          field: 'isHide',
          editable: true,
          // cellEditor: '',
          valueFormatter: '',
          filter: true,
          resizable: true,
          sortable: true,
          // pinned: '',
          minWidth: 75,
          // maxWidth: '',
          // width: '',
          // cellRenderer: '',
          // type: '', // rightAligned
        },
        {
          headerName: 'rules',
          field: 'rules',
          editable: true,
          // cellEditor: '',
          valueFormatter: '',
          filter: true,
          resizable: true,
          sortable: true,
          // pinned: '',
          minWidth: 75,
          // maxWidth: '',
          // width: '',
          // cellRenderer: '',
          // type: '', // rightAligned
        },
        {
          headerName: 'cellRenderer',
          field: 'cellRenderer',
          editable: true,
          // cellEditor: '',
          valueFormatter: '',
          filter: true,
          resizable: true,
          sortable: true,
          // pinned: '',
          minWidth: 75,
          // maxWidth: '',
          // width: '',
          // cellRenderer: '',
          // type: '', // rightAligned
        },
        {
          headerName: 'type',
          field: 'type',
          editable: true,
          // cellEditor: '',
          valueFormatter: '',
          filter: true,
          resizable: true,
          sortable: true,
          // pinned: '',
          minWidth: 75,
          // maxWidth: '',
          // width: '',
          // cellRenderer: '',
          // type: '', // rightAligned
        },
        {
          headerName: 'allowNull',
          field: 'allowNull',
          editable: true,
          // cellEditor: '',
          valueFormatter: '',
          filter: true,
          resizable: true,
          sortable: true,
          // pinned: '',
          minWidth: 75,
          // maxWidth: '',
          // width: '',
          // cellRenderer: '',
          // type: '', // rightAligned
        },
      ],
      selectedData: null,
      gridApi: null,
      backupRowData: [],
      rowData: [],
      changedData: [],
      columnCurrency: [],
      sheetColection: [],
      importBtnLoading: false,
      input: {
        undoInput: '',
        redoInput: '',
        undoInputdisable: true,
        redoInputdisable: true,
      },
      rowAfterFilter: [],
      componentKey: 0,
      whatform: '',
    }
  },
  created() {
    this.getAllMaster()
    // console.log('this.allMaster', this.allMaster)
    this.gridOptions = {}
    this.gridApi = this.gridOptions.api
    this.gridColumnApi = this.gridOptions.columnApi
    this.dataCabangList = this.user.cabangList
    // console.log('this.kodeCabang', this.kodeCabang)
    // this.getColumnDefs()
    // this.getAllData()
  },
  methods: {
    moment,
    showFormModal() {
      if (this.dbname !== '' && this.tablename !== '') {
        this.$refs.myform.showModal(this.dbname, this.tablename, this.rowData)
      }
    },
    async getTableList() {
      var res = await lou.readMaster('manage/gridconfig/table_list?dbName=' + this.dbname, true, true)
      if (res) {
        this.tablelist = res.data
        var tablelist = []
        for (let i = 0; i < this.tablelist.length; i++) {
          const element = this.tablelist[i]
          // console.log('element', element)
          tablelist.push({ kode: element.table_name, keterangan: element.table_name })
        }
        this.columnDefs[1].cellEditorParams.values = tablelist
        // console.log('this.columnDefs[1].cellEditorParams.values', this.columnDefs[1].cellEditorParams.values)
      }
    },
    async getData() {
      var imbuhan = this.dbname !== '' && this.tablename !== '' ? '?dbname=' + this.dbname + '&table_name=' + this.tablename : ''
      imbuhan += this.appname !== '' ? '&appname=' + this.appname : ''
      var res = await lou.readMaster('manage/gridconfig' + imbuhan, false, true)
      this.datatable = res.data
    },
    // handleMenuClick(e) {
    //   console.log('click', e)
    // },
    paginationruleschange() {
      this.allowPagination = !this.allowPagination
      localStorage.setItem('gridpagination', this.allowPagination)
      // location.reload()
      this.componentKey += 1
    },
    // previousPeriod() {
    //   var periode = this.$store.state.user.periode
    //   console.log('before', periode)
    //   var after = moment(periode, 'YYYY-MM-DD').subtract(1, 'months').endOf('months').format('YYYY-MM-DD')
    //   console.log('after', after)
    // },
    showModal() {
      this.modal.visible = true
    },
    async getAllMaster() {
      var allmaster = {}
      var cabanglist = []
      var tablelist = []
      var applist = await lou.customUrlGet('manage/app', false, true)
      if (applist) {
        this.applist = applist.data
        this.dblist = []
        this.applist.forEach((ele) => {
          var targetIndex = this.dblist.findIndex(x => x.dbname === ele.dbname)
          if (targetIndex < 0) {
            var data = {
              id: ele.id,
              appname: ele.appName,
              appkey: ele.appKey,
              dbname: ele.dbname,
            }
            this.dblist.push(data)
          }
        })
        console.log('this.dblist', this.dblist)
        // console.log('this.applist', this.applist)
      }
      for (let u = 0; u < this.user.cabangList.length; u++) {
        const element = this.user.cabangList[u]
        // console.log('element', element)
        cabanglist.push({ kode: element.kode, keterangan: element.kode })
      }
      for (let i = 0; i < this.tablelist.length; i++) {
        const element = this.tablelist[i]
        // console.log('element', element)
        tablelist.push({ kode: element.table_name, keterangan: element.table_name })
      }
      allmaster.cabanglist = cabanglist
      allmaster.tablelist = tablelist
      this.columnDefs[1].cellEditorParams.values = tablelist
      // console.log('indexeddb', indexeddb)
      this.allMaster = allmaster
      // console.log('this.allMaster', this.allMaster)
      // this.getColumnDefs()
    },
    async getColumnDefs() {
      var nindexeddb = await lou.getDataFromDb()
      // console.log('nindexxeddb', nindexeddb)
      var gcIndexTarget = nindexeddb.findIndex(x => x.mykey === 'allGridConfig' + this.settings.whatapp.appName + this.settings.whatapp.id)
      // console.log('this.settings.whatapp', this.settings.whatapp)
      var column = []
      var res = null
      // console.log('res.data', res.data)
      if (gcIndexTarget < 0) {
        res = await lou.customUrlGet('master/tables', false, true)
        // console.log('res.data', res.data)
        column = res.data[this.whatform]
        var master = {
          mykey: 'allGridConfig' + this.settings.whatapp.appName + this.settings.whatapp.id,
          data: JSON.stringify(res.data),
        }
        await lou.addDataToDb(master)
        // console.log('column', column)
      } else {
        var dataFDB = JSON.parse(nindexeddb[gcIndexTarget].data)
        // console.log('dataFDB', dataFDB)
        if (dataFDB[this.whatform] !== undefined) {
          column = dataFDB[this.whatform]
        } else {
          await lou.deleteDataFromDb(nindexeddb[gcIndexTarget].id)
          res = await lou.customUrlGet('master/tables', false, true)
          column = res.data[this.whatform]
          // console.log('column', column)
          var masters = {
            mykey: 'allGridConfig' + this.settings.whatapp.appName + this.settings.whatapp.id,
            data: JSON.stringify(res.data),
          }
          await lou.addDataToDb(masters)
        }
        // console.log('column', column)
      }
      this.columnDefs = []
      this.contohdata = {}
      for (let i = 0; i < column.length; i++) {
        const element = column[i]
        var data = element
        if (data.isHide === 'false') {
          if (data.valueFormatter === '') {
            delete data.valueFormatter
          } else {
            if (data.valueFormatter === 'currency') {
              this.columnCurrency.push({
                field: data.field.toLowerCase(),
                headerName: data.headerName,
                valueFormatter: this.currencyFormatter,
                resizable: true,
              })
              data.valueFormatter = this.currencyFormatter
            }
          }
          if (data.reftable !== undefined && data.reftable !== '' && data.reftable !== null) {
            var object = this.allMaster[data.reftable]
            if (object !== undefined) {
              data.cellEditor = 'selectEditor'
              data.cellEditorParams = {
                values: object,
                reffield: data.retreffield,
              }
            }
          }
          if (data.cellEditor === '') delete data.cellEditor
          if (data.pinned === '') delete data.pinned
          data.editable = data.editable === 'true'
          if (data.filter === 'true') {
            data.filter = true
          } else if (data.filter === 'false') {
            data.filter = false
          } else if (data.filter === 'agDateColumnFilter') {
            data.filterParams = {
              comparator: (filterLocalDateAtMidnight, cellValue) => {
                var dateAsString = cellValue
                if (dateAsString == null) return -1
                var dateParts = dateAsString.split('-')
                var cellDate = new Date(
                  Number(dateParts[0]),
                  Number(dateParts[1]) - 1,
                  Number(dateParts[2]),
                )
                if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
                  return 0
                }
                if (cellDate < filterLocalDateAtMidnight) {
                  return -1
                }
                if (cellDate > filterLocalDateAtMidnight) {
                  return 1
                }
              },
            }
          }
          data.sortable = data.sortable === 'true'
          data.resizable = data.resizable === 'true'
          data.field = data.field.toLowerCase()
          // data.cellClassRules = data.rules
          data.tooltipField = data.field
          data.tooltipComponent = 'customTooltip'
          this.contohdata[data.field] = ''
          this.columnDefs.push(data)
        }
      }
      this.refreshTable()
    },
    async getAllData() {
      this.mode = 'netral'
      var imbuhan = this.dbname !== '' && this.tablename !== '' ? '?dbname=' + this.dbname + '&table_name=' + this.tablename : ''
      var res = await lou.readMaster('manage/gridconfig' + imbuhan, false, true)
      if (res) {
        this.rowData = res.data
        this.backupRowData = this.$g.clone(this.rowData)
        this.rowAfterFilter = this.$g.clone(this.rowData)
      }
    },
    onFirstDataRendered() {
      this.autoSizeAll(false, { autosizecolumn: true })
    },
    onCellValueChanged(params) {
      // console.log('params', params)
      var undoSize = params.api.getCurrentUndoSize()
      this.input.undoInput = undoSize
      this.input.undoInputdisable = undoSize < 1
      var redoSize = params.api.getCurrentRedoSize()
      this.input.redoInput = redoSize
      this.input.redoInputdisable = redoSize < 1
      if (this.mode === 'oncreate') {
        if (params.data.newdata) {
          var target = this.changedData.findIndex(
            (x) => x.id === params.data.id,
          )
          if (target < 0) {
            var findField = this.backupRowData.findIndex(x => x.field === params.data.field)
            if (findField < 0) {
              this.changedData.push(params.data)
            } else {
              lou.shownotif('Same field', "There's same field in this table!", 'error')
            }
          } else {
            this.changedData[target] = params.data
          }
          // console.log('this.changedData', this.changedData)
        } else {
          // eslint-disable-next-line no-redeclare
          var target = this.rowData.findIndex((x) => x.id === params.data.id)
          var backupTarget = this.backupRowData.findIndex(
            (x) => x.id === params.data.id,
          )
          // console.log('this.rowData[target] before:', this.rowData[target])
          this.rowData[target] = this.$g.clone(
            this.backupRowData[backupTarget],
          )
          // console.log('this.rowData[target] after:', this.rowData[target])
          // console.log('this.backupRowData[backupTarget]', this.backupRowData[backupTarget])
          // this.componentKey += 1
          this.changedData = []
          this.input.undoInput = 0
          this.input.undoInputdisable = true

          this.input.redoInput = 0
          this.input.redoInputdisable = true
          lou.shownotif('Anda sedang dalam mode create')
        }
      } else {
        this.mode = 'onupdate'
        // eslint-disable-next-line no-redeclare
        var targetChanged = this.changedData.findIndex(
          (x) => x.id === params.data.id,
        )
        if (targetChanged < 0) {
          // var cd = {}
          // cd.id = params.data.id
          // cd[params.column.colId] = value.value
          // console.log('cd[params.column.colId]', cd)
          // console.log('value.value', value)
          this.changedData.push(params.data)
        } else {
          this.changedData[targetChanged][params.column.colId] =
            params.newValue
        }
        // console.log('this.changedData', this.changedData)
        // this.componentKey += 1
      }
      // console.log('this.mode', this.mode)
    },
    checkData() {
      // this.
    },
    async saveChanges() {
      var fd = this.changedData.length !== 0 ? this.$g.clone(this.changedData) : []
      // var user = this.$store.state.user
      if (fd.length === 0) lou.shownotif('Belum ada perubahan')
      if (this.mode === 'oncreate') {
        fd.forEach((element) => {
          delete element.id
          if (element.newdata) {
            delete element.newdata
          }
        })
      } else if (this.mode === 'onupdate') {
        fd.forEach((element) => {
          element.id = parseInt(element.id)
        })
      }
      if (this.columnCurrency.length !== 0) {
        fd.forEach(element => {
          this.columnCurrency.forEach(el => {
            // console.log('element[el.field]', element[el.field])
            if (element[el.field]) {
              element[el.field] = parseFloat(element[el.field])
            }
          })
        })
      }
      // console.log('fd', fd)
      // fd[0].tglmulai = fd[0].tglmulai.slice(0, 10)
      // fd[0].tgljatuhtempo = fd[0].tgljatuhtempo.slice(0, 10)
      // fd[0].tglpenilaianterakhir = fd[0].tglpenilaianterakhir.slice(0, 10)
      var res = {}
      if (this.mode === 'oncreate') {
        // console.log('fd', fd)
        res = await lou.createMasterMany('manage/gridconfig', fd, true, true)
      } else {
        res = await lou.updateMasterMany('manage/gridconfig', fd, true, true)
        // console.log('fd', fd)
      }
      if (res) {
        this.mode = 'netral'
        this.changedData = []
        this.getAllData()
      }
    },
    onSelectionChanged() {
      var selectedRows = this.gridApi.getSelectedRows()
      this.selectedData = selectedRows
      // console.log('selectedRows', selectedRows)
    },
    addNullData() {
      this.mode = 'oncreate'
      var fd = {}
      this.columnDefs.forEach((element, index) => {
        var booltgl = element.cellEditor === 'dateEditor'
        if (element.field === 'table_name') {
          fd[element.field] = this.tablename
        } else if (booltgl) {
          fd[element.field] = moment().format('YYYY-MM-DD')
        } else if (element.cellEditor === 'numericEditor') {
          fd[element.field] = 0
        } else {
          fd[element.field] = ''
        }
      })
      fd.id = this.$uuid.v4()
      fd.newdata = true
      this.rowData.splice(0, 0, fd)
    },
    removeOrDelete() {
      // console.log('this.selectedData', this.selectedData)
      if (this.selectedData === null) {
        lou.shownotif(
          'Anda belum memilih data atau data yang anda pilih harus tidak boleh lebih dari 1',
        )
      } else {
        if (this.selectedData[0].newdata) {
          var indexTarget = this.rowData.findIndex(
            (x) => x.id === this.selectedData[0].id,
          )
          this.rowData.splice(indexTarget, 1)
        } else {
          this.confirmationDelete(this.selectedData[0].id)
          // send to api
        }
      }
    },
    cancelaction() {
      // console.log('this.mode', this.mode)
      if (this.mode === 'oncreate') {
        var maped = this.rowData.filter((x) => x.newdata === undefined)
        // console.log('maped', maped)
        this.rowData = maped
      } else {
        // var changedRowNode = []
        this.rowData = this.$g.clone(this.backupRowData)
      }
      this.mode = 'netral'
      this.changedData = []
      this.input.undoInput = 0
      this.input.undoInputdisable = true

      this.input.redoInput = 0
      this.input.redoInputdisable = true
      this.refreshTable()
      // for (let i = 0; i < this.input.undoInput; i++) {
      //   this.gridApi.undoCellEditing()
      // }
    },
    async confirmationDelete(id) {
      Modal.confirm({
        title: 'Are you sure delete this data?',
        content: "You can't prevent this!!! ",
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'No',
        onOk: async () => {
          await lou.customUrlDelete('manage/gridconfig/' + this.dbname + '/' + this.tablename + '/' + id)
          this.getAllData()
        },
        onCancel: () => {
          // console.log('Cancel')
        },
      })
    },
    refreshTable() {
      var params = {
        force: true,
        suppressFlash: false,
      }

      this.gridOptions.api.refreshCells(params)
      // console.log('kerefresh kok')
    },
    onGridReady(params) {
      // params.api.sizeColumnsToFit()
      this.gridApi = params.api
      this.gridColumnApi = params.columnApi
    },
    autoSizeAll(skipHeader, { autosizecolumn = true }) {
      if (autosizecolumn) {
        var allColumnIds = []
        // console.log('this.gridColumnApi', this.gridColumnApi)
        this.gridColumnApi.getAllColumns().forEach(function (column) {
          allColumnIds.push(column.colId)
        })
        this.gridColumnApi.autoSizeColumns(allColumnIds, skipHeader)
      } else {
        this.gridApi.sizeColumnsToFit()
      }
    },
    undo() {
      this.gridApi.undoCellEditing()
    },
    redo() {
      this.gridApi.redoCellEditing()
    },
    currencyFormatter(params) {
      var ret = params.value === null ? 'NaN' : lou.curency(params.value)
      return ret === 'NaN' ? '' : ret
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      )
    },
    titleFormat(word) {
      return lou.capitalizeTheFirstLetterOfEachWord(word)
    },
  },
}
</script>

<style></style>
