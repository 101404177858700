<template>
  <div>
    <a-card>
      <template slot="title">
        <strong v-if="action === 'create'">Form Kredit</strong>
        <strong v-if="action === 'update'">Update Kredit</strong>
        <a-button size="default" type="danger" class="ml-2" @click="resetForm"
          >Back</a-button
        >
      </template>
      <div class="content">
        <div class="row pl-3 pr-3 pt-0 pb-3">
          <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
            <div class="row">
              <div
                class="col-xs-12 col-sm-12 col-md-12 col-lg-12"
                style="padding-right: unset"
              >
                <div class="row">
                  <div
                    class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2"
                    style="padding-right: unset"
                  >
                    <label class="mt-1">Tanggal</label>
                  </div>
                  <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                    <!-- :readOnly="action === 'update'" -->
                    <a-date-picker
                      style="width: 130px"
                      v-model="formData.tgl"
                      :allowClear="false"
                      format="DD-MM-YYYY"
                      @change="
                        (dateMoment, dateString) =>
                          changeDateInput(dateMoment, dateString, [
                            'formData',
                            'tgl',
                          ])
                      "
                    />
                  </div>
                  <div
                    class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2"
                    style="padding-right: unset"
                  >
                    <label class="mt-1">Kode</label>
                  </div>
                  <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                    <a-input
                      class=""
                      style="width: 200px"
                      placeholder="Kode"
                      v-model="formData.kode"
                    :readOnly="action === 'update'"
                    ></a-input>
                    <!-- <a-select
                  class=""
                  show-search
                  placeholder="Ketikan Nama"
                  option-filter-prop="children"
                  :filter-option="filterOption"
                  v-model="formData.nama"
                  style="width: 30%"
                  @search="rekeningSearch"
                  @change="rekeningChange"
                  >
                  <a-spin v-if="fetching" slot="notFoundContent" size="small" />
                  <a-select-option
                  v-for="(d, index) in listRekening"
                  :key="index"
                  >
                  {{ d.nama + " - " + d.alamat }}
                  </a-select-option>
                </a-select> -->
                    <a-button
                    v-if="action !== 'update'"
                      @click="carinasabahvisible = !carinasabahvisible"
                      class="ml-2 btn btn-outline-primary"
                    >
                      <a-icon type="search"></a-icon> Cari Nasabah</a-button
                    >
                  </div>
                  <div
                    class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2"
                    style="padding-right: unset"
                    v-if="action === 'update'"
                  >
                    <label class="mt-1">Rekening</label>
                  </div>
                  <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1"
                  v-if="action === 'update'">
                    <a-input
                      :readOnly="true"
                      class=""
                      style="width: 100%"
                      placeholder="Rekening"
                      v-model="formData.rekening"
                    ></a-input>
                  </div>
                  <div
                    class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2"
                    style="padding-right: unset"
                  >
                    <label class="mt-1">Nama</label>
                  </div>
                  <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                    <a-input
                      :readOnly="true"
                      class=""
                      style="width: 100%"
                      placeholder="Nama"
                      v-model="formData.nama"
                    ></a-input>
                  </div>
                  <div
                    class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2"
                    style="padding-right: unset"
                  >
                    <label class="mt-1">Alamat</label>
                  </div>
                  <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                    <a-input
                      :readOnly="true"
                      class=""
                      style="width: 100%"
                      placeholder="Alamat"
                      v-model="formData.alamat"
                    ></a-input>
                  </div>
                  <div
                    class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2"
                    style="padding-right: unset"
                  >
                    <label class="mt-1">Telepon</label>
                  </div>
                  <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                    <a-input
                      :readOnly="true"
                      class=""
                      style="width: 100%"
                      placeholder="Telepon"
                      v-model="formData.telepon"
                    ></a-input>
                  </div>
                  <div
                    class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2"
                    style="padding-right: unset"
                  >
                    <label class="mt-1">Auto Debet</label>
                  </div>
                  <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                    <a-radio-group v-model="formData.autodebet">
                      <a-radio-button :value="true"> Ya </a-radio-button>
                      <a-radio-button :value="false"> Tidak </a-radio-button>
                    </a-radio-group>
                  </div>
                  <div
                    class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2"
                    style="padding-right: unset"
                  >
                    <label class="mt-1">Golongan Kredit</label>
                  </div>
                  <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                    <!-- <a-input
                      :readOnly="true"
                      class=""
                      style="width: 100%"
                      placeholder="Golongan Kredit"
                      v-model="maskInput.golongankredit"
                    ></a-input> -->
                    <a-select
                      :disabled="action === 'update'"
                      class=""
                      show-search
                      placeholder="Pilih Golongan Kredit"
                      v-model="formData.golongankredit"
                      option-filter-prop="children"
                      :filter-option="filterOption"
                      style="width: 250px"
                    >
                      <!-- <a-spin v-if="fetching" slot="notFoundContent" size="small" /> -->
                      <a-select-option
                        v-for="d in mastergolongankredit"
                        :key="d.kode"
                      >
                        {{ d.kode + " - " + d.keterangan }}
                      </a-select-option>
                    </a-select>
                  </div>
                  <div
                    class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2"
                    style="padding-right: unset"
                  >
                    <label class="mt-1">Account Officer</label>
                  </div>
                  <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                    <!-- :readOnly="action === 'update'" -->
                    <a-select
                      class=""
                      show-search
                      placeholder="Pilih Account Officer"
                      v-model="formData.ao"
                      option-filter-prop="children"
                      :filter-option="filterOption"
                      style="width: 250px"
                    >
                      <!-- <a-spin v-if="fetching" slot="notFoundContent" size="small" /> -->
                      <a-select-option v-for="d in masterao" :key="d.kode">
                        {{ d.kode + " - " + d.nama }}
                      </a-select-option>
                    </a-select>
                  </div>
                  <div
                    class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2"
                    style="padding-right: unset"
                  >
                    <label class="mt-1">Kelompok</label>
                  </div>
                  <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                    <!-- :readOnly="action === 'update'" -->
                    <a-select
                      class=""
                      show-search
                      placeholder="Masukan Kelompok"
                      v-model="formData.golongankelompok"
                      option-filter-prop="children"
                      :filter-option="filterOption"
                      style="width: 250px"
                    >
                      <!-- <a-spin v-if="fetching" slot="notFoundContent" size="small" /> -->
                      <a-select-option
                        v-for="d in mastergolongankelompok"
                        :key="d.kode"
                      >
                        {{ d.kode + " - " + d.keterangan }}
                      </a-select-option>
                    </a-select>
                  </div>
                  <div
                    class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2"
                    style="padding-right: unset"
                  >
                    <label class="mt-1">No SPK</label>
                  </div>
                  <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                    <a-input
                      :readOnly="false"
                      class=""
                      style="width: 100%"
                      placeholder="No SPK"
                      v-model="formData.nospk"
                    ></a-input>
                  </div>
                  <div
                    class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2"
                    style="padding-right: unset"
                  >
                    <label class="mt-1">Plafond</label>
                  </div>
                  <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                    <cleave
                      :options="{ numeral: true }"
                      class="ant-input"
                      :raw="true"
                      placeholder="Plafond"
                      v-model="formData.plafond"
                    />
                  </div>
                  <div
                    class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2"
                    style="padding-right: unset"
                  >
                    <label class="mt-1">Jangka Waktu</label>
                  </div>
                  <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                    <cleave
                      :options="{ numeral: true }"
                      class="ant-input"
                      :raw="true"
                      placeholder="Jangka Waktu"
                      style="width: 70px"
                      v-model="formData.lama"
                    />
                    / Bulan
                  </div>
                  <div
                    class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2"
                    style="padding-right: unset"
                  >
                    <label class="mt-1">Bunga</label>
                  </div>
                  <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                    <cleave
                      :options="{ numeral: true }"
                      class="ant-input"
                      :raw="true"
                      style="width: 100px"
                      placeholder="Bunga"
                      v-model="formData.sukubunga"
                    />
                    %
                  </div>
                  <div
                    class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2"
                    style="padding-right: unset"
                  >
                    <label class="mt-1">Jenis Angsuran</label>
                  </div>
                  <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                    <!-- :readOnly="action === 'update'" -->
                    <a-select
                      class=""
                      show-search
                      placeholder="Pilih Jenis Angsuran"
                      v-model="formData.jenis_angsuran"
                      option-filter-prop="children"
                      :filter-option="filterOption"
                      style="width: 100%"
                    >
                      <!-- <a-spin v-if="fetching" slot="notFoundContent" size="small" /> -->
                      <a-select-option
                        style="width: 100%;"
                        v-for="d in masterjenisangsuran"
                        :key="d.id"
                      >
                        {{ d.keterangan + " - " + d.penjelasan }}
                      </a-select-option>
                    </a-select>
                  </div>
                  <div
                    class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2"
                    style="padding-right: unset"
                  >
                    <label class="mt-1">Total Bunga</label>
                  </div>
                  <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                    <cleave
                      :options="{ numeral: true }"
                      class="ant-input"
                      :raw="true"
                      placeholder="Total Bunga"
                      v-model="formData.totalbunga"
                    />
                  </div>
                  <!-- <div
                class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2"
                style="padding-right: unset"
              >
                <label class="mt-1">Menggunakan Uang Muka?</label>
              </div>
              <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                    <a-radio-group v-model="formData.um">
                      <a-radio-button :value="true"> Ya </a-radio-button>
                      <a-radio-button :value="false"> Tidak </a-radio-button>
                    </a-radio-group>
              </div> -->
                  <!-- <div
                    class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2"
                    style="padding-right: unset"
                  >
                    <label class="mt-1">Uang Muka</label>
                  </div>
                  <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                    <cleave
                      :options="{ numeral: true }"
                      class="ant-input"
                      :raw="true"
                      placeholder="Masukan Uang Muka"
                      v-model="formData.uangmuka"
                    />
                  </div>
                  <div
                    class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2"
                    style="padding-right: unset"
                  >
                    <label class="mt-1">Tabaruk</label>
                  </div>
                  <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                    <a-input
                      :readOnly="false"
                      class=""
                      style="width: 100%"
                      placeholder="Tabaruk"
                      v-model="formData.tabaruk"
                    ></a-input>
                  </div> -->
                </div>
              </div>
            </div>
          </div>
          <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
            <div class="row">
              <div
                class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2"
                style="padding-right: unset"
              >
                <label class="mt-1">Dana Sendiri</label>
              </div>
              <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                <cleave
                  :options="{ numeral: true }"
                  class="ant-input"
                  :raw="true"
                  style="width: 100%"
                  placeholder="Dana Sendiri"
                  v-model="formData.danasendiri"
                />
                <!-- <a-radio-group v-model="formData.danasendiri">
                      <a-radio-button :value="true"> Ya </a-radio-button>
                      <a-radio-button :value="false"> Tidak </a-radio-button>
                    </a-radio-group> -->
              </div>
              <div
                class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2"
                style="padding-right: unset"
              >
                <label class="mt-1">Harga Perolehan</label>
              </div>
              <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                <a-input
                  :readOnly="false"
                  class=""
                  style="width: 100%"
                  placeholder="Harga Perolehan"
                  v-model="formData.hargaperolehan"
                ></a-input>
              </div>
              <div
                class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2"
                style="padding-right: unset"
              >
                <label class="mt-1">Premi Asuransi</label>
              </div>
              <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                <a-input
                  :readOnly="false"
                  class=""
                  style="width: 100%"
                  placeholder="Premi Asuransi"
                  v-model="formData.premiasuransi"
                ></a-input>
              </div>
              <div
                class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2"
                style="padding-right: unset"
              >
                <label class="mt-1">No Polis</label>
              </div>
              <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                <a-input
                  :readOnly="false"
                  class=""
                  style="width: 100%"
                  placeholder="No Polis"
                  v-model="formData.nopolis"
                ></a-input>
              </div>
              <!-- <div
                class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2"
                style="padding-right: unset"
              >
                <label class="mt-1">Ujroh</label>
              </div>
              <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                <a-input
                  :readOnly="false"
                  class=""
                  style="width: 100%"
                  placeholder="Ujroh"
                  v-model="formData.ujroh"
                ></a-input>
              </div> -->
              <div
                class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2"
                style="padding-right: unset"
              >
                <label class="mt-1">Notaris</label>
              </div>
              <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                <a-input
                  :readOnly="false"
                  class=""
                  style="width: 100%"
                  placeholder="notaris"
                  v-model="formData.notaris"
                ></a-input>
              </div>
              <div
                class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2"
                style="padding-right: unset"
              >
                <label class="mt-1">Persen Provisi</label>
              </div>
              <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                <a-input
                  :readOnly="false"
                  class=""
                  style="width: 100%"
                  placeholder="persenprovisi"
                  v-model="formData.persenprovisi"
                ></a-input>
              </div>
              <div
                class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2"
                style="padding-right: unset"
              >
                <label class="mt-1">Provisi</label>
              </div>
              <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                <a-input
                  :readOnly="false"
                  class=""
                  style="width: 100%"
                  placeholder="provisi"
                  v-model="formData.provisi"
                ></a-input>
              </div>
              <div
                class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2"
                style="padding-right: unset"
              >
                <label class="mt-1">Asuransi</label>
              </div>
              <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                <a-input
                  :readOnly="false"
                  class=""
                  style="width: 100%"
                  placeholder="asuransi"
                  v-model="formData.asuransi"
                ></a-input>
              </div>
              <!-- <div
                class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2"
                style="padding-right: unset"
              >
                <label class="mt-1">Username</label>
              </div>
              <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                <a-input
                  :readOnly="false"
                  class=""
                  style="width: 100%"
                  placeholder="username"
                  v-model="formData.username"
                ></a-input>
              </div> -->
              <div
                class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2"
                style="padding-right: unset"
              >
                <label class="mt-1">Grace Period</label>
              </div>
              <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                <!-- <a-radio-group v-model="formData.graceperiod">
                      <a-radio-button :value="true"> Ya </a-radio-button>
                      <a-radio-button :value="false"> Tidak </a-radio-button>
                    </a-radio-group> -->
                <a-input
                  :readOnly="false"
                  class=""
                  style="width: 100%"
                  placeholder="Grace Period"
                  v-model="formData.graceperiod"
                ></a-input>
              </div>
              <div
                class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2"
                style="padding-right: unset"
              >
                <label class="mt-1">Grace Period Bunga</label>
              </div>
              <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                <a-input
                  :readOnly="false"
                  class=""
                  style="width: 100%"
                  placeholder="Grace Period Bunga"
                  v-model="formData.graceperiodbahas"
                ></a-input>
              </div>
              <div
                class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2"
                style="padding-right: unset"
              >
                <label class="mt-1">Rekening Tabungan</label>
              </div>
              <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                <a-input
                  :readOnly="false"
                  class=""
                  style="width: 100%"
                  placeholder="Rekening Tabungan"
                  v-model="formData.rekeningtabungan"
                ></a-input>
              </div>
              <div
                class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2"
                style="padding-right: unset"
              >
                <label class="mt-1">Administrasi</label>
              </div>
              <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                <a-input
                  :readOnly="false"
                  class=""
                  style="width: 100%"
                  placeholder="administrasi"
                  v-model="formData.administrasi"
                ></a-input>
              </div>
              <div
                class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2"
                style="padding-right: unset"
              >
                <label class="mt-1">Materai</label>
              </div>
              <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                <a-input
                  :readOnly="false"
                  class=""
                  style="width: 100%"
                  placeholder="materai"
                  v-model="formData.materai"
                ></a-input>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-4">
                <a-button
                  :class="
                    action === 'update'
                      ? 'btn btn-block btn-warning'
                      : 'btn btn-block btn-primary'
                  "
                  @click="handleSubmit"
                  >{{
                    action === "update" ? "Save Changes" : "Submit"
                  }}</a-button
                >
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            <a-button class="btn btn-outline-success" @click="postToGetTable">Generate Table Angsuran</a-button>
            <ag-grid-vue
                style="height: 30vh;"
                :class="
                  settings.theme === 'dark'
                    ? 'ag-theme-balham-dark mt-2'
                    : 'ag-theme-balham mt-2'
                "
                :key="componentKey"
                :gridOptions="gridOptions"
                :columnDefs="columnDefs"
                :pagination="allowPagination"
                :paginationPageSize="20"
                :cacheQuickFilter="true"
                :quickFilterText="tablequickfilter"
                :accentedSort="false"
                :sortingOrder="['asc', 'desc']"
                :rowData="rowData"
                @grid-ready="onGridReady"
                :singleClickEdit="false"
                @cell-value-changed="onCellValueChanged"
                :undoRedoCellEditing="true"
                :undoRedoCellEditingLimit="5"
                :frameworkComponents="frameworkComponents"
                @first-data-rendered="onFirstDataRendered"
                rowSelection="single"
                @selection-changed="onSelectionChanged"
                :rowClassRules="rowClassRules"
              >
              </ag-grid-vue>
          </div>
        </div>
      </div>
    </a-card>
    <a-modal
      v-model="carinasabahvisible"
      :dialogStyle="{ top: '10px' }"
      :footer="null"
      :width="800"
    >
      <template slot="title">
        <strong>{{ carinasabahtitle }} </strong>
        <label style="margin: unset" v-if="selectedRekening !== ''">
          | Active Rekening: {{ selectedRekening }}</label
        >
      </template>
      <!-- <template slot="footer">
          <a-button class="btn btn-outline-dark">Cancel</a-button>
          <a-button class="btn btn-outline-primary">Process</a-button>
        </template> -->
      <div class="row">
        <a-input
          class="ml-3"
          placeholder="Ketik Nama"
          v-model="input.nama"
          style="width: 200px"
          @keydown.enter="findData"
        ></a-input>
        <!-- <a-input
          class="ml-2"
          placeholder="Ketik Alamat"
          v-model="input.alamat"
          style="width: 200px"
          @keydown.enter="findData"
        ></a-input>
        <a-select
          style="width: 70px"
          placeholder="Kode"
          class="ml-2"
          v-model="input.cabang"
        >
          <a-select-option value="01">01</a-select-option>
        </a-select> -->
        <a-button
          v-if="selectedRekening !== ''"
          class="btn btn-outline-danger btn-sm ml-2"
          @click="clearRekening"
          >Clear Selected Rekening</a-button
        >
        <a-divider type="horizontal" style="margin: 8px 8px" />
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
          <label
            v-if="
              table.tableatas.datatable.length !== null &&
              table.tableatas.datatable.length !== 0
            "
            class="text-primary"
            style="
              margin-left: 0.5rem;
              margin-top: 0.5rem;
              margin-bottom: unset !important;
            "
            >Jumlah Data: {{ table.tableatas.datatable.length }}</label
          >
          <a-table
            :scroll="{ y: 300 }"
            :columns="table.tableatas.column"
            :dataSource="table.tableatas.datatable"
            :pagination="false"
            size="small"
            :customRow="customRow"
          >
            <template slot="actionButton" slot-scope="index, record">
              <a-button
                class="btn btn-outline-primary"
                @click="selectModalTable1(record)"
                >Pilih</a-button
              >
            </template>
          </a-table>
        </div>
        <!-- <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-2">
          <label
            v-if="
              table.tablebawah.datatable.length !== null &&
              table.tablebawah.datatable.length !== 0
            "
            class="text-primary"
            style="
              margin-left: 0.5rem;
              margin-top: 0.5rem;
              margin-bottom: unset !important;
            "
            >Jumlah Data: {{ table.tablebawah.datatable.length }}</label
          >
          <a-table
            :scroll="{ y: 200 }"
            :columns="table.tablebawah.column"
            :dataSource="table.tablebawah.datatable"
            :pagination="false"
            size="small"
            :customRow="customRowB"
          >
            <span slot="saldo" slot-scope="text">
              {{ formatCurrency(text) }}
            </span>
          <template slot="actionButton" slot-scope="index, record">
            <a-button class="btn btn-outline-primary" @click="selectModalTable2(record)">Pilih</a-button>
          </template>
          </a-table>
        </div> -->
      </div>
    </a-modal>
  </div>
</template>

<script>
import debounce from 'lodash/debounce'
// import { Modal } from 'ant-design-vue'
import moment from 'moment'
import * as lou from '@/services/data/lou'
import { mapState } from 'vuex'
import Cleave from 'vue-cleave-component'
import numericInput from './component/numeric'
import selectInput from './component/select'
import dateInput from './component/date'
import tooltip from './component/tooltip'
import htmlrender from './component/htmlrender'

export default {
  watch: {
    'formData.sukubunga'(newValue, oldValue) {
      // console.log('this.alreadyGenerated', this.alreadyGenerated)
      // if (this.alreadyGenerated) {
      // console.log('formData.sukubunga', this.formData.sukubunga)
      this.postToGetTable()
      // }
    },
    'formData.plafond'(newValue, oldValue) {
      // if (this.alreadyGenerated) {
      // console.log('formData.plafond', this.formData.plafond)
      this.postToGetTable()
      // }
    },
    'formData.lama'(newValue, oldValue) {
      // if (this.alreadyGenerated) {
      // console.log('formData.lama', this.formData.lama)
      this.postToGetTable()
      // }
    },
    'formData.graceperiod'(newValue, oldValue) {
      // if (this.alreadyGenerated) {
      // console.log('formData.graceperiod', this.formData.graceperiod)
      this.postToGetTable()
      // }
    },
    'formData.graceperiodbahas'(newValue, oldValue) {
      // if (this.alreadyGenerated) {
      // console.log('formData.graceperiodbahas', this.formData.graceperiodbahas)
      this.postToGetTable()
      // }
    },
  },
  components: {
    cleave: Cleave,
  },
  computed: {
    ...mapState(['settings', 'user']),
  },
  data() {
    this.lastFetchId = 0
    this.rekeningSearch = debounce(this.rekeningSearch, 800)
    return {
      editdata: JSON.parse(
        localStorage.getItem('minibankeditkreditdata'),
      ),
      action: null,
      title: 'Register Nasabah',
      fetching: false,
      loadingMaster: false,
      maskInput: {
        golongankredit: '',
      },
      formData: {
        administrasi: 0,
        asuransi: 0,
        danasendiri: 0,
        graceperiod: 0,
        graceperiodbahas: 0,
        hargaperolehan: 0,
        jangkawaktu: 0,
        materai: 0,
        notaris: 0,
        persenprovisi: 0,
        plafond: 0,
        premiasuransi: 0,
        provisi: 0,
        tabaruk: 0,
        totalbunga: 0,
        uangmuka: 0,
        ujroh: 0,
        alamat: '',
        ao: '',
        autodebet: false,
        caraperhitungan: '',
        golongankelompok: '',
        golongankredit: '',
        jenis_angsuran: 0,
        kode: '',
        lama: 0,
        nama: '',
        nopolis: '',
        nospk: '',
        rekeningtabungan: '',
        sukubunga: 0,
        telepon: '',
        tgl: moment(),
        username: '',
        // um: '',
      },
      listKode: [],
      backuplistKode: [],
      listRekening: [],
      backuplistRekening: [],
      componentKey: 0,
      mastergolongankredit: [],
      masterjenisangsuran: [],
      masterao: [],
      mastergolongankelompok: [],

      // Property ag-grid
      mode: 'netral',
      theme: this.$store.state.settings.theme,
      gridOptions: null,
      selectedData: null,
      gridApi: null,
      backupRowData: [],
      changedData: [],
      rowData: [],
      tablequickfilter: '',
      allowPagination: false,
      columnDefs: [
        {
          headerName: 'Ke',
          field: 'ke',
          editable: true,
          resizable: true,
          sortable: true,
          width: 50,
        },
        {
          headerName: 'Jatuh Tempo',
          field: 'tgl',
          editable: true,
          resizable: true,
          sortable: true,
          width: 50,
          valueFormatter: this.dateFormatter,
        },
        {
          headerName: 'Pokok',
          field: 'pokok',
          editable: true,
          resizable: true,
          sortable: true,
          minWidth: 150,
          valueFormatter: this.currencyFormatter,
          type: 'rightAligned',
        },
        {
          headerName: 'Bunga',
          field: 'bunga',
          editable: true,
          resizable: true,
          sortable: true,
          minWidth: 150,
          valueFormatter: this.currencyFormatter,
          type: 'rightAligned',
        },
        {
          headerName: 'Total',
          field: 'total',
          editable: true,
          resizable: true,
          sortable: true,
          minWidth: 150,
          valueFormatter: this.currencyFormatter,
          type: 'rightAligned',
        },
        {
          headerName: 'Sisa Pokok',
          field: 'sisa_pokok',
          editable: true,
          resizable: true,
          sortable: true,
          minWidth: 150,
          valueFormatter: this.currencyFormatter,
          type: 'rightAligned',
        },
        {
          headerName: 'Sisa Bunga',
          field: 'sisa_bunga',
          editable: true,
          resizable: true,
          sortable: true,
          minWidth: 150,
          valueFormatter: this.currencyFormatter,
          type: 'rightAligned',
        },
        // {
        //   headerName: 'Keterangan',
        //   field: 'keterangan',
        //   editable: true,
        //   resizable: true,
        //   sortable: true,
        // },
      ],
      frameworkComponents: {
        numericEditor: numericInput,
        dateEditor: dateInput,
        selectEditor: selectInput,
        customTooltip: tooltip,
        htmlRender: htmlrender,
      },
      rowClassRules: {
        'text-success': (params) => {
          return params.data.newdata !== undefined
        },
        'text-warning': (params) => {
          return params.data.edited !== undefined
        },
        'text-danger': (params) => {
          return params.data.isError !== undefined
        },
        'text-primary': (params) => {
          return params.data.isPrimary !== undefined && params.data.isPrimary === 1
        },
        'font-weight-bold': (params) => {
          return params.data.isBold !== undefined
        },
      },
      alreadyGenerated: false,
      // End property

      // Modal cari nasabah property
      carinasabahvisible: false,
      carinasabahtitle: 'Cari Nasabah',
      selectedRekening: '',
      input: {
        nama: '',
        alamat: '',
        cabang: '',
      },
      table: {
        tableatas: {
          column: [
            {
              title: 'Kode',
              rowKey: 'kode',
              dataIndex: 'kode',
              scopedSlots: { customRender: 'kode' },
              width: 100,
            },
            {
              title: 'Nama',
              dataIndex: 'nama',
              scopedSlots: { customRender: 'nama' },
              width: 150,
            },
            // {
            //   title: 'KTP',
            //   dataIndex: 'ktp',
            //   scopedSlots: { customRender: 'ktp' },
            //   width: 100,
            // },
            {
              title: 'Alamat',
              dataIndex: 'alamat',
              scopedSlots: { customRender: 'alamat' },
              // width: 100,
            },
            {
              title: '#',
              scopedSlots: { customRender: 'actionButton' },
              width: 70,
            },
          ],
          datatable: [],
        },
        tablebawah: {
          column: [
            {
              title: 'Jenis',
              dataIndex: 'jenis',
              scopedSlots: { customRender: 'jenis' },
              // width: 100,
            },
            {
              title: 'Rekening',
              dataIndex: 'rekening',
              scopedSlots: { customRender: 'rekening' },
              // width: 100,
            },
            {
              title: 'Saldo',
              dataIndex: 'saldo',
              scopedSlots: { customRender: 'saldo' },
              // width: 100,
            },
            {
              title: '#',
              scopedSlots: { customRender: 'actionButton' },
              // width: 70,
            },
          ],
          datatable: [],
        },
      },
      loading: false,
    }
  },
  created() {
    this.gridOptions = {}
    this.getMaster()
    // console.log('this.editdata.id', this.editdata.id)
    if (this.editdata.id !== '') {
      this.action = 'update'
      this.title = 'Update Nasabah'
      this.getEditData()
    } else {
      this.action = 'create'
      this.title = 'Create Nasabah'
    }
    // console.log('this.action', this.action)
  },
  methods: {
    moment,
    // ag-grid function
    async postToGetTable() {
      this.alreadyGenerated = true
      // this.rowData = [
      //   {
      //     ke: 0,
      //     total: 0,
      //     sisa_pokok: 100000000,
      //     sisa_bunga: 24000000,
      //     tgl: '2024-04-24',
      //     pokok: 0,
      //     bunga: 0,
      //   },
      //   {
      //     ke: 1,
      //     total: 10333333,
      //     sisa_pokok: 91666667,
      //     sisa_bunga: 22000000,
      //     tgl: '2024-05-24',
      //     pokok: 8333333,
      //     bunga: 2000000,
      //   },
      //   {
      //     ke: 2,
      //     total: 10333333,
      //     sisa_pokok: 83333334,
      //     sisa_bunga: 20000000,
      //     tgl: '2024-06-24',
      //     pokok: 8333333,
      //     bunga: 2000000,
      //   },
      //   {
      //     ke: 3,
      //     total: 10333333,
      //     sisa_pokok: 75000001,
      //     sisa_bunga: 18000000,
      //     tgl: '2024-07-24',
      //     pokok: 8333333,
      //     bunga: 2000000,
      //   },
      //   {
      //     ke: 4,
      //     total: 10333333,
      //     sisa_pokok: 66666668,
      //     sisa_bunga: 16000000,
      //     tgl: '2024-08-24',
      //     pokok: 8333333,
      //     bunga: 2000000,
      //   },
      //   {
      //     ke: 5,
      //     total: 10333333,
      //     sisa_pokok: 58333335,
      //     sisa_bunga: 14000000,
      //     tgl: '2024-09-24',
      //     pokok: 8333333,
      //     bunga: 2000000,
      //   },
      //   {
      //     ke: 6,
      //     total: 10333333,
      //     sisa_pokok: 50000002,
      //     sisa_bunga: 12000000,
      //     tgl: '2024-10-24',
      //     pokok: 8333333,
      //     bunga: 2000000,
      //   },
      //   {
      //     ke: 7,
      //     total: 10333333,
      //     sisa_pokok: 41666669,
      //     sisa_bunga: 10000000,
      //     tgl: '2024-11-24',
      //     pokok: 8333333,
      //     bunga: 2000000,
      //   },
      //   {
      //     ke: 8,
      //     total: 10333333,
      //     sisa_pokok: 33333336,
      //     sisa_bunga: 8000000,
      //     tgl: '2024-12-24',
      //     pokok: 8333333,
      //     bunga: 2000000,
      //   },
      //   {
      //     ke: 9,
      //     total: 10333333,
      //     sisa_pokok: 25000003,
      //     sisa_bunga: 6000000,
      //     tgl: '2025-01-24',
      //     pokok: 8333333,
      //     bunga: 2000000,
      //   },
      //   {
      //     ke: 10,
      //     total: 10333333,
      //     sisa_pokok: 16666670,
      //     sisa_bunga: 4000000,
      //     tgl: '2025-02-24',
      //     pokok: 8333333,
      //     bunga: 2000000,
      //   },
      //   {
      //     ke: 11,
      //     total: 10333333,
      //     sisa_pokok: 8333337,
      //     sisa_bunga: 2000000,
      //     tgl: '2025-03-24',
      //     pokok: 8333333,
      //     bunga: 2000000,
      //   },
      //   {
      //     ke: 12,
      //     total: 10333337,
      //     sisa_pokok: 0,
      //     sisa_bunga: 0,
      //     tgl: '2025-04-24',
      //     pokok: 8333337,
      //     bunga: 2000000,
      //   },
      // ]
      var data = {
        rekening: this.formData.rekening,
        tgl: this.formData.tgl.format('YYYY-MM-DD'),
        sukubunga: this.formData.sukubunga === '' || this.formData.sukubunga === null ? 0 : this.formData.sukubunga,
        plafond: this.formData.plafond === '' || this.formData.plafond === null ? 0 : this.formData.plafond,
        lama: this.formData.lama === '' || this.formData.lama === null ? 0 : this.formData.lama,
        graceperiod: this.formData.graceperiod === '' || this.formData.graceperiod === null ? 0 : this.formData.graceperiod,
        graceperiodbahas: this.formData.graceperiodbahas === '' || this.formData.graceperiodbahas === null ? 0 : this.formData.graceperiodbahas,
        jenis_angsuran: this.formData.jenis_angsuran, // untuk testing gunakan jenis_angsuran 0 dulu
      }
      var res = await lou.customUrlPost2('registrasi/pembiayaan/jadwal', data, false, false, false)
      if (res) {
        this.rowData = res.data
        this.generateTotalBunga()
        this.alreadyGenerated = true
      }
    },
    generateTotalBunga() {
      var total1 = 0
      var total2 = 0
      var total3 = 0
      this.rowData.forEach(element => {
        total1 += element.pokok
        total2 += element.bunga
        total3 += element.total
      })
      var d = {
        ke: '',
        total: total3,
        sisa_pokok: '',
        sisa_bunga: '',
        tgl: 'Total',
        pokok: total1,
        bunga: total2,
      }
      this.rowData.push(d)
      this.formData.totalbunga = total2
    },
    onGridReady(params) {
      // params.api.sizeColumnsToFit()
      this.gridApi = params.api
      this.gridColumnApi = params.columnApi
    },
    onCellValueChanged(params) {
      var undoSize = params.api.getCurrentUndoSize()
      this.input.undoInput = undoSize
      this.input.undoInputdisable = undoSize < 1
      var redoSize = params.api.getCurrentRedoSize()
      this.input.redoInput = redoSize
      this.input.redoInputdisable = redoSize < 1
      if (this.mode === 'oncreate') {
        if (params.data.newdata) {
          var target = this.changedData.findIndex(
            (x) => x.id === params.data.id,
          )
          if (target < 0) {
            this.changedData.push(params.data)
          } else {
            this.changedData[target] = params.data
          }
          // console.log('this.changedData', this.changedData)
        } else {
          // eslint-disable-next-line no-redeclare
          var target = this.rowData.findIndex((x) => x.id === params.data.id)
          var backupTarget = this.backupRowData.findIndex(
            (x) => x.id === params.data.id,
          )
          // console.log('this.rowData[target] before:', this.rowData[target])
          this.rowData[target] = this.$g.clone(
            this.backupRowData[backupTarget],
          )
          // console.log('this.rowData[target] after:', this.rowData[target])
          // console.log('this.backupRowData[backupTarget]', this.backupRowData[backupTarget])
          // this.componentKey += 1
          this.changedData = []
          this.input.undoInput = 0
          this.input.undoInputdisable = true

          this.input.redoInput = 0
          this.input.redoInputdisable = true
          lou.shownotif('Anda sedang dalam mode create')
        }
      } else {
        this.mode = 'onupdate'
        // eslint-disable-next-line no-redeclare
        var targetChanged = this.changedData.findIndex(
          (x) => x.id === params.data.id,
        )
        if (targetChanged < 0) {
          // var cd = {}
          // cd.id = params.data.id
          // cd[params.column.colId] = value.value
          // console.log('cd[params.column.colId]', cd)
          // console.log('value.value', value)
          this.changedData.push(params.data)
        } else {
          this.changedData[targetChanged][params.column.colId] =
            params.newValue
        }
        // console.log('this.changedData', this.changedData)
        // this.componentKey += 1
      }
      // console.log('this.mode', this.mode)
    },
    onSelectionChanged() {
      var selectedRows = this.gridApi.getSelectedRows()
      this.selectedData = selectedRows
      console.log('selectedRows', selectedRows)
    },
    onFirstDataRendered() {
      this.autoSizeAll(false, { autosizecolumn: true })
    },
    autoSizeAll(skipHeader, { autosizecolumn = true }) {
      if (autosizecolumn) {
        var allColumnIds = []
        // console.log('this.gridColumnApi', this.gridColumnApi)
        this.gridColumnApi.getAllColumns().forEach(function (column) {
          allColumnIds.push(column.colId)
        })
        this.gridColumnApi.autoSizeColumns(allColumnIds, skipHeader)
      } else {
        this.gridApi.sizeColumnsToFit()
      }
    },
    formatTanggal(v) {
      // console.log('v', v)
      // console.log('ntgl', ntgl)
      return moment(v, 'YYYY-MM-DD').format('DD-MM-YYYY')
    },
    dateFormatter(params) {
      // console.log('params', params)
      var ret =
        params.value === null
          ? ''
          : moment(params.value, 'YYYY-MM-DD').format('DD-MM-YYYY')
      // console.log('dateFormatter', ret)
      return ret !== 'Invalid date' ? ret : params.value
    },
    currencyFormatter(params) {
      var ret =
        params.value === null
          ? 'NaN'
          : lou.curency(parseFloat(params.value).toFixed(0))
      return ret === 'NaN' ? '' : ret
    },
    // End of Function

    ubahKe01(e, field) {
      var v = e.target.value ? 1 : 0
      this.formData[field] = v
    },

    // Modal Cari Nasabah Function
    clearRekening() {
      this.selectedRekening = ''
    },
    async findData() {
      // console.log('this.formData.rekening', this.formData.rekening)
      var imbuhan = ''
      imbuhan += this.input.nama !== '' ? '?q=' + this.input.nama : ''
      imbuhan +=
        this.input.alamat !== ''
          ? this.input.nama !== ''
            ? '&Alamat=' + this.input.alamat
            : '?Alamat=' + this.input.alamat
          : ''
      imbuhan += this.input.cabang !== '' ? '&cabang=' + this.input.cabang : ''
      var res = await lou.customUrlGet2(
        'registrasi/pembiayaan/cari_nama' + imbuhan,
        false,
      )
      if (res) {
        if (res.data.length === 0) {
          lou.shownotif('Info', 'Data tidak ditemukan!', 'warning')
          this.table.tableatas.datatable = []
        } else {
          lou.shownotif(
            'Info',
            'Ditemukan ' + res.data.length + ' data yang cocok!',
            'success',
          )
          this.table.tableatas.datatable = res.data
        }
        // for (let i = 0; i < 20; i++) {
        //   const element = res.data[0]
        //   this.table.tableatas.datatable.push(element)
        // }
      }
    },
    async nasabahDetail(record) {
      if (!this.loading) {
        this.loading = true
        var res = await lou.customUrlGet2(
          'registrasi/pembiayaan/cari_rekening?q=' + record.kode,
          false,
        )
        if (res) {
          if (res.data.length === 0) {
            lou.shownotif('Info', 'Data tidak ditemukan!', 'warning')
            this.table.tablebawah.datatable = []
          } else {
            lou.shownotif(
              'Info',
              'Ditemukan ' + res.data.length + ' data yang cocok!',
              'success',
            )
            this.table.tablebawah.datatable = res.data
          }
          this.listKode = this.$g.clone(res.data)
          this.backuplistKode = this.$g.clone(res.data)
          this.loading = false
        }
        this.loading = false
      }
    },
    selectModalTable1(record) {
      this.selectedKodeRegister = record.kode
      this.formData.kode = record.kode
      this.formData.nama = record.nama
      this.formData.alamat = record.alamat
      // this.nasabahDetail(record)
      this.carinasabahvisible = false
    },
    selectModalTable2(record) {
      // console.log('record', record)
      // this.selectedKodeRegister = record.kode
      // this.formData.kode = record.kode
      this.selectedRekening = record.rekening
      this.formData.rekening = record.rekening
      this.carinasabahvisible = false
    },
    customRow(record, index) {
      return {
        on: {
          click: (event) => {
            // console.log('record', record)
            // console.log('index', index)
            // console.log('event', event)
            this.selectedKodeRegister = record.kode
            this.formData.kode = record.kode
            this.formData.nama = record.nama
            this.formData.alamat = record.alamat
            // this.nasabahDetail(record)
          },
          contextmenu: (event) => {
            event.preventDefault()
          },
        },
      }
    },
    customRowB(record, index) {
      return {
        on: {
          click: (event) => {
            // this.selectedKodeRegister = record.kode
            // this.formData.kode = record.kode
            // console.log('record', record)
            this.selectedRekening = record.rekening
            // console.log('index', index)
            // console.log('event', event)
            // console.log('this.selectedRekening', this.selectedRekening)
            this.formData.rekening = record.rekening
          },
          contextmenu: (event) => {
            event.preventDefault()
          },
        },
      }
    },
    formatCurrency(v) {
      return lou.curency(v)
    },
    // End of Modal Cari Nasabah Function

    fetchUser(value) {
      // console.log('fetching user', value)
      // var hasil = this.backupmasterKota.filter(x => x.city_name.toLowerCase().includes(nval))
      // console.log('hasil', hasil)
      if (value !== '') {
        var nval = value.toLowerCase()
        this.lastFetchId += 1
        const fetchId = this.lastFetchId
        this.masterKota = []
        this.fetching = true
        var res = []
        res = this.backupmasterKota.filter((x) =>
          x.city_name.toLowerCase().includes(nval),
        )
        if (res.length !== 0) {
          if (fetchId !== this.lastFetchId) {
            // for fetch callback order
            return false
          }
          this.masterKota = res.data
          this.componentKey += 1
          this.fetching = false
        } else {
          this.fetching = null
        }
      }
    },
    async rekeningSearch(value) {
      if (value !== '' && value.length >= 3) {
        this.fetching = true
        // this.formData.kode = this.formData.cabang + this.formData.rekode
        var response = await lou.customUrlGet2(
          'registrasi/pembiayaan/cari_nama?q=' + value,
        )
        if (response) {
          this.listRekening = this.$g.clone(response.data)
          this.backuplistRekening = this.$g.clone(response.data)
          this.fetching = false
          this.componentKey += 1
          // console.log('this.backupmasterKota', this.backupmasterKota)
        } else {
          this.fetching = false
        }
      }
    },
    rekeningChange() {
      // console.log('this.formData.nama', this.formData.nama)
      // console.log('this.listRekening', this.listRekening)
      this.formData.kode = this.listRekening[this.formData.nama].kode
      this.kodeChange()
    },
    async kodeChange() {
      this.fetching = true
      // this.formData.kode = this.formData.cabang + this.formData.rekode
      var response = await lou.customUrlGet2(
        'registrasi/pembiayaan/cari_rekening?kode=' + this.formData.kode,
      )
      if (response) {
        this.listKode = this.$g.clone(response.data)
        this.backuplistKode = this.$g.clone(response.data)
        this.fetching = false
        this.componentKey += 1
        // console.log('this.backupmasterKota', this.backupmasterKota)
      } else {
        this.fetching = false
      }
    },
    changeDateInput(dateMoment, dateString, arrdata) {
      this[arrdata[0]][arrdata[1]] = dateMoment
      // console.log('dateMoment', dateMoment)
      // console.log('dateString', dateString)
      // console.log('arrdata', arrdata)
    },
    async getMaster() {
      this.loadingMaster = true
      var res = await lou.customUrlGet2('general/master/golongankredit')
      var res1 = await lou.customUrlGet2('general/master/ao')
      var res2 = await lou.customUrlGet2('general/master/golongankelompok')
      var res3 = await lou.customUrlGet2('registrasi/pembiayaan/jenis_angsuran')
      if (res) {
        this.mastergolongankredit = this.$g.clone(res.data)
        // console.log('this.backupmasterKota', this.backupmasterKota)
      }
      if (res1) {
        this.masterao = this.$g.clone(res1.data)
      }
      if (res2) {
        this.mastergolongankelompok = this.$g.clone(res2.data)
      }
      if (res3) {
        this.masterjenisangsuran = this.$g.clone(res3.data)
      }
    },
    async handleSubmit() {
      // Should format date value before submit.
      var res = false
      var fd = this.$g.clone(this.formData)
      fd.jadwal = this.rowData
      // console.log('fd', fd)
      // fd.rekening = fd.rekening === null || fd.rekening === undefined ? '' : this.formData.rekening
      fd.tgl =
        this.formData.tgl !== null
          ? this.formData.tgl.format('YYYY-MM-DD')
          : null
      // fd.tgllahir =
      //   this.formData.tgllahir !== null
      //     ? this.formData.tgllahir.format('YYYY-MM-DD')
      //     : null
      // fd.anggota = 1
      // fd.kodepos = parseInt(fd.kodepos)
      // fd.kodepos = fd.kodepos.toString()
      if (this.action === 'update') {
        res = await lou.customUrlPut2('registrasi/pembiayaan', fd, true)
      } else {
        delete fd.id
        delete fd.rekening
        res = await lou.customUrlPost2('registrasi/pembiayaan', fd, true)
      }
      if (res) {
        // lou.shownotif(
        //   'Success',
        //   'Data baru Berhasil ditambahkan dengan nomor Register: ' + res.data,
        //   'success',
        // )
        // Modal.confirm({
        //   title: 'Cetak Validasi?',
        //   content: 'Simpan berhasil!, Cetak Validasi?',
        //   okText: 'Yes',
        //   okType: 'primary',
        //   cancelText: 'No',
        //   onOk: async () => {
        //     var res1 = await lou.ngetDocumentPdf(
        //       'registrasi/pembiayaan/cetak/' + res.data.id,
        //       false,
        //       false,
        //     )
        //     if (res1) {
        //       this.resetForm()
        //       // console.log('res1', res1)
        //       // console.log("Success", res1ponse);
        //       const blob = new Blob([res1], {
        //         type: 'application/pdf',
        //       })
        //       var iframe = this._printIframe
        //       var url = URL.createObjectURL(blob)
        //       if (!this._printIframe) {
        //         iframe = this._printIframe = document.createElement('iframe')
        //         document.body.appendChild(iframe)

        //         iframe.style.display = 'none'
        //         iframe.onload = function () {
        //           setTimeout(function () {
        //             iframe.focus()
        //             iframe.contentWindow.print()
        //           }, 1)
        //         }
        //       }

        //       iframe.src = url
        //       // const link = document.createElement('a')
        //       // link.href = URL.createObjectURL(blob)
        //       // // link.target = '_blank'
        //       // // link.download = filename

        //       // link.download = ''
        //       // link.click()
        //       // // const objectUrl = URL.createObjectURL(blob)
        //       // // console.log('objectUrl', objectUrl)
        //       // // this.amodal.ArrayBuffer = objectUrl
        //       // URL.revokeObjectURL(blob)
        //       setTimeout(() => {
        //         this.componentKey += 1
        //         // this.amodalvisible = true
        //       }, 500)
        //     }
        //   },
        //   onCancel: () => {
        this.resetForm()
        //   },
        // })
      }
    },
    async getJadwalOnEdit() {
      var res = await lou.customUrlGet2('registrasi/pembiayaan/jadwal?rekening=' + this.formData.rekening, false, false, false)
      if (res) {
        setTimeout(() => {
          this.alreadyGenerated = true
        }, 500)
        this.rowData = res.data
        this.generateTotalBunga()
      }
    },
    getEditData() {
      // var res = await lou.customUrlGet2('registrasi/pembiayaan', { where: { id: this.editdata.id } })
      // console.log('this.editdata', this.editdata)
      this.formData = this.$g.clone(this.editdata)
      this.formData.nama = this.editdata.nama
      this.formData.alamat = this.editdata.alamat
      var target = this.mastergolongankredit.findIndex(x => x.id === this.editdata.golongankredit)
      // console.log('this.mastergolongankredit', this.mastergolongankredit)
      // console.log('this.editdata.golongankredit', this.editdata.golongankredit)
      // console.log('target', target)
      // console.log('this.mastergolongankredit[target].keterangan', this.mastergolongankredit[target].keterangan)
      this.maskInput.golongankredit = this.mastergolongankredit[target].keterangan
      // this.rowData = this.formData.jadwal
      this.getJadwalOnEdit()
      // console.log('this.editdata', this.editdata)
      // this.formData.cabang = this.editdata.kode.slice(0, this.editdata.kode.indexOf('.'))
      // this.formData.rekode = this.editdata.kode.slice(this.editdata.kode.indexOf('.') + 1, this.editdata.kode.length)
      this.formData.tgl = moment(this.editdata.tgl, 'YYYY-MM-DD')
      // setTimeout(() => {
      //   this.formData.kodya = this.editdata.kodya
      //   this.kotaChange()
      // }, 100)
      // setTimeout(() => {
      //   this.formData.kecamatan = this.editdata.kecamatan
      //   this.kecamatanChange()
      // }, 300)
      // setTimeout(() => {
      //   this.formData.kelurahan = this.editdata.kelurahan
      //   this.kelurahanChange()
    },
    resetForm() {
      localStorage.removeItem('minibankeditkreditdata')
      this.formData = {
        administrasi: 0,
        asuransi: 0,
        danasendiri: 0,
        graceperiod: 0,
        graceperiodbahas: 0,
        hargaperolehan: 0,
        jangkawaktu: 0,
        materai: 0,
        notaris: 0,
        persenprovisi: 0,
        plafond: 0,
        premiasuransi: 0,
        provisi: 0,
        tabaruk: 0,
        totalbunga: 0,
        uangmuka: 0,
        ujroh: 0,
        alamat: '',
        ao: '',
        autodebet: false,
        caraperhitungan: '',
        golongankelompok: '',
        golongankredit: '',
        jenis_angsuran: 0,
        kode: '',
        lama: 0,
        nama: '',
        nopolis: '',
        nospk: '',
        rekeningtabungan: '',
        sukubunga: 0,
        telepon: '',
        tgl: moment(),
        username: '',
        // um: '',
      }
      this.$router.push('/minibank/kredit/data')
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      )
    },
  },
}
</script>

<style lang="scss" scoped></style>
