<template>
  <div>
    <a-card>
      <template slot="title">
        <strong>Form Nasabah</strong>
        <a-button size="default" type="danger" class="ml-2" @click="resetForm"
          >Back</a-button
        >
      </template>
      <div class="content">
        <div class="row pl-3 pr-3 pt-3 pb-3">
          <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
            <div class="row">
              <div
                class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2"
                style="padding-right: unset"
              >
                <label class="mt-1">Tanggal</label>
              </div>
              <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                <!-- :readOnly="action === 'update'" -->
                <a-date-picker
                  style="width: 130px"
                  v-model="formData.tgl"
                  :allowClear="false"
                  format="DD-MM-YYYY"
                  @change="
                    (dateMoment, dateString) =>
                      changeDateInput(dateMoment, dateString, [
                        'formData',
                        'tgl',
                      ])
                  "
                />
              </div>
              <div
                class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2"
                style="padding-right: unset"
              >
                <label class="mt-1">Nama & Kelamin</label>
              </div>
              <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                <!-- :readOnly="action === 'update'" -->
                <a-input
                  style="width: 67%"
                  placeholder="Ketikan Nama"
                  v-model="formData.nama"
                ></a-input>
                <a-select
                  class="ml-2"
                  show-search
                  option-filter-prop="children"
                  :filter-option="filterOption"
                  v-model="formData.kelamin"
                  style="width: 30%"
                >
                  <a-select-option value="L">Laki-Laki</a-select-option>
                  <a-select-option value="P">Perempuan</a-select-option>
                </a-select>
              </div>
              <div
                class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2"
                style="padding-right: unset"
              >
                <label class="mt-1">Tempat & Tgl Lahir</label>
              </div>
              <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                <!-- :readOnly="action === 'update'" -->
                <a-input
                  style="width: 67%"
                  placeholder="Ketikan Tempat Lahir"
                  v-model="formData.tempatlahir"
                ></a-input>
                <a-date-picker
                  class="ml-2"
                  style="width: 30%"
                  v-model="formData.tgllahir"
                  :allowClear="false"
                  format="DD-MM-YYYY"
                  @change="
                    (dateMoment, dateString) =>
                      changeDateInput(dateMoment, dateString, [
                        'formData',
                        'tgllahir',
                      ])
                  "
                />
              </div>
              <div
                class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2"
                style="padding-right: unset"
              >
                <label class="mt-1">Nomor Telepon</label>
              </div>
              <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                <!-- :readOnly="action === 'update'" -->
                <a-input
                  placeholder="Masukan Nomor Telepon"
                  v-model="formData.telepon"
                ></a-input>
              </div>
              <div
                class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2"
                style="padding-right: unset"
              >
                <label class="mt-1">Status Kawin</label>
              </div>
              <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                <!-- :readOnly="action === 'update'" -->
                <a-select
                  show-search
                  option-filter-prop="children"
                  :filter-option="filterOption"
                  v-model="formData.statusperkawinan"
                  style="width: 100%"
                >
                  <a-select-option value="B">Belum Kawin</a-select-option>
                  <a-select-option value="K">Kawin</a-select-option>
                  <a-select-option value="J">Janda</a-select-option>
                  <a-select-option value="D">Duda</a-select-option>
                </a-select>
              </div>
              <div
                class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2"
                style="padding-right: unset"
              >
                <label class="mt-1">No KTP</label>
              </div>
              <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                <!-- <a-select
                  show-search
                  option-filter-prop="children"
                  :filter-option="filterOption"
                  v-model="formData.jenis_identitas"
                  style="width: 30%;"
                >
                  <a-select-option
                    v-for="(data, index) in masterJenisIndentitas"
                    :key="index"
                    :value="data.id"
                    >{{ data.keterangan }}</a-select-option
                  >
                </a-select> -->
                <!-- style="width: 67%;" -->
                <a-input
                  class="ml-"
                  placeholder="Ketikan No Identitas"
                  v-model="formData.ktp"
                ></a-input>
              </div>
              <div
                class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2"
                style="padding-right: unset"
              >
                <label class="mt-1">Agama</label>
              </div>
              <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                <!-- :readOnly="action === 'update'" -->
                <a-select
                  v-if="masterAgama.length !== 0"
                  show-search
                  option-filter-prop="children"
                  :filter-option="filterOption"
                  v-model="formData.agama"
                  style="width: 100%"
                >
                  <a-select-option
                    v-for="(data, index) in masterAgama"
                    :key="index"
                    :value="data.kode"
                    >{{ data.keterangan }}</a-select-option
                  >
                </a-select>
                <a-input
                  v-else
                  style="width: 100%"
                  placeholder="Masukan Agama"
                  v-model="formData.agama"
                ></a-input>
              </div>
              <div
                class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2"
                style="padding-right: unset"
              >
                <label class="mt-1">Pekerjaan</label>
              </div>
              <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                <!-- :readOnly="action === 'update'" -->
                <a-select
                  v-if="masterPekerjaan.length !== 0"
                  show-search
                  option-filter-prop="children"
                  :filter-option="filterOption"
                  v-model="formData.pekerjaan"
                  style="width: 100%"
                >
                  <a-select-option
                    v-for="(data, index) in masterPekerjaan"
                    :key="index"
                    :value="data.kode"
                    >{{ data.keterangan }}</a-select-option
                  >
                </a-select>
                <a-input
                  v-else
                  placeholder="Masukan Pekerjaan"
                  v-model="formData.pekerjaan"
                ></a-input>
              </div>
              <div
                class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2"
                style="padding-right: unset"
              >
                <label class="mt-1">Nama Ibu Kandung</label>
              </div>
              <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                <!-- :readOnly="action === 'update'" -->
                <a-input
                  placeholder="Masukan Nama Ibu Kandung"
                  v-model="formData.ibukandung"
                ></a-input>
              </div>
            </div>
          </div>
          <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
            <div class="row">
              <div
                class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2"
                style="padding-right: unset"
              >
                <label class="mt-1">Kota</label>
              </div>
              <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                <!-- :readOnly="action === 'update'" -->
                <v-select
                  placeholder="Kota"
                  label="keterangan"
                  v-model="formData.kodya"
                  :options="masterKota"
                  :reduce="(tes) => tes.kode"
                  @input="kotaChange(false)"
                />
              </div>
              <div
                class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2"
                style="padding-right: unset"
              >
                <label class="mt-1">Kecamatan</label>
              </div>
              <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                <!-- :readOnly="action === 'update'" -->
                <v-select
                  placeholder="Kecamatan"
                  label="keterangan"
                  v-model="formData.kecamatan"
                  :options="masterKecamatan"
                  :reduce="(tes) => tes.kode"
                  @input="kecamatanChange(false)"
                />
              </div>
              <div
                class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2"
                style="padding-right: unset"
              >
                <label class="mt-1">Kelurahan</label>
              </div>
              <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                <!-- :readOnly="action === 'update'" -->
                <!-- <a-select
                  show-search
                  option-filter-prop="children"
                  :filter-option="filterOption"
                  v-model="formData.kelurahan"
                  placeholder="Kelurahan"
                  @input="kelurahanChange"
                >
                  <a-select-option
                    v-for="(data, index) in masterKelurahan"
                    :key="index"
                    :value="data.kode"
                    >{{ data.keterangan }}</a-select-option
                  >
                </a-select> -->
                <v-select
                  placeholder="Kelurahan"
                  label="keterangan"
                  v-model="formData.kelurahan"
                  :options="masterKelurahan"
                  :reduce="(tes) => tes.kode"
                />
              </div>
              <div
                class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2"
                style="padding-right: unset"
              >
                <label class="mt-1">RT/RW</label>
              </div>
              <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                <!-- :readOnly="action === 'update'" -->
                <!-- class="ml-2" -->
                <a-input
                  class="ant-input"
                  placeholder="01/01"
                  v-model="formData.rtrw"
                  style="width: 30%"
                />
              </div>
              <div
                class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2"
                style="padding-right: unset"
              >
                <label class="mt-1">Kode Pos</label>
              </div>
              <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                <!-- :readOnly="action === 'update'" -->
                <!-- class="ml-2" -->
                <a-input
                  placeholder="KodePos"
                  v-model="formData.kodepos"
                  style="width: 30%"
                ></a-input>
              </div>
              <div
                class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2"
                style="padding-right: unset"
              >
                <label class="mt-1">Alamat</label>
              </div>
              <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                <!-- :readOnly="action === 'update'" -->
                <a-input
                  placeholder="Masukan Alamat"
                  v-model="formData.alamat"
                ></a-input>
              </div>
              <div
                class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2"
                style="padding-right: unset"
              >
                <label class="mt-1">NPWP</label>
              </div>
              <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                <!-- :readOnly="action === 'update'" -->
                <a-input
                  placeholder="Masukan NPWP"
                  v-model="formData.npwp"
                ></a-input>
              </div>
              <div
                class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2"
                style="padding-right: unset"
              >
                <label class="mt-1">Tambahan</label>
              </div>
              <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                <!-- :readOnly="action === 'update'" -->
                <a-input
                  placeholder="Masukan Tambahan"
                  v-model="formData.tambahan"
                ></a-input>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-2">
        <a-button
          size="default"
          type="primary"
          class="float-right"
          @click="handleSubmit"
          >Submit</a-button
        >
              </div>
              <!-- <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-2">
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </a-card>
  </div>
</template>

<script>
import debounce from 'lodash/debounce'
// import { Modal } from 'ant-design-vue'
import moment from 'moment'
import * as lou from '@/services/data/lou'
import { mapState } from 'vuex'
// import Cleave from 'vue-cleave-component'

export default {
  components: {
    // cleave: Cleave,
  },
  computed: {
    ...mapState(['settings', 'user']),
  },
  data() {
    this.lastFetchId = 0
    this.fetchUser = debounce(this.fetchUser, 800)
    // this.fetchKelurahan = debounce(this.fetchKelurahan, 800)
    return {
      editdata: JSON.parse(
        localStorage.getItem('minibankeditregisternasabahdata'),
      ),
      action: null,
      title: 'Register Nasabah',
      fetching: false,
      loadingMaster: false,
      maskInput: {
        cabang: '',
        rekode: '',
        kota: '',
        kecamatan: '',
      },
      formData: {
        kode: '',
        tgl: moment(),
        nama: '',
        kelamin: 'P',
        tgllahir: moment().subtract(18, 'years'),
        tempatlahir: '',
        statusperkawinan: '',
        // jenis_identitas: '',
        ktp: '',
        agama: '',
        pekerjaan: '',
        alamat: '',
        kodepos: '',
        telepon: '',
        kodya: '',
        kecamatan: '',
        kelurahan: '',
        rtrw: '',
        npwp: '',
        ibukandung: '',
        tambahan: '',
      },
      allMaster: {},
      masterJenisIndentitas: [],
      masterAgama: [],
      masterPostalCode: [],
      masterPekerjaan: [],
      masterKota: [],
      masterKecamatan: [],
      masterKelurahan: [],
      backupmasterJenisIndentitas: [],
      backupmasterAgama: [],
      backupmasterPostalCode: [],
      backupmasterPekerjaan: [],
      backupmasterKota: [],
      backupmasterKecamatan: [],
      backupmasterKelurahan: [],
      componentKey: 0,
    }
  },
  created() {
    this.getMaster()
    // console.log('this.editdata.id', this.editdata.id)
    if (this.editdata.id !== '') {
      this.action = 'update'
      this.title = 'Update Nasabah'
      this.getEditData()
    } else {
      this.action = 'create'
      this.title = 'Create Nasabah'
    }
    // console.log('this.action', this.action)
  },
  methods: {
    moment,
    fetchUser(value) {
      // console.log('fetching user', value)
      // var hasil = this.backupmasterKota.filter(x => x.city_name.toLowerCase().includes(nval))
      // console.log('hasil', hasil)
      if (value !== '') {
        var nval = value.toLowerCase()
        this.lastFetchId += 1
        const fetchId = this.lastFetchId
        this.masterKota = []
        this.fetching = true
        var res = []
        res = this.backupmasterKota.filter((x) =>
          x.city_name.toLowerCase().includes(nval),
        )
        if (res.length !== 0) {
          if (fetchId !== this.lastFetchId) {
            // for fetch callback order
            return false
          }
          this.masterKota = res.data
          this.componentKey += 1
          this.fetching = false
        } else {
          this.fetching = null
        }
      }
    },
    kodeChange() {
      this.formData.kode = this.formData.cabang + this.formData.rekode
    },
    postalCodeChange() {
      // find related data for kota, kecamatan, kelurahan and add to alamat
    },
    async kotaChange(exclude = false, forced = false) {
      if (exclude === false) {
        this.formData.kecamatan = ''
        this.formData.kelurahan = ''
        // if (this.action !== 'update') {
        // }
      }
      // setTimeout(() => {
      // }, 500)
      var response = await lou.customUrlGet2(
        'transaksi/registrasi/anggota/kecamatan?kodya=' + this.formData.kodya,
      )
      if (response) {
        this.masterKecamatan = this.$g.clone(response.data)
        this.backupmasterKecamatan = this.$g.clone(response.data)
        if (forced) {
          this.kecamatanChange(true, true)
        }
        // console.log('this.backupmasterKecamatan', this.backupmasterKecamatan)
      }
      // console.log('e', e)
      // var targetKota = this.backupmasterKota.findIndex(x => x.city_id === e)
      // var data = this.backupmasterKota[targetKota]
      // console.log('data', data)
      // var targetFilter = this.backupmasterKecamatan.filter(
      //   (x) => x.kodya === this.formData.kodya,
      // )
      // if (targetFilter.length !== 0) {
      //   this.masterKecamatan = this.$g.clone(targetFilter)
      //   if (!exclude) {
      //     this.formData.kecamatan = ''
      //     this.formData.kelurahan = ''
      //   }
      //   // console.log('this.masterKecamatan', this.masterKecamatan)
      // }
      // find related data for kecamatan and add to alamat
    },
    async kecamatanChange(exclude = false, forced = false) {
      if (exclude === false) {
        // if (this.action !== 'update') {
        this.formData.kelurahan = ''
        // }
      }
      var response = await lou.customUrlGet2(
        'transaksi/registrasi/anggota/kelurahan?kodya=' + this.formData.kodya + '&kecamatan=' + this.formData.kecamatan,
      )
      if (response) {
        this.masterKelurahan = this.$g.clone(response.data)
        this.backupmasterKelurahan = this.$g.clone(response.data)
        // console.log('this.backupmasterKelurahan', this.backupmasterKelurahan)
        if (forced) {
          this.kelurahanChange()
        }
      }
      // console.log('e', e)
      // console.log('this.backupmasterKecamatan', this.backupmasterKecamatan)
      // var targetKota = this.backupmasterKecamatan.findIndex(x => x.city_id === e)
      // var data = this.backupmasterKecamatan[targetKota]
      // console.log('data', data)
      // console.log('this.backupmasterKelurahan', this.backupmasterKelurahan)
      // var targetFilter = this.backupmasterKelurahan.filter(
      //   (x) => x.kecamatan === this.formData.kecamatan,
      // )
      // // console.log('targetFilter', targetFilter)
      // if (targetFilter.length !== 0) {
      //   this.masterKelurahan = this.$g.clone(targetFilter)
      //   if (!exclude) {
      //     if (this.action !== 'update') {
      //       this.formData.kelurahan = ''
      //     }
      //   }
      //   // console.log('this.masterKelurahan', this.masterKelurahan)
      // }
      // var targetIndex = this.
      // find related data for kelurahan and add to alamat
    },
    kelurahanChange() {
      // console.log('e', e)
      // console.log('this.masterKelurahankelurahanChange', this.masterKelurahan)
      // console.log('this.formData.kelurahan', this.formData.kelurahan)
      var targetKota = this.masterKelurahan.findIndex(
        (x) => x.kode === this.formData.kelurahan,
      )
      // console.log('targetKota', targetKota)
      var data = this.masterKelurahan[targetKota]
      // console.log('data', data)
      if (this.action !== 'update') {
        this.formData.kodepos = data.kodepos
      }
      // console.log('data', data)
      // this.formData.alamat =
      //   ',' + data.kelurahan + ',' + data.kecamatan + ',' + data.kota + ',' + data.kodepos.toString()
      // this.formData.kodepos = data.kodepos.toString()
      // var targetIndex = this.
      // find related data for kelurahan and add to alamat
    },
    changeDateInput(dateMoment, dateString, arrdata) {
      this[arrdata[0]][arrdata[1]] = dateMoment
      // console.log('dateMoment', dateMoment)
      // console.log('dateString', dateString)
      // console.log('arrdata', arrdata)
    },
    async getMaster() {
      this.loadingMaster = true
      this.getMasterPekerjaan()
      // if (this.action !== 'update') {
      this.getMasterKota()
      // }
      // this.getMasterKecamatan()
      // this.getMasterKelurahan()
      if (this.action === 'update') {
        // this.formData.agama = ''
      } else {
        this.formData.agama =
          this.masterAgama.length !== 0 ? this.masterAgama[0].kode : ''
      }
    },
    async getMasterPekerjaan() {
      var response = await lou.customUrlGet2('general/master/pekerjaan')
      if (response) {
        this.masterPekerjaan = this.$g.clone(response.data)
        this.backupmasterPekerjaan = this.$g.clone(response.data)
        // console.log('this.backupmasterKota', this.backupmasterKota)
      }
      var responseAgama = await lou.customUrlGet2('general/master/agama')
      if (responseAgama) {
        this.masterAgama = this.$g.clone(responseAgama.data)
        this.backupmasterAgama = this.$g.clone(responseAgama.data)
        // console.log('this.backupmasterKota', this.backupmasterKota)
      }
    },
    async getMasterKota() {
      var response = await lou.customUrlGet2(
        'transaksi/registrasi/anggota/kodya',
      )
      if (response) {
        this.masterKota = this.$g.clone(response.data)
        this.backupmasterKota = this.$g.clone(response.data)
        // console.log('this.backupmasterKota', this.backupmasterKota)
      }
    },
    async getMasterKecamatan() {
      var response = await lou.customUrlGet2(
        'transaksi/registrasi/anggota/kecamatan',
      )
      if (response) {
        if (this.action === 'update') {
          this.masterKecamatan = this.$g.clone(response.data)
        }
        this.backupmasterKecamatan = this.$g.clone(response.data)
        // console.log('this.backupmasterKecamatan', this.backupmasterKecamatan)
      }
      if (this.action === 'update') {
        this.kotaChange(true, true)
      }
    },
    async getMasterKelurahan() {
      var response = await lou.customUrlGet2(
        'transaksi/registrasi/anggota/kelurahan',
      )
      if (response) {
        if (this.action === 'update') {
          // this.masterKelurahan = this.$g.clone(response.data)
        }
        this.backupmasterKelurahan = this.$g.clone(response.data)
        // console.log('this.backupmasterKelurahan', this.backupmasterKelurahan)
      }
      if (this.action === 'update') {
        this.kecamatanChange(true)
      }
      this.loadingMaster = false
    },
    async handleSubmit() {
      // Should format date value before submit.
      var res = false
      var fd = this.$g.clone(this.formData)
      // console.log('fd', fd)
      fd.tgl =
        this.formData.tgl !== null
          ? this.formData.tgl.format('YYYY-MM-DD')
          : null
      fd.tgllahir =
        this.formData.tgllahir !== null
          ? this.formData.tgllahir.format('YYYY-MM-DD')
          : null
      fd.anggota = 1
      // fd.kodepos = parseInt(fd.kodepos)
      fd.kodepos = fd.kodepos.toString()
      if (this.action === 'update') {
        res = await lou.customUrlPut2(
          'transaksi/registrasi/anggota',
          fd,
          true,
        )
      } else {
        delete fd.id
        res = await lou.customUrlPost2(
          'transaksi/registrasi/anggota',
          fd,
          true,
        )
      }
      if (res) {
        // lou.shownotif(
        //   'Success',
        //   'Data baru Berhasil ditambahkan dengan nomor Register: ' + res.data,
        //   'success',
        // )
        this.resetForm()
      }
    },
    getEditData() {
      // var res = await lou.customUrlGet2('transaksi/registrasi/anggota', { where: { id: this.editdata.id } })
      // console.log('this.editdata', this.editdata)
      this.formData = this.$g.clone(this.editdata)
      // console.log('this.editdata', this.editdata)
      // this.maskInput.cabang = this.editdata.kode.slice(0, this.editdata.kode.indexOf('.'))
      // this.maskInput.rekode = this.editdata.kode.slice(this.editdata.kode.indexOf('.') + 1, this.editdata.kode.length)
      this.formData.tgl = moment(this.editdata.tgl, 'YYYY-MM-DD')
      this.formData.tgllahir = moment(this.editdata.tgllahir, 'YYYY-MM-DD')
      this.formData.kodya = this.editdata.kodya
      this.formData.kecamatan = this.editdata.kecamatan
      this.formData.kelurahan = this.editdata.kelurahan
      if (this.action !== 'update') {
        this.formData.kodepos = this.editdata.kodepos
      }
      setTimeout(() => {
        this.kotaChange(true, true)
      }, 100)
      // setTimeout(() => {
      //   this.kecamatanChange()
      // }, 300)
      // setTimeout(() => {
      //   this.kelurahanChange()
      // }, 400)
    },
    resetForm() {
      localStorage.removeItem('minibankeditregisternasabahdata')
      this.formData = {
        kode: '',
        tgl: moment().format('YYYY-MM-DD'),
        nama: '',
        kelamin: 'P',
        tgllahir: moment().subtract(18, 'years').format('YYYY-MM-DD'),
        tempatlahir: '',
        statusperkawinan: '',
        // jenis_identitas: '',
        ktp: '',
        agama: '',
        pekerjaan: '',
        alamat: '',
        kodepos: '',
        telepon: '',
        kodya: '',
        kecamatan: '',
        kelurahan: '',
        rtrw: '',
        npwp: '',
        ibukandung: '',
        tambahan: '',
      }
      this.$router.push('/minibank/registernasabah/data')
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      )
    },
  },
}
</script>

<style lang="scss" scoped></style>
