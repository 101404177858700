<template>
  <div>
    <a-card>
      <template slot="title">
        <strong>Form Anggota</strong>
        <a-button
          size="default"
          type="danger"
          class="ml-2"
          @click="resetForm"
          >Back</a-button
        >
      </template>
      <div class="content">
        <div class="row pl-3 pr-3 pt-3 pb-3">
          <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
            <div class="row">
              <div
                v-if="action !== 'create'"
                class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2"
                style="padding-right: unset;"
              >
                <label class="mt-1">Kode</label>
              </div>
              <div
                v-if="action !== 'create'"
                class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1"
              >
                <!-- :readOnly="action === 'update'" -->
                <a-input
                  style="width: 60px;"
                  placeholder=""
                  v-model="maskInput.cabang"
                ></a-input>
                <a-input
                  class="ml-2"
                  style="width: 67%;"
                  placeholder="Ketikan Kode"
                  v-model="maskInput.rekode"
                  @input="kodeChange"
                ></a-input>
              </div>
              <div
                class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2"
                style="padding-right: unset;"
              >
                <label class="mt-1">Tanggal</label>
              </div>
              <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                <!-- :readOnly="action === 'update'" -->
                <a-date-picker
                  style="width: 130px;"
                  v-model="formData.tgl"
                  :allowClear="false"
                  format="DD-MM-YYYY"
                  @change="
                    (dateMoment, dateString) =>
                      changeDateInput(dateMoment, dateString, [
                        'formData',
                        'tgl',
                      ])
                  "
                />
              </div>
              <div
                class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2"
                style="padding-right: unset;"
              >
                <label class="mt-1">Nama & Kelamin</label>
              </div>
              <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                <!-- :readOnly="action === 'update'" -->
                <a-input
                  style="width: 67%;"
                  placeholder="Ketikan Nama"
                  v-model="formData.nama"
                ></a-input>
                <a-select
                  class="ml-2"
                  show-search
                  option-filter-prop="children"
                  :filter-option="filterOption"
                  v-model="formData.kelamin"
                  style="width: 30%;"
                >
                  <a-select-option value="L">Laki-Laki</a-select-option>
                  <a-select-option value="P">Perempuan</a-select-option>
                </a-select>
              </div>
              <div
                class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2"
                style="padding-right: unset;"
              >
                <label class="mt-1">Tempat & Tgl Lahir</label>
              </div>
              <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                <!-- :readOnly="action === 'update'" -->
                <a-input
                  style="width: 67%;"
                  placeholder="Ketikan Tempat Lahir"
                  v-model="formData.tempatlahir"
                ></a-input>
                <a-date-picker
                  class="ml-2"
                  style="width: 30%;"
                  v-model="formData.tgllahir"
                  :allowClear="false"
                  format="DD-MM-YYYY"
                  @change="
                    (dateMoment, dateString) =>
                      changeDateInput(dateMoment, dateString, [
                        'formData',
                        'tgllahir',
                      ])
                  "
                />
              </div>
              <div
                class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2"
                style="padding-right: unset;"
              >
                <label class="mt-1">Nomor HP</label>
              </div>
              <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                <!-- :readOnly="action === 'update'" -->
                <a-input
                  placeholder="Masukan Nomor HP"
                  v-model="formData.hp"
                ></a-input>
              </div>
              <div
                class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2"
                style="padding-right: unset;"
              >
                <label class="mt-1">Status Kawin</label>
              </div>
              <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                <!-- :readOnly="action === 'update'" -->
                <a-select
                  show-search
                  option-filter-prop="children"
                  :filter-option="filterOption"
                  v-model="formData.statusperkawinan"
                  style="width: 100%;"
                >
                  <a-select-option value="B">Belum Kawin</a-select-option>
                  <a-select-option value="K">Kawin</a-select-option>
                  <a-select-option value="J">Janda</a-select-option>
                  <a-select-option value="D">Duda</a-select-option>
                </a-select>
              </div>
              <div
                class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2"
                style="padding-right: unset;"
              >
                <label class="mt-1">No Identitas</label>
              </div>
              <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                <a-select
                  show-search
                  option-filter-prop="children"
                  :filter-option="filterOption"
                  v-model="formData.jenis_identitas"
                  style="width: 30%;"
                >
                  <a-select-option
                    v-for="(data, index) in masterJenisIndentitas"
                    :key="index"
                    :value="data.id"
                    >{{ data.keterangan }}</a-select-option
                  >
                </a-select>
                <a-input
                  class="ml-2"
                  style="width: 67%;"
                  placeholder="Ketikan No Identitas"
                  v-model="formData.no_identitas"
                ></a-input>
              </div>
              <div
                class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2"
                style="padding-right: unset;"
              >
                <label class="mt-1">Agama</label>
              </div>
              <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                <!-- :readOnly="action === 'update'" -->
                <a-select
                  v-if="masterAgama.length !== 0"
                  show-search
                  option-filter-prop="children"
                  :filter-option="filterOption"
                  v-model="formData.agama"
                  style="width: 100%;"
                >
                  <a-select-option
                    v-for="(data, index) in masterAgama"
                    :key="index"
                    :value="data.kode"
                    >{{ data.keterangan }}</a-select-option
                  >
                </a-select>
                <a-input
                  v-else
                  style="width: 100%;"
                  placeholder="Masukan Agama"
                  v-model="formData.agama"
                ></a-input>
              </div>
              <div
                class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2"
                style="padding-right: unset;"
              >
                <label class="mt-1">Pekerjaan</label>
              </div>
              <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                <!-- :readOnly="action === 'update'" -->
                <a-input
                  placeholder="Masukan Pekerjaan"
                  v-model="formData.pekerjaan"
                ></a-input>
              </div>
              <div
                class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2"
                style="padding-right: unset;"
              >
                <label class="mt-1">Nama Ibu Kandung</label>
              </div>
              <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                <!-- :readOnly="action === 'update'" -->
                <a-input
                  placeholder="Masukan Nama Ibu Kandung"
                  v-model="formData.ibukandung"
                ></a-input>
              </div>
            </div>
          </div>
          <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
            <div class="row">
              <div
                class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2"
                style="padding-right: unset;"
              >
                <label class="mt-1">Kota</label>
              </div>
              <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                <!-- :readOnly="action === 'update'" -->
                <v-select
                  placeholder="Kota"
                  label="city_name"
                  v-model="maskInput.kota"
                  :options="masterKota"
                  :reduce="(tes) => tes.city_id"
                  @input="kotaChange"
                />
              </div>
              <div
                class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2"
                style="padding-right: unset;"
              >
                <label class="mt-1">Kecamatan</label>
              </div>
              <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                <!-- :readOnly="action === 'update'" -->
                <v-select
                  placeholder="Kecamatan"
                  label="dis_name"
                  v-model="maskInput.kecamatan"
                  :options="masterKecamatan"
                  :reduce="(tes) => tes.dis_id"
                        @input="kecamatanChange"
                />
              </div>
              <div
                class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2"
                style="padding-right: unset;"
              >
                <label class="mt-1">Kelurahan</label>
              </div>
              <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                <!-- :readOnly="action === 'update'" -->
                <v-select
                  placeholder="Kelurahan"
                  label="kelurahan"
                  v-model="formData.kelurahan"
                  :options="masterKelurahan"
                  :reduce="(tes) => tes.subdis_id"
                        @input="kelurahanChange"
                />
              </div>
              <div
                class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2"
                style="padding-right: unset;"
              >
                <label class="mt-1">Kode Pos</label>
              </div>
              <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                <!-- :readOnly="action === 'update'" -->
                  <!-- class="ml-2" -->
                <a-input
                  placeholder="KodePos"
                  v-model="formData.kodepos"
                  style="width: 30%;"
                ></a-input>
              </div>
              <div
                class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2"
                style="padding-right: unset;"
              >
                <label class="mt-1">Alamat</label>
              </div>
              <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                <!-- :readOnly="action === 'update'" -->
                <a-input
                  placeholder="Masukan Alamat"
                  v-model="formData.alamat"
                ></a-input>
              </div>
              <div
                class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2"
                style="padding-right: unset;"
              >
                <label class="mt-1">NPWP</label>
              </div>
              <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                <!-- :readOnly="action === 'update'" -->
                <a-input
                  placeholder="Masukan NPWP"
                  v-model="formData.npwp"
                ></a-input>
              </div>
              <div
                class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2"
                style="padding-right: unset;"
              >
                <label class="mt-1">Alamat Tinggal</label>
              </div>
              <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                <!-- :readOnly="action === 'update'" -->
                <a-input
                  placeholder="Masukan Alamat Tinggal"
                  v-model="formData.alamat_tinggal"
                ></a-input>
              </div>
              <div
                class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2"
                style="padding-right: unset;"
              >
                <label class="mt-1">Simpanan Pokok</label>
              </div>
              <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                <cleave
                  :options="{ numeral: true }"
                  class="ant-input"
                  :raw="true"
                  style="width: 200px;"
                  v-model="formData.simpanan_pokok"
                  placeholder="Simpanan Pokok"
                />
              </div>
              <div
                class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2"
                style="padding-right: unset;"
              >
                <label class="mt-1">Simpanan Wajib</label>
              </div>
              <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                <cleave
                  :options="{ numeral: true }"
                  class="ant-input"
                  :raw="true"
                  style="width: 200px;"
                  v-model="formData.simpanan_wajib"
                  placeholder="Simpanan Wajib"
                />
                <a-button
                  size="default"
                  type="primary"
                  class="float-right"
                  @click="handleSubmit"
                  >Submit</a-button
                >
              </div>
              <div
                class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2"
                style="padding-right: unset;"
              >
                <label class="mt-1">Instansi</label>
              </div>
              <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                <!-- :readOnly="action === 'update'" -->
                <v-select
                  placeholder="Instansi"
                  label="keterangan"
                  v-model="formData.instansi_id"
                  :options="masterInstansi"
                  :reduce="(tes) => tes.id"
                />
              </div>
              <!-- <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-2">
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </a-card>
  </div>
</template>

<script>
import debounce from 'lodash/debounce'
// import { Modal } from 'ant-design-vue'
import moment from 'moment'
import * as lou from '@/services/data/lou'
import { mapState } from 'vuex'
import Cleave from 'vue-cleave-component'

export default {
  components: {
    cleave: Cleave,
  },
  computed: {
    ...mapState(['settings', 'user']),
  },
  data() {
    this.lastFetchId = 0
    this.fetchUser = debounce(this.fetchUser, 800)
    return {
      editdata: JSON.parse(
        localStorage.getItem('newkoperasieditregisternasabahdata'),
      ),
      action: null,
      title: 'Register Nasabah',
      fetching: false,
      loadingMaster: false,
      maskInput: {
        cabang: '',
        rekode: '',
        kota: '',
        kecamatan: '',
      },
      formData: {
        kode: '',
        tgl: moment(),
        nama: '',
        kelamin: 'P',
        tgllahir: moment().subtract(18, 'years'),
        tempatlahir: '',
        statusperkawinan: '',
        jenis_identitas: '',
        no_identitas: '',
        agama: '',
        pekerjaan: '',
        alamat: '',
        kodepos: '',
        hp: '',
        kelurahan: '',
        npwp: '',
        alamat_tinggal: '',
        ibukandung: '',
        simpanan_pokok: 0,
        simpanan_wajib: 0,
        anggota: 1,
        instansi_id: null,
      },
      allMaster: {},
      masterJenisIndentitas: [],
      masterAgama: [],
      masterPostalCode: [],
      masterInstansi: [],
      masterKota: [],
      masterKecamatan: [],
      masterKelurahan: [],
      backupmasterJenisIndentitas: [],
      backupmasterAgama: [],
      backupmasterPostalCode: [],
      backupmasterInstansi: [],
      backupmasterKota: [],
      backupmasterKecamatan: [],
      backupmasterKelurahan: [],
      componentKey: 0,
    }
  },
  created() {
    this.getMaster()
    // console.log('this.editdata.id', this.editdata.id)
    if (this.editdata.id !== '') {
      this.action = 'update'
      this.title = 'Update Nasabah'
      this.getEditData()
    } else {
      this.action = 'create'
      this.title = 'Create Nasabah'
    }
    // console.log('this.action', this.action)
  },
  methods: {
    moment,
    fetchUser(value) {
      // console.log('fetching user', value)
      // var hasil = this.backupmasterKota.filter(x => x.city_name.toLowerCase().includes(nval))
      // console.log('hasil', hasil)
      if (value !== '') {
        var nval = value.toLowerCase()
        this.lastFetchId += 1
        const fetchId = this.lastFetchId
        this.masterKota = []
        this.fetching = true
        var res = []
        res = this.backupmasterKota.filter((x) =>
          x.city_name.toLowerCase().includes(nval),
        )
        if (res.length !== 0) {
          if (fetchId !== this.lastFetchId) {
            // for fetch callback order
            return false
          }
          this.masterKota = res.data
          this.componentKey += 1
          this.fetching = false
        } else {
          this.fetching = null
        }
      }
    },
    kodeChange() {
      this.formData.kode = this.formData.cabang + this.formData.rekode
    },
    postalCodeChange() {
      // find related data for kota, kecamatan, kelurahan and add to alamat
    },
    kotaChange(e, exclude = false) {
      // console.log('e', e)
      // var targetKota = this.backupmasterKota.findIndex(x => x.city_id === e)
      // var data = this.backupmasterKota[targetKota]
      // console.log('data', data)
      var targetFilter = this.backupmasterKecamatan.filter(
        (x) => x.city_id === e,
      )
      if (targetFilter.length !== 0) {
        this.masterKecamatan = this.$g.clone(targetFilter)
        if (!exclude) {
          this.maskInput.kecamatan = ''
          this.formData.kelurahan = ''
        }
        // console.log('this.masterKecamatan', this.masterKecamatan)
      }
      // find related data for kecamatan and add to alamat
    },
    kecamatanChange(e, exclude = false) {
      // console.log('e', e)
      // console.log('this.backupmasterKecamatan', this.backupmasterKecamatan)
      // var targetKota = this.backupmasterKecamatan.findIndex(x => x.city_id === e)
      // var data = this.backupmasterKecamatan[targetKota]
      // console.log('data', data)
      // console.log('this.backupmasterKelurahan', this.backupmasterKelurahan)
      var targetFilter = this.backupmasterKelurahan.filter(
        (x) => x.dis_id === e,
      )
      // console.log('targetFilter', targetFilter)
      if (targetFilter.length !== 0) {
        this.masterKelurahan = this.$g.clone(targetFilter)
        if (!exclude) {
          this.formData.kelurahan = ''
        }
        console.log('this.masterKelurahan', this.masterKelurahan)
      }
      // var targetIndex = this.
      // find related data for kelurahan and add to alamat
    },
    kelurahanChange(e) {
      // console.log('e', e)
      var targetKota = this.backupmasterKelurahan.findIndex(
        (x) => x.subdis_id === this.formData.kelurahan,
      )
      var data = this.backupmasterKelurahan[targetKota]
      this.formData.kodepos = data.kodepos
      // console.log('data', data)
      // this.formData.alamat =
      //   ',' + data.kelurahan + ',' + data.kecamatan + ',' + data.kota + ',' + data.kodepos.toString()
      // this.formData.kodepos = data.kodepos.toString()
      // var targetIndex = this.
      // find related data for kelurahan and add to alamat
    },
    changeDateInput(dateMoment, dateString, arrdata) {
      this[arrdata[0]][arrdata[1]] = dateMoment
      // console.log('dateMoment', dateMoment)
      // console.log('dateString', dateString)
      // console.log('arrdata', arrdata)
    },
    async getMaster() {
      this.loadingMaster = true
      var indexeddb = await lou.getDataFromDb()
      // console.log('indexeddb', indexeddb)
      // console.log('this.settings.whatapp', this.settings.whatapp)
      var allmaster = {}
      var masterIndex = indexeddb.findIndex(
        (x) =>
          x.mykey ===
          'allmaster' +
            this.settings.whatapp.appName.toLowerCase() +
            this.settings.whatapp.id,
      )
      // console.log('masterIndex', indexeddb[masterIndex])
      if (masterIndex < 0) {
        var res = await lou.customUrlGet2('master', false, true)
        var master = {
          mykey:
            'allmaster' +
            this.settings.whatapp.appName.toLowerCase() +
            this.settings.whatapp.id,
          data: JSON.stringify(res.data),
        }
        await lou.addDataToDb(master)
        var nindexeddb = await lou.getDataFromDb()
        var targetIndex = nindexeddb.findIndex(
          (x) =>
            x.mykey ===
            'allmaster' +
              this.settings.whatapp.appName.toLowerCase() +
              this.settings.whatapp.id,
        )
        // console.log('nindexeddb', nindexeddb[targetIndex])
        allmaster = JSON.parse(nindexeddb[targetIndex].data)
      } else {
        allmaster = JSON.parse(indexeddb[masterIndex].data)
      }
      var cabanglist = []
      for (let u = 0; u < this.user.cabangList.length; u++) {
        const element = this.user.cabangList[u]
        // console.log('element', element)
        cabanglist.push({ kode: element.kode, keterangan: element.kode })
      }
      // console.log('indexeddb', indexeddb)
      // console.log('allmaster', allmaster)
      this.allMaster = allmaster
      // console.log('this.allMaster', this.allMaster)
      this.masterAgama = this.$g.clone(this.allMaster.agama)
      this.masterJenisIndentitas = this.$g.clone(
        this.allMaster.jenis_identitas,
      )
      // this.backupmasterKelurahan = this.$g.clone(this.allMaster.ec_subdistricts)
      // this.masterPostalCode = this.$g.clone(this.allMaster.postal_code)
      this.getMasterInstansi()
      this.getMasterKota()
      this.getMasterKecamatan()
      this.getMasterKelurahan()
      if (this.action === 'update') {
        // this.formData.agama = ''
      } else {
        this.formData.agama =
          this.masterAgama.length !== 0 ? this.masterAgama[0].kode : ''
      }
    },
    async getMasterInstansi() {
      var response = await lou.customUrlGet2('instansi')
      if (response) {
        this.masterInstansi = this.$g.clone(response.data)
        this.backupmasterInstansi = this.$g.clone(response.data)
        // console.log('this.backupmasterKota', this.backupmasterKota)
      }
    },
    async getMasterKota() {
      var indexeddb = await lou.getDataFromDb()
      var masterKotaIndex = indexeddb.findIndex(
        (x) =>
          x.mykey ===
          'masterKota' +
            this.settings.whatapp.appName.toLowerCase() +
            this.settings.whatapp.id,
      )
      if (masterKotaIndex === -1) {
        var response = await lou.customUrlGet2('registernasabah/kota')
        if (response) {
          var masterKota = {
            mykey:
              'masterKota' +
              this.settings.whatapp.appName.toLowerCase() +
              this.settings.whatapp.id,
            data: JSON.stringify(response.data),
          }
          await lou.addDataToDb(masterKota)
          this.masterKota = this.$g.clone(response.data)
          this.backupmasterKota = this.$g.clone(response.data)
          // console.log('this.backupmasterKota', this.backupmasterKota)
        }
      } else {
        this.masterKota = JSON.parse(indexeddb[masterKotaIndex].data)
        this.backupmasterKota = JSON.parse(indexeddb[masterKotaIndex].data)
      }
    },
    async getMasterKecamatan() {
      var indexeddb = await lou.getDataFromDb()
      var masterKecamatanIndex = indexeddb.findIndex(
        (x) =>
          x.mykey ===
          'masterKecamatan' +
            this.settings.whatapp.appName.toLowerCase() +
            this.settings.whatapp.id,
      )
      if (masterKecamatanIndex === -1) {
        var response = await lou.customUrlGet2('registernasabah/kecamatan')
        if (response) {
          var masterKecamatan = {
            mykey:
              'masterKecamatan' +
              this.settings.whatapp.appName.toLowerCase() +
              this.settings.whatapp.id,
            data: JSON.stringify(response.data),
          }
          await lou.addDataToDb(masterKecamatan)
          if (this.action === 'update') {
            this.masterKecamatan = this.$g.clone(response.data)
          }
          this.backupmasterKecamatan = this.$g.clone(response.data)
          // console.log('this.backupmasterKecamatan', this.backupmasterKecamatan)
        }
      } else {
        if (this.action === 'update') {
          this.masterKecamatan = JSON.parse(indexeddb[masterKecamatanIndex].data)
        }
        this.backupmasterKecamatan = JSON.parse(indexeddb[masterKecamatanIndex].data)
      }
      if (this.action === 'update') {
        this.kotaChange(this.editdata.city_id, true)
      }
    },
    async getMasterKelurahan() {
      var indexeddb = await lou.getDataFromDb()
      var masterKelurahanIndex = indexeddb.findIndex(
        (x) =>
          x.mykey ===
          'masterKelurahan' +
            this.settings.whatapp.appName.toLowerCase() +
            this.settings.whatapp.id,
      )
      if (masterKelurahanIndex === -1) {
        var response = await lou.customUrlGet2('registernasabah/kelurahan')
        if (response) {
          var masterKelurahan = {
            mykey:
              'masterKelurahan' +
              this.settings.whatapp.appName.toLowerCase() +
              this.settings.whatapp.id,
            data: JSON.stringify(response.data),
          }
          await lou.addDataToDb(masterKelurahan)
          if (this.action === 'update') {
            this.masterKelurahan = this.$g.clone(response.data)
          }
          this.backupmasterKelurahan = this.$g.clone(response.data)
          // console.log('this.backupmasterKelurahan', this.backupmasterKelurahan)
        }
      } else {
        if (this.action === 'update') {
          this.masterKelurahan = JSON.parse(indexeddb[masterKelurahanIndex].data)
        }
        this.backupmasterKelurahan = JSON.parse(indexeddb[masterKelurahanIndex].data)
      }
      if (this.action === 'update') {
        this.kecamatanChange(this.editdata.dis_id, true)
      }
      this.loadingMaster = false
    },
    async handleSubmit() {
      // Should format date value before submit.
      var res = false
      var fd = this.$g.clone(this.formData)
      // console.log('fd', fd)
      fd.tgl =
        this.formData.tgl !== null
          ? this.formData.tgl.format('YYYY-MM-DD')
          : null
      fd.tgllahir =
        this.formData.tgllahir !== null
          ? this.formData.tgllahir.format('YYYY-MM-DD')
          : null
      fd.anggota = 1
      fd.kodepos = parseInt(fd.kodepos)
      if (this.action === 'update') {
        res = await lou.customUrlPut2('registernasabah', fd, false)
      } else {
        delete fd.id
        res = await lou.customUrlPost2('registernasabah', fd, false)
      }
      if (res) {
        lou.shownotif('Success', 'Data baru Berhasil ditambahkan dengan nomor Register: ' + res.data, 'success')
        this.resetForm()
      }
    },
    getEditData() {
      // var res = await lou.customUrlGet2('registernasabah', { where: { id: this.editdata.id } })
      // console.log('this.editdata', this.editdata)
      this.formData = this.$g.clone(this.editdata)
      this.maskInput.cabang = this.editdata.kode.slice(0, this.editdata.kode.indexOf('.'))
      this.maskInput.rekode = this.editdata.kode.slice(this.editdata.kode.indexOf('.') + 1, this.editdata.kode.length)
      this.formData.tgl = moment(this.editdata.tgl, 'YYYY-MM-DD')
      this.formData.tgllahir = moment(this.editdata.tgllahir, 'YYYY-MM-DD')
      this.maskInput.kota = this.editdata.city_id
      this.maskInput.kecamatan = this.editdata.dis_id
    },
    resetForm() {
      localStorage.removeItem('newkoperasieditregisternasabahdata')
      this.input = {
        kode: '',
        tgl: moment().format('YYYY-MM-DD'),
        nama: '',
        kelamin: 'P',
        tgllahir: moment().subtract(18, 'years').format('YYYY-MM-DD'),
        tempatlahir: '',
        statusperkawinan: '',
        jenis_identitas: '',
        no_identitas: '',
        agama: '',
        pekerjaan: '',
        alamat: '',
        kodepos: '',
        hp: '',
        kelurahan: '',
        npwp: '',
        alamat_tinggal: '',
        ibukandung: '',
        simpanan_pokok: 0,
        simpanan_wajib: 0,
        anggota: 1,
        instansi_id: null,
      }
      this.$router.push('/koperasi/registernasabah/data')
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      )
    },
  },
}
</script>

<style lang="scss" scoped></style>
