import { render, staticRenderFns } from "./k01.vue?vue&type=template&id=4d5ae6ba&"
import script from "./k01.vue?vue&type=script&lang=js&"
export * from "./k01.vue?vue&type=script&lang=js&"
import style0 from "./k01.vue?vue&type=style&index=0&id=4d5ae6ba&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports