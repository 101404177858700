<template>
  <div>
    <a-card>
      <template slot="title">
        <strong class="mr-4">Data Siswa</strong>
        <!-- <a-range-picker class="ml-4" :format="'DD-MM-YYYY'" @change="rangedofunction"/> -->
        <!-- <a-button
          type="button"
          class="btn btn-outline-primary ml-2"
          @click="connector('nonanggota')"
        >
          Tambah Non Anggota
        </a-button> -->
        <a-button-group>
          <a-button @click="showModal('create', {})">
            <a-icon class="text-primary" type="plus-circle"></a-icon>
          </a-button>
          <a-tooltip placement="top">
            <template slot="title">
              <span>Refresh Data</span>
            </template>
            <a-button @click="getAllData(true)">
              <a-icon type="sync" :spin="loading" class="text-success" />
            </a-button>
          </a-tooltip>
          <a-button class="text-success" @click="exportExcel">
            <a-icon type="file-excel" />
          </a-button>
        </a-button-group>
      </template>
      <a-table
        :columns="columns"
        :scroll="{ x: 2000 }"
        :dataSource="data"
        style="margin: -25px"
        :pagination="{
          hideOnSinglePage: true,
          defaultPageSize: 10,
          showQuickJumper: true,
          showSizeChanger: true,
          showTotal: (total) => `Total ${total} items`,
          pageSizeOptions: ['10', '20', '30'],
        }"
        size="small"
      >
        <div
          slot="filterDropdown"
          slot-scope="{
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
            column,
          }"
          style="padding: 8px"
        >
          <a-input
            v-ant-ref="(c) => (searchInput = c)"
            :placeholder="`Search ${column.dataIndex}`"
            :value="selectedKeys[0]"
            style="width: 188px; margin-bottom: 8px; display: block"
            @change="
              (e) => setSelectedKeys(e.target.value ? [e.target.value] : [])
            "
            @pressEnter="
              () => handleSearch(selectedKeys, confirm, column.dataIndex)
            "
          />
          <a-button
            type="primary"
            icon="search"
            size="small"
            style="width: 90px; margin-right: 8px"
            @click="() => handleSearch(selectedKeys, confirm, column.dataIndex)"
          >
            Search
          </a-button>
          <a-button
            size="small"
            style="width: 90px"
            @click="() => handleReset(clearFilters)"
          >
            Reset
          </a-button>
        </div>
        <a-icon
          slot="filterIcon"
          slot-scope="filtered"
          type="search"
          :style="{ color: filtered ? '#108ee9' : undefined }"
        ></a-icon>
        <template slot="customRender" slot-scope="text, record, index, column">
          <span v-if="searchText && searchedColumn === column.dataIndex">
            <template
              v-for="(fragment, i) in text
                .toString()
                .split(new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i'))"
            >
              <mark
                v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                :key="i"
                class="highlight"
                >{{ fragment }}</mark
              >
              <template v-else>{{ fragment }}</template>
            </template>
          </span>
          <template v-else>
            {{ text }}
          </template>
        </template>
        <a slot="name" slot-scope="text" href="javascript:;">{{ text }}</a>
        <span slot="customTitle"><a-icon type="smile-o" /> Kode</span>
        <span slot="tags" slot-scope="tags">
          <a-tag v-for="tag in tags" color="blue" :key="tag">{{ tag }}</a-tag>
        </span>
        <template slot="photo_url" slot-scope="text, record">
          <!-- <img :src="text" :alt="record.name"> -->
            <center>
              <img
                v-if="text === null"
                src="./favicon.png"
                :alt="record.name"
                style="width: 50px; height: 50px; margin-top: 4px"
              />
              <img
              @click="lihatgambar(record)"
                v-else
                :src="text"
                :alt="record.name"
                style="width: 50px; height: 50px; margin-top: 4px;cursor: pointer;"
              />
            </center>
        </template>
        <span slot="action" slot-scope="text, record">
          <a
            href="javascript:void(0)"
            class="text-warning"
            @click="showModal('update', record)"
          >
            <a-icon type="edit"></a-icon>
          </a>
          <a-divider type="vertical" />
          <a
            href="javascript:void(0)"
            class="text-danger"
            @click="showDeleteConfirm(record)"
          >
            <a-icon type="delete"></a-icon>
          </a>
          <!-- <a href="javascript:;" class="ant-dropdown-link">
            More actions <a-icon type="down" />
          </a> -->
        </span>
        <span slot="numberFormat" slot-scope="text">
          {{ numberFormat(text) }}
        </span>
        <span slot="academic_year_render" slot-scope="text">
          {{ findMasterRekening(text) }}
        </span>
        <span slot="dateFormat" slot-scope="text">
          {{ formatDate(text) }}
        </span>
      </a-table>
    </a-card>
    <a-modal v-model="formvisible" title="Siswa" :dialogStyle="{ top: '10px' }">
      <template slot="footer">
        <a-button class="" type="danger" @click="handleCancel">Cancel</a-button>
        <a-button class="" type="primary" @click="adagambartak"
          >Submit</a-button
        >
      </template>
      <div class="row">
        <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 mt-1">
          <label class="mt-1">NIS</label>
        </div>
        <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8">
          <a-input
            class=""
            v-model="modalInput.nis"
            placeholder="Masukan NIS"
          ></a-input>
        </div>
        <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 mt-2">
          <label class="mt-1">Nama</label>
        </div>
        <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8 mt-1">
          <a-input
            class=""
            v-model="modalInput.name"
            placeholder="Masukan Nama"
          ></a-input>
        </div>
        <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 mt-2">
          <label class="mt-1">Tanggal Lahir</label>
        </div>
        <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8 mt-1">
          <a-date-picker
            class=""
            style="width: 120px"
            v-model="modalInput.birth_date"
            :allowClear="false"
            format="DD-MM-YYYY"
          />
        </div>
        <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 mt-2">
          <label class="mt-1">Gender</label>
        </div>
        <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8 mt-1">
          <a-select class="" v-model="modalInput.gender">
            <a-select-option value="L">Laki-Laki</a-select-option>
            <a-select-option value="P">Perempuan</a-select-option>
          </a-select>
        </div>
        <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 mt-2">
          <label class="mt-1">Alamat</label>
        </div>
        <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8 mt-1">
          <a-input
            class=""
            v-model="modalInput.address"
            placeholder="Masukan Alamat"
          ></a-input>
        </div>
        <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 mt-2">
          <label class="mt-1">Hp</label>
        </div>
        <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8 mt-1">
          <a-input
            class=""
            v-model="modalInput.phone"
            placeholder="Masukan Hp"
          ></a-input>
        </div>
        <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 mt-2">
          <label class="mt-1">Email</label>
        </div>
        <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8 mt-1">
          <a-input
            class=""
            type="email"
            v-model="modalInput.email"
            placeholder="Masukan Email"
          ></a-input>
        </div>
        <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 mt-2">
          <label class="mt-1">Nama Ibu</label>
        </div>
        <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8 mt-1">
          <a-input
            class=""
            v-model="modalInput.mother_name"
            placeholder="Masukan Nama Ibu"
          ></a-input>
        </div>
        <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 mt-2">
          <label class="mt-1">Nama Ayah</label>
        </div>
        <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8 mt-1">
          <a-input
            class=""
            v-model="modalInput.father_name"
            placeholder="Masukan Nama Ayah"
          ></a-input>
        </div>
        <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 mt-2">
          <label class="mt-1">No Orang Tua</label>
        </div>
        <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8 mt-1">
          <a-input
            class=""
            v-model="modalInput.parent_phone_number"
            placeholder="Masukan No Orang Tua"
          ></a-input>
        </div>
        <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 mt-2">
          <label class="mt-1">Tanggal Masuk</label>
        </div>
        <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8 mt-1">
          <a-date-picker
            class=""
            style="width: 120px"
            v-model="modalInput.enrollment_date"
            :allowClear="false"
            format="DD-MM-YYYY"
          />
        </div>
        <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 mt-2">
          <label class="mt-1">Kelas</label>
        </div>
        <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8 mt-1">
          <a-select
            class=""
            show-search
            placeholder="Pilih Kelas"
            v-model="modalInput.class_id"
            option-filter-prop="children"
            :filter-option="filterOption"
            style="width: 100%"
          >
            <!-- <a-spin v-if="fetching" slot="notFoundContent" size="small" /> -->
            <a-select-option
              style="width: 100%"
              v-for="d in masterRekening"
              :key="d.id"
            >
              <!-- {{ formatDate(d.start_date) + " - " + formatDate(d.end_date) }} -->
              {{ d.name }}
            </a-select-option>
          </a-select>
        </div>
        <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 mt-2">
          <label class="mt-1">Image</label>
        </div>
        <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8 mt-1">
          <div
            @click="triggerTheUploadFile"
            style="
            cursor: pointer;
              width: 60px;
              height: 60px;
              border: 1px solid grey;
              border-radius: 4px;
            "
          >
            <center>
              <img
                v-if="mask.icon_url === null"
                src="./favicon.png"
                alt=""
                style="width: 50px; height: 50px; margin-top: 4px"
              />
              <img
                v-else
                :src="mask.icon_url"
                alt=""
                style="width: 50px; height: 50px; margin-top: 4px"
              />
            </center>
            <input
              type="file"
              ref="file"
              style="display: none"
              accept="image/png"
              v-on:change="onFileChange"
            />
          </div>
        </div>
      </div>
    </a-modal>
    <zoomgambar ref="zoomgambar" />
  </div>
</template>

<script>
/* Import Script */
import { Modal } from 'ant-design-vue'
import * as lou from '@/services/data/lou'
import moment from 'moment'
import NProgress from 'nprogress'

import zoomgambar from '@/components/lou/zoomgambar.vue'
// import Cleave from 'vue-cleave-component'
// import { mapState } from 'vuex'
/* End Script Import Script */

/* Kolom atau header dari table */
const columns = [
  {
    title: 'Action',
    scopedSlots: { customRender: 'action' },
    fixed: 'left',
    width: 70,
  },
  {
    title: 'Image',
    dataIndex: 'thumbnail_url',
    width: 100,
    scopedSlots: { customRender: 'photo_url' },
  },
  {
    title: 'NIS',
    dataIndex: 'nis',
    width: 90,
    // fixed: 'left',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
    onFilter: (value, record) =>
      record.nis.toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => {
          this.searchInput.focus()
        }, 0)
      }
    },
  },
  {
    title: 'Name',
    dataIndex: 'name',
    // width: 160,
    // fixed: 'left',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
    onFilter: (value, record) =>
      record.name.toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => {
          this.searchInput.focus()
        }, 0)
      }
    },
  },
  {
    title: 'Birth Date',
    // width: 100,
    dataIndex: 'birth_date',
    scopedSlots: { customRender: 'dateFormat' },
    // width: 100,
  },
  {
    title: 'Gender',
    dataIndex: 'gender',
    width: 90,
    // fixed: 'left',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
    onFilter: (value, record) =>
      record.gender.toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => {
          this.searchInput.focus()
        }, 0)
      }
    },
  },
  {
    title: 'Address',
    dataIndex: 'address',
    // width: 160,
    // fixed: 'left',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
    onFilter: (value, record) =>
      record.address.toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => {
          this.searchInput.focus()
        }, 0)
      }
    },
  },
  {
    title: 'HP',
    dataIndex: 'phone',
    // width: 160,
    // fixed: 'left',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
    onFilter: (value, record) =>
      record.phone.toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => {
          this.searchInput.focus()
        }, 0)
      }
    },
  },
  {
    title: 'Email',
    dataIndex: 'email',
    // width: 160,
    // fixed: 'left',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
    onFilter: (value, record) =>
      record.email.toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => {
          this.searchInput.focus()
        }, 0)
      }
    },
  },
  {
    title: 'Mother Name',
    dataIndex: 'mother_name',
    // width: 160,
    // fixed: 'left',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
    onFilter: (value, record) =>
      record.email.toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => {
          this.searchInput.focus()
        }, 0)
      }
    },
  },
  {
    title: 'Father Name',
    dataIndex: 'father_name',
    // width: 160,
    // fixed: 'left',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
    onFilter: (value, record) =>
      record.email.toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => {
          this.searchInput.focus()
        }, 0)
      }
    },
  },
  {
    title: 'Parent Phone Number',
    dataIndex: 'parent_phone_number',
    // width: 160,
    // fixed: 'left',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
    onFilter: (value, record) =>
      record.email.toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => {
          this.searchInput.focus()
        }, 0)
      }
    },
  },
  {
    title: 'Enrollment Date',
    // width: 100,
    dataIndex: 'enrollment_date',
    scopedSlots: { customRender: 'dateFormat' },
    // width: 100,
  },
  {
    title: 'Kelas',
    dataIndex: 'class_name',
    // width: 160,
    // fixed: 'left',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
    onFilter: (value, record) =>
      record.class_name.toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => {
          this.searchInput.focus()
        }, 0)
      }
    },
  },
]
/* End script Kolom atau header dari table */

/* Data dari table */
const data = []
/* End script Data dari table */

export default {
  components: {

    zoomgambar,
    // cleave: Cleave,
  },
  computed: {
    // ...mapState(['settings', 'user']),
  },
  created() {
    this.getAllData()
  },
  /* Data variable vue */
  data() {
    return {
      loading: false,

      searchText: '',
      tgl1: '',
      tgl2: '',
      searchInput: null,
      searchedColumn: '',
      name: 'Tabletabungan',
      visible: false,
      allMaster: [],
      masterstatuskawin: [],
      masterkelamin: [],
      data,
      columns,
      datenow: '',
      componentKey: 0,

      // Modal property
      masterRekening: [],
      action: null,
      formvisible: false,
      editdata: {},
      modalInput: {
        nis: '',
        name: '',
        birth_date: moment().subtract(5, 'y'),
        gender: 'L',
        address: '',
        phone: '',
        email: '',
        mother_name: '',
        father_name: '',
        parent_phone_number: '',
        enrollment_date: moment(),
        class_id: '',
        photo: null,
      },
      mask: {
        inputImage: null,
        icon_url: null,
      },
    }
  },
  /* End Data variable vue */
  mounted: function () {
    const date = moment().format('YYYY-MM-DD') // Tanggal hari ini format 2020-12-41
    // localStorage.removeItem('newkoperasiedittabungandata')
    this.tgl1 = date
    this.tgl2 = date
    this.datenow = date.toString()
    this.getMaster()
  },
  methods: {
    moment,
    async onFileChange(e) {
      // console.log('e', e)
      const file = e.target.files[0]
      this.modalInput.photo = file
      this.mask.inputImage = this.$refs.file.files[0]
      this.mask.icon_url = URL.createObjectURL(file)
    },
    triggerTheUploadFile() {
      this.$refs.file.click()
    },
    async getMaster() {
      this.masterRekening = []
      var res = await lou.customUrlGet2('sekolah/student/class')
      if (res) {
        this.masterRekening = res.data
      }
    },
    findMasterRekening(key) {
      var ret = ''
      var trgt = this.masterRekening.findIndex((x) => x.id === key)
      if (trgt >= 0) {
        // console.log('this.masterRekening[trgt]', this.masterRekening[trgt])
        ret = this.masterRekening[trgt].name
      } else {
        ret = 'Data tidak ditemukan!'
      }
      return ret
    },
    async showModal(action, data) {
      this.masterRekening = []
      var res = await lou.customUrlGet2('sekolah/student/class')
      // console.log('res', data)
      if (res) {
        res.data.forEach((x) => {
          var np = {
            ...x,
            label: x.start_date + ' - ' + x.end_date,
          }
          this.masterRekening.push(np)
        })
        this.formvisible = true
        this.action = action
        if (action === 'update') {
          this.modalInput = this.$g.clone(data)
          this.modalInput.birth_date = moment(data.birth_date, 'YYYY-MM-DD')
          this.modalInput.enrollment_date = moment(
            data.enrollment_date,
            'YYYY-MM-DD',
          )
          this.editdata = data
          this.mask.icon_url = this.editdata.photo_url
        } else {
          this.action = 'create'
          // this.masterRekening = []
          this.modalInput = {
            nis: '',
            name: '',
            birth_date: moment().subtract(5, 'y'),
            gender: 'L',
            address: '',
            phone: '',
            email: '',
            mother_name: '',
            father_name: '',
            parent_phone_number: '',
            enrollment_date: moment(),
            class_id: '',
            photo: null,
          }
          this.mask = {
            inputImage: null,
            icon_url: null,
          }
        }
      } else {
        // lou.shownotif('Api!', 'Pastikan i')
      }
    },
    async getAllData(onclick = false) {
      var response = await lou.customUrlGet2('sekolah/student')
      if (response) {
        this.data = response.data
        if (onclick) {
          lou.shownotif('Refresh', 'Data telah di refresh.')
        }
      }
      // console.log('this.data', this.data)
    },
    formatDate(value) {
      return moment(value).format('DD-MM-YYYY')
    },
    showDeleteConfirm(deldata) {
      Modal.confirm({
        title: 'Are you sure delete this data?',
        content: "You can't prevent this!!! ",
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'No',
        onOk: async () => {
          var response = await lou.customUrlDelete2(
            'sekolah/student/' + deldata.id,
          )
          if (response) {
            this.getAllData()
          }
        },
        onCancel: () => {
          // console.log('Cancel')
        },
      })
    },
    handleSearch(selectedKeys, confirm, dataIndex) {
      confirm()
      this.searchText = selectedKeys[0]
      this.searchedColumn = dataIndex
    },

    handleReset(clearFilters) {
      clearFilters()
      this.searchText = ''
    },

    getImage() {},

    adagambartak() {
      // console.log('this.modalInput.photo', this.modalInput.photo)
      // if (this.modalInput.photo !== null) {
      this.handleSubmitWImage()
      // } else {
      //   this.handleSubmit()
      // }
    },
    // Modal function
    // async handleSubmit() {
    //   var fd = this.$g.clone(this.modalInput)
    //   fd.birth_date = this.modalInput.birth_date.format('YYYY-MM-DD')
    //   fd.enrollment_date = this.modalInput.enrollment_date.format('YYYY-MM-DD')
    //   var res = false
    //   if (this.action === 'update') {
    //     delete fd.photo
    //     res = await lou.customUrlPut2('sekolah/student', fd)
    //   } else {
    //     delete fd.photo
    //     res = await lou.customUrlPost2('sekolah/student', fd)
    //   }
    //   if (res) {
    //     this.getAllData()
    //     this.handleCancel()
    //   }
    // },
    async handleSubmitWImage() {
      var fd = this.$g.clone(this.modalInput)
      fd.birth_date = this.modalInput.birth_date.format('YYYY-MM-DD')
      fd.enrollment_date = this.modalInput.enrollment_date.format('YYYY-MM-DD')
      fd.photo = this.mask.inputImage
      // console.log('this.mask', this.mask)
      var formData = new FormData()
      // Append the additional form data
      formData.append('nis', fd.nis)
      formData.append('name', fd.name)
      formData.append('birth_date', fd.birth_date)
      formData.append('gender', fd.gender)
      formData.append('address', fd.address)
      formData.append('phone', fd.phone)
      formData.append('email', fd.email)
      formData.append('mother_name', fd.mother_name)
      formData.append('father_name', fd.father_name)
      formData.append('parent_phone_number', fd.parent_phone_number)
      formData.append('enrollment_date', fd.enrollment_date)
      formData.append('class_id', fd.class_id)
      formData.append('file', this.mask.inputImage)
      // console.log('formData', formData)
      var res = false
      if (this.action === 'update') {
        formData.append('id', fd.id)
        res = await lou.customUrlPut2ButFile('sekolah/student/w_photo', formData)
        // res = await lou.customUrlPut2ButFile('sekolah/student', fd)
      } else {
        res = await lou.customUrlPost2ButFile('sekolah/student/w_photo', formData)
      }
      if (res) {
        this.getAllData()
        this.handleCancel()
      }
    },
    handleCancel() {
      this.formvisible = false
      this.action = 'create'
      // this.masterRekening = []
      this.modalInput = {
        nis: '',
        name: '',
        birth_date: moment().subtract(5, 'y'),
        gender: 'L',
        address: '',
        phone: '',
        email: '',
        mother_name: '',
        father_name: '',
        parent_phone_number: '',
        enrollment_date: moment(),
        class_id: '',
        photo: null,
      }
      this.mask = {
        inputImage: null,
        icon_url: null,
      }
    },
    async exportExcel() {
      NProgress.start()
      const ExcelJS = require('exceljs')
      const workbook = new ExcelJS.Workbook()
      workbook.creator = 'LB-BPRS Heasoft System'
      workbook.lastModifiedBy = 'Me'
      workbook.created = new Date()
      workbook.modified = new Date()
      workbook.lastPrinted = new Date()
      var alphabeth = [
        'A',
        'B',
        'C',
        'D',
        'E',
        'F',
        'G',
        'H',
        'I',
        'J',
        'K',
        'L',
        'M',
        'N',
        'O',
        'P',
        'Q',
        'R',
        'S',
        'T',
        'U',
        'V',
        'W',
        'X',
        'Y',
        'Z',
      ]
      const worksheet = workbook.addWorksheet(moment().format('DD-MM-YYYY'))

      // Table Data
      var column = this.$g.clone(this.columns)
      var tdata = this.$g.clone(this.data)
      var data = []
      tdata.forEach((element) => {
        var retv = {
          ...element,
          tgl:
            element.tgl === null
              ? ''
              : moment(element.tgl, 'YYYY-MM-DD').format('DD-MM-YYYY'),
        }
        data.push(retv)
      })
      var cfirst = 0
      var cloop = 0
      var dincrement = 2
      var columnLocation = []
      for (let i = 0; i < column.length; i++) {
        const element = column[i]
        var cloopni = cloop - 1
        var hname = element.title.toString()
        if (hname !== 'Action') {
          if (cloop === 0) {
            columnLocation.push({
              location: alphabeth[cfirst],
              field: element.dataIndex,
            })
            worksheet.getCell(alphabeth[cfirst] + '1').value = hname.replaceAll(
              ' ',
              '',
            )
          } else {
            columnLocation.push({
              location: alphabeth[cloopni] + alphabeth[cfirst],
              field: element.dataIndex,
            })
            worksheet.getCell(
              alphabeth[cloopni] + alphabeth[cfirst] + '1',
            ).value = hname.replaceAll(' ', '')
          }
          for (let u = 0; u < data.length; u++) {
            const el = data[u]
            if (cloop === 0) {
              worksheet.getCell(alphabeth[cfirst] + dincrement).value =
                el[element.dataIndex]
            } else {
              worksheet.getCell(
                alphabeth[cloopni] + alphabeth[cfirst] + dincrement,
              ).value = el[element.dataIndex]
            }
            var checklast = u + 1
            if (checklast === data.length) {
              dincrement = 2
            } else {
              dincrement += 1
            }
          }
          if (cfirst === 25) {
            cfirst = 0
            cloop += 1
          } else {
            cfirst += 1
          }
        }
      }

      /* For sum data */
      // var last = data.length + 3
      // worksheet.mergeCells('A' + last + ':B' + last)
      // worksheet.getCell('A' + last).value = 'Total'
      // for (let ti = 0; ti < this.columnsTotal.length; ti++) {
      //   const element = this.columnsTotal[ti]
      //   var targetLoc = columnLocation.findIndex(x => x.field === element.dataIndex)
      //   if (targetLoc >= 0) {
      //     worksheet.getCell(columnLocation[targetLoc].location + last).value = this.rowDataTotal[0][element.dataIndex]
      //   }
      //   // console.log(columnLocation[targetLoc].location + last, this.rowDataTotal[0][element.dataIndex])
      // }

      // write to a new buffer
      const buffer = await workbook.xlsx.writeBuffer()
      var blob = new Blob([buffer], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      })
      var link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = 'Data Master sekolah/student.xlsx'
      NProgress.done()
      link.click()
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      )
    },
    numberFormat(val) {
      return lou.curency(val)
    },
    lihatgambar(record) {
      this.$refs.zoomgambar.lihatgambar(record, 'photo_url', record.name)
    },
  },
}
</script>

<style scoped>
.highlight {
  background-color: rgb(255, 192, 105);
  padding: 0px;
}
</style>
