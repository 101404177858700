<template>
  <div>
    <a-card>
      <template slot="title">
        <strong class="mr-3">Data Produk Pembiayaan</strong>
        <!-- <a-range-picker class="ml-3" :format="'DD-MM-YYYY'" @change="rangedofunction"/> -->
        <!-- <a-button
          type="button"
          class="btn btn-outline-primary ml-2"
          @click="connector('nonanggota')"
        >
          Tambah Non Anggota
        </a-button> -->
        <a-button-group>
          <a-button
            type="button"
            class="btn btn-outline-primary"
            @click="showModal('create', {})"
          >
            Tambah
          </a-button>
          <a-button class="btn btn-outline-success" @click="getAllData(true)"
            >Refresh Data</a-button
          >
        </a-button-group>
        <a-button
          class="btn btn-outline-success float-right"
          @click="exportExcel"
          >Export Data</a-button
        >
      </template>
      <a-table
        :scroll="{ x: 2000 }"
        :columns="columns"
        :dataSource="data"
        style="margin: 0px"
        :pagination="{
          hideOnSinglePage: true,
          defaultPageSize: 10,
          showQuickJumper: true,
          showSizeChanger: true,
          showTotal: (total) => `Total ${total} items`,
          pageSizeOptions: ['10', '20', '30'],
        }"
        size="small"
      >
        <div
          slot="filterDropdown"
          slot-scope="{
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
            column,
          }"
          style="padding: 8px"
        >
          <a-input
            v-ant-ref="(c) => (searchInput = c)"
            :placeholder="`Search ${column.dataIndex}`"
            :value="selectedKeys[0]"
            style="width: 188px; margin-bottom: 8px; display: block"
            @change="
              (e) => setSelectedKeys(e.target.value ? [e.target.value] : [])
            "
            @pressEnter="
              () => handleSearch(selectedKeys, confirm, column.dataIndex)
            "
          />
          <a-button
            type="primary"
            icon="search"
            size="small"
            style="width: 90px; margin-right: 8px"
            @click="() => handleSearch(selectedKeys, confirm, column.dataIndex)"
          >
            Search
          </a-button>
          <a-button
            size="small"
            style="width: 90px"
            @click="() => handleReset(clearFilters)"
          >
            Reset
          </a-button>
        </div>
        <a-icon
          slot="filterIcon"
          slot-scope="filtered"
          type="search"
          :style="{ color: filtered ? '#108ee9' : undefined }"
        ></a-icon>
        <template slot="customRender" slot-scope="text, record, index, column">
          <span v-if="searchText && searchedColumn === column.dataIndex">
            <template
              v-for="(fragment, i) in text
                .toString()
                .split(new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i'))"
            >
              <mark
                v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                :key="i"
                class="highlight"
                >{{ fragment }}</mark
              >
              <template v-else>{{ fragment }}</template>
            </template>
          </span>
          <template v-else>
            {{ text }}
          </template>
        </template>
        <a slot="name" slot-scope="text" href="javascript:;">{{ text }}</a>
        <span slot="customTitle"><a-icon type="smile-o" /> Kode</span>
        <span slot="tags" slot-scope="tags">
          <a-tag v-for="tag in tags" color="blue" :key="tag">{{ tag }}</a-tag>
        </span>
        <span slot="action" slot-scope="text, record">
          <a href="javascript:void(0)" class="text-warning" @click="showModal('update', record)">
            <a-icon type="edit"></a-icon>
          </a>
          <a-divider type="vertical" />
          <a href="javascript:void(0)" class="text-danger" @click="showDeleteConfirm(record)">
            <a-icon type="delete"></a-icon>
          </a>
          <!-- <a href="javascript:;" class="ant-dropdown-link">
            More actions <a-icon type="down" />
          </a> -->
        </span>
        <span slot="numberFormat" slot-scope="text">
          {{ numberFormat(text) }}
        </span>
        <span slot="tgl" slot-scope="text, record">
          {{ formatDate(record.tgl) }}
        </span>
      </a-table>
    </a-card>
    <a-modal
      v-model="formvisible"
      title="Form Produk Pembiayaan"
      :width="800"
      :dialogStyle="{ top: '10px' }"
    >
      <template slot="footer">
        <a-button class="" type="danger" @click="handleCancel">Cancel</a-button>
        <a-button class="" type="primary" @click="handleSubmit"
          >Submit</a-button
        >
      </template>
      <div class="row">
        <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3">
          <label class="mt-1">Kode</label>
        </div>
        <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9">
          <a-input
            class=""
            v-model="modalInput.kode"
            placeholder="Kode"
          ></a-input>
        </div>
        <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
          <label class="mt-1">Keterangan</label>
        </div>
        <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
          <a-input
            class=""
            v-model="modalInput.keterangan"
            placeholder="Keterangan"
          ></a-input>
        </div>
        <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
          <label class="mt-1">Rekening</label>
        </div>
        <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
          <v-select
            label="label"
            v-model="modalInput.rekening"
            placeholder="Rekening"
            :options="masterRekening"
            :reduce="(tes) => tes.kode"
          />
        </div>
        <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
          <label class="mt-1">Rekening Margin</label>
        </div>
        <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
          <v-select
            label="label"
            v-model="modalInput.rekeningbunga"
            placeholder="Rekening Rekening Margin"
            :options="masterRekening"
            :reduce="(tes) => tes.kode"
          />
        </div>
        <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
          <label class="mt-1">Rekening Denda</label>
        </div>
        <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
          <v-select
            label="label"
            v-model="modalInput.rekeningdenda"
            placeholder="Rekening Rekening Denda"
            :options="masterRekening"
            :reduce="(tes) => tes.kode"
          />
        </div>
        <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
          <label class="mt-1">Rekening Provisi</label>
        </div>
        <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
          <v-select
            label="label"
            v-model="modalInput.rekeningprovisi"
            placeholder="Rekening Rekening Provisi"
            :options="masterRekening"
            :reduce="(tes) => tes.kode"
          />
        </div>
        <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
          <label class="mt-1">Rekening Administrasi</label>
        </div>
        <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
          <v-select
            label="label"
            v-model="modalInput.rekeningadministrasi"
            placeholder="Rekening Rekening Administrasi"
            :options="masterRekening"
            :reduce="(tes) => tes.kode"
          />
        </div>
        <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
          <label class="mt-1">Rekening Materai</label>
        </div>
        <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
          <v-select
            label="label"
            v-model="modalInput.rekeningmaterai"
            placeholder="Rekening Rekening Materai"
            :options="masterRekening"
            :reduce="(tes) => tes.kode"
          />
        </div>
        <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
          <label class="mt-1">Rekening Notaris</label>
        </div>
        <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
          <v-select
            label="label"
            v-model="modalInput.rekeningnotaris"
            placeholder="Rekening Rekening Notaris"
            :options="masterRekening"
            :reduce="(tes) => tes.kode"
          />
        </div>
        <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
          <label class="mt-1">Rekening Titipan</label>
        </div>
        <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
          <v-select
            label="label"
            v-model="modalInput.rekeningtitipan"
            placeholder="Rekening Rekening Titipan"
            :options="masterRekening"
            :reduce="(tes) => tes.kode"
          />
        </div>
        <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
          <label class="mt-1">Rekening Asuransi</label>
        </div>
        <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
          <v-select
            label="label"
            v-model="modalInput.rekeningasuransi"
            placeholder="Rekening Rekening Asuransi"
            :options="masterRekening"
            :reduce="(tes) => tes.kode"
          />
        </div>
        <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
          <label class="mt-1">Rekening Cadangan Margin</label>
        </div>
        <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
          <v-select
            label="label"
            v-model="modalInput.rekeningcadanganbunga"
            placeholder="Rekening Rekening Cadangan Margin"
            :options="masterRekening"
            :reduce="(tes) => tes.kode"
          />
        </div>
        <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3">
          <label class="mt-1">Rekening Pendapatan Provisi</label>
        </div>
        <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-2">
          <v-select
            label="label"
            v-model="modalInput.rekeningpendapatanprovisi"
            placeholder="Rekening Rekening Pendapatan Provisi"
            :options="masterRekening"
            :reduce="(tes) => tes.kode"
          />
        </div>
        <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
          <label class="mt-1">Rekening Persediaan</label>
        </div>
        <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
          <v-select
            label="label"
            v-model="modalInput.rekeningpersediaan"
            placeholder="Rekening Rekening Persediaan"
            :options="masterRekening"
            :reduce="(tes) => tes.kode"
          />
        </div>
        <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
          <label class="mt-1">Rekening Wakalah</label>
        </div>
        <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
          <v-select
            label="label"
            v-model="modalInput.rekeningwakalah"
            placeholder="Rekening Rekening Wakalah"
            :options="masterRekening"
            :reduce="(tes) => tes.kode"
          />
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
/* Import Script */
import { Modal } from 'ant-design-vue'
import * as lou from '@/services/data/lou'
import moment from 'moment'
import NProgress from 'nprogress'
// import Cleave from 'vue-cleave-component'
// import { mapState } from 'vuex'
/* End Script Import Script */

/* Kolom atau header dari table */
const columns = [
  {
    title: 'Action',
    scopedSlots: { customRender: 'action' },
    fixed: 'left',
    width: 70,
  },
  {
    title: 'Kode',
    dataIndex: 'kode',
    width: 70,
    // fixed: 'left',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
    onFilter: (value, record) =>
      record.kode.toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => {
          this.searchInput.focus()
        }, 0)
      }
    },
  },
  {
    title: 'Keterangan',
    dataIndex: 'keterangan',
    width: 160,
    // fixed: 'left',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
    onFilter: (value, record) =>
      record.keterangan.toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => {
          this.searchInput.focus()
        }, 0)
      }
    },
  },
  {
    title: 'Rekening',
    dataIndex: 'rekening',
    width: 100,
    // fixed: 'left',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
    onFilter: (value, record) =>
      record.rekening
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => {
          this.searchInput.focus()
        }, 0)
      }
    },
  },
  {
    title: 'Rekening Margin',
    dataIndex: 'rekeningbunga',
    width: 100,
    // fixed: 'left',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
    onFilter: (value, record) =>
      record.rekeningbunga
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => {
          this.searchInput.focus()
        }, 0)
      }
    },
  },
  {
    title: 'Rekening Denda',
    dataIndex: 'rekeningdenda',
    width: 100,
    // fixed: 'left',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
    onFilter: (value, record) =>
      record.rekeningdenda
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => {
          this.searchInput.focus()
        }, 0)
      }
    },
  },
  {
    title: 'Rekening Provisi',
    dataIndex: 'rekeningprovisi',
    width: 100,
    // fixed: 'left',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
    onFilter: (value, record) =>
      record.rekeningprovisi
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => {
          this.searchInput.focus()
        }, 0)
      }
    },
  },
  {
    title: 'Rekening Administrasi',
    dataIndex: 'rekeningadministrasi',
    width: 100,
    // fixed: 'left',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
    onFilter: (value, record) =>
      record.rekeningadministrasi
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => {
          this.searchInput.focus()
        }, 0)
      }
    },
  },
  {
    title: 'Rekening Materai',
    dataIndex: 'rekeningmaterai',
    width: 100,
    // fixed: 'left',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
    onFilter: (value, record) =>
      record.rekeningmaterai
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => {
          this.searchInput.focus()
        }, 0)
      }
    },
  },
  {
    title: 'Rekening Notaris',
    dataIndex: 'rekeningnotaris',
    width: 100,
    // fixed: 'left',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
    onFilter: (value, record) =>
      record.rekeningnotaris
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => {
          this.searchInput.focus()
        }, 0)
      }
    },
  },
  {
    title: 'Rekening Titipan',
    dataIndex: 'rekeningtitipan',
    width: 100,
    // fixed: 'left',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
    onFilter: (value, record) =>
      record.rekeningtitipan
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => {
          this.searchInput.focus()
        }, 0)
      }
    },
  },
  {
    title: 'Rekening Asuransi',
    dataIndex: 'rekeningasuransi',
    width: 100,
    // fixed: 'left',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
    onFilter: (value, record) =>
      record.rekeningasuransi
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => {
          this.searchInput.focus()
        }, 0)
      }
    },
  },
  {
    title: 'Rekening Cadangan Margin',
    dataIndex: 'rekeningcadanganbunga',
    width: 100,
    // fixed: 'left',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
    onFilter: (value, record) =>
      record.rekeningcadanganbunga
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => {
          this.searchInput.focus()
        }, 0)
      }
    },
  },
  {
    title: 'Rekening Pendapatan Provisi',
    dataIndex: 'rekeningpendapatanprovisi',
    width: 100,
    // fixed: 'left',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
    onFilter: (value, record) =>
      record.rekeningpendapatanprovisi
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => {
          this.searchInput.focus()
        }, 0)
      }
    },
  },
  {
    title: 'Rekening Persediaan',
    dataIndex: 'rekeningpersediaan',
    width: 100,
    // fixed: 'left',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
    onFilter: (value, record) =>
      record.rekeningpersediaan
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => {
          this.searchInput.focus()
        }, 0)
      }
    },
  },
  {
    title: 'Rekening Wakalah',
    dataIndex: 'rekeningwakalah',
    width: 100,
    // fixed: 'left',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
    onFilter: (value, record) =>
      record.rekeningwakalah
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => {
          this.searchInput.focus()
        }, 0)
      }
    },
  },
]
/* End script Kolom atau header dari table */

/* Data dari table */
const data = []
/* End script Data dari table */

export default {
  components: {
    // cleave: Cleave,
  },
  computed: {
    // ...mapState(['settings', 'user']),
  },
  created() {
    this.getAllData()
  },
  /* Data variable vue */
  data() {
    return {
      searchText: '',
      tgl1: '',
      tgl2: '',
      searchInput: null,
      searchedColumn: '',
      name: 'Tabletabungan',
      visible: false,
      allMaster: [],
      masterstatuskawin: [],
      masterkelamin: [],
      data,
      columns,
      datenow: '',
      componentKey: 0,

      // Modal property
      masterRekening: [],
      action: null,
      formvisible: false,
      editdata: {},
      modalInput: {
        kode: '',
        keterangan: '',
        rekening: '',
        rekeningbunga: '',
        rekeningdenda: '',
        rekeningprovisi: '',
        rekeningadministrasi: '',
        rekeningmaterai: '',
        rekeningnotaris: '',
        rekeningtitipan: '',
        rekeningasuransi: '',
        rekeningcadanganbunga: '',
        rekeningpendapatanprovisi: '',
        rekeningpersediaan: '',
        rekeningwakalah: '',
      },
    }
  },
  /* End Data variable vue */
  mounted: function () {
    const date = moment().format('YYYY-MM-DD') // Tanggal hari ini format 2020-12-31
    // localStorage.removeItem('newkoperasiedittabungandata')
    this.tgl1 = date
    this.tgl2 = date
    this.datenow = date.toString()
    // this.getAgamaF()
  },
  methods: {
    moment,
    async showModal(action, data) {
      this.masterRekening = []
      var res = await lou.customUrlGet2('general/master/rekening')
      if (res) {
        res.data.forEach((x) => {
          var np = {
            ...x,
            label: x.kode + ' - ' + x.keterangan,
          }
          this.masterRekening.push(np)
        })
        this.formvisible = true
        this.action = action
        // console.log('this.masterRekening', this.masterRekening)
        if (action === 'update') {
          this.modalInput = this.$g.clone(data)
          this.editdata = data
        }
      } else {
        // lou.shownotif('Api!', 'Pastikan i')
      }
    },
    async getAllData(onclick = false) {
      var response = await lou.customUrlGet2('master/produkpembiayaan')
      if (response) {
        this.data = response.data
        if (onclick) {
          lou.shownotif('Refresh', 'Data telah di refresh.')
        }
      }
      // console.log('this.data', this.data)
    },
    formatDate(value) {
      return moment(value).format('DD-MM-YYYY')
    },
    showDeleteConfirm(deldata) {
      Modal.confirm({
        title: 'Are you sure delete this data?',
        content: "You can't prevent this!!! ",
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'No',
        onOk: async () => {
          var response = await lou.customUrlDelete2(
            'master/produkpembiayaan/' + deldata.id,
          )
          if (response) {
            this.getAllData()
          }
        },
        onCancel: () => {
          // console.log('Cancel')
        },
      })
    },
    handleSearch(selectedKeys, confirm, dataIndex) {
      confirm()
      this.searchText = selectedKeys[0]
      this.searchedColumn = dataIndex
    },

    handleReset(clearFilters) {
      clearFilters()
      this.searchText = ''
    },

    // Modal function
    async handleSubmit() {
      var fd = this.$g.clone(this.modalInput)
      var res = false
      if (this.action === 'update') {
        res = await lou.customUrlPut2('master/produkpembiayaan', fd)
      } else {
        res = await lou.customUrlPost2('master/produkpembiayaan', fd)
      }
      if (res) {
        this.getAllData()
        this.handleCancel()
      }
    },
    handleCancel() {
      this.formvisible = false
      this.action = 'create'
      this.masterRekening = []
      this.modalInput = {
        kode: '',
        keterangan: '',
        rekening: '',
        rekeningbunga: '',
        rekeningdenda: '',
        rekeningprovisi: '',
        rekeningadministrasi: '',
        rekeningmaterai: '',
        rekeningnotaris: '',
        rekeningtitipan: '',
        rekeningasuransi: '',
        rekeningcadanganbunga: '',
        rekeningpendapatanprovisi: '',
        rekeningpersediaan: '',
        rekeningwakalah: '',
      }
    },
    async exportExcel() {
      NProgress.start()
      const ExcelJS = require('exceljs')
      const workbook = new ExcelJS.Workbook()
      workbook.creator = 'LB-BPRS Heasoft System'
      workbook.lastModifiedBy = 'Me'
      workbook.created = new Date()
      workbook.modified = new Date()
      workbook.lastPrinted = new Date()
      var alphabeth = [
        'A',
        'B',
        'C',
        'D',
        'E',
        'F',
        'G',
        'H',
        'I',
        'J',
        'K',
        'L',
        'M',
        'N',
        'O',
        'P',
        'Q',
        'R',
        'S',
        'T',
        'U',
        'V',
        'W',
        'X',
        'Y',
        'Z',
      ]
      const worksheet = workbook.addWorksheet(moment().format('DD-MM-YYYY'))

      // Table Data
      var column = this.$g.clone(this.columns)
      var tdata = this.$g.clone(this.data)
      var data = []
      tdata.forEach((element) => {
        var retv = {
          ...element,
          tgl:
            element.tgl === null
              ? ''
              : moment(element.tgl, 'YYYY-MM-DD').format('DD-MM-YYYY'),
        }
        data.push(retv)
      })
      var cfirst = 0
      var cloop = 0
      var dincrement = 2
      var columnLocation = []
      for (let i = 0; i < column.length; i++) {
        const element = column[i]
        var cloopni = cloop - 1
        var hname = element.title.toString()
        if (hname !== 'Action') {
          if (cloop === 0) {
            columnLocation.push({
              location: alphabeth[cfirst],
              field: element.dataIndex,
            })
            worksheet.getCell(alphabeth[cfirst] + '1').value = hname.replaceAll(
              ' ',
              '',
            )
          } else {
            columnLocation.push({
              location: alphabeth[cloopni] + alphabeth[cfirst],
              field: element.dataIndex,
            })
            worksheet.getCell(
              alphabeth[cloopni] + alphabeth[cfirst] + '1',
            ).value = hname.replaceAll(' ', '')
          }
          for (let u = 0; u < data.length; u++) {
            const el = data[u]
            if (cloop === 0) {
              worksheet.getCell(alphabeth[cfirst] + dincrement).value =
                el[element.dataIndex]
            } else {
              worksheet.getCell(
                alphabeth[cloopni] + alphabeth[cfirst] + dincrement,
              ).value = el[element.dataIndex]
            }
            var checklast = u + 1
            if (checklast === data.length) {
              dincrement = 2
            } else {
              dincrement += 1
            }
          }
          if (cfirst === 25) {
            cfirst = 0
            cloop += 1
          } else {
            cfirst += 1
          }
        }
      }

      /* For sum data */
      // var last = data.length + 3
      // worksheet.mergeCells('A' + last + ':B' + last)
      // worksheet.getCell('A' + last).value = 'Total'
      // for (let ti = 0; ti < this.columnsTotal.length; ti++) {
      //   const element = this.columnsTotal[ti]
      //   var targetLoc = columnLocation.findIndex(x => x.field === element.dataIndex)
      //   if (targetLoc >= 0) {
      //     worksheet.getCell(columnLocation[targetLoc].location + last).value = this.rowDataTotal[0][element.dataIndex]
      //   }
      //   // console.log(columnLocation[targetLoc].location + last, this.rowDataTotal[0][element.dataIndex])
      // }

      // write to a new buffer
      const buffer = await workbook.xlsx.writeBuffer()
      var blob = new Blob([buffer], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      })
      var link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = 'Data Master master/produkpembiayaan.xlsx'
      NProgress.done()
      link.click()
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      )
    },
    numberFormat(val) {
      return lou.curency(val)
    },
  },
}
</script>

<style scoped>
.highlight {
  background-color: rgb(255, 192, 105);
  padding: 0px;
}
</style>
