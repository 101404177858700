<template>
  <div>
    <a-modal
      v-model="visible"
      :title="form.to.length === 0 ? 'Broadcast ke semua' : 'Kirim ke ' + form.to.length + ' penerima'"
      :dialogStyle="{ top: '10px' }"
      :destroyOnClose="true"
    >
      <template slot="footer">
        <a-button class="btn btn-outline-dark" @click="handleCancel">
          Batal
        </a-button>
        <a-button
          class="btn btn-outline-primary"
          :loading="loading"
          @click="submit('ruleForm')"
        >
          Kirim
        </a-button>
      </template>
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
          <a-form-model
            ref="ruleForm"
            :model="form"
            :rules="rules"
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
          >
            <a-form-model-item ref="title" label="Title" prop="title">
              <a-input
                v-model="form.title"
                @blur="
                  () => {
                    $refs.title.onFieldBlur();
                  }
                "
              />
            </a-form-model-item>
            <a-form-model-item label="Link Image" prop="image">
              <a-input v-model="form.image" />
            </a-form-model-item>
            <a-form-model-item has-feedback label="Content" prop="body">
              <a-textarea
                placeholder="Masukan content disini"
                v-model="form.body"
                allow-clear
              />
            </a-form-model-item>
          </a-form-model>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import * as lou from '@/services/data/lou'
export default {
  data() {
    return {
      labelCol: { span: 4 },
      wrapperCol: { span: 20 },
      form: {
        title: '',
        body: '',
        image: '',
        to: [],
      },
      rules: {
        title: [
          {
            required: true,
            message: 'Please input title',
            trigger: 'blur',
          },
          {
            min: 5,
            message: 'Minimun title must be 5',
            trigger: 'blur',
          },
        ],
        // body: [{ required: false, message: 'Please input username!' }],
      },
    }
  },
  methods: {
    async showModal(data = []) {
      this.form.title = ''
      this.form.body = ''
      this.form.image = ''
      this.form.to = data
      this.visible = true
    },
    handleCancel() {
      this.visible = false
    },
    submit(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          var fd = {
            title: this.form.title,
            body: this.form.body,
            image: this.form.image,
            to: this.form.to,
          }
          await lou.urlTransaksi('/geo/absen/notification/broadcast', fd)
          this.visible = false
          this.resetForm(formName)
          this.$parent.form.to = []
        } else {
          // console.log('error submit!!')
          return false
        }
      })
    },
    resetForm(formName) {
      this.$refs[formName].resetFields()
    },
  },
}
</script>

<style></style>
