<template>
  <div>
    <a-card>
      <template slot="title">
        <strong>Register Nasabah</strong>
        <!-- <a-range-picker class="ml-3" :format="'DD-MM-YYYY'" @change="rangedofunction" :allowClear="false"/> -->
        <!-- <a-button
          type="button"
          class="btn btn-outline-primary ml-4"
          @click="connector('nonanggota')"
        >
          Tambah Non Anggota
        </a-button> -->
        <a-button-group class="ml-2">
          <a-button icon="plus-circle" class="text-primary" type=""
          @click="connector('anggota')">
            Tambah
          </a-button>
          <a-tooltip placement="top">
            <template slot="title">
              <span>Refresh Data</span>
            </template>
            <a-button @click="getAllData(true)">
              <a-icon type="sync" :spin="loading" class="text-success"/>
            </a-button>
          </a-tooltip>
            <a-dropdown>
              <a-button
                class="text-success"
                :loading="importBtnLoading"
                ><a-icon type="file-excel" class="text-success"/></a-button
              >
              <a-menu slot="overlay">
                <a-menu-item key="1" class="text-success" @click="exportExcel">
                  <a-icon type="file-excel" />Export Excel
                </a-menu-item>
              </a-menu>
            </a-dropdown>
            <input
              type="file"
              ref="excelFileUpload"
              style="display: none;"
              accept="application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              @input="getSheetExcel"
            />
          <!-- <a-tooltip placement="top">
            <template slot="title">
              <span>Export Data</span>
            </template>
            <a-button @click="exportExcel">
              <a-icon type="file-excel" class="text-success"/>
            </a-button>
          </a-tooltip> -->
        </a-button-group>
      </template>
      <a-table
        :scroll="{ x: 2000 }"
        :columns="columns"
        :dataSource="data"
        style="padding-top: -25px;"
        :pagination="{
          hideOnSinglePage:true,
          defaultPageSize: 10,
          showQuickJumper: true,
          showSizeChanger: true,
          showTotal: (total) => `Total ${total} items`,
          pageSizeOptions: ['10', '20', '30'],
        }"
        size="small"
      >
        <div
          slot="filterDropdown"
          slot-scope="{
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
            column,
          }"
          style="padding: 8px;"
        >
          <a-input
            v-ant-ref="(c) => (searchInput = c)"
            :placeholder="`Search ${column.dataIndex}`"
            :value="selectedKeys[0]"
            style="width: 188px; margin-bottom: 8px; display: block;"
            @change="
              (e) => setSelectedKeys(e.target.value ? [e.target.value] : [])
            "
            @pressEnter="
              () => handleSearch(selectedKeys, confirm, column.dataIndex)
            "
          />
          <a-button
            type="primary"
            icon="search"
            size="small"
            style="width: 90px; margin-right: 8px;"
            @click="
              () => handleSearch(selectedKeys, confirm, column.dataIndex)
            "
          >
            Search
          </a-button>
          <a-button
            size="small"
            style="width: 90px;"
            @click="() => handleReset(clearFilters)"
          >
            Reset
          </a-button>
        </div>
        <a-icon
          slot="filterIcon"
          slot-scope="filtered"
          type="search"
          :style="{ color: filtered ? '#108ee9' : undefined }"
        ></a-icon>
        <template
          slot="customRender"
          slot-scope="text, record, index, column"
        >
          <span v-if="searchText && searchedColumn === column.dataIndex">
            <template
              v-for="(fragment, i) in text
                .toString()
                .split(
                  new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i')
                )"
            >
              <mark
                v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                :key="i"
                class="highlight"
                >{{ fragment }}</mark
              >
              <template v-else>{{ fragment }}</template>
            </template>
          </span>
          <template v-else>
            {{ text }}
          </template>
        </template>
        <a slot="name" slot-scope="text" href="javascript:;">{{ text }}</a>
        <span slot="customTitle"><a-icon type="smile-o" /> Kode</span>
        <span slot="tags" slot-scope="tags">
          <a-tag v-for="tag in tags" color="blue" :key="tag">{{ tag }}</a-tag>
        </span>
        <span slot="action" slot-scope="text, record">
          <!-- <a-dropdown>
            <a-menu slot="overlay">
              <a-menu-item key="1" @click="registerMobileBanking(record.id)"> <a-icon type="user" />Register Mobile Banking</a-menu-item>
              <a-menu-item key="2" @click="phoneNumberMobileBankingModal(record.id, 'open')"> <a-icon type="number" />Change Phone Number</a-menu-item>
              <a-menu-item key="3" @click="resetMobileBanking(record.id)"> <a-icon type="reload" />Reset Phone Number</a-menu-item>
            </a-menu>
            <a class="text-primary">
              <a-icon type="menu"></a-icon>
            </a>
          </a-dropdown> -->
          <!-- <a-button style="margin-left: 8px"> Button <a-icon type="down" /> </a-button> -->
          <!-- <a-divider type="vertical" /> -->
          <a class="text-warning" @click="sendToEdit(record)">
            <a-icon type="edit"></a-icon>
          </a>
          <a-divider type="vertical" />
          <a href="javascript:void(0)" class="text-danger" @click="showDeleteConfirm(record)">
            <a-icon type="delete"></a-icon>
          </a>
          <!-- <a href="javascript:;" class="ant-dropdown-link">
            More actions <a-icon type="down" />
          </a> -->
        </span>
        <span slot="pekerjaan" slot-scope="text, record">
          {{ findRelationData(record, 'masterPekerjaan', 'kode', 'pekerjaan', 'keterangan') }}
        </span>
        <span slot="kodya" slot-scope="text, record">
          {{ findRelationData(record, 'masterKota', 'kode', 'kodya', 'keterangan') }}
        </span>
        <span slot="kecamatan" slot-scope="text, record">
          {{ findRelationData(record, 'masterKecamatan', 'kode', 'kecamatan', 'keterangan') }}
        </span>
        <span slot="kelurahan" slot-scope="text, record">
          {{ findRelationData(record, 'masterKelurahan', 'kode', 'kelurahan', 'keterangan') }}
        </span>
        <span slot="agama" slot-scope="text, record">
          <!-- {{ text }} -->
          {{ findRelationData(record, 'masterAgama', 'kode', 'agama', 'keterangan') }}
        </span>
        <span slot="statusperkawinan" slot-scope="text, record">
          {{ findRelationData(record, 'masterstatuskawin', 'kode', 'statusperkawinan', 'keterangan') }}
        </span>
        <span slot="kelamin" slot-scope="text, record">
          {{ findRelationData(record, 'masterkelamin', 'kode', 'kelamin', 'keterangan') }}
        </span>
        <span slot="tgl" slot-scope="text, record">
          {{ formatDate(record.tgl) }}
        </span>
        <span slot="tgllahir" slot-scope="text, record">
          {{ formatDate(record.tgllahir) }}
        </span>
      </a-table>
    </a-card>

      <a-modal
        title="Preview Import Excel"
        v-model="excelModalVisible"
        :width="800"
        :dialogStyle="{ top: '0px' }"
      >
        <template slot="footer">
          <a-button class="btn btn-outline-dark" @click="excelModalCancel"
            >Cancel</a-button
          >
          <a-button class="btn btn-outline-primary" @click="proccessDataExcel"
            >Process</a-button
          >
        </template>
        <div class="row">
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            <a-select
              show-search
              option-filter-prop="children"
              :filter-option="filterOption"
              style="width: 50%;"
              placeholder="Pilih sheet"
              @change="mselectSheet"
            >
              <a-select-option
                v-for="(data, index) in sheetColection"
                :key="index"
                :value="data"
                >{{ data }}</a-select-option
              >
            </a-select>
          </div>
          <ag-grid-vue
            :key="componentKey"
            style="height: 50vh; width: 100%;"
            :gridOptions="gridOptionsModalExcel"
            :class="
              theme === 'dark'
                ? 'ag-theme-balham-dark mt-2'
                : 'ag-theme-balham mt-2'
            "
            :columnDefs="excelModal.column"
            :rowData="excelModal.datatable"
          ></ag-grid-vue>
        </div>
      </a-modal>
      <a-modal title="Change Mobile Banking Phone Number" v-model="phoneNumberMobileBankingVisibleModal">
        <template slot="footer">
          <a-button class="btn btn-outline-dark" @click="phoneNumberMobileBankingModal(0, 'close')"
            >Cancel</a-button
          >
          <a-button class="btn btn-outline-primary" @click="changeNumberMobileBanking"
            >Process</a-button
          >
        </template>
          <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <a-input v-model="phoneNumberMobileBankingVisibleModalInput.hp" placeholder="Masukan Nomor HP Baru" ref="phoneNumberMobileBankingVisibleModalInputHP"></a-input>
            </div>
          </div>
      </a-modal>
      <a-modal title="Security Code" v-model="previewResponseSecCode.visible" :footer="null">
        <!-- <template slot="footer">
          <a-button class="btn btn-outline-dark" @click="phoneNumberMobileBankingModal(0, 'close')"
            >Cancel</a-button
          >
          <a-button class="btn btn-outline-primary" @click="changeNumberMobileBanking"
            >Process</a-button
          >
        </template> -->
        <div class="row">
          <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3">No HP: </div>
          <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9">{{ previewResponseSecCode.hp }}</div>
          <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3">Security Code: </div>
          <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9"><strong>{{ previewResponseSecCode.security_code }}</strong></div>
        </div>
      </a-modal>
  </div>
</template>

<script>
/* Import Script */
import { Modal } from 'ant-design-vue'
import * as lou from '@/services/data/lou'
import moment from 'moment'
import { mapState } from 'vuex'
/* End Script Import Script */

/* Kolom atau header dari table */
const columns = [
  {
    title: 'Action',
    scopedSlots: { customRender: 'action' },
    width: 70,
    fixed: 'left',
  },
  {
    title: 'Tanggal',
    width: 100,
    dataIndex: 'tgl',
    scopedSlots: { customRender: 'tgl' },
    // width: 100,
  },
  {
    title: 'Kode',
    dataIndex: 'kode',
    width: 100,
    // fixed: 'left',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
    onFilter: (value, record) =>
      record.kode.toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => {
          this.searchInput.focus()
        }, 0)
      }
    },
  },
  {
    title: 'Nama',
    dataIndex: 'nama',
    width: 150,
    // fixed: 'left',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
    onFilter: (value, record) =>
      record.nama.toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => {
          this.searchInput.focus()
        }, 0)
      }
    },
  },
  {
    title: 'Alamat',
    width: 250,
    dataIndex: 'alamat',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
    onFilter: (value, record) =>
      record.alamat.toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => {
          this.searchInput.focus()
        }, 0)
      }
    },
    // width: 100,
  },
  {
    title: 'Kelamin',
    width: 100,
    dataIndex: 'kelamin',
    scopedSlots: { customRender: 'kelamin' },
    // width: 100,
  },
  {
    title: 'Tgl Lahir',
    width: 100,
    dataIndex: 'tgllahir',
    scopedSlots: { customRender: 'tgllahir' },
    // width: 100,
  },
  {
    title: 'Tempat Lahir',
    width: 100,
    dataIndex: 'tempatlahir',
    scopedSlots: { customRender: 'tempatlahir' },
    // width: 100,
  },
  {
    title: 'Status',
    width: 80,
    dataIndex: 'statusperkawinan',
    scopedSlots: { customRender: 'statusperkawinan' },
    // width: 100,
  },
  {
    title: 'No KTP',
    width: 150,
    dataIndex: 'ktp',
    scopedSlots: { customRender: 'ktp' },
    // width: 100,
  },
  {
    title: 'Agama',
    width: 100,
    dataIndex: 'agama',
    scopedSlots: {
      customRender: 'agama',
    },
    // width: 100,
  },
  {
    title: 'Pekerjaan',
    width: 100,
    dataIndex: 'pekerjaan',
    scopedSlots: { customRender: 'pekerjaan' },
    // width: 100,
  },
  {
    title: 'KodePos',
    width: 100,
    dataIndex: 'kodepos',
    scopedSlots: { customRender: 'kodepos' },
    // width: 100,
  },
  {
    title: 'Telepon',
    width: 130,
    dataIndex: 'telepon',
    scopedSlots: { customRender: 'telepon' },
    // width: 100,
  },
  {
    title: 'Kodya',
    width: 100,
    dataIndex: 'kodya',
    scopedSlots: { customRender: 'kodya' },
    // width: 100,
  },
  {
    title: 'Kecamatan',
    width: 100,
    dataIndex: 'kecamatan',
    scopedSlots: { customRender: 'kecamatan' },
    // width: 100,
  },
  {
    title: 'Kelurahan',
    width: 100,
    dataIndex: 'kelurahan',
    scopedSlots: { customRender: 'kelurahan' },
    // width: 100,
  },
  {
    title: 'NPWP',
    width: 150,
    dataIndex: 'npwp',
    scopedSlots: { customRender: 'npwp' },
    // width: 100,
  },
  {
    title: 'Ibu Kandung',
    width: 120,
    dataIndex: 'ibukandung',
    scopedSlots: { customRender: 'ibukandung' },
    // width: 100,
  },
]
/* End script Kolom atau header dari table */

/* Data dari table */
const data = []
/* End script Data dari table */

export default {
  computed: {
    ...mapState(['settings', 'user']),
  },
  /* Data variable vue */
  data() {
    return {
      theme: this.$store.state.settings.theme,
      searchText: '',
      tgl1: '',
      tgl2: '',
      searchInput: null,
      searchedColumn: '',
      name: 'TableRegisterNasabah',
      visible: false,
      statusperkawinan: {
        K: 'Kawin',
        B: 'Belum Kawin',
        D: 'Duda',
        J: 'Janda',
      },
      jenisidentitas: {
        1: 'KTP',
        2: 'SIM',
        3: 'Pasport',
      },
      yatidak: {
        0: 'Tidak',
        1: 'Ya',
      },
      perorangan: {
        1: 'Perorangan',
        2: 'Non-Perorangan',
      },
      allMaster: {},
      masterstatuskawin: [],
      masterkelamin: [],
      masterPekerjaan: [],
      masterKota: [],
      masterKecamatan: [],
      masterKelurahan: [],
      data,
      columns,
      datenow: '',

      // Phone Number Mobile Banking Property
      phoneNumberMobileBankingVisibleModal: false,
      phoneNumberMobileBankingVisibleModalInput: {
        register_id: 0,
        hp: '',
      },

      // Preview Security Code Property
      previewResponseSecCode: {
        visible: false,
        hp: '',
        security_code: '',
      },
      loading: false,

      // Import Excel Property
      gridOptionsModalExcel: null,
      sheetColection: [],
      importBtnLoading: false,
      excelModalVisible: false,
      excelMode: 'normal',
      excelModal: {
        column: [
          {
            headerName: 'kode',
            field: 'kode',
            filter: true,
            resizable: true,
            editable: true,
          },
          {
            headerName: 'tgl',
            field: 'tgl',
            filter: true,
            resizable: true,
            editable: true,
          },
          {
            headerName: 'nama',
            field: 'nama',
            filter: true,
            resizable: true,
            editable: true,
          },
          {
            headerName: 'kelamin',
            field: 'kelamin',
            filter: true,
            resizable: true,
            editable: true,
          },
          {
            headerName: 'tgllahir',
            field: 'tgllahir',
            filter: true,
            resizable: true,
            editable: true,
          },
          {
            headerName: 'tempatlahir',
            field: 'tempatlahir',
            filter: true,
            resizable: true,
            editable: true,
          },
          {
            headerName: 'statusperkawinan',
            field: 'statusperkawinan',
            filter: true,
            resizable: true,
            editable: true,
          },
          {
            headerName: 'No KTP',
            field: 'ktp',
            filter: true,
            resizable: true,
            editable: true,
          },
          {
            headerName: 'agama',
            field: 'agama',
            filter: true,
            resizable: true,
            editable: true,
          },
          {
            headerName: 'pekerjaan',
            field: 'pekerjaan',
            filter: true,
            resizable: true,
            editable: true,
          },
          {
            headerName: 'alamat',
            field: 'alamat',
            filter: true,
            resizable: true,
            editable: true,
          },
          {
            headerName: 'kodepos',
            field: 'kodepos',
            filter: true,
            resizable: true,
            editable: true,
          },
          {
            headerName: 'hp',
            field: 'hp',
            filter: true,
            resizable: true,
            editable: true,
          },
          {
            headerName: 'kelurahan',
            field: 'kelurahan',
            filter: true,
            resizable: true,
            editable: true,
          },
          {
            headerName: 'npwp',
            field: 'npwp',
            filter: true,
            resizable: true,
            editable: true,
          },
          {
            headerName: 'ibukandung',
            field: 'ibukandung',
            filter: true,
            resizable: true,
            editable: true,
          },
          {
            headerName: 'simpanan_pokok',
            field: 'simpanan_pokok',
            filter: true,
            resizable: true,
            editable: true,
          },
          {
            headerName: 'simpanan_wajib',
            field: 'simpanan_wajib',
            filter: true,
            resizable: true,
            editable: true,
          },
          {
            headerName: 'anggota',
            field: 'anggota',
            filter: true,
            resizable: true,
            editable: true,
          },
          {
            headerName: 'instansi_id',
            field: 'instansi_id',
            filter: true,
            resizable: true,
            editable: true,
          },
        ],
        datatable: [],
      },
      failInsertManyData: [],
      excelErrorExist: false,
      input: {
        undoInput: '',
        redoInput: '',
        undoInputdisable: true,
        redoInputdisable: true,
      },
      rowAfterFilter: [],
      loadingImportExcel: false,
      componentKey: 0,
    }
  },
  /* End Data variable vue */
  mounted: function () {
    this.gridOptionsModalExcel = {}
    const date = moment().format('YYYY-MM-DD') // Tanggal hari ini format 2020-12-31
    localStorage.removeItem('koperasisyariaheditregisternasabahdata')
    this.tgl1 = date
    this.tgl2 = date
    this.datenow = date.toString()
    this.getAllData()
    // this.getAgamaF()
  },
  methods: {
    moment,
    async resetMobileBanking(id) {
      var fd = { register_id: id }
      var res = await lou.customUrlPost2('transaksi/registrasi/anggota/mobile_reset', fd)
      if (res) {
        this.previewResponseSecCode.visible = true
        this.previewResponseSecCode.hp = res.data.hp
        this.previewResponseSecCode.security_code = res.data.security_code
      }
    },
    async registerMobileBanking(id) {
      var fd = { register_id: id }
      var res = await lou.customUrlPost2('transaksi/registrasi/anggota/mobile_register', fd)
      if (res) {
        this.previewResponseSecCode.visible = true
        this.previewResponseSecCode.hp = res.data.hp
        this.previewResponseSecCode.security_code = res.data.security_code
      }
    },
    phoneNumberMobileBankingModal(id, action) {
      if (action === 'open') {
        this.phoneNumberMobileBankingVisibleModalInput.register_id = id
        this.phoneNumberMobileBankingVisibleModal = true
        this.$refs.phoneNumberMobileBankingVisibleModalInputHP.$el.focus()
      } else {
        this.phoneNumberMobileBankingVisibleModalInput.register_id = 0
        this.phoneNumberMobileBankingVisibleModal = false
      }
    },
    async changeNumberMobileBanking(id) {
      var fd = this.phoneNumberMobileBankingVisibleModalInput
      var res = await lou.customUrlPost2('transaksi/registrasi/anggota/mobile_change', fd)
      if (res) {
        this.phoneNumberMobileBankingVisibleModal = false
        this.previewResponseSecCode.visible = true
        this.previewResponseSecCode.hp = res.data.hp
        this.previewResponseSecCode.security_code = res.data.security_code
      }
    },
    connector(keanggotaan) {
      // console.log('keanggotaan', keanggotaan)
      localStorage.setItem('koperasisyariaheditregisternasabahdata', JSON.stringify({ id: '' }))
      this.$router.push('/koperasisyariah/registernasabah/form' + keanggotaan)
    },
    rangedofunction(date, dateString) {
      this.tgl1 = moment(dateString[0], 'DD-MM-YYYY').format('YYYY-MM-DD')
      this.tgl2 = moment(dateString[1], 'DD-MM-YYYY').format('YYYY-MM-DD')
      // console.log('date', date)
      // console.log('dateString', dateString)
      // var res = this.
      // this.getAllData()
    },
    async getAllData(onclick = false) {
      // var allmaster = {}
      // this.allMaster = allmaster
      this.masterstatuskawin = [
        {
          kode: 'K',
          keterangan: 'Kawin',
        },
        {
          kode: 'B',
          keterangan: 'Belum Kawin',
        },
        {
          kode: 'J',
          keterangan: 'Janda',
        },
        {
          kode: 'D',
          keterangan: 'Duda',
        },
      ]
      this.masterkelamin = [
        {
          kode: 'P',
          keterangan: 'Perempuan',
        },
        {
          kode: 'L',
          keterangan: 'Laki-Laki',
        },
      ]
      // console.log('this.allMaster', this.allMaster)
      this.getMasterKota()
      this.getMasterKecamatan()
      this.getMasterKelurahan()
      var responsePekerjaan = await lou.customUrlGet2('general/master/pekerjaan')
      if (responsePekerjaan) {
        // console.log('responsePekerjaan', responsePekerjaan)
        this.masterPekerjaan = this.$g.clone(responsePekerjaan.data)
        this.backupmasterPekerjaan = this.$g.clone(responsePekerjaan.data)
        // console.log('this.backupmasterKota', this.backupmasterKota)
      }
      var responseAgama = await lou.customUrlGet2('general/master/agama')
      if (responseAgama) {
        // console.log('responseAgama', responseAgama)
        this.masterAgama = this.$g.clone(responseAgama.data)
        this.backupmasterAgama = this.$g.clone(responseAgama.data)
        // console.log('this.backupmasterKota', this.backupmasterKota)
      }
      this.loading = true
      var response = await lou.customUrlGet2('transaksi/registrasi/anggota')
      if (response) {
        this.data = response.data
        this.loading = false
        if (onclick) {
          lou.shownotif('Refresh', 'Data telah di refresh.')
        }
      } else {
        this.loading = false
      }
      // console.log('this.data', this.data)
    },
    async getMasterKota() {
      var response = await lou.customUrlGet2('transaksi/registrasi/anggota/kodya')
      if (response) {
        this.masterKota = this.$g.clone(response.data)
        this.backupmasterKota = this.$g.clone(response.data)
        // console.log('this.backupmasterKota', this.backupmasterKota)
      }
    },
    async getMasterKecamatan() {
      var response = await lou.customUrlGet2('transaksi/registrasi/anggota/kecamatan')
      if (response) {
        this.masterKecamatan = this.$g.clone(response.data)
        this.backupmasterKecamatan = this.$g.clone(response.data)
        // console.log('this.backupmasterKecamatan', this.backupmasterKecamatan)
      }
    },
    async getMasterKelurahan() {
      var response = await lou.customUrlGet2('transaksi/registrasi/anggota/kelurahan')
      if (response) {
        this.masterKelurahan = this.$g.clone(response.data)
        this.backupmasterKelurahan = this.$g.clone(response.data)
        // console.log('this.backupmasterKelurahan', this.backupmasterKelurahan)
      }
    },
    async getAgamaF() {
      // var res = await lou.customUrlGet2('agama')
      // this.dataFagama = res.data
      // this.$perpus.searchObj()
    },
    sendToEdit(edata) {
      // var ndata = {}
      // for (let i = 0; i < this.columns.length; i++) {
      //   const element = this.columns[i].dataIndex
      //   ndata[element.toString().toLowerCase()] = edata[element]
      // }
      // console.log('edata', edata)
      var keanggotaan = edata.anggota === 0 ? 'nonanggota' : 'anggota'
      localStorage.setItem('koperasisyariaheditregisternasabahdata', JSON.stringify(edata))
      this.$router.push('/koperasisyariah/registernasabah/form' + keanggotaan)
    },
    formatDate(value) {
      return moment(value).format('DD-MM-YYYY')
    },
    showDeleteConfirm(deldata) {
      Modal.confirm({
        title: 'Are you sure delete this data?',
        content: "You can't prevent this!!! ",
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'No',
        onOk: async () => {
          var response = await lou.customUrlDelete2('transaksi/registrasi/anggota/' + deldata.id)
          this.data = response.data
          this.getAllData()
        },
        onCancel: () => {
          // console.log('Cancel')
        },
      })
    },
    findRelationData(data, mastername, key, datakey, label) {
      // console.log('mastername', mastername)
      var target = this[mastername].findIndex(x => x[key] === data[datakey])
      // console.log('data', data)
      // console.log('mastername', mastername)
      // console.log('key', key)
      // console.log('target', target)
      // console.log('this.allMaster[mastername][target]', this.allMaster[mastername][target])
      // console.log('this[mastername][target]', this[mastername][target])
      return target === -1 ? 'Data tidak ditemukan' : this[mastername][target][label]
    },
    handleSearch(selectedKeys, confirm, dataIndex) {
      confirm()
      this.searchText = selectedKeys[0]
      this.searchedColumn = dataIndex
    },

    handleReset(clearFilters) {
      clearFilters()
      this.searchText = ''
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      )
    },

    // Import Excel Function
    selectImportFile(to) {
      this.$refs[to].click()
    },
    readTextFile() {
      this.cancelaction()
      lou.shownotif('Loading...', 'Data onprocessing...', 'info', 1)
      this.importBtnLoading = true
      var fileupload = this.$refs.uploadtext.files[0]
      // console.log('this.rowData.length', this.rowData.length)
      if (this.rowData.length === 0) {
        this.rowData = null
      }
      var that = this
      setTimeout(() => {
        const reader = new FileReader()
        reader.onload = (function (reader) {
          return function () {
            var content = reader.result
            var lines = content.split('\r\n')
            // console.log('content', content)
            // console.log('lines', lines)
            that.filetextarr = lines
            // console.log('this.rowData', that.rowData)
            that.scrapTextFile()
          }
        })(reader)
        reader.readAsText(fileupload)
      }, 300)
      this.importBtnLoading = false
    },
    scrapTextFile() {
      var header = []
      var data = []
      var column = this.$g.clone(this.columns)
      // console.log('column', column)
      for (let u = 0; u < this.filetextarr.length; u++) {
        const element = this.filetextarr[u]
        var aftersplit = []
        if (u === 0) {
          aftersplit = element.split('|')
          header = aftersplit
        } else {
          aftersplit = element.split('|')
          // console.log('aftersplit', aftersplit)
          var md = {
            id: this.$uuid.v4(),
            periode: header[3][4],
            newdata: true,
          }
          for (let i = 0; i < column.length; i++) {
            const element = column[i]
            // console.log('element', element)
            // console.log('element.cellEditor', element.cellEditor)
            if (element.cellEditor === 'dateEditor') {
              md[element.dataIndex] =
                aftersplit[i] !== ''
                  ? moment(aftersplit[i], 'YYYYMMDD').format('YYYY-MM-DD')
                  : null
              md[element.dataIndex] =
                md[element.dataIndex] !== 'Invalid date'
                  ? md[element.dataIndex]
                  : null
            // } else if (element.valueFormatter === 'currency') {
            //   // console.log('element', element.dataIndex)
            //   md[element.dataIndex] = aftersplit[i] === '' ? 0 : parseInt(aftersplit[i])
            } else {
              var isCurency = this.columnCurrency.findIndex(x => x.field === element.dataIndex)
              if (isCurency >= 0) {
                md[element.dataIndex] = aftersplit[i] === '' ? 0 : parseInt(aftersplit[i])
              } else {
                md[element.dataIndex] = aftersplit[i] === '' ? null : aftersplit[i]
              }
            }
          }
          var last = u + 1
          if (last !== this.filetextarr.length) {
            data.push(md)
          }
        }
      }
      // console.log('header', header)
      // console.log('data', data)
      this.importTextHeader = header
      this.rowData = [...data, ...this.backupRowData]
      this.changedData = this.$g.clone(data)
      this.mode = 'oncreate'
      this.$refs.uploadtext.value = null
      lou.shownotif('Done', 'Text data selesai di baca.', 'success')
      // this.autoSizeAll(false, { autosizecolumn: true })
    },
    async downloadTemplate() {
      const ExcelJS = require('exceljs')
      const workbook = new ExcelJS.Workbook()
      workbook.creator = 'LB-BPR Heasoft System'
      workbook.lastModifiedBy = 'Me'
      workbook.created = new Date()
      workbook.modified = new Date()
      workbook.lastPrinted = new Date()
      var worksheet = workbook.addWorksheet('import data')
      var alphabeth = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z']
      var column = this.$g.clone(this.excelModal.column)
      var cfirst = 0
      var cloop = 0
      var columnLocation = []
      for (let i = 0; i < column.length; i++) {
        const element = column[i]
        var cloopni = cloop - 1
        var hname = element.headerName.toString()
        if (cloop === 0) {
          columnLocation.push({
            location: alphabeth[cfirst],
            field: element.field,
          })
          worksheet.getCell(alphabeth[cfirst] + '1').value = hname.replaceAll(' ', '')
        } else {
          columnLocation.push({
            location: alphabeth[cloopni] + alphabeth[cfirst],
            field: element.field,
          })
          worksheet.getCell(alphabeth[cloopni] + alphabeth[cfirst] + '1').value = hname.replaceAll(' ', '')
        }
        if (cfirst === 25) {
          cfirst = 0
          cloop += 1
        } else {
          cfirst += 1
        }
      }
      const buffer = await workbook.xlsx.writeBuffer()
      var blob = new Blob([buffer], {
        type:
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      })
      var link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = 'Register Nasabah IMPORT TEMPLATE.xlsx'
      link.click()
    },
    mselectSheet(params) {
      this.selectSheet(params)
    },
    selectSheet(params) {
      // console.log('params', params)
      var that = this
      if (this.fileupload) {
        var XLSX = require('xlsx')
        const reader = new FileReader()

        reader.onload = (e) => {
          /* Parse data */
          var data = e.target.result
          const wb = XLSX.read(data, {
            type: 'binary',
            cellDates: true,
          })
          /* Get first worksheet */
          // console.log('wb.SheetNames', wb.SheetNames)
          const ws = wb.Sheets[params]
          // var excellist = []
          /* Convert array of arrays */
          const ret = XLSX.utils.sheet_to_json(ws)
          // console.log('ret', ret)
          // var literalydata = ret.map((x) => {
          //   console.log('x', x[this.excelModal.column[0].field])
          //   if (x[this.excelModal.column[0].field] !== 'undefined') {
          //     return x
          //   }
          // })
          if (ret.length !== 0) {
            that.excelModal.datatable = []
            // console.log('that.excelModal.datatable', that.excelModal.datatable)
            var length = this.excelMode === 'normal' ? ret.length : ret.length - 1
            for (let i = 0; i < length; i++) {
              var element = ret[i]
              // if (element[that.excelModal.column[0].field] !== undefined) {
              // console.log('element', element.TglHapusBuku)
              element.tgl = element.tgl !== null && element.tgl !== undefined ? moment(element.tgl, 'DD-MM-YYYY').format('YYYY-MM-DD') : null
              element.tgllahir = element.tgllahir !== null && element.tgllahir !== undefined ? moment(element.tgllahir, 'DD-MM-YYYY').format('YYYY-MM-DD') : null
              // element.JangkaWaktuJatuhTempo = element.JangkaWaktuJatuhTempo !== null && element.JangkaWaktuJatuhTempo !== undefined ? moment(element.JangkaWaktuJatuhTempo, 'YYYY-MM-DD').format('YYYY-MM-DD') : null
              // element.Jumlah = element.Jumlah !== null && element.Jumlah !== undefined ? parseInt(element.Jumlah) : null
              // console.log('element', element.TglHapusBuku)
              element.anggota = 1
              that.excelModal.datatable.push(element)
              // }
            }
            this.componentKey += 1
            // console.log('that.excelModal.datatable', that.excelModal.datatable)
          }
        }
        reader.readAsBinaryString(this.fileupload)
      }
    },
    getSheetExcel() {
      this.importBtnLoading = true
      this.fileupload = this.$refs.excelFileUpload.files[0]
      // console.log('this.fileupload', this.fileupload)
      if (this.fileupload) {
        var XLSX = require('xlsx')
        const reader = new FileReader()

        reader.onload = (e) => {
          /* Parse data */
          var data = e.target.result
          const wb = XLSX.read(data, {
            type: 'binary',
            cellDates: true,
          })
          /* Get first worksheet */
          // console.log('wb.SheetNames', wb.SheetNames)
          const wsname = wb.SheetNames
          this.sheetColection = wsname
          this.importBtnLoading = false
          this.excelModalVisible = true
          // console.log('literalydata', literalydata)
          // for (var i = 0; i < ret.length; i++) {
          //   excellist.push(ret[i])
          // }
          // console.log('excellist', ret)
          // const a = wb.Sheets[wb.SheetNames[13]]
          // const headers = this.getHeader(a)
          // console.log('headers', headers)
          // dataexcel = literalydata
        }
        reader.readAsBinaryString(this.fileupload)
      }
    },
    importExcel(mode) {
      this.excelMode = mode
      this.excelModalFrom = ''
      this.$refs.excelFileUpload.click()
    },
    excelModalCancel() {
      this.excelModalVisible = false
      this.fileupload = null
      this.$refs.excelFileUpload.value = null
    },
    async proccessDataExcel() {
      if (!this.loadingImportExcel) {
        this.loadingImportExcel = true
        var res = null
        if (this.excelErrorExist) {
          Modal.confirm({
            title: 'Error data',
            content: 'Skip error data!?',
            okText: 'Agree',
            okType: 'primary',
            cancelText: 'No',
            onOk: async () => {
              var fd = this.excelModal.datatable
              fd = fd.filter((x) => x.TglHapusBuku !== 'Error')
              res = await lou.customUrlPost2('transaksi/registrasi/anggota/many', fd)
              this.afterProccessDataExcel(res)
            },
            onCancel: () => {
            // console.log('Cancel')
              return false
            },
          })
        } else {
          var fd = this.excelModal.datatable
          // res = await lou.customUrlPost2('transaksi/registrasi/anggota', fd)
          res = await lou.customUrlPost2('transaksi/registrasi/anggota/many', fd)
          this.afterProccessDataExcel(res)
        }
      }
    },
    async afterProccessDataExcel(res) {
      if (res) {
        this.loadingImportExcel = false
        this.excelModalVisible = false
        this.fileupload = null
        this.$refs.uploadtext.value = null
        this.$refs.excelFileUpload.value = null
        var response = await lou.customUrlGet2('transaksi/registrasi/anggota')
        if (response) {
          this.data = response.data
        }
      }
    },
    async exportExcel() {
      const ExcelJS = require('exceljs')
      const workbook = new ExcelJS.Workbook()
      workbook.creator = 'LB-BPRS Heasoft System'
      workbook.lastModifiedBy = 'Me'
      workbook.created = new Date()
      workbook.modified = new Date()
      workbook.lastPrinted = new Date()
      var alphabeth = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z']
      const worksheet = workbook.addWorksheet(moment().format('DD-MM-YYYY'))

      // Table Data
      var column = this.$g.clone(this.columns)
      var data = this.$g.clone(this.data)
      var cfirst = 0
      var cloop = 0
      var dincrement = 2
      var columnLocation = []
      for (let i = 0; i < column.length; i++) {
        const element = column[i]
        var cloopni = cloop - 1
        var hname = element.title.toString()
        if (hname !== 'Action') {
          if (cloop === 0) {
            columnLocation.push({
              location: alphabeth[cfirst],
              field: element.dataIndex,
            })
            worksheet.getCell(alphabeth[cfirst] + '1').value = hname.replaceAll(' ', '')
          } else {
            columnLocation.push({
              location: alphabeth[cloopni] + alphabeth[cfirst],
              field: element.dataIndex,
            })
            worksheet.getCell(alphabeth[cloopni] + alphabeth[cfirst] + '1').value = hname.replaceAll(' ', '')
          }
          for (let u = 0; u < data.length; u++) {
            const el = data[u]
            if (cloop === 0) {
              worksheet.getCell(alphabeth[cfirst] + dincrement).value = el[element.dataIndex]
            } else {
              worksheet.getCell(alphabeth[cloopni] + alphabeth[cfirst] + dincrement).value = el[element.dataIndex]
            }
            var checklast = u + 1
            if (checklast === data.length) {
              dincrement = 2
            } else {
              dincrement += 1
            }
          }
          if (cfirst === 25) {
            cfirst = 0
            cloop += 1
          } else {
            cfirst += 1
          }
        }
      }

      /* For sum data */
      // var last = data.length + 3
      // worksheet.mergeCells('A' + last + ':B' + last)
      // worksheet.getCell('A' + last).value = 'Total'
      // for (let ti = 0; ti < this.columnsTotal.length; ti++) {
      //   const element = this.columnsTotal[ti]
      //   var targetLoc = columnLocation.findIndex(x => x.field === element.dataIndex)
      //   if (targetLoc >= 0) {
      //     worksheet.getCell(columnLocation[targetLoc].location + last).value = this.rowDataTotal[0][element.dataIndex]
      //   }
      //   // console.log(columnLocation[targetLoc].location + last, this.rowDataTotal[0][element.dataIndex])
      // }

      // write to a new buffer
      const buffer = await workbook.xlsx.writeBuffer()
      var blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
      var link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = 'Data Register nasabah.xlsx'
      link.click()
    },
  },
}
</script>

<style>
.ant-card-body {
  padding: 24px !important;
}
.highlight {
  background-color: rgb(255, 192, 105);
  padding: 0px;
}
</style>
