<template>
  <div>
    <a-modal
      v-model="visible"
      :title="action + ' Active Domain'"
      :dialogStyle="{ top: '10px' }"
      :destroyOnClose="true"
    >
      <template slot="footer">
        <a-button class="btn btn-outline-dark" @click="handleCancel">
          Cancel
        </a-button>
        <a-button
          class="btn btn-outline-primary"
          :loading="loading"
          @click="submit('ruleForm')"
        >
          Submit
        </a-button>
      </template>
      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12">
          <a-form-model
            ref="ruleForm"
            :model="form"
            :rules="rules"
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
          >
            <a-form-model-item
              style="margin: unset;"
              ref="url"
              label="url"
              prop="url"
            >
              <a-input
                v-model="form.url"
                @blur="
                  () => {
                    $refs.url.onFieldBlur();
                  }
                "
              />
            </a-form-model-item>
            <a-form-model-item
              style="margin: unset;"
              ref="system_name"
              label="system_name"
              prop="system_name"
            >
              <a-input
                v-model="form.system_name"
                @blur="
                  () => {
                    $refs.system_name.onFieldBlur();
                  }
                "
              />
            </a-form-model-item>
            <a-form-model-item
              style="margin: unset;"
              ref="company_name"
              label="company_name"
              prop="company_name"
            >
              <a-input
                v-model="form.company_name"
                @blur="
                  () => {
                    $refs.company_name.onFieldBlur();
                  }
                "
              />
            </a-form-model-item>
            <a-form-model-item
              style="margin: unset;"
              ref="copyright"
              label="copyright"
              prop="copyright"
            >
              <a-input
                v-model="form.copyright"
                @blur="
                  () => {
                    $refs.copyright.onFieldBlur();
                  }
                "
              />
            </a-form-model-item>
            <a-form-model-item
              style="margin: unset;"
              ref="status"
              label="status"
              prop="status"
            >
              <a-select v-model="form.status" show-search option-filter-prop="children" :filter-option="filterOption">
                <a-select-option
                  v-for="data in datastatus"
                  :key="data.kode"
                  :value="data.kode"
                  >{{ data.label }}</a-select-option
                >
              </a-select>
            </a-form-model-item>
            <a-form-model-item
              style="margin: unset;"
              ref="notes"
              label="notes"
              prop="notes"
            >
              <a-input
                v-model="form.notes"
                @blur="
                  () => {
                    $refs.notes.onFieldBlur();
                  }
                "
              />
            </a-form-model-item>
          </a-form-model>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import * as lou from '@/services/data/lou'
import moment from 'moment'

export default {
  name: 'editprofile',
  created() {
    // this.getMaster()
  },
  data() {
    // const validatePass = (rule, value, callback) => {
    //   // if (this.action === 'Create') {
    //   //   if (this.form.password_confirmation !== '') {
    //   //     this.$refs.ruleForm.validateField('checkPass')
    //   //   }
    //   //   callback()
    //   // } else {
    //   if (value === '') {
    //     callback(new Error('Please input the password'))
    //   } else {
    //     if (this.form.password_confirmation !== '') {
    //       this.$refs.ruleForm.validateField('checkPass')
    //     }
    //     callback()
    //   }
    //   // }
    // }
    // const validatePass2 = (rule, value, callback) => {
    //   // if (this.action === 'Create') {
    //   //   if (value !== this.form.password) {
    //   //     callback(new Error("Password doesn't match!"))
    //   //   } else {
    //   //     callback()
    //   //   }
    //   // } else {
    //   if (value === '') {
    //     callback(new Error('Please input the password again'))
    //   } else {
    //     if (value !== this.form.password) {
    //       callback(new Error("Password doesn't match!"))
    //     } else {
    //       callback()
    //     }
    //   }
    //   // }
    // }
    return {
      labelCol: { span: 6 },
      wrapperCol: { span: 18 },
      user: [],
      datastatus: [
        {
          kode: 0,
          label: 'Non-Aktif',
        },
        {
          kode: 1,
          label: 'Aktif',
        },
      ],
      roletags: false,
      editdata: {},
      action: 'Create',
      form: {
        url: '',
        system_name: '',
        company_name: '',
        copyright: '',
        status: 1,
        notes: '',
      },
      rules: {
        kode: [{ required: true, message: 'Please input kode!' }],
        obox: [{ required: true, message: 'Please input obox!' }],
        // periode: [{ required: true, message: 'Please input periode!' }],
      },
      loading: false,
      visible: false,
      datenow: null,
    }
  },
  methods: {
    moment,
    // async getMaster() {
    //   var resp = await lou.readMaster('id_role')
    //   this.datarole = resp.data
    // },
    selectDate(selectedDates, dateString, input) {
      // console.log('selectedDates', selectedDates)
      // console.log('dateString', dateString)
      // console.log('input', input)
      if (selectedDates !== null) {
        this.form[input] = dateString
      } else {
        this.form[input] = null
      }
    },
    userAppSelect(e) {
      // console.log('e', e)
      var temp = []
      for (let m = 0; m < e.length; m++) {
        const element = e[m]
        temp.push({ id_app: element })
      }
      // console.log('temp', temp)
      this.form.appLists = temp
    },
    showModal(action, data = {}) {
      // console.log('data', data)
      this.editdata = data
      // var resbpr = await lou.customUrlGet('manage/domain', false, true)
      // this.databpr = resbpr.data
      if (action === 'Update') {
        this.form.id = data.id
        this.form.url = data.url
        this.form.system_name = data.system_name
        this.form.company_name = data.company_name
        this.form.copyright = data.copyright
        this.form.status = data.status
        this.form.notes = data.notes
        // console.log('this.form.selectedAppLists', this.form.selectedAppLists)
      } else {
        this.form.url = ''
        this.form.system_name = ''
        this.form.company_name = ''
        this.form.copyright = ''
        this.form.status = 1
        this.form.notes = ''
      }
      this.action = action
      this.visible = true
    },
    handleCancel() {
      this.visible = false
    },
    submit(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          // console.log('this.form.appLists', this.form.appLists)
          var fd = {
            url: this.form.url,
            system_name: this.form.system_name,
            company_name: this.form.company_name,
            copyright: this.form.copyright,
            status: this.form.status,
            notes: this.form.notes,
          }
          if (this.action === 'Create') {
            await lou.createMaster('manage/domain', fd, true, true)
          } else {
            fd.id = this.form.id
            await lou.updateMaster('manage/domain', fd, true, true)
          }
          this.$parent.getData()
          this.visible = false
          this.resetForm(formName)
        } else {
          // console.log('error submit!!')
          return false
        }
      })
    },
    resetForm(formName) {
      this.$refs[formName].resetFields()
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      )
    },
  },
}
</script>

<style></style>
