<template>
  <div>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mb-2">
        <div class="row">
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            <strong style="font-size: 18px;">Otorisasi</strong>
            <v-select
              multiple
              style="width: 400px;"
              :clearable="true"
              class="style-chooser pull-right"
              v-model="filtered"
              placeholder="Filter"
              label=""
              :options="['Waiting', 'Approved', 'Rejected', 'Cancelled']"
              :reduce="(tes) => tes"
              @input="filterData"
            />
          </div>
        </div>
      </div>
      <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4" v-for="(item, index) in data" :key="index">
        <div :class="item.result === 'Cancelled' ? 'card border-secondary mb-3 card-block' : item.result === 'Waiting' ? 'card border-warning mb-3 card-block' : item.result === 'Approved' ? 'card border-success mb-3 card-block' : 'card border-danger mb-3 card-block'" style="">
          <!-- <div class="card-header">2024-02-17 11:02</div> -->
          <div :class="item.result === 'Cancelled' ? 'card-body text-secondary' : item.result === 'Waiting' ? 'card-body text-warning' : item.result === 'Approved' ? 'card-body text-success' : 'card-body text-danger'" style="padding: 0.8rem 0.6600000000000001rem;">
            <template class="card-title mb-4">
              <strong class="text-dark">
                {{ item.nama_form }} -
              </strong>
              <strong :class="item.result === 'Cancelled' ? 'text-secondary' : item.result === 'Waiting' ? 'text-warning' : item.result === 'Approved' ? 'text-success' : 'text-danger'">
                {{item.result}}
              </strong>
              <a href="javascript:void(0)" class="pull-right text-success" @click="Approve" v-if="item.result === 'Waiting'">
                <a-icon type="check"></a-icon>
                Approve
              </a>
            </template>
            <template class="card-text">
              <div class="row">
                <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 text-dark" style="padding-right: 0px !important;">Created At </div>
                <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8" style="padding-right: 0px !important;">: {{formatDateCreatedAt(item.created_at, 'YYYY-MM-DDTHH:mm:ss', 'DD-MM-YYYY HH:mm')}}</div>
                <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 text-dark" style="padding-right: 0px !important;">Auth Code </div>
                <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8" style="padding-right: 0px !important;">: {{ item.auth_code }}</div>
                <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 text-dark" style="padding-right: 0px !important;">Requested By </div>
                <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8" style="padding-right: 0px !important;">: {{ item.requested_by }}</div>
                <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 text-dark" style="padding-right: 0px !important;">Approval 1 </div>
                <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8" style="padding-right: 0px !important;">: {{ item.approval_1 }}</div>
                <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 text-dark" style="padding-right: 0px !important;">Approval 2 </div>
                <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8" style="padding-right: 0px !important;">: {{ item.approval_2 }}</div>
                <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 text-dark" style="padding-right: 0px !important;">Approval 3 </div>
                <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8" style="padding-right: 0px !important;">: {{ item.approval_3 }}</div>
                <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 text-dark" style="padding-right: 0px !important;">Faktur </div>
                <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8" style="padding-right: 0px !important;">: {{ item.faktur }}</div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* Import Script */
import { Modal } from 'ant-design-vue'
import * as lou from '@/services/data/lou'
import moment from 'moment'
import NProgress from 'nprogress'
// import { mapState } from 'vuex'
/* End Script Import Script */

/* Kolom atau header dari table */
const columns = [
  {
    title: 'Action',
    scopedSlots: { customRender: 'action' },
    // fixed: 'left',
    width: 70,
  },
  {
    title: 'Result',
    dataIndex: 'result',
    width: 80,
    // fixed: 'left',
    scopedSlots: {
      customRender: 'tags',
    },
  },
  {
    title: 'Nama Form',
    dataIndex: 'nama_form',
    width: 150,
    // fixed: 'left',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
    onFilter: (value, record) =>
      record.nama_form.toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => {
          this.searchInput.focus()
        }, 0)
      }
    },
  },
  {
    title: 'Auth Code',
    dataIndex: 'auth_code',
    width: 200,
    // fixed: 'left',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
    onFilter: (value, record) =>
      record.auth_code.toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => {
          this.searchInput.focus()
        }, 0)
      }
    },
  },
  {
    title: 'Request Type',
    dataIndex: 'request_type',
    width: 100,
    // fixed: 'left',
    scopedSlots: {
      customRender: 'tagsrequesttype',
    },
  },
  {
    title: 'Requested By',
    dataIndex: 'requested_by',
    width: 150,
    // fixed: 'left',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
    onFilter: (value, record) =>
      record.requested_by
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => {
          this.searchInput.focus()
        }, 0)
      }
    },
  },
  {
    title: 'Approval 1',
    dataIndex: 'approval_1',
    width: 150,
    // fixed: 'left',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
    onFilter: (value, record) =>
      record.approval_1.toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => {
          this.searchInput.focus()
        }, 0)
      }
    },
  },
  {
    title: 'Approval 2',
    dataIndex: 'approval_2',
    width: 150,
    // fixed: 'left',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
    onFilter: (value, record) =>
      record.approval_2.toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => {
          this.searchInput.focus()
        }, 0)
      }
    },
  },
  {
    title: 'Approval 3',
    dataIndex: 'approval_3',
    width: 150,
    // fixed: 'left',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
    onFilter: (value, record) =>
      record.approval_3.toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => {
          this.searchInput.focus()
        }, 0)
      }
    },
  },
  {
    title: 'Created At',
    dataIndex: 'created_at',
    width: 150,
    // fixed: 'left',
    scopedSlots: {
      customRender: 'created_at',
    },
  },
  {
    title: 'Faktur',
    dataIndex: 'faktur',
    width: 150,
    // fixed: 'left',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
    onFilter: (value, record) =>
      record.faktur.toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => {
          this.searchInput.focus()
        }, 0)
      }
    },
  },
]
/* End script Kolom atau header dari table */

/* Data dari table */
const data = []
/* End script Data dari table */

export default {
  components: {},
  computed: {
    // ...mapState(['settings', 'user']),
  },
  created() {
    this.getAllData()
  },
  /* Data variable vue */
  data() {
    return {
      loadingRefresh: false,
      searchText: '',
      tgl1: '',
      tgl2: '',
      searchInput: null,
      searchedColumn: '',
      name: 'Tabletabungan',
      visible: false,
      filtered: [],
      allMaster: [],
      masterForms: [],
      masterUsers: [],
      masterLevels: [
        {
          label: 1,
        },
        {
          label: 2,
        },
        {
          label: 3,
        },
      ],
      backupData: [],
      data,
      columns,
      columnChildren: [
        {
          title: 'Product_name',
          dataIndex: 'Product_name',
          ellipsis: true,
          // scopedSlots: {
          //   customRender: 'formatCurrency',
          // },
        },
        {
          title: 'Jumlah',
          dataIndex: 'Jumlah',
          ellipsis: true,
          align: 'right',
          scopedSlots: {
            customRender: 'details',
          },
          sorter: (a, b) => a.Jumlah - b.Jumlah,
        },
        {
          title: 'Harga',
          dataIndex: 'Harga',
          ellipsis: true,
          align: 'right',
          scopedSlots: {
            customRender: 'formatCurrency',
          },
          sorter: (a, b) => a.Harga - b.Harga,
        },
        {
          title: 'Diskon',
          dataIndex: 'Diskon',
          ellipsis: true,
          align: 'right',
          scopedSlots: {
            customRender: 'formatCurrency',
          },
          sorter: (a, b) => a.Diskon - b.Diskon,
        },
        {
          title: 'Total',
          dataIndex: 'Total',
          ellipsis: true,
          align: 'right',
          scopedSlots: {
            customRender: 'formatCurrency',
          },
          sorter: (a, b) => a.Total - b.Total,
        },
      ],
      datenow: '',
      componentKey: 0,

      // Modal property
      action: null,
      formvisible: false,
      editdata: {},
      modalInput: {
        form_id: '',
        user_id: '',
        user_name: '',
        level: '',
      },
    }
  },
  /* End Data variable vue */
  mounted: function () {
    const date = moment().format('YYYY-MM-DD') // Tanggal hari ini format 2020-12-31
    // localStorage.removeItem('newkoperasiedittabungandata')
    this.tgl1 = date
    this.tgl2 = date
    this.datenow = date.toString()
    // this.getAgamaF()
  },
  methods: {
    moment,
    filterData() {
      console.log('this.filtered', this.filtered)
      if (this.filtered.length === 0) {
        this.data = this.$g.clone(this.backupData)
      } else {
      // Filter names that contain either "s" or "S"
        const filteredNames = this.backupData.filter(x => {
          for (const char of this.filtered) {
            if (x.result.includes(char)) {
              return true
            }
          }
          return false
        })

        // Output the filtered names
        // console.log(filteredNames)
        this.data = filteredNames
      }
    },
    sudahdiapprove() {
      lou.shownotif('Informasi!', 'Data terpilih telah diApprove atau diCanceled.')
    },
    async Approve(data) {
      Modal.confirm({
        title: 'Approved this data?',
        content: "You can't change this later! ",
        okText: 'Yes',
        okType: 'primary',
        cancelText: 'No',
        onOk: async () => {
          var fd = {
            auth_code: data.auth_code,
            status: 1,
          }
          var res = await lou.customUrlPost2('utility/otorisasi', fd)
          if (res) {
            this.getAllData()
          }
        },
        onCancel: () => {
          // console.log('Cancel')
        },
      })
    },
    selectuser() {
      var targetIndex = this.masterUsers.findIndex(
        (x) => x.id === this.modalInput.user_id,
      )
      if (targetIndex > -1) {
        this.modalInput.user_name = this.masterUsers[targetIndex].name
      }
    },
    async showModal(action, data) {
      this.formvisible = true
      this.action = action
      var response = await lou.customUrlGet2('utility/otorisasi/forms')
      var res = await lou.readMaster('users', false, true)
      var ndata = []
      if (res.data.length !== 0) {
        res.data.forEach((element) => {
          if (element.hidden === 0) {
            // element.changePassPop = false
            ndata.push(element)
          }
        })
        this.masterUsers = ndata
      }
      if (response) {
        this.masterForms = response.data
      }
      if (action === 'update') {
        this.modalInput = this.$g.clone(data)
        this.editdata = data
      }
    },
    async getAllData(onclick = false) {
      this.loadingRefresh = true
      var response = await lou.customUrlGet2('utility/otorisasi')
      if (response) {
        this.data = response.data
        this.backupData = response.data
        if (onclick) {
          lou.shownotif('Refresh', 'Data telah di refresh.')
        }
        setTimeout(() => {
          this.loadingRefresh = false
        }, 450)
      } else {
        setTimeout(() => {
          this.loadingRefresh = false
        }, 450)
      }
      // console.log('this.data', this.data)
    },
    formatDateCreatedAt(params, fromformat = 'YYYY-MM-DDTHH:mm:ss', toformat = 'DD-MM-YYYY HH:mm:ss') {
      console.log('params', params)
      var ret = ''
      ret = params === '' || params === null || params === undefined ? '-' : moment(params, fromformat).format(toformat)
      return ret
    },
    formatDate(value) {
      return moment(value).format('DD-MM-YYYY')
    },
    showDeleteConfirm(deldata) {
      Modal.confirm({
        title: 'Are you sure delete this data?',
        content: "You can't prevent this!!! ",
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'No',
        onOk: async () => {
          var response = await lou.customUrlDelete2(
            'utility/otorisasi/' + deldata.id,
          )
          if (response) {
            this.getAllData()
          }
        },
        onCancel: () => {
          // console.log('Cancel')
        },
      })
    },
    handleSearch(selectedKeys, confirm, dataIndex) {
      confirm()
      this.searchText = selectedKeys[0]
      this.searchedColumn = dataIndex
    },

    handleReset(clearFilters) {
      clearFilters()
      this.searchText = ''
    },

    // Modal function
    async handleSubmit() {
      var fd = this.$g.clone(this.modalInput)
      var res = false
      if (this.action === 'update') {
        res = await lou.customUrlPut2('utility/otorisasi', fd)
      } else {
        delete fd.id
        res = await lou.customUrlPost2('utility/otorisasi', fd)
      }
      if (res) {
        this.getAllData()
        this.handleCancel()
      }
    },
    handleCancel() {
      this.formvisible = false
      this.action = 'create'
      this.modalInput = {
        form_id: '',
        user_id: '',
        user_name: '',
        level: '',
      }
    },
    async exportExcel() {
      NProgress.start()
      const ExcelJS = require('exceljs')
      const workbook = new ExcelJS.Workbook()
      workbook.creator = 'LB-BPRS Heasoft System'
      workbook.lastModifiedBy = 'Me'
      workbook.created = new Date()
      workbook.modified = new Date()
      workbook.lastPrinted = new Date()
      var alphabeth = [
        'A',
        'B',
        'C',
        'D',
        'E',
        'F',
        'G',
        'H',
        'I',
        'J',
        'K',
        'L',
        'M',
        'N',
        'O',
        'P',
        'Q',
        'R',
        'S',
        'T',
        'U',
        'V',
        'W',
        'X',
        'Y',
        'Z',
      ]
      const worksheet = workbook.addWorksheet(moment().format('DD-MM-YYYY'))

      // Table Data
      var column = this.$g.clone(this.columns)
      var tdata = this.$g.clone(this.data)
      var data = []
      tdata.forEach((element) => {
        var retv = {
          ...element,
          tgl:
            element.tgl === null
              ? ''
              : moment(element.tgl, 'YYYY-MM-DD').format('DD-MM-YYYY'),
        }
        data.push(retv)
      })
      var cfirst = 0
      var cloop = 0
      var dincrement = 2
      var columnLocation = []
      for (let i = 0; i < column.length; i++) {
        const element = column[i]
        var cloopni = cloop - 1
        var hname = element.title.toString()
        if (hname !== 'Action') {
          if (cloop === 0) {
            columnLocation.push({
              location: alphabeth[cfirst],
              field: element.dataIndex,
            })
            worksheet.getCell(alphabeth[cfirst] + '1').value = hname.replaceAll(
              ' ',
              '',
            )
          } else {
            columnLocation.push({
              location: alphabeth[cloopni] + alphabeth[cfirst],
              field: element.dataIndex,
            })
            worksheet.getCell(
              alphabeth[cloopni] + alphabeth[cfirst] + '1',
            ).value = hname.replaceAll(' ', '')
          }
          for (let u = 0; u < data.length; u++) {
            const el = data[u]
            if (cloop === 0) {
              worksheet.getCell(alphabeth[cfirst] + dincrement).value =
                el[element.dataIndex]
            } else {
              worksheet.getCell(
                alphabeth[cloopni] + alphabeth[cfirst] + dincrement,
              ).value = el[element.dataIndex]
            }
            var checklast = u + 1
            if (checklast === data.length) {
              dincrement = 2
            } else {
              dincrement += 1
            }
          }
          if (cfirst === 25) {
            cfirst = 0
            cloop += 1
          } else {
            cfirst += 1
          }
        }
      }

      /* For sum data */
      // var last = data.length + 3
      // worksheet.mergeCells('A' + last + ':B' + last)
      // worksheet.getCell('A' + last).value = 'Total'
      // for (let ti = 0; ti < this.columnsTotal.length; ti++) {
      //   const element = this.columnsTotal[ti]
      //   var targetLoc = columnLocation.findIndex(x => x.field === element.dataIndex)
      //   if (targetLoc >= 0) {
      //     worksheet.getCell(columnLocation[targetLoc].location + last).value = this.rowDataTotal[0][element.dataIndex]
      //   }
      //   // console.log(columnLocation[targetLoc].location + last, this.rowDataTotal[0][element.dataIndex])
      // }

      // write to a new buffer
      const buffer = await workbook.xlsx.writeBuffer()
      var blob = new Blob([buffer], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      })
      var link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = 'Data Master Otorisasi.xlsx'
      NProgress.done()
      link.click()
    },
    formatCurrency(v) {
    // console.log('v', v)
      return lou.curency(v)
    },
  },
}
</script>

<style scoped>
.highlight {
  background-color: rgb(255, 192, 105);
  padding: 0px;
}
</style>
