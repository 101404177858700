<template>
  <div>
    <a-card title="Buku Tabungan">
      <div class="row" style="padding: 10px;">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
          <div class="row">
            <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
              <div class="row">
                <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3">
                  <label for="betweenTgl">No. Rekening: </label>
                </div>
                <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9">
                  <cleave
                    :readOnly="false"
                    :options="{ delimiter: '.', blocks: [2, 2, 6, 2] }"
                    class="ant-input"
                    :raw="false"
                    style="width: 150px;"
                    v-model.lazy="norekening"
                    placeholder="Ketik No Rekening"
                    v-on:keydown.enter.native="checkRekening"
                  />
                  <!-- <a-select
                    label-in-value
                    show-search
                    :value="selectedRekening"
                    placeholder="Nomor Rekening"
                    style="width: 150px;"
                    :filter-option="false"
                    :not-found-content="fetching ? undefined : null"
                    @search="fetchUser"
                    @change="(e) => handleChange(e, 'selectedRekening')"
                  >
                    <a-spin v-if="fetching" slot="notFoundContent" size="small" />
                    <a-select-option v-for="(d, index) in dataRekening" :key="index">
                      {{ d.rekening + ' - ' + d.nama }}
                      {{ d.rekening }}
                    </a-select-option>
                  </a-select> -->
                  <a-select
                    label-in-value
                    show-search
                    class="ml-2"
                    :value="selectedName"
                    placeholder="Nama Nasabah"
                    style="width: 200px;"
                    :filter-option="false"
                    :not-found-content="fetching ? undefined : null"
                    @search="fetchUser"
                    @change="(e) => handleChange(e, 'selectedName')"
                  >
                    <a-spin v-if="fetching" slot="notFoundContent" size="small" />
                    <a-select-option v-for="(d, index) in dataRekening" :key="index">
                      <!-- {{ d.rekening + ' - ' + d.nama }} -->
                      {{ d.nama }}
                    </a-select-option>
                  </a-select>
                </div>
              </div>
            </div>
            <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
              <label for="saldoAwal">Saldo Awal: </label>
              <a-input class="ml-2" style="width: 200px; text-align: right;" v-model="modalCetakBuku.saldoAwal" :readOnly="true"></a-input>
            </div>
          </div>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
          <div class="row">
            <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
              <div class="row">
                <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3">
                  <label for="betweenTgl">Antara Tanggal: </label>
                </div>
                <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9">
                  <a-date-picker
                    class="mr-2"
                    style="width: 100px;"
                    v-model="modalCetakBuku.tgl1"
                    :allowClear="false"
                    format="DD-MM-YYYY"
                    @change="(dateMoment, dateString) => changeDateInput(dateMoment, dateString, ['modalCetakBuku', 'tgl1'])"
                  />
                  -
                  <a-date-picker
                    class="ml-2"
                    style="width: 100px;"
                    v-model="modalCetakBuku.tgl2"
                    :allowClear="false"
                    format="DD-MM-YYYY"
                    @change="(dateMoment, dateString) => changeDateInput(dateMoment, dateString, ['modalCetakBuku', 'tgl2'])"
                  />
                </div>
              </div>
            </div>
            <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
              <label for="saldoAwal">Saldo Akhir: </label>
              <a-input class="ml-2" style="width: 200px; text-align: right;" v-model="modalCetakBuku.saldoAkhir" :readOnly="true"></a-input>
            </div>
          </div>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
          <div class="row">
            <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
              <div class="row">
                <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3">
                  <label for="betweenTgl">Nama: </label>
                </div>
                <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9">
                  <a-input class="" style="width: 300px;" v-model="namaprekening" :readOnly="true"></a-input>
                </div>
              </div>
            </div>
            <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
              <label for="saldoAwal">Baris Cetak: </label>
              <a-input class="ml-2" style="width: 40px;" v-model="modalCetakBuku.barisCetak"></a-input>
            </div>
          </div>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
          <div class="row">
            <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
              <div class="row">
                <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3">
                  <label for="betweenTgl">Alamat: </label>
                </div>
                <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9">
                  <a-input class="" style="width: 300px;" v-model="alamatprekening" :readOnly="true"></a-input>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-3">
          <a-button :class="modalCetakBuku.selectallmode ? 'btn btn-outline-primary btn-sm mb-2' : 'btn btn-outline-danger btn-sm mb-2'" @click="selectAllModalCetak" :disabled="modalCetakBuku.datatable.length === 0">{{modalCetakBuku.selectallmode ? 'Select All' : 'Diselect All'}}</a-button>
          <a-button class="btn btn-outline-success btn-sm mb-2 ml-2" @click="generateBuku">
            Cetak
          </a-button>
          <a-table
            :columns="modalCetakBuku.columns"
            :dataSource="modalCetakBuku.datatable"
            size="small"
            :key="componentKeyDatatable"
            :scroll="{ y: 200 }"
            :pagination="{
              hideOnSinglePage: true,
              defaultPageSize: 200,
              showQuickJumper: true,
              showSizeChanger: true,
              showTotal: (total) => `Total ${total} items`,
              pageSizeOptions: ['100', '200', '300'],
            }"
          >
            <div
              slot="filterDropdown"
              slot-scope="{
                setSelectedKeys,
                selectedKeys,
                confirm,
                clearFilters,
                column,
              }"
              style="padding: 8px;"
            >
              <a-input
                v-ant-ref="(c) => (searchInput = c)"
                :placeholder="`Search ${column.dataIndex}`"
                :value="selectedKeys[0]"
                style="width: 188px; margin-bottom: 8px; display: block;"
                @change="
                  (e) => setSelectedKeys(e.target.value ? [e.target.value] : [])
                "
                @pressEnter="
                  () => handleSearch(selectedKeys, confirm, column.dataIndex)
                "
              />
              <a-button
                type="primary"
                icon="search"
                size="small"
                style="width: 90px; margin-right: 8px;"
                @click="
                  () => handleSearch(selectedKeys, confirm, column.dataIndex)
                "
              >
                Search
              </a-button>
              <a-button
                size="small"
                style="width: 90px;"
                @click="() => handleReset(clearFilters)"
              >
                Reset
              </a-button>
            </div>
            <a-icon
              slot="filterIcon"
              slot-scope="filtered"
              type="search"
              :style="{ color: filtered ? '#108ee9' : undefined }"
            />
            <template
              slot="customRender"
              slot-scope="text, record, index, column"
            >
              <span v-if="searchText && searchedColumn === column.dataIndex">
                <template
                  v-for="(fragment, i) in text
                    .toString()
                    .split(
                      new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i')
                    )"
                >
                  <mark
                    v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                    :key="i"
                    class="highlight"
                    >{{ fragment }}</mark
                  >
                  <template v-else>{{ fragment }}</template>
                </template>
              </span>
              <template v-else>
                {{ text }}
              </template>
            </template>
            <template slot="mutasi" slot-scope="text, record">
              {{ record.debet === 0 ? formatCurrency(record.kredit) : formatCurrency(record.debet) }}
            </template>
            <template slot="saldo" slot-scope="text, record">
              {{ formatCurrency(record.saldo) }}
            </template>
            <template slot="selected" slot-scope="text, record">
              <a-checkbox :key="componentKey" :checked="record.selected" @change="(e) => changedSelectedModal(e, record)" />
            </template>
          </a-table>
        </div>
      </div>
    </a-card>
  </div>
</template>

<script>
import * as lou from '@/services/data/lou'
import debounce from 'lodash/debounce'
import moment from 'moment'
import { mapState } from 'vuex'
import JSPDF from 'jspdf'
import Cleave from 'vue-cleave-component'

export default {
  components: {
    cleave: Cleave,
  },
  computed: {
    ...mapState(['settings', 'user']),
  },
  watch: {
    'settings.selectedRekening'() {
      this.norekening = this.settings.selectedRekening
      this.checkRekening()
    },
  },
  created () {
    if (this.settings.selectedRekening !== '') {
      this.norekening = this.settings.selectedRekening
      this.checkRekening()
    }
  },
  data() {
    this.lastFetchId = 0
    this.fetchUser = debounce(this.fetchUser, 800)
    return {
      dataRekening: [],
      modalCetakBuku: {
        responseAPI: null,
        changedData: [],
        visible: false,
        tgl1: moment(),
        tgl2: moment(),
        noRekening: '',
        nama: '',
        alamat: '',
        saldoAwal: '',
        saldoAkhir: '',
        barisCetak: 0,
        selectallmode: false,
        columns: [
          {
            title: '#',
            width: 50,
            dataIndex: 'selected',
            scopedSlots: {
              customRender: 'selected',
            },
          },
          {
            title: 'Tanggal',
            dataIndex: 'tgl',
            scopedSlots: {
              filterDropdown: 'filterDropdown',
              filterIcon: 'filterIcon',
              customRender: 'customRender',
            },
            onFilter: (value, record) =>
              record.tgl.toString().toLowerCase().includes(value.toLowerCase()),
            onFilterDropdownVisibleChange: (visible) => {
              if (visible) {
                setTimeout(() => {
                  this.searchInput.focus()
                }, 0)
              }
            },
          },
          {
            title: 'SD',
            dataIndex: 'kodetransaksi',
            scopedSlots: {
              filterDropdown: 'filterDropdown',
              filterIcon: 'filterIcon',
              customRender: 'customRender',
            },
            onFilter: (value, record) =>
              record.kodetransaksi.toString().toLowerCase().includes(value.toLowerCase()),
            onFilterDropdownVisibleChange: (visible) => {
              if (visible) {
                setTimeout(() => {
                  this.searchInput.focus()
                }, 0)
              }
            },
          },
          {
            title: 'Mutasi',
            dataIndex: 'mutasi',
            scopedSlots: {
              customRender: 'mutasi',
            },
            align: 'right',
          },
          {
            title: 'Saldo',
            dataIndex: 'saldo',
            scopedSlots: {
              customRender: 'saldo',
            },
            align: 'right',
          },
          {
            title: 'Username',
            dataIndex: 'username',
            scopedSlots: {
              filterDropdown: 'filterDropdown',
              filterIcon: 'filterIcon',
              customRender: 'customRender',
            },
            onFilter: (value, record) =>
              record.username.toString().toLowerCase().includes(value.toLowerCase()),
            onFilterDropdownVisibleChange: (visible) => {
              if (visible) {
                setTimeout(() => {
                  this.searchInput.focus()
                }, 0)
              }
            },
          },
        ],
        datatable: [],
      },
      selectedRekening: [],
      selectedName: [],
      tanggalTransaksi: moment(),
      norekening: '',
      namaprekening: '',
      alamatprekening: '',
      searchText: '',
      searchInput: null,
      searchedColumn: '',
      fetching: false,
      componentKey: 0,
      componentKeyDatatable: 0,
    }
  },
  methods: {
    async checkRekening() {
      if (this.norekening.length === 15) {
      // console.log('this.norekening', this.norekening)
        var res = await lou.customUrlGet('mutasitabungan/cari_rekening/' + this.norekening.toString())
        if (res) {
          if (res.data.length !== 0) {
            // lou.shownotif('Ditemukan!', 'Data telah ditemukan!', 'info')
            this.dataRekening = res.data
            var value = this.dataRekening.findIndex(x => x.rekening === this.norekening)
            var dataTarget = this.dataRekening[value]
            var data = {
              selectedName: { key: value, label: dataTarget.nama },
              selectedRekening: { key: value, label: dataTarget.rekening },
              rekeningjurnal: dataTarget.rekening,
              norekening: dataTarget.rekening,
              namaprekening: dataTarget.nama,
              alamatprekening: dataTarget.alamat,
              saldoprekening: dataTarget.saldo,
              // dataRekening: [],
              fetching: false,
            }
            // console.log('dataTarget', dataTarget)
            Object.assign(this, data)
            this.getDatatableModalBuku()
          } else {
            lou.shownotif('Tidak di temukan', 'Tidak ada data dengan rekening tersebut!', 'error')
          }
        } else {
        // lou.shownotif('Tidak di temukan', 'Tidak ada data dengan rekening tersebut!', 'error')
        }
      }
    },
    changedSelectedModal(e, record) {
    // console.log('record', record.selected)
    // console.log('e', e)
      var checked = e.target.checked
      // console.log('record', record.selected)
      record.selected = checked
      var target = this.modalCetakBuku.changedData.findIndex((x) => x.id === record.id)
      if (target < 0) {
        this.modalCetakBuku.changedData.push(record)
      } else {
        if (checked) {
          this.modalCetakBuku.changedData[target].selected = checked
        } else {
          this.modalCetakBuku.changedData.splice(target, 1)
        }
      }
      this.componentKey++
    // console.log('this.modalCetakBuku.changedData', this.modalCetakBuku.changedData)
    // console.log('checked', checked)
    },
    changeDateInput(dateMoment, dateString, from) {
      if (dateMoment !== null && from[0] === 'modalCetakBuku') {
        this.getDatatableModalBuku()
      }
    },
    async getDatatableModalBuku() {
      var params = {
        rekening: this.norekening,
        tgl_awal: this.modalCetakBuku.tgl1.format('YYYY-MM-DD'),
        tgl_akhir: this.modalCetakBuku.tgl2.format('YYYY-MM-DD'),
      }
      var res = await lou.customUrlPost('mutasitabungan/bukutabungan', params, false)
      if (res) {
        this.modalCetakBuku.responseAPI = res.data
        this.modalCetakBuku.datatable = res.data.mutasitabungan
        this.modalCetakBuku.saldoAwal = lou.curency(this.modalCetakBuku.responseAPI.saldoawal)
        this.modalCetakBuku.saldoAkhir = lou.curency(this.modalCetakBuku.responseAPI.saldoakhir)
        this.modalCetakBuku.barisCetak = this.modalCetakBuku.responseAPI.bariscetak
        this.modalCetakBuku.datatable.forEach((element) => {
          element.selected = element.statusprinter === '0'
        })
      }
    },
    handleChange(value, from) {
    // console.log('value', value)
      if (value.key !== null && value.key !== undefined) {
        var dataTarget = this.dataRekening[value.key]
        var data = {
          selectedName: value,
          selectedRekening: value,
          rekeningjurnal: dataTarget.rekening,
          norekening: dataTarget.rekening,
          namaprekening: dataTarget.nama,
          saldoprekening: dataTarget.saldo,
          // dataRekening: [],
          fetching: false,
        }
        Object.assign(this, data)
        this.alamatprekening = dataTarget.alamat
        this.modalCetakBuku.nama = this.namaprekening
        this.modalCetakBuku.alamat = this.alamatprekening
        this.getDatatableModalBuku()
      }
    },
    selectAllModalCetak() {
      this.modalCetakBuku.datatable.forEach((element) => {
        element.selected = this.modalCetakBuku.selectallmode
      })
      if (this.modalCetakBuku.selectallmode) {
        this.modalCetakBuku.changedData = this.$g.clone(this.modalCetakBuku.datatable)
      } else {
        this.modalCetakBuku.changedData = []
      }
      this.modalCetakBuku.selectallmode = !this.modalCetakBuku.selectallmode
    },
    async generateBuku() {
      this.modalCetakBuku.changedData = []
      this.modalCetakBuku.datatable.forEach(element => {
        if (element.selected) {
          this.modalCetakBuku.changedData.push(element)
        }
      })
      if (this.modalCetakBuku.changedData.lenght === 0) {
        lou.shownotif('Data', 'Tidak memilih data untuk di cetak!')
      } else {
        const doc = new JSPDF()
        doc.setFontSize(10)
        var leftMargin = 2
        var topMargin = 27
        // console.log('this.modalCetakBuku', this.modalCetakBuku.changedData)
        var nourut = this.modalCetakBuku.barisCetak !== 0 ? this.modalCetakBuku.barisCetak.toString() : 1
        var tambahanjarak = 5 * this.modalCetakBuku.barisCetak
        var jarak = 0 + tambahanjarak
        for (let i = 0; i < this.modalCetakBuku.changedData.length; i++) {
          const element = this.modalCetakBuku.changedData[i]
          // console.log('element', element)
          doc.text(nourut.toString(), leftMargin, topMargin + jarak)
          doc.text(moment(element.tgl, 'YYYY-MM-DD').format('DD-MM-YYYY'), leftMargin + 8, topMargin + jarak)
          doc.text(element.kodetransaksi.toString(), leftMargin + 40, topMargin + jarak)
          var mutasi = element.debet === 0 ? lou.curency(element.kredit) : lou.curency(element.debet)
          doc.text(mutasi.toString() + '.00', leftMargin + 60, topMargin + jarak)
          doc.text(lou.curency(element.jumlah).toString() + '.00', leftMargin + 100, topMargin + jarak)
          doc.text(element.username.toString(), leftMargin + 140, topMargin + jarak)
          nourut++
          jarak += 5
        }
        // var filename = 'Buku'
        doc.output('dataurlnewwindow')
        var datapost = []
        this.modalCetakBuku.changedData.forEach(element => {
          datapost.push({ id: element.id, statusprinter: '1' })
        })
        var res = await lou.customUrlPost('mutasitabungan/statuscetak', datapost)
        if (res) {
          this.modalCetakBuku.datatable.forEach(element => {
            var targetIndex = this.modalCetakBuku.changedData.findIndex(x => x.id === element.id)
            if (targetIndex) {
              element.statusprinter = '1'
            }
          })
          this.componentKeyDatatable++
          this.componentKey++
          this.modalCetakBuku.barisCetak += this.modalCetakBuku.changedData.length
        }
      }
    },
    async fetchUser(value) {
    // console.log('fetching user', value)
      if (value !== '') {
        this.lastFetchId += 1
        const fetchId = this.lastFetchId
        this.dataRekening = []
        this.fetching = true
        var res = false
        if (!this.hasNumber(value)) {
          var nvalue = value.replace(/[^\w\s]/gi, '')
          res = await lou.customUrlGet('mutasitabungan/cari?Nama=' + nvalue)
        } else {
          var nvalue1 = value.replace(/[0-9]/g, '')
          res = await lou.customUrlGet('mutasitabungan/cari_rekening/' + nvalue1)
        }
        if (res) {
          if (fetchId !== this.lastFetchId) {
          // for fetch callback order
            return false
          }
          this.dataRekening = res.data
          this.fetching = false
        } else {
          this.fetching = null
        }
      }
    },
    formatTanggal(v) {
      var ntgl = v.tgl.substring(0, 10)
      // console.log('ntgl', ntgl)
      return moment(ntgl, 'YYYY-MM-DD').format('DD-MM-YYYY')
    },
    hasNumber(myString) {
      return /\d/.test(myString)
    },
    formatCurrency(v) {
      return lou.curency(v)
    },
  },
}
</script>
