<template>
  <div>
    <a-card>
      <template slot="title">
        <strong>Form Realisasi Kredit</strong>
        <a-button size="default" type="danger" class="ml-2" @click="resetForm"
          >Back</a-button
        >
      </template>
      <div class="content">
        <div class="row pl-3 pr-3 pt-3 pb-3">
          <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
            <div class="row">
              <div
                class="col-xs-3 col-sm-3 col-md-3 col-lg-3"
                v-if="action !== 'create'"
              >
                <label style="font-size: 13px;" class="mt-1">Sudah Cair</label>
              </div>
              <div
                class="col-xs-9 col-sm-9 col-md-9 col-lg-9"
                v-if="action !== 'create'"
              >
                <!-- <a-select
                  class=""
                  v-model="input.sudah_cair"
                  style="width: 200px;"
                >
                  <a-select-option value="true">Sudah Cair</a-select-option>
                  <a-select-option value="false">Belum Cair</a-select-option>
                </a-select> -->
                <a-input
                  v-model="maskInput.sudah_cair"
                  style="width: 150px;"
                  placeholder="Faktur"
                  :readOnly="true"
                ></a-input>
              </div>
              <div
                class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-1"
                v-if="action !== 'create'"
              >
                <label style="font-size: 13px;" class="mt-1">Faktur</label>
              </div>
              <div
                class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1"
                v-if="action !== 'create'"
              >
                <a-input
                  v-model="maskInput.faktur"
                  placeholder="Faktur"
                  :readOnly="true"
                  style="width: 200px;"
                ></a-input>
              </div>
              <!-- <div
                class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-1"
                v-if="action !== 'create'"
              >
                <label style="font-size: 13px;" class="mt-1">Rekening</label>
              </div>
              <div
                class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1"
                v-if="action !== 'create'"
              >
                <a-input
                  placeholder=""
                  v-model="maskInput.rekcabang"
                  style="width: 60px;"
                  :readOnly="false"
                ></a-input>
                <a-input
                  class="ml-2"
                  placeholder=""
                  v-model="maskInput.rerekening"
                  style="width: 60px;"
                  :readOnly="false"
                ></a-input>
                <a-input
                  class="ml-2"
                  placeholder=""
                  v-model="maskInput.rerekening1"
                  style="width: 30%;"
                  :readOnly="false"
                  @keydown.enter="rekeningcheck"
                ></a-input>
                <a-button
                  class="ml-2"
                  @click="carinasabahvisible = !carinasabahvisible"
                >
                  <a-icon type="search"></a-icon>
                </a-button>
              </div> -->
              <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-1">
                <label style="font-size: 13px;" class="mt-1">Register</label>
              </div>
              <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                <a-input
                  placeholder=""
                  v-model="maskInput.cabang"
                  style="width: 60px;"
                  :readOnly="false"
                ></a-input>
                <a-input
                  class="ml-2"
                  placeholder=""
                  v-model="maskInput.reregister"
                  style="width: 50%;"
                  :readOnly="false"
                  @keydown.enter="registeridchanged"
                ></a-input>
                <a-button
                  class="ml-2"
                  @click="carinasabahvisible = !carinasabahvisible"
                >
                  <a-icon type="search"></a-icon>
                </a-button>
              </div>
              <div class="col-sm-3 col-md-3 col-lg-3 mt-1">
                <label class="mt-1">Nama Nasabah</label>
              </div>
              <div class="col-lg-9 col-md-9 col-sm-9 mt-1">
                <a-input
                  placeholder="Nama Nasabah"
                  v-model="maskInput.namanasabah"
                  :readOnly="true"
                ></a-input>
              </div>
              <div class="col-sm-3 col-md-3 col-lg-3 mt-1">
                <label class="mt-1">Alamat Nasabah</label>
              </div>
              <div class="col-lg-9 col-md-9 col-sm-9 mt-1">
                <a-input
                  placeholder="Alamat Nasabah"
                  v-model="maskInput.alamatnasabah"
                  :readOnly="true"
                ></a-input>
              </div>
              <div class="col-sm-3 col-md-3 col-lg-3 mt-1">
                <label class="mt-1">Telepon</label>
              </div>
              <div class="col-lg-9 col-md-9 col-sm-9 mt-1">
                <a-input
                  placeholder="Telepon"
                  v-model="maskInput.telepon"
                  :readOnly="true"
                ></a-input>
              </div>
              <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-1">
                <label style="font-size: 13px;" class="mt-1"
                  >Golongan Kredit</label
                >
              </div>
              <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                <a-select
                  show-search
                  option-filter-prop="children"
                  :filter-option="filterOption"
                  v-model="input.golongankredit_id"
                  style="width: 100%;"
                >
                  <a-select-option
                    v-for="(data, index) in mastergolonganKredit"
                    :value="data.id"
                    :key="index"
                    >{{ data.kode + " - " + data.keterangan }}</a-select-option
                  >
                </a-select>
              </div>
              <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-1">
                <label style="font-size: 13px;" class="mt-1">No SPK</label>
              </div>
              <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                <a-input v-model="input.no_spk" placeholder="No SPK"></a-input>
              </div>
              <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-1">
                <label style="font-size: 13px;" class="mt-1">Tanggal</label>
              </div>
              <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                <a-date-picker
                  style="width: 130px;"
                  v-model="input.tgl"
                  :allowClear="false"
                  format="DD-MM-YYYY"
                />
              </div>
              <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-1">
                <label style="font-size: 13px;" class="mt-1"
                  >Cara Perhitungan</label
                >
              </div>
              <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                <a-select
                  class=""
                  v-model="input.caraperhitungan"
                  style="width: 200px;"
                  @change="isiGridAngsuran(false)"
                >
                  <a-select-option
                    v-for="(data, index) in allMaster.caraperhitungan"
                    :key="index"
                    :value="data.kode"
                    >{{ data.keterangan }}</a-select-option
                  >
                </a-select>
              </div>
              <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-1">
                <label style="font-size: 13px;" class="mt-1"
                  >Jangka Waktu</label
                >
              </div>
              <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                <cleave
                  :options="{ numeral: true }"
                  class="ant-input mr-1"
                  :raw="true"
                  v-model.lazy="input.jw"
                  style="width: 130px;"
                  placeholder="Jangka Waktu"
                  @blur="isiGridAngsuran(false)"
                />
                <!-- @input="cleaveInput" -->
                Bulan
              </div>
              <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-1">
                <label style="font-size: 13px;" class="mt-1">Suku Bunga</label>
              </div>
              <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                <cleave
                  :options="{ numeral: true }"
                  class="ant-input mr-1"
                  style="width: 130px;"
                  :raw="true"
                  v-model.lazy="input.sukubunga"
                  placeholder="Suku Bunga"
                />% Per tahun
                <!-- @input="cleaveInput" -->
              </div>
              <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-1">
                <label style="font-size: 13px;" class="mt-1">Plafond</label>
              </div>
              <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                <cleave
                  :options="{ numeral: true }"
                  class="ant-input"
                  :raw="true"
                  style="width: 200px;"
                  v-model.lazy="input.plafond"
                  placeholder="Plafond"
                />
                <!-- @input="cleaveInput" -->
              </div>
              <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-1">
                <label style="font-size: 13px;" class="mt-1">Total Bunga</label>
              </div>
              <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                <cleave
                  :options="{ numeral: true }"
                  class="ant-input"
                  :raw="true"
                  style="width: 200px;"
                  v-model.lazy="input.totalbunga"
                  @keydown.native.tab="totalbungatab"
                  placeholder="Total Bunga"
                />
                <!-- @input="cleaveInput" -->
              </div>
            </div>
          </div>
          <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
            <a-button
              class="btn btn-primary btn-warning"
              @click="isiGridAngsuran(true)"
              v-if="action === 'update'"
              >Generate Jadwal</a-button
            >
            <ag-grid-vue
              style="height: 41vh;"
              :class="
                settings.theme === 'dark'
                  ? 'ag-theme-balham-dark mt-2'
                  : 'ag-theme-balham mt-2'
              "
              :key="componentKey"
              :gridOptions="gridOptions"
              :columnDefs="columnDefs"
              :pagination="allowPagination"
              :paginationPageSize="20"
              :cacheQuickFilter="true"
              :quickFilterText="tablequickfilter"
              :accentedSort="false"
              :sortingOrder="['asc', 'desc']"
              :rowData="rowData"
              @grid-ready="onGridReady"
              :singleClickEdit="false"
              @cell-value-changed="onCellValueChanged"
              :undoRedoCellEditing="true"
              :undoRedoCellEditingLimit="5"
              :frameworkComponents="frameworkComponents"
              @first-data-rendered="onFirstDataRendered"
              rowSelection="single"
              @selection-changed="onSelectionChanged"
              :rowClassRules="rowClassRules"
            >
            </ag-grid-vue>
          </div>
          <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
            <div class="row">
              <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-1">
                <label style="font-size: 13px;" class="mt-1">AO</label>
              </div>
              <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                <a-select
                  ref="selectao"
                  show-search
                  option-filter-prop="children"
                  :filter-option="filterOption"
                  v-model="input.ao_id"
                  style="width: 70%;"
                >
                  <a-select-option
                    v-for="(data, index) in masterAO"
                    :key="index"
                    :value="data.id"
                    >{{ data.kode + " - " + data.nama }}</a-select-option
                  >
                </a-select>
              </div>
              <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-1">
                <label style="font-size: 13px;" class="mt-1">Tabungan</label>
              </div>
              <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                <cleave
                  :readOnly="false"
                  :options="{ delimiter: '.', blocks: [2, 2, 6] }"
                  class="ant-input"
                  :raw="false"
                  style="width: 100px;"
                  v-model.lazy="norekening"
                  placeholder="No Rekening"
                  v-on:keydown.enter.native="checkRekening1"
                />
                <a-input :readOnly="true" v-model="maskInput.namatabungan" class="ml-2" style="width: 200px;"></a-input>
                <a-button
                  class="ml-2"
                  @click="carinasabahvisible = !carinasabahvisible"
                >
                  <a-icon type="search"></a-icon>
                </a-button>
              </div>
              <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-1">
                <label style="font-size: 13px;" class="mt-1"
                  >Biaya Administrasi</label
                >
              </div>
              <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                <cleave
                  :options="{ numeral: true }"
                  class="ant-input"
                  :raw="true"
                  style="width: 200px;"
                  v-model="input.biaya_administrasi"
                  placeholder="Biaya Administrasi"
                />
              </div>
              <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-1">
                <label style="font-size: 13px;" class="mt-1"
                  >Biaya Materai</label
                >
              </div>
              <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                <cleave
                  :options="{ numeral: true }"
                  class="ant-input"
                  :raw="true"
                  style="width: 200px;"
                  v-model="input.biaya_materai"
                  placeholder="Biaya Materai"
                />
              </div>
              <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-1">
                <label style="font-size: 13px;" class="mt-1"
                  >Biaya Notaris</label
                >
              </div>
              <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                <cleave
                  :options="{ numeral: true }"
                  class="ant-input"
                  :raw="true"
                  style="width: 200px;"
                  v-model="input.biaya_notaris"
                  placeholder="Biaya Notaris"
                />
              </div>
              <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-1">
                <label style="font-size: 13px;" class="mt-1"
                  >Biaya Asuransi</label
                >
              </div>
              <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                <cleave
                  :options="{ numeral: true }"
                  class="ant-input"
                  :raw="true"
                  style="width: 200px;"
                  v-model="input.biaya_asuransi"
                  placeholder="Biaya Asuransi"
                />
              </div>
              <div
                class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-1"
                v-if="action === 'update' && input.sudah_cair === 'true'"
              >
                <label style="font-size: 13px;" class="mt-1">Save Jadwal</label>
              </div>
              <div
                class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1"
                v-if="action === 'update' && input.sudah_cair === 'true'"
              >
                <a-select
                  class=""
                  v-model="input.save_jadwal"
                  style="width: 200px;"
                >
                  <a-select-option value="true">Save</a-select-option>
                  <a-select-option value="false">Tidak</a-select-option>
                </a-select>
              </div>
            </div>
          </div>
          <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
            <div class="row">
              <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 mt-1">
                <label style="font-size: 13px;" class="mt-1">Jaminan</label>
              </div>
              <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8 mt-1">
                <a-input
                  v-model="input.jaminan"
                  placeholder="Jaminan"
                ></a-input>
              </div>
              <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 mt-1">
                <label style="font-size: 13px;" class="mt-1">Nilai Pasar</label>
              </div>
              <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8 mt-1">
                <cleave
                  :options="{ numeral: true }"
                  class="ant-input"
                  :raw="true"
                  style="width: 200px;"
                  v-model="input.nilai_pasar"
                  placeholder="Nilai Pasar"
                />
              </div>
              <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 mt-1">
                <label style="font-size: 13px;" class="mt-1"
                  >Nilai Taksasi</label
                >
              </div>
              <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8 mt-1">
                <cleave
                  :options="{ numeral: true }"
                  class="ant-input"
                  :raw="true"
                  style="width: 200px;"
                  v-model="input.nilai_taksasi"
                  placeholder="Nilai Taksasi"
                />
              </div>
              <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 mt-1">
                <label style="font-size: 13px;" class="mt-1"
                  >Nama Pasangan</label
                >
              </div>
              <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8 mt-1">
                <a-input
                  v-model="input.nama_pasangan"
                  placeholder="Nama Pasangan"
                ></a-input>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-1">
                <a-button
                  size="default"
                  type="primary"
                  class="ml-2 mr-5 float-right"
                  @click="handleSubmit"
                  >Submit</a-button
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </a-card>
    <a-modal
      v-model="carinasabahvisible"
      :dialogStyle="{ top: '10px' }"
      :footer="null"
      :width="800"
    >
      <template slot="title">
        <strong>{{ carinasabahtitle }} </strong>
        <label style="margin: unset;" v-if="settings.selectedRekening !== ''">
          | Active Rekening: {{ settings.selectedRekening }}</label
        >
      </template>
      <!-- <template slot="footer">
          <a-button class="btn btn-outline-dark">Cancel</a-button>
          <a-button class="btn btn-outline-primary">Process</a-button>
        </template> -->
      <div class="row">
        <a-input
          class="ml-3"
          placeholder="Ketik Nama"
          v-model="inputModal.nama"
          style="width: 200px;"
          @keydown.enter="findData"
        ></a-input>
        <a-input
          class="ml-2"
          placeholder="Ketik Alamat"
          v-model="inputModal.alamat"
          style="width: 200px;"
          @keydown.enter="findData"
        ></a-input>
        <a-select
          style="width: 70px;"
          placeholder="Kode"
          class="ml-2"
          v-model="inputModal.cabang"
        >
          <a-select-option value="01">01</a-select-option>
        </a-select>
        <a-button
          v-if="settings.selectedRekening !== ''"
          class="btn btn-outline-danger btn-sm ml-2"
          @click="clearRekening"
          >Clear Selected Rekening</a-button
        >
        <a-divider type="horizontal" style="margin: 8px 8px;" />
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
          <label
            v-if="
              table.tableatas.datatable.length !== null &&
              table.tableatas.datatable.length !== 0
            "
            class="text-primary"
            style="
              margin-left: 0.5rem;
              margin-top: 0.5rem;
              margin-bottom: unset !important;
            "
            >Jumlah Data: {{ table.tableatas.datatable.length }}</label
          >
          <a-table
            :scroll="{ y: 300 }"
            :columns="table.tableatas.column"
            :dataSource="table.tableatas.datatable"
            :pagination="false"
            size="small"
            :customRow="customRow"
          >
          </a-table>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-2">
          <label
            v-if="
              table.tablebawah.datatable.length !== null &&
              table.tablebawah.datatable.length !== 0
            "
            class="text-primary"
            style="
              margin-left: 0.5rem;
              margin-top: 0.5rem;
              margin-bottom: unset !important;
            "
            >Jumlah Data: {{ table.tablebawah.datatable.length }}</label
          >
          <a-table
            :scroll="{ y: 200 }"
            :columns="table.tablebawah.column"
            :dataSource="table.tablebawah.datatable"
            :pagination="false"
            size="small"
            :customRow="customRowB"
          >
            <span slot="saldo" slot-scope="text">
              {{ formatCurrency(text) }}
            </span>
          </a-table>
        </div>
      </div>
      <!-- <lou-cari-nasabah /> -->
    </a-modal>
  </div>
</template>

<script>
// import { Modal } from 'ant-design-vue'
import moment from 'moment'
import * as lou from '@/services/data/lou'
import { mapState } from 'vuex'
import numericInput from './component/numeric'
import selectInput from './component/select'
import dateInput from './component/date'
import tooltip from './component/tooltip'
import htmlrender from './component/htmlrender'
import Cleave from 'vue-cleave-component'
import debounce from 'lodash/debounce'
import { Modal } from 'ant-design-vue'
// import LouCariNasabah from './CariNasabah'

export default {
  computed: mapState(['settings', 'user']),
  components: {
    // LouCariNasabah,
    cleave: Cleave,
  },
  watch: {
    'settings.selectedRekening'() {
      this.checkRekening()
    },
    'input.totalbunga'() {
      this.cleaveInput()
    },
    'input.jw'() {
      this.cleaveInput1()
    },
    'input.sukubunga'() {
      this.cleaveInput1()
      this.cleaveInput()
    },
    'input.plafond'() {
      this.cleaveInput1()
      this.cleaveInput()
    },
    'settings.selectedKodeRegister'() {
      this.input.register = this.settings.selectedKodeRegister
      this.maskInput.cabang = this.settings.selectedKodeRegister.slice(
        0,
        this.settings.selectedKodeRegister.indexOf('.'),
      )
      this.maskInput.reregister = this.settings.selectedKodeRegister.slice(
        this.settings.selectedKodeRegister.indexOf('.') + 1,
        this.settings.selectedKodeRegister.length,
      )
      this.registeridchanged()
    },
  },
  data() {
    return {
      editdata: JSON.parse(
        localStorage.getItem('tabungankueditrealisasikreditdata'),
      ),
      norekening: '',
      action: null,
      title: 'Register Nasabah',
      fetching: false,
      loadingMaster: false,
      updateJadwal: false,

      // Property ag-grid
      mode: 'netral',
      theme: this.$store.state.settings.theme,
      gridOptions: null,
      selectedData: null,
      gridApi: null,
      backupRowData: [],
      changedData: [],
      rowData: [],
      tablequickfilter: '',
      allowPagination: false,
      columnDefs: [
        {
          headerName: 'Tgl',
          field: 'tgl',
          editable: true,
          resizable: true,
          sortable: true,
          valueFormatter: this.dateFormatter,
        },
        {
          headerName: 'Pokok',
          field: 'pokok',
          editable: true,
          resizable: true,
          sortable: true,
          valueFormatter: this.currencyFormatter,
          type: 'rightAligned',
        },
        {
          headerName: 'Bunga',
          field: 'bunga',
          editable: true,
          resizable: true,
          sortable: true,
          valueFormatter: this.currencyFormatter,
          type: 'rightAligned',
        },
        {
          headerName: 'Total',
          field: 'total',
          editable: true,
          resizable: true,
          sortable: true,
          valueFormatter: this.currencyFormatter,
          type: 'rightAligned',
        },
        // {
        //   headerName: 'Keterangan',
        //   field: 'keterangan',
        //   editable: true,
        //   resizable: true,
        //   sortable: true,
        // },
      ],
      frameworkComponents: {
        numericEditor: numericInput,
        dateEditor: dateInput,
        selectEditor: selectInput,
        customTooltip: tooltip,
        htmlRender: htmlrender,
      },
      rowClassRules: {
        'text-success': (params) => {
          return params.data.newdata !== undefined
        },
        'text-warning': (params) => {
          return params.data.edited !== undefined
        },
        'text-danger': (params) => {
          return params.data.isError !== undefined
        },
        'text-primary': (params) => {
          return params.data.isPrimary !== undefined && params.data.isPrimary === 1
        },
        'font-weight-bold': (params) => {
          return params.data.isBold !== undefined
        },
      },
      // End property

      maskInput: {
        faktur: '',
        sudah_cair: '',
        cabang: '01',
        rekcabang: '01',
        reregister: '',
        rerekening: '',
        rerekening1: '',
        namanasabah: '',
        alamatnasabah: '',
        telepon: '',
        pekerjaan: '',
        noregister: '',
        namatabungan: '',
      },
      input: {
        sudah_cair: '',
        faktur: '',
        rekening: '',
        register_id: '',
        golongankredit_id: '',
        no_spk: '',
        sukubunga: '',
        tgl: moment(),
        plafond: '',
        totalbunga: '',
        jw: '',
        ao_id: '',
        tabungan_id: '',
        biaya_administrasi: '',
        biaya_materai: '',
        biaya_notaris: '',
        biaya_asuransi: '',
        username: '',
        caraperhitungan: '',
        jaminan: '',
        nilai_pasar: '',
        nilai_taksasi: '',
        nama_pasangan: '',
        save_jadwal: true,
      },
      allMaster: {},
      mastergolonganKredit: [],
      backupmastergolonganKredit: [],
      masterregisterNasabah: [],
      backupmasterregisterNasabah: [],
      masterAO: [],
      backupmasterAO: [],
      visible: false,
      loading: false,
      loadingJadwal: false,
      loadingTotalBunga: false,
      datenow: '',
      componentKey: 0,

      // Modal Property
      carinasabahvisible: false,
      carinasabahtitle: 'Cari Nasabah',
      inputModal: {
        nama: '',
        alamat: '',
        cabang: '',
      },
      table: {
        tableatas: {
          column: [
            {
              title: 'Kode',
              rowKey: 'kode',
              dataIndex: 'kode',
              scopedSlots: { customRender: 'kode' },
              width: 100,
            },
            {
              title: 'Nama',
              dataIndex: 'nama',
              scopedSlots: { customRender: 'nama' },
              width: 150,
            },
            // {
            //   title: 'KTP',
            //   dataIndex: 'ktp',
            //   scopedSlots: { customRender: 'ktp' },
            //   width: 100,
            // },
            {
              title: 'Alamat',
              dataIndex: 'alamat',
              scopedSlots: { customRender: 'alamat' },
              // width: 100,
            },
          ],
          datatable: [],
        },
        tablebawah: {
          column: [
            {
              title: 'Jenis',
              dataIndex: 'jenis',
              scopedSlots: { customRender: 'jenis' },
              // width: 100,
            },
            {
              title: 'Rekening',
              dataIndex: 'rekening',
              scopedSlots: { customRender: 'rekening' },
              // width: 100,
            },
            {
              title: 'Saldo',
              dataIndex: 'saldo',
              scopedSlots: { customRender: 'saldo' },
              // width: 100,
            },
          ],
          datatable: [],
        },
      },
    }
  },
  created() {
    this.gridOptions = {}
    this.getMaster()
    // if (this.settings.selectedRekening !== '') {
    //   this.checkRekening()
    // }
    // if (this.settings.selectedKodeRegister !== '') {
    //   this.input.register = this.settings.selectedKodeRegister
    //   this.maskInput.cabang = this.settings.selectedKodeRegister.slice(0, this.settings.selectedKodeRegister.indexOf('.'))
    //   this.maskInput.reregister = this.settings.selectedKodeRegister.slice(this.settings.selectedKodeRegister.indexOf('.') + 1, this.settings.selectedKodeRegister.length)
    //   this.registeridchanged()
    // }
    if (this.editdata.id !== '') {
      this.action = 'update'
      this.title = 'Update Nasabah'
      this.getEditData()
    } else {
      this.action = 'create'
      this.title = 'Create Nasabah'
    }
    // console.log('this.action', this.action)
  },
  methods: {
    moment,
    totalbungatab() {
      console.log('tab')
      this.$refs.selectao.$el.focus()
    },
    cleaveInput1: debounce(function () {
      this.getTotalBunga()
    }, 700),
    cleaveInput: debounce(function () {
      this.isiGridAngsuran()
    }, 700),
    isiGridAngsuran(force = false) {
      // console.log('force', force)
      if (force) {
        // this.updateJadwal = true
        this.hitGrid()
      } else if (!force) {
        if (!this.editdata.sudah_cair) {
          this.hitGrid()
        }
      }
    },
    async hitGrid() {
      if (!this.loadingJadwal) {
        if (
          this.input.tgl !== '' &&
          this.input.caraperhitungan !== '' &&
          this.input.sukubunga !== '' &&
          this.input.plafond !== '' &&
          this.input.jw !== '' &&
          this.input.totalbunga !== ''
        ) {
          this.loadingJadwal = true
          var res = await lou.customUrlGet2(
            'data_kredit/hitung_jadwal?tglrealisasi=' +
              this.input.tgl.format('YYYY-MM-DD') +
              '&jw=' +
              this.input.jw +
              '&caraperhitungan=' +
              this.input.caraperhitungan +
              '&sukubunga=' +
              this.input.sukubunga +
              '&plafond=' +
              this.input.plafond +
              '&totalbunga=' +
              this.input.totalbunga +
              '',
          )
          if (res) {
            this.input.jadwalAngsurans = res.data.jadwal
            this.rowData = this.$g.clone(res.data.jadwal)
            var total = {
              tgl: 'Total',
              keterangan: '',
              ...res.data.total,
            }
            this.rowData.push(total)
          }
          this.loadingJadwal = false
        }
      }
    },
    async getTotalBunga() {
      // console.log('this.loadingTotalBunga', this.loadingTotalBunga)
      if (!this.loadingTotalBunga) {
        if (
          this.input.plafond !== '' &&
          this.input.sukubunga !== '' &&
          this.input.jw !== ''
        ) {
          this.loadingTotalBunga = true
          var res = await lou.customUrlGet2(
            'data_kredit/add_hitungtotalbunga?plafond=' +
              this.input.plafond +
              '&sukubunga=' +
              this.input.sukubunga +
              '&jangkawaktu=' +
              this.input.jw,
          )
          if (res) {
            this.input.totalbunga = res.data
          }
          this.loadingTotalBunga = false
        }
      }
    },
    hitGrid1() {
      // console.log('isiGridAngsuran')
      if (
        this.input.tgl !== '' &&
        this.input.caraperhitungan !== '' &&
        this.input.sukubunga !== '' &&
        this.input.plafond !== '' &&
        this.input.jw !== '' &&
        this.input.totalbunga !== ''
      ) {
        var data = []
        var jangka = parseInt(this.input.jw)
        var tgl = moment(this.input.tgl, 'YYYY-MM-DD')
        var totalplafond = parseInt(this.input.plafond)
        var totalbunga = parseInt(this.input.totalbunga)
        var temppokok = totalplafond / jangka
        var tempbunga = totalbunga / jangka

        for (let i = 0; i < jangka; i++) {
          // console.log('temppokok', temppokok)
          // console.log('tempbunga', tempbunga)
          var df = {
            tgl: tgl.format('YYYY-MM-DD'),
            pokok: parseFloat(temppokok).toFixed(0),
            bunga: parseFloat(tempbunga).toFixed(0),
            total: parseFloat(temppokok + tempbunga).toFixed(0),
            keterangan: 'Angsuran Kredit ' + this.input.rekening + ' [' + this.maskInput.namanasabah + ']',
          }
          data.push(df)
          tgl = tgl.add(1, 'month')
          totalplafond = totalplafond - temppokok
          totalbunga = totalbunga - tempbunga
        }
        var t1 = 0
        var t2 = 0
        var t3 = 0
        data.forEach((element) => {
          // console.log('element.pokok', element.pokok)
          // console.log('element.bunga', element.bunga)
          t1 = t1 + parseInt(element.pokok)
          t2 = t2 + parseInt(element.bunga)
          t3 = t3 + parseInt(element.total)
        })
        var df1 = {
          tgl: 'Total',
          pokok: t1,
          bunga: t2,
          total: '',
          keterangan: '',
        }
        data.push(df1)
        // console.log('data', data)
        this.rowData = data
        this.jadwalAngsurans = data
      }
    },
    async rekeningcheck() {
      var res = await lou.customUrlGet2(
        'data_kredit/rekening/' +
          this.maskInput.rekcabang +
          '.' +
          this.maskInput.rerekening +
          '.' +
          this.maskInput.rerekening1,
      )
      if (res) {
        if (res.data !== null) {
          lou.shownotif('Ditemukan!', 'Rekening ditemukan.')
        } else {
          lou.shownotif(
            'Tidak ditemukan!',
            'Rekening tidak ditemukan.',
            'error',
          )
        }
      }
    },
    async checkRekening1() {
      var res = await lou.customUrlGet2(
        'data_kredit/tabungan/' + this.norekening,
      )
      if (res) {
        if (res.data !== null) {
          // console.log('res.data', res.data)
          this.input.tabungan_id = res.data.id
          this.maskInput.namatabungan = res.data.nama
          lou.shownotif('Ditemukan!', 'Rekening ditemukan.')
        } else {
          lou.shownotif(
            'Tidak ditemukan!',
            'Rekening tidak ditemukan.',
            'error',
          )
        }
      }
    },
    async checkRekening() {
      this.norekening = this.settings.selectedRekening
      this.checkRekening1()
      // this.input.rekening = this.settings.selectedRekening
      // var text = this.settings.selectedRekening
      // this.maskInput.rekcabang = text.slice(0, text.indexOf('.'))
      // this.maskInput.rerekening = text.slice(
      //   text.indexOf('.') + 1,
      //   text.indexOf('.') + 3,
      // )
      // var ntext = text.replace('.', '')
      // // console.log('ntext', ntext.slice(ntext.indexOf('.') + 1, ntext.length))
      // this.maskInput.rerekening1 = ntext.slice(
      //   ntext.indexOf('.') + 1,
      //   ntext.length,
      // )
    },
    async registeridchanged() {
      var res = await lou.customUrlGet2(
        'registernasabah/kode/' +
          this.maskInput.cabang +
          '.' +
          this.maskInput.reregister,
      )
      if (res) {
        if (res.data !== null) {
          // lou.shownotif('Ditemukan!', 'Data register ditemukan.')

          // console.log('res.data', res.data)
          this.input.register_id = res.data.id
          this.input.register = res.data.kode
          this.maskInput.namanasabah = res.data.nama
          this.maskInput.alamatnasabah = res.data.alamat
          this.maskInput.telepon = res.data.hp
          this.maskInput.pekerjaan = res.data.pekerjaan
        } else {
          lou.shownotif(
            'Tidak ditemukan!',
            'Data register tidak ditemukan.',
            'error',
          )

          this.input.register_id = ''
          this.input.register = ''
          this.maskInput.namanasabah = ''
          this.maskInput.alamatnasabah = ''
          this.maskInput.telepon = ''
          this.maskInput.pekerjaan = ''
        }
      }
    },
    changeDateInput(dateMoment, dateString, arrdata) {
      this[arrdata[0]][arrdata[1]] = dateMoment
      // console.log('dateMoment', dateMoment)
      // console.log('dateString', dateString)
      // console.log('arrdata', arrdata)
    },
    async getMaster() {
      var indexeddb = await lou.getDataFromDb()
      // console.log('indexeddb', indexeddb)
      // console.log('this.settings.whatapp', this.settings.whatapp)
      var allmaster = {}
      var masterIndex = indexeddb.findIndex(
        (x) =>
          x.mykey ===
          'allmaster' +
            this.settings.whatapp.appName.toLowerCase() +
            this.settings.whatapp.id,
      )
      // console.log('masterIndex', indexeddb[masterIndex])
      if (masterIndex < 0) {
        var res = await lou.customUrlGet2('master', false, true)
        var master = {
          mykey:
            'allmaster' +
            this.settings.whatapp.appName.toLowerCase() +
            this.settings.whatapp.id,
          data: JSON.stringify(res.data),
        }
        await lou.addDataToDb(master)
        var nindexeddb = await lou.getDataFromDb()
        var targetIndex = nindexeddb.findIndex(
          (x) =>
            x.mykey ===
            'allmaster' +
              this.settings.whatapp.appName.toLowerCase() +
              this.settings.whatapp.id,
        )
        // console.log('nindexeddb', nindexeddb[targetIndex])
        allmaster = JSON.parse(nindexeddb[targetIndex].data)
      } else {
        allmaster = JSON.parse(indexeddb[masterIndex].data)
      }
      var cabanglist = []
      for (let u = 0; u < this.user.cabangList.length; u++) {
        const element = this.user.cabangList[u]
        // console.log('element', element)
        cabanglist.push({ kode: element.kode, keterangan: element.kode })
      }
      // console.log('indexeddb', indexeddb)
      this.allMaster = allmaster
      // console.log('this.allMaster', this.allMaster)

      var response1 = await lou.customUrlGet2('ao')
      if (response1) {
        this.masterAO = this.$g.clone(response1.data)
        this.backupmasterAO = this.$g.clone(response1.data)
        // console.log('this.backupmasterKota', this.backupmasterKota)
      }
      var response = await lou.customUrlGet2('golongankredit')
      if (response) {
        this.mastergolonganKredit = this.$g.clone(response.data)
        this.backupmastergolonganKredit = this.$g.clone(response.data)
        // console.log('this.mastergolonganKredit', this.mastergolonganKredit)
        // console.log('this.backupmasterKota', this.backupmasterKota)
      }
    },
    handleSubmit() {
      Modal.confirm({
        title: 'Apakah anda yakin?',
        content: 'Data akan disimpan!',
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'No',
        onOk: async () => {
          // Should format date value before submit.
          var res = false
          var fd = this.$g.clone(this.input)
          // console.log('fd', fd)
          fd.tgl =
        this.input.tgl !== null ? this.input.tgl.format('YYYY-MM-DD') : null
          fd.sudah_cair = this.input.sudah_cair === 'true'
          fd.save_jadwal = this.input.save_jadwal === 'true'
          if (this.action === 'update') {
            res = await lou.customUrlPut2('data_kredit', fd, true)
          } else {
            delete fd.id
            fd.save_jadwal = true
            res = await lou.customUrlPost2('data_kredit', fd, false)
          }
          if (res) {
            if (this.action === 'create') {
              lou.shownotif(
                'Success',
                'Data baru Berhasil ditambahkan dengan nomor rekening: ' + res.data,
                'success',
              )
            }
            this.resetForm()
          }
        },
        onCancel: () => {
          // console.log('Cancel')
          return false
        },
      })
    },
    getEditData() {
      // console.log('this.editdata', this.editdata)
      // var res = await lou.customUrlGet2('data_kredit', { where: { id: this.editdata.id } })
      this.input = this.$g.clone(this.editdata)
      this.maskInput.cabang = this.editdata.register.slice(
        0,
        this.editdata.register.indexOf('.'),
      )
      this.maskInput.reregister = this.editdata.register.slice(
        this.editdata.register.indexOf('.') + 1,
        this.editdata.register.length,
      )
      this.registeridchanged()
      // console.log('this.input.sudah_cair', this.input.sudah_cair)
      this.input.sudah_cair = this.editdata.sudah_cair.toString()
      this.maskInput.sudah_cair = this.editdata.sudah_cair
        ? 'Sudah Cair'
        : 'Belum Cair'
      this.maskInput.faktur = this.editdata.faktur
      // console.log('this.input.sudah_cair', this.input.sudah_cair)
      this.input.save_jadwal = 'true'
      this.input.tgl = moment(this.editdata.tgl, 'YYYY-MM-DD')
      // this.input.tgllunas = moment(this.editdata.tgllunas, 'YYYY-MM-DD')
      // this.isiGridAngsuran()
      var text = this.editdata.rekening
      this.maskInput.rekcabang = text.slice(0, text.indexOf('.'))
      this.maskInput.rerekening = text.slice(
        text.indexOf('.') + 1,
        text.indexOf('.') + 3,
      )
      var ntext = text.replace('.', '')
      // console.log('ntext', ntext.slice(ntext.indexOf('.') + 1, ntext.length))
      this.maskInput.rerekening1 = ntext.slice(
        ntext.indexOf('.') + 1,
        ntext.length,
      )
      // console.log('ntext', ntext)
      // console.log('this.editdata', this.editdata)
    },
    resetForm() {
      localStorage.removeItem('tabungankueditrealisasikreditdata')
      this.input = {
        sudah_cair: '',
        faktur: '',
        rekening: '',
        register_id: '',
        golongankredit_id: '',
        no_spk: '',
        sukubunga: '',
        tgl: moment(),
        plafond: '',
        totalbunga: '',
        jw: '',
        ao_id: '',
        tabungan_id: '',
        biaya_administrasi: '',
        biaya_materai: '',
        biaya_notaris: '',
        biaya_asuransi: '',
        username: '',
        caraperhitungan: '',
        jaminan: '',
        nilai_pasar: '',
        nilai_taksasi: '',
        nama_pasangan: '',
        save_jadwal: true,
      }
      this.$router.push('/tabungannku/realisasikredit/data')
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      )
    },

    // Modal function
    clearRekening() {
      this.settings.selectedRekening = ''
    },
    async findData() {
      var imbuhan = ''
      imbuhan +=
        this.inputModal.nama !== '' ? '?Nama=' + this.inputModal.nama : ''
      imbuhan +=
        this.inputModal.alamat !== ''
          ? this.inputModal.nama !== ''
            ? '&Alamat=' + this.inputModal.alamat
            : '?Alamat=' + this.inputModal.alamat
          : ''
      imbuhan +=
        this.inputModal.cabang !== ''
          ? '&cabang=' + this.inputModal.cabang
          : ''
      var res = await lou.customUrlGet2('carinasabah' + imbuhan)
      if (res) {
        this.table.tableatas.datatable = res.data
        // for (let i = 0; i < 20; i++) {
        //   const element = res.data[0]
        //   this.table.tableatas.datatable.push(element)
        // }
      }
    },
    async nasabahDetail(record) {
      this.loading = true
      var res = await lou.customUrlGet2('carinasabah/detail/' + record.kode)
      if (res) {
        this.maskInput.cabang = record.kode.slice(0, record.kode.indexOf('.'))
        this.maskInput.reregister = record.kode.slice(
          record.kode.indexOf('.') + 1,
          record.kode.length,
        )
        this.registeridchanged()
        // console.log('res.data', res.data)
        this.table.tablebawah.datatable = res.data.length !== 0 ? res.data.filter(x => x.jenis === 'Tabungan') : []
        this.loading = false
      }
    },
    customRow(record, index) {
      return {
        on: {
          click: (event) => {
            // console.log('record', record)
            // console.log('index', index)
            // console.log('event', event)
            this.settings.selectedKodeRegister = record.kode
            this.nasabahDetail(record)
          },
          contextmenu: (event) => {
            event.preventDefault()
          },
        },
      }
    },
    customRowB(record, index) {
      return {
        on: {
          click: (event) => {
            // console.log('record', record)
            this.settings.selectedRekening = record.rekening
            // console.log('index', index)
            // console.log('event', event)
            // console.log('this.settings.selectedRekening', this.settings.selectedRekening)
          },
          contextmenu: (event) => {
            event.preventDefault()
          },
        },
      }
    },
    formatCurrency(v) {
      return lou.curency(v)
    },

    // ag-grid function
    onGridReady(params) {
      // params.api.sizeColumnsToFit()
      this.gridApi = params.api
      this.gridColumnApi = params.columnApi
    },
    onCellValueChanged(params) {
      var undoSize = params.api.getCurrentUndoSize()
      this.input.undoInput = undoSize
      this.input.undoInputdisable = undoSize < 1
      var redoSize = params.api.getCurrentRedoSize()
      this.input.redoInput = redoSize
      this.input.redoInputdisable = redoSize < 1
      if (this.mode === 'oncreate') {
        if (params.data.newdata) {
          var target = this.changedData.findIndex(
            (x) => x.id === params.data.id,
          )
          if (target < 0) {
            this.changedData.push(params.data)
          } else {
            this.changedData[target] = params.data
          }
          // console.log('this.changedData', this.changedData)
        } else {
          // eslint-disable-next-line no-redeclare
          var target = this.rowData.findIndex((x) => x.id === params.data.id)
          var backupTarget = this.backupRowData.findIndex(
            (x) => x.id === params.data.id,
          )
          // console.log('this.rowData[target] before:', this.rowData[target])
          this.rowData[target] = this.$g.clone(
            this.backupRowData[backupTarget],
          )
          // console.log('this.rowData[target] after:', this.rowData[target])
          // console.log('this.backupRowData[backupTarget]', this.backupRowData[backupTarget])
          // this.componentKey += 1
          this.changedData = []
          this.input.undoInput = 0
          this.input.undoInputdisable = true

          this.input.redoInput = 0
          this.input.redoInputdisable = true
          lou.shownotif('Anda sedang dalam mode create')
        }
      } else {
        this.mode = 'onupdate'
        // eslint-disable-next-line no-redeclare
        var targetChanged = this.changedData.findIndex(
          (x) => x.id === params.data.id,
        )
        if (targetChanged < 0) {
          // var cd = {}
          // cd.id = params.data.id
          // cd[params.column.colId] = value.value
          // console.log('cd[params.column.colId]', cd)
          // console.log('value.value', value)
          this.changedData.push(params.data)
        } else {
          this.changedData[targetChanged][params.column.colId] =
            params.newValue
        }
        // console.log('this.changedData', this.changedData)
        // this.componentKey += 1
      }
      // console.log('this.mode', this.mode)
    },
    onSelectionChanged() {
      var selectedRows = this.gridApi.getSelectedRows()
      this.selectedData = selectedRows
      // console.log('selectedRows', selectedRows)
    },
    onFirstDataRendered() {
      this.autoSizeAll(false, { autosizecolumn: true })
    },
    autoSizeAll(skipHeader, { autosizecolumn = true }) {
      if (autosizecolumn) {
        var allColumnIds = []
        // console.log('this.gridColumnApi', this.gridColumnApi)
        this.gridColumnApi.getAllColumns().forEach(function (column) {
          allColumnIds.push(column.colId)
        })
        this.gridColumnApi.autoSizeColumns(allColumnIds, skipHeader)
      } else {
        this.gridApi.sizeColumnsToFit()
      }
    },
    dateFormatter(params) {
      var ret =
        params.value === null
          ? ''
          : moment(params.value, 'YYYY-MM-DD').format('DD-MM-YYYY')
      // console.log('dateFormatter', ret)
      return ret !== 'Invalid date' ? ret : params.value
    },
    currencyFormatter(params) {
      var ret =
        params.value === null
          ? 'NaN'
          : lou.curency(parseFloat(params.value).toFixed(0))
      return ret === 'NaN' ? '' : ret
    },
  },
}
</script>

<style lang="scss" scoped></style>
