<template>
  <div>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-sm-6 col-md-6 col-lg-6 mb-3">
                <strong>Shift</strong>
              </div>
              <div class="col-sm-6 col-md-6 col-lg-6 mb-3">
                <a-button
                  @click="sendToForm('Create', {})"
                  class="btn btn-outline-primary float-right"
                  >Tambah</a-button
                >
              </div>
              <div class="col-sm-12 col-md-12 col-lg-12">
                <a-table
                  :columns="columns"
                  :dataSource="datatable"
                  style="margin-left: -26px; margin-right: -26px;"
                  size="small"
                  :pagination="{
                    defaultPageSize: 10,
                    showQuickJumper: true,
                    showSizeChanger: true,
                    showTotal: (total) => `Total ${total} items`,
                    pageSizeOptions: ['10', '20', '30'],
                  }"
                >
                  <div
                    slot="filterDropdown"
                    slot-scope="{
                      setSelectedKeys,
                      selectedKeys,
                      confirm,
                      clearFilters,
                      column,
                    }"
                    style="padding: 8px;"
                  >
                    <a-input
                      v-ant-ref="(c) => (searchInput = c)"
                      :placeholder="`Search ${column.dataIndex}`"
                      :value="selectedKeys[0]"
                      style="width: 188px; margin-bottom: 8px; display: block;"
                      @change="
                        (e) =>
                          setSelectedKeys(
                            e.target.value ? [e.target.value] : []
                          )
                      "
                      @pressEnter="
                        () =>
                          handleSearch(selectedKeys, confirm, column.dataIndex)
                      "
                    />
                    <a-button
                      type="primary"
                      icon="search"
                      size="small"
                      style="width: 90px; margin-right: 8px;"
                      @click="
                        () =>
                          handleSearch(selectedKeys, confirm, column.dataIndex)
                      "
                    >
                      Search
                    </a-button>
                    <a-button
                      size="small"
                      style="width: 90px;"
                      @click="() => handleReset(clearFilters)"
                    >
                      Reset
                    </a-button>
                  </div>
                  <a-icon
                    slot="filterIcon"
                    slot-scope="filtered"
                    type="search"
                    :style="{ color: filtered ? '#108ee9' : undefined }"
                  />
                  <template
                    slot="customRender"
                    slot-scope="text, record, index, column"
                  >
                    <span
                      v-if="searchText && searchedColumn === column.dataIndex"
                    >
                      <template
                        v-for="(fragment, i) in text
                          .toString()
                          .split(
                            new RegExp(
                              `(?<=${searchText})|(?=${searchText})`,
                              'i'
                            )
                          )"
                      >
                        <mark
                          v-if="
                            fragment.toLowerCase() === searchText.toLowerCase()
                          "
                          :key="i"
                          class="highlight"
                          >{{ fragment }}</mark
                        >
                        <template v-else>{{ fragment }}</template>
                      </template>
                    </span>
                    <template v-else>
                      {{ text }}
                    </template>
                  </template>
                  <template
                    slot="expandedRowRender"
                    slot-scope="record"
                    style="margin: 0;"
                  >
                    <a-table
                      :bordered="true"
                      :columns="childColumns"
                      :data-source="record.jam_kerja"
                      size="small"
                      :pagination="{
                        hideOnSinglePage: true,
                        defaultPageSize: 10,
                        showQuickJumper: true,
                        showSizeChanger: true,
                        showTotal: (total) => `Total ${total} items`,
                        pageSizeOptions: ['10', '20', '30'],
                      }"
                    >
                    </a-table>
                  </template>
                  <span slot="jumlah_jamkerja" slot-scope="text, record">
                    {{ record.jam_kerja.length }}
                  </span>
                  <span slot="action" slot-scope="text, record, index">
                    <a
                      href="javascript:void(0)"
                      @click="sendToForm('Update', record)"
                      class="text-warning"
                      >Edit</a
                    >
                    <a-divider type="vertical" v-if="index !== 0"/>
                    <a
                      v-if="index !== 0"
                      href="javascript:void(0)"
                      @click="showDeleteConfirm(record)"
                      class="text-danger"
                      >Delete</a
                    >
                  </span>
                </a-table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <popup-form ref="popupform" :key="componentKey" />
  </div>
</template>

<script>
import { Modal } from 'ant-design-vue'
import * as lou from '@/services/data/lou'
import popup from './form'

export default {
  components: {
    'popup-form': popup,
  },
  data() {
    return {
      searchText: '',
      searchInput: null,
      searchedColumn: '',
      columns: [
        {
          title: 'Name',
          dataIndex: 'name',
          scopedSlots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
            customRender: 'customRender',
          },
          onFilter: (value, record) =>
            record.name.toString().toLowerCase().includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus()
              }, 0)
            }
          },
        },
        {
          title: 'Jumlah Jam Kerja',
          scopedSlots: {
            customRender: 'jumlah_jamkerja',
          },
        },
        {
          title: 'Action',
          dataIndex: '#',
          scopedSlots: {
            customRender: 'action',
          },
        },
      ],
      childColumns: [
        {
          title: 'Shift',
          dataIndex: 'shift',
          scopedSlots: {
            customRender: 'shift',
          },
        },
        {
          title: 'Hari',
          dataIndex: 'hari',
          scopedSlots: {
            customRender: 'hari',
          },
        },
        {
          title: 'Datang',
          dataIndex: 'datang',
          scopedSlots: {
            customRender: 'datang',
          },
        },
        {
          title: 'Pulang',
          dataIndex: 'pulang',
          scopedSlots: {
            customRender: 'pulang',
          },
        },
      ],
      componentKey: 0,
      datatable: [],
      tablename: 'role',
    }
  },
  created() {
    this.getAllData()
  },
  methods: {
    async getAllData() {
      var response = await lou.readMaster(this.tablename)
      this.datatable = response.data
    },
    sendToForm(action, data = {}) {
      this.$refs.popupform.showModal(action, data)
    },
    showDeleteConfirm(deldata) {
      Modal.confirm({
        title: 'Are you sure delete this data?',
        content: "You can't prevent this!!! ",
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'No',
        onOk: async () => {
          await lou.deleteMaster(this.tablename, deldata.id)
          this.getAllData()
        },
        onCancel: () => {
          // console.log('Cancel')
        },
      })
    },
    handleSearch(selectedKeys, confirm, dataIndex) {
      confirm()
      this.searchText = selectedKeys[0]
      this.searchedColumn = dataIndex
    },

    handleReset(clearFilters) {
      clearFilters()
      this.searchText = ''
    },
    numberFormat(value) {
      return lou.curency(value)
    },
  },
}
</script>

<style></style>
