<template>
  <div>
    <div class="row" v-if="!chatMode">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
        <a-card>
          <div slot="title">
            <strong>Chats</strong>
          </div>
      <div class="demo-infinite-container">
          <a-list size="small" item-layout="horizontal" :data-source="dataDefs">
            <a-list-item slot="renderItem" slot-scope="item" style="cursor: pointer;" v-if="item.messages !== undefined" @click="getConversation(item.id)">
              <a-list-item-meta>
                <strong slot="title" href="https://www.antdv.com/">{{
                  item.id
                }}</strong>
                <p slot="description">{{ getDescription(item) }}</p>
                <a-badge  v-if="item.unreadCount !== 0" slot="avatar" :count="item.unreadCount">
                  <!-- <a href="javascript:void(0)" class="head-example" /> -->
                  <a-avatar
                  class="mt-2"
                    src="https://img.icons8.com/ios-glyphs/256/gender-neutral-user.png"
                  />
                </a-badge>
                <a-avatar v-else
                  slot="avatar"
                  class="mt-2"
                  src="https://img.icons8.com/ios-glyphs/256/gender-neutral-user.png"
                />
                <a-divider type="horizontal"/>
              </a-list-item-meta>
            </a-list-item>
          </a-list>
      </div>
          <!-- <a-table
            class="mt-3"
            size="small"
            :columns="columnDefs"
            :dataSource="dataDefs"
            :pagination="{
              defaultPageSize: 20,
              showQuickJumper: true,
              showSizeChanger: true,
              showTotal: (total) => `Total ${total} items`,
              pageSizeOptions: ['10', '20', '30'],
              hideOnSinglePage: true,
            }"
          >
            <span slot="action" slot-scope="text, record">
              <a
                href="javascript:void(0)"
                @click="deleteSessions(record)"
                class="text-danger"
                >
                <a-icon type="delete"></a-icon>
                </a
              >
            </span>
          </a-table> -->
        </a-card>
      </div>
    </div>

    <div class="row" v-else>
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
        <a-card>
          <div slot="title">
            <!-- <a-avatar class="mr-2" style="cursor: pointer;" @click="chatMode = false">
              <a-icon type="arrow-left"/>
            </a-avatar> -->
            <strong>Conversation</strong>
          </div>
      <div class="demo-infinite-container">
        <a-list size="small" item-layout="horizontal" :data-source="messages">
            <a-list-item slot="renderItem" slot-scope="item" style="border-bottom: unset !important;" class="mt-1">
              <a-list-item-meta v-if="item.message!== undefined && !item.key.fromMe">
                <strong slot="title">{{ item.remoteJid }}</strong>
                <p slot="description">{{ getMessage(item) }}</p>
                <a-avatar
                  slot="avatar"
                  class="mt-2"
                  src="https://img.icons8.com/ios-glyphs/256/gender-neutral-user.png"
                />
              </a-list-item-meta>

              <a-avatar
                v-else-if="item.message!== undefined && item.key.fromMe"
                slot="actions"
                class="mt-2"
                src="https://img.icons8.com/ios-glyphs/256/gender-neutral-user.png"
              />
              <a-list-item-meta v-if="item.message!== undefined && item.key.fromMe">
                <div slot="title" href="https://www.antdv.com/" class="mt-2 pull-right">
                <!-- <strong>{{ item.key.fromMe ? item.key.remoteJid : 'Me' }}</strong> -->
                <strong>Me</strong>
                <br>
                <p class="pull-right" style="color: rgba(0, 0, 0, 0.45) !important; font-size: 14px !important; line-height: 22px !important;">{{ getMessage(item) }}</p>
                </div>
                <!-- <a-avatar
                  slot="avatar"
                  class="mt-2"
                  src="https://img.icons8.com/ios-glyphs/256/gender-neutral-user.png"
                /> -->
              </a-list-item-meta>
            </a-list-item>
          </a-list>
</div>
        </a-card>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
// import vueCustomScrollbar from 'vue-custom-scrollbar'
import * as lou from '@/services/data/lou'
import moment from 'moment'

export default {
  // components: { vueCustomScrollbar },
  computed: {
    ...mapState(['settings', 'user']),
  },
  data() {
    return {
      sessionKey: '',
      columnDefs: [
        {
          title: 'Action',
          scopedSlots: {
            customRender: 'action',
          },
        },
        {
          title: 'Session Name',
          dataIndex: 'id',
          // class: 'bg-transparent text-gray-6',
        },
        {
          title: 'Status',
          dataIndex: 'status',
          // class: 'bg-transparent text-gray-6',
        },
      ],
      dataDefs: [],

      chatMode: true,
      messages: [],
      connectedUsers: [],
      activeIndex: 0,
      namespace: 'heasoft',
      name: '',
      socket: '',
      pesan: '',
      username: 'System',
      typing: false,
      lastTypingTime: '',
      TYPING_TIMER_LENGTH: 400,
      nulisnama: '',
      to: '',
      token: localStorage.getItem('accessToken'),
      history: [],
      recon: 0,
    }
  },
  mounted() {
    this.checkActiveSessionsFromApi()
    this.sessionKey = this.user.activeSessions
    this.checkStatusSessions()
    // this.getList()
  },
  methods: {
    moment,
    async checkActiveSessionsFromApi() {
      var res = await lou.customUrlGet2('sessions/active')
      if (res) {
        this.user.activeSessions = res.sessionId
      }
    },
    async checkStatusSessions() {
      var res = await lou.customUrlGet2(
        'sessions/' + this.sessionKey + '/status',
      )
      if (res) {
        if (res.status === 'CONNECTED') {
          var delet = await lou.customUrlDelete2(
            'sessions/' + this.sessionKey,
          )
          // console.log('delet', delet)
          lou.shownotif('Error', 'Sesi belum connect dengan whatsapp', 'error')
        } else if (res.status === 'AUTHENTICATED') {
          this.getList()
        }
      } else {
        lou.shownotif('Error', 'Sesi tidak ada atau belum connect dengan whatsapp', 'error')
      }
    },
    async getConversation(id) {
      var res = await lou.customUrlGet2(this.sessionKey + '/chats/' + id)
      if (res) {
        console.log('res', res)
        this.messages = res.data
        this.chatMode = true
      }
    },
    async getList() {
      var res = await lou.customUrlGet2(this.sessionKey + '/messages')
      // var res = await lou.customUrlGet2(this.sessionKey + '/chats')
      if (res) {
        this.messages = res.data
      }
    },
    getMessage(message) {
      console.log('message', message)
      if (message.message !== undefined) {
        if (message.message.conversation !== undefined) {
          // console.log('message.message.conversation', message.message.conversation)
          return message.message.conversation
        } else {
          return ''
        }
      } else {
        return ''
      }
    },
    getDescription(item) {
      if (item.messages !== undefined) {
        if (item.messages.length !== 0) {
          // console.log('item.messages[0].message.message', item.messages[0].message.message)
          if (item.messages[0].message.message !== undefined) {
            return item.messages[0].message.message.conversation
          } else {
            return ''
          }
        } else {
          return ''
        }
      } else {
        return ''
      }
    },
  },
}
</script>

<style lang="scss" scope>

p {
  margin-bottom: 0 !important;
}
.demo-infinite-container {
  border: 1px solid #e8e8e8;
  border-radius: 4px;
  overflow: auto;
  padding: 8px 24px;
  height: 300px;
}
.demo-loading-container {
  position: absolute;
  bottom: 40px;
  width: 100%;
  text-align: center;
}
</style>
