<template>
  <div>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mb-2 ml-1">
        <strong>Periode</strong>
        <a-radio-group v-model="size" class="ml-2">
          <a-radio-button value="date">
            Date
          </a-radio-button>
          <a-radio-button value="month">
            Month
          </a-radio-button>
          <a-radio-button value="year">
            Year
          </a-radio-button>
        </a-radio-group>
      </div>
      <div class="col-sm-12 col-md-12 col-lg-12 mb-4">
        <a-date-picker
          v-if="size === 'date'"
          placeholder="Start date"
          format="DD-MM-YYYY"
          class=""
          ref="endYear"
          :default-value="inittgl1"
          :allowClear="false"
          @change="
            (dateMoment, dateString) => onChange(dateMoment, dateString, 'tgl1')
          "
        />
        <a-date-picker
          v-if="size === 'date'"
          placeholder="End date"
          style="margin-left: 10px;"
          format="DD-MM-YYYY"
          class="mr-2"
          :default-value="inittgl2"
          :allowClear="false"
          @change="
            (dateMoment, dateString) => onChange(dateMoment, dateString, 'tgl2')
          "
        />
        <a-month-picker
          v-if="size === 'month'"
          placeholder="Start month"
          format="MM-YYYY"
          class=""
          :default-value="inittgl1"
          :allowClear="false"
          @change="
            (dateMoment, dateString) =>
              onChangeMonth(dateMoment, dateString, 'tgl1')
          "
        />
        <a-month-picker
          v-if="size === 'month'"
          placeholder="End Month"
          format="MM-YYYY"
          class="ml-2"
          :default-value="inittgl2"
          :allowClear="false"
          @change="
            (dateMoment, dateString) =>
              onChangeMonth(dateMoment, dateString, 'tgl2')
          "
        />
        <label v-if="size === 'year'">Year: </label>
        <a-date-picker
          v-if="size === 'year'"
          placeholder="Select Year"
          format="YYYY"
          mode="year"
          class="ml-2"
          :default-value="inittgl1"
          :allowClear="false"
          @change="
            (dateMoment, dateString) => onChangeYear(dateMoment, dateString)
          "
        />
        <label v-if="size === 'year'" class="ml-2"
          >*note: Setelah memilih tolong tekan tombol "ENTER"</label
        >
      </div>
      <div class="col-sm-6 col-md-6 col-lg-6">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-sm-12 col-md-12 col-lg-12">
                <div class="row">
                  <h5 class="col-sm-6 col-md-6 col-lg-6 mb-4">
                    <strong>Deposito</strong>
                  </h5>
                  <!-- <div class="col-sm-6 col-md-6 col-lg-6 mb-4">
                    <a-select
                      show-search
                      class="col-sm-4 col-md-4 col-lg-4 float-right"
                      style="margin-left: 15px;"
                      v-model="select.labarugi"
                      @change="
                        (e) => pilihsandikantor(e, dataChartOptions.labarugi)
                      "
                      :filter-option="filterOption"
                    >
                      <a-select-option
                        v-for="(item, index) in datalabarugi"
                        :key="index"
                        :value="index"
                      >
                        {{ item.sandikc }}
                      </a-select-option>
                    </a-select>
                  </div> -->
                </div>
                <div class="mb-5">
                  <div class="row">
                    <!-- <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4">
                      <div
                        class="demo-infinite-container"
                        :infinite-scroll-distance="10"
                      >
                        <div slot="header">
                          Label: nominal
                        </div>
                        <div slot="footer">
                          Footer
                        </div>
                        <a-list size="small" :data-source="dataListDeposito">
                          <a-list-item slot="renderItem" slot-scope="item">
                            {{ item.label }}:
                            <strong>{{ formatCurrency(item.jumlah) }}</strong>
                          </a-list-item>
                        </a-list>
                      </div>
                    </div> -->
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                      <bar-chart
                        id="testingid"
                        :key="componentKey"
                        :chartdata="dataChartDeposito"
                        :options="barOptions"
                        :height="200"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-6 col-md-6 col-lg-6">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-sm-12 col-md-12 col-lg-12">
                <div class="row">
                  <h5 class="col-sm-6 col-md-6 col-lg-6 mb-4">
                    <strong>Kredit</strong>
                  </h5>
                  <!-- <div class="col-sm-6 col-md-6 col-lg-6 mb-4">
                    <a-select
                      show-search
                      class="col-sm-4 col-md-4 col-lg-4 float-right"
                      style="margin-left: 15px;"
                      v-model="select.labarugi"
                      @change="
                        (e) => pilihsandikantor(e, dataChartOptions.labarugi)
                      "
                      :filter-option="filterOption"
                    >
                      <a-select-option
                        v-for="(item, index) in datalabarugi"
                        :key="index"
                        :value="index"
                      >
                        {{ item.sandikc }}
                      </a-select-option>
                    </a-select>
                  </div> -->
                </div>
                <div class="mb-5">
                  <line-chart
                    :key="componentKey"
                    :chartdata="dataChartKredit"
                    :options="lineOptions"
                    :height="200"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-6 col-md-6 col-lg-6">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-sm-12 col-md-12 col-lg-12">
                <div class="row">
                  <h5 class="col-sm-6 col-md-6 col-lg-6 mb-4">
                    <strong>Laba/Rugi</strong>
                  </h5>
                  <!-- <div class="col-sm-6 col-md-6 col-lg-6 mb-4">
                    <a-select
                      show-search
                      class="col-sm-4 col-md-4 col-lg-4 float-right"
                      style="margin-left: 15px;"
                      v-model="select.labarugi"
                      @change="
                        (e) => pilihsandikantor(e, dataChartOptions.labarugi)
                      "
                      :filter-option="filterOption"
                    >
                      <a-select-option
                        v-for="(item, index) in datalabarugi"
                        :key="index"
                        :value="index"
                      >
                        {{ item.sandikc }}
                      </a-select-option>
                    </a-select>
                  </div> -->
                </div>
                <div class="mb-5">
                  <line-chart
                    :key="componentKey"
                    :chartdata="dataChartLabaRugi"
                    :options="lineOptions"
                    :height="200"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-6 col-md-6 col-lg-6">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-sm-12 col-md-12 col-lg-12">
                <div class="row">
                  <h5 class="col-sm-6 col-md-6 col-lg-6 mb-4">
                    <strong>Neraca</strong>
                  </h5>
                  <!-- <div class="col-sm-6 col-md-6 col-lg-6 mb-4">
                    <a-select
                      show-search
                      class="col-sm-4 col-md-4 col-lg-4 float-right"
                      style="margin-left: 15px;"
                      v-model="select.labarugi"
                      @change="
                        (e) => pilihsandikantor(e, dataChartOptions.labarugi)
                      "
                      :filter-option="filterOption"
                    >
                      <a-select-option
                        v-for="(item, index) in datalabarugi"
                        :key="index"
                        :value="index"
                      >
                        {{ item.sandikc }}
                      </a-select-option>
                    </a-select>
                  </div> -->
                </div>
                <div class="mb-5">
                  <line-chart
                    :key="componentKey"
                    :chartdata="dataChartNeraca"
                    :options="lineOptions"
                    :height="200"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-6 col-md-6 col-lg-6">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-sm-12 col-md-12 col-lg-12">
                <div class="row">
                  <h5 class="col-sm-6 col-md-6 col-lg-6 mb-4">
                    <strong>Tabungan</strong>
                  </h5>
                  <!-- <div class="col-sm-6 col-md-6 col-lg-6 mb-4">
                    <a-select
                      show-search
                      class="col-sm-4 col-md-4 col-lg-4 float-right"
                      style="margin-left: 15px;"
                      v-model="select.labarugi"
                      @change="
                        (e) => pilihsandikantor(e, dataChartOptions.labarugi)
                      "
                      :filter-option="filterOption"
                    >
                      <a-select-option
                        v-for="(item, index) in datalabarugi"
                        :key="index"
                        :value="index"
                      >
                        {{ item.sandikc }}
                      </a-select-option>
                    </a-select>
                  </div> -->
                </div>
                <div class="mb-5">
                  <line-chart
                    :key="componentKey"
                    :chartdata="dataChartTabungan"
                    :options="lineOptions"
                    :height="200"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LineChart from './component/linechart'
import BarChart from './component/barchart'
import * as lou from '@/services/data/lou'
import moment from 'moment'
// eslint-disable-next-line no-unused-vars
import Chart from 'chart.js'
import ChartJSPluginDatalabels from 'chartjs-plugin-datalabels'

Chart.plugins.register(ChartJSPluginDatalabels)

export default {
  components: {
    'line-chart': LineChart,
    BarChart,
  },
  data() {
    return {
      size: 'date',
      datenow: null,
      tgl1: null,
      tgl2: null,
      inittgl1: null,
      inittgl2: null,
      select: {
        labarugi: '',
        deposito: '',
        kredit: '',
        neraca: '',
        tabungan: '',
      },
      dataChartOptions: {
        defaultdatefield: 'kode_periode',
        labarugi: {
          fromarray: 'datalabarugi',
          toarray: 'dataChartLabaRugi',
          selectname: 'labarugi',
          fill: false,
          dynamicColor: true,
          bgcolorOpacity: '0',
          bordercolorOpacity: '1',
        },
        deposito: {
          fromarray: 'datadeposito',
          toarray: 'dataChartDeposito',
          selectname: 'deposito',
          fill: false,
          dynamicColor: true,
          bgcolorOpacity: '0',
          bordercolorOpacity: '1',
        },
        kredit: {
          fromarray: 'datakredit',
          toarray: 'dataChartKredit',
          selectname: 'kredit',
          fill: false,
          dynamicColor: true,
          bgcolorOpacity: '0',
          bordercolorOpacity: '1',
        },
        neraca: {
          fromarray: 'dataneraca',
          toarray: 'dataChartNeraca',
          selectname: 'neraca',
          fill: true,
          dynamicColor: true,
          bgcolorOpacity: '0',
          bordercolorOpacity: '1',
        },
        tabungan: {
          fromarray: 'datatabungan',
          toarray: 'dataChartTabungan',
          selectname: 'tabungan',
          fill: false,
          dynamicColor: true,
          bgcolorOpacity: '0',
          bordercolorOpacity: '1',
        },
      },
      componentKey: 0,
      datalabarugi: [],
      datadeposito: [],
      datakredit: [],
      dataneraca: [],
      datatabungan: [],

      lineOptions: {
        plugins: {
          datalabels: {
            display: false,
            anchor: 'end',
            // rotation: 20,
            // color: lou.dynamicColors(0, 1),
            // color: 'black',
            // display: function(context) {
            //   // console.log('context.dataset.data[context.dataIndex]', context.dataset.data[context.dataIndex])
            //   return context.dataset.data[context.dataIndex]
            // },
            font: {
              weight: 'bold',
            },
            formatter: function(value, context) {
              return lou.curency(value)
            },
          },
        },
        maintainAspectRatio: true,
        animation: false,
        tooltips: {
          // interaction: {
          //   mode: 'x',
          // },
          callbacks: {
            label: function (t, d) {
            // console.log('t', t)
              var xLabel, yLabel
              // if (t.datasetIndex === 0) {
              //   xLabel = d.datasets[t.datasetIndex].label
              //   yLabel = t.yLabel
              //   return xLabel + ': ' + yLabel
              // } else {
              xLabel = d.datasets[t.datasetIndex].label
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, '.')
              yLabel =
                t.yLabel >= 1000
                  ? '' +
                    t.yLabel.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
                  : '' +
                    t.yLabel.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
              return xLabel + ': ' + yLabel
            // }
            },
          },
        },
        scales: {
        // xAxes: [
        //   {
        //     display: true,
        //   },
        // ],
        // yAxes: [
        //   {
        //     stacked: true,
        //   },
        // ],
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
                callback: function (value, index, values) {
                // console.log('value', value)
                  if (parseInt(value) >= 1000) {
                    return (
                      '' +
                      Intl.NumberFormat('id-ID', {
                        notation: 'compact',
                        compactDisplay: 'short',
                      }).format(value)
                      // value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
                    )
                  } else if (parseInt(value) <= -1000) {
                    return (
                      '' +
                      Intl.NumberFormat('id-ID', {
                        notation: 'compact',
                        compactDisplay: 'short',
                      }).format(value)
                      // value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
                    )
                  } else {
                    return '' + value
                  }
                },
              },
            },
          ],
        },
      },
      barOptions: {
        plugins: {
          datalabels: {
            anchor: 'center',
            rotation: 90,
            // color: lou.dynamicColors(0, 1),
            // color: 'black',
            display: function(context) {
              // console.log('context.dataset.data[context.dataIndex]', context.dataset.data[context.dataIndex])
              return context.dataset.data[context.dataIndex]
            },
            font: {
              weight: 'bold',
            },
            formatter: function(value, context) {
              return lou.curency(value)
            },
          },
        },

        tooltips: {
          callbacks: {
            label: function (t, d) {
            // console.log('t', t)
              var xLabel, yLabel
              // if (t.datasetIndex === 0) {
              //   xLabel = d.datasets[t.datasetIndex].label
              //   yLabel = t.yLabel
              //   return xLabel + ': ' + yLabel
              // } else {
              xLabel = d.datasets[t.datasetIndex].label
              yLabel =
                t.yLabel >= 1000
                  ? '' +
                    t.yLabel.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
                  : '' + t.yLabel
              return xLabel + ': ' + yLabel
            // }
            },
          },
        },
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
                callback: function (value, index, values) {
                  if (parseInt(value) >= 1000) {
                    return (
                      '' +
                      // Intl.NumberFormat('id-ID', {
                      //   notation: 'compact',
                      //   compactDisplay: 'short',
                      // }).format(value)
                      value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
                    )
                  } else {
                    return '' + value
                  }
                },
              },
            },
          ],
        },
      },

      dataChartLabaRugi: {
        labels: [],
        datasets: [],
      },
      dataChartDeposito: {
        labels: [],
        datasets: [],
      },
      dataChartKredit: {
        labels: [],
        datasets: [],
      },
      dataChartNeraca: {
        labels: [],
        datasets: [],
      },
      dataChartTabungan: {
        labels: [],
        datasets: [],
      },
      dataListLabaRugi: [],
      dataListDeposito: [],
      dataListKredit: [],
      dataListNeraca: [],
      dataListTabungan: [],
    }
  },
  created() {
    this.datenow = lou.datenow()
    // var startYear = moment().startOf('year').format('YYYY-MM-DD')
    // var endYear = moment().endOf('year').format('YYYY-MM-DD')
    var lastMonth = moment().subtract(1, 'month').format('DD-MM-YYYY')
    var initlastmonth = moment(lastMonth, 'DD-MM-YYYY')
      .subtract(11, 'month')
      .format('DD-MM-YYYY')
    var endMonth1 = moment(initlastmonth, 'DD-MM-YYYY')
      .endOf('month')
      .format('YYYY-MM-DD')
    var endMonth2 = moment().endOf('month').format('YYYY-MM-DD')
    this.tgl1 = endMonth1
    this.tgl2 = endMonth2
    this.inittgl1 = moment(endMonth1)
    this.inittgl2 = moment(endMonth2)
    setTimeout(() => {
      this.getAllData()
      // console.log('this.dataChartDeposito', this.dataChartDeposito)
    }, 200)
  },
  methods: {
    moment,
    generateList() {
      this.dataListDeposito = []
      this.dataChartDeposito.labels.forEach((element, index) => {
        var obj = {
          label: element,
          jumlah: this.dataChartDeposito.datasets[0].data[index],
        }
        this.dataListDeposito.push(obj)
        // console.log('obj', obj)
      })
      // console.log('this.dataListDeposito', this.dataListDeposito)
    },
    onChangeYear(date, dateString) {
      // console.log(date, dateString)
      this.tgl1 = moment(dateString, 'YYYY')
        .startOf('year')
        .endOf('month')
        .format('YYYY-MM-DD')
      this.tgl2 = moment(dateString, 'YYYY')
        .endOf('year')
        .endOf('month')
        .format('YYYY-MM-DD')
      if (this.tgl1 !== null && this.tgl2 !== null) {
        this.getAllData()
      }
    },
    onChangeMonth(date, dateString, from) {
      // console.log(date, dateString)
      if (from === 'tgl1') {
        this.tgl1 = moment(dateString, 'MM-YYYY')
          .endOf('month')
          .format('YYYY-MM-DD')
      } else {
        this.tgl2 = moment(dateString, 'MM-YYYY')
          .endOf('month')
          .format('YYYY-MM-DD')
      }
      if (this.tgl1 !== null && this.tgl2 !== null) {
        this.getAllData()
      }
    },
    onChange(date, dateString, from) {
      // console.log(date, dateString)
      if (from === 'tgl1') {
        this.tgl1 = moment(dateString, 'DD-MM-YYYY').format('YYYY-MM-DD')
      } else {
        this.tgl2 = moment(dateString, 'DD-MM-YYYY').format('YYYY-MM-DD')
      }
      if (this.tgl1 !== null && this.tgl2 !== null) {
        this.getAllData()
      }
      // console.log('tgl1', this.tgl1)
      // console.log('tgl2', this.tgl2)
    },
    async getAllData() {
      var imbuhan =
        this.tgl1 !== null && this.tgl2 !== null
          ? '?dawal=' + this.tgl1 + '&dakhir=' + this.tgl2
          : ''
      var res = await lou.customUrlGet(
        'master/dashboard' + imbuhan,
        false,
        false,
        true,
      )
      // console.log('data', res)
      if (!res.isError) {
        this.datalabarugi = res.data.labarugi
        this.generateChart(this.dataChartOptions.labarugi)
        this.datadeposito = res.data.deposito
        this.generateChart(this.dataChartOptions.deposito)
        this.datakredit = res.data.kredit
        this.generateChart(this.dataChartOptions.kredit)
        this.dataneraca = res.data.neraca
        this.generateChart(this.dataChartOptions.neraca)
        this.datatabungan = res.data.tabungan
        this.generateChart(this.dataChartOptions.tabungan)
        this.generateList()
      }
    },
    generateChart({
      fromarray,
      toarray,
      selectname,
      fill = false,
      dynamicColor = false,
      bgcolorOpacity = '1',
      bordercolorOpacity = '1',
    }) {
      this[toarray].labels = []
      var tempfieldlist = []
      tempfieldlist = Object.keys(this[fromarray][0])
      var kpindex = tempfieldlist.indexOf('kode_periode')
      tempfieldlist.splice(kpindex, 1)
      this[fromarray].forEach((element) => {
        var label = moment(element.kode_periode, 'YYYY-MM-DD').format('MM')
        this[toarray].labels.push(label)
      })
      this[toarray].datasets = []
      for (let i = 0; i < tempfieldlist.length; i++) {
        const el = tempfieldlist[i]
        var tempdata = []
        this[fromarray].forEach((element, index) => {
          tempdata.push(element[el])
        })
        var dataset = {
          label: el,
          fill: fill,
          lineTension: 0.1,
          borderWidth: 1,
          borderColor: dynamicColor
            ? lou.dynamicColors(i, bordercolorOpacity)
            : 'rgb(46, 91, 255, ' + bordercolorOpacity + ')',
          backgroundColor: dynamicColor
            ? lou.dynamicColors(i, bgcolorOpacity)
            : 'rgb(46, 91, 255, ' + bgcolorOpacity + ')',
          pointRadius: 2,
          data: tempdata,
        }
        this[toarray].datasets.push(dataset)
      }
      this.componentKey += 1
    },
    formatCurrency(v) {
      return lou.curency(v)
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      )
    },
  },
}
</script>

<style>
.demo-infinite-container {
  border: 1px solid #e8e8e8;
  border-radius: 4px;
  overflow: auto;
  padding: 8px 24px;
  height: 40vh;
}
.demo-loading-container {
  position: absolute;
  bottom: 40px;
  width: 100%;
  text-align: center;
}
</style>
