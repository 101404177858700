<template>
  <div>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
        <a-card>
          <template slot="title">
            <strong>Cetak Bilyet</strong>
          </template>
          <div class="row pl-3 pr-3 pt-2 pb-3">
            <!-- <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3">
              <label></label>
            </div> -->
            <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
              <div class="row">
                <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-1">
                  <label class="mt-1">Tgl Transaksi</label>
                </div>
                <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                  <a-input
                    style="width: 150px;"
                    v-model="maskInput.tgl"
                    :readOnly="true"
                  ></a-input>
                </div>
                <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-1">
                  <label class="mt-1">No. Rekening</label>
                </div>
                <div class="col-lg-9 col-md-9 col-sm-9 mt-1">
              <cleave
                :readOnly="false"
                :options="{ delimiter: '.', blocks: [2, 2, 6] }"
                class="ant-input"
                :raw="false"
                style="width: 150px;"
                v-model.lazy="norekening"
                placeholder="No Rekening"
                v-on:keydown.enter.native="checkRekening1"
              />
              <a-select
                label-in-value
                show-search
                class="ml-2"
                :value="selectedName"
                placeholder="Ketikkan Nama Nasabah"
                style="width: 200px;"
                :filter-option="false"
                :not-found-content="fetching ? 'Searching...' : 'Not Found!'"
                @search="fetchUser"
                @change="(e) => handleChange(e, 'selectedName')"
              >
                <a-spin v-if="fetching" slot="notFoundContent" size="small" />
                <a-select-option
                  v-for="(d, index) in dataRekening"
                  :key="index"
                >
                  <!-- {{ d.rekening + ' - ' + d.nama }} -->
                  {{ d.nama + " - " + d.rekening }}
                </a-select-option>
              </a-select>
                  <!-- <a-input
                    ref="rekcabang"
                    placeholder=""
                    v-model="maskInput.rekcabang"
                    style="width: 60px;"
                    :readOnly="false"
                    @input="rekeninglimit('rekcabang', 2, 'rerekening')"
                  ></a-input>
                  <a-input
                    class="ml-2"
                    placeholder=""
                    v-model="maskInput.rerekening"
                    style="width: 60px;"
                    :readOnly="false"
                    ref="rerekening"
                    @input="rekeninglimit('rerekening', 2, 'rerekening1')"
                  ></a-input>
                  <a-input
                    ref="rerekening1"
                    class="ml-2"
                    placeholder=""
                    v-model="maskInput.rerekening1"
                    style="width: 150px;"
                    :readOnly="false"
                    @keydown.enter="rekeningcheck"
                  ></a-input> -->
                  <a-button class="ml-2" @click="checkRekening1">
                    <a-icon type="search"></a-icon>
                  </a-button>
                </div>
                <div class="col-sm-3 col-md-3 col-lg-3 mt-1">
                  <label class="mt-1">Nama Deposan</label>
                </div>
                <div class="col-lg-9 col-md-9 col-sm-9 mt-1">
                  <a-input
                    placeholder="Nama"
                    v-model="maskInput.nama_deposan"
                    :readOnly="true"
                  ></a-input>
                </div>
                <div class="col-sm-3 col-md-3 col-lg-3 mt-1">
                  <label class="mt-1">Alamat</label>
                </div>
                <div class="col-lg-9 col-md-9 col-sm-9 mt-1">
                  <a-input
                    placeholder="Alamat"
                    v-model="maskInput.alamat_deposan"
                    :readOnly="true"
                  ></a-input>
                </div>
                <div class="col-sm-3 col-md-3 col-lg-3 mt-1">
                  <label class="mt-1">Kota</label>
                </div>
                <div class="col-lg-9 col-md-9 col-sm-9 mt-1">
                  <a-input
                    placeholder="Kota"
                    v-model="maskInput.kota_deposan"
                    :readOnly="true"
                  ></a-input>
                </div>
                <div class="col-sm-3 col-md-3 col-lg-3 mt-4">
                  <label class="mt-1">Sistem ARO</label>
                </div>
                <div class="col-lg-9 col-md-9 col-sm-9 mt-4">
                  <a-radio-group v-model="input.pencairan" size="small">
                    <a-radio-button value="ya">
                      Ya
                    </a-radio-button>
                    <a-radio-button value="tidak">
                      Tidak
                    </a-radio-button>
                  </a-radio-group>
                </div>

                <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
                  <label class="mt-1">Rek. Tabungan</label>
                </div>
                <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
              <cleave
                :readOnly="false"
                :options="{ delimiter: '.', blocks: [2, 2, 6] }"
                class="ant-input"
                :raw="false"
                style="width: 150px;"
                v-model.lazy="norekening"
                placeholder="No Rekening"
                v-on:keydown.enter.native="checkRekening1"
              />
                    <a-input
                      ref="rekcabang1"
                      placeholder=""
                      v-model="maskInput.rekcabang1"
                      style="width: 60px;"
                      :readOnly="false"
                      @input="rekeninglimit('rekcabang12', 2, 'rerekening1')"
                    ></a-input>
                    <a-input
                      class="ml-2"
                      placeholder=""
                      v-model="maskInput.rerekening12"
                      style="width: 60px;"
                      :readOnly="false"
                      ref="rerekening12"
                      @input="rekeninglimit('rerekening12', 2, 'rerekening11')"
                    ></a-input>
                    <a-input
                      ref="rerekening11"
                      class="ml-2"
                      placeholder=""
                      v-model="maskInput.rerekening11"
                      style="width: 150px;"
                      :readOnly="false"
                      @keydown.enter="rekeningcheck1"
                    ></a-input>
                    <a-button class="ml-2" @click="rekeningcheck1">
                      <a-icon type="search"></a-icon>
                    </a-button>
                </div>
                <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
                  <label class="mt-1">Nama Nasabah</label>
                </div>
                <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                  <a-input
                    placeholder="Nama Nasabah"
                    v-model="maskInput.nama_nasabah"
                    :readOnly="true"
                  ></a-input>
                </div>
                <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
                  <label class="mt-1">Alamat Nasabah</label>
                </div>
                <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                  <a-input
                    placeholder="Alamat Nasabah"
                    v-model="maskInput.alamat_nasabah"
                    :readOnly="true"
                  ></a-input>
                </div>
                <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
                  <label class="mt-1">Pejabat 1</label>
                </div>
                <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                  <a-input
                    placeholder="Pejabat 1"
                    v-model="input.pejabat1"
                    :readOnly="false"
                  ></a-input>
                </div>
                <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
                  <label class="mt-1">Pejabat 2</label>
                </div>
                <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                  <a-input
                    placeholder="Pejabat 2"
                    v-model="input.pejabat2"
                    :readOnly="false"
                  ></a-input>
                </div>
              </div>
            </div>
            <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
              <div class="row">
                <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2 mt-2">
                  <label class="mt-1">Tgl. Valuta</label>
                </div>
                <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 mt-2">
                  <a-input
                    placeholder="Tanggal Valuta"
                    v-model="maskInput.tanggal_valuta"
                    :readOnly="true"
                  ></a-input>
                </div>
                <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2 mt-2">
                  <label class="mt-1">Jth Tempo</label>
                </div>
                <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 mt-2">
                  <a-input
                    placeholder="Jatuh Tempo"
                    v-model="maskInput.jatuh_tempo"
                    :readOnly="true"
                  ></a-input>
                </div>
                <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
                  <label class="mt-1">Gol. Deposan</label>
                </div>
                <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                  <a-input
                    placeholder="Golongan Deposan"
                    v-model="maskInput.golongan_deposan"
                    style="width: 300px;"
                    :readOnly="true"
                  ></a-input>
                </div>
                <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
                  <label class="mt-1">Gol. Deposito</label>
                </div>
                <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                  <a-input
                    placeholder="Golongan Deposito"
                    v-model="maskInput.golongan_deposito"
                    style="width: 300px;"
                    :readOnly="true"
                  ></a-input>
                </div>
                <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
                  <label class="mt-1">Jangka Waktu</label>
                </div>
                <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
                  <a-input
                    class="mr-2"
                    style="width: 50px; text-align: right;"
                    placeholder="0"
                    v-model="maskInput.jw"
                    :readOnly="true"
                  ></a-input>
                  Bulan
                </div>
                <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
                  <label class="mt-1">Suku Bunga</label>
                </div>
                <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
                  <a-input
                    class="mr-2"
                    placeholder="0.00"
                    v-model="maskInput.bunga"
                    style="width: 80px; text-align: right;"
                    :readOnly="true"
                  ></a-input>
                  %
                </div>
                <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
                  <label class="mt-1">Nominal Deposito</label>
                </div>
                <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                  <a-input
                    placeholder="Nominal Deposito"
                    v-model="maskInput.nominal_deposito"
                    style="width: 300px;text-align: right;"
                    :readOnly="true"
                  ></a-input>
                </div>
                <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
                  <label class="mt-1">Nomor Bilyet</label>
                </div>
                <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                  <a-input
                    placeholder="Nomor Bilyet"
                    v-model="maskInput.nomor_bilyet"
                    style="width: 250px;"
                    :readOnly="true"
                  ></a-input>
                </div>
                <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
                  <label class="mt-1">Nomor Seri</label>
                </div>
                <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                  <a-input
                    placeholder="Nomor Seri"
                    v-model="maskInput.nomor_seri"
                    style="width: 250px;"
                    :readOnly="true"
                  ></a-input>
                </div>
                <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
                  <label class="mt-1">Top Margin</label>
                </div>
                <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
                  <a-input
                    placeholder=""
                    style="width: 80px; text-align: right;"
                    v-model="input.top_margin"
                    :readOnly="false"
                  ></a-input>
                  mm
                </div>
                <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
                  <label class="mt-1">Paper Height</label>
                </div>
                <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
                  <a-input
                    placeholder=""
                    style="width: 80px; text-align: right;"
                    v-model="input.paper_height"
                    :readOnly="false"
                  ></a-input>
                  mm
                </div>
                <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
                  <label class="mt-1">Left Margin</label>
                </div>
                <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
                  <a-input
                    placeholder=""
                    style="width: 80px; text-align: right;"
                    v-model="input.left_margin"
                    :readOnly="false"
                  ></a-input>
                  mm
                </div>
                <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
                  <label class="mt-1">Paper Weight</label>
                </div>
                <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
                  <a-input
                    placeholder=""
                    style="width: 80px; text-align: right;"
                    v-model="input.paper_weight"
                    :readOnly="false"
                  ></a-input>
                  mm
                </div>
                <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
                  <label class="mt-1">File Name</label>
                </div>
                <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 mt-1">
                  <a-input
                    placeholder="File Name"
                    v-model="input.filename"
                    :readOnly="false"
                  ></a-input>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-2">
                  <a-button
                    class="btn btn-primary ml-3 float-right"
                    @click="exportDocx"
                    >Submit</a-button
                  >
                </div>
              </div>
            </div>
          </div>
        </a-card>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import * as lou from '@/services/data/lou'
import { mapState } from 'vuex'
import JSPDF from 'jspdf'
import debounce from 'lodash/debounce'
import Cleave from 'vue-cleave-component'

// import { Document, Packer, Paragraph, TextRun } from 'docx'
// import { saveAs } from 'file-saver'; // you can use this also
// const FileSaver = require('file-saver')

export default {
  computed: mapState(['settings', 'user']),
  components: {
    cleave: Cleave,
  },
  watch: {
    // 'settings.selectedRekening'() {
    //   this.checkRekening()
    // },
  },
  data() {
    this.lastFetchId = 0
    this.fetchUser = debounce(this.fetchUser, 800)
    return {
      norekening: '',
      fetching: false,
      selectedName: [],
      dataRekening: [],
      searchText: '',
      searchInput: null,
      searchedColumn: '',
      input: {
        rekening: '',
        confirmation: '',
        sistem_aro: '',
        no_rekeining: '',
        pejabat1: '',
        pejabat2: '',
        top_margin: '0',
        left_margin: '0',
        paper_height: '0',
        paper_weight: '0',
        filename: 'bilyet.docx',
      },
      maskInput: {
        rekcabang: '01',
        rerekening: '',
        rerekening1: '',
        rekcabang1: '01',
        rerekening12: '',
        rerekening11: '',
        nama_deposan: '',
        alamat_deposan: '',
        kota_deposan: '',
        tanggal_valuta: '',
        jatuh_tempo: '',
        golongan_deposan: '',
        golongan_deposito: '',
        nama_nasabah: '',
        alamat_nasabah: '',
        jw: '',
        bunga: '',
        nominal_deposito: '',
        nomor_bilyet: '',
        nomor_seri: '',
      },
      allMaster: {
        carapencairan: [],
      },

      // Modal Property
      formvisible: false,
      carinasabahtitle: 'Cari',
      inputModal: {
        nama: '',
        alamat: '',
        cabang: '',
      },
    }
  },
  created() {
    // this.getAllData()
  },
  methods: {
    moment,
    async fetchUser(value) {
      // console.log('fetching user', value)
      if (value !== '') {
        this.lastFetchId += 1
        const fetchId = this.lastFetchId
        this.dataRekening = []
        this.fetching = true
        var res = false
        if (!this.hasNumber(value)) {
          var nvalue = value.replace(/[^\w\s]/gi, '')
          res = await lou.customUrlGet2('deposito_cetakbilyet?Nama=' + nvalue)
        } else {
          var nvalue1 = value.replace(/[0-9]/g, '')
          res = await lou.customUrlGet2(
            'deposito_cetakbilyet?Rekening=' + nvalue1,
          )
        }
        if (res) {
          if (fetchId !== this.lastFetchId) {
            // for fetch callback order
            return false
          }
          this.dataRekening = res.data
          this.fetching = false
        } else {
          this.fetching = null
        }
      }
    },
    handleChange(value, from) {
      // console.log('value', value)
      if (value.key !== null && value.key !== undefined) {
        var dataTarget = this.dataRekening[value.key]
        var data = {
          selectedName: value,
          selectedRekening: value,
          rekeningjurnal: dataTarget.rekening,
          norekening: dataTarget.rekening,
          tabungan_id: dataTarget.id,
          namaprekening: dataTarget.nama,
          alamatprekening: dataTarget.alamat,
          saldoprekening: dataTarget.saldo,
          // dataRekening: [],
          fetching: false,
        }
        Object.assign(this, data)
        this.input.deposito_id = dataTarget.id
        this.input.sukubunga = dataTarget.sukubunga
        this.maskInput = dataTarget
        this.maskInput.namanasabah = dataTarget.nama
        this.maskInput.alamatnasabah = dataTarget.alamat
        this.maskInput.golongan_deposito = dataTarget.nama_golongandeposito
        this.maskInput.rekening_tabungan = dataTarget.rekening_tabungan
        this.maskInput.tgl_penempatan = dataTarget.tgl
        this.maskInput.saldo_tabungan = lou.curency(
          dataTarget.saldo_tabungan,
        )
        this.maskInput.tgl_jatuh_tempo = dataTarget.jthtmp
        this.maskInput.no_bilyet_deposito = dataTarget.nobilyet
      }
    },
    async checkRekening1() {
      if (this.norekening.length === 12) {
        // console.log('this.norekening', this.norekening)
        var res = await lou.customUrlGet2(
          'deposito_cetakbilyet?Rekening=' + this.norekening.toString(),
        )
        console.log('res', res)
        if (res) {
          if (res.data.length !== 0) {
            // lou.shownotif('Ditemukan!', 'Data telah ditemukan!', 'info')
            this.dataRekening = res.data
            var value = this.dataRekening.findIndex(
              (x) => x.rekening === this.norekening,
            )
            var dataTarget = this.dataRekening[value]
            // console.log('dataTarget', dataTarget)
            var data = {
              selectedName: { key: 0, label: dataTarget.nama },
              selectedRekening: { key: 0, label: dataTarget.rekening },
              rekeningjurnal: dataTarget.rekening,
              norekening: dataTarget.rekening,
              tabungan_id: dataTarget.id,
              namaprekening: dataTarget.nama,
              alamatprekening: dataTarget.alamat,
              saldoprekening: dataTarget.saldo,
              // dataRekening: [],
              fetching: false,
            }
            Object.assign(this, data)

            // this.input.deposito_id = dataTarget.id
            // this.input.sukubunga = dataTarget.sukubunga
            // this.maskInput = dataTarget
            // this.maskInput.namanasabah = dataTarget.nama
            // this.maskInput.alamatnasabah = dataTarget.alamat
            // this.maskInput.golongan_deposito = dataTarget.nama_golongandeposito
            // this.maskInput.rekening_tabungan = dataTarget.rekening_tabungan
            // this.maskInput.tgl_penempatan = dataTarget.tgl
            // this.maskInput.saldo_tabungan = lou.curency(
            //   dataTarget.saldo_tabungan,
            // )
            // this.maskInput.tgl_jatuh_tempo = dataTarget.jthtmp
            // this.maskInput.no_bilyet_deposito = dataTarget.nobilyet
          } else {
            lou.shownotif(
              'Tidak di temukan',
              'Tidak ada data dengan rekening tersebut!',
              'error',
            )
          }
        } else {
          // var res = await lou.customUrlGet2(
          //   'deposito_mutasi/cari?Rekening=' + this.norekening,
          // )
          // if (res) {
          //   if (res.data !== null) {
          //     this.selectedName = { key: 0, label: res.data.nama }
          //     this.input.deposito_id = res.data.id
          //     this.input.sukubunga = res.data.sukubunga
          //     this.maskInput = res.data
          //     this.maskInput.namanasabah = res.data.nama
          //     this.maskInput.alamatnasabah = res.data.alamat
          //     this.maskInput.golongan_deposito = res.data.nama_golongandeposito
          //     this.maskInput.rekening_tabungan = res.data.rekening_tabungan
          //     this.maskInput.tgl_penempatan = res.data.tgl
          //     this.maskInput.saldo_tabungan = lou.curency(res.data.saldo_tabungan)
          //     this.maskInput.tgl_jatuh_tempo = res.data.jthtmp
          //     this.maskInput.no_bilyet_deposito = res.data.nobilyet
          //   }
        }
      }
    },
    rekeninglimit(from, limit, toref) {
      if (this.maskInput[from].length >= limit) {
        this.$refs[toref].$el.focus()
      }
    },
    exportDocx() {
      // Create a new Document an save it in a variable
      const logo = require('@/assets/icons/logo-bpr-1-01.png')
      const doc = new JSPDF()
      var img = new Image()
      img.src = logo
      doc.addImage(img, 'PNG', 10, 8, 15, 15)
      doc.setFont(undefined, 'bold')
      doc.setFontSize(12)
      doc.setTextColor('#062649')
      doc.text('UNIVERSAL', 15, 25)
      doc.setFontSize(14)
      doc.setTextColor('#17533E')
      doc.text('BPR', 41, 25)
      doc.setTextColor('#000000')
      doc.setFont(undefined, 'bold')
      doc.setFontSize(22)
      doc.text('BILYET DEPOSITO', 80, 25)
      // var filename = 'Validasi'
      // doc.output('dataurlnewwindow')
      window.open(doc.output('bloburl'))
      // doc.save('testing')
      // const doc = new Document({
      //   sections: [
      //     {
      //       properties: {},
      //       children: [
      //         new Paragraph({
      //           children: [
      //             new TextRun('Hello World'),
      //             new TextRun({
      //               text: 'Foo Bar',
      //               bold: true,
      //             }),
      //             new TextRun({
      //               text: 'אני אדם כמו כל אדם אחר בעולם חחחחחחחחחח הצחקתי את עצמי ',
      //               bold: true,
      //             }),
      //           ],
      //         }),
      //       ],
      //     },
      //   ],
      // })
      // const mimeType =
      //   'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
      // const fileName = 'test.docx'
      // Packer.toBlob(doc).then((blob) => {
      //   const docblob = blob.slice(0, blob.size, mimeType)
      //   FileSaver.saveAs(docblob, fileName)
      // })
    },
    async showModal() {
      this.carinasabahvisible = true
      var res = await lou.customUrlGet2('pencairan_kredit')
      if (res) {
        console.log('res', res)
        this.table.tablebawah.datatable = res.data
      }
    },
    async handleSubmit() {
      if (this.input.confirmation === 'PEMBATALAN PENCAIRAN DEPOSITO') {
        var res = await lou.customUrlPost2(
          'batalpencairan_kredit/' + this.maskInput.id,
          {},
        )
        if (res) {
          // this.getAllData()
          this.resetForm()
        }
      } else {
        lou.shownotif(
          'Tolong ketik "PEMBATALAN PENCAIRAN DEPOSITO" di input konfirmasi!',
        )
      }
    },
    async checkRekening() {
      this.input.rekening = this.settings.selectedRekening
      var text = this.settings.selectedRekening
      this.maskInput.rekcabang = text.slice(0, text.indexOf('.'))
      this.maskInput.rerekening = text.slice(
        text.indexOf('.') + 1,
        text.indexOf('.') + 3,
      )
      var ntext = text.replace('.', '')
      // console.log('ntext', ntext.slice(ntext.indexOf('.') + 1, ntext.length))
      this.maskInput.rerekening1 = ntext.slice(
        ntext.indexOf('.') + 1,
        ntext.length,
      )
      // this.rekeningcheck()
    },
    async rekeningcheck() {
      var res = await lou.customUrlGet2(
        'batalpencairan_kredit?rekening=' +
          this.maskInput.rekcabang +
          '.' +
          this.maskInput.rerekening +
          '.' +
          this.maskInput.rerekening1,
      )
      if (res) {
        console.log('res', res)
        if (res.data !== null) {
          lou.shownotif('Ditemukan!', 'Rekening ditemukan.')
          // this.maskInput = this.$g.clone(res.data)
        } else {
          lou.shownotif(
            'Tidak ditemukan!',
            'Rekening tidak ditemukan.',
            'error',
          )
        }
      }
    },
    async rekeningcheck1() {
      var res = await lou.customUrlGet2(
        'deposito_cetakbilyet?Rekening=' +
          this.maskInput.rekcabang +
          '.' +
          this.maskInput.rerekening +
          '.' +
          this.maskInput.rerekening1,
      )
      if (res) {
        if (res.data !== null) {
          lou.shownotif('Ditemukan!', 'Rekening ditemukan.')
          // this.maskInput = this.$g.clone(res.data)
        } else {
          lou.shownotif(
            'Tidak ditemukan!',
            'Rekening tidak ditemukan.',
            'error',
          )
        }
      }
    },
    async getAllData() {
      var indexeddb = await lou.getDataFromDb()
      // console.log('indexeddb', indexeddb)
      // console.log('this.settings.whatapp', this.settings.whatapp)
      var allmaster = {}
      var masterIndex = indexeddb.findIndex(
        (x) =>
          x.mykey ===
          'allmaster' +
            this.settings.whatapp.appName.toLowerCase() +
            this.settings.whatapp.id,
      )
      // console.log('masterIndex', indexeddb[masterIndex])
      if (masterIndex < 0) {
        var res1 = await lou.customUrlGet2('master', false, true)
        var master = {
          mykey:
            'allmaster' +
            this.settings.whatapp.appName.toLowerCase() +
            this.settings.whatapp.id,
          data: JSON.stringify(res1.data),
        }
        await lou.addDataToDb(master)
        var nindexeddb = await lou.getDataFromDb()
        var targetIndex = nindexeddb.findIndex(
          (x) =>
            x.mykey ===
            'allmaster' +
              this.settings.whatapp.appName.toLowerCase() +
              this.settings.whatapp.id,
        )
        // console.log('nindexeddb', nindexeddb[targetIndex])
        allmaster = JSON.parse(nindexeddb[targetIndex].data)
      } else {
        allmaster = JSON.parse(indexeddb[masterIndex].data)
      }
      var cabanglist = []
      for (let u = 0; u < this.user.cabangList.length; u++) {
        const element = this.user.cabangList[u]
        // console.log('element', element)
        cabanglist.push({ kode: element.kode, keterangan: element.kode })
      }
      // console.log('indexeddb', indexeddb)
      this.allMaster = allmaster
      console.log('this.allMaster', this.allMaster)
      var res = await lou.customUrlGet2('pencairan_kredit')
      if (res) {
        this.table.tablebawah.datatable = res.data
      }
    },
    resetForm() {
      this.input = {
        rekening: '',
        confirmation: '',
      }
      this.maskInput = {
        rekcabang: '',
        rerekening: '',
        rerekening1: '',
        nama_nasabah: '',
        alamat_nasabah: '',
        no_pencairan: '',
        nominal: '',
        sukubunga: '',
        rekening_tabungan: '',
        atas_nama: '',
        saldo_tabungan: '',
      }
      this.$refs.rekcabang.$el.focus()
    },

    // Modal function
    clearRekening() {
      this.settings.selectedRekening = ''
    },
    async findData() {
      var imbuhan = ''
      imbuhan +=
        this.inputModal.nama !== '' ? '?Nama=' + this.inputModal.nama : ''
      imbuhan +=
        this.inputModal.alamat !== ''
          ? this.inputModal.nama !== ''
            ? '&Alamat=' + this.inputModal.alamat
            : '?Alamat=' + this.inputModal.alamat
          : ''
      imbuhan +=
        this.inputModal.cabang !== ''
          ? '&cabang=' + this.inputModal.cabang
          : ''
      var res = await lou.customUrlGet2('carinasabah' + imbuhan)
      if (res) {
        this.table.tableatas.datatable = res.data
        // for (let i = 0; i < 20; i++) {
        //   const element = res.data[0]
        //   this.table.tableatas.datatable.push(element)
        // }
      }
    },
    async nasabahDetail(record) {
      this.loading = true
      var res = await lou.customUrlGet2('carinasabah/detail/' + record.kode)
      if (res) {
        this.maskInput.cabang = record.kode.slice(0, record.kode.indexOf('.'))
        this.maskInput.reregister = record.kode.slice(
          record.kode.indexOf('.') + 1,
          record.kode.length,
        )
        // this.registeridchanged()
        this.table.tablebawah.datatable = res.data
        this.loading = false
      }
    },
    customRow(record, index) {
      return {
        on: {
          click: (event) => {
            // console.log('record', record)
            // console.log('index', index)
            // console.log('event', event)
            this.settings.selectedKodeRegister = record.kode
            this.nasabahDetail(record)
          },
          contextmenu: (event) => {
            event.preventDefault()
          },
        },
      }
    },
    customRowB(record, index) {
      return {
        on: {
          click: (event) => {
            // console.log('record', record)

            this.input.rekening = record.rekening
            var text = record.rekening
            this.maskInput.rekcabang = text.slice(0, text.indexOf('.'))
            this.maskInput.rerekening = text.slice(
              text.indexOf('.') + 1,
              text.indexOf('.') + 3,
            )
            var ntext = text.replace('.', '')
            // console.log('ntext', ntext.slice(ntext.indexOf('.') + 1, ntext.length))
            this.maskInput.rerekening1 = ntext.slice(
              ntext.indexOf('.') + 1,
              ntext.length,
            )
            this.maskInput = this.$g.clone(record)
            this.maskInput.plafond = this.formatCurrency(record.plafond)
            this.maskInput.biaya_administrasi = this.formatCurrency(
              record.biaya_administrasi,
            )
            this.maskInput.biaya_asuransi = this.formatCurrency(
              record.biaya_asuransi,
            )
            this.maskInput.biaya_materai = this.formatCurrency(
              record.biaya_materai,
            )
            this.maskInput.biaya_notaris = this.formatCurrency(
              record.biaya_notaris,
            )
            // console.log('this.maskInput', this.maskInput)
            this.formvisible = true
            // this.settings.selectedRekening = record.rekening
            // console.log('index', index)
            // console.log('event', event)
            // console.log('this.settings.selectedRekening', this.settings.selectedRekening)
          },
          contextmenu: (event) => {
            event.preventDefault()
          },
        },
      }
    },
    formatCurrency(v) {
      return lou.curency(v)
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      )
    },
    dateFormatter(params) {
      var ret =
        params.value === null
          ? ''
          : moment(params.value, 'YYYY-MM-DD').format('DD-MM-YYYY')
      // console.log('dateFormatter', ret)
      return ret !== 'Invalid date' ? ret : params.value
    },
    currencyFormatter(params) {
      var ret =
        params.value === null
          ? 'NaN'
          : lou.curency(parseFloat(params.value).toFixed(0))
      return ret === 'NaN' ? '' : ret
    },
  },
}
</script>

<style lang="scss" scoped></style>
