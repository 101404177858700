<template>
  <div>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
        <a-card>
          <template slot="title">
            <strong>Angsuran</strong>
            <!-- <a-button class="btn btn-outline-danger ml-4" @click="handleSubmit">Pembatalan Pencairan</a-button> -->
          </template>
          <div class="row pl-4 pr-4 pt-4 pb-4">
            <!-- <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4">
              <label></label>
            </div> -->
            <div class="col-xs-5 col-sm-5 col-md-5 col-lg-5">
              <div class="row">
                <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3">
                  <label class="mt-1">Rekening</label>
                </div>
                <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9">
                  <cleave
                    ref="rekeningfirst"
                    :readOnly="false"
                    :options="{ delimiter: '.', blocks: [2, 2, 6] }"
                    class="ant-input"
                    :raw="false"
                    style="width: 100px;"
                    v-model.lazy="norekening"
                    placeholder="No Rekening"
                    v-on:keydown.enter.native="checkRekening"
                  />
                  <a-select
                    label-in-value
                    show-search
                    class="ml-2"
                    :value="selectedName"
                    placeholder="Ketikkan Nama Nasabah"
                    style="width: 180px;"
                    :filter-option="false"
                    :not-found-content="
                      fetching ? 'Searching...' : 'Not Found!'
                    "
                    @search="fetchUser"
                    @change="(e) => handleChange(e, 'selectedName')"
                  >
                    <a-spin
                      v-if="fetching"
                      slot="notFoundContent"
                      size="small"
                    />
                    <a-select-option
                      v-for="(d, index) in dataRekening"
                      :key="index"
                    >
                      <!-- {{ d.rekening + ' - ' + d.nama }} -->
                      {{ d.nama + " - " + d.rekening }}
                    </a-select-option>
                  </a-select>
                </div>
                <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 mt-1">
                  <label class="mt-1">Nama</label>
                </div>
                <div class="col-lg-8 col-md-8 col-sm-8 mt-1">
                  <a-input
                    placeholder="Nama"
                    v-model="maskInput.nama"
                    :readOnly="true"
                  ></a-input>
                </div>
                <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 mt-1">
                  <label class="mt-1">Alamat</label>
                </div>
                <div class="col-lg-8 col-md-8 col-sm-8 mt-1">
                  <a-input
                    placeholder="Alamat"
                    v-model="maskInput.alamat"
                    :readOnly="true"
                  ></a-input>
                </div>
                <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 mt-2">
                  <label class="mt-1">Tanggal</label>
                </div>
                <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8 mt-2">
                  <a-date-picker
                    style="width: 130px;"
                    v-model="input.tgl"
                    :allowClear="false"
                    format="DD-MM-YYYY"
                  />
                </div>
                <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 mt-2">
                  <label class="mt-1">Rek. Tabungan</label>
                </div>
                <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8 mt-2">
                  <a-input
                    placeholder="Rek. Tabungan"
                    v-model="maskInput.rektabungan"
                    :readOnly="true"
                  ></a-input>
                </div>
                <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 mt-2">
                  <label class="mt-1">Saldo Tab. Efektif</label>
                </div>
                <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8 mt-2">
                  <a-input
                    placeholder="Saldo Tab. Efektif"
                    style="width: 200px;"
                    v-model="maskInput.saldotabunganefektif"
                    :readOnly="true"
                  ></a-input>
                </div>
                <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 mt-2">
                  <label class="mt-1">Atas Nama</label>
                </div>
                <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8 mt-2">
                  <a-input
                    placeholder="Atas Nama"
                    v-model="maskInput.atasnama"
                    :readOnly="true"
                  ></a-input>
                </div>
                <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 mt-2">
                  <label class="mt-1">Angsuran Pokok</label>
                </div>
                <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8 mt-2">
                  <cleave
                    ref="angsuranpokok"
                    :options="{ numeral: true }"
                    class="ant-input mr-1"
                    :raw="true"
                    style="width: 150px;"
                    placeholder="Angsuran Pokok"
                    v-model="input.pokok"
                  />
                </div>
                <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 mt-2">
                  <label class="mt-1">Angsuran Bunga</label>
                </div>
                <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8 mt-2">
                  <cleave
                    :options="{ numeral: true }"
                    class="ant-input mr-1"
                    :raw="true"
                    style="width: 150px;"
                    placeholder="Angsuran Bunga"
                    v-model="input.bunga"
                  />
                </div>
                <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 mt-2">
                  <label class="mt-1">Denda</label>
                </div>
                <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8 mt-2">
                  <cleave
                    :options="{ numeral: true }"
                    class="ant-input mr-1"
                    :raw="true"
                    style="width: 150px;"
                    placeholder="Denda"
                    v-model="input.denda"
                  />
                </div>
                <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 mt-2">
                  <label class="mt-1">Keterangan</label>
                </div>
                <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8 mt-2">
                  <a-input
                    placeholder="Keterangan"
                    v-model="input.keterangan"
                    :readOnly="false"
                  ></a-input>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-2">
                  <a-button
                    class="btn btn-primary ml-4 float-right"
                    @click="handleSubmit"
                    >Submit</a-button
                  >
                </div>
              </div>
            </div>
            <div class="col-xs-7 col-sm-7 col-md-7 col-lg-7">
              <div class="row">
                <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                  <div class="row">
                    <div class="col-xs-5 col-sm-5 col-md-5 col-lg-5">
                      <label class="mt-1">Plafond</label>
                    </div>
                    <div class="col-xs-7 col-sm-7 col-md-7 col-lg-7">
                      <a-input
                        placeholder="Plafond"
                        v-model="maskInput.plafond"
                        :readOnly="true"
                      ></a-input>
                    </div>
                  </div>
                </div>
                <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                  <div class="row">
                    <div class="col-xs-5 col-sm-5 col-md-5 col-lg-5">
                      <label class="mt-1">Tgl Valuta</label>
                    </div>
                    <div class="col-xs-7 col-sm-7 col-md-7 col-lg-7">
                      <a-input
                        placeholder="Tgl Valuta"
                        style="width: 150px;"
                        v-model="maskInput.tglvaluta"
                        :readOnly="true"
                      ></a-input>
                    </div>
                  </div>
                </div>
                <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                  <div class="row">
                    <div class="col-xs-5 col-sm-5 col-md-5 col-lg-5 mt-2">
                      <label class="mt-1">Total Bunga</label>
                    </div>
                    <div class="col-xs-7 col-sm-7 col-md-7 col-lg-7 mt-2">
                      <a-input
                        placeholder="Total Bunga"
                        v-model="maskInput.totalbunga"
                        :readOnly="true"
                      ></a-input>
                    </div>
                  </div>
                </div>
                <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                  <div class="row">
                    <div class="col-xs-5 col-sm-5 col-md-5 col-lg-5 mt-2">
                      <label class="mt-1">Jangka Waktu</label>
                    </div>
                    <div class="col-xs-7 col-sm-7 col-md-7 col-lg-7 mt-2">
                      <a-input
                        placeholder="Jangka Waktu"
                        v-model="maskInput.jw"
                        :readOnly="true"
                        class="mr-2"
                        style="width: 70px; text-align: right;"
                      ></a-input>
                      Bulan
                    </div>
                  </div>
                </div>
                <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 mt-2">
                  <div class="row">
                    <div class="col-xs-5 col-sm-5 col-md-5 col-lg-5">
                      <label>Saldo Pokok: </label>
                    </div>
                    <div class="col-xs-7 col-sm-7 col-md-7 col-lg-7">
                      <label>{{ maskInput.saldopokok }}</label>
                    </div>
                  </div>
                </div>
                <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 mt-2">
                  <div class="row">
                    <div class="col-xs-5 col-sm-5 col-md-5 col-lg-5">
                      <label>Saldo Bunga: </label>
                    </div>
                    <div class="col-xs-7 col-sm-7 col-md-7 col-lg-7">
                      <label>{{ maskInput.saldobunga }}</label>
                    </div>
                  </div>
                </div>
                <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 mt-2">
                  <div class="row">
                    <div class="col-xs-5 col-sm-5 col-md-5 col-lg-5">
                      <label>Tunggakan Pokok: </label>
                    </div>
                    <div class="col-xs-7 col-sm-7 col-md-7 col-lg-7">
                      <label>{{ maskInput.tunggakanpokok }}</label>
                    </div>
                  </div>
                </div>
                <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 mt-2">
                  <div class="row">
                    <div class="col-xs-5 col-sm-5 col-md-5 col-lg-5">
                      <label>Tunggakan Bunga: </label>
                    </div>
                    <div class="col-xs-7 col-sm-7 col-md-7 col-lg-7">
                      <label>{{ maskInput.tunggakanbunga }}</label>
                    </div>
                  </div>
                </div>
              </div>
              <ag-grid-vue
                style="height: 30vh;"
                :class="
                  settings.theme === 'dark'
                    ? 'ag-theme-balham-dark mt-2'
                    : 'ag-theme-balham mt-2'
                "
                :key="componentKey"
                :gridOptions="gridOptions"
                :columnDefs="columnDefs"
                :pagination="allowPagination"
                :paginationPageSize="20"
                :cacheQuickFilter="true"
                :quickFilterText="tablequickfilter"
                :accentedSort="false"
                :sortingOrder="['asc', 'desc']"
                :rowData="rowData"
                @grid-ready="onGridReady"
                :singleClickEdit="false"
                @cell-value-changed="onCellValueChanged"
                :undoRedoCellEditing="true"
                :undoRedoCellEditingLimit="5"
                :frameworkComponents="frameworkComponents"
                @first-data-rendered="onFirstDataRendered"
                rowSelection="single"
                @selection-changed="onSelectionChanged"
                :rowClassRules="rowClassRules"
              >
              </ag-grid-vue>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-2">
              <a-table
                :key="componentKeyDatatable"
                :columns="columns"
                :dataSource="data"
                size="small"
                :scroll="{ y: 400, x: 300 }"
                :pagination="{
                  defaultPageSize: 500,
                  hideOnSinglePage: true,
                  showQuickJumper: true,
                  showSizeChanger: true,
                  showTotal: (total) => `Total ${total} items`,
                  pageSizeOptions: ['10', '20', '30'],
                }"
              >
                <div
                  slot="filterDropdown"
                  slot-scope="{
                    setSelectedKeys,
                    selectedKeys,
                    confirm,
                    clearFilters,
                    column,
                  }"
                  style="padding: 8px;"
                >
                  <a-input
                    v-ant-ref="(c) => (searchInput = c)"
                    :placeholder="`Search ${column.dataIndex}`"
                    :value="selectedKeys[0]"
                    style="width: 188px; margin-bottom: 8px; display: block;"
                    @change="
                      (e) =>
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                    "
                    @pressEnter="
                      () =>
                        handleSearch(selectedKeys, confirm, column.dataIndex)
                    "
                  />
                  <a-button
                    type="primary"
                    icon="search"
                    size="small"
                    style="width: 90px; margin-right: 8px;"
                    @click="
                      () =>
                        handleSearch(selectedKeys, confirm, column.dataIndex)
                    "
                  >
                    Search
                  </a-button>
                  <a-button
                    size="small"
                    style="width: 90px;"
                    @click="() => handleReset(clearFilters)"
                  >
                    Reset
                  </a-button>
                </div>
                <a-icon
                  slot="filterIcon"
                  slot-scope="filtered"
                  type="search"
                  :style="{ color: filtered ? '#108ee9' : undefined }"
                />
                <template
                  slot="customRender"
                  slot-scope="text, record, index, column"
                >
                  <span
                    v-if="searchText && searchedColumn === column.dataIndex"
                  >
                    <template
                      v-for="(fragment, i) in text
                        .toString()
                        .split(
                          new RegExp(
                            `(?<=${searchText})|(?=${searchText})`,
                            'i'
                          )
                        )"
                    >
                      <mark
                        v-if="
                          fragment.toLowerCase() === searchText.toLowerCase()
                        "
                        :key="i"
                        class="highlight"
                        >{{ fragment }}</mark
                      >
                      <template v-else>{{ fragment }}</template>
                    </template>
                  </span>
                  <template v-else>
                    <span v-if="column.dataIndex === 'name'">
                      {{ text }}
                    </span>
                    <span v-else>
                      {{ text }}
                    </span>
                  </template>
                </template>
                <template slot="cetak" slot-scope="text, record">
                  <a-button
                    class="btn btn-outline-success"
                    @click="reGenerateValidasi(record)"
                  >
                    <a-icon type="printer" />
                  </a-button>
                </template>
                <label slot="tgl" slot-scope="text">
                  {{ formatTanggal(text) }}
                </label>
                <template slot="Nominal" slot-scope="text">
                  {{ formatCurrency(text) }}
                </template>
                <template slot="jumlah" slot-scope="text, record">
                  {{
                    formatCurrency(record.pokok + record.bunga + record.denda)
                  }}
                </template>
              </a-table>
            </div>
          </div>
        </a-card>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import * as lou from '@/services/data/lou'
import { mapState } from 'vuex'
import numericInput from './component/numeric'
import selectInput from './component/select'
import dateInput from './component/date'
import tooltip from './component/tooltip'
import htmlrender from './component/htmlrender'
import Cleave from 'vue-cleave-component'
import debounce from 'lodash/debounce'

export default {
  components: {
    cleave: Cleave,
  },
  computed: mapState(['settings', 'user']),
  watch: {
    // 'settings.selectedRekening'() {
    //   this.checkRekening()
    // },
  },
  data() {
    this.lastFetchId = 0
    this.fetchUser = debounce(this.fetchUser, 800)
    return {
      dataselectedrekening: {},
      selectedName: [],
      norekening: '',
      fetching: false,
      dataRekening: [],

      componentKey: 0,
      searchText: '',
      searchInput: null,
      searchedColumn: '',
      input: {
        debitur_id: '',
        tgl: moment(),
        pokok: '',
        denda: '',
        keterangan: '',
        username: '',
        total: '',
        bunga: '',
      },
      maskInput: {
        rekcabang: '01',
        rerekening: '',
        rerekening1: '',
        namanasabah: '',
        alamatnasabah: '',
        plafond: '',
        totalbunga: '',
        tglvaluta: '',
        jw: '',
        rektabungan: '',
        saldotabunganefektif: '',
        atasnama: '',
        saldopokok: '0',
        saldobunga: '0',
        tunggakanpokok: '0',
        tunggakanbunga: '0',
      },
      allMaster: {
        carapencairan: [],
      },
      // Property a-table
      componentKeyDatatable: 0,
      columns: [
        // {
        //   title: 'Cetak',
        //   width: 50,
        //   scopedSlots: {
        //     customRender: 'cetak',
        //   },
        // },
        {
          title: 'Tgl Transaksi',
          dataIndex: 'tgl',
          scopedSlots: { customRender: 'tgl' },
          width: 100,
        },
        {
          title: 'Nomor Transaksi',
          dataIndex: 'faktur',
          scopedSlots: { customRender: 'nomortransaksi' },
          width: 170,
        },
        {
          title: 'Rekening',
          dataIndex: 'rekening',
          scopedSlots: { customRender: 'rekening' },
          width: 100,
        },
        {
          title: 'Keterangan',
          dataIndex: 'keterangan',
          scopedSlots: { customRender: 'keterangan' },
          width: 350,
        },
        {
          title: 'Pokok',
          dataIndex: 'pokok',
          width: 100,
          scopedSlots: { customRender: 'Nominal' },
          align: 'right',
        },
        {
          title: 'Bunga',
          dataIndex: 'bunga',
          width: 100,
          scopedSlots: { customRender: 'Nominal' },
          align: 'right',
        },
        {
          title: 'Denda',
          dataIndex: 'denda',
          width: 100,
          scopedSlots: { customRender: 'Nominal' },
          align: 'right',
        },
        {
          title: 'Jumlah',
          dataIndex: 'jumlah',
          scopedSlots: { customRender: 'jumlah' },
          align: 'right',
          width: 100,
        },
        // {
        //   title: 'Akses',
        //   dataIndex: 'privileges',
        //   scopedSlots: { customRender: 'privileges' },
        // },
      ],
      data: [],
      // End of property

      // Property ag-grid
      mode: 'netral',
      theme: this.$store.state.settings.theme,
      gridOptions: null,
      selectedData: null,
      gridApi: null,
      backupRowData: [],
      changedData: [],
      rowData: [],
      tablequickfilter: '',
      allowPagination: false,
      columnDefs: [
        {
          headerName: 'Tgl',
          field: 'tgl',
          editable: true,
          resizable: true,
          sortable: true,
          valueFormatter: this.dateFormatter,
        },
        {
          headerName: 'Pokok',
          field: 'pokok',
          editable: true,
          resizable: true,
          sortable: true,
          valueFormatter: this.currencyFormatter,
          type: 'rightAligned',
        },
        {
          headerName: 'Bunga',
          field: 'bunga',
          editable: true,
          resizable: true,
          sortable: true,
          valueFormatter: this.currencyFormatter,
          type: 'rightAligned',
        },
        {
          headerName: 'Total',
          field: 'total',
          editable: true,
          resizable: true,
          sortable: true,
          valueFormatter: this.currencyFormatter,
          type: 'rightAligned',
        },
        // {
        //   headerName: 'Keterangan',
        //   field: 'keterangan',
        //   editable: true,
        //   resizable: true,
        //   sortable: true,
        // },
      ],
      frameworkComponents: {
        numericEditor: numericInput,
        dateEditor: dateInput,
        selectEditor: selectInput,
        customTooltip: tooltip,
        htmlRender: htmlrender,
      },
      rowClassRules: {
        'text-success': (params) => {
          return params.data.newdata !== undefined
        },
        'text-warning': (params) => {
          return params.data.edited !== undefined
        },
        'text-danger': (params) => {
          return params.data.isError !== undefined
        },
        'text-primary': (params) => {
          return params.data.isPrimary !== undefined && params.data.isPrimary === 1
        },
        'font-weight-bold': (params) => {
          return params.data.isBold !== undefined
        },
      },
      // End property

      // Modal Property
      formvisible: false,
      carinasabahtitle: 'Cari',
      inputModal: {
        nama: '',
        alamat: '',
        cabang: '',
      },
    }
  },
  created() {
    this.gridOptions = {}
    this.getAllData()
  },
  methods: {
    moment,
    async fetchUser(value) {
      // console.log('fetching user', value)
      if (value !== '') {
        this.lastFetchId += 1
        const fetchId = this.lastFetchId
        this.dataRekening = []
        this.fetching = true
        var res = false
        if (!this.hasNumber(value)) {
          var nvalue = value.replace(/[^\w\s]/gi, '')
          res = await lou.customUrlGet2('angsuran_kredit/cari?Nama=' + nvalue)
        } else {
          var nvalue1 = value.replace(/[0-9]/g, '')
          res = await lou.customUrlGet2(
            'angsuran_kredit/cari?Rekening=' + nvalue1,
          )
        }
        if (res) {
          if (fetchId !== this.lastFetchId) {
            // for fetch callback order
            return false
          }
          this.dataRekening = res.data
          this.fetching = false
        } else {
          this.fetching = null
        }
      }
    },
    hasNumber(myString) {
      return /\d/.test(myString)
    },
    handleChange(value, from) {
      // console.log('value', value)
      if (value.key !== null && value.key !== undefined) {
        var dataTarget = this.dataRekening[value.key]
        // console.log('dataTarget', dataTarget)
        var data = {
          selectedName: value,
          selectedRekening: value,
          rekeningjurnal: dataTarget.rekening,
          norekening: dataTarget.rekening,
          tabungan_id: dataTarget.id,
          namaprekening: dataTarget.nama,
          alamatprekening: dataTarget.alamat,
          saldoprekening: dataTarget.saldo,
          // dataRekening: [],
          fetching: false,
        }
        Object.assign(this, data)
        this.input.debitur_id = dataTarget.id
        this.maskInput.tglvaluta = moment(dataTarget.tgl, 'YYYY-MM-DD').format(
          'DD-MM-YYYY',
        )
        this.maskInput.nama = dataTarget.nama
        this.maskInput.alamat = dataTarget.alamat
        this.maskInput.atasnama = dataTarget.nama_tabungan
        this.maskInput.rektabungan = dataTarget.rekening_tabungan
        this.maskInput.jw = dataTarget.jw
        this.rowData = dataTarget.buku_angsurans
        this.maskInput.plafond = this.formatCurrency(dataTarget.plafond)
        this.maskInput.totalbunga = this.formatCurrency(dataTarget.totalbunga)
        this.maskInput.saldotabunganefektif = this.formatCurrency(
          dataTarget.saldo_tabungan,
        )
        this.maskInput.saldopokok = this.formatCurrency(dataTarget.saldo_pokok)
        this.maskInput.saldobunga = this.formatCurrency(dataTarget.saldo_bunga)
        this.maskInput.tunggakanpokok = this.formatCurrency(
          dataTarget.tunggakan_pokok,
        )
        this.maskInput.tunggakanbunga = this.formatCurrency(
          dataTarget.tunggakan_bunga,
        )
        this.input.keterangan =
          'Angsuran - ' +
          dataTarget.rekening +
          ' [' +
          dataTarget.nama +
          ']'
        this.$refs.angsuranpokok.$el.focus()
      }
    },
    async checkRekening() {
      // console.log('this.norekening.length', this.norekening.length)
      if (this.norekening.length === 12) {
        // console.log('this.norekening', this.norekening)
        var res = await lou.customUrlGet2(
          'angsuran_kredit/cari?Rekening=' + this.norekening.toString(),
        )
        if (res) {
          // console.log('res.data', res.data)
          if (res.data !== null) {
            this.dataselectedrekening = this.$g.clone(res.data)
            var dataTarget = res.data
            var data = {
              selectedName: { key: 0, label: dataTarget.nama },
              selectedRekening: { key: 0, label: dataTarget.rekening },
              rekeningjurnal: dataTarget.rekening,
              norekening: dataTarget.rekening,
              tabungan_id: dataTarget.id,
              namaprekening: dataTarget.nama,
              alamatprekening: dataTarget.alamat,
              saldoprekening: dataTarget.saldo,
              // dataRekening: [],
              fetching: false,
            }
            Object.assign(this, data)
            this.input.debitur_id = dataTarget.id
            this.maskInput.tglvaluta = moment(
              dataTarget.tgl,
              'YYYY-MM-DD',
            ).format('DD-MM-YYYY')
            this.maskInput.nama = dataTarget.nama
            this.maskInput.alamat = dataTarget.alamat
            this.maskInput.atasnama = dataTarget.nama_tabungan
            this.maskInput.jw = dataTarget.jw
            this.rowData = dataTarget.buku_angsurans
            this.maskInput.plafond = this.formatCurrency(dataTarget.plafond)
            this.maskInput.totalbunga = this.formatCurrency(
              dataTarget.totalbunga,
            )
            this.maskInput.saldotabunganefektif = this.formatCurrency(
              dataTarget.saldo_tabungan,
            )
            this.maskInput.saldopokok = this.formatCurrency(
              dataTarget.saldo_pokok,
            )
            this.maskInput.saldobunga = this.formatCurrency(
              dataTarget.saldo_bunga,
            )
            this.maskInput.tunggakanpokok = this.formatCurrency(
              dataTarget.tunggakan_pokok,
            )
            this.maskInput.tunggakanbunga = this.formatCurrency(
              dataTarget.tunggakan_bunga,
            )
            this.maskInput.rektabungan = dataTarget.rekening_tabungan
            this.input.keterangan =
              'Angsuran - ' +
              dataTarget.rekening +
              ' [' +
              dataTarget.nama +
              ']'
            this.$refs.angsuranpokok.$el.focus()
          } else {
            lou.shownotif(
              'Tidak di temukan',
              'Tidak ada data dengan rekening tersebut!',
              'error',
            )
          }
        } else {
          // lou.shownotif('Tidak di temukan', 'Tidak ada data dengan rekening tersebut!', 'error')
        }
      }
    },
    async rekeningcheck() {
      var res = await lou.customUrlGet2(
        'angsuran_kredit/rekening/' +
          this.maskInput.rekcabang +
          '.' +
          this.maskInput.rerekening +
          '.' +
          this.maskInput.rerekening1,
      )
      if (res) {
        if (res.data !== null) {
          lou.shownotif('Ditemukan!', 'Rekening ditemukan.')
          // this.maskInput = this.$g.clone(res.data)
          // console.log('this.maskInput', this.maskInput)
        } else {
          lou.shownotif(
            'Tidak ditemukan!',
            'Rekening tidak ditemukan.',
            'error',
          )
        }
      }
    },
    async handleSubmit() {
      // Should format date value before submit.
      var res = false
      var fd = this.$g.clone(this.input)
      // console.log('fd', fd)
      fd.tgl =
        this.input.tgl !== null ? this.input.tgl.format('YYYY-MM-DD') : null
      // console.log('this.user', this.user)
      fd.username = this.user.name
      fd.denda = parseInt(fd.denda)
      fd.pokok = parseInt(fd.pokok)
      fd.bunga = parseInt(fd.bunga)
      fd.total =
        parseInt(fd.denda) + parseInt(fd.pokok) + parseInt(fd.bunga)
      if (this.action === 'update') {
        res = await lou.customUrlPut2('angsuran_kredit', fd, false)
      } else {
        res = await lou.customUrlPost2('angsuran_kredit', fd, false)
      }
      if (res) {
        this.resetForm()
        this.data.push(res.data)
      }
    },
    async getAllData() {
      var res = await lou.customUrlGet2('angsuran_kredit')
      if (res) {
        this.data = res.data
      }
    },
    resetForm() {
      this.selectedName = {}
      this.selectedRekening = {}
      this.rekeningjurnal = ''
      this.norekening = ''
      this.tabungan_id = ''
      this.namaprekening = ''
      this.alamatprekening = ''
      this.saldoprekening = ''
      this.input = {
        debitur_id: '',
        tgl: moment(),
        pokok: '',
        denda: '',
        keterangan: '',
        username: '',
        total: '',
        bunga: '',
      }
      this.maskInput = {
        rekcabang: '01',
        rerekening: '',
        rerekening1: '',
        namanasabah: '',
        alamatnasabah: '',
        plafond: '',
        totalbunga: '',
        tglvaluta: '',
        jw: '',
        rektabungan: '',
        saldotabunganefektif: '',
        atasnama: '',
        saldopokok: '0',
        saldobunga: '0',
        tunggakanpokok: '0',
        tunggakanbunga: '0',
      }
      this.$refs.rekeningfirst.$el.focus()
    },

    // Modal function
    clearRekening() {
      this.settings.selectedRekening = ''
    },
    async findData() {
      var imbuhan = ''
      imbuhan +=
        this.inputModal.nama !== '' ? '?Nama=' + this.inputModal.nama : ''
      imbuhan +=
        this.inputModal.alamat !== ''
          ? this.inputModal.nama !== ''
            ? '&Alamat=' + this.inputModal.alamat
            : '?Alamat=' + this.inputModal.alamat
          : ''
      imbuhan +=
        this.inputModal.cabang !== ''
          ? '&cabang=' + this.inputModal.cabang
          : ''
      var res = await lou.customUrlGet2('carinasabah' + imbuhan)
      if (res) {
        this.table.tableatas.datatable = res.data
        // for (let i = 0; i < 20; i++) {
        //   const element = res.data[0]
        //   this.table.tableatas.datatable.push(element)
        // }
      }
    },
    async nasabahDetail(record) {
      this.loading = true
      var res = await lou.customUrlGet2('carinasabah/detail/' + record.kode)
      if (res) {
        this.maskInput.cabang = record.kode.slice(0, record.kode.indexOf('.'))
        this.maskInput.reregister = record.kode.slice(
          record.kode.indexOf('.') + 1,
          record.kode.length,
        )
        // this.registeridchanged()
        this.table.tablebawah.datatable = res.data
        this.loading = false
      }
    },
    customRow(record, index) {
      return {
        on: {
          click: (event) => {
            // console.log('record', record)
            // console.log('index', index)
            // console.log('event', event)
            this.settings.selectedKodeRegister = record.kode
            this.nasabahDetail(record)
          },
          contextmenu: (event) => {
            event.preventDefault()
          },
        },
      }
    },
    customRowB(record, index) {
      return {
        on: {
          click: (event) => {
            // console.log('record', record)

            this.input.rekening = record.rekening
            var text = record.rekening
            this.maskInput.rekcabang = text.slice(0, text.indexOf('.'))
            this.maskInput.rerekening = text.slice(
              text.indexOf('.') + 1,
              text.indexOf('.') + 3,
            )
            var ntext = text.replace('.', '')
            // console.log('ntext', ntext.slice(ntext.indexOf('.') + 1, ntext.length))
            this.maskInput.rerekening1 = ntext.slice(
              ntext.indexOf('.') + 1,
              ntext.length,
            )
            this.maskInput = this.$g.clone(record)
            this.maskInput.plafond = this.formatCurrency(record.plafond)
            this.maskInput.biaya_administrasi = this.formatCurrency(
              record.biaya_administrasi,
            )
            this.maskInput.biaya_asuransi = this.formatCurrency(
              record.biaya_asuransi,
            )
            this.maskInput.biaya_materai = this.formatCurrency(
              record.biaya_materai,
            )
            this.maskInput.biaya_notaris = this.formatCurrency(
              record.biaya_notaris,
            )
            // console.log('this.maskInput', this.maskInput)
            this.formvisible = true
            // this.settings.selectedRekening = record.rekening
            // console.log('index', index)
            // console.log('event', event)
            // console.log('this.settings.selectedRekening', this.settings.selectedRekening)
          },
          contextmenu: (event) => {
            event.preventDefault()
          },
        },
      }
    },
    formatCurrency(v) {
      return lou.curency(v)
    },
    filterOption(input, option) {
      return (
        option.componentOptions[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      )
    },
    dateFormatter(params) {
      var ret =
        params.value === null
          ? ''
          : moment(params.value, 'YYYY-MM-DD').format('DD-MM-YYYY')
      // console.log('dateFormatter', ret)
      return ret !== 'Invalid date' ? ret : params.value
    },
    currencyFormatter(params) {
      var ret =
        params.value === null
          ? 'NaN'
          : lou.curency(parseFloat(params.value).toFixed(0))
      return ret === 'NaN' ? '' : ret
    },
    // ag-grid function
    onGridReady(params) {
      // params.api.sizeColumnsToFit()
      this.gridApi = params.api
      this.gridColumnApi = params.columnApi
    },
    onCellValueChanged(params) {
      var undoSize = params.api.getCurrentUndoSize()
      this.input.undoInput = undoSize
      this.input.undoInputdisable = undoSize < 1
      var redoSize = params.api.getCurrentRedoSize()
      this.input.redoInput = redoSize
      this.input.redoInputdisable = redoSize < 1
      if (this.mode === 'oncreate') {
        if (params.data.newdata) {
          var target = this.changedData.findIndex(
            (x) => x.id === params.data.id,
          )
          if (target < 0) {
            this.changedData.push(params.data)
          } else {
            this.changedData[target] = params.data
          }
          // console.log('this.changedData', this.changedData)
        } else {
          // eslint-disable-next-line no-redeclare
          var target = this.rowData.findIndex((x) => x.id === params.data.id)
          var backupTarget = this.backupRowData.findIndex(
            (x) => x.id === params.data.id,
          )
          // console.log('this.rowData[target] before:', this.rowData[target])
          this.rowData[target] = this.$g.clone(
            this.backupRowData[backupTarget],
          )
          // console.log('this.rowData[target] after:', this.rowData[target])
          // console.log('this.backupRowData[backupTarget]', this.backupRowData[backupTarget])
          // this.componentKey += 1
          this.changedData = []
          this.input.undoInput = 0
          this.input.undoInputdisable = true

          this.input.redoInput = 0
          this.input.redoInputdisable = true
          lou.shownotif('Anda sedang dalam mode create')
        }
      } else {
        this.mode = 'onupdate'
        // eslint-disable-next-line no-redeclare
        var targetChanged = this.changedData.findIndex(
          (x) => x.id === params.data.id,
        )
        if (targetChanged < 0) {
          // var cd = {}
          // cd.id = params.data.id
          // cd[params.column.colId] = value.value
          // console.log('cd[params.column.colId]', cd)
          // console.log('value.value', value)
          this.changedData.push(params.data)
        } else {
          this.changedData[targetChanged][params.column.colId] =
            params.newValue
        }
        // console.log('this.changedData', this.changedData)
        // this.componentKey += 1
      }
      // console.log('this.mode', this.mode)
    },
    onSelectionChanged() {
      var selectedRows = this.gridApi.getSelectedRows()
      this.selectedData = selectedRows
      console.log('selectedRows', selectedRows)
    },
    onFirstDataRendered() {
      this.autoSizeAll(false, { autosizecolumn: true })
    },
    autoSizeAll(skipHeader, { autosizecolumn = true }) {
      if (autosizecolumn) {
        var allColumnIds = []
        // console.log('this.gridColumnApi', this.gridColumnApi)
        this.gridColumnApi.getAllColumns().forEach(function (column) {
          allColumnIds.push(column.colId)
        })
        this.gridColumnApi.autoSizeColumns(allColumnIds, skipHeader)
      } else {
        this.gridApi.sizeColumnsToFit()
      }
    },
    formatTanggal(v) {
      // console.log('v', v)
      // console.log('ntgl', ntgl)
      return moment(v, 'YYYY-MM-DD').format('DD-MM-YYYY')
    },
  },
}
</script>

<style lang="scss" scoped></style>
